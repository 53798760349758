import { Box, Paper } from '@mui/material';
import {
  ANALYTICS_PAGE_TYPE,
  bffSchema,
  contentfulSchema,
  setCountryFromCookie,
  setCountryToCookie,
} from '@wr/web-shared';
import {
  CalculatorCountryField,
  CalculatorExchangeRate,
  CountryData,
  ExchangeRateCalculationType,
  OnCalculatorCountryChangeEvent,
  PerimeterX,
} from '@wr/web-ui';
import { useRouter } from 'next/router';
import React, { useCallback, useContext, useEffect } from 'react';

import { Anchor, useStickyBanners } from '@/components';
import { Button } from '@/components/button';
import {
  AppContext,
  Calculation,
  CalculatorContext,
  CalculatorContextType,
  useCalculatorCorridors,
  useCalculatorExchangeRate,
  useCalculatorPayoutMethods,
} from '@/context';
import { getCalculatorCorridorSlug } from '@/services';

import { CexCalculatorError } from './cex-calculator.error';
import { useErrorMessages } from './cex-calculator.errors.hooks';
import { CexCalculatorFooter } from './cex-calculator.footer';
import { useStyles } from './cex-calculator.styles';
import { CalculatorProps } from './cex-calculator.types';
import {
  getSortedPayoutMethods,
  setDataLayerForCalculation,
  setDataLayerForDropdownOpen,
  setDataLayerForDropdownSearch,
  setDataLayerForDropdownSelection,
} from './cex-calculator.utils';

export const CexCalculator: React.FC<CalculatorProps> = ({
  name,
  sendLabel,
  receiveLabel,
  exchangeRatePromoLabel,
  countriesSearchPlaceholder,
  ctaLinks,
  feeLabel,
  transferTimeLabel,
  totalToPayLabel,
  payoutMethodsLabel,
  isLite,
  blendedRateLabel,
  promotionalTermsLink,
  contentfulPayoutMethodsList,
  ...props
}) => {
  const router = useRouter();
  const classes = useStyles();

  const [state, dispatch] = useContext(CalculatorContext);
  const { receiveCorridors, fetchCorridors } = useCalculatorCorridors();
  const { errors, calculateExchangeRate } = useCalculatorExchangeRate();
  const errorMessages = useErrorMessages({ ...props, errors });
  const { fetchStickyBanners } = useStickyBanners();
  const appContext = useContext(AppContext);

  const { fetchPayoutMethods } = useCalculatorPayoutMethods({
    isExternal: true,
  });

  const calculationsInProgress: Array<Promise<Calculation | undefined>> = [];

  /**
   * Calculate Exchange Rate
   */
  const createCalculation = useCallback(
    async (newState: CalculatorContextType) => {
      dispatch.setIsLoading(true);

      fetchStickyBanners(
        newState.sendCountry?.countryCode,
        newState.receiveCountry?.countryCode,
      );

      const calculation = calculateExchangeRate({
        amount: newState.calculationAmount || 1,
        type: (newState.calculationType as unknown) as bffSchema.CalculationType,
        sendCountryCode: newState.sendCountry?.countryCode ?? '',
        sendCurrencyCode: newState.sendCountry?.currency ?? '',
        receiveCountryCode: newState.receiveCountry?.countryCode ?? '',
        receiveCurrencyCode: newState.receiveCountry?.currency ?? '',
        payOutMethodCode: isLite ? '' : newState?.payoutMethodId ?? '',
        correspondentId: '',
      });

      calculationsInProgress.push(calculation);
      calculation
        .then(calculationResult => {
          const calculationIndex = calculationsInProgress.findIndex(
            calculationInProgress => calculation === calculationInProgress,
          );
          /* 
        if result is for an expired calculation whose reference has already been removed from 
        calculationInProgress array, return early.
        */
          if (calculationIndex === -1) return;
          /* 
        if result is for a valid calculation which is still referenced in calculationsInProgress,
        but where the calculation is not for the most recently entered amount, remove calculation from 
        the calculationInProgress array and return.
        */
          if (calculationIndex !== calculationsInProgress.length - 1) {
            calculationsInProgress.splice(calculationIndex, 1);
            return;
          }
          if (calculationResult) {
            const {
              sendAmount,
              receiveAmount,
              ...exchangeRate
            } = calculationResult;

            dispatch.setSendAmount(sendAmount);
            dispatch.setReceiveAmount(receiveAmount);
            dispatch.setExchangeRate(exchangeRate);

            setDataLayerForCalculation({
              state: { ...newState, sendAmount, receiveAmount, exchangeRate },
            });
            calculationsInProgress.length = 0;
          } else {
            // Reset amounts on calculation error
            dispatch.setSendAmount(0);
            dispatch.setReceiveAmount(0);
            dispatch.setExchangeRate({
              fee: 0,
              totalToPay: 0,
              conversionRate: 0,
              crossedOutRate: 0,
              hasPromo: false,
            });
            setDataLayerForCalculation({
              state: {
                ...newState,
                sendAmount: 0,
                receiveAmount: 0,
                exchangeRate: {
                  fee: 0,
                  totalToPay: 0,
                  conversionRate: 0,
                  crossedOutRate: 0,
                  hasPromo: false,
                },
              },
            });
          }

          dispatch.setIsLoading(false);
        })
        .catch(() => {
          dispatch.setIsLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * Navigate to correct page if its a country page or cex-currency-pair
   */
  const routeToCurrencyPage = useCallback(
    ({
      sendCountry,
      receiveCountry,
    }: Pick<CalculatorContextType, 'sendCountry' | 'receiveCountry'>) => {
      // Only re-route pages with slugs
      const currentSlug = Array.isArray(router.query.slug)
        ? router.query.slug.join('/')
        : router.query.slug;

      if (currentSlug) {
        let newSlug: string | undefined = undefined;

        if (appContext.analyticsPageType === ANALYTICS_PAGE_TYPE.Country) {
          newSlug = (receiveCountry?.countryCode
            ? appContext.countriesByCode[receiveCountry.countryCode]
            : undefined
          )?.slug;
        } else if (
          appContext.analyticsPageType === ANALYTICS_PAGE_TYPE.CurrencyConverter
        ) {
          newSlug = getCalculatorCorridorSlug({
            currentSlug,
            sendCurrencyCode: sendCountry?.currency ?? '',
            receiveCurrencyCode: receiveCountry?.currency ?? '',
            corridors: appContext.corridors,
          });
        }

        if (newSlug && newSlug !== currentSlug) {
          router.push(
            {
              pathname: router.pathname,
              query: {
                ...router.query,
                slug: [newSlug],
              },
            },
            undefined,
            {
              shallow: false,
            },
          );
        }
      }
    },
    [
      appContext.corridors,
      appContext.analyticsPageType,
      appContext.countriesByCode,
      router,
    ],
  );

  /**
   * Google Analytics event for search
   */
  const setDropdownDataLayerEvent = useCallback(
    (
      event: Omit<OnCalculatorCountryChangeEvent, 'country'> & {
        country?: CountryData;
      },
    ) => {
      if (event.searchText) {
        setDataLayerForDropdownSearch(event);
      }
      if (event?.country) {
        setDataLayerForDropdownSelection(
          event.country.countryCode,
          event.calculationType,
        );
      }
    },
    [],
  );

  /**
   * Handle changes in calculation amount
   */
  const handleValueChange = useCallback(
    (amount: number, type: ExchangeRateCalculationType) => {
      if (type === ExchangeRateCalculationType.Send) {
        dispatch.setSendAmount(amount);
      } else if (type === ExchangeRateCalculationType.Receive) {
        dispatch.setReceiveAmount(amount);
      }

      dispatch.setCalculationAmount(amount);

      if (state.calculationType !== type) {
        dispatch.setCalculationType(type);
      }

      createCalculation({
        ...state,
        calculationAmount: amount,
        calculationType: type,
      });
    },
    [createCalculation, dispatch, state],
  );

  const onChangePayoutMethod = useCallback(
    async (
      newPayoutMethodId: string,
      country?: CountryData,
      calculationType?: ExchangeRateCalculationType,
    ) => {
      dispatch.setIsLoading(true);
      const {
        sendCountry: currentSendCountry,
        receiveCountry: currentReceiveCountry,
      } = state;

      let sendCountry = currentSendCountry?.countryCode ?? '';
      let receiveCountry = currentReceiveCountry?.countryCode ?? '';
      let receiveCurrency = currentReceiveCountry?.currency ?? '';
      const newState = { ...state };

      if (country) {
        if (calculationType === ExchangeRateCalculationType.Send) {
          sendCountry = country.countryCode;
          newState.sendCountry = country;
        } else if (calculationType === ExchangeRateCalculationType.Receive) {
          receiveCountry = country.countryCode;
          receiveCurrency = country.currency;
          newState.receiveCountry = country;
        }
      }

      const payoutMethods = await fetchPayoutMethods({
        sendCountry,
        receiveCountry,
        receiveCurrency,
      });

      const sortedPayoutMethods = getSortedPayoutMethods(
        payoutMethods,
        contentfulPayoutMethodsList?.items as contentfulSchema.PayoutMethod[],
      );
      if (!newPayoutMethodId) {
        newPayoutMethodId = sortedPayoutMethods?.[0]?.code ?? '';
      }

      dispatch.setPayoutMethodId(newPayoutMethodId);
      dispatch.setPayoutMethods(sortedPayoutMethods);
      dispatch.setIsLoading(false);

      createCalculation({ ...newState, payoutMethodId: newPayoutMethodId });
    },
    [
      createCalculation,
      dispatch,
      fetchPayoutMethods,
      state,
      contentfulPayoutMethodsList,
    ],
  );

  /**
   * Handle changes in send country
   */
  const handleSendCountryChange = useCallback(
    ({
      country,
      calculationType,
      searchText,
    }: OnCalculatorCountryChangeEvent) => {
      if (country.countryCode !== state.sendCountry?.countryCode) {
        const newState = { ...state, sendCountry: country };

        dispatch.setSendCountry(country);
        dispatch.setPayoutMethods([]);
        onChangePayoutMethod('', country, calculationType);
        fetchCorridors(country.countryCode);

        setCountryFromCookie(country.countryCode);

        setDropdownDataLayerEvent({
          searchText,
          calculationType,
          country,
        });

        routeToCurrencyPage(newState);
      }
    },
    [
      state,
      dispatch,
      onChangePayoutMethod,
      fetchCorridors,
      setDropdownDataLayerEvent,
      routeToCurrencyPage,
    ],
  );

  /**
   * Handle changes in receive country
   */
  const handleReceiveCountryChange = useCallback(
    ({
      country,
      calculationType,
      searchText,
    }: OnCalculatorCountryChangeEvent) => {
      if (
        country.countryCode !== state.receiveCountry?.countryCode ||
        country.currency !== state.receiveCountry?.currency
      ) {
        const newState = { ...state, receiveCountry: country };

        dispatch.setReceiveCountry(country);
        dispatch.setPayoutMethods([]);
        onChangePayoutMethod('', country, calculationType);

        setCountryToCookie(country.countryCode);
        setDropdownDataLayerEvent({
          searchText,
          calculationType,
          country,
        });

        routeToCurrencyPage(newState);
      }
    },
    [
      state,
      dispatch,
      onChangePayoutMethod,
      setDropdownDataLayerEvent,
      routeToCurrencyPage,
    ],
  );

  /**
   * Create initial Calculation
   */
  useEffect(() => {
    if (state.sendCountry?.countryCode) {
      fetchCorridors(state.sendCountry.countryCode);
    }
    onChangePayoutMethod('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper
      elevation={0}
      data-testid="calculator"
      classes={{
        root: classes.root,
      }}
    >
      <PerimeterX
        captchaPxLabel={appContext.messages?.captchaPxLabel || undefined}
      />

      <CalculatorCountryField
        loading={state.isLoading}
        id={`${name}-send-country`}
        inputLabel={sendLabel || ''}
        inputValue={state.sendAmount}
        autocompletePlaceholder={countriesSearchPlaceholder}
        selectedCountry={state.sendCountry || undefined}
        calculationType={ExchangeRateCalculationType.Send}
        onSearchCompleted={setDataLayerForDropdownSearch}
        onDropdownOpen={setDataLayerForDropdownOpen}
        onCountryChange={handleSendCountryChange}
        onValueChange={handleValueChange}
        countries={appContext.sendCountries}
        context={{ osName: appContext.osName }}
        isCurrentCalculation={
          state.calculationType === ExchangeRateCalculationType.Send
        }
      />
      <CalculatorExchangeRate
        isLoading={state.isLoading}
        exchangeRate={state.exchangeRate}
        blendedRateLabel={blendedRateLabel}
        sendCurrencyCode={state.sendCountry?.currency ?? ''}
        receiveCurrencyCode={state.receiveCountry?.currency ?? ''}
        promoLabel={exchangeRatePromoLabel}
      />
      <CalculatorCountryField
        loading={state.isLoading}
        id={`${name}-receive-country`}
        inputLabel={receiveLabel || ''}
        inputValue={state.receiveAmount}
        autocompletePlaceholder={countriesSearchPlaceholder}
        selectedCountry={state.receiveCountry || undefined}
        calculationType={ExchangeRateCalculationType.Receive}
        onSearchCompleted={setDataLayerForDropdownSearch}
        onDropdownOpen={setDataLayerForDropdownOpen}
        onCountryChange={handleReceiveCountryChange}
        onValueChange={handleValueChange}
        countries={receiveCorridors}
        context={{ osName: appContext.osName }}
        isCurrentCalculation={
          state.calculationType === ExchangeRateCalculationType.Receive
        }
      />

      <Box
        className={`${classes.footerWrapper} ${
          isLite ? classes.footerWrapperLite : ''
        }`}
      >
        {!isLite ? (
          <CexCalculatorFooter
            feeLabel={feeLabel}
            transferTimeLabel={transferTimeLabel}
            totalToPayLabel={totalToPayLabel}
            payoutMethodsLabel={payoutMethodsLabel}
            onChangePayoutMethod={onChangePayoutMethod}
            hasErrors={errorMessages && errorMessages.length > 0}
          />
        ) : null}
        {!state.isLoading && errorMessages.length ? (
          <CexCalculatorError errors={errorMessages} severity={'error'} />
        ) : null}

        {!state.isLoading && state.exchangeRate?.hasPromo && (
          <Anchor {...promotionalTermsLink}>
            {promotionalTermsLink?.label}
          </Anchor>
        )}
      </Box>

      {ctaLinks?.items && (
        <Box className={classes.ctaLinkWrapper}>
          {ctaLinks.items.map(link => (
            <Button
              {...link}
              className={classes.ctaLinkButton}
              key={link?.name}
            >
              {link?.label}
            </Button>
          ))}
        </Box>
      )}
    </Paper>
  );
};
