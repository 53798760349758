import { Document } from '@contentful/rich-text-types';
import { gql } from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: string; output: string };
  Dimension: { input: number; output: number };
  HexColor: { input: string; output: string };
  JSON: { input: Document; output: Document };
  Quality: { input: number; output: number };
};

/** [web-processes] Page which is being displayed when account is duplicated. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/accountDuplicationPage) */
export type AccountDuplicationPage = Entry &
  _Node & {
    __typename?: 'AccountDuplicationPage';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<AccountDuplicationPageDescription>;
    header?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<AccountDuplicationPageLinkingCollections>;
    logInButton?: Maybe<Link>;
    name?: Maybe<Scalars['String']['output']>;
    subDescription?: Maybe<AccountDuplicationPageSubDescription>;
    sys: Sys;
  };

/** [web-processes] Page which is being displayed when account is duplicated. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/accountDuplicationPage) */
export type AccountDuplicationPageDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Page which is being displayed when account is duplicated. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/accountDuplicationPage) */
export type AccountDuplicationPageHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Page which is being displayed when account is duplicated. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/accountDuplicationPage) */
export type AccountDuplicationPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-processes] Page which is being displayed when account is duplicated. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/accountDuplicationPage) */
export type AccountDuplicationPageLogInButtonArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-processes] Page which is being displayed when account is duplicated. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/accountDuplicationPage) */
export type AccountDuplicationPageNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Page which is being displayed when account is duplicated. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/accountDuplicationPage) */
export type AccountDuplicationPageSubDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type AccountDuplicationPageCollection = {
  __typename?: 'AccountDuplicationPageCollection';
  items: Array<Maybe<AccountDuplicationPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type AccountDuplicationPageDescription = {
  __typename?: 'AccountDuplicationPageDescription';
  json: Scalars['JSON']['output'];
  links: AccountDuplicationPageDescriptionLinks;
};

export type AccountDuplicationPageDescriptionAssets = {
  __typename?: 'AccountDuplicationPageDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type AccountDuplicationPageDescriptionEntries = {
  __typename?: 'AccountDuplicationPageDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type AccountDuplicationPageDescriptionLinks = {
  __typename?: 'AccountDuplicationPageDescriptionLinks';
  assets: AccountDuplicationPageDescriptionAssets;
  entries: AccountDuplicationPageDescriptionEntries;
  resources: AccountDuplicationPageDescriptionResources;
};

export type AccountDuplicationPageDescriptionResources = {
  __typename?: 'AccountDuplicationPageDescriptionResources';
  block: Array<AccountDuplicationPageDescriptionResourcesBlock>;
  hyperlink: Array<AccountDuplicationPageDescriptionResourcesHyperlink>;
  inline: Array<AccountDuplicationPageDescriptionResourcesInline>;
};

export type AccountDuplicationPageDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'AccountDuplicationPageDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type AccountDuplicationPageDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'AccountDuplicationPageDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type AccountDuplicationPageDescriptionResourcesInline = ResourceLink & {
  __typename?: 'AccountDuplicationPageDescriptionResourcesInline';
  sys: ResourceSys;
};

export type AccountDuplicationPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<AccountDuplicationPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AccountDuplicationPageFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  logInButton?: InputMaybe<CfLinkNestedFilter>;
  logInButton_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subDescription_contains?: InputMaybe<Scalars['String']['input']>;
  subDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type AccountDuplicationPageLinkingCollections = {
  __typename?: 'AccountDuplicationPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type AccountDuplicationPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum AccountDuplicationPageOrder {
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type AccountDuplicationPageSubDescription = {
  __typename?: 'AccountDuplicationPageSubDescription';
  json: Scalars['JSON']['output'];
  links: AccountDuplicationPageSubDescriptionLinks;
};

export type AccountDuplicationPageSubDescriptionAssets = {
  __typename?: 'AccountDuplicationPageSubDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type AccountDuplicationPageSubDescriptionEntries = {
  __typename?: 'AccountDuplicationPageSubDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type AccountDuplicationPageSubDescriptionLinks = {
  __typename?: 'AccountDuplicationPageSubDescriptionLinks';
  assets: AccountDuplicationPageSubDescriptionAssets;
  entries: AccountDuplicationPageSubDescriptionEntries;
  resources: AccountDuplicationPageSubDescriptionResources;
};

export type AccountDuplicationPageSubDescriptionResources = {
  __typename?: 'AccountDuplicationPageSubDescriptionResources';
  block: Array<AccountDuplicationPageSubDescriptionResourcesBlock>;
  hyperlink: Array<AccountDuplicationPageSubDescriptionResourcesHyperlink>;
  inline: Array<AccountDuplicationPageSubDescriptionResourcesInline>;
};

export type AccountDuplicationPageSubDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'AccountDuplicationPageSubDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type AccountDuplicationPageSubDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'AccountDuplicationPageSubDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type AccountDuplicationPageSubDescriptionResourcesInline = ResourceLink & {
  __typename?: 'AccountDuplicationPageSubDescriptionResourcesInline';
  sys: ResourceSys;
};

/** Provide action result information Eg. success/error [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/actionResult) */
export type ActionResult = Entry &
  _Node & {
    __typename?: 'ActionResult';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<ActionResultLinkingCollections>;
    linksCollection?: Maybe<ActionResultLinksCollection>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Provide action result information Eg. success/error [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/actionResult) */
export type ActionResultDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Provide action result information Eg. success/error [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/actionResult) */
export type ActionResultLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Provide action result information Eg. success/error [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/actionResult) */
export type ActionResultLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ActionResultLinksCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** Provide action result information Eg. success/error [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/actionResult) */
export type ActionResultTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type ActionResultCollection = {
  __typename?: 'ActionResultCollection';
  items: Array<Maybe<ActionResult>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ActionResultFilter = {
  AND?: InputMaybe<Array<InputMaybe<ActionResultFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ActionResultFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  links?: InputMaybe<CfLinkNestedFilter>;
  linksCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ActionResultLinkingCollections = {
  __typename?: 'ActionResultLinkingCollections';
  changePasswordPageCollection?: Maybe<ChangePasswordPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type ActionResultLinkingCollectionsChangePasswordPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<ActionResultLinkingCollectionsChangePasswordPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ActionResultLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ActionResultLinkingCollectionsChangePasswordPageCollectionOrder {
  ButtonAsc = 'button_ASC',
  ButtonDesc = 'button_DESC',
  ErrorDescriptionAsc = 'errorDescription_ASC',
  ErrorDescriptionDesc = 'errorDescription_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValidationLowerCaseLetterLabelAsc = 'validationLowerCaseLetterLabel_ASC',
  ValidationLowerCaseLetterLabelDesc = 'validationLowerCaseLetterLabel_DESC',
  ValidationMinimumCharactersLabelAsc = 'validationMinimumCharactersLabel_ASC',
  ValidationMinimumCharactersLabelDesc = 'validationMinimumCharactersLabel_DESC',
  ValidationNumericNumberLabelAsc = 'validationNumericNumberLabel_ASC',
  ValidationNumericNumberLabelDesc = 'validationNumericNumberLabel_DESC',
  ValidationUpperCaseLetterLabelAsc = 'validationUpperCaseLetterLabel_ASC',
  ValidationUpperCaseLetterLabelDesc = 'validationUpperCaseLetterLabel_DESC',
}

export type ActionResultLinksCollection = {
  __typename?: 'ActionResultLinksCollection';
  items: Array<Maybe<Link>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ActionResultLinksCollectionOrder {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  GaTrackNameAsc = 'gaTrackName_ASC',
  GaTrackNameDesc = 'gaTrackName_DESC',
  HighlightedAsc = 'highlighted_ASC',
  HighlightedDesc = 'highlighted_DESC',
  IsDisplayedWhenLoggedInAsc = 'isDisplayedWhenLoggedIn_ASC',
  IsDisplayedWhenLoggedInDesc = 'isDisplayedWhenLoggedIn_DESC',
  IsInternalAsc = 'isInternal_ASC',
  IsInternalDesc = 'isInternal_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  MobileMenuToggleAsc = 'mobileMenuToggle_ASC',
  MobileMenuToggleDesc = 'mobileMenuToggle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoFollowAsc = 'noFollow_ASC',
  NoFollowDesc = 'noFollow_DESC',
  OpenInNewTabAsc = 'openInNewTab_ASC',
  OpenInNewTabDesc = 'openInNewTab_DESC',
  RedirectToLegacyProjectAsc = 'redirectToLegacyProject_ASC',
  RedirectToLegacyProjectDesc = 'redirectToLegacyProject_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnderlineAsc = 'underline_ASC',
  UnderlineDesc = 'underline_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

export enum ActionResultOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1922%3A37692&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/appBanner) */
export type AppBanner = Entry &
  _Node & {
    __typename?: 'AppBanner';
    _id: Scalars['ID']['output'];
    appStoreIconLink?: Maybe<Link>;
    bannerDescription?: Maybe<Scalars['String']['output']>;
    bannerTitle?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    image?: Maybe<Asset>;
    linkedFrom?: Maybe<AppBannerLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    playStoreIconLink?: Maybe<Link>;
    sys: Sys;
  };

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1922%3A37692&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/appBanner) */
export type AppBannerAppStoreIconLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1922%3A37692&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/appBanner) */
export type AppBannerBannerDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1922%3A37692&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/appBanner) */
export type AppBannerBannerTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1922%3A37692&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/appBanner) */
export type AppBannerImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1922%3A37692&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/appBanner) */
export type AppBannerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1922%3A37692&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/appBanner) */
export type AppBannerNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1922%3A37692&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/appBanner) */
export type AppBannerPlayStoreIconLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

export type AppBannerCollection = {
  __typename?: 'AppBannerCollection';
  items: Array<Maybe<AppBanner>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type AppBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<AppBannerFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AppBannerFilter>>>;
  appStoreIconLink?: InputMaybe<CfLinkNestedFilter>;
  appStoreIconLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bannerDescription?: InputMaybe<Scalars['String']['input']>;
  bannerDescription_contains?: InputMaybe<Scalars['String']['input']>;
  bannerDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bannerDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  bannerDescription_not?: InputMaybe<Scalars['String']['input']>;
  bannerDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  bannerDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  bannerTitle?: InputMaybe<Scalars['String']['input']>;
  bannerTitle_contains?: InputMaybe<Scalars['String']['input']>;
  bannerTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bannerTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bannerTitle_not?: InputMaybe<Scalars['String']['input']>;
  bannerTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  bannerTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  playStoreIconLink?: InputMaybe<CfLinkNestedFilter>;
  playStoreIconLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type AppBannerLinkingCollections = {
  __typename?: 'AppBannerLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type AppBannerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum AppBannerOrder {
  BannerDescriptionAsc = 'bannerDescription_ASC',
  BannerDescriptionDesc = 'bannerDescription_DESC',
  BannerTitleAsc = 'bannerTitle_ASC',
  BannerTitleDesc = 'bannerTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/appReview) */
export type AppReview = Entry &
  _Node & {
    __typename?: 'AppReview';
    _id: Scalars['ID']['output'];
    connectedComponent?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    isCentred?: Maybe<Scalars['Boolean']['output']>;
    link?: Maybe<Link>;
    linkedFrom?: Maybe<AppReviewLinkingCollections>;
    logo?: Maybe<Asset>;
    moduleBlockStyles?: Maybe<Scalars['JSON']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    starIcon?: Maybe<Asset>;
    stars?: Maybe<Scalars['Float']['output']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/appReview) */
export type AppReviewConnectedComponentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/appReview) */
export type AppReviewIsCentredArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/appReview) */
export type AppReviewLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/appReview) */
export type AppReviewLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/appReview) */
export type AppReviewLogoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/appReview) */
export type AppReviewModuleBlockStylesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/appReview) */
export type AppReviewNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/appReview) */
export type AppReviewStarIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/appReview) */
export type AppReviewStarsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type AppReviewCollection = {
  __typename?: 'AppReviewCollection';
  items: Array<Maybe<AppReview>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type AppReviewFilter = {
  AND?: InputMaybe<Array<InputMaybe<AppReviewFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AppReviewFilter>>>;
  connectedComponent?: InputMaybe<Scalars['String']['input']>;
  connectedComponent_contains?: InputMaybe<Scalars['String']['input']>;
  connectedComponent_exists?: InputMaybe<Scalars['Boolean']['input']>;
  connectedComponent_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  connectedComponent_not?: InputMaybe<Scalars['String']['input']>;
  connectedComponent_not_contains?: InputMaybe<Scalars['String']['input']>;
  connectedComponent_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  isCentred?: InputMaybe<Scalars['Boolean']['input']>;
  isCentred_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isCentred_not?: InputMaybe<Scalars['Boolean']['input']>;
  link?: InputMaybe<CfLinkNestedFilter>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  logo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  moduleBlockStyles_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  starIcon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  stars?: InputMaybe<Scalars['Float']['input']>;
  stars_exists?: InputMaybe<Scalars['Boolean']['input']>;
  stars_gt?: InputMaybe<Scalars['Float']['input']>;
  stars_gte?: InputMaybe<Scalars['Float']['input']>;
  stars_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  stars_lt?: InputMaybe<Scalars['Float']['input']>;
  stars_lte?: InputMaybe<Scalars['Float']['input']>;
  stars_not?: InputMaybe<Scalars['Float']['input']>;
  stars_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type AppReviewLinkingCollections = {
  __typename?: 'AppReviewLinkingCollections';
  appReviewsCollection?: Maybe<AppReviewsCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type AppReviewLinkingCollectionsAppReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<AppReviewLinkingCollectionsAppReviewsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AppReviewLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum AppReviewLinkingCollectionsAppReviewsCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum AppReviewOrder {
  ConnectedComponentAsc = 'connectedComponent_ASC',
  ConnectedComponentDesc = 'connectedComponent_DESC',
  IsCentredAsc = 'isCentred_ASC',
  IsCentredDesc = 'isCentred_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  StarsAsc = 'stars_ASC',
  StarsDesc = 'stars_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/appReviews) */
export type AppReviews = Entry &
  _Node & {
    __typename?: 'AppReviews';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<AppReviewsLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    reviewsCollection?: Maybe<AppReviewsReviewsCollection>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/appReviews) */
export type AppReviewsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/appReviews) */
export type AppReviewsNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/appReviews) */
export type AppReviewsReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<AppReviewsReviewsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AppReviewFilter>;
};

export type AppReviewsCollection = {
  __typename?: 'AppReviewsCollection';
  items: Array<Maybe<AppReviews>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type AppReviewsFilter = {
  AND?: InputMaybe<Array<InputMaybe<AppReviewsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AppReviewsFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reviews?: InputMaybe<CfAppReviewNestedFilter>;
  reviewsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type AppReviewsLinkingCollections = {
  __typename?: 'AppReviewsLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type AppReviewsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum AppReviewsOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type AppReviewsReviewsCollection = {
  __typename?: 'AppReviewsReviewsCollection';
  items: Array<Maybe<AppReview>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum AppReviewsReviewsCollectionOrder {
  ConnectedComponentAsc = 'connectedComponent_ASC',
  ConnectedComponentDesc = 'connectedComponent_DESC',
  IsCentredAsc = 'isCentred_ASC',
  IsCentredDesc = 'isCentred_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  StarsAsc = 'stars_ASC',
  StarsDesc = 'stars_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Represents a binary file in a space. An asset can be any file type. */
export type Asset = {
  __typename?: 'Asset';
  contentType?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: ContentfulMetadata;
  description?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  linkedFrom?: Maybe<AssetLinkingCollections>;
  size?: Maybe<Scalars['Int']['output']>;
  sys: Sys;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetContentTypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetFileNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetHeightArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetSizeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetUrlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  transform?: InputMaybe<ImageTransformOptions>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetWidthArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type AssetCollection = {
  __typename?: 'AssetCollection';
  items: Array<Maybe<Asset>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type AssetFilter = {
  AND?: InputMaybe<Array<InputMaybe<AssetFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AssetFilter>>>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  contentType_contains?: InputMaybe<Scalars['String']['input']>;
  contentType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentType_not?: InputMaybe<Scalars['String']['input']>;
  contentType_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentType_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileName_contains?: InputMaybe<Scalars['String']['input']>;
  fileName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fileName_not?: InputMaybe<Scalars['String']['input']>;
  fileName_not_contains?: InputMaybe<Scalars['String']['input']>;
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  height?: InputMaybe<Scalars['Int']['input']>;
  height_exists?: InputMaybe<Scalars['Boolean']['input']>;
  height_gt?: InputMaybe<Scalars['Int']['input']>;
  height_gte?: InputMaybe<Scalars['Int']['input']>;
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  height_lt?: InputMaybe<Scalars['Int']['input']>;
  height_lte?: InputMaybe<Scalars['Int']['input']>;
  height_not?: InputMaybe<Scalars['Int']['input']>;
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  size?: InputMaybe<Scalars['Int']['input']>;
  size_exists?: InputMaybe<Scalars['Boolean']['input']>;
  size_gt?: InputMaybe<Scalars['Int']['input']>;
  size_gte?: InputMaybe<Scalars['Int']['input']>;
  size_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  size_lt?: InputMaybe<Scalars['Int']['input']>;
  size_lte?: InputMaybe<Scalars['Int']['input']>;
  size_not?: InputMaybe<Scalars['Int']['input']>;
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_contains?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_not?: InputMaybe<Scalars['String']['input']>;
  url_not_contains?: InputMaybe<Scalars['String']['input']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  width?: InputMaybe<Scalars['Int']['input']>;
  width_exists?: InputMaybe<Scalars['Boolean']['input']>;
  width_gt?: InputMaybe<Scalars['Int']['input']>;
  width_gte?: InputMaybe<Scalars['Int']['input']>;
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  width_lt?: InputMaybe<Scalars['Int']['input']>;
  width_lte?: InputMaybe<Scalars['Int']['input']>;
  width_not?: InputMaybe<Scalars['Int']['input']>;
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type AssetLinkingCollections = {
  __typename?: 'AssetLinkingCollections';
  appBannerCollection?: Maybe<AppBannerCollection>;
  appReviewCollection?: Maybe<AppReviewCollection>;
  backToSchoolCollection?: Maybe<BackToSchoolCollection>;
  backToSchoolCostCategoryCollection?: Maybe<BackToSchoolCostCategoryCollection>;
  backToSchoolCountryDataCollection?: Maybe<BackToSchoolCountryDataCollection>;
  betterWayTopCollection?: Maybe<BetterWayTopCollection>;
  blogContentCollection?: Maybe<BlogContentCollection>;
  cardCollection?: Maybe<CardCollection>;
  cardsCollection?: Maybe<CardsCollection>;
  comparisonTableRowCollection?: Maybe<ComparisonTableRowCollection>;
  contextualPromoBannerCollection?: Maybe<ContextualPromoBannerCollection>;
  entryCollection?: Maybe<EntryCollection>;
  faqFeedbackModuleItemCollection?: Maybe<FaqFeedbackModuleItemCollection>;
  faqHeroCollection?: Maybe<FaqHeroCollection>;
  faqModuleCollection?: Maybe<FaqModuleCollection>;
  faqSearchHelpCollection?: Maybe<FaqSearchHelpCollection>;
  faqTopicsModuleItemsCollection?: Maybe<FaqTopicsModuleItemsCollection>;
  footerCollection?: Maybe<FooterCollection>;
  fullWidthBannerCollection?: Maybe<FullWidthBannerCollection>;
  heroSlotCollection?: Maybe<HeroSlotCollection>;
  howItWorksStepRelaunchCollection?: Maybe<HowItWorksStepRelaunchCollection>;
  iconWithTextCollection?: Maybe<IconWithTextCollection>;
  imageCollection?: Maybe<ImageCollection>;
  jazzCashRequestCollection?: Maybe<JazzCashRequestCollection>;
  layoutFooterCollection?: Maybe<LayoutFooterCollection>;
  layoutHeaderCollection?: Maybe<LayoutHeaderCollection>;
  linkCollection?: Maybe<LinkCollection>;
  loginPageCollection?: Maybe<LoginPageCollection>;
  logoCollection?: Maybe<LogoCollection>;
  memberCollection?: Maybe<MemberCollection>;
  mobileStoreDataCollection?: Maybe<MobileStoreDataCollection>;
  moneyRequestPayCollection?: Maybe<MoneyRequestPayCollection>;
  moneyTransferCardCollection?: Maybe<MoneyTransferCardCollection>;
  moneyTransferCollection?: Maybe<MoneyTransferCollection>;
  notFoundPageCollection?: Maybe<NotFoundPageCollection>;
  pageCollection?: Maybe<PageCollection>;
  payoutMethodAlertCollection?: Maybe<PayoutMethodAlertCollection>;
  payoutMethodCollection?: Maybe<PayoutMethodCollection>;
  personCollection?: Maybe<PersonCollection>;
  priceComparsionCollection?: Maybe<PriceComparsionCollection>;
  promotionalBannerCollection?: Maybe<PromotionalBannerCollection>;
  referFriendHeaderCollection?: Maybe<ReferFriendHeaderCollection>;
  requestMoneyNewRequestEmailCollection?: Maybe<RequestMoneyNewRequestEmailCollection>;
  reviewsCollection?: Maybe<ReviewsCollection>;
  sendingPartnershipCollection?: Maybe<SendingPartnershipCollection>;
  seoOrganizationStructuredDataCollection?: Maybe<SeoOrganizationStructuredDataCollection>;
  shareSocialLinkCollection?: Maybe<ShareSocialLinkCollection>;
  stepCollection?: Maybe<StepCollection>;
  stickyBannerCollection?: Maybe<StickyBannerCollection>;
  textMeTheAppModuleCollection?: Maybe<TextMeTheAppModuleCollection>;
  textSectionCollection?: Maybe<TextSectionCollection>;
  textWithColorCollection?: Maybe<TextWithColorCollection>;
  verificationInProgressCollection?: Maybe<VerificationInProgressCollection>;
  videoCollection?: Maybe<VideoCollection>;
  whyChooseCollection?: Maybe<WhyChooseCollection>;
  whyChooseReasonCollection?: Maybe<WhyChooseReasonCollection>;
};

export type AssetLinkingCollectionsAppBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsAppReviewCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsBackToSchoolCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsBackToSchoolCostCategoryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsBackToSchoolCountryDataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsBetterWayTopCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsBlogContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsComparisonTableRowCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsContextualPromoBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsFaqFeedbackModuleItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsFaqHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsFaqModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsFaqSearchHelpCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsFaqTopicsModuleItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsFooterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsFullWidthBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsHeroSlotCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsHowItWorksStepRelaunchCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsIconWithTextCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsJazzCashRequestCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsLayoutFooterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsLayoutHeaderCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsLoginPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsLogoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsMemberCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsMobileStoreDataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsMoneyRequestPayCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsMoneyTransferCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsMoneyTransferCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsNotFoundPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsPayoutMethodAlertCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsPayoutMethodCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsPersonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsPriceComparsionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsPromotionalBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsReferFriendHeaderCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsRequestMoneyNewRequestEmailCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsSendingPartnershipCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsSeoOrganizationStructuredDataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsShareSocialLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsStepCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsStickyBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsTextMeTheAppModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsTextSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsTextWithColorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsVerificationInProgressCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsVideoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsWhyChooseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsWhyChooseReasonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum AssetOrder {
  ContentTypeAsc = 'contentType_ASC',
  ContentTypeDesc = 'contentType_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
}

/** This is content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchool) */
export type BackToSchool = Entry &
  _Node & {
    __typename?: 'BackToSchool';
    _id: Scalars['ID']['output'];
    averageHouseholdIncomeLabel?: Maybe<Scalars['String']['output']>;
    categoriesCollection?: Maybe<BackToSchoolCategoriesCollection>;
    chooseCurrencyLabel?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    countriesCollection?: Maybe<BackToSchoolCountriesCollection>;
    hasCurrencyChart?: Maybe<Scalars['Boolean']['output']>;
    incomesHeading?: Maybe<Scalars['String']['output']>;
    incomesMessage?: Maybe<BackToSchoolIncomesMessage>;
    itemsListHeading?: Maybe<Scalars['String']['output']>;
    itemsListMessage?: Maybe<BackToSchoolItemsListMessage>;
    linkedFrom?: Maybe<BackToSchoolLinkingCollections>;
    mapSectionHeading?: Maybe<Scalars['String']['output']>;
    mapSectionImage?: Maybe<Asset>;
    mapSectionMessage?: Maybe<BackToSchoolMapSectionMessage>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    totalCostPerChildLabel?: Maybe<Scalars['String']['output']>;
    totalCostPerHouseholdLabel?: Maybe<Scalars['String']['output']>;
    totalSpendPerChildLabel?: Maybe<Scalars['String']['output']>;
  };

/** This is content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchool) */
export type BackToSchoolAverageHouseholdIncomeLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchool) */
export type BackToSchoolCategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<BackToSchoolCategoriesCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BackToSchoolCostCategoryFilter>;
};

/** This is content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchool) */
export type BackToSchoolChooseCurrencyLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchool) */
export type BackToSchoolCountriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<BackToSchoolCountriesCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BackToSchoolCountryDataFilter>;
};

/** This is content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchool) */
export type BackToSchoolHasCurrencyChartArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchool) */
export type BackToSchoolIncomesHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchool) */
export type BackToSchoolIncomesMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchool) */
export type BackToSchoolItemsListHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchool) */
export type BackToSchoolItemsListMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchool) */
export type BackToSchoolLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** This is content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchool) */
export type BackToSchoolMapSectionHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchool) */
export type BackToSchoolMapSectionImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** This is content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchool) */
export type BackToSchoolMapSectionMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchool) */
export type BackToSchoolNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchool) */
export type BackToSchoolTotalCostPerChildLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchool) */
export type BackToSchoolTotalCostPerHouseholdLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchool) */
export type BackToSchoolTotalSpendPerChildLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type BackToSchoolCategoriesCollection = {
  __typename?: 'BackToSchoolCategoriesCollection';
  items: Array<Maybe<BackToSchoolCostCategory>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum BackToSchoolCategoriesCollectionOrder {
  CategoryHexColourAsc = 'categoryHexColour_ASC',
  CategoryHexColourDesc = 'categoryHexColour_DESC',
  CategoryKeyAsc = 'categoryKey_ASC',
  CategoryKeyDesc = 'categoryKey_DESC',
  CategoryTitleAsc = 'categoryTitle_ASC',
  CategoryTitleDesc = 'categoryTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type BackToSchoolCollection = {
  __typename?: 'BackToSchoolCollection';
  items: Array<Maybe<BackToSchool>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

/** This is cost category content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchoolCostCategory) */
export type BackToSchoolCostCategory = Entry &
  _Node & {
    __typename?: 'BackToSchoolCostCategory';
    _id: Scalars['ID']['output'];
    categoryHexColour?: Maybe<Scalars['String']['output']>;
    categoryImage?: Maybe<Asset>;
    categoryKey?: Maybe<Scalars['String']['output']>;
    categoryTitle?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<BackToSchoolCostCategoryLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** This is cost category content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchoolCostCategory) */
export type BackToSchoolCostCategoryCategoryHexColourArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is cost category content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchoolCostCategory) */
export type BackToSchoolCostCategoryCategoryImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** This is cost category content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchoolCostCategory) */
export type BackToSchoolCostCategoryCategoryKeyArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is cost category content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchoolCostCategory) */
export type BackToSchoolCostCategoryCategoryTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is cost category content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchoolCostCategory) */
export type BackToSchoolCostCategoryLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** This is cost category content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchoolCostCategory) */
export type BackToSchoolCostCategoryNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type BackToSchoolCostCategoryCollection = {
  __typename?: 'BackToSchoolCostCategoryCollection';
  items: Array<Maybe<BackToSchoolCostCategory>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type BackToSchoolCostCategoryFilter = {
  AND?: InputMaybe<Array<InputMaybe<BackToSchoolCostCategoryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BackToSchoolCostCategoryFilter>>>;
  categoryHexColour?: InputMaybe<Scalars['String']['input']>;
  categoryHexColour_contains?: InputMaybe<Scalars['String']['input']>;
  categoryHexColour_exists?: InputMaybe<Scalars['Boolean']['input']>;
  categoryHexColour_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  categoryHexColour_not?: InputMaybe<Scalars['String']['input']>;
  categoryHexColour_not_contains?: InputMaybe<Scalars['String']['input']>;
  categoryHexColour_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  categoryImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  categoryKey?: InputMaybe<Scalars['String']['input']>;
  categoryKey_contains?: InputMaybe<Scalars['String']['input']>;
  categoryKey_exists?: InputMaybe<Scalars['Boolean']['input']>;
  categoryKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  categoryKey_not?: InputMaybe<Scalars['String']['input']>;
  categoryKey_not_contains?: InputMaybe<Scalars['String']['input']>;
  categoryKey_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  categoryTitle?: InputMaybe<Scalars['String']['input']>;
  categoryTitle_contains?: InputMaybe<Scalars['String']['input']>;
  categoryTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  categoryTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  categoryTitle_not?: InputMaybe<Scalars['String']['input']>;
  categoryTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  categoryTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type BackToSchoolCostCategoryLinkingCollections = {
  __typename?: 'BackToSchoolCostCategoryLinkingCollections';
  backToSchoolCollection?: Maybe<BackToSchoolCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type BackToSchoolCostCategoryLinkingCollectionsBackToSchoolCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<BackToSchoolCostCategoryLinkingCollectionsBackToSchoolCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type BackToSchoolCostCategoryLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum BackToSchoolCostCategoryLinkingCollectionsBackToSchoolCollectionOrder {
  AverageHouseholdIncomeLabelAsc = 'averageHouseholdIncomeLabel_ASC',
  AverageHouseholdIncomeLabelDesc = 'averageHouseholdIncomeLabel_DESC',
  ChooseCurrencyLabelAsc = 'chooseCurrencyLabel_ASC',
  ChooseCurrencyLabelDesc = 'chooseCurrencyLabel_DESC',
  HasCurrencyChartAsc = 'hasCurrencyChart_ASC',
  HasCurrencyChartDesc = 'hasCurrencyChart_DESC',
  IncomesHeadingAsc = 'incomesHeading_ASC',
  IncomesHeadingDesc = 'incomesHeading_DESC',
  ItemsListHeadingAsc = 'itemsListHeading_ASC',
  ItemsListHeadingDesc = 'itemsListHeading_DESC',
  MapSectionHeadingAsc = 'mapSectionHeading_ASC',
  MapSectionHeadingDesc = 'mapSectionHeading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TotalCostPerChildLabelAsc = 'totalCostPerChildLabel_ASC',
  TotalCostPerChildLabelDesc = 'totalCostPerChildLabel_DESC',
  TotalCostPerHouseholdLabelAsc = 'totalCostPerHouseholdLabel_ASC',
  TotalCostPerHouseholdLabelDesc = 'totalCostPerHouseholdLabel_DESC',
  TotalSpendPerChildLabelAsc = 'totalSpendPerChildLabel_ASC',
  TotalSpendPerChildLabelDesc = 'totalSpendPerChildLabel_DESC',
}

export enum BackToSchoolCostCategoryOrder {
  CategoryHexColourAsc = 'categoryHexColour_ASC',
  CategoryHexColourDesc = 'categoryHexColour_DESC',
  CategoryKeyAsc = 'categoryKey_ASC',
  CategoryKeyDesc = 'categoryKey_DESC',
  CategoryTitleAsc = 'categoryTitle_ASC',
  CategoryTitleDesc = 'categoryTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type BackToSchoolCountriesCollection = {
  __typename?: 'BackToSchoolCountriesCollection';
  items: Array<Maybe<BackToSchoolCountryData>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum BackToSchoolCountriesCollectionOrder {
  ChildrenRatioAsc = 'childrenRatio_ASC',
  ChildrenRatioDesc = 'childrenRatio_DESC',
  CircleRatioAsc = 'circleRatio_ASC',
  CircleRatioDesc = 'circleRatio_DESC',
  CountryAsc = 'country_ASC',
  CountryDesc = 'country_DESC',
  CurrencySymbolAsc = 'currencySymbol_ASC',
  CurrencySymbolDesc = 'currencySymbol_DESC',
  CurrencyAsc = 'currency_ASC',
  CurrencyDesc = 'currency_DESC',
  ExchangeRateAsc = 'exchangeRate_ASC',
  ExchangeRateDesc = 'exchangeRate_DESC',
  FlagXPositionAsc = 'flagXPosition_ASC',
  FlagXPositionDesc = 'flagXPosition_DESC',
  FlagYPositionAsc = 'flagYPosition_ASC',
  FlagYPositionDesc = 'flagYPosition_DESC',
  HouseholdIncomeAsc = 'householdIncome_ASC',
  HouseholdIncomeDesc = 'householdIncome_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TotalChildCostAsc = 'totalChildCost_ASC',
  TotalChildCostDesc = 'totalChildCost_DESC',
  TotalHouseholdCostAsc = 'totalHouseholdCost_ASC',
  TotalHouseholdCostDesc = 'totalHouseholdCost_DESC',
}

/** This is country content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchoolCountryData) */
export type BackToSchoolCountryData = Entry &
  _Node & {
    __typename?: 'BackToSchoolCountryData';
    _id: Scalars['ID']['output'];
    childrenRatio?: Maybe<Scalars['Float']['output']>;
    circleRatio?: Maybe<Scalars['Float']['output']>;
    contentfulMetadata: ContentfulMetadata;
    costs?: Maybe<Scalars['JSON']['output']>;
    country?: Maybe<Scalars['String']['output']>;
    countryFlag?: Maybe<Asset>;
    currency?: Maybe<Scalars['String']['output']>;
    currencySymbol?: Maybe<Scalars['String']['output']>;
    exchangeRate?: Maybe<Scalars['Float']['output']>;
    flagXPosition?: Maybe<Scalars['Float']['output']>;
    flagYPosition?: Maybe<Scalars['Float']['output']>;
    householdIncome?: Maybe<Scalars['Float']['output']>;
    link?: Maybe<Link>;
    linkedFrom?: Maybe<BackToSchoolCountryDataLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    totalChildCost?: Maybe<Scalars['Float']['output']>;
    totalHouseholdCost?: Maybe<Scalars['Float']['output']>;
  };

/** This is country content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchoolCountryData) */
export type BackToSchoolCountryDataChildrenRatioArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is country content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchoolCountryData) */
export type BackToSchoolCountryDataCircleRatioArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is country content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchoolCountryData) */
export type BackToSchoolCountryDataCostsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is country content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchoolCountryData) */
export type BackToSchoolCountryDataCountryArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is country content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchoolCountryData) */
export type BackToSchoolCountryDataCountryFlagArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** This is country content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchoolCountryData) */
export type BackToSchoolCountryDataCurrencyArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is country content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchoolCountryData) */
export type BackToSchoolCountryDataCurrencySymbolArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is country content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchoolCountryData) */
export type BackToSchoolCountryDataExchangeRateArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is country content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchoolCountryData) */
export type BackToSchoolCountryDataFlagXPositionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is country content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchoolCountryData) */
export type BackToSchoolCountryDataFlagYPositionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is country content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchoolCountryData) */
export type BackToSchoolCountryDataHouseholdIncomeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is country content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchoolCountryData) */
export type BackToSchoolCountryDataLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** This is country content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchoolCountryData) */
export type BackToSchoolCountryDataLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** This is country content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchoolCountryData) */
export type BackToSchoolCountryDataNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is country content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchoolCountryData) */
export type BackToSchoolCountryDataTotalChildCostArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This is country content for Back To School module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/backToSchoolCountryData) */
export type BackToSchoolCountryDataTotalHouseholdCostArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type BackToSchoolCountryDataCollection = {
  __typename?: 'BackToSchoolCountryDataCollection';
  items: Array<Maybe<BackToSchoolCountryData>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type BackToSchoolCountryDataFilter = {
  AND?: InputMaybe<Array<InputMaybe<BackToSchoolCountryDataFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BackToSchoolCountryDataFilter>>>;
  childrenRatio?: InputMaybe<Scalars['Float']['input']>;
  childrenRatio_exists?: InputMaybe<Scalars['Boolean']['input']>;
  childrenRatio_gt?: InputMaybe<Scalars['Float']['input']>;
  childrenRatio_gte?: InputMaybe<Scalars['Float']['input']>;
  childrenRatio_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  childrenRatio_lt?: InputMaybe<Scalars['Float']['input']>;
  childrenRatio_lte?: InputMaybe<Scalars['Float']['input']>;
  childrenRatio_not?: InputMaybe<Scalars['Float']['input']>;
  childrenRatio_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
  circleRatio?: InputMaybe<Scalars['Float']['input']>;
  circleRatio_exists?: InputMaybe<Scalars['Boolean']['input']>;
  circleRatio_gt?: InputMaybe<Scalars['Float']['input']>;
  circleRatio_gte?: InputMaybe<Scalars['Float']['input']>;
  circleRatio_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  circleRatio_lt?: InputMaybe<Scalars['Float']['input']>;
  circleRatio_lte?: InputMaybe<Scalars['Float']['input']>;
  circleRatio_not?: InputMaybe<Scalars['Float']['input']>;
  circleRatio_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  costs_exists?: InputMaybe<Scalars['Boolean']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  countryFlag_exists?: InputMaybe<Scalars['Boolean']['input']>;
  country_contains?: InputMaybe<Scalars['String']['input']>;
  country_exists?: InputMaybe<Scalars['Boolean']['input']>;
  country_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  country_not?: InputMaybe<Scalars['String']['input']>;
  country_not_contains?: InputMaybe<Scalars['String']['input']>;
  country_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  currency?: InputMaybe<Scalars['String']['input']>;
  currencySymbol?: InputMaybe<Scalars['String']['input']>;
  currencySymbol_contains?: InputMaybe<Scalars['String']['input']>;
  currencySymbol_exists?: InputMaybe<Scalars['Boolean']['input']>;
  currencySymbol_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  currencySymbol_not?: InputMaybe<Scalars['String']['input']>;
  currencySymbol_not_contains?: InputMaybe<Scalars['String']['input']>;
  currencySymbol_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  currency_contains?: InputMaybe<Scalars['String']['input']>;
  currency_exists?: InputMaybe<Scalars['Boolean']['input']>;
  currency_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  currency_not?: InputMaybe<Scalars['String']['input']>;
  currency_not_contains?: InputMaybe<Scalars['String']['input']>;
  currency_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exchangeRate?: InputMaybe<Scalars['Float']['input']>;
  exchangeRate_exists?: InputMaybe<Scalars['Boolean']['input']>;
  exchangeRate_gt?: InputMaybe<Scalars['Float']['input']>;
  exchangeRate_gte?: InputMaybe<Scalars['Float']['input']>;
  exchangeRate_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  exchangeRate_lt?: InputMaybe<Scalars['Float']['input']>;
  exchangeRate_lte?: InputMaybe<Scalars['Float']['input']>;
  exchangeRate_not?: InputMaybe<Scalars['Float']['input']>;
  exchangeRate_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
  flagXPosition?: InputMaybe<Scalars['Float']['input']>;
  flagXPosition_exists?: InputMaybe<Scalars['Boolean']['input']>;
  flagXPosition_gt?: InputMaybe<Scalars['Float']['input']>;
  flagXPosition_gte?: InputMaybe<Scalars['Float']['input']>;
  flagXPosition_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  flagXPosition_lt?: InputMaybe<Scalars['Float']['input']>;
  flagXPosition_lte?: InputMaybe<Scalars['Float']['input']>;
  flagXPosition_not?: InputMaybe<Scalars['Float']['input']>;
  flagXPosition_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
  flagYPosition?: InputMaybe<Scalars['Float']['input']>;
  flagYPosition_exists?: InputMaybe<Scalars['Boolean']['input']>;
  flagYPosition_gt?: InputMaybe<Scalars['Float']['input']>;
  flagYPosition_gte?: InputMaybe<Scalars['Float']['input']>;
  flagYPosition_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  flagYPosition_lt?: InputMaybe<Scalars['Float']['input']>;
  flagYPosition_lte?: InputMaybe<Scalars['Float']['input']>;
  flagYPosition_not?: InputMaybe<Scalars['Float']['input']>;
  flagYPosition_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
  householdIncome?: InputMaybe<Scalars['Float']['input']>;
  householdIncome_exists?: InputMaybe<Scalars['Boolean']['input']>;
  householdIncome_gt?: InputMaybe<Scalars['Float']['input']>;
  householdIncome_gte?: InputMaybe<Scalars['Float']['input']>;
  householdIncome_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  householdIncome_lt?: InputMaybe<Scalars['Float']['input']>;
  householdIncome_lte?: InputMaybe<Scalars['Float']['input']>;
  householdIncome_not?: InputMaybe<Scalars['Float']['input']>;
  householdIncome_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
  link?: InputMaybe<CfLinkNestedFilter>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  totalChildCost?: InputMaybe<Scalars['Float']['input']>;
  totalChildCost_exists?: InputMaybe<Scalars['Boolean']['input']>;
  totalChildCost_gt?: InputMaybe<Scalars['Float']['input']>;
  totalChildCost_gte?: InputMaybe<Scalars['Float']['input']>;
  totalChildCost_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  totalChildCost_lt?: InputMaybe<Scalars['Float']['input']>;
  totalChildCost_lte?: InputMaybe<Scalars['Float']['input']>;
  totalChildCost_not?: InputMaybe<Scalars['Float']['input']>;
  totalChildCost_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
  totalHouseholdCost?: InputMaybe<Scalars['Float']['input']>;
  totalHouseholdCost_exists?: InputMaybe<Scalars['Boolean']['input']>;
  totalHouseholdCost_gt?: InputMaybe<Scalars['Float']['input']>;
  totalHouseholdCost_gte?: InputMaybe<Scalars['Float']['input']>;
  totalHouseholdCost_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
  totalHouseholdCost_lt?: InputMaybe<Scalars['Float']['input']>;
  totalHouseholdCost_lte?: InputMaybe<Scalars['Float']['input']>;
  totalHouseholdCost_not?: InputMaybe<Scalars['Float']['input']>;
  totalHouseholdCost_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
};

export type BackToSchoolCountryDataLinkingCollections = {
  __typename?: 'BackToSchoolCountryDataLinkingCollections';
  backToSchoolCollection?: Maybe<BackToSchoolCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type BackToSchoolCountryDataLinkingCollectionsBackToSchoolCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<BackToSchoolCountryDataLinkingCollectionsBackToSchoolCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type BackToSchoolCountryDataLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum BackToSchoolCountryDataLinkingCollectionsBackToSchoolCollectionOrder {
  AverageHouseholdIncomeLabelAsc = 'averageHouseholdIncomeLabel_ASC',
  AverageHouseholdIncomeLabelDesc = 'averageHouseholdIncomeLabel_DESC',
  ChooseCurrencyLabelAsc = 'chooseCurrencyLabel_ASC',
  ChooseCurrencyLabelDesc = 'chooseCurrencyLabel_DESC',
  HasCurrencyChartAsc = 'hasCurrencyChart_ASC',
  HasCurrencyChartDesc = 'hasCurrencyChart_DESC',
  IncomesHeadingAsc = 'incomesHeading_ASC',
  IncomesHeadingDesc = 'incomesHeading_DESC',
  ItemsListHeadingAsc = 'itemsListHeading_ASC',
  ItemsListHeadingDesc = 'itemsListHeading_DESC',
  MapSectionHeadingAsc = 'mapSectionHeading_ASC',
  MapSectionHeadingDesc = 'mapSectionHeading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TotalCostPerChildLabelAsc = 'totalCostPerChildLabel_ASC',
  TotalCostPerChildLabelDesc = 'totalCostPerChildLabel_DESC',
  TotalCostPerHouseholdLabelAsc = 'totalCostPerHouseholdLabel_ASC',
  TotalCostPerHouseholdLabelDesc = 'totalCostPerHouseholdLabel_DESC',
  TotalSpendPerChildLabelAsc = 'totalSpendPerChildLabel_ASC',
  TotalSpendPerChildLabelDesc = 'totalSpendPerChildLabel_DESC',
}

export enum BackToSchoolCountryDataOrder {
  ChildrenRatioAsc = 'childrenRatio_ASC',
  ChildrenRatioDesc = 'childrenRatio_DESC',
  CircleRatioAsc = 'circleRatio_ASC',
  CircleRatioDesc = 'circleRatio_DESC',
  CountryAsc = 'country_ASC',
  CountryDesc = 'country_DESC',
  CurrencySymbolAsc = 'currencySymbol_ASC',
  CurrencySymbolDesc = 'currencySymbol_DESC',
  CurrencyAsc = 'currency_ASC',
  CurrencyDesc = 'currency_DESC',
  ExchangeRateAsc = 'exchangeRate_ASC',
  ExchangeRateDesc = 'exchangeRate_DESC',
  FlagXPositionAsc = 'flagXPosition_ASC',
  FlagXPositionDesc = 'flagXPosition_DESC',
  FlagYPositionAsc = 'flagYPosition_ASC',
  FlagYPositionDesc = 'flagYPosition_DESC',
  HouseholdIncomeAsc = 'householdIncome_ASC',
  HouseholdIncomeDesc = 'householdIncome_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TotalChildCostAsc = 'totalChildCost_ASC',
  TotalChildCostDesc = 'totalChildCost_DESC',
  TotalHouseholdCostAsc = 'totalHouseholdCost_ASC',
  TotalHouseholdCostDesc = 'totalHouseholdCost_DESC',
}

export type BackToSchoolFilter = {
  AND?: InputMaybe<Array<InputMaybe<BackToSchoolFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BackToSchoolFilter>>>;
  averageHouseholdIncomeLabel?: InputMaybe<Scalars['String']['input']>;
  averageHouseholdIncomeLabel_contains?: InputMaybe<Scalars['String']['input']>;
  averageHouseholdIncomeLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  averageHouseholdIncomeLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  averageHouseholdIncomeLabel_not?: InputMaybe<Scalars['String']['input']>;
  averageHouseholdIncomeLabel_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  averageHouseholdIncomeLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  categories?: InputMaybe<CfBackToSchoolCostCategoryNestedFilter>;
  categoriesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  chooseCurrencyLabel?: InputMaybe<Scalars['String']['input']>;
  chooseCurrencyLabel_contains?: InputMaybe<Scalars['String']['input']>;
  chooseCurrencyLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  chooseCurrencyLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  chooseCurrencyLabel_not?: InputMaybe<Scalars['String']['input']>;
  chooseCurrencyLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  chooseCurrencyLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  countries?: InputMaybe<CfBackToSchoolCountryDataNestedFilter>;
  countriesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hasCurrencyChart?: InputMaybe<Scalars['Boolean']['input']>;
  hasCurrencyChart_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hasCurrencyChart_not?: InputMaybe<Scalars['Boolean']['input']>;
  incomesHeading?: InputMaybe<Scalars['String']['input']>;
  incomesHeading_contains?: InputMaybe<Scalars['String']['input']>;
  incomesHeading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  incomesHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  incomesHeading_not?: InputMaybe<Scalars['String']['input']>;
  incomesHeading_not_contains?: InputMaybe<Scalars['String']['input']>;
  incomesHeading_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  incomesMessage_contains?: InputMaybe<Scalars['String']['input']>;
  incomesMessage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  incomesMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  itemsListHeading?: InputMaybe<Scalars['String']['input']>;
  itemsListHeading_contains?: InputMaybe<Scalars['String']['input']>;
  itemsListHeading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  itemsListHeading_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  itemsListHeading_not?: InputMaybe<Scalars['String']['input']>;
  itemsListHeading_not_contains?: InputMaybe<Scalars['String']['input']>;
  itemsListHeading_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  itemsListMessage_contains?: InputMaybe<Scalars['String']['input']>;
  itemsListMessage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  itemsListMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  mapSectionHeading?: InputMaybe<Scalars['String']['input']>;
  mapSectionHeading_contains?: InputMaybe<Scalars['String']['input']>;
  mapSectionHeading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mapSectionHeading_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  mapSectionHeading_not?: InputMaybe<Scalars['String']['input']>;
  mapSectionHeading_not_contains?: InputMaybe<Scalars['String']['input']>;
  mapSectionHeading_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  mapSectionImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mapSectionMessage_contains?: InputMaybe<Scalars['String']['input']>;
  mapSectionMessage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mapSectionMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  totalCostPerChildLabel?: InputMaybe<Scalars['String']['input']>;
  totalCostPerChildLabel_contains?: InputMaybe<Scalars['String']['input']>;
  totalCostPerChildLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  totalCostPerChildLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  totalCostPerChildLabel_not?: InputMaybe<Scalars['String']['input']>;
  totalCostPerChildLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  totalCostPerChildLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  totalCostPerHouseholdLabel?: InputMaybe<Scalars['String']['input']>;
  totalCostPerHouseholdLabel_contains?: InputMaybe<Scalars['String']['input']>;
  totalCostPerHouseholdLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  totalCostPerHouseholdLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  totalCostPerHouseholdLabel_not?: InputMaybe<Scalars['String']['input']>;
  totalCostPerHouseholdLabel_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  totalCostPerHouseholdLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  totalSpendPerChildLabel?: InputMaybe<Scalars['String']['input']>;
  totalSpendPerChildLabel_contains?: InputMaybe<Scalars['String']['input']>;
  totalSpendPerChildLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  totalSpendPerChildLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  totalSpendPerChildLabel_not?: InputMaybe<Scalars['String']['input']>;
  totalSpendPerChildLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  totalSpendPerChildLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
};

export type BackToSchoolIncomesMessage = {
  __typename?: 'BackToSchoolIncomesMessage';
  json: Scalars['JSON']['output'];
  links: BackToSchoolIncomesMessageLinks;
};

export type BackToSchoolIncomesMessageAssets = {
  __typename?: 'BackToSchoolIncomesMessageAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type BackToSchoolIncomesMessageEntries = {
  __typename?: 'BackToSchoolIncomesMessageEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type BackToSchoolIncomesMessageLinks = {
  __typename?: 'BackToSchoolIncomesMessageLinks';
  assets: BackToSchoolIncomesMessageAssets;
  entries: BackToSchoolIncomesMessageEntries;
  resources: BackToSchoolIncomesMessageResources;
};

export type BackToSchoolIncomesMessageResources = {
  __typename?: 'BackToSchoolIncomesMessageResources';
  block: Array<BackToSchoolIncomesMessageResourcesBlock>;
  hyperlink: Array<BackToSchoolIncomesMessageResourcesHyperlink>;
  inline: Array<BackToSchoolIncomesMessageResourcesInline>;
};

export type BackToSchoolIncomesMessageResourcesBlock = ResourceLink & {
  __typename?: 'BackToSchoolIncomesMessageResourcesBlock';
  sys: ResourceSys;
};

export type BackToSchoolIncomesMessageResourcesHyperlink = ResourceLink & {
  __typename?: 'BackToSchoolIncomesMessageResourcesHyperlink';
  sys: ResourceSys;
};

export type BackToSchoolIncomesMessageResourcesInline = ResourceLink & {
  __typename?: 'BackToSchoolIncomesMessageResourcesInline';
  sys: ResourceSys;
};

export type BackToSchoolItemsListMessage = {
  __typename?: 'BackToSchoolItemsListMessage';
  json: Scalars['JSON']['output'];
  links: BackToSchoolItemsListMessageLinks;
};

export type BackToSchoolItemsListMessageAssets = {
  __typename?: 'BackToSchoolItemsListMessageAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type BackToSchoolItemsListMessageEntries = {
  __typename?: 'BackToSchoolItemsListMessageEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type BackToSchoolItemsListMessageLinks = {
  __typename?: 'BackToSchoolItemsListMessageLinks';
  assets: BackToSchoolItemsListMessageAssets;
  entries: BackToSchoolItemsListMessageEntries;
  resources: BackToSchoolItemsListMessageResources;
};

export type BackToSchoolItemsListMessageResources = {
  __typename?: 'BackToSchoolItemsListMessageResources';
  block: Array<BackToSchoolItemsListMessageResourcesBlock>;
  hyperlink: Array<BackToSchoolItemsListMessageResourcesHyperlink>;
  inline: Array<BackToSchoolItemsListMessageResourcesInline>;
};

export type BackToSchoolItemsListMessageResourcesBlock = ResourceLink & {
  __typename?: 'BackToSchoolItemsListMessageResourcesBlock';
  sys: ResourceSys;
};

export type BackToSchoolItemsListMessageResourcesHyperlink = ResourceLink & {
  __typename?: 'BackToSchoolItemsListMessageResourcesHyperlink';
  sys: ResourceSys;
};

export type BackToSchoolItemsListMessageResourcesInline = ResourceLink & {
  __typename?: 'BackToSchoolItemsListMessageResourcesInline';
  sys: ResourceSys;
};

export type BackToSchoolLinkingCollections = {
  __typename?: 'BackToSchoolLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type BackToSchoolLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type BackToSchoolMapSectionMessage = {
  __typename?: 'BackToSchoolMapSectionMessage';
  json: Scalars['JSON']['output'];
  links: BackToSchoolMapSectionMessageLinks;
};

export type BackToSchoolMapSectionMessageAssets = {
  __typename?: 'BackToSchoolMapSectionMessageAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type BackToSchoolMapSectionMessageEntries = {
  __typename?: 'BackToSchoolMapSectionMessageEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type BackToSchoolMapSectionMessageLinks = {
  __typename?: 'BackToSchoolMapSectionMessageLinks';
  assets: BackToSchoolMapSectionMessageAssets;
  entries: BackToSchoolMapSectionMessageEntries;
  resources: BackToSchoolMapSectionMessageResources;
};

export type BackToSchoolMapSectionMessageResources = {
  __typename?: 'BackToSchoolMapSectionMessageResources';
  block: Array<BackToSchoolMapSectionMessageResourcesBlock>;
  hyperlink: Array<BackToSchoolMapSectionMessageResourcesHyperlink>;
  inline: Array<BackToSchoolMapSectionMessageResourcesInline>;
};

export type BackToSchoolMapSectionMessageResourcesBlock = ResourceLink & {
  __typename?: 'BackToSchoolMapSectionMessageResourcesBlock';
  sys: ResourceSys;
};

export type BackToSchoolMapSectionMessageResourcesHyperlink = ResourceLink & {
  __typename?: 'BackToSchoolMapSectionMessageResourcesHyperlink';
  sys: ResourceSys;
};

export type BackToSchoolMapSectionMessageResourcesInline = ResourceLink & {
  __typename?: 'BackToSchoolMapSectionMessageResourcesInline';
  sys: ResourceSys;
};

export enum BackToSchoolOrder {
  AverageHouseholdIncomeLabelAsc = 'averageHouseholdIncomeLabel_ASC',
  AverageHouseholdIncomeLabelDesc = 'averageHouseholdIncomeLabel_DESC',
  ChooseCurrencyLabelAsc = 'chooseCurrencyLabel_ASC',
  ChooseCurrencyLabelDesc = 'chooseCurrencyLabel_DESC',
  HasCurrencyChartAsc = 'hasCurrencyChart_ASC',
  HasCurrencyChartDesc = 'hasCurrencyChart_DESC',
  IncomesHeadingAsc = 'incomesHeading_ASC',
  IncomesHeadingDesc = 'incomesHeading_DESC',
  ItemsListHeadingAsc = 'itemsListHeading_ASC',
  ItemsListHeadingDesc = 'itemsListHeading_DESC',
  MapSectionHeadingAsc = 'mapSectionHeading_ASC',
  MapSectionHeadingDesc = 'mapSectionHeading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TotalCostPerChildLabelAsc = 'totalCostPerChildLabel_ASC',
  TotalCostPerChildLabelDesc = 'totalCostPerChildLabel_DESC',
  TotalCostPerHouseholdLabelAsc = 'totalCostPerHouseholdLabel_ASC',
  TotalCostPerHouseholdLabelDesc = 'totalCostPerHouseholdLabel_DESC',
  TotalSpendPerChildLabelAsc = 'totalSpendPerChildLabel_ASC',
  TotalSpendPerChildLabelDesc = 'totalSpendPerChildLabel_DESC',
}

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/betterWayTop) */
export type BetterWayTop = Entry &
  _Node & {
    __typename?: 'BetterWayTop';
    _id: Scalars['ID']['output'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    backgroundColor?: Maybe<Scalars['String']['output']>;
    backgroundImage?: Maybe<Asset>;
    backgroundImageMobile?: Maybe<Asset>;
    calculatorPage?: Maybe<CalculatorPage>;
    contentAlignment?: Maybe<Scalars['String']['output']>;
    contentFlexDirection?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    foregroundImage?: Maybe<Asset>;
    heading?: Maybe<BetterWayTopHeading>;
    headingColor?: Maybe<Scalars['String']['output']>;
    hideDescriptionOnMobile?: Maybe<Scalars['Boolean']['output']>;
    link?: Maybe<Link>;
    linkedFrom?: Maybe<BetterWayTopLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    pricingCalculator?: Maybe<Entry>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    rightModule?: Maybe<BetterWayTopRightModule>;
    secondBackgroundColor?: Maybe<Scalars['String']['output']>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    subheading?: Maybe<BetterWayTopSubheading>;
    sys: Sys;
    trustpilotLink?: Maybe<Link>;
    trustpilotLogo?: Maybe<Asset>;
    trustpilotMessage?: Maybe<Scalars['String']['output']>;
  };

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/betterWayTop) */
export type BetterWayTopAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/betterWayTop) */
export type BetterWayTopAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/betterWayTop) */
export type BetterWayTopBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/betterWayTop) */
export type BetterWayTopBackgroundImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/betterWayTop) */
export type BetterWayTopBackgroundImageMobileArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/betterWayTop) */
export type BetterWayTopCalculatorPageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CalculatorPageFilter>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/betterWayTop) */
export type BetterWayTopContentAlignmentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/betterWayTop) */
export type BetterWayTopContentFlexDirectionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/betterWayTop) */
export type BetterWayTopForegroundImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/betterWayTop) */
export type BetterWayTopHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/betterWayTop) */
export type BetterWayTopHeadingColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/betterWayTop) */
export type BetterWayTopHideDescriptionOnMobileArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/betterWayTop) */
export type BetterWayTopLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/betterWayTop) */
export type BetterWayTopLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/betterWayTop) */
export type BetterWayTopNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/betterWayTop) */
export type BetterWayTopPricingCalculatorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/betterWayTop) */
export type BetterWayTopReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/betterWayTop) */
export type BetterWayTopRightModuleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/betterWayTop) */
export type BetterWayTopSecondBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/betterWayTop) */
export type BetterWayTopSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/betterWayTop) */
export type BetterWayTopSubheadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/betterWayTop) */
export type BetterWayTopTrustpilotLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/betterWayTop) */
export type BetterWayTopTrustpilotLogoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/betterWayTop) */
export type BetterWayTopTrustpilotMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type BetterWayTopCollection = {
  __typename?: 'BetterWayTopCollection';
  items: Array<Maybe<BetterWayTop>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type BetterWayTopFilter = {
  AND?: InputMaybe<Array<InputMaybe<BetterWayTopFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BetterWayTopFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundColor_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundImageMobile_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  calculatorPage?: InputMaybe<CfCalculatorPageNestedFilter>;
  calculatorPage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentAlignment?: InputMaybe<Scalars['String']['input']>;
  contentAlignment_contains?: InputMaybe<Scalars['String']['input']>;
  contentAlignment_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentAlignment_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentAlignment_not?: InputMaybe<Scalars['String']['input']>;
  contentAlignment_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentAlignment_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentFlexDirection?: InputMaybe<Scalars['String']['input']>;
  contentFlexDirection_contains?: InputMaybe<Scalars['String']['input']>;
  contentFlexDirection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentFlexDirection_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentFlexDirection_not?: InputMaybe<Scalars['String']['input']>;
  contentFlexDirection_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentFlexDirection_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  foregroundImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headingColor?: InputMaybe<Scalars['String']['input']>;
  headingColor_contains?: InputMaybe<Scalars['String']['input']>;
  headingColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headingColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headingColor_not?: InputMaybe<Scalars['String']['input']>;
  headingColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  headingColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  heading_contains?: InputMaybe<Scalars['String']['input']>;
  heading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heading_not_contains?: InputMaybe<Scalars['String']['input']>;
  hideDescriptionOnMobile?: InputMaybe<Scalars['Boolean']['input']>;
  hideDescriptionOnMobile_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hideDescriptionOnMobile_not?: InputMaybe<Scalars['Boolean']['input']>;
  link?: InputMaybe<CfLinkNestedFilter>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pricingCalculator_exists?: InputMaybe<Scalars['Boolean']['input']>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  rightModule_exists?: InputMaybe<Scalars['Boolean']['input']>;
  secondBackgroundColor?: InputMaybe<Scalars['String']['input']>;
  secondBackgroundColor_contains?: InputMaybe<Scalars['String']['input']>;
  secondBackgroundColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  secondBackgroundColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  secondBackgroundColor_not?: InputMaybe<Scalars['String']['input']>;
  secondBackgroundColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  secondBackgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subheading_contains?: InputMaybe<Scalars['String']['input']>;
  subheading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subheading_not_contains?: InputMaybe<Scalars['String']['input']>;
  sys?: InputMaybe<SysFilter>;
  trustpilotLink?: InputMaybe<CfLinkNestedFilter>;
  trustpilotLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  trustpilotLogo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  trustpilotMessage?: InputMaybe<Scalars['String']['input']>;
  trustpilotMessage_contains?: InputMaybe<Scalars['String']['input']>;
  trustpilotMessage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  trustpilotMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  trustpilotMessage_not?: InputMaybe<Scalars['String']['input']>;
  trustpilotMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  trustpilotMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
};

export type BetterWayTopHeading = {
  __typename?: 'BetterWayTopHeading';
  json: Scalars['JSON']['output'];
  links: BetterWayTopHeadingLinks;
};

export type BetterWayTopHeadingAssets = {
  __typename?: 'BetterWayTopHeadingAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type BetterWayTopHeadingEntries = {
  __typename?: 'BetterWayTopHeadingEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type BetterWayTopHeadingLinks = {
  __typename?: 'BetterWayTopHeadingLinks';
  assets: BetterWayTopHeadingAssets;
  entries: BetterWayTopHeadingEntries;
  resources: BetterWayTopHeadingResources;
};

export type BetterWayTopHeadingResources = {
  __typename?: 'BetterWayTopHeadingResources';
  block: Array<BetterWayTopHeadingResourcesBlock>;
  hyperlink: Array<BetterWayTopHeadingResourcesHyperlink>;
  inline: Array<BetterWayTopHeadingResourcesInline>;
};

export type BetterWayTopHeadingResourcesBlock = ResourceLink & {
  __typename?: 'BetterWayTopHeadingResourcesBlock';
  sys: ResourceSys;
};

export type BetterWayTopHeadingResourcesHyperlink = ResourceLink & {
  __typename?: 'BetterWayTopHeadingResourcesHyperlink';
  sys: ResourceSys;
};

export type BetterWayTopHeadingResourcesInline = ResourceLink & {
  __typename?: 'BetterWayTopHeadingResourcesInline';
  sys: ResourceSys;
};

export type BetterWayTopLinkingCollections = {
  __typename?: 'BetterWayTopLinkingCollections';
  calculatorPageCollection?: Maybe<CalculatorPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type BetterWayTopLinkingCollectionsCalculatorPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<BetterWayTopLinkingCollectionsCalculatorPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type BetterWayTopLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum BetterWayTopLinkingCollectionsCalculatorPageCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IsTopPageAsc = 'isTopPage_ASC',
  IsTopPageDesc = 'isTopPage_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum BetterWayTopOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentAlignmentAsc = 'contentAlignment_ASC',
  ContentAlignmentDesc = 'contentAlignment_DESC',
  ContentFlexDirectionAsc = 'contentFlexDirection_ASC',
  ContentFlexDirectionDesc = 'contentFlexDirection_DESC',
  HeadingColorAsc = 'headingColor_ASC',
  HeadingColorDesc = 'headingColor_DESC',
  HideDescriptionOnMobileAsc = 'hideDescriptionOnMobile_ASC',
  HideDescriptionOnMobileDesc = 'hideDescriptionOnMobile_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrustpilotMessageAsc = 'trustpilotMessage_ASC',
  TrustpilotMessageDesc = 'trustpilotMessage_DESC',
}

export type BetterWayTopRightModule = Calculator | Search | SendToCountryModule;

export type BetterWayTopSubheading = {
  __typename?: 'BetterWayTopSubheading';
  json: Scalars['JSON']['output'];
  links: BetterWayTopSubheadingLinks;
};

export type BetterWayTopSubheadingAssets = {
  __typename?: 'BetterWayTopSubheadingAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type BetterWayTopSubheadingEntries = {
  __typename?: 'BetterWayTopSubheadingEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type BetterWayTopSubheadingLinks = {
  __typename?: 'BetterWayTopSubheadingLinks';
  assets: BetterWayTopSubheadingAssets;
  entries: BetterWayTopSubheadingEntries;
  resources: BetterWayTopSubheadingResources;
};

export type BetterWayTopSubheadingResources = {
  __typename?: 'BetterWayTopSubheadingResources';
  block: Array<BetterWayTopSubheadingResourcesBlock>;
  hyperlink: Array<BetterWayTopSubheadingResourcesHyperlink>;
  inline: Array<BetterWayTopSubheadingResourcesInline>;
};

export type BetterWayTopSubheadingResourcesBlock = ResourceLink & {
  __typename?: 'BetterWayTopSubheadingResourcesBlock';
  sys: ResourceSys;
};

export type BetterWayTopSubheadingResourcesHyperlink = ResourceLink & {
  __typename?: 'BetterWayTopSubheadingResourcesHyperlink';
  sys: ResourceSys;
};

export type BetterWayTopSubheadingResourcesInline = ResourceLink & {
  __typename?: 'BetterWayTopSubheadingResourcesInline';
  sys: ResourceSys;
};

/** [web-cms]: test [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/blogContent) */
export type BlogContent = Entry &
  _Node & {
    __typename?: 'BlogContent';
    _id: Scalars['ID']['output'];
    author?: Maybe<Person>;
    content?: Maybe<BlogContentContent>;
    contentfulMetadata: ContentfulMetadata;
    image?: Maybe<Asset>;
    linkedFrom?: Maybe<BlogContentLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    publishedDate?: Maybe<Scalars['DateTime']['output']>;
    sys: Sys;
    timeToRead?: Maybe<Scalars['String']['output']>;
    updatedDate?: Maybe<Scalars['DateTime']['output']>;
  };

/** [web-cms]: test [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/blogContent) */
export type BlogContentAuthorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PersonFilter>;
};

/** [web-cms]: test [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/blogContent) */
export type BlogContentContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms]: test [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/blogContent) */
export type BlogContentImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [web-cms]: test [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/blogContent) */
export type BlogContentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-cms]: test [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/blogContent) */
export type BlogContentNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms]: test [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/blogContent) */
export type BlogContentPublishedDateArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms]: test [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/blogContent) */
export type BlogContentTimeToReadArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms]: test [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/blogContent) */
export type BlogContentUpdatedDateArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type BlogContentCollection = {
  __typename?: 'BlogContentCollection';
  items: Array<Maybe<BlogContent>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type BlogContentContent = {
  __typename?: 'BlogContentContent';
  json: Scalars['JSON']['output'];
  links: BlogContentContentLinks;
};

export type BlogContentContentAssets = {
  __typename?: 'BlogContentContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type BlogContentContentEntries = {
  __typename?: 'BlogContentContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type BlogContentContentLinks = {
  __typename?: 'BlogContentContentLinks';
  assets: BlogContentContentAssets;
  entries: BlogContentContentEntries;
  resources: BlogContentContentResources;
};

export type BlogContentContentResources = {
  __typename?: 'BlogContentContentResources';
  block: Array<BlogContentContentResourcesBlock>;
  hyperlink: Array<BlogContentContentResourcesHyperlink>;
  inline: Array<BlogContentContentResourcesInline>;
};

export type BlogContentContentResourcesBlock = ResourceLink & {
  __typename?: 'BlogContentContentResourcesBlock';
  sys: ResourceSys;
};

export type BlogContentContentResourcesHyperlink = ResourceLink & {
  __typename?: 'BlogContentContentResourcesHyperlink';
  sys: ResourceSys;
};

export type BlogContentContentResourcesInline = ResourceLink & {
  __typename?: 'BlogContentContentResourcesInline';
  sys: ResourceSys;
};

export type BlogContentFilter = {
  AND?: InputMaybe<Array<InputMaybe<BlogContentFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BlogContentFilter>>>;
  author?: InputMaybe<CfPersonNestedFilter>;
  author_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_contains?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  publishedDate_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publishedDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  publishedDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  publishedDate_in?: InputMaybe<
    Array<InputMaybe<Scalars['DateTime']['input']>>
  >;
  publishedDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  publishedDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  publishedDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  publishedDate_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['DateTime']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  timeToRead?: InputMaybe<Scalars['String']['input']>;
  timeToRead_contains?: InputMaybe<Scalars['String']['input']>;
  timeToRead_exists?: InputMaybe<Scalars['Boolean']['input']>;
  timeToRead_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  timeToRead_not?: InputMaybe<Scalars['String']['input']>;
  timeToRead_not_contains?: InputMaybe<Scalars['String']['input']>;
  timeToRead_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updatedDate?: InputMaybe<Scalars['DateTime']['input']>;
  updatedDate_exists?: InputMaybe<Scalars['Boolean']['input']>;
  updatedDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedDate_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['DateTime']['input']>>
  >;
};

export type BlogContentLinkingCollections = {
  __typename?: 'BlogContentLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type BlogContentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum BlogContentOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimeToReadAsc = 'timeToRead_ASC',
  TimeToReadDesc = 'timeToRead_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/bodyText) */
export type BodyText = Entry &
  _Node & {
    __typename?: 'BodyText';
    _id: Scalars['ID']['output'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    content?: Maybe<BodyTextContent>;
    contentAlign?: Maybe<Scalars['String']['output']>;
    contentTwo?: Maybe<BodyTextContentTwo>;
    contentfulMetadata: ContentfulMetadata;
    heading?: Maybe<BodyTextHeading>;
    headingAlign?: Maybe<Scalars['String']['output']>;
    isPageSection?: Maybe<Scalars['Boolean']['output']>;
    linkedFrom?: Maybe<BodyTextLinkingCollections>;
    listIndent?: Maybe<Scalars['Int']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    paragraphIndent?: Maybe<Scalars['Int']['output']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sys: Sys;
  };

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/bodyText) */
export type BodyTextAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/bodyText) */
export type BodyTextAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/bodyText) */
export type BodyTextContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/bodyText) */
export type BodyTextContentAlignArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/bodyText) */
export type BodyTextContentTwoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/bodyText) */
export type BodyTextHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/bodyText) */
export type BodyTextHeadingAlignArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/bodyText) */
export type BodyTextIsPageSectionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/bodyText) */
export type BodyTextLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/bodyText) */
export type BodyTextListIndentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/bodyText) */
export type BodyTextNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/bodyText) */
export type BodyTextParagraphIndentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/bodyText) */
export type BodyTextReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/bodyText) */
export type BodyTextSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type BodyTextCollection = {
  __typename?: 'BodyTextCollection';
  items: Array<Maybe<BodyText>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type BodyTextContent = {
  __typename?: 'BodyTextContent';
  json: Scalars['JSON']['output'];
  links: BodyTextContentLinks;
};

export type BodyTextContentAssets = {
  __typename?: 'BodyTextContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type BodyTextContentEntries = {
  __typename?: 'BodyTextContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type BodyTextContentLinks = {
  __typename?: 'BodyTextContentLinks';
  assets: BodyTextContentAssets;
  entries: BodyTextContentEntries;
  resources: BodyTextContentResources;
};

export type BodyTextContentResources = {
  __typename?: 'BodyTextContentResources';
  block: Array<BodyTextContentResourcesBlock>;
  hyperlink: Array<BodyTextContentResourcesHyperlink>;
  inline: Array<BodyTextContentResourcesInline>;
};

export type BodyTextContentResourcesBlock = ResourceLink & {
  __typename?: 'BodyTextContentResourcesBlock';
  sys: ResourceSys;
};

export type BodyTextContentResourcesHyperlink = ResourceLink & {
  __typename?: 'BodyTextContentResourcesHyperlink';
  sys: ResourceSys;
};

export type BodyTextContentResourcesInline = ResourceLink & {
  __typename?: 'BodyTextContentResourcesInline';
  sys: ResourceSys;
};

export type BodyTextContentTwo = {
  __typename?: 'BodyTextContentTwo';
  json: Scalars['JSON']['output'];
  links: BodyTextContentTwoLinks;
};

export type BodyTextContentTwoAssets = {
  __typename?: 'BodyTextContentTwoAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type BodyTextContentTwoEntries = {
  __typename?: 'BodyTextContentTwoEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type BodyTextContentTwoLinks = {
  __typename?: 'BodyTextContentTwoLinks';
  assets: BodyTextContentTwoAssets;
  entries: BodyTextContentTwoEntries;
  resources: BodyTextContentTwoResources;
};

export type BodyTextContentTwoResources = {
  __typename?: 'BodyTextContentTwoResources';
  block: Array<BodyTextContentTwoResourcesBlock>;
  hyperlink: Array<BodyTextContentTwoResourcesHyperlink>;
  inline: Array<BodyTextContentTwoResourcesInline>;
};

export type BodyTextContentTwoResourcesBlock = ResourceLink & {
  __typename?: 'BodyTextContentTwoResourcesBlock';
  sys: ResourceSys;
};

export type BodyTextContentTwoResourcesHyperlink = ResourceLink & {
  __typename?: 'BodyTextContentTwoResourcesHyperlink';
  sys: ResourceSys;
};

export type BodyTextContentTwoResourcesInline = ResourceLink & {
  __typename?: 'BodyTextContentTwoResourcesInline';
  sys: ResourceSys;
};

export type BodyTextFilter = {
  AND?: InputMaybe<Array<InputMaybe<BodyTextFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BodyTextFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  contentAlign?: InputMaybe<Scalars['String']['input']>;
  contentAlign_contains?: InputMaybe<Scalars['String']['input']>;
  contentAlign_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentAlign_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentAlign_not?: InputMaybe<Scalars['String']['input']>;
  contentAlign_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentAlign_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentTwo_contains?: InputMaybe<Scalars['String']['input']>;
  contentTwo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentTwo_not_contains?: InputMaybe<Scalars['String']['input']>;
  content_contains?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  headingAlign?: InputMaybe<Scalars['String']['input']>;
  headingAlign_contains?: InputMaybe<Scalars['String']['input']>;
  headingAlign_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headingAlign_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headingAlign_not?: InputMaybe<Scalars['String']['input']>;
  headingAlign_not_contains?: InputMaybe<Scalars['String']['input']>;
  headingAlign_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  heading_contains?: InputMaybe<Scalars['String']['input']>;
  heading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heading_not_contains?: InputMaybe<Scalars['String']['input']>;
  isPageSection?: InputMaybe<Scalars['Boolean']['input']>;
  isPageSection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isPageSection_not?: InputMaybe<Scalars['Boolean']['input']>;
  listIndent?: InputMaybe<Scalars['Int']['input']>;
  listIndent_exists?: InputMaybe<Scalars['Boolean']['input']>;
  listIndent_gt?: InputMaybe<Scalars['Int']['input']>;
  listIndent_gte?: InputMaybe<Scalars['Int']['input']>;
  listIndent_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  listIndent_lt?: InputMaybe<Scalars['Int']['input']>;
  listIndent_lte?: InputMaybe<Scalars['Int']['input']>;
  listIndent_not?: InputMaybe<Scalars['Int']['input']>;
  listIndent_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  paragraphIndent?: InputMaybe<Scalars['Int']['input']>;
  paragraphIndent_exists?: InputMaybe<Scalars['Boolean']['input']>;
  paragraphIndent_gt?: InputMaybe<Scalars['Int']['input']>;
  paragraphIndent_gte?: InputMaybe<Scalars['Int']['input']>;
  paragraphIndent_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  paragraphIndent_lt?: InputMaybe<Scalars['Int']['input']>;
  paragraphIndent_lte?: InputMaybe<Scalars['Int']['input']>;
  paragraphIndent_not?: InputMaybe<Scalars['Int']['input']>;
  paragraphIndent_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>>
  >;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type BodyTextHeading = {
  __typename?: 'BodyTextHeading';
  json: Scalars['JSON']['output'];
  links: BodyTextHeadingLinks;
};

export type BodyTextHeadingAssets = {
  __typename?: 'BodyTextHeadingAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type BodyTextHeadingEntries = {
  __typename?: 'BodyTextHeadingEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type BodyTextHeadingLinks = {
  __typename?: 'BodyTextHeadingLinks';
  assets: BodyTextHeadingAssets;
  entries: BodyTextHeadingEntries;
  resources: BodyTextHeadingResources;
};

export type BodyTextHeadingResources = {
  __typename?: 'BodyTextHeadingResources';
  block: Array<BodyTextHeadingResourcesBlock>;
  hyperlink: Array<BodyTextHeadingResourcesHyperlink>;
  inline: Array<BodyTextHeadingResourcesInline>;
};

export type BodyTextHeadingResourcesBlock = ResourceLink & {
  __typename?: 'BodyTextHeadingResourcesBlock';
  sys: ResourceSys;
};

export type BodyTextHeadingResourcesHyperlink = ResourceLink & {
  __typename?: 'BodyTextHeadingResourcesHyperlink';
  sys: ResourceSys;
};

export type BodyTextHeadingResourcesInline = ResourceLink & {
  __typename?: 'BodyTextHeadingResourcesInline';
  sys: ResourceSys;
};

export type BodyTextLinkingCollections = {
  __typename?: 'BodyTextLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pageSectionCollection?: Maybe<PageSectionCollection>;
};

export type BodyTextLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type BodyTextLinkingCollectionsPageSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<BodyTextLinkingCollectionsPageSectionCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum BodyTextLinkingCollectionsPageSectionCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundGradientAsc = 'backgroundGradient_ASC',
  BackgroundGradientDesc = 'backgroundGradient_DESC',
  BackgroundTypeAsc = 'backgroundType_ASC',
  BackgroundTypeDesc = 'backgroundType_DESC',
  EnablePaddingAsc = 'enablePadding_ASC',
  EnablePaddingDesc = 'enablePadding_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum BodyTextOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  ContentAlignAsc = 'contentAlign_ASC',
  ContentAlignDesc = 'contentAlign_DESC',
  HeadingAlignAsc = 'headingAlign_ASC',
  HeadingAlignDesc = 'headingAlign_DESC',
  IsPageSectionAsc = 'isPageSection_ASC',
  IsPageSectionDesc = 'isPageSection_DESC',
  ListIndentAsc = 'listIndent_ASC',
  ListIndentDesc = 'listIndent_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ParagraphIndentAsc = 'paragraphIndent_ASC',
  ParagraphIndentDesc = 'paragraphIndent_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type Calculator = Entry &
  _Node & {
    __typename?: 'Calculator';
    _id: Scalars['ID']['output'];
    airtimeTopupQuoteAvailableInApp?: Maybe<Scalars['String']['output']>;
    blendedRateLabel?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    countriesSearchPlaceholder?: Maybe<Scalars['String']['output']>;
    ctaLinksCollection?: Maybe<CalculatorCtaLinksCollection>;
    estimatedFeeLabel?: Maybe<Scalars['String']['output']>;
    exchangeRateLabel?: Maybe<Scalars['String']['output']>;
    exchangeRatePromoLabel?: Maybe<Scalars['String']['output']>;
    feeLabel?: Maybe<Scalars['String']['output']>;
    generalErrorMessage?: Maybe<Scalars['String']['output']>;
    incorrectCountrySelectedErrorMessage?: Maybe<Scalars['String']['output']>;
    instantDiscountLabel?: Maybe<Scalars['String']['output']>;
    isLite?: Maybe<Scalars['Boolean']['output']>;
    linkedFrom?: Maybe<CalculatorLinkingCollections>;
    loginLink?: Maybe<Link>;
    maxDiscountLabel?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    partnerSelectErrorMessage?: Maybe<Scalars['String']['output']>;
    partnersLabel?: Maybe<Scalars['String']['output']>;
    partnersListDefaultValue?: Maybe<Scalars['String']['output']>;
    payoutMethodsLabel?: Maybe<Scalars['String']['output']>;
    payoutMethodsListCollection?: Maybe<CalculatorPayoutMethodsListCollection>;
    phReceiveBankTransferMessage?: Maybe<Scalars['String']['output']>;
    phoneInputInfoMoreInfoLabel?: Maybe<Scalars['String']['output']>;
    phoneInputInfoPopupText?: Maybe<Scalars['String']['output']>;
    phoneNumberInputErrorMessage?: Maybe<Scalars['String']['output']>;
    phoneNumberInvalidErrorMessage?: Maybe<Scalars['String']['output']>;
    phoneValidatorLabel?: Maybe<Scalars['String']['output']>;
    phoneValidatorPlaceholder?: Maybe<Scalars['String']['output']>;
    promotionalTermsLink?: Maybe<Link>;
    receiveLabel?: Maybe<Scalars['String']['output']>;
    receiverPayoutMethodUnavailableMessage?: Maybe<Scalars['String']['output']>;
    recipientGetsLabel?: Maybe<Scalars['String']['output']>;
    sendLabel?: Maybe<Scalars['String']['output']>;
    senderPayoutMethodUnavailableMessage?: Maybe<Scalars['String']['output']>;
    signUpLink?: Maybe<Link>;
    sys: Sys;
    topUpAmountsLabel?: Maybe<Scalars['String']['output']>;
    topUpAmountsPlaceholder?: Maybe<Scalars['String']['output']>;
    topUpAmountsSelectErrorMessage?: Maybe<Scalars['String']['output']>;
    topUpAmountsSelectRetrievalErrorMessage?: Maybe<
      Scalars['String']['output']
    >;
    topUpAmountsTitle?: Maybe<Scalars['String']['output']>;
    totalToPayLabel?: Maybe<Scalars['String']['output']>;
    transferTimeLabel?: Maybe<Scalars['String']['output']>;
    unavailableCorridorErrorMessage?: Maybe<Scalars['String']['output']>;
    version?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorAirtimeTopupQuoteAvailableInAppArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorBlendedRateLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorCountriesSearchPlaceholderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorCtaLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<CalculatorCtaLinksCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorEstimatedFeeLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorExchangeRateLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorExchangeRatePromoLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorFeeLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorGeneralErrorMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorIncorrectCountrySelectedErrorMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorInstantDiscountLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorIsLiteArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorLoginLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorMaxDiscountLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorPartnerSelectErrorMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorPartnersLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorPartnersListDefaultValueArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorPayoutMethodsLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorPayoutMethodsListCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<CalculatorPayoutMethodsListCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PayoutMethodFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorPhReceiveBankTransferMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorPhoneInputInfoMoreInfoLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorPhoneInputInfoPopupTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorPhoneNumberInputErrorMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorPhoneNumberInvalidErrorMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorPhoneValidatorLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorPhoneValidatorPlaceholderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorPromotionalTermsLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorReceiveLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorReceiverPayoutMethodUnavailableMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorRecipientGetsLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorSendLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorSenderPayoutMethodUnavailableMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorSignUpLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorTopUpAmountsLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorTopUpAmountsPlaceholderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorTopUpAmountsSelectErrorMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorTopUpAmountsSelectRetrievalErrorMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorTopUpAmountsTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorTotalToPayLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorTransferTimeLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorUnavailableCorridorErrorMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculator) */
export type CalculatorVersionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Links used for the lite calculator "Get started CTA on app" CTA  [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculatorAppLeadingLinks) */
export type CalculatorAppLeadingLinks = Entry &
  _Node & {
    __typename?: 'CalculatorAppLeadingLinks';
    _id: Scalars['ID']['output'];
    androidLink?: Maybe<Link>;
    contentfulMetadata: ContentfulMetadata;
    desktopLink?: Maybe<Link>;
    iosLink?: Maybe<Link>;
    label?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<CalculatorAppLeadingLinksLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** Links used for the lite calculator "Get started CTA on app" CTA  [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculatorAppLeadingLinks) */
export type CalculatorAppLeadingLinksAndroidLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** Links used for the lite calculator "Get started CTA on app" CTA  [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculatorAppLeadingLinks) */
export type CalculatorAppLeadingLinksDesktopLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** Links used for the lite calculator "Get started CTA on app" CTA  [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculatorAppLeadingLinks) */
export type CalculatorAppLeadingLinksIosLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** Links used for the lite calculator "Get started CTA on app" CTA  [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculatorAppLeadingLinks) */
export type CalculatorAppLeadingLinksLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Links used for the lite calculator "Get started CTA on app" CTA  [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculatorAppLeadingLinks) */
export type CalculatorAppLeadingLinksLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Links used for the lite calculator "Get started CTA on app" CTA  [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculatorAppLeadingLinks) */
export type CalculatorAppLeadingLinksNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type CalculatorAppLeadingLinksCollection = {
  __typename?: 'CalculatorAppLeadingLinksCollection';
  items: Array<Maybe<CalculatorAppLeadingLinks>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CalculatorAppLeadingLinksFilter = {
  AND?: InputMaybe<Array<InputMaybe<CalculatorAppLeadingLinksFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CalculatorAppLeadingLinksFilter>>>;
  androidLink?: InputMaybe<CfLinkNestedFilter>;
  androidLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  desktopLink?: InputMaybe<CfLinkNestedFilter>;
  desktopLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  iosLink?: InputMaybe<CfLinkNestedFilter>;
  iosLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CalculatorAppLeadingLinksLinkingCollections = {
  __typename?: 'CalculatorAppLeadingLinksLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type CalculatorAppLeadingLinksLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CalculatorAppLeadingLinksOrder {
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CalculatorCollection = {
  __typename?: 'CalculatorCollection';
  items: Array<Maybe<Calculator>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CalculatorCtaLinksCollection = {
  __typename?: 'CalculatorCtaLinksCollection';
  items: Array<Maybe<Link>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum CalculatorCtaLinksCollectionOrder {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  GaTrackNameAsc = 'gaTrackName_ASC',
  GaTrackNameDesc = 'gaTrackName_DESC',
  HighlightedAsc = 'highlighted_ASC',
  HighlightedDesc = 'highlighted_DESC',
  IsDisplayedWhenLoggedInAsc = 'isDisplayedWhenLoggedIn_ASC',
  IsDisplayedWhenLoggedInDesc = 'isDisplayedWhenLoggedIn_DESC',
  IsInternalAsc = 'isInternal_ASC',
  IsInternalDesc = 'isInternal_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  MobileMenuToggleAsc = 'mobileMenuToggle_ASC',
  MobileMenuToggleDesc = 'mobileMenuToggle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoFollowAsc = 'noFollow_ASC',
  NoFollowDesc = 'noFollow_DESC',
  OpenInNewTabAsc = 'openInNewTab_ASC',
  OpenInNewTabDesc = 'openInNewTab_DESC',
  RedirectToLegacyProjectAsc = 'redirectToLegacyProject_ASC',
  RedirectToLegacyProjectDesc = 'redirectToLegacyProject_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnderlineAsc = 'underline_ASC',
  UnderlineDesc = 'underline_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

export type CalculatorFilter = {
  AND?: InputMaybe<Array<InputMaybe<CalculatorFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CalculatorFilter>>>;
  airtimeTopupQuoteAvailableInApp?: InputMaybe<Scalars['String']['input']>;
  airtimeTopupQuoteAvailableInApp_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  airtimeTopupQuoteAvailableInApp_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  airtimeTopupQuoteAvailableInApp_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  airtimeTopupQuoteAvailableInApp_not?: InputMaybe<Scalars['String']['input']>;
  airtimeTopupQuoteAvailableInApp_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  airtimeTopupQuoteAvailableInApp_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  blendedRateLabel?: InputMaybe<Scalars['String']['input']>;
  blendedRateLabel_contains?: InputMaybe<Scalars['String']['input']>;
  blendedRateLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  blendedRateLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  blendedRateLabel_not?: InputMaybe<Scalars['String']['input']>;
  blendedRateLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  blendedRateLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  countriesSearchPlaceholder?: InputMaybe<Scalars['String']['input']>;
  countriesSearchPlaceholder_contains?: InputMaybe<Scalars['String']['input']>;
  countriesSearchPlaceholder_exists?: InputMaybe<Scalars['Boolean']['input']>;
  countriesSearchPlaceholder_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  countriesSearchPlaceholder_not?: InputMaybe<Scalars['String']['input']>;
  countriesSearchPlaceholder_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  countriesSearchPlaceholder_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  ctaLinks?: InputMaybe<CfLinkNestedFilter>;
  ctaLinksCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  estimatedFeeLabel?: InputMaybe<Scalars['String']['input']>;
  estimatedFeeLabel_contains?: InputMaybe<Scalars['String']['input']>;
  estimatedFeeLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  estimatedFeeLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  estimatedFeeLabel_not?: InputMaybe<Scalars['String']['input']>;
  estimatedFeeLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  estimatedFeeLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  exchangeRateLabel?: InputMaybe<Scalars['String']['input']>;
  exchangeRateLabel_contains?: InputMaybe<Scalars['String']['input']>;
  exchangeRateLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  exchangeRateLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  exchangeRateLabel_not?: InputMaybe<Scalars['String']['input']>;
  exchangeRateLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  exchangeRateLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  exchangeRatePromoLabel?: InputMaybe<Scalars['String']['input']>;
  exchangeRatePromoLabel_contains?: InputMaybe<Scalars['String']['input']>;
  exchangeRatePromoLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  exchangeRatePromoLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  exchangeRatePromoLabel_not?: InputMaybe<Scalars['String']['input']>;
  exchangeRatePromoLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  exchangeRatePromoLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  feeLabel?: InputMaybe<Scalars['String']['input']>;
  feeLabel_contains?: InputMaybe<Scalars['String']['input']>;
  feeLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  feeLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  feeLabel_not?: InputMaybe<Scalars['String']['input']>;
  feeLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  feeLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  generalErrorMessage?: InputMaybe<Scalars['String']['input']>;
  generalErrorMessage_contains?: InputMaybe<Scalars['String']['input']>;
  generalErrorMessage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  generalErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  generalErrorMessage_not?: InputMaybe<Scalars['String']['input']>;
  generalErrorMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  generalErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  incorrectCountrySelectedErrorMessage?: InputMaybe<Scalars['String']['input']>;
  incorrectCountrySelectedErrorMessage_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  incorrectCountrySelectedErrorMessage_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  incorrectCountrySelectedErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  incorrectCountrySelectedErrorMessage_not?: InputMaybe<
    Scalars['String']['input']
  >;
  incorrectCountrySelectedErrorMessage_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  incorrectCountrySelectedErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  instantDiscountLabel?: InputMaybe<Scalars['String']['input']>;
  instantDiscountLabel_contains?: InputMaybe<Scalars['String']['input']>;
  instantDiscountLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  instantDiscountLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  instantDiscountLabel_not?: InputMaybe<Scalars['String']['input']>;
  instantDiscountLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  instantDiscountLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  isLite?: InputMaybe<Scalars['Boolean']['input']>;
  isLite_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isLite_not?: InputMaybe<Scalars['Boolean']['input']>;
  loginLink?: InputMaybe<CfLinkNestedFilter>;
  loginLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  maxDiscountLabel?: InputMaybe<Scalars['String']['input']>;
  maxDiscountLabel_contains?: InputMaybe<Scalars['String']['input']>;
  maxDiscountLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  maxDiscountLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  maxDiscountLabel_not?: InputMaybe<Scalars['String']['input']>;
  maxDiscountLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  maxDiscountLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  partnerSelectErrorMessage?: InputMaybe<Scalars['String']['input']>;
  partnerSelectErrorMessage_contains?: InputMaybe<Scalars['String']['input']>;
  partnerSelectErrorMessage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  partnerSelectErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  partnerSelectErrorMessage_not?: InputMaybe<Scalars['String']['input']>;
  partnerSelectErrorMessage_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  partnerSelectErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  partnersLabel?: InputMaybe<Scalars['String']['input']>;
  partnersLabel_contains?: InputMaybe<Scalars['String']['input']>;
  partnersLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  partnersLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  partnersLabel_not?: InputMaybe<Scalars['String']['input']>;
  partnersLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  partnersLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  partnersListDefaultValue?: InputMaybe<Scalars['String']['input']>;
  partnersListDefaultValue_contains?: InputMaybe<Scalars['String']['input']>;
  partnersListDefaultValue_exists?: InputMaybe<Scalars['Boolean']['input']>;
  partnersListDefaultValue_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  partnersListDefaultValue_not?: InputMaybe<Scalars['String']['input']>;
  partnersListDefaultValue_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  partnersListDefaultValue_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  payoutMethodsLabel?: InputMaybe<Scalars['String']['input']>;
  payoutMethodsLabel_contains?: InputMaybe<Scalars['String']['input']>;
  payoutMethodsLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  payoutMethodsLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  payoutMethodsLabel_not?: InputMaybe<Scalars['String']['input']>;
  payoutMethodsLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  payoutMethodsLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  payoutMethodsList?: InputMaybe<CfPayoutMethodNestedFilter>;
  payoutMethodsListCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  phReceiveBankTransferMessage?: InputMaybe<Scalars['String']['input']>;
  phReceiveBankTransferMessage_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  phReceiveBankTransferMessage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  phReceiveBankTransferMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phReceiveBankTransferMessage_not?: InputMaybe<Scalars['String']['input']>;
  phReceiveBankTransferMessage_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  phReceiveBankTransferMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneInputInfoMoreInfoLabel?: InputMaybe<Scalars['String']['input']>;
  phoneInputInfoMoreInfoLabel_contains?: InputMaybe<Scalars['String']['input']>;
  phoneInputInfoMoreInfoLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  phoneInputInfoMoreInfoLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneInputInfoMoreInfoLabel_not?: InputMaybe<Scalars['String']['input']>;
  phoneInputInfoMoreInfoLabel_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  phoneInputInfoMoreInfoLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneInputInfoPopupText?: InputMaybe<Scalars['String']['input']>;
  phoneInputInfoPopupText_contains?: InputMaybe<Scalars['String']['input']>;
  phoneInputInfoPopupText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  phoneInputInfoPopupText_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneInputInfoPopupText_not?: InputMaybe<Scalars['String']['input']>;
  phoneInputInfoPopupText_not_contains?: InputMaybe<Scalars['String']['input']>;
  phoneInputInfoPopupText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneNumberInputErrorMessage?: InputMaybe<Scalars['String']['input']>;
  phoneNumberInputErrorMessage_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  phoneNumberInputErrorMessage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumberInputErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneNumberInputErrorMessage_not?: InputMaybe<Scalars['String']['input']>;
  phoneNumberInputErrorMessage_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  phoneNumberInputErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneNumberInvalidErrorMessage?: InputMaybe<Scalars['String']['input']>;
  phoneNumberInvalidErrorMessage_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  phoneNumberInvalidErrorMessage_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  phoneNumberInvalidErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneNumberInvalidErrorMessage_not?: InputMaybe<Scalars['String']['input']>;
  phoneNumberInvalidErrorMessage_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  phoneNumberInvalidErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneValidatorLabel?: InputMaybe<Scalars['String']['input']>;
  phoneValidatorLabel_contains?: InputMaybe<Scalars['String']['input']>;
  phoneValidatorLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  phoneValidatorLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneValidatorLabel_not?: InputMaybe<Scalars['String']['input']>;
  phoneValidatorLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  phoneValidatorLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneValidatorPlaceholder?: InputMaybe<Scalars['String']['input']>;
  phoneValidatorPlaceholder_contains?: InputMaybe<Scalars['String']['input']>;
  phoneValidatorPlaceholder_exists?: InputMaybe<Scalars['Boolean']['input']>;
  phoneValidatorPlaceholder_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneValidatorPlaceholder_not?: InputMaybe<Scalars['String']['input']>;
  phoneValidatorPlaceholder_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  phoneValidatorPlaceholder_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  promotionalTermsLink?: InputMaybe<CfLinkNestedFilter>;
  promotionalTermsLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  receiveLabel?: InputMaybe<Scalars['String']['input']>;
  receiveLabel_contains?: InputMaybe<Scalars['String']['input']>;
  receiveLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  receiveLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  receiveLabel_not?: InputMaybe<Scalars['String']['input']>;
  receiveLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  receiveLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiverPayoutMethodUnavailableMessage?: InputMaybe<
    Scalars['String']['input']
  >;
  receiverPayoutMethodUnavailableMessage_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  receiverPayoutMethodUnavailableMessage_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  receiverPayoutMethodUnavailableMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiverPayoutMethodUnavailableMessage_not?: InputMaybe<
    Scalars['String']['input']
  >;
  receiverPayoutMethodUnavailableMessage_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  receiverPayoutMethodUnavailableMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  recipientGetsLabel?: InputMaybe<Scalars['String']['input']>;
  recipientGetsLabel_contains?: InputMaybe<Scalars['String']['input']>;
  recipientGetsLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  recipientGetsLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  recipientGetsLabel_not?: InputMaybe<Scalars['String']['input']>;
  recipientGetsLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  recipientGetsLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendLabel?: InputMaybe<Scalars['String']['input']>;
  sendLabel_contains?: InputMaybe<Scalars['String']['input']>;
  sendLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sendLabel_not?: InputMaybe<Scalars['String']['input']>;
  sendLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  sendLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  senderPayoutMethodUnavailableMessage?: InputMaybe<Scalars['String']['input']>;
  senderPayoutMethodUnavailableMessage_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  senderPayoutMethodUnavailableMessage_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  senderPayoutMethodUnavailableMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  senderPayoutMethodUnavailableMessage_not?: InputMaybe<
    Scalars['String']['input']
  >;
  senderPayoutMethodUnavailableMessage_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  senderPayoutMethodUnavailableMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  signUpLink?: InputMaybe<CfLinkNestedFilter>;
  signUpLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  topUpAmountsLabel?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsLabel_contains?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  topUpAmountsLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  topUpAmountsLabel_not?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  topUpAmountsPlaceholder?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsPlaceholder_contains?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsPlaceholder_exists?: InputMaybe<Scalars['Boolean']['input']>;
  topUpAmountsPlaceholder_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  topUpAmountsPlaceholder_not?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsPlaceholder_not_contains?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsPlaceholder_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  topUpAmountsSelectErrorMessage?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsSelectErrorMessage_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  topUpAmountsSelectErrorMessage_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  topUpAmountsSelectErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  topUpAmountsSelectErrorMessage_not?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsSelectErrorMessage_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  topUpAmountsSelectErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  topUpAmountsSelectRetrievalErrorMessage?: InputMaybe<
    Scalars['String']['input']
  >;
  topUpAmountsSelectRetrievalErrorMessage_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  topUpAmountsSelectRetrievalErrorMessage_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  topUpAmountsSelectRetrievalErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  topUpAmountsSelectRetrievalErrorMessage_not?: InputMaybe<
    Scalars['String']['input']
  >;
  topUpAmountsSelectRetrievalErrorMessage_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  topUpAmountsSelectRetrievalErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  topUpAmountsTitle?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsTitle_contains?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  topUpAmountsTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  topUpAmountsTitle_not?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  totalToPayLabel?: InputMaybe<Scalars['String']['input']>;
  totalToPayLabel_contains?: InputMaybe<Scalars['String']['input']>;
  totalToPayLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  totalToPayLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  totalToPayLabel_not?: InputMaybe<Scalars['String']['input']>;
  totalToPayLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  totalToPayLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  transferTimeLabel?: InputMaybe<Scalars['String']['input']>;
  transferTimeLabel_contains?: InputMaybe<Scalars['String']['input']>;
  transferTimeLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  transferTimeLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  transferTimeLabel_not?: InputMaybe<Scalars['String']['input']>;
  transferTimeLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  transferTimeLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  unavailableCorridorErrorMessage?: InputMaybe<Scalars['String']['input']>;
  unavailableCorridorErrorMessage_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  unavailableCorridorErrorMessage_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  unavailableCorridorErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  unavailableCorridorErrorMessage_not?: InputMaybe<Scalars['String']['input']>;
  unavailableCorridorErrorMessage_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  unavailableCorridorErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  version?: InputMaybe<Scalars['String']['input']>;
  version_contains?: InputMaybe<Scalars['String']['input']>;
  version_exists?: InputMaybe<Scalars['Boolean']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version_not?: InputMaybe<Scalars['String']['input']>;
  version_not_contains?: InputMaybe<Scalars['String']['input']>;
  version_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CalculatorLinkingCollections = {
  __typename?: 'CalculatorLinkingCollections';
  betterWayTopCollection?: Maybe<BetterWayTopCollection>;
  calculatorPageCollection?: Maybe<CalculatorPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type CalculatorLinkingCollectionsBetterWayTopCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<CalculatorLinkingCollectionsBetterWayTopCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CalculatorLinkingCollectionsCalculatorPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<CalculatorLinkingCollectionsCalculatorPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CalculatorLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CalculatorLinkingCollectionsBetterWayTopCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentAlignmentAsc = 'contentAlignment_ASC',
  ContentAlignmentDesc = 'contentAlignment_DESC',
  ContentFlexDirectionAsc = 'contentFlexDirection_ASC',
  ContentFlexDirectionDesc = 'contentFlexDirection_DESC',
  HeadingColorAsc = 'headingColor_ASC',
  HeadingColorDesc = 'headingColor_DESC',
  HideDescriptionOnMobileAsc = 'hideDescriptionOnMobile_ASC',
  HideDescriptionOnMobileDesc = 'hideDescriptionOnMobile_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrustpilotMessageAsc = 'trustpilotMessage_ASC',
  TrustpilotMessageDesc = 'trustpilotMessage_DESC',
}

export enum CalculatorLinkingCollectionsCalculatorPageCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IsTopPageAsc = 'isTopPage_ASC',
  IsTopPageDesc = 'isTopPage_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CalculatorOrder {
  BlendedRateLabelAsc = 'blendedRateLabel_ASC',
  BlendedRateLabelDesc = 'blendedRateLabel_DESC',
  CountriesSearchPlaceholderAsc = 'countriesSearchPlaceholder_ASC',
  CountriesSearchPlaceholderDesc = 'countriesSearchPlaceholder_DESC',
  EstimatedFeeLabelAsc = 'estimatedFeeLabel_ASC',
  EstimatedFeeLabelDesc = 'estimatedFeeLabel_DESC',
  ExchangeRateLabelAsc = 'exchangeRateLabel_ASC',
  ExchangeRateLabelDesc = 'exchangeRateLabel_DESC',
  ExchangeRatePromoLabelAsc = 'exchangeRatePromoLabel_ASC',
  ExchangeRatePromoLabelDesc = 'exchangeRatePromoLabel_DESC',
  FeeLabelAsc = 'feeLabel_ASC',
  FeeLabelDesc = 'feeLabel_DESC',
  IncorrectCountrySelectedErrorMessageAsc = 'incorrectCountrySelectedErrorMessage_ASC',
  IncorrectCountrySelectedErrorMessageDesc = 'incorrectCountrySelectedErrorMessage_DESC',
  InstantDiscountLabelAsc = 'instantDiscountLabel_ASC',
  InstantDiscountLabelDesc = 'instantDiscountLabel_DESC',
  IsLiteAsc = 'isLite_ASC',
  IsLiteDesc = 'isLite_DESC',
  MaxDiscountLabelAsc = 'maxDiscountLabel_ASC',
  MaxDiscountLabelDesc = 'maxDiscountLabel_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PartnerSelectErrorMessageAsc = 'partnerSelectErrorMessage_ASC',
  PartnerSelectErrorMessageDesc = 'partnerSelectErrorMessage_DESC',
  PartnersLabelAsc = 'partnersLabel_ASC',
  PartnersLabelDesc = 'partnersLabel_DESC',
  PartnersListDefaultValueAsc = 'partnersListDefaultValue_ASC',
  PartnersListDefaultValueDesc = 'partnersListDefaultValue_DESC',
  PayoutMethodsLabelAsc = 'payoutMethodsLabel_ASC',
  PayoutMethodsLabelDesc = 'payoutMethodsLabel_DESC',
  PhReceiveBankTransferMessageAsc = 'phReceiveBankTransferMessage_ASC',
  PhReceiveBankTransferMessageDesc = 'phReceiveBankTransferMessage_DESC',
  PhoneInputInfoMoreInfoLabelAsc = 'phoneInputInfoMoreInfoLabel_ASC',
  PhoneInputInfoMoreInfoLabelDesc = 'phoneInputInfoMoreInfoLabel_DESC',
  PhoneInputInfoPopupTextAsc = 'phoneInputInfoPopupText_ASC',
  PhoneInputInfoPopupTextDesc = 'phoneInputInfoPopupText_DESC',
  PhoneNumberInputErrorMessageAsc = 'phoneNumberInputErrorMessage_ASC',
  PhoneNumberInputErrorMessageDesc = 'phoneNumberInputErrorMessage_DESC',
  PhoneNumberInvalidErrorMessageAsc = 'phoneNumberInvalidErrorMessage_ASC',
  PhoneNumberInvalidErrorMessageDesc = 'phoneNumberInvalidErrorMessage_DESC',
  PhoneValidatorLabelAsc = 'phoneValidatorLabel_ASC',
  PhoneValidatorLabelDesc = 'phoneValidatorLabel_DESC',
  PhoneValidatorPlaceholderAsc = 'phoneValidatorPlaceholder_ASC',
  PhoneValidatorPlaceholderDesc = 'phoneValidatorPlaceholder_DESC',
  ReceiveLabelAsc = 'receiveLabel_ASC',
  ReceiveLabelDesc = 'receiveLabel_DESC',
  RecipientGetsLabelAsc = 'recipientGetsLabel_ASC',
  RecipientGetsLabelDesc = 'recipientGetsLabel_DESC',
  SendLabelAsc = 'sendLabel_ASC',
  SendLabelDesc = 'sendLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TopUpAmountsLabelAsc = 'topUpAmountsLabel_ASC',
  TopUpAmountsLabelDesc = 'topUpAmountsLabel_DESC',
  TopUpAmountsPlaceholderAsc = 'topUpAmountsPlaceholder_ASC',
  TopUpAmountsPlaceholderDesc = 'topUpAmountsPlaceholder_DESC',
  TopUpAmountsSelectErrorMessageAsc = 'topUpAmountsSelectErrorMessage_ASC',
  TopUpAmountsSelectErrorMessageDesc = 'topUpAmountsSelectErrorMessage_DESC',
  TopUpAmountsSelectRetrievalErrorMessageAsc = 'topUpAmountsSelectRetrievalErrorMessage_ASC',
  TopUpAmountsSelectRetrievalErrorMessageDesc = 'topUpAmountsSelectRetrievalErrorMessage_DESC',
  TopUpAmountsTitleAsc = 'topUpAmountsTitle_ASC',
  TopUpAmountsTitleDesc = 'topUpAmountsTitle_DESC',
  TotalToPayLabelAsc = 'totalToPayLabel_ASC',
  TotalToPayLabelDesc = 'totalToPayLabel_DESC',
  TransferTimeLabelAsc = 'transferTimeLabel_ASC',
  TransferTimeLabelDesc = 'transferTimeLabel_DESC',
  UnavailableCorridorErrorMessageAsc = 'unavailableCorridorErrorMessage_ASC',
  UnavailableCorridorErrorMessageDesc = 'unavailableCorridorErrorMessage_DESC',
  VersionAsc = 'version_ASC',
  VersionDesc = 'version_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculatorPage) */
export type CalculatorPage = Entry &
  _Node & {
    __typename?: 'CalculatorPage';
    _id: Scalars['ID']['output'];
    analyticsPageName?: Maybe<Scalars['String']['output']>;
    analyticsPageType?: Maybe<Scalars['String']['output']>;
    buildLocales?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    calculator?: Maybe<Calculator>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    footer?: Maybe<LayoutFooter>;
    isTopPage?: Maybe<Scalars['Boolean']['output']>;
    linkedFrom?: Maybe<CalculatorPageLinkingCollections>;
    meta?: Maybe<Scalars['JSON']['output']>;
    modulesCollection?: Maybe<CalculatorPageModulesCollection>;
    name?: Maybe<Scalars['String']['output']>;
    noIndex?: Maybe<Scalars['Boolean']['output']>;
    pricingCalculator?: Maybe<PricingCalculator>;
    sitemap?: Maybe<Scalars['Boolean']['output']>;
    sitemapTitle?: Maybe<Scalars['String']['output']>;
    slug?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculatorPage) */
export type CalculatorPageAnalyticsPageNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculatorPage) */
export type CalculatorPageAnalyticsPageTypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculatorPage) */
export type CalculatorPageBuildLocalesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculatorPage) */
export type CalculatorPageCalculatorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CalculatorFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculatorPage) */
export type CalculatorPageDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculatorPage) */
export type CalculatorPageFooterArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LayoutFooterFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculatorPage) */
export type CalculatorPageIsTopPageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculatorPage) */
export type CalculatorPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculatorPage) */
export type CalculatorPageMetaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculatorPage) */
export type CalculatorPageModulesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CalculatorPageModulesFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculatorPage) */
export type CalculatorPageNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculatorPage) */
export type CalculatorPageNoIndexArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculatorPage) */
export type CalculatorPagePricingCalculatorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PricingCalculatorFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculatorPage) */
export type CalculatorPageSitemapArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculatorPage) */
export type CalculatorPageSitemapTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculatorPage) */
export type CalculatorPageSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/calculatorPage) */
export type CalculatorPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type CalculatorPageCollection = {
  __typename?: 'CalculatorPageCollection';
  items: Array<Maybe<CalculatorPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CalculatorPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<CalculatorPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CalculatorPageFilter>>>;
  analyticsPageName?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  analyticsPageName_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  analyticsPageName_not?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_not_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  analyticsPageType?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  analyticsPageType_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  analyticsPageType_not?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_not_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_exists?: InputMaybe<Scalars['Boolean']['input']>;
  calculator?: InputMaybe<CfCalculatorNestedFilter>;
  calculator_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  footer?: InputMaybe<CfLayoutFooterNestedFilter>;
  footer_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isTopPage?: InputMaybe<Scalars['Boolean']['input']>;
  isTopPage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isTopPage_not?: InputMaybe<Scalars['Boolean']['input']>;
  meta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  modules?: InputMaybe<CfmodulesMultiTypeNestedFilter>;
  modulesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noIndex?: InputMaybe<Scalars['Boolean']['input']>;
  noIndex_exists?: InputMaybe<Scalars['Boolean']['input']>;
  noIndex_not?: InputMaybe<Scalars['Boolean']['input']>;
  pricingCalculator?: InputMaybe<CfPricingCalculatorNestedFilter>;
  pricingCalculator_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sitemap?: InputMaybe<Scalars['Boolean']['input']>;
  sitemapTitle?: InputMaybe<Scalars['String']['input']>;
  sitemapTitle_contains?: InputMaybe<Scalars['String']['input']>;
  sitemapTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sitemapTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sitemapTitle_not?: InputMaybe<Scalars['String']['input']>;
  sitemapTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  sitemapTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sitemap_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sitemap_not?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CalculatorPageLinkingCollections = {
  __typename?: 'CalculatorPageLinkingCollections';
  betterWayTopCollection?: Maybe<BetterWayTopCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type CalculatorPageLinkingCollectionsBetterWayTopCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<CalculatorPageLinkingCollectionsBetterWayTopCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CalculatorPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CalculatorPageLinkingCollectionsBetterWayTopCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentAlignmentAsc = 'contentAlignment_ASC',
  ContentAlignmentDesc = 'contentAlignment_DESC',
  ContentFlexDirectionAsc = 'contentFlexDirection_ASC',
  ContentFlexDirectionDesc = 'contentFlexDirection_DESC',
  HeadingColorAsc = 'headingColor_ASC',
  HeadingColorDesc = 'headingColor_DESC',
  HideDescriptionOnMobileAsc = 'hideDescriptionOnMobile_ASC',
  HideDescriptionOnMobileDesc = 'hideDescriptionOnMobile_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrustpilotMessageAsc = 'trustpilotMessage_ASC',
  TrustpilotMessageDesc = 'trustpilotMessage_DESC',
}

export type CalculatorPageModulesCollection = {
  __typename?: 'CalculatorPageModulesCollection';
  items: Array<Maybe<CalculatorPageModulesItem>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CalculatorPageModulesFilter = {
  AND?: InputMaybe<Array<InputMaybe<CalculatorPageModulesFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CalculatorPageModulesFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CalculatorPageModulesItem =
  | BetterWayTop
  | Correspondents
  | PromotionalBanner
  | PromotionalBannerDisclaimer;

export enum CalculatorPageOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IsTopPageAsc = 'isTopPage_ASC',
  IsTopPageDesc = 'isTopPage_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type CalculatorPayoutMethodsListCollection = {
  __typename?: 'CalculatorPayoutMethodsListCollection';
  items: Array<Maybe<PayoutMethod>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum CalculatorPayoutMethodsListCollectionOrder {
  CorrespondentsTitleAsc = 'correspondentsTitle_ASC',
  CorrespondentsTitleDesc = 'correspondentsTitle_DESC',
  CtaButtonBaseUrlAsc = 'ctaButtonBaseUrl_ASC',
  CtaButtonBaseUrlDesc = 'ctaButtonBaseUrl_DESC',
  CtaButtonLabelAsc = 'ctaButtonLabel_ASC',
  CtaButtonLabelDesc = 'ctaButtonLabel_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ExchangeRateLabelAsc = 'exchangeRateLabel_ASC',
  ExchangeRateLabelDesc = 'exchangeRateLabel_DESC',
  FeesLabelAsc = 'feesLabel_ASC',
  FeesLabelDesc = 'feesLabel_DESC',
  HideExchangeRateAsc = 'hideExchangeRate_ASC',
  HideExchangeRateDesc = 'hideExchangeRate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PayoutMethodIdAsc = 'payoutMethodId_ASC',
  PayoutMethodIdDesc = 'payoutMethodId_DESC',
  ReceiveMethodDescriptionAsc = 'receiveMethodDescription_ASC',
  ReceiveMethodDescriptionDesc = 'receiveMethodDescription_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SupportLinkTextAsc = 'supportLinkText_ASC',
  SupportLinkTextDesc = 'supportLinkText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TransferTimeLabelAsc = 'transferTimeLabel_ASC',
  TransferTimeLabelDesc = 'transferTimeLabel_DESC',
  TransferTimeTooltipAsc = 'transferTimeTooltip_ASC',
  TransferTimeTooltipDesc = 'transferTimeTooltip_DESC',
}

/**
 * Xenia Module
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/card)
 */
export type Card = Entry &
  _Node & {
    __typename?: 'Card';
    _id: Scalars['ID']['output'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    backgroundColor?: Maybe<Scalars['String']['output']>;
    backgroundImage?: Maybe<Asset>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    header?: Maybe<Scalars['String']['output']>;
    link?: Maybe<Link>;
    linkedFrom?: Maybe<CardLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    payoutMethodId?: Maybe<Scalars['String']['output']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sys: Sys;
  };

/**
 * Xenia Module
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/card)
 */
export type CardAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Xenia Module
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/card)
 */
export type CardAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Xenia Module
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/card)
 */
export type CardBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Xenia Module
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/card)
 */
export type CardBackgroundImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * Xenia Module
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/card)
 */
export type CardDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Xenia Module
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/card)
 */
export type CardHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Xenia Module
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/card)
 */
export type CardLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/**
 * Xenia Module
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/card)
 */
export type CardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/**
 * Xenia Module
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/card)
 */
export type CardNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Xenia Module
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/card)
 */
export type CardPayoutMethodIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Xenia Module
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/card)
 */
export type CardReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Xenia Module
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/card)
 */
export type CardSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type CardCollection = {
  __typename?: 'CardCollection';
  items: Array<Maybe<Card>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CardFilter = {
  AND?: InputMaybe<Array<InputMaybe<CardFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CardFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundColor_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  link?: InputMaybe<CfLinkNestedFilter>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  payoutMethodId?: InputMaybe<Scalars['String']['input']>;
  payoutMethodId_contains?: InputMaybe<Scalars['String']['input']>;
  payoutMethodId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  payoutMethodId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  payoutMethodId_not?: InputMaybe<Scalars['String']['input']>;
  payoutMethodId_not_contains?: InputMaybe<Scalars['String']['input']>;
  payoutMethodId_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type CardLinkingCollections = {
  __typename?: 'CardLinkingCollections';
  cardsCollection?: Maybe<CardsCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type CardLinkingCollectionsCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<CardLinkingCollectionsCardsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CardLinkingCollectionsCardsCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  CarouselTextAsc = 'carouselText_ASC',
  CarouselTextDesc = 'carouselText_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PaymentTextAsc = 'paymentText_ASC',
  PaymentTextDesc = 'paymentText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CardOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PayoutMethodIdAsc = 'payoutMethodId_ASC',
  PayoutMethodIdDesc = 'payoutMethodId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cards) */
export type Cards = Entry &
  _Node & {
    __typename?: 'Cards';
    _id: Scalars['ID']['output'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    cardsCollection?: Maybe<CardsCardsCollection>;
    carouselText?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    header?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<CardsLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    paymentIconsCollection?: Maybe<AssetCollection>;
    paymentText?: Maybe<Scalars['String']['output']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sys: Sys;
  };

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cards) */
export type CardsAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cards) */
export type CardsAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cards) */
export type CardsCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<CardsCardsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CardFilter>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cards) */
export type CardsCarouselTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cards) */
export type CardsDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cards) */
export type CardsHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cards) */
export type CardsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cards) */
export type CardsNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cards) */
export type CardsPaymentIconsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cards) */
export type CardsPaymentTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cards) */
export type CardsReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1909%3A31887 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cards) */
export type CardsSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type CardsCardsCollection = {
  __typename?: 'CardsCardsCollection';
  items: Array<Maybe<Card>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum CardsCardsCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PayoutMethodIdAsc = 'payoutMethodId_ASC',
  PayoutMethodIdDesc = 'payoutMethodId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CardsCollection = {
  __typename?: 'CardsCollection';
  items: Array<Maybe<Cards>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CardsFilter = {
  AND?: InputMaybe<Array<InputMaybe<CardsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CardsFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  cards?: InputMaybe<CfCardNestedFilter>;
  cardsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  carouselText?: InputMaybe<Scalars['String']['input']>;
  carouselText_contains?: InputMaybe<Scalars['String']['input']>;
  carouselText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  carouselText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  carouselText_not?: InputMaybe<Scalars['String']['input']>;
  carouselText_not_contains?: InputMaybe<Scalars['String']['input']>;
  carouselText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  paymentIconsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  paymentText?: InputMaybe<Scalars['String']['input']>;
  paymentText_contains?: InputMaybe<Scalars['String']['input']>;
  paymentText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  paymentText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  paymentText_not?: InputMaybe<Scalars['String']['input']>;
  paymentText_not_contains?: InputMaybe<Scalars['String']['input']>;
  paymentText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type CardsLinkingCollections = {
  __typename?: 'CardsLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type CardsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CardsOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  CarouselTextAsc = 'carouselText_ASC',
  CarouselTextDesc = 'carouselText_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PaymentTextAsc = 'paymentText_ASC',
  PaymentTextDesc = 'paymentText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexChartFilterOptions) */
export type CexChartFilterOptions = Entry &
  _Node & {
    __typename?: 'CexChartFilterOptions';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    filterOption?: Maybe<Scalars['String']['output']>;
    label?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<CexChartFilterOptionsLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    setDefault?: Maybe<Scalars['Boolean']['output']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexChartFilterOptions) */
export type CexChartFilterOptionsFilterOptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexChartFilterOptions) */
export type CexChartFilterOptionsLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexChartFilterOptions) */
export type CexChartFilterOptionsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexChartFilterOptions) */
export type CexChartFilterOptionsNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexChartFilterOptions) */
export type CexChartFilterOptionsSetDefaultArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type CexChartFilterOptionsCollection = {
  __typename?: 'CexChartFilterOptionsCollection';
  items: Array<Maybe<CexChartFilterOptions>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CexChartFilterOptionsFilter = {
  AND?: InputMaybe<Array<InputMaybe<CexChartFilterOptionsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CexChartFilterOptionsFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  filterOption?: InputMaybe<Scalars['String']['input']>;
  filterOption_contains?: InputMaybe<Scalars['String']['input']>;
  filterOption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  filterOption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filterOption_not?: InputMaybe<Scalars['String']['input']>;
  filterOption_not_contains?: InputMaybe<Scalars['String']['input']>;
  filterOption_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  setDefault?: InputMaybe<Scalars['Boolean']['input']>;
  setDefault_exists?: InputMaybe<Scalars['Boolean']['input']>;
  setDefault_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type CexChartFilterOptionsLinkingCollections = {
  __typename?: 'CexChartFilterOptionsLinkingCollections';
  cexLineChartCollection?: Maybe<CexLineChartCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type CexChartFilterOptionsLinkingCollectionsCexLineChartCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<CexChartFilterOptionsLinkingCollectionsCexLineChartCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CexChartFilterOptionsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CexChartFilterOptionsLinkingCollectionsCexLineChartCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleTemplateAsc = 'titleTemplate_ASC',
  TitleTemplateDesc = 'titleTemplate_DESC',
}

export enum CexChartFilterOptionsOrder {
  FilterOptionAsc = 'filterOption_ASC',
  FilterOptionDesc = 'filterOption_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SetDefaultAsc = 'setDefault_ASC',
  SetDefaultDesc = 'setDefault_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Currency information for CEX pages [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexCurrency) */
export type CexCurrency = Entry &
  _Node & {
    __typename?: 'CexCurrency';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    corridorGridTitle?: Maybe<Scalars['String']['output']>;
    countryCode?: Maybe<Scalars['String']['output']>;
    countryName?: Maybe<Scalars['String']['output']>;
    currencyCode?: Maybe<Scalars['String']['output']>;
    currencyName?: Maybe<Scalars['String']['output']>;
    description?: Maybe<CexCurrencyDescription>;
    faqContent?: Maybe<CexCurrencyFaqContent>;
    linkedFrom?: Maybe<CexCurrencyLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    pageSlug?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    topCurrencyPairsCollection?: Maybe<CexCurrencyTopCurrencyPairsCollection>;
  };

/** Currency information for CEX pages [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexCurrency) */
export type CexCurrencyCorridorGridTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Currency information for CEX pages [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexCurrency) */
export type CexCurrencyCountryCodeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Currency information for CEX pages [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexCurrency) */
export type CexCurrencyCountryNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Currency information for CEX pages [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexCurrency) */
export type CexCurrencyCurrencyCodeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Currency information for CEX pages [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexCurrency) */
export type CexCurrencyCurrencyNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Currency information for CEX pages [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexCurrency) */
export type CexCurrencyDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Currency information for CEX pages [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexCurrency) */
export type CexCurrencyFaqContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Currency information for CEX pages [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexCurrency) */
export type CexCurrencyLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Currency information for CEX pages [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexCurrency) */
export type CexCurrencyNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Currency information for CEX pages [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexCurrency) */
export type CexCurrencyPageSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Currency information for CEX pages [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexCurrency) */
export type CexCurrencyTopCurrencyPairsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<CexCurrencyTopCurrencyPairsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CexCurrencyFilter>;
};

export type CexCurrencyCollection = {
  __typename?: 'CexCurrencyCollection';
  items: Array<Maybe<CexCurrency>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

/** Currency conversion corridors with exchange rate [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexCurrencyCorridor) */
export type CexCurrencyCorridor = Entry &
  _Node & {
    __typename?: 'CexCurrencyCorridor';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<CexCurrencyCorridorLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    pageSlug?: Maybe<Scalars['String']['output']>;
    receiveCurrency?: Maybe<CexCurrency>;
    sendCurrency?: Maybe<CexCurrency>;
    sys: Sys;
  };

/** Currency conversion corridors with exchange rate [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexCurrencyCorridor) */
export type CexCurrencyCorridorLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Currency conversion corridors with exchange rate [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexCurrencyCorridor) */
export type CexCurrencyCorridorNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Currency conversion corridors with exchange rate [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexCurrencyCorridor) */
export type CexCurrencyCorridorPageSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Currency conversion corridors with exchange rate [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexCurrencyCorridor) */
export type CexCurrencyCorridorReceiveCurrencyArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CexCurrencyFilter>;
};

/** Currency conversion corridors with exchange rate [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexCurrencyCorridor) */
export type CexCurrencyCorridorSendCurrencyArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CexCurrencyFilter>;
};

export type CexCurrencyCorridorCollection = {
  __typename?: 'CexCurrencyCorridorCollection';
  items: Array<Maybe<CexCurrencyCorridor>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CexCurrencyCorridorFilter = {
  AND?: InputMaybe<Array<InputMaybe<CexCurrencyCorridorFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CexCurrencyCorridorFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pageSlug?: InputMaybe<Scalars['String']['input']>;
  pageSlug_contains?: InputMaybe<Scalars['String']['input']>;
  pageSlug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  pageSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pageSlug_not?: InputMaybe<Scalars['String']['input']>;
  pageSlug_not_contains?: InputMaybe<Scalars['String']['input']>;
  pageSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  receiveCurrency?: InputMaybe<CfCexCurrencyNestedFilter>;
  receiveCurrency_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCurrency?: InputMaybe<CfCexCurrencyNestedFilter>;
  sendCurrency_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type CexCurrencyCorridorLinkingCollections = {
  __typename?: 'CexCurrencyCorridorLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type CexCurrencyCorridorLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CexCurrencyCorridorOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageSlugAsc = 'pageSlug_ASC',
  PageSlugDesc = 'pageSlug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CexCurrencyDescription = {
  __typename?: 'CexCurrencyDescription';
  json: Scalars['JSON']['output'];
  links: CexCurrencyDescriptionLinks;
};

export type CexCurrencyDescriptionAssets = {
  __typename?: 'CexCurrencyDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type CexCurrencyDescriptionEntries = {
  __typename?: 'CexCurrencyDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type CexCurrencyDescriptionLinks = {
  __typename?: 'CexCurrencyDescriptionLinks';
  assets: CexCurrencyDescriptionAssets;
  entries: CexCurrencyDescriptionEntries;
  resources: CexCurrencyDescriptionResources;
};

export type CexCurrencyDescriptionResources = {
  __typename?: 'CexCurrencyDescriptionResources';
  block: Array<CexCurrencyDescriptionResourcesBlock>;
  hyperlink: Array<CexCurrencyDescriptionResourcesHyperlink>;
  inline: Array<CexCurrencyDescriptionResourcesInline>;
};

export type CexCurrencyDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'CexCurrencyDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type CexCurrencyDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'CexCurrencyDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type CexCurrencyDescriptionResourcesInline = ResourceLink & {
  __typename?: 'CexCurrencyDescriptionResourcesInline';
  sys: ResourceSys;
};

export type CexCurrencyFaqContent = {
  __typename?: 'CexCurrencyFaqContent';
  json: Scalars['JSON']['output'];
  links: CexCurrencyFaqContentLinks;
};

export type CexCurrencyFaqContentAssets = {
  __typename?: 'CexCurrencyFaqContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type CexCurrencyFaqContentEntries = {
  __typename?: 'CexCurrencyFaqContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type CexCurrencyFaqContentLinks = {
  __typename?: 'CexCurrencyFaqContentLinks';
  assets: CexCurrencyFaqContentAssets;
  entries: CexCurrencyFaqContentEntries;
  resources: CexCurrencyFaqContentResources;
};

export type CexCurrencyFaqContentResources = {
  __typename?: 'CexCurrencyFaqContentResources';
  block: Array<CexCurrencyFaqContentResourcesBlock>;
  hyperlink: Array<CexCurrencyFaqContentResourcesHyperlink>;
  inline: Array<CexCurrencyFaqContentResourcesInline>;
};

export type CexCurrencyFaqContentResourcesBlock = ResourceLink & {
  __typename?: 'CexCurrencyFaqContentResourcesBlock';
  sys: ResourceSys;
};

export type CexCurrencyFaqContentResourcesHyperlink = ResourceLink & {
  __typename?: 'CexCurrencyFaqContentResourcesHyperlink';
  sys: ResourceSys;
};

export type CexCurrencyFaqContentResourcesInline = ResourceLink & {
  __typename?: 'CexCurrencyFaqContentResourcesInline';
  sys: ResourceSys;
};

export type CexCurrencyFilter = {
  AND?: InputMaybe<Array<InputMaybe<CexCurrencyFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CexCurrencyFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  corridorGridTitle?: InputMaybe<Scalars['String']['input']>;
  corridorGridTitle_contains?: InputMaybe<Scalars['String']['input']>;
  corridorGridTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  corridorGridTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  corridorGridTitle_not?: InputMaybe<Scalars['String']['input']>;
  corridorGridTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  corridorGridTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  countryCode_contains?: InputMaybe<Scalars['String']['input']>;
  countryCode_exists?: InputMaybe<Scalars['Boolean']['input']>;
  countryCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  countryCode_not?: InputMaybe<Scalars['String']['input']>;
  countryCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  countryCode_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  countryName?: InputMaybe<Scalars['String']['input']>;
  countryName_contains?: InputMaybe<Scalars['String']['input']>;
  countryName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  countryName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  countryName_not?: InputMaybe<Scalars['String']['input']>;
  countryName_not_contains?: InputMaybe<Scalars['String']['input']>;
  countryName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  currencyCode_contains?: InputMaybe<Scalars['String']['input']>;
  currencyCode_exists?: InputMaybe<Scalars['Boolean']['input']>;
  currencyCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  currencyCode_not?: InputMaybe<Scalars['String']['input']>;
  currencyCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  currencyCode_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  currencyName?: InputMaybe<Scalars['String']['input']>;
  currencyName_contains?: InputMaybe<Scalars['String']['input']>;
  currencyName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  currencyName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  currencyName_not?: InputMaybe<Scalars['String']['input']>;
  currencyName_not_contains?: InputMaybe<Scalars['String']['input']>;
  currencyName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  faqContent_contains?: InputMaybe<Scalars['String']['input']>;
  faqContent_exists?: InputMaybe<Scalars['Boolean']['input']>;
  faqContent_not_contains?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pageSlug?: InputMaybe<Scalars['String']['input']>;
  pageSlug_contains?: InputMaybe<Scalars['String']['input']>;
  pageSlug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  pageSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pageSlug_not?: InputMaybe<Scalars['String']['input']>;
  pageSlug_not_contains?: InputMaybe<Scalars['String']['input']>;
  pageSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  topCurrencyPairs?: InputMaybe<CfCexCurrencyNestedFilter>;
  topCurrencyPairsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CexCurrencyLinkingCollections = {
  __typename?: 'CexCurrencyLinkingCollections';
  cexCurrencyCollection?: Maybe<CexCurrencyCollection>;
  cexCurrencyCorridorCollection?: Maybe<CexCurrencyCorridorCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type CexCurrencyLinkingCollectionsCexCurrencyCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<CexCurrencyLinkingCollectionsCexCurrencyCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CexCurrencyLinkingCollectionsCexCurrencyCorridorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<CexCurrencyLinkingCollectionsCexCurrencyCorridorCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CexCurrencyLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CexCurrencyLinkingCollectionsCexCurrencyCollectionOrder {
  CorridorGridTitleAsc = 'corridorGridTitle_ASC',
  CorridorGridTitleDesc = 'corridorGridTitle_DESC',
  CountryCodeAsc = 'countryCode_ASC',
  CountryCodeDesc = 'countryCode_DESC',
  CountryNameAsc = 'countryName_ASC',
  CountryNameDesc = 'countryName_DESC',
  CurrencyCodeAsc = 'currencyCode_ASC',
  CurrencyCodeDesc = 'currencyCode_DESC',
  CurrencyNameAsc = 'currencyName_ASC',
  CurrencyNameDesc = 'currencyName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageSlugAsc = 'pageSlug_ASC',
  PageSlugDesc = 'pageSlug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CexCurrencyLinkingCollectionsCexCurrencyCorridorCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageSlugAsc = 'pageSlug_ASC',
  PageSlugDesc = 'pageSlug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CexCurrencyOrder {
  CorridorGridTitleAsc = 'corridorGridTitle_ASC',
  CorridorGridTitleDesc = 'corridorGridTitle_DESC',
  CountryCodeAsc = 'countryCode_ASC',
  CountryCodeDesc = 'countryCode_DESC',
  CountryNameAsc = 'countryName_ASC',
  CountryNameDesc = 'countryName_DESC',
  CurrencyCodeAsc = 'currencyCode_ASC',
  CurrencyCodeDesc = 'currencyCode_DESC',
  CurrencyNameAsc = 'currencyName_ASC',
  CurrencyNameDesc = 'currencyName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageSlugAsc = 'pageSlug_ASC',
  PageSlugDesc = 'pageSlug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CexCurrencyTopCurrencyPairsCollection = {
  __typename?: 'CexCurrencyTopCurrencyPairsCollection';
  items: Array<Maybe<CexCurrency>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum CexCurrencyTopCurrencyPairsCollectionOrder {
  CorridorGridTitleAsc = 'corridorGridTitle_ASC',
  CorridorGridTitleDesc = 'corridorGridTitle_DESC',
  CountryCodeAsc = 'countryCode_ASC',
  CountryCodeDesc = 'countryCode_DESC',
  CountryNameAsc = 'countryName_ASC',
  CountryNameDesc = 'countryName_DESC',
  CurrencyCodeAsc = 'currencyCode_ASC',
  CurrencyCodeDesc = 'currencyCode_DESC',
  CurrencyNameAsc = 'currencyName_ASC',
  CurrencyNameDesc = 'currencyName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageSlugAsc = 'pageSlug_ASC',
  PageSlugDesc = 'pageSlug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Line chart for historical currency exchange rates [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexLineChart) */
export type CexLineChart = Entry &
  _Node & {
    __typename?: 'CexLineChart';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    filterOptionsCollection?: Maybe<CexLineChartFilterOptionsCollection>;
    linkedFrom?: Maybe<CexLineChartLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    titleTemplate?: Maybe<Scalars['String']['output']>;
  };

/** Line chart for historical currency exchange rates [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexLineChart) */
export type CexLineChartFilterOptionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<CexLineChartFilterOptionsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CexChartFilterOptionsFilter>;
};

/** Line chart for historical currency exchange rates [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexLineChart) */
export type CexLineChartLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Line chart for historical currency exchange rates [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexLineChart) */
export type CexLineChartNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Line chart for historical currency exchange rates [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexLineChart) */
export type CexLineChartTitleTemplateArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type CexLineChartCollection = {
  __typename?: 'CexLineChartCollection';
  items: Array<Maybe<CexLineChart>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CexLineChartFilter = {
  AND?: InputMaybe<Array<InputMaybe<CexLineChartFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CexLineChartFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  filterOptions?: InputMaybe<CfCexChartFilterOptionsNestedFilter>;
  filterOptionsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  titleTemplate?: InputMaybe<Scalars['String']['input']>;
  titleTemplate_contains?: InputMaybe<Scalars['String']['input']>;
  titleTemplate_exists?: InputMaybe<Scalars['Boolean']['input']>;
  titleTemplate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titleTemplate_not?: InputMaybe<Scalars['String']['input']>;
  titleTemplate_not_contains?: InputMaybe<Scalars['String']['input']>;
  titleTemplate_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
};

export type CexLineChartFilterOptionsCollection = {
  __typename?: 'CexLineChartFilterOptionsCollection';
  items: Array<Maybe<CexChartFilterOptions>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum CexLineChartFilterOptionsCollectionOrder {
  FilterOptionAsc = 'filterOption_ASC',
  FilterOptionDesc = 'filterOption_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SetDefaultAsc = 'setDefault_ASC',
  SetDefaultDesc = 'setDefault_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CexLineChartLinkingCollections = {
  __typename?: 'CexLineChartLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type CexLineChartLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CexLineChartOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleTemplateAsc = 'titleTemplate_ASC',
  TitleTemplateDesc = 'titleTemplate_DESC',
}

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?node-id=1552%3A301865&mode=dev [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexRateAlerts) */
export type CexRateAlerts = Entry &
  _Node & {
    __typename?: 'CexRateAlerts';
    _id: Scalars['ID']['output'];
    buttonTitle?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    informationDialog?: Maybe<Entry>;
    linkedFrom?: Maybe<CexRateAlertsLinkingCollections>;
    message?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    receiveMethodLabel?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    textFieldPlaceholder?: Maybe<Scalars['String']['output']>;
    titleTemplate?: Maybe<Scalars['String']['output']>;
  };

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?node-id=1552%3A301865&mode=dev [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexRateAlerts) */
export type CexRateAlertsButtonTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?node-id=1552%3A301865&mode=dev [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexRateAlerts) */
export type CexRateAlertsDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?node-id=1552%3A301865&mode=dev [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexRateAlerts) */
export type CexRateAlertsInformationDialogArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?node-id=1552%3A301865&mode=dev [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexRateAlerts) */
export type CexRateAlertsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?node-id=1552%3A301865&mode=dev [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexRateAlerts) */
export type CexRateAlertsMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?node-id=1552%3A301865&mode=dev [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexRateAlerts) */
export type CexRateAlertsNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?node-id=1552%3A301865&mode=dev [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexRateAlerts) */
export type CexRateAlertsReceiveMethodLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?node-id=1552%3A301865&mode=dev [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexRateAlerts) */
export type CexRateAlertsTextFieldPlaceholderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?node-id=1552%3A301865&mode=dev [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexRateAlerts) */
export type CexRateAlertsTitleTemplateArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type CexRateAlertsCollection = {
  __typename?: 'CexRateAlertsCollection';
  items: Array<Maybe<CexRateAlerts>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CexRateAlertsFilter = {
  AND?: InputMaybe<Array<InputMaybe<CexRateAlertsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CexRateAlertsFilter>>>;
  buttonTitle?: InputMaybe<Scalars['String']['input']>;
  buttonTitle_contains?: InputMaybe<Scalars['String']['input']>;
  buttonTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  buttonTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  buttonTitle_not?: InputMaybe<Scalars['String']['input']>;
  buttonTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  buttonTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  informationDialog_exists?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  message_contains?: InputMaybe<Scalars['String']['input']>;
  message_exists?: InputMaybe<Scalars['Boolean']['input']>;
  message_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  message_not?: InputMaybe<Scalars['String']['input']>;
  message_not_contains?: InputMaybe<Scalars['String']['input']>;
  message_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  receiveMethodLabel?: InputMaybe<Scalars['String']['input']>;
  receiveMethodLabel_contains?: InputMaybe<Scalars['String']['input']>;
  receiveMethodLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  receiveMethodLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveMethodLabel_not?: InputMaybe<Scalars['String']['input']>;
  receiveMethodLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  receiveMethodLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  textFieldPlaceholder?: InputMaybe<Scalars['String']['input']>;
  textFieldPlaceholder_contains?: InputMaybe<Scalars['String']['input']>;
  textFieldPlaceholder_exists?: InputMaybe<Scalars['Boolean']['input']>;
  textFieldPlaceholder_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  textFieldPlaceholder_not?: InputMaybe<Scalars['String']['input']>;
  textFieldPlaceholder_not_contains?: InputMaybe<Scalars['String']['input']>;
  textFieldPlaceholder_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  titleTemplate?: InputMaybe<Scalars['String']['input']>;
  titleTemplate_contains?: InputMaybe<Scalars['String']['input']>;
  titleTemplate_exists?: InputMaybe<Scalars['Boolean']['input']>;
  titleTemplate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titleTemplate_not?: InputMaybe<Scalars['String']['input']>;
  titleTemplate_not_contains?: InputMaybe<Scalars['String']['input']>;
  titleTemplate_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
};

export type CexRateAlertsLinkingCollections = {
  __typename?: 'CexRateAlertsLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type CexRateAlertsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CexRateAlertsOrder {
  ButtonTitleAsc = 'buttonTitle_ASC',
  ButtonTitleDesc = 'buttonTitle_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  MessageAsc = 'message_ASC',
  MessageDesc = 'message_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ReceiveMethodLabelAsc = 'receiveMethodLabel_ASC',
  ReceiveMethodLabelDesc = 'receiveMethodLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextFieldPlaceholderAsc = 'textFieldPlaceholder_ASC',
  TextFieldPlaceholderDesc = 'textFieldPlaceholder_DESC',
  TitleTemplateAsc = 'titleTemplate_ASC',
  TitleTemplateDesc = 'titleTemplate_DESC',
}

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?type=design&node-id=175-120855&mode=design&t=i2HWZ0NYXymc1Suw-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexRateTable) */
export type CexRateTable = Entry &
  _Node & {
    __typename?: 'CexRateTable';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    conversionAmounts?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    linkedFrom?: Maybe<CexRateTableLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    tableSubtitleOne?: Maybe<Scalars['String']['output']>;
    tableSubtitleTwo?: Maybe<Scalars['String']['output']>;
    tableTitleLabel?: Maybe<Scalars['String']['output']>;
    title?: Maybe<CexRateTableTitle>;
  };

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?type=design&node-id=175-120855&mode=design&t=i2HWZ0NYXymc1Suw-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexRateTable) */
export type CexRateTableConversionAmountsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?type=design&node-id=175-120855&mode=design&t=i2HWZ0NYXymc1Suw-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexRateTable) */
export type CexRateTableLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?type=design&node-id=175-120855&mode=design&t=i2HWZ0NYXymc1Suw-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexRateTable) */
export type CexRateTableNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?type=design&node-id=175-120855&mode=design&t=i2HWZ0NYXymc1Suw-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexRateTable) */
export type CexRateTableTableSubtitleOneArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?type=design&node-id=175-120855&mode=design&t=i2HWZ0NYXymc1Suw-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexRateTable) */
export type CexRateTableTableSubtitleTwoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?type=design&node-id=175-120855&mode=design&t=i2HWZ0NYXymc1Suw-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexRateTable) */
export type CexRateTableTableTitleLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?type=design&node-id=175-120855&mode=design&t=i2HWZ0NYXymc1Suw-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexRateTable) */
export type CexRateTableTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type CexRateTableCollection = {
  __typename?: 'CexRateTableCollection';
  items: Array<Maybe<CexRateTable>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CexRateTableFilter = {
  AND?: InputMaybe<Array<InputMaybe<CexRateTableFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CexRateTableFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  conversionAmounts_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  conversionAmounts_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  conversionAmounts_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  conversionAmounts_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  tableSubtitleOne?: InputMaybe<Scalars['String']['input']>;
  tableSubtitleOne_contains?: InputMaybe<Scalars['String']['input']>;
  tableSubtitleOne_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tableSubtitleOne_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  tableSubtitleOne_not?: InputMaybe<Scalars['String']['input']>;
  tableSubtitleOne_not_contains?: InputMaybe<Scalars['String']['input']>;
  tableSubtitleOne_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  tableSubtitleTwo?: InputMaybe<Scalars['String']['input']>;
  tableSubtitleTwo_contains?: InputMaybe<Scalars['String']['input']>;
  tableSubtitleTwo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tableSubtitleTwo_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  tableSubtitleTwo_not?: InputMaybe<Scalars['String']['input']>;
  tableSubtitleTwo_not_contains?: InputMaybe<Scalars['String']['input']>;
  tableSubtitleTwo_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  tableTitleLabel?: InputMaybe<Scalars['String']['input']>;
  tableTitleLabel_contains?: InputMaybe<Scalars['String']['input']>;
  tableTitleLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tableTitleLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  tableTitleLabel_not?: InputMaybe<Scalars['String']['input']>;
  tableTitleLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  tableTitleLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
};

export type CexRateTableLinkingCollections = {
  __typename?: 'CexRateTableLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type CexRateTableLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CexRateTableOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TableSubtitleOneAsc = 'tableSubtitleOne_ASC',
  TableSubtitleOneDesc = 'tableSubtitleOne_DESC',
  TableSubtitleTwoAsc = 'tableSubtitleTwo_ASC',
  TableSubtitleTwoDesc = 'tableSubtitleTwo_DESC',
  TableTitleLabelAsc = 'tableTitleLabel_ASC',
  TableTitleLabelDesc = 'tableTitleLabel_DESC',
}

export type CexRateTableTitle = {
  __typename?: 'CexRateTableTitle';
  json: Scalars['JSON']['output'];
  links: CexRateTableTitleLinks;
};

export type CexRateTableTitleAssets = {
  __typename?: 'CexRateTableTitleAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type CexRateTableTitleEntries = {
  __typename?: 'CexRateTableTitleEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type CexRateTableTitleLinks = {
  __typename?: 'CexRateTableTitleLinks';
  assets: CexRateTableTitleAssets;
  entries: CexRateTableTitleEntries;
  resources: CexRateTableTitleResources;
};

export type CexRateTableTitleResources = {
  __typename?: 'CexRateTableTitleResources';
  block: Array<CexRateTableTitleResourcesBlock>;
  hyperlink: Array<CexRateTableTitleResourcesHyperlink>;
  inline: Array<CexRateTableTitleResourcesInline>;
};

export type CexRateTableTitleResourcesBlock = ResourceLink & {
  __typename?: 'CexRateTableTitleResourcesBlock';
  sys: ResourceSys;
};

export type CexRateTableTitleResourcesHyperlink = ResourceLink & {
  __typename?: 'CexRateTableTitleResourcesHyperlink';
  sys: ResourceSys;
};

export type CexRateTableTitleResourcesInline = ResourceLink & {
  __typename?: 'CexRateTableTitleResourcesInline';
  sys: ResourceSys;
};

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?type=design&node-id=2088%3A504361&mode=dev [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexSearch) */
export type CexSearch = Entry &
  _Node & {
    __typename?: 'CexSearch';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<CexSearchLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    searchInputLabel?: Maybe<Scalars['String']['output']>;
    searchResultListLabel?: Maybe<Scalars['String']['output']>;
    showCurrencyGrid?: Maybe<Scalars['Boolean']['output']>;
    sys: Sys;
    titleTemplate?: Maybe<CexSearchTitleTemplate>;
  };

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?type=design&node-id=2088%3A504361&mode=dev [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexSearch) */
export type CexSearchLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?type=design&node-id=2088%3A504361&mode=dev [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexSearch) */
export type CexSearchNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?type=design&node-id=2088%3A504361&mode=dev [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexSearch) */
export type CexSearchSearchInputLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?type=design&node-id=2088%3A504361&mode=dev [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexSearch) */
export type CexSearchSearchResultListLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?type=design&node-id=2088%3A504361&mode=dev [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexSearch) */
export type CexSearchShowCurrencyGridArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?type=design&node-id=2088%3A504361&mode=dev [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/cexSearch) */
export type CexSearchTitleTemplateArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type CexSearchCollection = {
  __typename?: 'CexSearchCollection';
  items: Array<Maybe<CexSearch>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CexSearchFilter = {
  AND?: InputMaybe<Array<InputMaybe<CexSearchFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CexSearchFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  searchInputLabel?: InputMaybe<Scalars['String']['input']>;
  searchInputLabel_contains?: InputMaybe<Scalars['String']['input']>;
  searchInputLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  searchInputLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  searchInputLabel_not?: InputMaybe<Scalars['String']['input']>;
  searchInputLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  searchInputLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  searchResultListLabel?: InputMaybe<Scalars['String']['input']>;
  searchResultListLabel_contains?: InputMaybe<Scalars['String']['input']>;
  searchResultListLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  searchResultListLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  searchResultListLabel_not?: InputMaybe<Scalars['String']['input']>;
  searchResultListLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  searchResultListLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  showCurrencyGrid?: InputMaybe<Scalars['Boolean']['input']>;
  showCurrencyGrid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  showCurrencyGrid_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  titleTemplate_contains?: InputMaybe<Scalars['String']['input']>;
  titleTemplate_exists?: InputMaybe<Scalars['Boolean']['input']>;
  titleTemplate_not_contains?: InputMaybe<Scalars['String']['input']>;
};

export type CexSearchLinkingCollections = {
  __typename?: 'CexSearchLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type CexSearchLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CexSearchOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SearchInputLabelAsc = 'searchInputLabel_ASC',
  SearchInputLabelDesc = 'searchInputLabel_DESC',
  SearchResultListLabelAsc = 'searchResultListLabel_ASC',
  SearchResultListLabelDesc = 'searchResultListLabel_DESC',
  ShowCurrencyGridAsc = 'showCurrencyGrid_ASC',
  ShowCurrencyGridDesc = 'showCurrencyGrid_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CexSearchTitleTemplate = {
  __typename?: 'CexSearchTitleTemplate';
  json: Scalars['JSON']['output'];
  links: CexSearchTitleTemplateLinks;
};

export type CexSearchTitleTemplateAssets = {
  __typename?: 'CexSearchTitleTemplateAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type CexSearchTitleTemplateEntries = {
  __typename?: 'CexSearchTitleTemplateEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type CexSearchTitleTemplateLinks = {
  __typename?: 'CexSearchTitleTemplateLinks';
  assets: CexSearchTitleTemplateAssets;
  entries: CexSearchTitleTemplateEntries;
  resources: CexSearchTitleTemplateResources;
};

export type CexSearchTitleTemplateResources = {
  __typename?: 'CexSearchTitleTemplateResources';
  block: Array<CexSearchTitleTemplateResourcesBlock>;
  hyperlink: Array<CexSearchTitleTemplateResourcesHyperlink>;
  inline: Array<CexSearchTitleTemplateResourcesInline>;
};

export type CexSearchTitleTemplateResourcesBlock = ResourceLink & {
  __typename?: 'CexSearchTitleTemplateResourcesBlock';
  sys: ResourceSys;
};

export type CexSearchTitleTemplateResourcesHyperlink = ResourceLink & {
  __typename?: 'CexSearchTitleTemplateResourcesHyperlink';
  sys: ResourceSys;
};

export type CexSearchTitleTemplateResourcesInline = ResourceLink & {
  __typename?: 'CexSearchTitleTemplateResourcesInline';
  sys: ResourceSys;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/changePasswordPage) */
export type ChangePasswordPage = Entry &
  _Node & {
    __typename?: 'ChangePasswordPage';
    _id: Scalars['ID']['output'];
    button?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    errorDescription?: Maybe<Scalars['String']['output']>;
    header?: Maybe<Scalars['String']['output']>;
    helpText?: Maybe<ChangePasswordPageHelpText>;
    label?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<ChangePasswordPageLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    successInfo?: Maybe<ActionResult>;
    sys: Sys;
    validationLowerCaseLetterLabel?: Maybe<Scalars['String']['output']>;
    validationMinimumCharactersLabel?: Maybe<Scalars['String']['output']>;
    validationNumericNumberLabel?: Maybe<Scalars['String']['output']>;
    validationUpperCaseLetterLabel?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/changePasswordPage) */
export type ChangePasswordPageButtonArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/changePasswordPage) */
export type ChangePasswordPageErrorDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/changePasswordPage) */
export type ChangePasswordPageHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/changePasswordPage) */
export type ChangePasswordPageHelpTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/changePasswordPage) */
export type ChangePasswordPageLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/changePasswordPage) */
export type ChangePasswordPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/changePasswordPage) */
export type ChangePasswordPageNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/changePasswordPage) */
export type ChangePasswordPageSuccessInfoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ActionResultFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/changePasswordPage) */
export type ChangePasswordPageValidationLowerCaseLetterLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/changePasswordPage) */
export type ChangePasswordPageValidationMinimumCharactersLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/changePasswordPage) */
export type ChangePasswordPageValidationNumericNumberLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/changePasswordPage) */
export type ChangePasswordPageValidationUpperCaseLetterLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type ChangePasswordPageCollection = {
  __typename?: 'ChangePasswordPageCollection';
  items: Array<Maybe<ChangePasswordPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ChangePasswordPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ChangePasswordPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ChangePasswordPageFilter>>>;
  button?: InputMaybe<Scalars['String']['input']>;
  button_contains?: InputMaybe<Scalars['String']['input']>;
  button_exists?: InputMaybe<Scalars['Boolean']['input']>;
  button_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  button_not?: InputMaybe<Scalars['String']['input']>;
  button_not_contains?: InputMaybe<Scalars['String']['input']>;
  button_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  errorDescription?: InputMaybe<Scalars['String']['input']>;
  errorDescription_contains?: InputMaybe<Scalars['String']['input']>;
  errorDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  errorDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  errorDescription_not?: InputMaybe<Scalars['String']['input']>;
  errorDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  errorDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  helpText_contains?: InputMaybe<Scalars['String']['input']>;
  helpText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  helpText_not_contains?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  successInfo?: InputMaybe<CfActionResultNestedFilter>;
  successInfo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  validationLowerCaseLetterLabel?: InputMaybe<Scalars['String']['input']>;
  validationLowerCaseLetterLabel_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  validationLowerCaseLetterLabel_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  validationLowerCaseLetterLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  validationLowerCaseLetterLabel_not?: InputMaybe<Scalars['String']['input']>;
  validationLowerCaseLetterLabel_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  validationLowerCaseLetterLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  validationMinimumCharactersLabel?: InputMaybe<Scalars['String']['input']>;
  validationMinimumCharactersLabel_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  validationMinimumCharactersLabel_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  validationMinimumCharactersLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  validationMinimumCharactersLabel_not?: InputMaybe<Scalars['String']['input']>;
  validationMinimumCharactersLabel_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  validationMinimumCharactersLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  validationNumericNumberLabel?: InputMaybe<Scalars['String']['input']>;
  validationNumericNumberLabel_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  validationNumericNumberLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  validationNumericNumberLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  validationNumericNumberLabel_not?: InputMaybe<Scalars['String']['input']>;
  validationNumericNumberLabel_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  validationNumericNumberLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  validationUpperCaseLetterLabel?: InputMaybe<Scalars['String']['input']>;
  validationUpperCaseLetterLabel_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  validationUpperCaseLetterLabel_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  validationUpperCaseLetterLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  validationUpperCaseLetterLabel_not?: InputMaybe<Scalars['String']['input']>;
  validationUpperCaseLetterLabel_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  validationUpperCaseLetterLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
};

export type ChangePasswordPageHelpText = {
  __typename?: 'ChangePasswordPageHelpText';
  json: Scalars['JSON']['output'];
  links: ChangePasswordPageHelpTextLinks;
};

export type ChangePasswordPageHelpTextAssets = {
  __typename?: 'ChangePasswordPageHelpTextAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type ChangePasswordPageHelpTextEntries = {
  __typename?: 'ChangePasswordPageHelpTextEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type ChangePasswordPageHelpTextLinks = {
  __typename?: 'ChangePasswordPageHelpTextLinks';
  assets: ChangePasswordPageHelpTextAssets;
  entries: ChangePasswordPageHelpTextEntries;
  resources: ChangePasswordPageHelpTextResources;
};

export type ChangePasswordPageHelpTextResources = {
  __typename?: 'ChangePasswordPageHelpTextResources';
  block: Array<ChangePasswordPageHelpTextResourcesBlock>;
  hyperlink: Array<ChangePasswordPageHelpTextResourcesHyperlink>;
  inline: Array<ChangePasswordPageHelpTextResourcesInline>;
};

export type ChangePasswordPageHelpTextResourcesBlock = ResourceLink & {
  __typename?: 'ChangePasswordPageHelpTextResourcesBlock';
  sys: ResourceSys;
};

export type ChangePasswordPageHelpTextResourcesHyperlink = ResourceLink & {
  __typename?: 'ChangePasswordPageHelpTextResourcesHyperlink';
  sys: ResourceSys;
};

export type ChangePasswordPageHelpTextResourcesInline = ResourceLink & {
  __typename?: 'ChangePasswordPageHelpTextResourcesInline';
  sys: ResourceSys;
};

export type ChangePasswordPageLinkingCollections = {
  __typename?: 'ChangePasswordPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type ChangePasswordPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ChangePasswordPageOrder {
  ButtonAsc = 'button_ASC',
  ButtonDesc = 'button_DESC',
  ErrorDescriptionAsc = 'errorDescription_ASC',
  ErrorDescriptionDesc = 'errorDescription_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValidationLowerCaseLetterLabelAsc = 'validationLowerCaseLetterLabel_ASC',
  ValidationLowerCaseLetterLabelDesc = 'validationLowerCaseLetterLabel_DESC',
  ValidationMinimumCharactersLabelAsc = 'validationMinimumCharactersLabel_ASC',
  ValidationMinimumCharactersLabelDesc = 'validationMinimumCharactersLabel_DESC',
  ValidationNumericNumberLabelAsc = 'validationNumericNumberLabel_ASC',
  ValidationNumericNumberLabelDesc = 'validationNumericNumberLabel_DESC',
  ValidationUpperCaseLetterLabelAsc = 'validationUpperCaseLetterLabel_ASC',
  ValidationUpperCaseLetterLabelDesc = 'validationUpperCaseLetterLabel_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTable) */
export type ComparisonTable = Entry &
  _Node & {
    __typename?: 'ComparisonTable';
    _id: Scalars['ID']['output'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    caption?: Maybe<Scalars['String']['output']>;
    content?: Maybe<ComparisonTableContent>;
    contentfulMetadata: ContentfulMetadata;
    footer?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<ComparisonTableLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    providersLabel?: Maybe<Scalars['String']['output']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    rowsCollection?: Maybe<ComparisonTableRowsCollection>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTable) */
export type ComparisonTableAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTable) */
export type ComparisonTableAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTable) */
export type ComparisonTableCaptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTable) */
export type ComparisonTableContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTable) */
export type ComparisonTableFooterArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTable) */
export type ComparisonTableLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTable) */
export type ComparisonTableNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTable) */
export type ComparisonTableProvidersLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTable) */
export type ComparisonTableReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTable) */
export type ComparisonTableRowsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ComparisonTableRowsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ComparisonTableRowFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTable) */
export type ComparisonTableSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTableCell) */
export type ComparisonTableCell = Entry &
  _Node & {
    __typename?: 'ComparisonTableCell';
    _id: Scalars['ID']['output'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    amount?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    fee?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<ComparisonTableCellLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTableCell) */
export type ComparisonTableCellAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTableCell) */
export type ComparisonTableCellAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTableCell) */
export type ComparisonTableCellAmountArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTableCell) */
export type ComparisonTableCellFeeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTableCell) */
export type ComparisonTableCellLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTableCell) */
export type ComparisonTableCellNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTableCell) */
export type ComparisonTableCellReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTableCell) */
export type ComparisonTableCellSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type ComparisonTableCellCollection = {
  __typename?: 'ComparisonTableCellCollection';
  items: Array<Maybe<ComparisonTableCell>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ComparisonTableCellFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComparisonTableCellFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ComparisonTableCellFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['String']['input']>;
  amount_contains?: InputMaybe<Scalars['String']['input']>;
  amount_exists?: InputMaybe<Scalars['Boolean']['input']>;
  amount_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  amount_not?: InputMaybe<Scalars['String']['input']>;
  amount_not_contains?: InputMaybe<Scalars['String']['input']>;
  amount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  fee?: InputMaybe<Scalars['String']['input']>;
  fee_contains?: InputMaybe<Scalars['String']['input']>;
  fee_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fee_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fee_not?: InputMaybe<Scalars['String']['input']>;
  fee_not_contains?: InputMaybe<Scalars['String']['input']>;
  fee_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type ComparisonTableCellLinkingCollections = {
  __typename?: 'ComparisonTableCellLinkingCollections';
  comparisonTableRowCollection?: Maybe<ComparisonTableRowCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type ComparisonTableCellLinkingCollectionsComparisonTableRowCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<ComparisonTableCellLinkingCollectionsComparisonTableRowCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ComparisonTableCellLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ComparisonTableCellLinkingCollectionsComparisonTableRowCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComparisonTableCellOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  FeeAsc = 'fee_ASC',
  FeeDesc = 'fee_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComparisonTableCollection = {
  __typename?: 'ComparisonTableCollection';
  items: Array<Maybe<ComparisonTable>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ComparisonTableContent = {
  __typename?: 'ComparisonTableContent';
  json: Scalars['JSON']['output'];
  links: ComparisonTableContentLinks;
};

export type ComparisonTableContentAssets = {
  __typename?: 'ComparisonTableContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type ComparisonTableContentEntries = {
  __typename?: 'ComparisonTableContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type ComparisonTableContentLinks = {
  __typename?: 'ComparisonTableContentLinks';
  assets: ComparisonTableContentAssets;
  entries: ComparisonTableContentEntries;
  resources: ComparisonTableContentResources;
};

export type ComparisonTableContentResources = {
  __typename?: 'ComparisonTableContentResources';
  block: Array<ComparisonTableContentResourcesBlock>;
  hyperlink: Array<ComparisonTableContentResourcesHyperlink>;
  inline: Array<ComparisonTableContentResourcesInline>;
};

export type ComparisonTableContentResourcesBlock = ResourceLink & {
  __typename?: 'ComparisonTableContentResourcesBlock';
  sys: ResourceSys;
};

export type ComparisonTableContentResourcesHyperlink = ResourceLink & {
  __typename?: 'ComparisonTableContentResourcesHyperlink';
  sys: ResourceSys;
};

export type ComparisonTableContentResourcesInline = ResourceLink & {
  __typename?: 'ComparisonTableContentResourcesInline';
  sys: ResourceSys;
};

export type ComparisonTableFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComparisonTableFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ComparisonTableFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_contains?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_not?: InputMaybe<Scalars['String']['input']>;
  caption_not_contains?: InputMaybe<Scalars['String']['input']>;
  caption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  content_contains?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  footer?: InputMaybe<Scalars['String']['input']>;
  footer_contains?: InputMaybe<Scalars['String']['input']>;
  footer_exists?: InputMaybe<Scalars['Boolean']['input']>;
  footer_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  footer_not?: InputMaybe<Scalars['String']['input']>;
  footer_not_contains?: InputMaybe<Scalars['String']['input']>;
  footer_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providersLabel?: InputMaybe<Scalars['String']['input']>;
  providersLabel_contains?: InputMaybe<Scalars['String']['input']>;
  providersLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  providersLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providersLabel_not?: InputMaybe<Scalars['String']['input']>;
  providersLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  providersLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  rows?: InputMaybe<CfComparisonTableRowNestedFilter>;
  rowsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type ComparisonTableLinkingCollections = {
  __typename?: 'ComparisonTableLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pageSectionCollection?: Maybe<PageSectionCollection>;
};

export type ComparisonTableLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ComparisonTableLinkingCollectionsPageSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<ComparisonTableLinkingCollectionsPageSectionCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ComparisonTableLinkingCollectionsPageSectionCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundGradientAsc = 'backgroundGradient_ASC',
  BackgroundGradientDesc = 'backgroundGradient_DESC',
  BackgroundTypeAsc = 'backgroundType_ASC',
  BackgroundTypeDesc = 'backgroundType_DESC',
  EnablePaddingAsc = 'enablePadding_ASC',
  EnablePaddingDesc = 'enablePadding_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComparisonTableOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  CaptionAsc = 'caption_ASC',
  CaptionDesc = 'caption_DESC',
  FooterAsc = 'footer_ASC',
  FooterDesc = 'footer_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ProvidersLabelAsc = 'providersLabel_ASC',
  ProvidersLabelDesc = 'providersLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTableRow) */
export type ComparisonTableRow = Entry &
  _Node & {
    __typename?: 'ComparisonTableRow';
    _id: Scalars['ID']['output'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    atpCollection?: Maybe<ComparisonTableRowAtpCollection>;
    bnkCollection?: Maybe<ComparisonTableRowBnkCollection>;
    contentfulMetadata: ContentfulMetadata;
    cshCollection?: Maybe<ComparisonTableRowCshCollection>;
    linkedFrom?: Maybe<ComparisonTableRowLinkingCollections>;
    mobCollection?: Maybe<ComparisonTableRowMobCollection>;
    name?: Maybe<Scalars['String']['output']>;
    provider?: Maybe<Asset>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTableRow) */
export type ComparisonTableRowAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTableRow) */
export type ComparisonTableRowAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTableRow) */
export type ComparisonTableRowAtpCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ComparisonTableRowAtpCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ComparisonTableCellFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTableRow) */
export type ComparisonTableRowBnkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ComparisonTableRowBnkCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ComparisonTableCellFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTableRow) */
export type ComparisonTableRowCshCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ComparisonTableRowCshCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ComparisonTableCellFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTableRow) */
export type ComparisonTableRowLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTableRow) */
export type ComparisonTableRowMobCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ComparisonTableRowMobCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ComparisonTableCellFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTableRow) */
export type ComparisonTableRowNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTableRow) */
export type ComparisonTableRowProviderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTableRow) */
export type ComparisonTableRowReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/comparisonTableRow) */
export type ComparisonTableRowSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type ComparisonTableRowAtpCollection = {
  __typename?: 'ComparisonTableRowATPCollection';
  items: Array<Maybe<ComparisonTableCell>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ComparisonTableRowAtpCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  FeeAsc = 'fee_ASC',
  FeeDesc = 'fee_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComparisonTableRowBnkCollection = {
  __typename?: 'ComparisonTableRowBNKCollection';
  items: Array<Maybe<ComparisonTableCell>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ComparisonTableRowBnkCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  FeeAsc = 'fee_ASC',
  FeeDesc = 'fee_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComparisonTableRowCshCollection = {
  __typename?: 'ComparisonTableRowCSHCollection';
  items: Array<Maybe<ComparisonTableCell>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ComparisonTableRowCshCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  FeeAsc = 'fee_ASC',
  FeeDesc = 'fee_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComparisonTableRowCollection = {
  __typename?: 'ComparisonTableRowCollection';
  items: Array<Maybe<ComparisonTableRow>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ComparisonTableRowFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComparisonTableRowFilter>>>;
  ATP?: InputMaybe<CfComparisonTableCellNestedFilter>;
  ATPCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  BNK?: InputMaybe<CfComparisonTableCellNestedFilter>;
  BNKCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  CSH?: InputMaybe<CfComparisonTableCellNestedFilter>;
  CSHCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  MOB?: InputMaybe<CfComparisonTableCellNestedFilter>;
  MOBCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  OR?: InputMaybe<Array<InputMaybe<ComparisonTableRowFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  provider_exists?: InputMaybe<Scalars['Boolean']['input']>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type ComparisonTableRowLinkingCollections = {
  __typename?: 'ComparisonTableRowLinkingCollections';
  comparisonTableCollection?: Maybe<ComparisonTableCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type ComparisonTableRowLinkingCollectionsComparisonTableCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<ComparisonTableRowLinkingCollectionsComparisonTableCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ComparisonTableRowLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ComparisonTableRowLinkingCollectionsComparisonTableCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  CaptionAsc = 'caption_ASC',
  CaptionDesc = 'caption_DESC',
  FooterAsc = 'footer_ASC',
  FooterDesc = 'footer_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ProvidersLabelAsc = 'providersLabel_ASC',
  ProvidersLabelDesc = 'providersLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComparisonTableRowMobCollection = {
  __typename?: 'ComparisonTableRowMOBCollection';
  items: Array<Maybe<ComparisonTableCell>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ComparisonTableRowMobCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  FeeAsc = 'fee_ASC',
  FeeDesc = 'fee_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComparisonTableRowOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComparisonTableRowsCollection = {
  __typename?: 'ComparisonTableRowsCollection';
  items: Array<Maybe<ComparisonTableRow>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ComparisonTableRowsCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ContentfulMetadata = {
  __typename?: 'ContentfulMetadata';
  concepts: Array<Maybe<TaxonomyConcept>>;
  tags: Array<Maybe<ContentfulTag>>;
};

export type ContentfulMetadataConceptsDescendantsFilter = {
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulMetadataConceptsFilter = {
  descendants?: InputMaybe<ContentfulMetadataConceptsDescendantsFilter>;
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulMetadataFilter = {
  concepts?: InputMaybe<ContentfulMetadataConceptsFilter>;
  concepts_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<ContentfulMetadataTagsFilter>;
  tags_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentfulMetadataTagsFilter = {
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/**
 * Represents a tag entity for finding and organizing content easily.
 *       Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulTag = {
  __typename?: 'ContentfulTag';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** [web-cms] [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/contextualPromoBanner) */
export type ContextualPromoBanner = Entry &
  _Node & {
    __typename?: 'ContextualPromoBanner';
    _id: Scalars['ID']['output'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    backgroundColor?: Maybe<Scalars['String']['output']>;
    button?: Maybe<Link>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<ContextualPromoBannerDescription>;
    disclaimer?: Maybe<Scalars['String']['output']>;
    horizontalBackgroundImage?: Maybe<Asset>;
    link?: Maybe<Link>;
    linkedFrom?: Maybe<ContextualPromoBannerLinkingCollections>;
    mobileBackgroundImage?: Maybe<Asset>;
    name?: Maybe<Scalars['String']['output']>;
    promoIcon?: Maybe<Asset>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sys: Sys;
    verticalBackgroundImage?: Maybe<Asset>;
  };

/** [web-cms] [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/contextualPromoBanner) */
export type ContextualPromoBannerAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/contextualPromoBanner) */
export type ContextualPromoBannerAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/contextualPromoBanner) */
export type ContextualPromoBannerBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/contextualPromoBanner) */
export type ContextualPromoBannerButtonArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-cms] [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/contextualPromoBanner) */
export type ContextualPromoBannerDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/contextualPromoBanner) */
export type ContextualPromoBannerDisclaimerArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/contextualPromoBanner) */
export type ContextualPromoBannerHorizontalBackgroundImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [web-cms] [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/contextualPromoBanner) */
export type ContextualPromoBannerLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-cms] [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/contextualPromoBanner) */
export type ContextualPromoBannerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-cms] [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/contextualPromoBanner) */
export type ContextualPromoBannerMobileBackgroundImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [web-cms] [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/contextualPromoBanner) */
export type ContextualPromoBannerNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/contextualPromoBanner) */
export type ContextualPromoBannerPromoIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [web-cms] [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/contextualPromoBanner) */
export type ContextualPromoBannerReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/contextualPromoBanner) */
export type ContextualPromoBannerSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/contextualPromoBanner) */
export type ContextualPromoBannerVerticalBackgroundImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContextualPromoBannerCollection = {
  __typename?: 'ContextualPromoBannerCollection';
  items: Array<Maybe<ContextualPromoBanner>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContextualPromoBannerDescription = {
  __typename?: 'ContextualPromoBannerDescription';
  json: Scalars['JSON']['output'];
  links: ContextualPromoBannerDescriptionLinks;
};

export type ContextualPromoBannerDescriptionAssets = {
  __typename?: 'ContextualPromoBannerDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type ContextualPromoBannerDescriptionEntries = {
  __typename?: 'ContextualPromoBannerDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type ContextualPromoBannerDescriptionLinks = {
  __typename?: 'ContextualPromoBannerDescriptionLinks';
  assets: ContextualPromoBannerDescriptionAssets;
  entries: ContextualPromoBannerDescriptionEntries;
  resources: ContextualPromoBannerDescriptionResources;
};

export type ContextualPromoBannerDescriptionResources = {
  __typename?: 'ContextualPromoBannerDescriptionResources';
  block: Array<ContextualPromoBannerDescriptionResourcesBlock>;
  hyperlink: Array<ContextualPromoBannerDescriptionResourcesHyperlink>;
  inline: Array<ContextualPromoBannerDescriptionResourcesInline>;
};

export type ContextualPromoBannerDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'ContextualPromoBannerDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type ContextualPromoBannerDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'ContextualPromoBannerDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type ContextualPromoBannerDescriptionResourcesInline = ResourceLink & {
  __typename?: 'ContextualPromoBannerDescriptionResourcesInline';
  sys: ResourceSys;
};

export type ContextualPromoBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContextualPromoBannerFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ContextualPromoBannerFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundColor_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  button?: InputMaybe<CfLinkNestedFilter>;
  button_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  disclaimer?: InputMaybe<Scalars['String']['input']>;
  disclaimer_contains?: InputMaybe<Scalars['String']['input']>;
  disclaimer_exists?: InputMaybe<Scalars['Boolean']['input']>;
  disclaimer_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  disclaimer_not?: InputMaybe<Scalars['String']['input']>;
  disclaimer_not_contains?: InputMaybe<Scalars['String']['input']>;
  disclaimer_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  horizontalBackgroundImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link?: InputMaybe<CfLinkNestedFilter>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileBackgroundImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  promoIcon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  verticalBackgroundImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContextualPromoBannerLinkingCollections = {
  __typename?: 'ContextualPromoBannerLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type ContextualPromoBannerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContextualPromoBannerOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  DisclaimerAsc = 'disclaimer_ASC',
  DisclaimerDesc = 'disclaimer_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondent) */
export type Correspondent = Entry &
  _Node & {
    __typename?: 'Correspondent';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    id?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<CorrespondentLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondent) */
export type CorrespondentIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondent) */
export type CorrespondentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondent) */
export type CorrespondentNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondent) */
export type CorrespondentTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type CorrespondentCollection = {
  __typename?: 'CorrespondentCollection';
  items: Array<Maybe<Correspondent>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CorrespondentFilter = {
  AND?: InputMaybe<Array<InputMaybe<CorrespondentFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CorrespondentFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CorrespondentLinkingCollections = {
  __typename?: 'CorrespondentLinkingCollections';
  countryPayoutMethodCollection?: Maybe<CountryPayoutMethodCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type CorrespondentLinkingCollectionsCountryPayoutMethodCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<CorrespondentLinkingCollectionsCountryPayoutMethodCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CorrespondentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CorrespondentLinkingCollectionsCountryPayoutMethodCollectionOrder {
  CountryCodeAsc = 'countryCode_ASC',
  CountryCodeDesc = 'countryCode_DESC',
  CurrencyCodeAsc = 'currencyCode_ASC',
  CurrencyCodeDesc = 'currencyCode_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PayoutMethodAsc = 'payoutMethod_ASC',
  PayoutMethodDesc = 'payoutMethod_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondentLocations) */
export type CorrespondentLocations = Entry &
  _Node & {
    __typename?: 'CorrespondentLocations';
    _id: Scalars['ID']['output'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    contentfulMetadata: ContentfulMetadata;
    ctaButton?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<CorrespondentLocationsLinkingCollections>;
    locationDetailsIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    locationDetailsNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    name?: Maybe<Scalars['String']['output']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sys: Sys;
    tableHeading?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondentLocations) */
export type CorrespondentLocationsAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondentLocations) */
export type CorrespondentLocationsAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondentLocations) */
export type CorrespondentLocationsCtaButtonArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondentLocations) */
export type CorrespondentLocationsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondentLocations) */
export type CorrespondentLocationsLocationDetailsIdsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondentLocations) */
export type CorrespondentLocationsLocationDetailsNamesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondentLocations) */
export type CorrespondentLocationsNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondentLocations) */
export type CorrespondentLocationsReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondentLocations) */
export type CorrespondentLocationsSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondentLocations) */
export type CorrespondentLocationsTableHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondentLocations) */
export type CorrespondentLocationsTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type CorrespondentLocationsCollection = {
  __typename?: 'CorrespondentLocationsCollection';
  items: Array<Maybe<CorrespondentLocations>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CorrespondentLocationsFilter = {
  AND?: InputMaybe<Array<InputMaybe<CorrespondentLocationsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CorrespondentLocationsFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  ctaButton?: InputMaybe<Scalars['String']['input']>;
  ctaButton_contains?: InputMaybe<Scalars['String']['input']>;
  ctaButton_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ctaButton_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ctaButton_not?: InputMaybe<Scalars['String']['input']>;
  ctaButton_not_contains?: InputMaybe<Scalars['String']['input']>;
  ctaButton_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locationDetailsIds_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  locationDetailsIds_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  locationDetailsIds_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  locationDetailsIds_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locationDetailsNames_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  locationDetailsNames_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  locationDetailsNames_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  locationDetailsNames_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  tableHeading?: InputMaybe<Scalars['String']['input']>;
  tableHeading_contains?: InputMaybe<Scalars['String']['input']>;
  tableHeading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tableHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tableHeading_not?: InputMaybe<Scalars['String']['input']>;
  tableHeading_not_contains?: InputMaybe<Scalars['String']['input']>;
  tableHeading_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CorrespondentLocationsLinkingCollections = {
  __typename?: 'CorrespondentLocationsLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type CorrespondentLocationsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CorrespondentLocationsOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  CtaButtonAsc = 'ctaButton_ASC',
  CtaButtonDesc = 'ctaButton_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TableHeadingAsc = 'tableHeading_ASC',
  TableHeadingDesc = 'tableHeading_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CorrespondentOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondents) */
export type Correspondents = Entry &
  _Node & {
    __typename?: 'Correspondents';
    _id: Scalars['ID']['output'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<CorrespondentsLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    subtitle?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondents) */
export type CorrespondentsAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondents) */
export type CorrespondentsAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondents) */
export type CorrespondentsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondents) */
export type CorrespondentsNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondents) */
export type CorrespondentsReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondents) */
export type CorrespondentsSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondents) */
export type CorrespondentsSubtitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/correspondents) */
export type CorrespondentsTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type CorrespondentsCollection = {
  __typename?: 'CorrespondentsCollection';
  items: Array<Maybe<Correspondents>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CorrespondentsFilter = {
  AND?: InputMaybe<Array<InputMaybe<CorrespondentsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CorrespondentsFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  subtitle_contains?: InputMaybe<Scalars['String']['input']>;
  subtitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subtitle_not?: InputMaybe<Scalars['String']['input']>;
  subtitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CorrespondentsLinkingCollections = {
  __typename?: 'CorrespondentsLinkingCollections';
  calculatorPageCollection?: Maybe<CalculatorPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type CorrespondentsLinkingCollectionsCalculatorPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<CorrespondentsLinkingCollectionsCalculatorPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CorrespondentsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CorrespondentsLinkingCollectionsCalculatorPageCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IsTopPageAsc = 'isTopPage_ASC',
  IsTopPageDesc = 'isTopPage_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CorrespondentsOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/country) */
export type Country = Entry &
  _Node & {
    __typename?: 'Country';
    _id: Scalars['ID']['output'];
    article?: Maybe<Scalars['String']['output']>;
    calculatorReceiveCountry?: Maybe<Country>;
    code?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    link?: Maybe<Link>;
    linkedFrom?: Maybe<CountryLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    prepositionFrom?: Maybe<Scalars['String']['output']>;
    prepositionIn?: Maybe<Scalars['String']['output']>;
    prepositionTo?: Maybe<Scalars['String']['output']>;
    slug?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/country) */
export type CountryArticleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/country) */
export type CountryCalculatorReceiveCountryArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CountryFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/country) */
export type CountryCodeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/country) */
export type CountryLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/country) */
export type CountryLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/country) */
export type CountryNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/country) */
export type CountryPrepositionFromArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/country) */
export type CountryPrepositionInArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/country) */
export type CountryPrepositionToArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/country) */
export type CountrySlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type CountryCollection = {
  __typename?: 'CountryCollection';
  items: Array<Maybe<Country>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CountryFilter = {
  AND?: InputMaybe<Array<InputMaybe<CountryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CountryFilter>>>;
  article?: InputMaybe<Scalars['String']['input']>;
  article_contains?: InputMaybe<Scalars['String']['input']>;
  article_exists?: InputMaybe<Scalars['Boolean']['input']>;
  article_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  article_not?: InputMaybe<Scalars['String']['input']>;
  article_not_contains?: InputMaybe<Scalars['String']['input']>;
  article_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  calculatorReceiveCountry?: InputMaybe<CfCountryNestedFilter>;
  calculatorReceiveCountry_exists?: InputMaybe<Scalars['Boolean']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  code_contains?: InputMaybe<Scalars['String']['input']>;
  code_exists?: InputMaybe<Scalars['Boolean']['input']>;
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  code_not?: InputMaybe<Scalars['String']['input']>;
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  link?: InputMaybe<CfLinkNestedFilter>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  prepositionFrom?: InputMaybe<Scalars['String']['input']>;
  prepositionFrom_contains?: InputMaybe<Scalars['String']['input']>;
  prepositionFrom_exists?: InputMaybe<Scalars['Boolean']['input']>;
  prepositionFrom_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  prepositionFrom_not?: InputMaybe<Scalars['String']['input']>;
  prepositionFrom_not_contains?: InputMaybe<Scalars['String']['input']>;
  prepositionFrom_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  prepositionIn?: InputMaybe<Scalars['String']['input']>;
  prepositionIn_contains?: InputMaybe<Scalars['String']['input']>;
  prepositionIn_exists?: InputMaybe<Scalars['Boolean']['input']>;
  prepositionIn_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  prepositionIn_not?: InputMaybe<Scalars['String']['input']>;
  prepositionIn_not_contains?: InputMaybe<Scalars['String']['input']>;
  prepositionIn_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  prepositionTo?: InputMaybe<Scalars['String']['input']>;
  prepositionTo_contains?: InputMaybe<Scalars['String']['input']>;
  prepositionTo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  prepositionTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  prepositionTo_not?: InputMaybe<Scalars['String']['input']>;
  prepositionTo_not_contains?: InputMaybe<Scalars['String']['input']>;
  prepositionTo_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CountryLinkingCollections = {
  __typename?: 'CountryLinkingCollections';
  countryCollection?: Maybe<CountryCollection>;
  entryCollection?: Maybe<EntryCollection>;
  listOfCountriesCollection?: Maybe<ListOfCountriesCollection>;
  pageSectionCollection?: Maybe<PageSectionCollection>;
  payoutMethodAlertCollection?: Maybe<PayoutMethodAlertCollection>;
  payoutMethodCollection?: Maybe<PayoutMethodCollection>;
  referAFriendGreenfieldEnabledCountriesCollection?: Maybe<ReferAFriendGreenfieldEnabledCountriesCollection>;
  regulatorCollection?: Maybe<RegulatorCollection>;
  stickyBannerCollection?: Maybe<StickyBannerCollection>;
};

export type CountryLinkingCollectionsCountryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<CountryLinkingCollectionsCountryCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CountryLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CountryLinkingCollectionsListOfCountriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<CountryLinkingCollectionsListOfCountriesCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CountryLinkingCollectionsPageSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<CountryLinkingCollectionsPageSectionCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CountryLinkingCollectionsPayoutMethodAlertCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<CountryLinkingCollectionsPayoutMethodAlertCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CountryLinkingCollectionsPayoutMethodCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<CountryLinkingCollectionsPayoutMethodCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CountryLinkingCollectionsReferAFriendGreenfieldEnabledCountriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<CountryLinkingCollectionsReferAFriendGreenfieldEnabledCountriesCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CountryLinkingCollectionsRegulatorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<CountryLinkingCollectionsRegulatorCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CountryLinkingCollectionsStickyBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<CountryLinkingCollectionsStickyBannerCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CountryLinkingCollectionsCountryCollectionOrder {
  ArticleAsc = 'article_ASC',
  ArticleDesc = 'article_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrepositionFromAsc = 'prepositionFrom_ASC',
  PrepositionFromDesc = 'prepositionFrom_DESC',
  PrepositionInAsc = 'prepositionIn_ASC',
  PrepositionInDesc = 'prepositionIn_DESC',
  PrepositionToAsc = 'prepositionTo_ASC',
  PrepositionToDesc = 'prepositionTo_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CountryLinkingCollectionsListOfCountriesCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  ButtonLabelExpandedListAsc = 'buttonLabelExpandedList_ASC',
  ButtonLabelExpandedListDesc = 'buttonLabelExpandedList_DESC',
  ButtonLabelHiddenListAsc = 'buttonLabelHiddenList_ASC',
  ButtonLabelHiddenListDesc = 'buttonLabelHiddenList_DESC',
  ExpandListTextAsc = 'expandListText_ASC',
  ExpandListTextDesc = 'expandListText_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubheaderAsc = 'subheader_ASC',
  SubheaderDesc = 'subheader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CountryLinkingCollectionsPageSectionCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundGradientAsc = 'backgroundGradient_ASC',
  BackgroundGradientDesc = 'backgroundGradient_DESC',
  BackgroundTypeAsc = 'backgroundType_ASC',
  BackgroundTypeDesc = 'backgroundType_DESC',
  EnablePaddingAsc = 'enablePadding_ASC',
  EnablePaddingDesc = 'enablePadding_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CountryLinkingCollectionsPayoutMethodAlertCollectionOrder {
  AlertLabelAsc = 'alertLabel_ASC',
  AlertLabelDesc = 'alertLabel_DESC',
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  EnabledAsc = 'enabled_ASC',
  EnabledDesc = 'enabled_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CountryLinkingCollectionsPayoutMethodCollectionOrder {
  CorrespondentsTitleAsc = 'correspondentsTitle_ASC',
  CorrespondentsTitleDesc = 'correspondentsTitle_DESC',
  CtaButtonBaseUrlAsc = 'ctaButtonBaseUrl_ASC',
  CtaButtonBaseUrlDesc = 'ctaButtonBaseUrl_DESC',
  CtaButtonLabelAsc = 'ctaButtonLabel_ASC',
  CtaButtonLabelDesc = 'ctaButtonLabel_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ExchangeRateLabelAsc = 'exchangeRateLabel_ASC',
  ExchangeRateLabelDesc = 'exchangeRateLabel_DESC',
  FeesLabelAsc = 'feesLabel_ASC',
  FeesLabelDesc = 'feesLabel_DESC',
  HideExchangeRateAsc = 'hideExchangeRate_ASC',
  HideExchangeRateDesc = 'hideExchangeRate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PayoutMethodIdAsc = 'payoutMethodId_ASC',
  PayoutMethodIdDesc = 'payoutMethodId_DESC',
  ReceiveMethodDescriptionAsc = 'receiveMethodDescription_ASC',
  ReceiveMethodDescriptionDesc = 'receiveMethodDescription_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SupportLinkTextAsc = 'supportLinkText_ASC',
  SupportLinkTextDesc = 'supportLinkText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TransferTimeLabelAsc = 'transferTimeLabel_ASC',
  TransferTimeLabelDesc = 'transferTimeLabel_DESC',
  TransferTimeTooltipAsc = 'transferTimeTooltip_ASC',
  TransferTimeTooltipDesc = 'transferTimeTooltip_DESC',
}

export enum CountryLinkingCollectionsReferAFriendGreenfieldEnabledCountriesCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CountryLinkingCollectionsRegulatorCollectionOrder {
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  CustomFlagCodeAsc = 'customFlagCode_ASC',
  CustomFlagCodeDesc = 'customFlagCode_DESC',
  CustomRegulatorNameAsc = 'customRegulatorName_ASC',
  CustomRegulatorNameDesc = 'customRegulatorName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CountryLinkingCollectionsStickyBannerCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ShouldMatchAllCookiesAsc = 'shouldMatchAllCookies_ASC',
  ShouldMatchAllCookiesDesc = 'shouldMatchAllCookies_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextColorAsc = 'textColor_ASC',
  TextColorDesc = 'textColor_DESC',
}

export enum CountryOrder {
  ArticleAsc = 'article_ASC',
  ArticleDesc = 'article_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrepositionFromAsc = 'prepositionFrom_ASC',
  PrepositionFromDesc = 'prepositionFrom_DESC',
  PrepositionInAsc = 'prepositionIn_ASC',
  PrepositionInDesc = 'prepositionIn_DESC',
  PrepositionToAsc = 'prepositionTo_ASC',
  PrepositionToDesc = 'prepositionTo_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/countryPayoutMethod) */
export type CountryPayoutMethod = Entry &
  _Node & {
    __typename?: 'CountryPayoutMethod';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    correspondentsCollection?: Maybe<CountryPayoutMethodCorrespondentsCollection>;
    countryCode?: Maybe<Scalars['String']['output']>;
    currencyCode?: Maybe<Scalars['String']['output']>;
    description?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<CountryPayoutMethodLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    payoutMethod?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/countryPayoutMethod) */
export type CountryPayoutMethodCorrespondentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<CountryPayoutMethodCorrespondentsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CorrespondentFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/countryPayoutMethod) */
export type CountryPayoutMethodCountryCodeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/countryPayoutMethod) */
export type CountryPayoutMethodCurrencyCodeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/countryPayoutMethod) */
export type CountryPayoutMethodDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/countryPayoutMethod) */
export type CountryPayoutMethodIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/countryPayoutMethod) */
export type CountryPayoutMethodLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/countryPayoutMethod) */
export type CountryPayoutMethodNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/countryPayoutMethod) */
export type CountryPayoutMethodPayoutMethodArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/countryPayoutMethod) */
export type CountryPayoutMethodTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type CountryPayoutMethodCollection = {
  __typename?: 'CountryPayoutMethodCollection';
  items: Array<Maybe<CountryPayoutMethod>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CountryPayoutMethodCorrespondentsCollection = {
  __typename?: 'CountryPayoutMethodCorrespondentsCollection';
  items: Array<Maybe<Correspondent>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum CountryPayoutMethodCorrespondentsCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type CountryPayoutMethodFilter = {
  AND?: InputMaybe<Array<InputMaybe<CountryPayoutMethodFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CountryPayoutMethodFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  correspondents?: InputMaybe<CfCorrespondentNestedFilter>;
  correspondentsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  countryCode_contains?: InputMaybe<Scalars['String']['input']>;
  countryCode_exists?: InputMaybe<Scalars['Boolean']['input']>;
  countryCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  countryCode_not?: InputMaybe<Scalars['String']['input']>;
  countryCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  countryCode_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  currencyCode_contains?: InputMaybe<Scalars['String']['input']>;
  currencyCode_exists?: InputMaybe<Scalars['Boolean']['input']>;
  currencyCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  currencyCode_not?: InputMaybe<Scalars['String']['input']>;
  currencyCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  currencyCode_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  id?: InputMaybe<Scalars['String']['input']>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  payoutMethod?: InputMaybe<Scalars['String']['input']>;
  payoutMethod_contains?: InputMaybe<Scalars['String']['input']>;
  payoutMethod_exists?: InputMaybe<Scalars['Boolean']['input']>;
  payoutMethod_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  payoutMethod_not?: InputMaybe<Scalars['String']['input']>;
  payoutMethod_not_contains?: InputMaybe<Scalars['String']['input']>;
  payoutMethod_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CountryPayoutMethodLinkingCollections = {
  __typename?: 'CountryPayoutMethodLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type CountryPayoutMethodLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CountryPayoutMethodOrder {
  CountryCodeAsc = 'countryCode_ASC',
  CountryCodeDesc = 'countryCode_DESC',
  CurrencyCodeAsc = 'currencyCode_ASC',
  CurrencyCodeDesc = 'currencyCode_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PayoutMethodAsc = 'payoutMethod_ASC',
  PayoutMethodDesc = 'payoutMethod_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/currencyPricingConverter) */
export type CurrencyPricingConverter = Entry &
  _Node & {
    __typename?: 'CurrencyPricingConverter';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    exchangeRateDescription?: Maybe<Scalars['String']['output']>;
    exchangeRateTitle?: Maybe<Scalars['String']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    link?: Maybe<Link>;
    linkedFrom?: Maybe<CurrencyPricingConverterLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    receiveCountryLabel?: Maybe<Scalars['String']['output']>;
    sendCountryLabel?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/currencyPricingConverter) */
export type CurrencyPricingConverterExchangeRateDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/currencyPricingConverter) */
export type CurrencyPricingConverterExchangeRateTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/currencyPricingConverter) */
export type CurrencyPricingConverterHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/currencyPricingConverter) */
export type CurrencyPricingConverterLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/currencyPricingConverter) */
export type CurrencyPricingConverterLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/currencyPricingConverter) */
export type CurrencyPricingConverterNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/currencyPricingConverter) */
export type CurrencyPricingConverterReceiveCountryLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/currencyPricingConverter) */
export type CurrencyPricingConverterSendCountryLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type CurrencyPricingConverterCollection = {
  __typename?: 'CurrencyPricingConverterCollection';
  items: Array<Maybe<CurrencyPricingConverter>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CurrencyPricingConverterFilter = {
  AND?: InputMaybe<Array<InputMaybe<CurrencyPricingConverterFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CurrencyPricingConverterFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  exchangeRateDescription?: InputMaybe<Scalars['String']['input']>;
  exchangeRateDescription_contains?: InputMaybe<Scalars['String']['input']>;
  exchangeRateDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  exchangeRateDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  exchangeRateDescription_not?: InputMaybe<Scalars['String']['input']>;
  exchangeRateDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  exchangeRateDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  exchangeRateTitle?: InputMaybe<Scalars['String']['input']>;
  exchangeRateTitle_contains?: InputMaybe<Scalars['String']['input']>;
  exchangeRateTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  exchangeRateTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  exchangeRateTitle_not?: InputMaybe<Scalars['String']['input']>;
  exchangeRateTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  exchangeRateTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  heading?: InputMaybe<Scalars['String']['input']>;
  heading_contains?: InputMaybe<Scalars['String']['input']>;
  heading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heading_not?: InputMaybe<Scalars['String']['input']>;
  heading_not_contains?: InputMaybe<Scalars['String']['input']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  link?: InputMaybe<CfLinkNestedFilter>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  receiveCountryLabel?: InputMaybe<Scalars['String']['input']>;
  receiveCountryLabel_contains?: InputMaybe<Scalars['String']['input']>;
  receiveCountryLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  receiveCountryLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryLabel_not?: InputMaybe<Scalars['String']['input']>;
  receiveCountryLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  receiveCountryLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryLabel?: InputMaybe<Scalars['String']['input']>;
  sendCountryLabel_contains?: InputMaybe<Scalars['String']['input']>;
  sendCountryLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryLabel_not?: InputMaybe<Scalars['String']['input']>;
  sendCountryLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  sendCountryLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
};

export type CurrencyPricingConverterLinkingCollections = {
  __typename?: 'CurrencyPricingConverterLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type CurrencyPricingConverterLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CurrencyPricingConverterOrder {
  ExchangeRateDescriptionAsc = 'exchangeRateDescription_ASC',
  ExchangeRateDescriptionDesc = 'exchangeRateDescription_DESC',
  ExchangeRateTitleAsc = 'exchangeRateTitle_ASC',
  ExchangeRateTitleDesc = 'exchangeRateTitle_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ReceiveCountryLabelAsc = 'receiveCountryLabel_ASC',
  ReceiveCountryLabelDesc = 'receiveCountryLabel_DESC',
  SendCountryLabelAsc = 'sendCountryLabel_ASC',
  SendCountryLabelDesc = 'sendCountryLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** RichText content to be used inside table data content [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/embeddedTableEntry) */
export type EmbeddedTableEntry = Entry &
  _Node & {
    __typename?: 'EmbeddedTableEntry';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<EmbeddedTableEntryDescription>;
    linkedFrom?: Maybe<EmbeddedTableEntryLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** RichText content to be used inside table data content [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/embeddedTableEntry) */
export type EmbeddedTableEntryDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** RichText content to be used inside table data content [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/embeddedTableEntry) */
export type EmbeddedTableEntryLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** RichText content to be used inside table data content [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/embeddedTableEntry) */
export type EmbeddedTableEntryNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type EmbeddedTableEntryCollection = {
  __typename?: 'EmbeddedTableEntryCollection';
  items: Array<Maybe<EmbeddedTableEntry>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type EmbeddedTableEntryDescription = {
  __typename?: 'EmbeddedTableEntryDescription';
  json: Scalars['JSON']['output'];
  links: EmbeddedTableEntryDescriptionLinks;
};

export type EmbeddedTableEntryDescriptionAssets = {
  __typename?: 'EmbeddedTableEntryDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type EmbeddedTableEntryDescriptionEntries = {
  __typename?: 'EmbeddedTableEntryDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type EmbeddedTableEntryDescriptionLinks = {
  __typename?: 'EmbeddedTableEntryDescriptionLinks';
  assets: EmbeddedTableEntryDescriptionAssets;
  entries: EmbeddedTableEntryDescriptionEntries;
  resources: EmbeddedTableEntryDescriptionResources;
};

export type EmbeddedTableEntryDescriptionResources = {
  __typename?: 'EmbeddedTableEntryDescriptionResources';
  block: Array<EmbeddedTableEntryDescriptionResourcesBlock>;
  hyperlink: Array<EmbeddedTableEntryDescriptionResourcesHyperlink>;
  inline: Array<EmbeddedTableEntryDescriptionResourcesInline>;
};

export type EmbeddedTableEntryDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'EmbeddedTableEntryDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type EmbeddedTableEntryDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'EmbeddedTableEntryDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type EmbeddedTableEntryDescriptionResourcesInline = ResourceLink & {
  __typename?: 'EmbeddedTableEntryDescriptionResourcesInline';
  sys: ResourceSys;
};

export type EmbeddedTableEntryFilter = {
  AND?: InputMaybe<Array<InputMaybe<EmbeddedTableEntryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<EmbeddedTableEntryFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type EmbeddedTableEntryLinkingCollections = {
  __typename?: 'EmbeddedTableEntryLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type EmbeddedTableEntryLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum EmbeddedTableEntryOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type Entry = {
  contentfulMetadata: ContentfulMetadata;
  sys: Sys;
};

export type EntryCollection = {
  __typename?: 'EntryCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type EntryFilter = {
  AND?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export enum EntryOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** This module injects external scripts like OneTrust or Chatbot. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/externalScript) */
export type ExternalScript = Entry &
  _Node & {
    __typename?: 'ExternalScript';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    id?: Maybe<Scalars['String']['output']>;
    key?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<ExternalScriptLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    scriptType?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** This module injects external scripts like OneTrust or Chatbot. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/externalScript) */
export type ExternalScriptIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This module injects external scripts like OneTrust or Chatbot. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/externalScript) */
export type ExternalScriptKeyArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This module injects external scripts like OneTrust or Chatbot. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/externalScript) */
export type ExternalScriptLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** This module injects external scripts like OneTrust or Chatbot. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/externalScript) */
export type ExternalScriptNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This module injects external scripts like OneTrust or Chatbot. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/externalScript) */
export type ExternalScriptScriptTypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type ExternalScriptCollection = {
  __typename?: 'ExternalScriptCollection';
  items: Array<Maybe<ExternalScript>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ExternalScriptFilter = {
  AND?: InputMaybe<Array<InputMaybe<ExternalScriptFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ExternalScriptFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_exists?: InputMaybe<Scalars['Boolean']['input']>;
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  scriptType?: InputMaybe<Scalars['String']['input']>;
  scriptType_contains?: InputMaybe<Scalars['String']['input']>;
  scriptType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  scriptType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  scriptType_not?: InputMaybe<Scalars['String']['input']>;
  scriptType_not_contains?: InputMaybe<Scalars['String']['input']>;
  scriptType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type ExternalScriptLinkingCollections = {
  __typename?: 'ExternalScriptLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type ExternalScriptLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ExternalScriptOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ScriptTypeAsc = 'scriptType_ASC',
  ScriptTypeDesc = 'scriptType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Interactive module to get insight from customers on whether articles were useful so that we can respond to it [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqFeedbackModule) */
export type FaqFeedbackModule = Entry &
  _Node & {
    __typename?: 'FaqFeedbackModule';
    _id: Scalars['ID']['output'];
    analyticsTagId?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    feedbackOptionsCollection?: Maybe<FaqFeedbackModuleFeedbackOptionsCollection>;
    linkedFrom?: Maybe<FaqFeedbackModuleLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    postFeedbackMessage?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Interactive module to get insight from customers on whether articles were useful so that we can respond to it [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqFeedbackModule) */
export type FaqFeedbackModuleAnalyticsTagIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Interactive module to get insight from customers on whether articles were useful so that we can respond to it [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqFeedbackModule) */
export type FaqFeedbackModuleFeedbackOptionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<FaqFeedbackModuleFeedbackOptionsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaqFeedbackModuleItemFilter>;
};

/** Interactive module to get insight from customers on whether articles were useful so that we can respond to it [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqFeedbackModule) */
export type FaqFeedbackModuleLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Interactive module to get insight from customers on whether articles were useful so that we can respond to it [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqFeedbackModule) */
export type FaqFeedbackModuleNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Interactive module to get insight from customers on whether articles were useful so that we can respond to it [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqFeedbackModule) */
export type FaqFeedbackModulePostFeedbackMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Interactive module to get insight from customers on whether articles were useful so that we can respond to it [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqFeedbackModule) */
export type FaqFeedbackModuleTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type FaqFeedbackModuleCollection = {
  __typename?: 'FaqFeedbackModuleCollection';
  items: Array<Maybe<FaqFeedbackModule>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type FaqFeedbackModuleFeedbackOptionsCollection = {
  __typename?: 'FaqFeedbackModuleFeedbackOptionsCollection';
  items: Array<Maybe<FaqFeedbackModuleItem>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum FaqFeedbackModuleFeedbackOptionsCollectionOrder {
  AnalyticsTagIdAsc = 'analyticsTagId_ASC',
  AnalyticsTagIdDesc = 'analyticsTagId_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type FaqFeedbackModuleFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqFeedbackModuleFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqFeedbackModuleFilter>>>;
  analyticsTagId?: InputMaybe<Scalars['String']['input']>;
  analyticsTagId_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsTagId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  analyticsTagId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  analyticsTagId_not?: InputMaybe<Scalars['String']['input']>;
  analyticsTagId_not_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsTagId_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  feedbackOptions?: InputMaybe<CfFaqFeedbackModuleItemNestedFilter>;
  feedbackOptionsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postFeedbackMessage?: InputMaybe<Scalars['String']['input']>;
  postFeedbackMessage_contains?: InputMaybe<Scalars['String']['input']>;
  postFeedbackMessage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  postFeedbackMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  postFeedbackMessage_not?: InputMaybe<Scalars['String']['input']>;
  postFeedbackMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  postFeedbackMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqFeedbackModuleItem) */
export type FaqFeedbackModuleItem = Entry &
  _Node & {
    __typename?: 'FaqFeedbackModuleItem';
    _id: Scalars['ID']['output'];
    analyticsTagId?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    icon?: Maybe<Asset>;
    label?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<FaqFeedbackModuleItemLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqFeedbackModuleItem) */
export type FaqFeedbackModuleItemAnalyticsTagIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqFeedbackModuleItem) */
export type FaqFeedbackModuleItemIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqFeedbackModuleItem) */
export type FaqFeedbackModuleItemLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqFeedbackModuleItem) */
export type FaqFeedbackModuleItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqFeedbackModuleItem) */
export type FaqFeedbackModuleItemNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type FaqFeedbackModuleItemCollection = {
  __typename?: 'FaqFeedbackModuleItemCollection';
  items: Array<Maybe<FaqFeedbackModuleItem>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type FaqFeedbackModuleItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqFeedbackModuleItemFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqFeedbackModuleItemFilter>>>;
  analyticsTagId?: InputMaybe<Scalars['String']['input']>;
  analyticsTagId_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsTagId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  analyticsTagId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  analyticsTagId_not?: InputMaybe<Scalars['String']['input']>;
  analyticsTagId_not_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsTagId_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type FaqFeedbackModuleItemLinkingCollections = {
  __typename?: 'FaqFeedbackModuleItemLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  faqFeedbackModuleCollection?: Maybe<FaqFeedbackModuleCollection>;
};

export type FaqFeedbackModuleItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type FaqFeedbackModuleItemLinkingCollectionsFaqFeedbackModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<FaqFeedbackModuleItemLinkingCollectionsFaqFeedbackModuleCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum FaqFeedbackModuleItemLinkingCollectionsFaqFeedbackModuleCollectionOrder {
  AnalyticsTagIdAsc = 'analyticsTagId_ASC',
  AnalyticsTagIdDesc = 'analyticsTagId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PostFeedbackMessageAsc = 'postFeedbackMessage_ASC',
  PostFeedbackMessageDesc = 'postFeedbackMessage_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqFeedbackModuleItemOrder {
  AnalyticsTagIdAsc = 'analyticsTagId_ASC',
  AnalyticsTagIdDesc = 'analyticsTagId_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type FaqFeedbackModuleLinkingCollections = {
  __typename?: 'FaqFeedbackModuleLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type FaqFeedbackModuleLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum FaqFeedbackModuleOrder {
  AnalyticsTagIdAsc = 'analyticsTagId_ASC',
  AnalyticsTagIdDesc = 'analyticsTagId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PostFeedbackMessageAsc = 'postFeedbackMessage_ASC',
  PostFeedbackMessageDesc = 'postFeedbackMessage_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** New hero component for FAQ pages [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqHero) */
export type FaqHero = Entry &
  _Node & {
    __typename?: 'FaqHero';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    heading?: Maybe<Scalars['String']['output']>;
    heroImage?: Maybe<Asset>;
    linkedFrom?: Maybe<FaqHeroLinkingCollections>;
    linksCollection?: Maybe<FaqHeroLinksCollection>;
    menuTitle?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    primaryBackgroundColor?: Maybe<Scalars['String']['output']>;
    search?: Maybe<Search>;
    secondaryBackgroundColor?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** New hero component for FAQ pages [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqHero) */
export type FaqHeroHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** New hero component for FAQ pages [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqHero) */
export type FaqHeroHeroImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** New hero component for FAQ pages [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqHero) */
export type FaqHeroLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** New hero component for FAQ pages [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqHero) */
export type FaqHeroLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FaqHeroLinksCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** New hero component for FAQ pages [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqHero) */
export type FaqHeroMenuTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** New hero component for FAQ pages [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqHero) */
export type FaqHeroNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** New hero component for FAQ pages [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqHero) */
export type FaqHeroPrimaryBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** New hero component for FAQ pages [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqHero) */
export type FaqHeroSearchArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SearchFilter>;
};

/** New hero component for FAQ pages [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqHero) */
export type FaqHeroSecondaryBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type FaqHeroCollection = {
  __typename?: 'FaqHeroCollection';
  items: Array<Maybe<FaqHero>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type FaqHeroFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqHeroFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqHeroFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  heading?: InputMaybe<Scalars['String']['input']>;
  heading_contains?: InputMaybe<Scalars['String']['input']>;
  heading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heading_not?: InputMaybe<Scalars['String']['input']>;
  heading_not_contains?: InputMaybe<Scalars['String']['input']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heroImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  links?: InputMaybe<CfLinkNestedFilter>;
  linksCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  menuTitle?: InputMaybe<Scalars['String']['input']>;
  menuTitle_contains?: InputMaybe<Scalars['String']['input']>;
  menuTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  menuTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  menuTitle_not?: InputMaybe<Scalars['String']['input']>;
  menuTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  menuTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  primaryBackgroundColor?: InputMaybe<Scalars['String']['input']>;
  primaryBackgroundColor_contains?: InputMaybe<Scalars['String']['input']>;
  primaryBackgroundColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  primaryBackgroundColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  primaryBackgroundColor_not?: InputMaybe<Scalars['String']['input']>;
  primaryBackgroundColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  primaryBackgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  search?: InputMaybe<CfSearchNestedFilter>;
  search_exists?: InputMaybe<Scalars['Boolean']['input']>;
  secondaryBackgroundColor?: InputMaybe<Scalars['String']['input']>;
  secondaryBackgroundColor_contains?: InputMaybe<Scalars['String']['input']>;
  secondaryBackgroundColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  secondaryBackgroundColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  secondaryBackgroundColor_not?: InputMaybe<Scalars['String']['input']>;
  secondaryBackgroundColor_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  secondaryBackgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
};

export type FaqHeroLinkingCollections = {
  __typename?: 'FaqHeroLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type FaqHeroLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type FaqHeroLinksCollection = {
  __typename?: 'FaqHeroLinksCollection';
  items: Array<Maybe<Link>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum FaqHeroLinksCollectionOrder {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  GaTrackNameAsc = 'gaTrackName_ASC',
  GaTrackNameDesc = 'gaTrackName_DESC',
  HighlightedAsc = 'highlighted_ASC',
  HighlightedDesc = 'highlighted_DESC',
  IsDisplayedWhenLoggedInAsc = 'isDisplayedWhenLoggedIn_ASC',
  IsDisplayedWhenLoggedInDesc = 'isDisplayedWhenLoggedIn_DESC',
  IsInternalAsc = 'isInternal_ASC',
  IsInternalDesc = 'isInternal_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  MobileMenuToggleAsc = 'mobileMenuToggle_ASC',
  MobileMenuToggleDesc = 'mobileMenuToggle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoFollowAsc = 'noFollow_ASC',
  NoFollowDesc = 'noFollow_DESC',
  OpenInNewTabAsc = 'openInNewTab_ASC',
  OpenInNewTabDesc = 'openInNewTab_DESC',
  RedirectToLegacyProjectAsc = 'redirectToLegacyProject_ASC',
  RedirectToLegacyProjectDesc = 'redirectToLegacyProject_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnderlineAsc = 'underline_ASC',
  UnderlineDesc = 'underline_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

export enum FaqHeroOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  MenuTitleAsc = 'menuTitle_ASC',
  MenuTitleDesc = 'menuTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrimaryBackgroundColorAsc = 'primaryBackgroundColor_ASC',
  PrimaryBackgroundColorDesc = 'primaryBackgroundColor_DESC',
  SecondaryBackgroundColorAsc = 'secondaryBackgroundColor_ASC',
  SecondaryBackgroundColorDesc = 'secondaryBackgroundColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqModule) */
export type FaqModule = Entry &
  _Node & {
    __typename?: 'FaqModule';
    _id: Scalars['ID']['output'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    contentfulMetadata: ContentfulMetadata;
    ctaButton?: Maybe<Link>;
    image?: Maybe<Asset>;
    linkedFrom?: Maybe<FaqModuleLinkingCollections>;
    mobileImage?: Maybe<Asset>;
    name?: Maybe<Scalars['String']['output']>;
    questionsCollection?: Maybe<FaqModuleQuestionsCollection>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    subheading?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    tabletImage?: Maybe<Asset>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqModule) */
export type FaqModuleAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqModule) */
export type FaqModuleAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqModule) */
export type FaqModuleCtaButtonArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqModule) */
export type FaqModuleImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqModule) */
export type FaqModuleLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqModule) */
export type FaqModuleMobileImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqModule) */
export type FaqModuleNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqModule) */
export type FaqModuleQuestionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FaqModuleQuestionsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaqModuleItemFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqModule) */
export type FaqModuleReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqModule) */
export type FaqModuleSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqModule) */
export type FaqModuleSubheadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqModule) */
export type FaqModuleTabletImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqModule) */
export type FaqModuleTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type FaqModuleCollection = {
  __typename?: 'FaqModuleCollection';
  items: Array<Maybe<FaqModule>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type FaqModuleFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqModuleFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqModuleFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  ctaButton?: InputMaybe<CfLinkNestedFilter>;
  ctaButton_exists?: InputMaybe<Scalars['Boolean']['input']>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  questions?: InputMaybe<CfFaqModuleItemNestedFilter>;
  questionsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subheading?: InputMaybe<Scalars['String']['input']>;
  subheading_contains?: InputMaybe<Scalars['String']['input']>;
  subheading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subheading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subheading_not?: InputMaybe<Scalars['String']['input']>;
  subheading_not_contains?: InputMaybe<Scalars['String']['input']>;
  subheading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  tabletImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqModuleItem) */
export type FaqModuleItem = Entry &
  _Node & {
    __typename?: 'FaqModuleItem';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<FaqModuleItemDescription>;
    linkedFrom?: Maybe<FaqModuleItemLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    path?: Maybe<Scalars['String']['output']>;
    sortOrder?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
    umbracoId?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqModuleItem) */
export type FaqModuleItemDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqModuleItem) */
export type FaqModuleItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqModuleItem) */
export type FaqModuleItemNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqModuleItem) */
export type FaqModuleItemPathArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqModuleItem) */
export type FaqModuleItemSortOrderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqModuleItem) */
export type FaqModuleItemTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqModuleItem) */
export type FaqModuleItemUmbracoIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type FaqModuleItemCollection = {
  __typename?: 'FaqModuleItemCollection';
  items: Array<Maybe<FaqModuleItem>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type FaqModuleItemDescription = {
  __typename?: 'FaqModuleItemDescription';
  json: Scalars['JSON']['output'];
  links: FaqModuleItemDescriptionLinks;
};

export type FaqModuleItemDescriptionAssets = {
  __typename?: 'FaqModuleItemDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type FaqModuleItemDescriptionEntries = {
  __typename?: 'FaqModuleItemDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type FaqModuleItemDescriptionLinks = {
  __typename?: 'FaqModuleItemDescriptionLinks';
  assets: FaqModuleItemDescriptionAssets;
  entries: FaqModuleItemDescriptionEntries;
  resources: FaqModuleItemDescriptionResources;
};

export type FaqModuleItemDescriptionResources = {
  __typename?: 'FaqModuleItemDescriptionResources';
  block: Array<FaqModuleItemDescriptionResourcesBlock>;
  hyperlink: Array<FaqModuleItemDescriptionResourcesHyperlink>;
  inline: Array<FaqModuleItemDescriptionResourcesInline>;
};

export type FaqModuleItemDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'FaqModuleItemDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type FaqModuleItemDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'FaqModuleItemDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type FaqModuleItemDescriptionResourcesInline = ResourceLink & {
  __typename?: 'FaqModuleItemDescriptionResourcesInline';
  sys: ResourceSys;
};

export type FaqModuleItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqModuleItemFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqModuleItemFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  path?: InputMaybe<Scalars['String']['input']>;
  path_contains?: InputMaybe<Scalars['String']['input']>;
  path_exists?: InputMaybe<Scalars['Boolean']['input']>;
  path_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  path_not?: InputMaybe<Scalars['String']['input']>;
  path_not_contains?: InputMaybe<Scalars['String']['input']>;
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sortOrder?: InputMaybe<Scalars['String']['input']>;
  sortOrder_contains?: InputMaybe<Scalars['String']['input']>;
  sortOrder_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sortOrder_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sortOrder_not?: InputMaybe<Scalars['String']['input']>;
  sortOrder_not_contains?: InputMaybe<Scalars['String']['input']>;
  sortOrder_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  umbracoId?: InputMaybe<Scalars['String']['input']>;
  umbracoId_contains?: InputMaybe<Scalars['String']['input']>;
  umbracoId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  umbracoId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  umbracoId_not?: InputMaybe<Scalars['String']['input']>;
  umbracoId_not_contains?: InputMaybe<Scalars['String']['input']>;
  umbracoId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FaqModuleItemLinkingCollections = {
  __typename?: 'FaqModuleItemLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  faqModuleCollection?: Maybe<FaqModuleCollection>;
};

export type FaqModuleItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type FaqModuleItemLinkingCollectionsFaqModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<FaqModuleItemLinkingCollectionsFaqModuleCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum FaqModuleItemLinkingCollectionsFaqModuleCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubheadingAsc = 'subheading_ASC',
  SubheadingDesc = 'subheading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqModuleItemOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PathAsc = 'path_ASC',
  PathDesc = 'path_DESC',
  SortOrderAsc = 'sortOrder_ASC',
  SortOrderDesc = 'sortOrder_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UmbracoIdAsc = 'umbracoId_ASC',
  UmbracoIdDesc = 'umbracoId_DESC',
}

export type FaqModuleLinkingCollections = {
  __typename?: 'FaqModuleLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type FaqModuleLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum FaqModuleOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubheadingAsc = 'subheading_ASC',
  SubheadingDesc = 'subheading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type FaqModuleQuestionsCollection = {
  __typename?: 'FaqModuleQuestionsCollection';
  items: Array<Maybe<FaqModuleItem>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum FaqModuleQuestionsCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PathAsc = 'path_ASC',
  PathDesc = 'path_DESC',
  SortOrderAsc = 'sortOrder_ASC',
  SortOrderDesc = 'sortOrder_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UmbracoIdAsc = 'umbracoId_ASC',
  UmbracoIdDesc = 'umbracoId_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqPopularQuestions) */
export type FaqPopularQuestions = Entry &
  _Node & {
    __typename?: 'FaqPopularQuestions';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<FaqPopularQuestionsLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    questionsCollection?: Maybe<FaqPopularQuestionsQuestionsCollection>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqPopularQuestions) */
export type FaqPopularQuestionsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqPopularQuestions) */
export type FaqPopularQuestionsNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqPopularQuestions) */
export type FaqPopularQuestionsQuestionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<FaqPopularQuestionsQuestionsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PageFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqPopularQuestions) */
export type FaqPopularQuestionsTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type FaqPopularQuestionsCollection = {
  __typename?: 'FaqPopularQuestionsCollection';
  items: Array<Maybe<FaqPopularQuestions>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type FaqPopularQuestionsFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqPopularQuestionsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqPopularQuestionsFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  questions?: InputMaybe<CfPageNestedFilter>;
  questionsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FaqPopularQuestionsLinkingCollections = {
  __typename?: 'FaqPopularQuestionsLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  faqTopicsModuleCollection?: Maybe<FaqTopicsModuleCollection>;
};

export type FaqPopularQuestionsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type FaqPopularQuestionsLinkingCollectionsFaqTopicsModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<FaqPopularQuestionsLinkingCollectionsFaqTopicsModuleCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum FaqPopularQuestionsLinkingCollectionsFaqTopicsModuleCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqPopularQuestionsOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type FaqPopularQuestionsQuestionsCollection = {
  __typename?: 'FaqPopularQuestionsQuestionsCollection';
  items: Array<Maybe<Page>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum FaqPopularQuestionsQuestionsCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderPositionAsc = 'headerPosition_ASC',
  HeaderPositionDesc = 'headerPosition_DESC',
  HeaderThemeAsc = 'headerTheme_ASC',
  HeaderThemeDesc = 'headerTheme_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimeToReadAsc = 'timeToRead_ASC',
  TimeToReadDesc = 'timeToRead_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqSearchHelp) */
export type FaqSearchHelp = Entry &
  _Node & {
    __typename?: 'FaqSearchHelp';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    gradientColorsCollection?: Maybe<FaqSearchHelpGradientColorsCollection>;
    gradientDirection?: Maybe<Scalars['String']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    helpIllustration?: Maybe<Asset>;
    linkedFrom?: Maybe<FaqSearchHelpLinkingCollections>;
    noOptionsText?: Maybe<Scalars['String']['output']>;
    searchTextPlaceholder?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqSearchHelp) */
export type FaqSearchHelpGradientColorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<FaqSearchHelpGradientColorsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaqSearchHelpItemsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqSearchHelp) */
export type FaqSearchHelpGradientDirectionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqSearchHelp) */
export type FaqSearchHelpHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqSearchHelp) */
export type FaqSearchHelpHelpIllustrationArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqSearchHelp) */
export type FaqSearchHelpLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqSearchHelp) */
export type FaqSearchHelpNoOptionsTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqSearchHelp) */
export type FaqSearchHelpSearchTextPlaceholderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqSearchHelp) */
export type FaqSearchHelpTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type FaqSearchHelpCollection = {
  __typename?: 'FaqSearchHelpCollection';
  items: Array<Maybe<FaqSearchHelp>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type FaqSearchHelpFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqSearchHelpFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqSearchHelpFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  gradientColors?: InputMaybe<CfFaqSearchHelpItemsNestedFilter>;
  gradientColorsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  gradientDirection?: InputMaybe<Scalars['String']['input']>;
  gradientDirection_contains?: InputMaybe<Scalars['String']['input']>;
  gradientDirection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  gradientDirection_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  gradientDirection_not?: InputMaybe<Scalars['String']['input']>;
  gradientDirection_not_contains?: InputMaybe<Scalars['String']['input']>;
  gradientDirection_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  heading?: InputMaybe<Scalars['String']['input']>;
  heading_contains?: InputMaybe<Scalars['String']['input']>;
  heading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heading_not?: InputMaybe<Scalars['String']['input']>;
  heading_not_contains?: InputMaybe<Scalars['String']['input']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  helpIllustration_exists?: InputMaybe<Scalars['Boolean']['input']>;
  noOptionsText?: InputMaybe<Scalars['String']['input']>;
  noOptionsText_contains?: InputMaybe<Scalars['String']['input']>;
  noOptionsText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  noOptionsText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noOptionsText_not?: InputMaybe<Scalars['String']['input']>;
  noOptionsText_not_contains?: InputMaybe<Scalars['String']['input']>;
  noOptionsText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  searchTextPlaceholder?: InputMaybe<Scalars['String']['input']>;
  searchTextPlaceholder_contains?: InputMaybe<Scalars['String']['input']>;
  searchTextPlaceholder_exists?: InputMaybe<Scalars['Boolean']['input']>;
  searchTextPlaceholder_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  searchTextPlaceholder_not?: InputMaybe<Scalars['String']['input']>;
  searchTextPlaceholder_not_contains?: InputMaybe<Scalars['String']['input']>;
  searchTextPlaceholder_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FaqSearchHelpGradientColorsCollection = {
  __typename?: 'FaqSearchHelpGradientColorsCollection';
  items: Array<Maybe<FaqSearchHelpItems>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum FaqSearchHelpGradientColorsCollectionOrder {
  GradientColorAsc = 'gradientColor_ASC',
  GradientColorDesc = 'gradientColor_DESC',
  GradientPercentageAsc = 'gradientPercentage_ASC',
  GradientPercentageDesc = 'gradientPercentage_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqSearchHelpItems) */
export type FaqSearchHelpItems = Entry &
  _Node & {
    __typename?: 'FaqSearchHelpItems';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    gradientColor?: Maybe<Scalars['String']['output']>;
    gradientPercentage?: Maybe<Scalars['String']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<FaqSearchHelpItemsLinkingCollections>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqSearchHelpItems) */
export type FaqSearchHelpItemsGradientColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqSearchHelpItems) */
export type FaqSearchHelpItemsGradientPercentageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqSearchHelpItems) */
export type FaqSearchHelpItemsHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqSearchHelpItems) */
export type FaqSearchHelpItemsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FaqSearchHelpItemsCollection = {
  __typename?: 'FaqSearchHelpItemsCollection';
  items: Array<Maybe<FaqSearchHelpItems>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type FaqSearchHelpItemsFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqSearchHelpItemsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqSearchHelpItemsFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  gradientColor?: InputMaybe<Scalars['String']['input']>;
  gradientColor_contains?: InputMaybe<Scalars['String']['input']>;
  gradientColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  gradientColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gradientColor_not?: InputMaybe<Scalars['String']['input']>;
  gradientColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  gradientColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  gradientPercentage?: InputMaybe<Scalars['String']['input']>;
  gradientPercentage_contains?: InputMaybe<Scalars['String']['input']>;
  gradientPercentage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  gradientPercentage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  gradientPercentage_not?: InputMaybe<Scalars['String']['input']>;
  gradientPercentage_not_contains?: InputMaybe<Scalars['String']['input']>;
  gradientPercentage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  heading?: InputMaybe<Scalars['String']['input']>;
  heading_contains?: InputMaybe<Scalars['String']['input']>;
  heading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heading_not?: InputMaybe<Scalars['String']['input']>;
  heading_not_contains?: InputMaybe<Scalars['String']['input']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type FaqSearchHelpItemsLinkingCollections = {
  __typename?: 'FaqSearchHelpItemsLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  faqSearchHelpCollection?: Maybe<FaqSearchHelpCollection>;
};

export type FaqSearchHelpItemsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type FaqSearchHelpItemsLinkingCollectionsFaqSearchHelpCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<FaqSearchHelpItemsLinkingCollectionsFaqSearchHelpCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum FaqSearchHelpItemsLinkingCollectionsFaqSearchHelpCollectionOrder {
  GradientDirectionAsc = 'gradientDirection_ASC',
  GradientDirectionDesc = 'gradientDirection_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NoOptionsTextAsc = 'noOptionsText_ASC',
  NoOptionsTextDesc = 'noOptionsText_DESC',
  SearchTextPlaceholderAsc = 'searchTextPlaceholder_ASC',
  SearchTextPlaceholderDesc = 'searchTextPlaceholder_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqSearchHelpItemsOrder {
  GradientColorAsc = 'gradientColor_ASC',
  GradientColorDesc = 'gradientColor_DESC',
  GradientPercentageAsc = 'gradientPercentage_ASC',
  GradientPercentageDesc = 'gradientPercentage_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type FaqSearchHelpLinkingCollections = {
  __typename?: 'FaqSearchHelpLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type FaqSearchHelpLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum FaqSearchHelpOrder {
  GradientDirectionAsc = 'gradientDirection_ASC',
  GradientDirectionDesc = 'gradientDirection_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NoOptionsTextAsc = 'noOptionsText_ASC',
  NoOptionsTextDesc = 'noOptionsText_DESC',
  SearchTextPlaceholderAsc = 'searchTextPlaceholder_ASC',
  SearchTextPlaceholderDesc = 'searchTextPlaceholder_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqTopicsModule) */
export type FaqTopicsModule = Entry &
  _Node & {
    __typename?: 'FaqTopicsModule';
    _id: Scalars['ID']['output'];
    backgroundColor?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<FaqTopicsModuleLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    popularQuestions?: Maybe<FaqPopularQuestions>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
    topicsCollection?: Maybe<FaqTopicsModuleTopicsCollection>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqTopicsModule) */
export type FaqTopicsModuleBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqTopicsModule) */
export type FaqTopicsModuleLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqTopicsModule) */
export type FaqTopicsModuleNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqTopicsModule) */
export type FaqTopicsModulePopularQuestionsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<FaqPopularQuestionsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqTopicsModule) */
export type FaqTopicsModuleTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqTopicsModule) */
export type FaqTopicsModuleTopicsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FaqTopicsModuleTopicsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaqTopicsModuleItemsFilter>;
};

export type FaqTopicsModuleCollection = {
  __typename?: 'FaqTopicsModuleCollection';
  items: Array<Maybe<FaqTopicsModule>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type FaqTopicsModuleFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqTopicsModuleFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqTopicsModuleFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundColor_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  popularQuestions?: InputMaybe<CfFaqPopularQuestionsNestedFilter>;
  popularQuestions_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<CfFaqTopicsModuleItemsNestedFilter>;
  topicsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqTopicsModuleItems) */
export type FaqTopicsModuleItems = Entry &
  _Node & {
    __typename?: 'FaqTopicsModuleItems';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Asset>;
    linkedFrom?: Maybe<FaqTopicsModuleItemsLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
    topicLinksCollection?: Maybe<FaqTopicsModuleItemsTopicLinksCollection>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqTopicsModuleItems) */
export type FaqTopicsModuleItemsDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqTopicsModuleItems) */
export type FaqTopicsModuleItemsIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqTopicsModuleItems) */
export type FaqTopicsModuleItemsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqTopicsModuleItems) */
export type FaqTopicsModuleItemsNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqTopicsModuleItems) */
export type FaqTopicsModuleItemsTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/faqTopicsModuleItems) */
export type FaqTopicsModuleItemsTopicLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<FaqTopicsModuleItemsTopicLinksCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PageFilter>;
};

export type FaqTopicsModuleItemsCollection = {
  __typename?: 'FaqTopicsModuleItemsCollection';
  items: Array<Maybe<FaqTopicsModuleItems>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type FaqTopicsModuleItemsFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqTopicsModuleItemsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqTopicsModuleItemsFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topicLinks?: InputMaybe<CfPageNestedFilter>;
  topicLinksCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FaqTopicsModuleItemsLinkingCollections = {
  __typename?: 'FaqTopicsModuleItemsLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  faqTopicsModuleCollection?: Maybe<FaqTopicsModuleCollection>;
};

export type FaqTopicsModuleItemsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type FaqTopicsModuleItemsLinkingCollectionsFaqTopicsModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<FaqTopicsModuleItemsLinkingCollectionsFaqTopicsModuleCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum FaqTopicsModuleItemsLinkingCollectionsFaqTopicsModuleCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqTopicsModuleItemsOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type FaqTopicsModuleItemsTopicLinksCollection = {
  __typename?: 'FaqTopicsModuleItemsTopicLinksCollection';
  items: Array<Maybe<Page>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum FaqTopicsModuleItemsTopicLinksCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderPositionAsc = 'headerPosition_ASC',
  HeaderPositionDesc = 'headerPosition_DESC',
  HeaderThemeAsc = 'headerTheme_ASC',
  HeaderThemeDesc = 'headerTheme_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimeToReadAsc = 'timeToRead_ASC',
  TimeToReadDesc = 'timeToRead_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export type FaqTopicsModuleLinkingCollections = {
  __typename?: 'FaqTopicsModuleLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type FaqTopicsModuleLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum FaqTopicsModuleOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type FaqTopicsModuleTopicsCollection = {
  __typename?: 'FaqTopicsModuleTopicsCollection';
  items: Array<Maybe<FaqTopicsModuleItems>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum FaqTopicsModuleTopicsCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/featureFlag) */
export type FeatureFlag = Entry &
  _Node & {
    __typename?: 'FeatureFlag';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<FeatureFlagLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    value?: Maybe<Scalars['Boolean']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/featureFlag) */
export type FeatureFlagLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/featureFlag) */
export type FeatureFlagNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/featureFlag) */
export type FeatureFlagValueArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type FeatureFlagCollection = {
  __typename?: 'FeatureFlagCollection';
  items: Array<Maybe<FeatureFlag>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type FeatureFlagFilter = {
  AND?: InputMaybe<Array<InputMaybe<FeatureFlagFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FeatureFlagFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  value?: InputMaybe<Scalars['Boolean']['input']>;
  value_exists?: InputMaybe<Scalars['Boolean']['input']>;
  value_not?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FeatureFlagLinkingCollections = {
  __typename?: 'FeatureFlagLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type FeatureFlagLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum FeatureFlagOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

/** MS TEST [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/footer) */
export type Footer = Entry &
  _Node & {
    __typename?: 'Footer';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    copyrightText?: Maybe<Scalars['String']['output']>;
    footerLogo?: Maybe<Asset>;
    linkedFrom?: Maybe<FooterLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    navigationCollection?: Maybe<FooterNavigationCollection>;
    socialIconsLinksCollection?: Maybe<FooterSocialIconsLinksCollection>;
    sys: Sys;
  };

/** MS TEST [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/footer) */
export type FooterCopyrightTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** MS TEST [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/footer) */
export type FooterFooterLogoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** MS TEST [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/footer) */
export type FooterLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** MS TEST [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/footer) */
export type FooterNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** MS TEST [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/footer) */
export type FooterNavigationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FooterNavigationCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FooterNavigationGroupFilter>;
};

/** MS TEST [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/footer) */
export type FooterSocialIconsLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FooterSocialIconsLinksCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LinkFilter>;
};

export type FooterCollection = {
  __typename?: 'FooterCollection';
  items: Array<Maybe<Footer>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type FooterFilter = {
  AND?: InputMaybe<Array<InputMaybe<FooterFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FooterFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  copyrightText?: InputMaybe<Scalars['String']['input']>;
  copyrightText_contains?: InputMaybe<Scalars['String']['input']>;
  copyrightText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  copyrightText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  copyrightText_not?: InputMaybe<Scalars['String']['input']>;
  copyrightText_not_contains?: InputMaybe<Scalars['String']['input']>;
  copyrightText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  footerLogo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  navigation?: InputMaybe<CfFooterNavigationGroupNestedFilter>;
  navigationCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  socialIconsLinks?: InputMaybe<CfLinkNestedFilter>;
  socialIconsLinksCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type FooterLinkingCollections = {
  __typename?: 'FooterLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type FooterLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type FooterNavigationCollection = {
  __typename?: 'FooterNavigationCollection';
  items: Array<Maybe<FooterNavigationGroup>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum FooterNavigationCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/footerNavigationGroup) */
export type FooterNavigationGroup = Entry &
  _Node & {
    __typename?: 'FooterNavigationGroup';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    extras?: Maybe<FooterNavigationGroupExtras>;
    linkedFrom?: Maybe<FooterNavigationGroupLinkingCollections>;
    linksCollection?: Maybe<FooterNavigationGroupLinksCollection>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/footerNavigationGroup) */
export type FooterNavigationGroupExtrasArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<FooterNavigationGroupExtrasFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/footerNavigationGroup) */
export type FooterNavigationGroupLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/footerNavigationGroup) */
export type FooterNavigationGroupLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<FooterNavigationGroupLinksCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/footerNavigationGroup) */
export type FooterNavigationGroupNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/footerNavigationGroup) */
export type FooterNavigationGroupTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type FooterNavigationGroupCollection = {
  __typename?: 'FooterNavigationGroupCollection';
  items: Array<Maybe<FooterNavigationGroup>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/footerNavigationGroupExtras) */
export type FooterNavigationGroupExtras = Entry &
  _Node & {
    __typename?: 'FooterNavigationGroupExtras';
    _id: Scalars['ID']['output'];
    backgroundColor?: Maybe<Scalars['String']['output']>;
    color?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<FooterNavigationGroupExtrasLinkingCollections>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/footerNavigationGroupExtras) */
export type FooterNavigationGroupExtrasBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/footerNavigationGroupExtras) */
export type FooterNavigationGroupExtrasColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/footerNavigationGroupExtras) */
export type FooterNavigationGroupExtrasLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/footerNavigationGroupExtras) */
export type FooterNavigationGroupExtrasTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type FooterNavigationGroupExtrasCollection = {
  __typename?: 'FooterNavigationGroupExtrasCollection';
  items: Array<Maybe<FooterNavigationGroupExtras>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type FooterNavigationGroupExtrasFilter = {
  AND?: InputMaybe<Array<InputMaybe<FooterNavigationGroupExtrasFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FooterNavigationGroupExtrasFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundColor_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  color?: InputMaybe<Scalars['String']['input']>;
  color_contains?: InputMaybe<Scalars['String']['input']>;
  color_exists?: InputMaybe<Scalars['Boolean']['input']>;
  color_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color_not?: InputMaybe<Scalars['String']['input']>;
  color_not_contains?: InputMaybe<Scalars['String']['input']>;
  color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FooterNavigationGroupExtrasLinkingCollections = {
  __typename?: 'FooterNavigationGroupExtrasLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  footerNavigationGroupCollection?: Maybe<FooterNavigationGroupCollection>;
};

export type FooterNavigationGroupExtrasLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type FooterNavigationGroupExtrasLinkingCollectionsFooterNavigationGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<FooterNavigationGroupExtrasLinkingCollectionsFooterNavigationGroupCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum FooterNavigationGroupExtrasLinkingCollectionsFooterNavigationGroupCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FooterNavigationGroupExtrasOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type FooterNavigationGroupFilter = {
  AND?: InputMaybe<Array<InputMaybe<FooterNavigationGroupFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FooterNavigationGroupFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  extras?: InputMaybe<CfFooterNavigationGroupExtrasNestedFilter>;
  extras_exists?: InputMaybe<Scalars['Boolean']['input']>;
  links?: InputMaybe<CfLinkNestedFilter>;
  linksCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FooterNavigationGroupLinkingCollections = {
  __typename?: 'FooterNavigationGroupLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  footerCollection?: Maybe<FooterCollection>;
  layoutFooterCollection?: Maybe<LayoutFooterCollection>;
};

export type FooterNavigationGroupLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type FooterNavigationGroupLinkingCollectionsFooterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<FooterNavigationGroupLinkingCollectionsFooterCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type FooterNavigationGroupLinkingCollectionsLayoutFooterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<FooterNavigationGroupLinkingCollectionsLayoutFooterCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum FooterNavigationGroupLinkingCollectionsFooterCollectionOrder {
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum FooterNavigationGroupLinkingCollectionsLayoutFooterCollectionOrder {
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type FooterNavigationGroupLinksCollection = {
  __typename?: 'FooterNavigationGroupLinksCollection';
  items: Array<Maybe<Link>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum FooterNavigationGroupLinksCollectionOrder {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  GaTrackNameAsc = 'gaTrackName_ASC',
  GaTrackNameDesc = 'gaTrackName_DESC',
  HighlightedAsc = 'highlighted_ASC',
  HighlightedDesc = 'highlighted_DESC',
  IsDisplayedWhenLoggedInAsc = 'isDisplayedWhenLoggedIn_ASC',
  IsDisplayedWhenLoggedInDesc = 'isDisplayedWhenLoggedIn_DESC',
  IsInternalAsc = 'isInternal_ASC',
  IsInternalDesc = 'isInternal_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  MobileMenuToggleAsc = 'mobileMenuToggle_ASC',
  MobileMenuToggleDesc = 'mobileMenuToggle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoFollowAsc = 'noFollow_ASC',
  NoFollowDesc = 'noFollow_DESC',
  OpenInNewTabAsc = 'openInNewTab_ASC',
  OpenInNewTabDesc = 'openInNewTab_DESC',
  RedirectToLegacyProjectAsc = 'redirectToLegacyProject_ASC',
  RedirectToLegacyProjectDesc = 'redirectToLegacyProject_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnderlineAsc = 'underline_ASC',
  UnderlineDesc = 'underline_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

export enum FooterNavigationGroupOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FooterOrder {
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type FooterSocialIconsLinksCollection = {
  __typename?: 'FooterSocialIconsLinksCollection';
  items: Array<Maybe<Link>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum FooterSocialIconsLinksCollectionOrder {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  GaTrackNameAsc = 'gaTrackName_ASC',
  GaTrackNameDesc = 'gaTrackName_DESC',
  HighlightedAsc = 'highlighted_ASC',
  HighlightedDesc = 'highlighted_DESC',
  IsDisplayedWhenLoggedInAsc = 'isDisplayedWhenLoggedIn_ASC',
  IsDisplayedWhenLoggedInDesc = 'isDisplayedWhenLoggedIn_DESC',
  IsInternalAsc = 'isInternal_ASC',
  IsInternalDesc = 'isInternal_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  MobileMenuToggleAsc = 'mobileMenuToggle_ASC',
  MobileMenuToggleDesc = 'mobileMenuToggle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoFollowAsc = 'noFollow_ASC',
  NoFollowDesc = 'noFollow_DESC',
  OpenInNewTabAsc = 'openInNewTab_ASC',
  OpenInNewTabDesc = 'openInNewTab_DESC',
  RedirectToLegacyProjectAsc = 'redirectToLegacyProject_ASC',
  RedirectToLegacyProjectDesc = 'redirectToLegacyProject_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnderlineAsc = 'underline_ASC',
  UnderlineDesc = 'underline_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

/** [web-processes] Forgotten password page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/forgottenPasswordPage) */
export type ForgottenPasswordPage = Entry &
  _Node & {
    __typename?: 'ForgottenPasswordPage';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    continueButtonLabel?: Maybe<Scalars['String']['output']>;
    description?: Maybe<Scalars['String']['output']>;
    emailHelperText?: Maybe<Scalars['String']['output']>;
    emailInputLabel?: Maybe<Scalars['String']['output']>;
    header?: Maybe<Scalars['String']['output']>;
    helpText?: Maybe<ForgottenPasswordPageHelpText>;
    linkedFrom?: Maybe<ForgottenPasswordPageLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    resetPasswordTimerText?: Maybe<Scalars['String']['output']>;
    stickyBanner?: Maybe<StickyBanner>;
    successEmailSentAlert?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** [web-processes] Forgotten password page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/forgottenPasswordPage) */
export type ForgottenPasswordPageContinueButtonLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Forgotten password page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/forgottenPasswordPage) */
export type ForgottenPasswordPageDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Forgotten password page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/forgottenPasswordPage) */
export type ForgottenPasswordPageEmailHelperTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Forgotten password page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/forgottenPasswordPage) */
export type ForgottenPasswordPageEmailInputLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Forgotten password page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/forgottenPasswordPage) */
export type ForgottenPasswordPageHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Forgotten password page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/forgottenPasswordPage) */
export type ForgottenPasswordPageHelpTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Forgotten password page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/forgottenPasswordPage) */
export type ForgottenPasswordPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-processes] Forgotten password page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/forgottenPasswordPage) */
export type ForgottenPasswordPageNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Forgotten password page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/forgottenPasswordPage) */
export type ForgottenPasswordPageResetPasswordTimerTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Forgotten password page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/forgottenPasswordPage) */
export type ForgottenPasswordPageStickyBannerArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<StickyBannerFilter>;
};

/** [web-processes] Forgotten password page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/forgottenPasswordPage) */
export type ForgottenPasswordPageSuccessEmailSentAlertArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type ForgottenPasswordPageCollection = {
  __typename?: 'ForgottenPasswordPageCollection';
  items: Array<Maybe<ForgottenPasswordPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ForgottenPasswordPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ForgottenPasswordPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ForgottenPasswordPageFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  continueButtonLabel?: InputMaybe<Scalars['String']['input']>;
  continueButtonLabel_contains?: InputMaybe<Scalars['String']['input']>;
  continueButtonLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  continueButtonLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  continueButtonLabel_not?: InputMaybe<Scalars['String']['input']>;
  continueButtonLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  continueButtonLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  emailHelperText?: InputMaybe<Scalars['String']['input']>;
  emailHelperText_contains?: InputMaybe<Scalars['String']['input']>;
  emailHelperText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  emailHelperText_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  emailHelperText_not?: InputMaybe<Scalars['String']['input']>;
  emailHelperText_not_contains?: InputMaybe<Scalars['String']['input']>;
  emailHelperText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  emailInputLabel?: InputMaybe<Scalars['String']['input']>;
  emailInputLabel_contains?: InputMaybe<Scalars['String']['input']>;
  emailInputLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  emailInputLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  emailInputLabel_not?: InputMaybe<Scalars['String']['input']>;
  emailInputLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  emailInputLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  helpText_contains?: InputMaybe<Scalars['String']['input']>;
  helpText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  helpText_not_contains?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  resetPasswordTimerText?: InputMaybe<Scalars['String']['input']>;
  resetPasswordTimerText_contains?: InputMaybe<Scalars['String']['input']>;
  resetPasswordTimerText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  resetPasswordTimerText_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  resetPasswordTimerText_not?: InputMaybe<Scalars['String']['input']>;
  resetPasswordTimerText_not_contains?: InputMaybe<Scalars['String']['input']>;
  resetPasswordTimerText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  stickyBanner?: InputMaybe<CfStickyBannerNestedFilter>;
  stickyBanner_exists?: InputMaybe<Scalars['Boolean']['input']>;
  successEmailSentAlert?: InputMaybe<Scalars['String']['input']>;
  successEmailSentAlert_contains?: InputMaybe<Scalars['String']['input']>;
  successEmailSentAlert_exists?: InputMaybe<Scalars['Boolean']['input']>;
  successEmailSentAlert_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  successEmailSentAlert_not?: InputMaybe<Scalars['String']['input']>;
  successEmailSentAlert_not_contains?: InputMaybe<Scalars['String']['input']>;
  successEmailSentAlert_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
};

export type ForgottenPasswordPageHelpText = {
  __typename?: 'ForgottenPasswordPageHelpText';
  json: Scalars['JSON']['output'];
  links: ForgottenPasswordPageHelpTextLinks;
};

export type ForgottenPasswordPageHelpTextAssets = {
  __typename?: 'ForgottenPasswordPageHelpTextAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type ForgottenPasswordPageHelpTextEntries = {
  __typename?: 'ForgottenPasswordPageHelpTextEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type ForgottenPasswordPageHelpTextLinks = {
  __typename?: 'ForgottenPasswordPageHelpTextLinks';
  assets: ForgottenPasswordPageHelpTextAssets;
  entries: ForgottenPasswordPageHelpTextEntries;
  resources: ForgottenPasswordPageHelpTextResources;
};

export type ForgottenPasswordPageHelpTextResources = {
  __typename?: 'ForgottenPasswordPageHelpTextResources';
  block: Array<ForgottenPasswordPageHelpTextResourcesBlock>;
  hyperlink: Array<ForgottenPasswordPageHelpTextResourcesHyperlink>;
  inline: Array<ForgottenPasswordPageHelpTextResourcesInline>;
};

export type ForgottenPasswordPageHelpTextResourcesBlock = ResourceLink & {
  __typename?: 'ForgottenPasswordPageHelpTextResourcesBlock';
  sys: ResourceSys;
};

export type ForgottenPasswordPageHelpTextResourcesHyperlink = ResourceLink & {
  __typename?: 'ForgottenPasswordPageHelpTextResourcesHyperlink';
  sys: ResourceSys;
};

export type ForgottenPasswordPageHelpTextResourcesInline = ResourceLink & {
  __typename?: 'ForgottenPasswordPageHelpTextResourcesInline';
  sys: ResourceSys;
};

export type ForgottenPasswordPageLinkingCollections = {
  __typename?: 'ForgottenPasswordPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type ForgottenPasswordPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ForgottenPasswordPageOrder {
  ContinueButtonLabelAsc = 'continueButtonLabel_ASC',
  ContinueButtonLabelDesc = 'continueButtonLabel_DESC',
  EmailHelperTextAsc = 'emailHelperText_ASC',
  EmailHelperTextDesc = 'emailHelperText_DESC',
  EmailInputLabelAsc = 'emailInputLabel_ASC',
  EmailInputLabelDesc = 'emailInputLabel_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ResetPasswordTimerTextAsc = 'resetPasswordTimerText_ASC',
  ResetPasswordTimerTextDesc = 'resetPasswordTimerText_DESC',
  SuccessEmailSentAlertAsc = 'successEmailSentAlert_ASC',
  SuccessEmailSentAlertDesc = 'successEmailSentAlert_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/fullWidthBanner)
 */
export type FullWidthBanner = Entry &
  _Node & {
    __typename?: 'FullWidthBanner';
    _id: Scalars['ID']['output'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    backgroundColor?: Maybe<Scalars['String']['output']>;
    backgroundImage?: Maybe<Asset>;
    backgroundImageMobile?: Maybe<Asset>;
    borderColor?: Maybe<Scalars['String']['output']>;
    contentWidth?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    image?: Maybe<Asset>;
    link?: Maybe<Link>;
    linkedFrom?: Maybe<FullWidthBannerLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    secondBackgroundColor?: Maybe<Scalars['String']['output']>;
    secondBorderColor?: Maybe<Scalars['String']['output']>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sys: Sys;
    text?: Maybe<FullWidthBannerText>;
    textAlign?: Maybe<Scalars['String']['output']>;
    textColor?: Maybe<Scalars['String']['output']>;
    textVerticalAlign?: Maybe<Scalars['String']['output']>;
  };

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/fullWidthBanner)
 */
export type FullWidthBannerAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/fullWidthBanner)
 */
export type FullWidthBannerAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/fullWidthBanner)
 */
export type FullWidthBannerBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/fullWidthBanner)
 */
export type FullWidthBannerBackgroundImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/fullWidthBanner)
 */
export type FullWidthBannerBackgroundImageMobileArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/fullWidthBanner)
 */
export type FullWidthBannerBorderColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/fullWidthBanner)
 */
export type FullWidthBannerContentWidthArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/fullWidthBanner)
 */
export type FullWidthBannerImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/fullWidthBanner)
 */
export type FullWidthBannerLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/fullWidthBanner)
 */
export type FullWidthBannerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/fullWidthBanner)
 */
export type FullWidthBannerNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/fullWidthBanner)
 */
export type FullWidthBannerReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/fullWidthBanner)
 */
export type FullWidthBannerSecondBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/fullWidthBanner)
 */
export type FullWidthBannerSecondBorderColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/fullWidthBanner)
 */
export type FullWidthBannerSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/fullWidthBanner)
 */
export type FullWidthBannerTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/fullWidthBanner)
 */
export type FullWidthBannerTextAlignArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/fullWidthBanner)
 */
export type FullWidthBannerTextColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/fullWidthBanner)
 */
export type FullWidthBannerTextVerticalAlignArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type FullWidthBannerCollection = {
  __typename?: 'FullWidthBannerCollection';
  items: Array<Maybe<FullWidthBanner>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type FullWidthBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<FullWidthBannerFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FullWidthBannerFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundColor_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundImageMobile_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  borderColor?: InputMaybe<Scalars['String']['input']>;
  borderColor_contains?: InputMaybe<Scalars['String']['input']>;
  borderColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  borderColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  borderColor_not?: InputMaybe<Scalars['String']['input']>;
  borderColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  borderColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentWidth?: InputMaybe<Scalars['String']['input']>;
  contentWidth_contains?: InputMaybe<Scalars['String']['input']>;
  contentWidth_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentWidth_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentWidth_not?: InputMaybe<Scalars['String']['input']>;
  contentWidth_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentWidth_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link?: InputMaybe<CfLinkNestedFilter>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  secondBackgroundColor?: InputMaybe<Scalars['String']['input']>;
  secondBackgroundColor_contains?: InputMaybe<Scalars['String']['input']>;
  secondBackgroundColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  secondBackgroundColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  secondBackgroundColor_not?: InputMaybe<Scalars['String']['input']>;
  secondBackgroundColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  secondBackgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  secondBorderColor?: InputMaybe<Scalars['String']['input']>;
  secondBorderColor_contains?: InputMaybe<Scalars['String']['input']>;
  secondBorderColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  secondBorderColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  secondBorderColor_not?: InputMaybe<Scalars['String']['input']>;
  secondBorderColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  secondBorderColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  textAlign?: InputMaybe<Scalars['String']['input']>;
  textAlign_contains?: InputMaybe<Scalars['String']['input']>;
  textAlign_exists?: InputMaybe<Scalars['Boolean']['input']>;
  textAlign_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  textAlign_not?: InputMaybe<Scalars['String']['input']>;
  textAlign_not_contains?: InputMaybe<Scalars['String']['input']>;
  textAlign_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  textColor?: InputMaybe<Scalars['String']['input']>;
  textColor_contains?: InputMaybe<Scalars['String']['input']>;
  textColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  textColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  textColor_not?: InputMaybe<Scalars['String']['input']>;
  textColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  textColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  textVerticalAlign?: InputMaybe<Scalars['String']['input']>;
  textVerticalAlign_contains?: InputMaybe<Scalars['String']['input']>;
  textVerticalAlign_exists?: InputMaybe<Scalars['Boolean']['input']>;
  textVerticalAlign_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  textVerticalAlign_not?: InputMaybe<Scalars['String']['input']>;
  textVerticalAlign_not_contains?: InputMaybe<Scalars['String']['input']>;
  textVerticalAlign_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  text_contains?: InputMaybe<Scalars['String']['input']>;
  text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_not_contains?: InputMaybe<Scalars['String']['input']>;
};

export type FullWidthBannerLinkingCollections = {
  __typename?: 'FullWidthBannerLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type FullWidthBannerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum FullWidthBannerOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BorderColorAsc = 'borderColor_ASC',
  BorderColorDesc = 'borderColor_DESC',
  ContentWidthAsc = 'contentWidth_ASC',
  ContentWidthDesc = 'contentWidth_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  SecondBorderColorAsc = 'secondBorderColor_ASC',
  SecondBorderColorDesc = 'secondBorderColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAlignAsc = 'textAlign_ASC',
  TextAlignDesc = 'textAlign_DESC',
  TextColorAsc = 'textColor_ASC',
  TextColorDesc = 'textColor_DESC',
  TextVerticalAlignAsc = 'textVerticalAlign_ASC',
  TextVerticalAlignDesc = 'textVerticalAlign_DESC',
}

export type FullWidthBannerText = {
  __typename?: 'FullWidthBannerText';
  json: Scalars['JSON']['output'];
  links: FullWidthBannerTextLinks;
};

export type FullWidthBannerTextAssets = {
  __typename?: 'FullWidthBannerTextAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type FullWidthBannerTextEntries = {
  __typename?: 'FullWidthBannerTextEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type FullWidthBannerTextLinks = {
  __typename?: 'FullWidthBannerTextLinks';
  assets: FullWidthBannerTextAssets;
  entries: FullWidthBannerTextEntries;
  resources: FullWidthBannerTextResources;
};

export type FullWidthBannerTextResources = {
  __typename?: 'FullWidthBannerTextResources';
  block: Array<FullWidthBannerTextResourcesBlock>;
  hyperlink: Array<FullWidthBannerTextResourcesHyperlink>;
  inline: Array<FullWidthBannerTextResourcesInline>;
};

export type FullWidthBannerTextResourcesBlock = ResourceLink & {
  __typename?: 'FullWidthBannerTextResourcesBlock';
  sys: ResourceSys;
};

export type FullWidthBannerTextResourcesHyperlink = ResourceLink & {
  __typename?: 'FullWidthBannerTextResourcesHyperlink';
  sys: ResourceSys;
};

export type FullWidthBannerTextResourcesInline = ResourceLink & {
  __typename?: 'FullWidthBannerTextResourcesInline';
  sys: ResourceSys;
};

/** [web-processes] 5xx page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/genericErrorPage) */
export type GenericErrorPage = Entry &
  _Node & {
    __typename?: 'GenericErrorPage';
    _id: Scalars['ID']['output'];
    buildLocales?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<GenericErrorPageDescription>;
    header?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<GenericErrorPageLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    subDescription?: Maybe<GenericErrorPageSubDescription>;
    sys: Sys;
  };

/** [web-processes] 5xx page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/genericErrorPage) */
export type GenericErrorPageBuildLocalesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] 5xx page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/genericErrorPage) */
export type GenericErrorPageDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] 5xx page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/genericErrorPage) */
export type GenericErrorPageHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] 5xx page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/genericErrorPage) */
export type GenericErrorPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-processes] 5xx page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/genericErrorPage) */
export type GenericErrorPageNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] 5xx page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/genericErrorPage) */
export type GenericErrorPageSubDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type GenericErrorPageCollection = {
  __typename?: 'GenericErrorPageCollection';
  items: Array<Maybe<GenericErrorPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type GenericErrorPageDescription = {
  __typename?: 'GenericErrorPageDescription';
  json: Scalars['JSON']['output'];
  links: GenericErrorPageDescriptionLinks;
};

export type GenericErrorPageDescriptionAssets = {
  __typename?: 'GenericErrorPageDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type GenericErrorPageDescriptionEntries = {
  __typename?: 'GenericErrorPageDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type GenericErrorPageDescriptionLinks = {
  __typename?: 'GenericErrorPageDescriptionLinks';
  assets: GenericErrorPageDescriptionAssets;
  entries: GenericErrorPageDescriptionEntries;
  resources: GenericErrorPageDescriptionResources;
};

export type GenericErrorPageDescriptionResources = {
  __typename?: 'GenericErrorPageDescriptionResources';
  block: Array<GenericErrorPageDescriptionResourcesBlock>;
  hyperlink: Array<GenericErrorPageDescriptionResourcesHyperlink>;
  inline: Array<GenericErrorPageDescriptionResourcesInline>;
};

export type GenericErrorPageDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'GenericErrorPageDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type GenericErrorPageDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'GenericErrorPageDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type GenericErrorPageDescriptionResourcesInline = ResourceLink & {
  __typename?: 'GenericErrorPageDescriptionResourcesInline';
  sys: ResourceSys;
};

export type GenericErrorPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<GenericErrorPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<GenericErrorPageFilter>>>;
  buildLocales_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subDescription_contains?: InputMaybe<Scalars['String']['input']>;
  subDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type GenericErrorPageLinkingCollections = {
  __typename?: 'GenericErrorPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type GenericErrorPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum GenericErrorPageOrder {
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type GenericErrorPageSubDescription = {
  __typename?: 'GenericErrorPageSubDescription';
  json: Scalars['JSON']['output'];
  links: GenericErrorPageSubDescriptionLinks;
};

export type GenericErrorPageSubDescriptionAssets = {
  __typename?: 'GenericErrorPageSubDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type GenericErrorPageSubDescriptionEntries = {
  __typename?: 'GenericErrorPageSubDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type GenericErrorPageSubDescriptionLinks = {
  __typename?: 'GenericErrorPageSubDescriptionLinks';
  assets: GenericErrorPageSubDescriptionAssets;
  entries: GenericErrorPageSubDescriptionEntries;
  resources: GenericErrorPageSubDescriptionResources;
};

export type GenericErrorPageSubDescriptionResources = {
  __typename?: 'GenericErrorPageSubDescriptionResources';
  block: Array<GenericErrorPageSubDescriptionResourcesBlock>;
  hyperlink: Array<GenericErrorPageSubDescriptionResourcesHyperlink>;
  inline: Array<GenericErrorPageSubDescriptionResourcesInline>;
};

export type GenericErrorPageSubDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'GenericErrorPageSubDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type GenericErrorPageSubDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'GenericErrorPageSubDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type GenericErrorPageSubDescriptionResourcesInline = ResourceLink & {
  __typename?: 'GenericErrorPageSubDescriptionResourcesInline';
  sys: ResourceSys;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/heroSlot) */
export type HeroSlot = Entry &
  _Node & {
    __typename?: 'HeroSlot';
    _id: Scalars['ID']['output'];
    appStoreLink?: Maybe<Link>;
    buttonTitle?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    dividerLabel?: Maybe<Scalars['String']['output']>;
    downloadAppsPageLink?: Maybe<Link>;
    googlePlayStoreLink?: Maybe<Link>;
    header?: Maybe<Scalars['String']['output']>;
    image?: Maybe<Asset>;
    linkedFrom?: Maybe<HeroSlotLinkingCollections>;
    loginInLinkTitle?: Maybe<Scalars['String']['output']>;
    loginInText?: Maybe<Scalars['String']['output']>;
    loginPageLink?: Maybe<Link>;
    name?: Maybe<Scalars['String']['output']>;
    qrCode?: Maybe<Asset>;
    qrCodeLabel?: Maybe<Scalars['String']['output']>;
    subheader?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/heroSlot) */
export type HeroSlotAppStoreLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/heroSlot) */
export type HeroSlotButtonTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/heroSlot) */
export type HeroSlotDividerLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/heroSlot) */
export type HeroSlotDownloadAppsPageLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/heroSlot) */
export type HeroSlotGooglePlayStoreLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/heroSlot) */
export type HeroSlotHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/heroSlot) */
export type HeroSlotImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/heroSlot) */
export type HeroSlotLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/heroSlot) */
export type HeroSlotLoginInLinkTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/heroSlot) */
export type HeroSlotLoginInTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/heroSlot) */
export type HeroSlotLoginPageLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/heroSlot) */
export type HeroSlotNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/heroSlot) */
export type HeroSlotQrCodeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/heroSlot) */
export type HeroSlotQrCodeLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/heroSlot) */
export type HeroSlotSubheaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type HeroSlotCollection = {
  __typename?: 'HeroSlotCollection';
  items: Array<Maybe<HeroSlot>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type HeroSlotFilter = {
  AND?: InputMaybe<Array<InputMaybe<HeroSlotFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<HeroSlotFilter>>>;
  appStoreLink?: InputMaybe<CfLinkNestedFilter>;
  appStoreLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  buttonTitle?: InputMaybe<Scalars['String']['input']>;
  buttonTitle_contains?: InputMaybe<Scalars['String']['input']>;
  buttonTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  buttonTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  buttonTitle_not?: InputMaybe<Scalars['String']['input']>;
  buttonTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  buttonTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  dividerLabel?: InputMaybe<Scalars['String']['input']>;
  dividerLabel_contains?: InputMaybe<Scalars['String']['input']>;
  dividerLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  dividerLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dividerLabel_not?: InputMaybe<Scalars['String']['input']>;
  dividerLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  dividerLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  downloadAppsPageLink?: InputMaybe<CfLinkNestedFilter>;
  downloadAppsPageLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  googlePlayStoreLink?: InputMaybe<CfLinkNestedFilter>;
  googlePlayStoreLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  loginInLinkTitle?: InputMaybe<Scalars['String']['input']>;
  loginInLinkTitle_contains?: InputMaybe<Scalars['String']['input']>;
  loginInLinkTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  loginInLinkTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  loginInLinkTitle_not?: InputMaybe<Scalars['String']['input']>;
  loginInLinkTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  loginInLinkTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  loginInText?: InputMaybe<Scalars['String']['input']>;
  loginInText_contains?: InputMaybe<Scalars['String']['input']>;
  loginInText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  loginInText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  loginInText_not?: InputMaybe<Scalars['String']['input']>;
  loginInText_not_contains?: InputMaybe<Scalars['String']['input']>;
  loginInText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  loginPageLink?: InputMaybe<CfLinkNestedFilter>;
  loginPageLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  qrCodeLabel?: InputMaybe<Scalars['String']['input']>;
  qrCodeLabel_contains?: InputMaybe<Scalars['String']['input']>;
  qrCodeLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  qrCodeLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  qrCodeLabel_not?: InputMaybe<Scalars['String']['input']>;
  qrCodeLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  qrCodeLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  qrCode_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subheader?: InputMaybe<Scalars['String']['input']>;
  subheader_contains?: InputMaybe<Scalars['String']['input']>;
  subheader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subheader_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subheader_not?: InputMaybe<Scalars['String']['input']>;
  subheader_not_contains?: InputMaybe<Scalars['String']['input']>;
  subheader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type HeroSlotLinkingCollections = {
  __typename?: 'HeroSlotLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type HeroSlotLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum HeroSlotOrder {
  ButtonTitleAsc = 'buttonTitle_ASC',
  ButtonTitleDesc = 'buttonTitle_DESC',
  DividerLabelAsc = 'dividerLabel_ASC',
  DividerLabelDesc = 'dividerLabel_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  LoginInLinkTitleAsc = 'loginInLinkTitle_ASC',
  LoginInLinkTitleDesc = 'loginInLinkTitle_DESC',
  LoginInTextAsc = 'loginInText_ASC',
  LoginInTextDesc = 'loginInText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  QrCodeLabelAsc = 'qrCodeLabel_ASC',
  QrCodeLabelDesc = 'qrCodeLabel_DESC',
  SubheaderAsc = 'subheader_ASC',
  SubheaderDesc = 'subheader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** How does it work [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howDoesItWork) */
export type HowDoesItWork = Entry &
  _Node & {
    __typename?: 'HowDoesItWork';
    _id: Scalars['ID']['output'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    animationStepsCollection?: Maybe<HowDoesItWorkAnimationStepsCollection>;
    button?: Maybe<Entry>;
    contentfulMetadata: ContentfulMetadata;
    ctaButton?: Maybe<Link>;
    helpLink?: Maybe<Link>;
    linkedFrom?: Maybe<HowDoesItWorkLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    payoutMethod?: Maybe<PayoutMethod>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** How does it work [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howDoesItWork) */
export type HowDoesItWorkAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** How does it work [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howDoesItWork) */
export type HowDoesItWorkAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** How does it work [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howDoesItWork) */
export type HowDoesItWorkAnimationStepsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HowDoesItWorkAnimationStepsFilter>;
};

/** How does it work [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howDoesItWork) */
export type HowDoesItWorkButtonArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** How does it work [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howDoesItWork) */
export type HowDoesItWorkCtaButtonArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** How does it work [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howDoesItWork) */
export type HowDoesItWorkHelpLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** How does it work [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howDoesItWork) */
export type HowDoesItWorkLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** How does it work [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howDoesItWork) */
export type HowDoesItWorkNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** How does it work [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howDoesItWork) */
export type HowDoesItWorkPayoutMethodArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PayoutMethodFilter>;
};

/** How does it work [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howDoesItWork) */
export type HowDoesItWorkReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** How does it work [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howDoesItWork) */
export type HowDoesItWorkSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** How does it work [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howDoesItWork) */
export type HowDoesItWorkTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type HowDoesItWorkAnimationStepsCollection = {
  __typename?: 'HowDoesItWorkAnimationStepsCollection';
  items: Array<Maybe<HowDoesItWorkAnimationStepsItem>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type HowDoesItWorkAnimationStepsFilter = {
  AND?: InputMaybe<Array<InputMaybe<HowDoesItWorkAnimationStepsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<HowDoesItWorkAnimationStepsFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type HowDoesItWorkAnimationStepsItem = HowItWorksStepRelaunch;

export type HowDoesItWorkCollection = {
  __typename?: 'HowDoesItWorkCollection';
  items: Array<Maybe<HowDoesItWork>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type HowDoesItWorkFilter = {
  AND?: InputMaybe<Array<InputMaybe<HowDoesItWorkFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<HowDoesItWorkFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  animationSteps?: InputMaybe<CfanimationStepsMultiTypeNestedFilter>;
  animationStepsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  button_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  ctaButton?: InputMaybe<CfLinkNestedFilter>;
  ctaButton_exists?: InputMaybe<Scalars['Boolean']['input']>;
  helpLink?: InputMaybe<CfLinkNestedFilter>;
  helpLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  payoutMethod?: InputMaybe<CfPayoutMethodNestedFilter>;
  payoutMethod_exists?: InputMaybe<Scalars['Boolean']['input']>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type HowDoesItWorkLinkingCollections = {
  __typename?: 'HowDoesItWorkLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type HowDoesItWorkLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum HowDoesItWorkOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howItWorksStepRelaunch) */
export type HowItWorksStepRelaunch = Entry &
  _Node & {
    __typename?: 'HowItWorksStepRelaunch';
    _id: Scalars['ID']['output'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    header?: Maybe<Scalars['String']['output']>;
    image?: Maybe<Asset>;
    linkedFrom?: Maybe<HowItWorksStepRelaunchLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howItWorksStepRelaunch) */
export type HowItWorksStepRelaunchAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howItWorksStepRelaunch) */
export type HowItWorksStepRelaunchAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howItWorksStepRelaunch) */
export type HowItWorksStepRelaunchDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howItWorksStepRelaunch) */
export type HowItWorksStepRelaunchHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howItWorksStepRelaunch) */
export type HowItWorksStepRelaunchImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howItWorksStepRelaunch) */
export type HowItWorksStepRelaunchLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howItWorksStepRelaunch) */
export type HowItWorksStepRelaunchNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howItWorksStepRelaunch) */
export type HowItWorksStepRelaunchReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howItWorksStepRelaunch) */
export type HowItWorksStepRelaunchSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type HowItWorksStepRelaunchCollection = {
  __typename?: 'HowItWorksStepRelaunchCollection';
  items: Array<Maybe<HowItWorksStepRelaunch>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type HowItWorksStepRelaunchFilter = {
  AND?: InputMaybe<Array<InputMaybe<HowItWorksStepRelaunchFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<HowItWorksStepRelaunchFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type HowItWorksStepRelaunchLinkingCollections = {
  __typename?: 'HowItWorksStepRelaunchLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  howDoesItWorkCollection?: Maybe<HowDoesItWorkCollection>;
};

export type HowItWorksStepRelaunchLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type HowItWorksStepRelaunchLinkingCollectionsHowDoesItWorkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<HowItWorksStepRelaunchLinkingCollectionsHowDoesItWorkCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum HowItWorksStepRelaunchLinkingCollectionsHowDoesItWorkCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum HowItWorksStepRelaunchOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howSendingWorks) */
export type HowSendingWorks = Entry &
  _Node & {
    __typename?: 'HowSendingWorks';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    header?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<HowSendingWorksLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    stepsCollection?: Maybe<HowSendingWorksStepsCollection>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howSendingWorks) */
export type HowSendingWorksDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howSendingWorks) */
export type HowSendingWorksHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howSendingWorks) */
export type HowSendingWorksLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howSendingWorks) */
export type HowSendingWorksNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/howSendingWorks) */
export type HowSendingWorksStepsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type HowSendingWorksCollection = {
  __typename?: 'HowSendingWorksCollection';
  items: Array<Maybe<HowSendingWorks>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type HowSendingWorksFilter = {
  AND?: InputMaybe<Array<InputMaybe<HowSendingWorksFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<HowSendingWorksFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  stepsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type HowSendingWorksLinkingCollections = {
  __typename?: 'HowSendingWorksLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type HowSendingWorksLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum HowSendingWorksOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type HowSendingWorksStepsCollection = {
  __typename?: 'HowSendingWorksStepsCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

/** Small icon asset with text using TextWithColor content model [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/iconWithText) */
export type IconWithText = Entry &
  _Node & {
    __typename?: 'IconWithText';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    icon?: Maybe<Asset>;
    linkedFrom?: Maybe<IconWithTextLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    text?: Maybe<TextWithColor>;
  };

/** Small icon asset with text using TextWithColor content model [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/iconWithText) */
export type IconWithTextIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Small icon asset with text using TextWithColor content model [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/iconWithText) */
export type IconWithTextLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Small icon asset with text using TextWithColor content model [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/iconWithText) */
export type IconWithTextNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Small icon asset with text using TextWithColor content model [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/iconWithText) */
export type IconWithTextTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<TextWithColorFilter>;
};

export type IconWithTextCollection = {
  __typename?: 'IconWithTextCollection';
  items: Array<Maybe<IconWithText>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type IconWithTextFilter = {
  AND?: InputMaybe<Array<InputMaybe<IconWithTextFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<IconWithTextFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  text?: InputMaybe<CfTextWithColorNestedFilter>;
  text_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IconWithTextLinkingCollections = {
  __typename?: 'IconWithTextLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pageSectionCollection?: Maybe<PageSectionCollection>;
};

export type IconWithTextLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type IconWithTextLinkingCollectionsPageSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<IconWithTextLinkingCollectionsPageSectionCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum IconWithTextLinkingCollectionsPageSectionCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundGradientAsc = 'backgroundGradient_ASC',
  BackgroundGradientDesc = 'backgroundGradient_DESC',
  BackgroundTypeAsc = 'backgroundType_ASC',
  BackgroundTypeDesc = 'backgroundType_DESC',
  EnablePaddingAsc = 'enablePadding_ASC',
  EnablePaddingDesc = 'enablePadding_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum IconWithTextOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Image asset with alignment and size options [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/image) */
export type Image = Entry &
  _Node & {
    __typename?: 'Image';
    _id: Scalars['ID']['output'];
    align?: Maybe<Scalars['String']['output']>;
    asset?: Maybe<Asset>;
    contentfulMetadata: ContentfulMetadata;
    height?: Maybe<Scalars['Int']['output']>;
    linkedFrom?: Maybe<ImageLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    width?: Maybe<Scalars['Int']['output']>;
  };

/** Image asset with alignment and size options [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/image) */
export type ImageAlignArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Image asset with alignment and size options [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/image) */
export type ImageAssetArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Image asset with alignment and size options [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/image) */
export type ImageHeightArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Image asset with alignment and size options [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/image) */
export type ImageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Image asset with alignment and size options [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/image) */
export type ImageNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Image asset with alignment and size options [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/image) */
export type ImagePositionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Image asset with alignment and size options [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/image) */
export type ImageWidthArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type ImageCollection = {
  __typename?: 'ImageCollection';
  items: Array<Maybe<Image>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ImageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ImageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ImageFilter>>>;
  align?: InputMaybe<Scalars['String']['input']>;
  align_contains?: InputMaybe<Scalars['String']['input']>;
  align_exists?: InputMaybe<Scalars['Boolean']['input']>;
  align_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  align_not?: InputMaybe<Scalars['String']['input']>;
  align_not_contains?: InputMaybe<Scalars['String']['input']>;
  align_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  asset_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  height?: InputMaybe<Scalars['Int']['input']>;
  height_exists?: InputMaybe<Scalars['Boolean']['input']>;
  height_gt?: InputMaybe<Scalars['Int']['input']>;
  height_gte?: InputMaybe<Scalars['Int']['input']>;
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  height_lt?: InputMaybe<Scalars['Int']['input']>;
  height_lte?: InputMaybe<Scalars['Int']['input']>;
  height_not?: InputMaybe<Scalars['Int']['input']>;
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  position?: InputMaybe<Scalars['String']['input']>;
  position_contains?: InputMaybe<Scalars['String']['input']>;
  position_exists?: InputMaybe<Scalars['Boolean']['input']>;
  position_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  position_not?: InputMaybe<Scalars['String']['input']>;
  position_not_contains?: InputMaybe<Scalars['String']['input']>;
  position_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  width?: InputMaybe<Scalars['Int']['input']>;
  width_exists?: InputMaybe<Scalars['Boolean']['input']>;
  width_gt?: InputMaybe<Scalars['Int']['input']>;
  width_gte?: InputMaybe<Scalars['Int']['input']>;
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  width_lt?: InputMaybe<Scalars['Int']['input']>;
  width_lte?: InputMaybe<Scalars['Int']['input']>;
  width_not?: InputMaybe<Scalars['Int']['input']>;
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export enum ImageFormat {
  Avif = 'AVIF',
  /** JPG image format. */
  Jpg = 'JPG',
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JpgProgressive = 'JPG_PROGRESSIVE',
  /** PNG image format */
  Png = 'PNG',
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  Png8 = 'PNG8',
  /** WebP image format. */
  Webp = 'WEBP',
}

export type ImageLinkingCollections = {
  __typename?: 'ImageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type ImageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ImageOrder {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
}

export enum ImageResizeFocus {
  /** Focus the resizing on the bottom. */
  Bottom = 'BOTTOM',
  /** Focus the resizing on the bottom left. */
  BottomLeft = 'BOTTOM_LEFT',
  /** Focus the resizing on the bottom right. */
  BottomRight = 'BOTTOM_RIGHT',
  /** Focus the resizing on the center. */
  Center = 'CENTER',
  /** Focus the resizing on the largest face. */
  Face = 'FACE',
  /** Focus the resizing on the area containing all the faces. */
  Faces = 'FACES',
  /** Focus the resizing on the left. */
  Left = 'LEFT',
  /** Focus the resizing on the right. */
  Right = 'RIGHT',
  /** Focus the resizing on the top. */
  Top = 'TOP',
  /** Focus the resizing on the top left. */
  TopLeft = 'TOP_LEFT',
  /** Focus the resizing on the top right. */
  TopRight = 'TOP_RIGHT',
}

export enum ImageResizeStrategy {
  /** Crops a part of the original image to fit into the specified dimensions. */
  Crop = 'CROP',
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  Fill = 'FILL',
  /** Resizes the image to fit into the specified dimensions. */
  Fit = 'FIT',
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  Pad = 'PAD',
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  Scale = 'SCALE',
  /** Creates a thumbnail from the image. */
  Thumb = 'THUMB',
}

export type ImageTransformOptions = {
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor?: InputMaybe<Scalars['HexColor']['input']>;
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius?: InputMaybe<Scalars['Int']['input']>;
  /** Desired image format. Defaults to the original image format. */
  format?: InputMaybe<ImageFormat>;
  /** Desired height in pixels. Defaults to the original image height. */
  height?: InputMaybe<Scalars['Dimension']['input']>;
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality?: InputMaybe<Scalars['Quality']['input']>;
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus?: InputMaybe<ImageResizeFocus>;
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy?: InputMaybe<ImageResizeStrategy>;
  /** Desired width in pixels. Defaults to the original image width. */
  width?: InputMaybe<Scalars['Dimension']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/informationDialog) */
export type InformationDialog = Entry &
  _Node & {
    __typename?: 'InformationDialog';
    _id: Scalars['ID']['output'];
    content?: Maybe<InformationDialogContent>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<InformationDialogLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/informationDialog) */
export type InformationDialogContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/informationDialog) */
export type InformationDialogLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/informationDialog) */
export type InformationDialogNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/informationDialog) */
export type InformationDialogTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type InformationDialogCollection = {
  __typename?: 'InformationDialogCollection';
  items: Array<Maybe<InformationDialog>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type InformationDialogContent = {
  __typename?: 'InformationDialogContent';
  json: Scalars['JSON']['output'];
  links: InformationDialogContentLinks;
};

export type InformationDialogContentAssets = {
  __typename?: 'InformationDialogContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type InformationDialogContentEntries = {
  __typename?: 'InformationDialogContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type InformationDialogContentLinks = {
  __typename?: 'InformationDialogContentLinks';
  assets: InformationDialogContentAssets;
  entries: InformationDialogContentEntries;
  resources: InformationDialogContentResources;
};

export type InformationDialogContentResources = {
  __typename?: 'InformationDialogContentResources';
  block: Array<InformationDialogContentResourcesBlock>;
  hyperlink: Array<InformationDialogContentResourcesHyperlink>;
  inline: Array<InformationDialogContentResourcesInline>;
};

export type InformationDialogContentResourcesBlock = ResourceLink & {
  __typename?: 'InformationDialogContentResourcesBlock';
  sys: ResourceSys;
};

export type InformationDialogContentResourcesHyperlink = ResourceLink & {
  __typename?: 'InformationDialogContentResourcesHyperlink';
  sys: ResourceSys;
};

export type InformationDialogContentResourcesInline = ResourceLink & {
  __typename?: 'InformationDialogContentResourcesInline';
  sys: ResourceSys;
};

export type InformationDialogFilter = {
  AND?: InputMaybe<Array<InputMaybe<InformationDialogFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<InformationDialogFilter>>>;
  content_contains?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type InformationDialogLinkingCollections = {
  __typename?: 'InformationDialogLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type InformationDialogLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum InformationDialogOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Content type used for creating jazz cash page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/jazzCashPage) */
export type JazzCashPage = Entry &
  _Node & {
    __typename?: 'JazzCashPage';
    _id: Scalars['ID']['output'];
    analyticsPageName?: Maybe<Scalars['String']['output']>;
    analyticsPageType?: Maybe<Scalars['String']['output']>;
    buildLocales?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    footer?: Maybe<LayoutFooter>;
    isTopPage?: Maybe<Scalars['Boolean']['output']>;
    linkedFrom?: Maybe<JazzCashPageLinkingCollections>;
    modulesForNotLoggedInCollection?: Maybe<JazzCashPageModulesForNotLoggedInCollection>;
    modulesRelaunchCollection?: Maybe<JazzCashPageModulesRelaunchCollection>;
    name?: Maybe<Scalars['String']['output']>;
    noIndex?: Maybe<Scalars['Boolean']['output']>;
    sitemap?: Maybe<Scalars['Boolean']['output']>;
    sitemapTitle?: Maybe<Scalars['String']['output']>;
    slug?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Content type used for creating jazz cash page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/jazzCashPage) */
export type JazzCashPageAnalyticsPageNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Content type used for creating jazz cash page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/jazzCashPage) */
export type JazzCashPageAnalyticsPageTypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Content type used for creating jazz cash page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/jazzCashPage) */
export type JazzCashPageBuildLocalesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Content type used for creating jazz cash page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/jazzCashPage) */
export type JazzCashPageDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Content type used for creating jazz cash page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/jazzCashPage) */
export type JazzCashPageFooterArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LayoutFooterFilter>;
};

/** Content type used for creating jazz cash page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/jazzCashPage) */
export type JazzCashPageIsTopPageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Content type used for creating jazz cash page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/jazzCashPage) */
export type JazzCashPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Content type used for creating jazz cash page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/jazzCashPage) */
export type JazzCashPageModulesForNotLoggedInCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** Content type used for creating jazz cash page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/jazzCashPage) */
export type JazzCashPageModulesRelaunchCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** Content type used for creating jazz cash page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/jazzCashPage) */
export type JazzCashPageNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Content type used for creating jazz cash page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/jazzCashPage) */
export type JazzCashPageNoIndexArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Content type used for creating jazz cash page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/jazzCashPage) */
export type JazzCashPageSitemapArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Content type used for creating jazz cash page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/jazzCashPage) */
export type JazzCashPageSitemapTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Content type used for creating jazz cash page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/jazzCashPage) */
export type JazzCashPageSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Content type used for creating jazz cash page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/jazzCashPage) */
export type JazzCashPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type JazzCashPageCollection = {
  __typename?: 'JazzCashPageCollection';
  items: Array<Maybe<JazzCashPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type JazzCashPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<JazzCashPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<JazzCashPageFilter>>>;
  analyticsPageName?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  analyticsPageName_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  analyticsPageName_not?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_not_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  analyticsPageType?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  analyticsPageType_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  analyticsPageType_not?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_not_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  footer?: InputMaybe<CfLayoutFooterNestedFilter>;
  footer_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isTopPage?: InputMaybe<Scalars['Boolean']['input']>;
  isTopPage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isTopPage_not?: InputMaybe<Scalars['Boolean']['input']>;
  modulesForNotLoggedInCollection_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  modulesRelaunchCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noIndex?: InputMaybe<Scalars['Boolean']['input']>;
  noIndex_exists?: InputMaybe<Scalars['Boolean']['input']>;
  noIndex_not?: InputMaybe<Scalars['Boolean']['input']>;
  sitemap?: InputMaybe<Scalars['Boolean']['input']>;
  sitemapTitle?: InputMaybe<Scalars['String']['input']>;
  sitemapTitle_contains?: InputMaybe<Scalars['String']['input']>;
  sitemapTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sitemapTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sitemapTitle_not?: InputMaybe<Scalars['String']['input']>;
  sitemapTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  sitemapTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sitemap_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sitemap_not?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type JazzCashPageLinkingCollections = {
  __typename?: 'JazzCashPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type JazzCashPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type JazzCashPageModulesForNotLoggedInCollection = {
  __typename?: 'JazzCashPageModulesForNotLoggedInCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type JazzCashPageModulesRelaunchCollection = {
  __typename?: 'JazzCashPageModulesRelaunchCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum JazzCashPageOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IsTopPageAsc = 'isTopPage_ASC',
  IsTopPageDesc = 'isTopPage_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** The main module of the Jazz cash page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/jazzCashRequest) */
export type JazzCashRequest = Entry &
  _Node & {
    __typename?: 'JazzCashRequest';
    _id: Scalars['ID']['output'];
    amountRequestedLabel?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<JazzCashRequestLinkingCollections>;
    mobileNumberLabel?: Maybe<Scalars['String']['output']>;
    moneyProviderLabel?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    quoteIcon?: Maybe<Asset>;
    requestedByLabel?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** The main module of the Jazz cash page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/jazzCashRequest) */
export type JazzCashRequestAmountRequestedLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** The main module of the Jazz cash page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/jazzCashRequest) */
export type JazzCashRequestLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** The main module of the Jazz cash page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/jazzCashRequest) */
export type JazzCashRequestMobileNumberLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** The main module of the Jazz cash page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/jazzCashRequest) */
export type JazzCashRequestMoneyProviderLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** The main module of the Jazz cash page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/jazzCashRequest) */
export type JazzCashRequestNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** The main module of the Jazz cash page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/jazzCashRequest) */
export type JazzCashRequestQuoteIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The main module of the Jazz cash page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/jazzCashRequest) */
export type JazzCashRequestRequestedByLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** The main module of the Jazz cash page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/jazzCashRequest) */
export type JazzCashRequestTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type JazzCashRequestCollection = {
  __typename?: 'JazzCashRequestCollection';
  items: Array<Maybe<JazzCashRequest>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type JazzCashRequestFilter = {
  AND?: InputMaybe<Array<InputMaybe<JazzCashRequestFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<JazzCashRequestFilter>>>;
  amountRequestedLabel?: InputMaybe<Scalars['String']['input']>;
  amountRequestedLabel_contains?: InputMaybe<Scalars['String']['input']>;
  amountRequestedLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  amountRequestedLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  amountRequestedLabel_not?: InputMaybe<Scalars['String']['input']>;
  amountRequestedLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  amountRequestedLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  mobileNumberLabel?: InputMaybe<Scalars['String']['input']>;
  mobileNumberLabel_contains?: InputMaybe<Scalars['String']['input']>;
  mobileNumberLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileNumberLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  mobileNumberLabel_not?: InputMaybe<Scalars['String']['input']>;
  mobileNumberLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  mobileNumberLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  moneyProviderLabel?: InputMaybe<Scalars['String']['input']>;
  moneyProviderLabel_contains?: InputMaybe<Scalars['String']['input']>;
  moneyProviderLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  moneyProviderLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  moneyProviderLabel_not?: InputMaybe<Scalars['String']['input']>;
  moneyProviderLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  moneyProviderLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  quoteIcon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  requestedByLabel?: InputMaybe<Scalars['String']['input']>;
  requestedByLabel_contains?: InputMaybe<Scalars['String']['input']>;
  requestedByLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  requestedByLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  requestedByLabel_not?: InputMaybe<Scalars['String']['input']>;
  requestedByLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  requestedByLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type JazzCashRequestLinkingCollections = {
  __typename?: 'JazzCashRequestLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type JazzCashRequestLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum JazzCashRequestOrder {
  AmountRequestedLabelAsc = 'amountRequestedLabel_ASC',
  AmountRequestedLabelDesc = 'amountRequestedLabel_DESC',
  MobileNumberLabelAsc = 'mobileNumberLabel_ASC',
  MobileNumberLabelDesc = 'mobileNumberLabel_DESC',
  MoneyProviderLabelAsc = 'moneyProviderLabel_ASC',
  MoneyProviderLabelDesc = 'moneyProviderLabel_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RequestedByLabelAsc = 'requestedByLabel_ASC',
  RequestedByLabelDesc = 'requestedByLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutFooter) */
export type LayoutFooter = Entry &
  _Node & {
    __typename?: 'LayoutFooter';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    copyrightText?: Maybe<Scalars['String']['output']>;
    footerLogo?: Maybe<Asset>;
    linkedFrom?: Maybe<LayoutFooterLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    navigationCollection?: Maybe<LayoutFooterNavigationCollection>;
    region?: Maybe<Scalars['String']['output']>;
    socialIconsLinksCollection?: Maybe<LayoutFooterSocialIconsLinksCollection>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutFooter) */
export type LayoutFooterCopyrightTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutFooter) */
export type LayoutFooterFooterLogoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutFooter) */
export type LayoutFooterLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutFooter) */
export type LayoutFooterNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutFooter) */
export type LayoutFooterNavigationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<LayoutFooterNavigationCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FooterNavigationGroupFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutFooter) */
export type LayoutFooterRegionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutFooter) */
export type LayoutFooterSocialIconsLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LayoutFooterSocialIconsLinksCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LinkFilter>;
};

export type LayoutFooterCollection = {
  __typename?: 'LayoutFooterCollection';
  items: Array<Maybe<LayoutFooter>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type LayoutFooterFilter = {
  AND?: InputMaybe<Array<InputMaybe<LayoutFooterFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LayoutFooterFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  copyrightText?: InputMaybe<Scalars['String']['input']>;
  copyrightText_contains?: InputMaybe<Scalars['String']['input']>;
  copyrightText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  copyrightText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  copyrightText_not?: InputMaybe<Scalars['String']['input']>;
  copyrightText_not_contains?: InputMaybe<Scalars['String']['input']>;
  copyrightText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  footerLogo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  navigation?: InputMaybe<CfFooterNavigationGroupNestedFilter>;
  navigationCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  region_contains?: InputMaybe<Scalars['String']['input']>;
  region_exists?: InputMaybe<Scalars['Boolean']['input']>;
  region_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  region_not?: InputMaybe<Scalars['String']['input']>;
  region_not_contains?: InputMaybe<Scalars['String']['input']>;
  region_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  socialIconsLinks?: InputMaybe<CfLinkNestedFilter>;
  socialIconsLinksCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type LayoutFooterLinkingCollections = {
  __typename?: 'LayoutFooterLinkingCollections';
  calculatorPageCollection?: Maybe<CalculatorPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
  jazzCashPageCollection?: Maybe<JazzCashPageCollection>;
  notFoundPageCollection?: Maybe<NotFoundPageCollection>;
  pageCollection?: Maybe<PageCollection>;
  requestMoneyPageCollection?: Maybe<RequestMoneyPageCollection>;
};

export type LayoutFooterLinkingCollectionsCalculatorPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<LayoutFooterLinkingCollectionsCalculatorPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LayoutFooterLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LayoutFooterLinkingCollectionsJazzCashPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LayoutFooterLinkingCollectionsJazzCashPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LayoutFooterLinkingCollectionsNotFoundPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LayoutFooterLinkingCollectionsNotFoundPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LayoutFooterLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LayoutFooterLinkingCollectionsPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LayoutFooterLinkingCollectionsRequestMoneyPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<LayoutFooterLinkingCollectionsRequestMoneyPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum LayoutFooterLinkingCollectionsCalculatorPageCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IsTopPageAsc = 'isTopPage_ASC',
  IsTopPageDesc = 'isTopPage_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum LayoutFooterLinkingCollectionsJazzCashPageCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IsTopPageAsc = 'isTopPage_ASC',
  IsTopPageDesc = 'isTopPage_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum LayoutFooterLinkingCollectionsNotFoundPageCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  MetaDescriptionAsc = 'metaDescription_ASC',
  MetaDescriptionDesc = 'metaDescription_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum LayoutFooterLinkingCollectionsPageCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderPositionAsc = 'headerPosition_ASC',
  HeaderPositionDesc = 'headerPosition_DESC',
  HeaderThemeAsc = 'headerTheme_ASC',
  HeaderThemeDesc = 'headerTheme_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimeToReadAsc = 'timeToRead_ASC',
  TimeToReadDesc = 'timeToRead_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export enum LayoutFooterLinkingCollectionsRequestMoneyPageCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IsTopPageAsc = 'isTopPage_ASC',
  IsTopPageDesc = 'isTopPage_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type LayoutFooterNavigationCollection = {
  __typename?: 'LayoutFooterNavigationCollection';
  items: Array<Maybe<FooterNavigationGroup>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum LayoutFooterNavigationCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum LayoutFooterOrder {
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type LayoutFooterSocialIconsLinksCollection = {
  __typename?: 'LayoutFooterSocialIconsLinksCollection';
  items: Array<Maybe<Link>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum LayoutFooterSocialIconsLinksCollectionOrder {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  GaTrackNameAsc = 'gaTrackName_ASC',
  GaTrackNameDesc = 'gaTrackName_DESC',
  HighlightedAsc = 'highlighted_ASC',
  HighlightedDesc = 'highlighted_DESC',
  IsDisplayedWhenLoggedInAsc = 'isDisplayedWhenLoggedIn_ASC',
  IsDisplayedWhenLoggedInDesc = 'isDisplayedWhenLoggedIn_DESC',
  IsInternalAsc = 'isInternal_ASC',
  IsInternalDesc = 'isInternal_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  MobileMenuToggleAsc = 'mobileMenuToggle_ASC',
  MobileMenuToggleDesc = 'mobileMenuToggle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoFollowAsc = 'noFollow_ASC',
  NoFollowDesc = 'noFollow_DESC',
  OpenInNewTabAsc = 'openInNewTab_ASC',
  OpenInNewTabDesc = 'openInNewTab_DESC',
  RedirectToLegacyProjectAsc = 'redirectToLegacyProject_ASC',
  RedirectToLegacyProjectDesc = 'redirectToLegacyProject_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnderlineAsc = 'underline_ASC',
  UnderlineDesc = 'underline_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutHeader) */
export type LayoutHeader = Entry &
  _Node & {
    __typename?: 'LayoutHeader';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    languageSwitcherOption?: Maybe<Scalars['String']['output']>;
    lastLogin?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<LayoutHeaderLinkingCollections>;
    loggedInMenuItemsCollection?: Maybe<LayoutHeaderLoggedInMenuItemsCollection>;
    logo?: Maybe<Asset>;
    logoDark?: Maybe<Asset>;
    logoDarkMobile?: Maybe<Asset>;
    logoLight?: Maybe<Asset>;
    logoLightMobile?: Maybe<Asset>;
    logoRelaunch?: Maybe<Asset>;
    logoRelaunchMobile?: Maybe<Asset>;
    menuLabel?: Maybe<Scalars['String']['output']>;
    myAccount?: Maybe<Scalars['String']['output']>;
    myAccountLogoDark?: Maybe<Asset>;
    myAccountLogoLight?: Maybe<Asset>;
    name?: Maybe<Scalars['String']['output']>;
    notLoggedInMenuItemsCollection?: Maybe<LayoutHeaderNotLoggedInMenuItemsCollection>;
    referFriendLink?: Maybe<LayoutHeaderReferFriendLink>;
    sendFrom?: Maybe<Scalars['String']['output']>;
    skipToMainContent?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
    userDropdownMenuItemsCollection?: Maybe<LayoutHeaderUserDropdownMenuItemsCollection>;
    welcome?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutHeader) */
export type LayoutHeaderLanguageSwitcherOptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutHeader) */
export type LayoutHeaderLastLoginArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutHeader) */
export type LayoutHeaderLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutHeader) */
export type LayoutHeaderLoggedInMenuItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LayoutHeaderLoggedInMenuItemsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutHeader) */
export type LayoutHeaderLogoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutHeader) */
export type LayoutHeaderLogoDarkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutHeader) */
export type LayoutHeaderLogoDarkMobileArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutHeader) */
export type LayoutHeaderLogoLightArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutHeader) */
export type LayoutHeaderLogoLightMobileArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutHeader) */
export type LayoutHeaderLogoRelaunchArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutHeader) */
export type LayoutHeaderLogoRelaunchMobileArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutHeader) */
export type LayoutHeaderMenuLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutHeader) */
export type LayoutHeaderMyAccountArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutHeader) */
export type LayoutHeaderMyAccountLogoDarkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutHeader) */
export type LayoutHeaderMyAccountLogoLightArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutHeader) */
export type LayoutHeaderNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutHeader) */
export type LayoutHeaderNotLoggedInMenuItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LayoutHeaderNotLoggedInMenuItemsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutHeader) */
export type LayoutHeaderReferFriendLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LayoutHeaderReferFriendLinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutHeader) */
export type LayoutHeaderSendFromArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutHeader) */
export type LayoutHeaderSkipToMainContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutHeader) */
export type LayoutHeaderTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutHeader) */
export type LayoutHeaderUserDropdownMenuItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LayoutHeaderUserDropdownMenuItemsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/layoutHeader) */
export type LayoutHeaderWelcomeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type LayoutHeaderCollection = {
  __typename?: 'LayoutHeaderCollection';
  items: Array<Maybe<LayoutHeader>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type LayoutHeaderFilter = {
  AND?: InputMaybe<Array<InputMaybe<LayoutHeaderFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LayoutHeaderFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  languageSwitcherOption?: InputMaybe<Scalars['String']['input']>;
  languageSwitcherOption_contains?: InputMaybe<Scalars['String']['input']>;
  languageSwitcherOption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  languageSwitcherOption_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  languageSwitcherOption_not?: InputMaybe<Scalars['String']['input']>;
  languageSwitcherOption_not_contains?: InputMaybe<Scalars['String']['input']>;
  languageSwitcherOption_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  lastLogin?: InputMaybe<Scalars['String']['input']>;
  lastLogin_contains?: InputMaybe<Scalars['String']['input']>;
  lastLogin_exists?: InputMaybe<Scalars['Boolean']['input']>;
  lastLogin_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lastLogin_not?: InputMaybe<Scalars['String']['input']>;
  lastLogin_not_contains?: InputMaybe<Scalars['String']['input']>;
  lastLogin_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  loggedInMenuItems?: InputMaybe<CfloggedInMenuItemsMultiTypeNestedFilter>;
  loggedInMenuItemsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  logoDarkMobile_exists?: InputMaybe<Scalars['Boolean']['input']>;
  logoDark_exists?: InputMaybe<Scalars['Boolean']['input']>;
  logoLightMobile_exists?: InputMaybe<Scalars['Boolean']['input']>;
  logoLight_exists?: InputMaybe<Scalars['Boolean']['input']>;
  logoRelaunchMobile_exists?: InputMaybe<Scalars['Boolean']['input']>;
  logoRelaunch_exists?: InputMaybe<Scalars['Boolean']['input']>;
  logo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  menuLabel?: InputMaybe<Scalars['String']['input']>;
  menuLabel_contains?: InputMaybe<Scalars['String']['input']>;
  menuLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  menuLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  menuLabel_not?: InputMaybe<Scalars['String']['input']>;
  menuLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  menuLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  myAccount?: InputMaybe<Scalars['String']['input']>;
  myAccountLogoDark_exists?: InputMaybe<Scalars['Boolean']['input']>;
  myAccountLogoLight_exists?: InputMaybe<Scalars['Boolean']['input']>;
  myAccount_contains?: InputMaybe<Scalars['String']['input']>;
  myAccount_exists?: InputMaybe<Scalars['Boolean']['input']>;
  myAccount_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  myAccount_not?: InputMaybe<Scalars['String']['input']>;
  myAccount_not_contains?: InputMaybe<Scalars['String']['input']>;
  myAccount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notLoggedInMenuItems?: InputMaybe<CfnotLoggedInMenuItemsMultiTypeNestedFilter>;
  notLoggedInMenuItemsCollection_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  referFriendLink?: InputMaybe<CfreferFriendLinkMultiTypeNestedFilter>;
  referFriendLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendFrom?: InputMaybe<Scalars['String']['input']>;
  sendFrom_contains?: InputMaybe<Scalars['String']['input']>;
  sendFrom_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sendFrom_not?: InputMaybe<Scalars['String']['input']>;
  sendFrom_not_contains?: InputMaybe<Scalars['String']['input']>;
  sendFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  skipToMainContent?: InputMaybe<Scalars['String']['input']>;
  skipToMainContent_contains?: InputMaybe<Scalars['String']['input']>;
  skipToMainContent_exists?: InputMaybe<Scalars['Boolean']['input']>;
  skipToMainContent_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  skipToMainContent_not?: InputMaybe<Scalars['String']['input']>;
  skipToMainContent_not_contains?: InputMaybe<Scalars['String']['input']>;
  skipToMainContent_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userDropdownMenuItems?: InputMaybe<CfuserDropdownMenuItemsMultiTypeNestedFilter>;
  userDropdownMenuItemsCollection_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  welcome?: InputMaybe<Scalars['String']['input']>;
  welcome_contains?: InputMaybe<Scalars['String']['input']>;
  welcome_exists?: InputMaybe<Scalars['Boolean']['input']>;
  welcome_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  welcome_not?: InputMaybe<Scalars['String']['input']>;
  welcome_not_contains?: InputMaybe<Scalars['String']['input']>;
  welcome_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LayoutHeaderLinkingCollections = {
  __typename?: 'LayoutHeaderLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type LayoutHeaderLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LayoutHeaderLoggedInMenuItemsCollection = {
  __typename?: 'LayoutHeaderLoggedInMenuItemsCollection';
  items: Array<Maybe<LayoutHeaderLoggedInMenuItemsItem>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type LayoutHeaderLoggedInMenuItemsFilter = {
  AND?: InputMaybe<Array<InputMaybe<LayoutHeaderLoggedInMenuItemsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LayoutHeaderLoggedInMenuItemsFilter>>>;
  align?: InputMaybe<Scalars['String']['input']>;
  align_contains?: InputMaybe<Scalars['String']['input']>;
  align_exists?: InputMaybe<Scalars['Boolean']['input']>;
  align_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  align_not?: InputMaybe<Scalars['String']['input']>;
  align_not_contains?: InputMaybe<Scalars['String']['input']>;
  align_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ariaLabel?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_contains?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ariaLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ariaLabel_not?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color?: InputMaybe<Scalars['String']['input']>;
  color_contains?: InputMaybe<Scalars['String']['input']>;
  color_exists?: InputMaybe<Scalars['Boolean']['input']>;
  color_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color_not?: InputMaybe<Scalars['String']['input']>;
  color_not_contains?: InputMaybe<Scalars['String']['input']>;
  color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  gaTrackName?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_contains?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  gaTrackName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gaTrackName_not?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_not_contains?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  highlighted?: InputMaybe<Scalars['Boolean']['input']>;
  highlighted_exists?: InputMaybe<Scalars['Boolean']['input']>;
  highlighted_not?: InputMaybe<Scalars['Boolean']['input']>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn_not?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal_not?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mobileAppLeadingLinks_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileMenuToggle?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_contains?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileMenuToggle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  mobileMenuToggle_not?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_not_contains?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noFollow?: InputMaybe<Scalars['Boolean']['input']>;
  noFollow_exists?: InputMaybe<Scalars['Boolean']['input']>;
  noFollow_not?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab_not?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject_exists?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  underline?: InputMaybe<Scalars['String']['input']>;
  underline_contains?: InputMaybe<Scalars['String']['input']>;
  underline_exists?: InputMaybe<Scalars['Boolean']['input']>;
  underline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  underline_not?: InputMaybe<Scalars['String']['input']>;
  underline_not_contains?: InputMaybe<Scalars['String']['input']>;
  underline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_contains?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_not?: InputMaybe<Scalars['String']['input']>;
  url_not_contains?: InputMaybe<Scalars['String']['input']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variant?: InputMaybe<Scalars['String']['input']>;
  variant_contains?: InputMaybe<Scalars['String']['input']>;
  variant_exists?: InputMaybe<Scalars['Boolean']['input']>;
  variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variant_not?: InputMaybe<Scalars['String']['input']>;
  variant_not_contains?: InputMaybe<Scalars['String']['input']>;
  variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LayoutHeaderLoggedInMenuItemsItem = Link;

export type LayoutHeaderNotLoggedInMenuItemsCollection = {
  __typename?: 'LayoutHeaderNotLoggedInMenuItemsCollection';
  items: Array<Maybe<LayoutHeaderNotLoggedInMenuItemsItem>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type LayoutHeaderNotLoggedInMenuItemsFilter = {
  AND?: InputMaybe<Array<InputMaybe<LayoutHeaderNotLoggedInMenuItemsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LayoutHeaderNotLoggedInMenuItemsFilter>>>;
  align?: InputMaybe<Scalars['String']['input']>;
  align_contains?: InputMaybe<Scalars['String']['input']>;
  align_exists?: InputMaybe<Scalars['Boolean']['input']>;
  align_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  align_not?: InputMaybe<Scalars['String']['input']>;
  align_not_contains?: InputMaybe<Scalars['String']['input']>;
  align_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ariaLabel?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_contains?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ariaLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ariaLabel_not?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color?: InputMaybe<Scalars['String']['input']>;
  color_contains?: InputMaybe<Scalars['String']['input']>;
  color_exists?: InputMaybe<Scalars['Boolean']['input']>;
  color_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color_not?: InputMaybe<Scalars['String']['input']>;
  color_not_contains?: InputMaybe<Scalars['String']['input']>;
  color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  gaTrackName?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_contains?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  gaTrackName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gaTrackName_not?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_not_contains?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  highlighted?: InputMaybe<Scalars['Boolean']['input']>;
  highlighted_exists?: InputMaybe<Scalars['Boolean']['input']>;
  highlighted_not?: InputMaybe<Scalars['Boolean']['input']>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn_not?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal_not?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mobileAppLeadingLinks_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileMenuToggle?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_contains?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileMenuToggle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  mobileMenuToggle_not?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_not_contains?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noFollow?: InputMaybe<Scalars['Boolean']['input']>;
  noFollow_exists?: InputMaybe<Scalars['Boolean']['input']>;
  noFollow_not?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab_not?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject_exists?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  underline?: InputMaybe<Scalars['String']['input']>;
  underline_contains?: InputMaybe<Scalars['String']['input']>;
  underline_exists?: InputMaybe<Scalars['Boolean']['input']>;
  underline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  underline_not?: InputMaybe<Scalars['String']['input']>;
  underline_not_contains?: InputMaybe<Scalars['String']['input']>;
  underline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_contains?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_not?: InputMaybe<Scalars['String']['input']>;
  url_not_contains?: InputMaybe<Scalars['String']['input']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variant?: InputMaybe<Scalars['String']['input']>;
  variant_contains?: InputMaybe<Scalars['String']['input']>;
  variant_exists?: InputMaybe<Scalars['Boolean']['input']>;
  variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variant_not?: InputMaybe<Scalars['String']['input']>;
  variant_not_contains?: InputMaybe<Scalars['String']['input']>;
  variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LayoutHeaderNotLoggedInMenuItemsItem = Link;

export enum LayoutHeaderOrder {
  LanguageSwitcherOptionAsc = 'languageSwitcherOption_ASC',
  LanguageSwitcherOptionDesc = 'languageSwitcherOption_DESC',
  LastLoginAsc = 'lastLogin_ASC',
  LastLoginDesc = 'lastLogin_DESC',
  MenuLabelAsc = 'menuLabel_ASC',
  MenuLabelDesc = 'menuLabel_DESC',
  MyAccountAsc = 'myAccount_ASC',
  MyAccountDesc = 'myAccount_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SendFromAsc = 'sendFrom_ASC',
  SendFromDesc = 'sendFrom_DESC',
  SkipToMainContentAsc = 'skipToMainContent_ASC',
  SkipToMainContentDesc = 'skipToMainContent_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  WelcomeAsc = 'welcome_ASC',
  WelcomeDesc = 'welcome_DESC',
}

export type LayoutHeaderReferFriendLink = Link;

export type LayoutHeaderReferFriendLinkFilter = {
  AND?: InputMaybe<Array<InputMaybe<LayoutHeaderReferFriendLinkFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LayoutHeaderReferFriendLinkFilter>>>;
  align?: InputMaybe<Scalars['String']['input']>;
  align_contains?: InputMaybe<Scalars['String']['input']>;
  align_exists?: InputMaybe<Scalars['Boolean']['input']>;
  align_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  align_not?: InputMaybe<Scalars['String']['input']>;
  align_not_contains?: InputMaybe<Scalars['String']['input']>;
  align_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ariaLabel?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_contains?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ariaLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ariaLabel_not?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color?: InputMaybe<Scalars['String']['input']>;
  color_contains?: InputMaybe<Scalars['String']['input']>;
  color_exists?: InputMaybe<Scalars['Boolean']['input']>;
  color_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color_not?: InputMaybe<Scalars['String']['input']>;
  color_not_contains?: InputMaybe<Scalars['String']['input']>;
  color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  gaTrackName?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_contains?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  gaTrackName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gaTrackName_not?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_not_contains?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  highlighted?: InputMaybe<Scalars['Boolean']['input']>;
  highlighted_exists?: InputMaybe<Scalars['Boolean']['input']>;
  highlighted_not?: InputMaybe<Scalars['Boolean']['input']>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn_not?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal_not?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mobileAppLeadingLinks_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileMenuToggle?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_contains?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileMenuToggle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  mobileMenuToggle_not?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_not_contains?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noFollow?: InputMaybe<Scalars['Boolean']['input']>;
  noFollow_exists?: InputMaybe<Scalars['Boolean']['input']>;
  noFollow_not?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab_not?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject_exists?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  underline?: InputMaybe<Scalars['String']['input']>;
  underline_contains?: InputMaybe<Scalars['String']['input']>;
  underline_exists?: InputMaybe<Scalars['Boolean']['input']>;
  underline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  underline_not?: InputMaybe<Scalars['String']['input']>;
  underline_not_contains?: InputMaybe<Scalars['String']['input']>;
  underline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_contains?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_not?: InputMaybe<Scalars['String']['input']>;
  url_not_contains?: InputMaybe<Scalars['String']['input']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variant?: InputMaybe<Scalars['String']['input']>;
  variant_contains?: InputMaybe<Scalars['String']['input']>;
  variant_exists?: InputMaybe<Scalars['Boolean']['input']>;
  variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variant_not?: InputMaybe<Scalars['String']['input']>;
  variant_not_contains?: InputMaybe<Scalars['String']['input']>;
  variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LayoutHeaderUserDropdownMenuItemsCollection = {
  __typename?: 'LayoutHeaderUserDropdownMenuItemsCollection';
  items: Array<Maybe<LayoutHeaderUserDropdownMenuItemsItem>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type LayoutHeaderUserDropdownMenuItemsFilter = {
  AND?: InputMaybe<Array<InputMaybe<LayoutHeaderUserDropdownMenuItemsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LayoutHeaderUserDropdownMenuItemsFilter>>>;
  align?: InputMaybe<Scalars['String']['input']>;
  align_contains?: InputMaybe<Scalars['String']['input']>;
  align_exists?: InputMaybe<Scalars['Boolean']['input']>;
  align_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  align_not?: InputMaybe<Scalars['String']['input']>;
  align_not_contains?: InputMaybe<Scalars['String']['input']>;
  align_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ariaLabel?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_contains?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ariaLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ariaLabel_not?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color?: InputMaybe<Scalars['String']['input']>;
  color_contains?: InputMaybe<Scalars['String']['input']>;
  color_exists?: InputMaybe<Scalars['Boolean']['input']>;
  color_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color_not?: InputMaybe<Scalars['String']['input']>;
  color_not_contains?: InputMaybe<Scalars['String']['input']>;
  color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  gaTrackName?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_contains?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  gaTrackName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gaTrackName_not?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_not_contains?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  highlighted?: InputMaybe<Scalars['Boolean']['input']>;
  highlighted_exists?: InputMaybe<Scalars['Boolean']['input']>;
  highlighted_not?: InputMaybe<Scalars['Boolean']['input']>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn_not?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal_not?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mobileAppLeadingLinks_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileMenuToggle?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_contains?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileMenuToggle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  mobileMenuToggle_not?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_not_contains?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noFollow?: InputMaybe<Scalars['Boolean']['input']>;
  noFollow_exists?: InputMaybe<Scalars['Boolean']['input']>;
  noFollow_not?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab_not?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject_exists?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  underline?: InputMaybe<Scalars['String']['input']>;
  underline_contains?: InputMaybe<Scalars['String']['input']>;
  underline_exists?: InputMaybe<Scalars['Boolean']['input']>;
  underline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  underline_not?: InputMaybe<Scalars['String']['input']>;
  underline_not_contains?: InputMaybe<Scalars['String']['input']>;
  underline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_contains?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_not?: InputMaybe<Scalars['String']['input']>;
  url_not_contains?: InputMaybe<Scalars['String']['input']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variant?: InputMaybe<Scalars['String']['input']>;
  variant_contains?: InputMaybe<Scalars['String']['input']>;
  variant_exists?: InputMaybe<Scalars['Boolean']['input']>;
  variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variant_not?: InputMaybe<Scalars['String']['input']>;
  variant_not_contains?: InputMaybe<Scalars['String']['input']>;
  variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LayoutHeaderUserDropdownMenuItemsItem = Link;

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/legalMessaging) */
export type LegalMessaging = Entry &
  _Node & {
    __typename?: 'LegalMessaging';
    _id: Scalars['ID']['output'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    content?: Maybe<LegalMessagingContent>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<LegalMessagingLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/legalMessaging) */
export type LegalMessagingAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/legalMessaging) */
export type LegalMessagingAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/legalMessaging) */
export type LegalMessagingContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/legalMessaging) */
export type LegalMessagingLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/legalMessaging) */
export type LegalMessagingNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/legalMessaging) */
export type LegalMessagingReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/legalMessaging) */
export type LegalMessagingSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type LegalMessagingCollection = {
  __typename?: 'LegalMessagingCollection';
  items: Array<Maybe<LegalMessaging>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type LegalMessagingContent = {
  __typename?: 'LegalMessagingContent';
  json: Scalars['JSON']['output'];
  links: LegalMessagingContentLinks;
};

export type LegalMessagingContentAssets = {
  __typename?: 'LegalMessagingContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type LegalMessagingContentEntries = {
  __typename?: 'LegalMessagingContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type LegalMessagingContentLinks = {
  __typename?: 'LegalMessagingContentLinks';
  assets: LegalMessagingContentAssets;
  entries: LegalMessagingContentEntries;
  resources: LegalMessagingContentResources;
};

export type LegalMessagingContentResources = {
  __typename?: 'LegalMessagingContentResources';
  block: Array<LegalMessagingContentResourcesBlock>;
  hyperlink: Array<LegalMessagingContentResourcesHyperlink>;
  inline: Array<LegalMessagingContentResourcesInline>;
};

export type LegalMessagingContentResourcesBlock = ResourceLink & {
  __typename?: 'LegalMessagingContentResourcesBlock';
  sys: ResourceSys;
};

export type LegalMessagingContentResourcesHyperlink = ResourceLink & {
  __typename?: 'LegalMessagingContentResourcesHyperlink';
  sys: ResourceSys;
};

export type LegalMessagingContentResourcesInline = ResourceLink & {
  __typename?: 'LegalMessagingContentResourcesInline';
  sys: ResourceSys;
};

export type LegalMessagingFilter = {
  AND?: InputMaybe<Array<InputMaybe<LegalMessagingFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LegalMessagingFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  content_contains?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type LegalMessagingLinkingCollections = {
  __typename?: 'LegalMessagingLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type LegalMessagingLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum LegalMessagingOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/link) */
export type Link = Entry &
  _Node & {
    __typename?: 'Link';
    _id: Scalars['ID']['output'];
    align?: Maybe<Scalars['String']['output']>;
    ariaLabel?: Maybe<Scalars['String']['output']>;
    color?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    gaTrackName?: Maybe<Scalars['String']['output']>;
    highlighted?: Maybe<Scalars['Boolean']['output']>;
    image?: Maybe<Asset>;
    isDisplayedWhenLoggedIn?: Maybe<Scalars['Boolean']['output']>;
    isInternal?: Maybe<Scalars['Boolean']['output']>;
    label?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<LinkLinkingCollections>;
    mobileAppLeadingLinks?: Maybe<MobileAppLeadingLinks>;
    mobileMenuToggle?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    noFollow?: Maybe<Scalars['Boolean']['output']>;
    openInNewTab?: Maybe<Scalars['Boolean']['output']>;
    redirectToLegacyProject?: Maybe<Scalars['Boolean']['output']>;
    sys: Sys;
    underline?: Maybe<Scalars['String']['output']>;
    url?: Maybe<Scalars['String']['output']>;
    variant?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/link) */
export type LinkAlignArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/link) */
export type LinkAriaLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/link) */
export type LinkColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/link) */
export type LinkGaTrackNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/link) */
export type LinkHighlightedArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/link) */
export type LinkImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/link) */
export type LinkIsDisplayedWhenLoggedInArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/link) */
export type LinkIsInternalArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/link) */
export type LinkLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/link) */
export type LinkLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/link) */
export type LinkMobileAppLeadingLinksArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<MobileAppLeadingLinksFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/link) */
export type LinkMobileMenuToggleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/link) */
export type LinkNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/link) */
export type LinkNoFollowArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/link) */
export type LinkOpenInNewTabArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/link) */
export type LinkRedirectToLegacyProjectArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/link) */
export type LinkUnderlineArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/link) */
export type LinkUrlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/link) */
export type LinkVariantArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type LinkCollection = {
  __typename?: 'LinkCollection';
  items: Array<Maybe<Link>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type LinkFilter = {
  AND?: InputMaybe<Array<InputMaybe<LinkFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LinkFilter>>>;
  align?: InputMaybe<Scalars['String']['input']>;
  align_contains?: InputMaybe<Scalars['String']['input']>;
  align_exists?: InputMaybe<Scalars['Boolean']['input']>;
  align_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  align_not?: InputMaybe<Scalars['String']['input']>;
  align_not_contains?: InputMaybe<Scalars['String']['input']>;
  align_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ariaLabel?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_contains?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ariaLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ariaLabel_not?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color?: InputMaybe<Scalars['String']['input']>;
  color_contains?: InputMaybe<Scalars['String']['input']>;
  color_exists?: InputMaybe<Scalars['Boolean']['input']>;
  color_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color_not?: InputMaybe<Scalars['String']['input']>;
  color_not_contains?: InputMaybe<Scalars['String']['input']>;
  color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  gaTrackName?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_contains?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  gaTrackName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gaTrackName_not?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_not_contains?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  highlighted?: InputMaybe<Scalars['Boolean']['input']>;
  highlighted_exists?: InputMaybe<Scalars['Boolean']['input']>;
  highlighted_not?: InputMaybe<Scalars['Boolean']['input']>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn_not?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal_not?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mobileAppLeadingLinks?: InputMaybe<CfMobileAppLeadingLinksNestedFilter>;
  mobileAppLeadingLinks_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileMenuToggle?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_contains?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileMenuToggle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  mobileMenuToggle_not?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_not_contains?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noFollow?: InputMaybe<Scalars['Boolean']['input']>;
  noFollow_exists?: InputMaybe<Scalars['Boolean']['input']>;
  noFollow_not?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab_not?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject_exists?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  underline?: InputMaybe<Scalars['String']['input']>;
  underline_contains?: InputMaybe<Scalars['String']['input']>;
  underline_exists?: InputMaybe<Scalars['Boolean']['input']>;
  underline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  underline_not?: InputMaybe<Scalars['String']['input']>;
  underline_not_contains?: InputMaybe<Scalars['String']['input']>;
  underline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_contains?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_not?: InputMaybe<Scalars['String']['input']>;
  url_not_contains?: InputMaybe<Scalars['String']['input']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variant?: InputMaybe<Scalars['String']['input']>;
  variant_contains?: InputMaybe<Scalars['String']['input']>;
  variant_exists?: InputMaybe<Scalars['Boolean']['input']>;
  variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variant_not?: InputMaybe<Scalars['String']['input']>;
  variant_not_contains?: InputMaybe<Scalars['String']['input']>;
  variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LinkLinkingCollections = {
  __typename?: 'LinkLinkingCollections';
  accountDuplicationPageCollection?: Maybe<AccountDuplicationPageCollection>;
  actionResultCollection?: Maybe<ActionResultCollection>;
  appBannerCollection?: Maybe<AppBannerCollection>;
  appReviewCollection?: Maybe<AppReviewCollection>;
  backToSchoolCountryDataCollection?: Maybe<BackToSchoolCountryDataCollection>;
  betterWayTopCollection?: Maybe<BetterWayTopCollection>;
  calculatorAppLeadingLinksCollection?: Maybe<CalculatorAppLeadingLinksCollection>;
  calculatorCollection?: Maybe<CalculatorCollection>;
  cardCollection?: Maybe<CardCollection>;
  contextualPromoBannerCollection?: Maybe<ContextualPromoBannerCollection>;
  countryCollection?: Maybe<CountryCollection>;
  currencyPricingConverterCollection?: Maybe<CurrencyPricingConverterCollection>;
  entryCollection?: Maybe<EntryCollection>;
  faqHeroCollection?: Maybe<FaqHeroCollection>;
  faqModuleCollection?: Maybe<FaqModuleCollection>;
  footerCollection?: Maybe<FooterCollection>;
  footerNavigationGroupCollection?: Maybe<FooterNavigationGroupCollection>;
  fullWidthBannerCollection?: Maybe<FullWidthBannerCollection>;
  heroSlotCollection?: Maybe<HeroSlotCollection>;
  howDoesItWorkCollection?: Maybe<HowDoesItWorkCollection>;
  layoutFooterCollection?: Maybe<LayoutFooterCollection>;
  layoutHeaderCollection?: Maybe<LayoutHeaderCollection>;
  loginPageCollection?: Maybe<LoginPageCollection>;
  logoCollection?: Maybe<LogoCollection>;
  membersListCollection?: Maybe<MembersListCollection>;
  mobileAppLeadingLinksCollection?: Maybe<MobileAppLeadingLinksCollection>;
  mobileStoreDataCollection?: Maybe<MobileStoreDataCollection>;
  moneyRequestPaymentReasonCollection?: Maybe<MoneyRequestPaymentReasonCollection>;
  moneyTransferCardCollection?: Maybe<MoneyTransferCardCollection>;
  notFoundContentCollection?: Maybe<NotFoundContentCollection>;
  notFoundPageCollection?: Maybe<NotFoundPageCollection>;
  onfidoErrorPageCollection?: Maybe<OnfidoErrorPageCollection>;
  pageCollection?: Maybe<PageCollection>;
  payoutMethodCollection?: Maybe<PayoutMethodCollection>;
  priceComparsionCollection?: Maybe<PriceComparsionCollection>;
  promotionalBannerCollection?: Maybe<PromotionalBannerCollection>;
  referFriendHeaderCollection?: Maybe<ReferFriendHeaderCollection>;
  reviewsCollection?: Maybe<ReviewsCollection>;
  sendingPartnershipCollection?: Maybe<SendingPartnershipCollection>;
  signInCollection?: Maybe<SignInCollection>;
  socialProofingModuleCollection?: Maybe<SocialProofingModuleCollection>;
  stepByStepCollection?: Maybe<StepByStepCollection>;
};

export type LinkLinkingCollectionsAccountDuplicationPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<LinkLinkingCollectionsAccountDuplicationPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsActionResultCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsActionResultCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsAppBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsAppBannerCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsAppReviewCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsAppReviewCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsBackToSchoolCountryDataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<LinkLinkingCollectionsBackToSchoolCountryDataCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsBetterWayTopCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsBetterWayTopCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsCalculatorAppLeadingLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<LinkLinkingCollectionsCalculatorAppLeadingLinksCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsCalculatorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsCalculatorCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsCardCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsContextualPromoBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<LinkLinkingCollectionsContextualPromoBannerCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsCountryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsCountryCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsCurrencyPricingConverterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<LinkLinkingCollectionsCurrencyPricingConverterCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsFaqHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsFaqHeroCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsFaqModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsFaqModuleCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsFooterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsFooterCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsFooterNavigationGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<LinkLinkingCollectionsFooterNavigationGroupCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsFullWidthBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsFullWidthBannerCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsHeroSlotCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsHeroSlotCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsHowDoesItWorkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsHowDoesItWorkCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsLayoutFooterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsLayoutFooterCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsLayoutHeaderCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsLayoutHeaderCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsLoginPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsLoginPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsLogoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsLogoCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsMembersListCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsMembersListCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsMobileAppLeadingLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<LinkLinkingCollectionsMobileAppLeadingLinksCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsMobileStoreDataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsMobileStoreDataCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsMoneyRequestPaymentReasonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<LinkLinkingCollectionsMoneyRequestPaymentReasonCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsMoneyTransferCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsMoneyTransferCardCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsNotFoundContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsNotFoundContentCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsNotFoundPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsNotFoundPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsOnfidoErrorPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsOnfidoErrorPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsPayoutMethodCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsPayoutMethodCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsPriceComparsionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsPriceComparsionCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsPromotionalBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsPromotionalBannerCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsReferFriendHeaderCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsReferFriendHeaderCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsReviewsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsSendingPartnershipCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsSendingPartnershipCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsSignInCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsSignInCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsSocialProofingModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsSocialProofingModuleCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsStepByStepCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsStepByStepCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum LinkLinkingCollectionsAccountDuplicationPageCollectionOrder {
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsActionResultCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum LinkLinkingCollectionsAppBannerCollectionOrder {
  BannerDescriptionAsc = 'bannerDescription_ASC',
  BannerDescriptionDesc = 'bannerDescription_DESC',
  BannerTitleAsc = 'bannerTitle_ASC',
  BannerTitleDesc = 'bannerTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsAppReviewCollectionOrder {
  ConnectedComponentAsc = 'connectedComponent_ASC',
  ConnectedComponentDesc = 'connectedComponent_DESC',
  IsCentredAsc = 'isCentred_ASC',
  IsCentredDesc = 'isCentred_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  StarsAsc = 'stars_ASC',
  StarsDesc = 'stars_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsBackToSchoolCountryDataCollectionOrder {
  ChildrenRatioAsc = 'childrenRatio_ASC',
  ChildrenRatioDesc = 'childrenRatio_DESC',
  CircleRatioAsc = 'circleRatio_ASC',
  CircleRatioDesc = 'circleRatio_DESC',
  CountryAsc = 'country_ASC',
  CountryDesc = 'country_DESC',
  CurrencySymbolAsc = 'currencySymbol_ASC',
  CurrencySymbolDesc = 'currencySymbol_DESC',
  CurrencyAsc = 'currency_ASC',
  CurrencyDesc = 'currency_DESC',
  ExchangeRateAsc = 'exchangeRate_ASC',
  ExchangeRateDesc = 'exchangeRate_DESC',
  FlagXPositionAsc = 'flagXPosition_ASC',
  FlagXPositionDesc = 'flagXPosition_DESC',
  FlagYPositionAsc = 'flagYPosition_ASC',
  FlagYPositionDesc = 'flagYPosition_DESC',
  HouseholdIncomeAsc = 'householdIncome_ASC',
  HouseholdIncomeDesc = 'householdIncome_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TotalChildCostAsc = 'totalChildCost_ASC',
  TotalChildCostDesc = 'totalChildCost_DESC',
  TotalHouseholdCostAsc = 'totalHouseholdCost_ASC',
  TotalHouseholdCostDesc = 'totalHouseholdCost_DESC',
}

export enum LinkLinkingCollectionsBetterWayTopCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentAlignmentAsc = 'contentAlignment_ASC',
  ContentAlignmentDesc = 'contentAlignment_DESC',
  ContentFlexDirectionAsc = 'contentFlexDirection_ASC',
  ContentFlexDirectionDesc = 'contentFlexDirection_DESC',
  HeadingColorAsc = 'headingColor_ASC',
  HeadingColorDesc = 'headingColor_DESC',
  HideDescriptionOnMobileAsc = 'hideDescriptionOnMobile_ASC',
  HideDescriptionOnMobileDesc = 'hideDescriptionOnMobile_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrustpilotMessageAsc = 'trustpilotMessage_ASC',
  TrustpilotMessageDesc = 'trustpilotMessage_DESC',
}

export enum LinkLinkingCollectionsCalculatorAppLeadingLinksCollectionOrder {
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsCalculatorCollectionOrder {
  BlendedRateLabelAsc = 'blendedRateLabel_ASC',
  BlendedRateLabelDesc = 'blendedRateLabel_DESC',
  CountriesSearchPlaceholderAsc = 'countriesSearchPlaceholder_ASC',
  CountriesSearchPlaceholderDesc = 'countriesSearchPlaceholder_DESC',
  EstimatedFeeLabelAsc = 'estimatedFeeLabel_ASC',
  EstimatedFeeLabelDesc = 'estimatedFeeLabel_DESC',
  ExchangeRateLabelAsc = 'exchangeRateLabel_ASC',
  ExchangeRateLabelDesc = 'exchangeRateLabel_DESC',
  ExchangeRatePromoLabelAsc = 'exchangeRatePromoLabel_ASC',
  ExchangeRatePromoLabelDesc = 'exchangeRatePromoLabel_DESC',
  FeeLabelAsc = 'feeLabel_ASC',
  FeeLabelDesc = 'feeLabel_DESC',
  IncorrectCountrySelectedErrorMessageAsc = 'incorrectCountrySelectedErrorMessage_ASC',
  IncorrectCountrySelectedErrorMessageDesc = 'incorrectCountrySelectedErrorMessage_DESC',
  InstantDiscountLabelAsc = 'instantDiscountLabel_ASC',
  InstantDiscountLabelDesc = 'instantDiscountLabel_DESC',
  IsLiteAsc = 'isLite_ASC',
  IsLiteDesc = 'isLite_DESC',
  MaxDiscountLabelAsc = 'maxDiscountLabel_ASC',
  MaxDiscountLabelDesc = 'maxDiscountLabel_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PartnerSelectErrorMessageAsc = 'partnerSelectErrorMessage_ASC',
  PartnerSelectErrorMessageDesc = 'partnerSelectErrorMessage_DESC',
  PartnersLabelAsc = 'partnersLabel_ASC',
  PartnersLabelDesc = 'partnersLabel_DESC',
  PartnersListDefaultValueAsc = 'partnersListDefaultValue_ASC',
  PartnersListDefaultValueDesc = 'partnersListDefaultValue_DESC',
  PayoutMethodsLabelAsc = 'payoutMethodsLabel_ASC',
  PayoutMethodsLabelDesc = 'payoutMethodsLabel_DESC',
  PhReceiveBankTransferMessageAsc = 'phReceiveBankTransferMessage_ASC',
  PhReceiveBankTransferMessageDesc = 'phReceiveBankTransferMessage_DESC',
  PhoneInputInfoMoreInfoLabelAsc = 'phoneInputInfoMoreInfoLabel_ASC',
  PhoneInputInfoMoreInfoLabelDesc = 'phoneInputInfoMoreInfoLabel_DESC',
  PhoneInputInfoPopupTextAsc = 'phoneInputInfoPopupText_ASC',
  PhoneInputInfoPopupTextDesc = 'phoneInputInfoPopupText_DESC',
  PhoneNumberInputErrorMessageAsc = 'phoneNumberInputErrorMessage_ASC',
  PhoneNumberInputErrorMessageDesc = 'phoneNumberInputErrorMessage_DESC',
  PhoneNumberInvalidErrorMessageAsc = 'phoneNumberInvalidErrorMessage_ASC',
  PhoneNumberInvalidErrorMessageDesc = 'phoneNumberInvalidErrorMessage_DESC',
  PhoneValidatorLabelAsc = 'phoneValidatorLabel_ASC',
  PhoneValidatorLabelDesc = 'phoneValidatorLabel_DESC',
  PhoneValidatorPlaceholderAsc = 'phoneValidatorPlaceholder_ASC',
  PhoneValidatorPlaceholderDesc = 'phoneValidatorPlaceholder_DESC',
  ReceiveLabelAsc = 'receiveLabel_ASC',
  ReceiveLabelDesc = 'receiveLabel_DESC',
  RecipientGetsLabelAsc = 'recipientGetsLabel_ASC',
  RecipientGetsLabelDesc = 'recipientGetsLabel_DESC',
  SendLabelAsc = 'sendLabel_ASC',
  SendLabelDesc = 'sendLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TopUpAmountsLabelAsc = 'topUpAmountsLabel_ASC',
  TopUpAmountsLabelDesc = 'topUpAmountsLabel_DESC',
  TopUpAmountsPlaceholderAsc = 'topUpAmountsPlaceholder_ASC',
  TopUpAmountsPlaceholderDesc = 'topUpAmountsPlaceholder_DESC',
  TopUpAmountsSelectErrorMessageAsc = 'topUpAmountsSelectErrorMessage_ASC',
  TopUpAmountsSelectErrorMessageDesc = 'topUpAmountsSelectErrorMessage_DESC',
  TopUpAmountsSelectRetrievalErrorMessageAsc = 'topUpAmountsSelectRetrievalErrorMessage_ASC',
  TopUpAmountsSelectRetrievalErrorMessageDesc = 'topUpAmountsSelectRetrievalErrorMessage_DESC',
  TopUpAmountsTitleAsc = 'topUpAmountsTitle_ASC',
  TopUpAmountsTitleDesc = 'topUpAmountsTitle_DESC',
  TotalToPayLabelAsc = 'totalToPayLabel_ASC',
  TotalToPayLabelDesc = 'totalToPayLabel_DESC',
  TransferTimeLabelAsc = 'transferTimeLabel_ASC',
  TransferTimeLabelDesc = 'transferTimeLabel_DESC',
  UnavailableCorridorErrorMessageAsc = 'unavailableCorridorErrorMessage_ASC',
  UnavailableCorridorErrorMessageDesc = 'unavailableCorridorErrorMessage_DESC',
  VersionAsc = 'version_ASC',
  VersionDesc = 'version_DESC',
}

export enum LinkLinkingCollectionsCardCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PayoutMethodIdAsc = 'payoutMethodId_ASC',
  PayoutMethodIdDesc = 'payoutMethodId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsContextualPromoBannerCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  DisclaimerAsc = 'disclaimer_ASC',
  DisclaimerDesc = 'disclaimer_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsCountryCollectionOrder {
  ArticleAsc = 'article_ASC',
  ArticleDesc = 'article_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrepositionFromAsc = 'prepositionFrom_ASC',
  PrepositionFromDesc = 'prepositionFrom_DESC',
  PrepositionInAsc = 'prepositionIn_ASC',
  PrepositionInDesc = 'prepositionIn_DESC',
  PrepositionToAsc = 'prepositionTo_ASC',
  PrepositionToDesc = 'prepositionTo_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsCurrencyPricingConverterCollectionOrder {
  ExchangeRateDescriptionAsc = 'exchangeRateDescription_ASC',
  ExchangeRateDescriptionDesc = 'exchangeRateDescription_DESC',
  ExchangeRateTitleAsc = 'exchangeRateTitle_ASC',
  ExchangeRateTitleDesc = 'exchangeRateTitle_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ReceiveCountryLabelAsc = 'receiveCountryLabel_ASC',
  ReceiveCountryLabelDesc = 'receiveCountryLabel_DESC',
  SendCountryLabelAsc = 'sendCountryLabel_ASC',
  SendCountryLabelDesc = 'sendCountryLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsFaqHeroCollectionOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  MenuTitleAsc = 'menuTitle_ASC',
  MenuTitleDesc = 'menuTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrimaryBackgroundColorAsc = 'primaryBackgroundColor_ASC',
  PrimaryBackgroundColorDesc = 'primaryBackgroundColor_DESC',
  SecondaryBackgroundColorAsc = 'secondaryBackgroundColor_ASC',
  SecondaryBackgroundColorDesc = 'secondaryBackgroundColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsFaqModuleCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubheadingAsc = 'subheading_ASC',
  SubheadingDesc = 'subheading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum LinkLinkingCollectionsFooterCollectionOrder {
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsFooterNavigationGroupCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum LinkLinkingCollectionsFullWidthBannerCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BorderColorAsc = 'borderColor_ASC',
  BorderColorDesc = 'borderColor_DESC',
  ContentWidthAsc = 'contentWidth_ASC',
  ContentWidthDesc = 'contentWidth_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  SecondBorderColorAsc = 'secondBorderColor_ASC',
  SecondBorderColorDesc = 'secondBorderColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAlignAsc = 'textAlign_ASC',
  TextAlignDesc = 'textAlign_DESC',
  TextColorAsc = 'textColor_ASC',
  TextColorDesc = 'textColor_DESC',
  TextVerticalAlignAsc = 'textVerticalAlign_ASC',
  TextVerticalAlignDesc = 'textVerticalAlign_DESC',
}

export enum LinkLinkingCollectionsHeroSlotCollectionOrder {
  ButtonTitleAsc = 'buttonTitle_ASC',
  ButtonTitleDesc = 'buttonTitle_DESC',
  DividerLabelAsc = 'dividerLabel_ASC',
  DividerLabelDesc = 'dividerLabel_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  LoginInLinkTitleAsc = 'loginInLinkTitle_ASC',
  LoginInLinkTitleDesc = 'loginInLinkTitle_DESC',
  LoginInTextAsc = 'loginInText_ASC',
  LoginInTextDesc = 'loginInText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  QrCodeLabelAsc = 'qrCodeLabel_ASC',
  QrCodeLabelDesc = 'qrCodeLabel_DESC',
  SubheaderAsc = 'subheader_ASC',
  SubheaderDesc = 'subheader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsHowDoesItWorkCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum LinkLinkingCollectionsLayoutFooterCollectionOrder {
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsLayoutHeaderCollectionOrder {
  LanguageSwitcherOptionAsc = 'languageSwitcherOption_ASC',
  LanguageSwitcherOptionDesc = 'languageSwitcherOption_DESC',
  LastLoginAsc = 'lastLogin_ASC',
  LastLoginDesc = 'lastLogin_DESC',
  MenuLabelAsc = 'menuLabel_ASC',
  MenuLabelDesc = 'menuLabel_DESC',
  MyAccountAsc = 'myAccount_ASC',
  MyAccountDesc = 'myAccount_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SendFromAsc = 'sendFrom_ASC',
  SendFromDesc = 'sendFrom_DESC',
  SkipToMainContentAsc = 'skipToMainContent_ASC',
  SkipToMainContentDesc = 'skipToMainContent_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  WelcomeAsc = 'welcome_ASC',
  WelcomeDesc = 'welcome_DESC',
}

export enum LinkLinkingCollectionsLoginPageCollectionOrder {
  AuthErrorMessageAsc = 'authErrorMessage_ASC',
  AuthErrorMessageDesc = 'authErrorMessage_DESC',
  ContinueButtonLabelAsc = 'continueButtonLabel_ASC',
  ContinueButtonLabelDesc = 'continueButtonLabel_DESC',
  DownloadMobileAppLabelAsc = 'downloadMobileAppLabel_ASC',
  DownloadMobileAppLabelDesc = 'downloadMobileAppLabel_DESC',
  EmailHelperTextAsc = 'emailHelperText_ASC',
  EmailHelperTextDesc = 'emailHelperText_DESC',
  EmailInputLabelAsc = 'emailInputLabel_ASC',
  EmailInputLabelDesc = 'emailInputLabel_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PasswordHelperTextAsc = 'passwordHelperText_ASC',
  PasswordHelperTextDesc = 'passwordHelperText_DESC',
  PasswordInputLabelAsc = 'passwordInputLabel_ASC',
  PasswordInputLabelDesc = 'passwordInputLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsLogoCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsMembersListCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsMobileAppLeadingLinksCollectionOrder {
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsMobileStoreDataCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RatingAsc = 'rating_ASC',
  RatingDesc = 'rating_DESC',
  RatingsAmountAsc = 'ratingsAmount_ASC',
  RatingsAmountDesc = 'ratingsAmount_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsMoneyRequestPaymentReasonCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum LinkLinkingCollectionsMoneyTransferCardCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PayoutMethodIdAsc = 'payoutMethodId_ASC',
  PayoutMethodIdDesc = 'payoutMethodId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsNotFoundContentCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsNotFoundPageCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  MetaDescriptionAsc = 'metaDescription_ASC',
  MetaDescriptionDesc = 'metaDescription_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum LinkLinkingCollectionsOnfidoErrorPageCollectionOrder {
  ContactInfoAsc = 'contactInfo_ASC',
  ContactInfoDesc = 'contactInfo_DESC',
  HardSubheaderAsc = 'hardSubheader_ASC',
  HardSubheaderDesc = 'hardSubheader_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  SettingsAsc = 'settings_ASC',
  SettingsDesc = 'settings_DESC',
  SoftSubheaderAsc = 'softSubheader_ASC',
  SoftSubheaderDesc = 'softSubheader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsPageCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderPositionAsc = 'headerPosition_ASC',
  HeaderPositionDesc = 'headerPosition_DESC',
  HeaderThemeAsc = 'headerTheme_ASC',
  HeaderThemeDesc = 'headerTheme_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimeToReadAsc = 'timeToRead_ASC',
  TimeToReadDesc = 'timeToRead_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export enum LinkLinkingCollectionsPayoutMethodCollectionOrder {
  CorrespondentsTitleAsc = 'correspondentsTitle_ASC',
  CorrespondentsTitleDesc = 'correspondentsTitle_DESC',
  CtaButtonBaseUrlAsc = 'ctaButtonBaseUrl_ASC',
  CtaButtonBaseUrlDesc = 'ctaButtonBaseUrl_DESC',
  CtaButtonLabelAsc = 'ctaButtonLabel_ASC',
  CtaButtonLabelDesc = 'ctaButtonLabel_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ExchangeRateLabelAsc = 'exchangeRateLabel_ASC',
  ExchangeRateLabelDesc = 'exchangeRateLabel_DESC',
  FeesLabelAsc = 'feesLabel_ASC',
  FeesLabelDesc = 'feesLabel_DESC',
  HideExchangeRateAsc = 'hideExchangeRate_ASC',
  HideExchangeRateDesc = 'hideExchangeRate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PayoutMethodIdAsc = 'payoutMethodId_ASC',
  PayoutMethodIdDesc = 'payoutMethodId_DESC',
  ReceiveMethodDescriptionAsc = 'receiveMethodDescription_ASC',
  ReceiveMethodDescriptionDesc = 'receiveMethodDescription_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SupportLinkTextAsc = 'supportLinkText_ASC',
  SupportLinkTextDesc = 'supportLinkText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TransferTimeLabelAsc = 'transferTimeLabel_ASC',
  TransferTimeLabelDesc = 'transferTimeLabel_DESC',
  TransferTimeTooltipAsc = 'transferTimeTooltip_ASC',
  TransferTimeTooltipDesc = 'transferTimeTooltip_DESC',
}

export enum LinkLinkingCollectionsPriceComparsionCollectionOrder {
  BackgroundColor1Asc = 'backgroundColor1_ASC',
  BackgroundColor1Desc = 'backgroundColor1_DESC',
  BackgroundColor2Asc = 'backgroundColor2_ASC',
  BackgroundColor2Desc = 'backgroundColor2_DESC',
  ButtonColorAsc = 'buttonColor_ASC',
  ButtonColorDesc = 'buttonColor_DESC',
  ButtonHoverColorAsc = 'buttonHoverColor_ASC',
  ButtonHoverColorDesc = 'buttonHoverColor_DESC',
  DescriptionColorAsc = 'descriptionColor_ASC',
  DescriptionColorDesc = 'descriptionColor_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsPromotionalBannerCollectionOrder {
  AddToInitialHtmlAsc = 'addToInitialHtml_ASC',
  AddToInitialHtmlDesc = 'addToInitialHtml_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsReferFriendHeaderCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BorderColorAsc = 'borderColor_ASC',
  BorderColorDesc = 'borderColor_DESC',
  ContentWidthAsc = 'contentWidth_ASC',
  ContentWidthDesc = 'contentWidth_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  HintTextAsc = 'hintText_ASC',
  HintTextDesc = 'hintText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  SecondBorderColorAsc = 'secondBorderColor_ASC',
  SecondBorderColorDesc = 'secondBorderColor_DESC',
  SubheadingAsc = 'subheading_ASC',
  SubheadingDesc = 'subheading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAlignAsc = 'textAlign_ASC',
  TextAlignDesc = 'textAlign_DESC',
  TextColorAsc = 'textColor_ASC',
  TextColorDesc = 'textColor_DESC',
  TextVerticalAlignAsc = 'textVerticalAlign_ASC',
  TextVerticalAlignDesc = 'textVerticalAlign_DESC',
}

export enum LinkLinkingCollectionsReviewsCollectionOrder {
  CarouselTextAsc = 'carouselText_ASC',
  CarouselTextDesc = 'carouselText_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  HideTrustpilotHeaderAsc = 'hideTrustpilotHeader_ASC',
  HideTrustpilotHeaderDesc = 'hideTrustpilotHeader_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ReviewsNumberLabelAsc = 'reviewsNumberLabel_ASC',
  ReviewsNumberLabelDesc = 'reviewsNumberLabel_DESC',
  ReviewsTotalScoreAsc = 'reviewsTotalScore_ASC',
  ReviewsTotalScoreDesc = 'reviewsTotalScore_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsSendingPartnershipCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsSignInCollectionOrder {
  LoginDescriptionAsc = 'loginDescription_ASC',
  LoginDescriptionDesc = 'loginDescription_DESC',
  LoginTitleAsc = 'loginTitle_ASC',
  LoginTitleDesc = 'loginTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SignUpDescriptionAsc = 'signUpDescription_ASC',
  SignUpDescriptionDesc = 'signUpDescription_DESC',
  SignUpTitleAsc = 'signUpTitle_ASC',
  SignUpTitleDesc = 'signUpTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum LinkLinkingCollectionsSocialProofingModuleCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum LinkLinkingCollectionsStepByStepCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkOrder {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  GaTrackNameAsc = 'gaTrackName_ASC',
  GaTrackNameDesc = 'gaTrackName_DESC',
  HighlightedAsc = 'highlighted_ASC',
  HighlightedDesc = 'highlighted_DESC',
  IsDisplayedWhenLoggedInAsc = 'isDisplayedWhenLoggedIn_ASC',
  IsDisplayedWhenLoggedInDesc = 'isDisplayedWhenLoggedIn_DESC',
  IsInternalAsc = 'isInternal_ASC',
  IsInternalDesc = 'isInternal_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  MobileMenuToggleAsc = 'mobileMenuToggle_ASC',
  MobileMenuToggleDesc = 'mobileMenuToggle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoFollowAsc = 'noFollow_ASC',
  NoFollowDesc = 'noFollow_DESC',
  OpenInNewTabAsc = 'openInNewTab_ASC',
  OpenInNewTabDesc = 'openInNewTab_DESC',
  RedirectToLegacyProjectAsc = 'redirectToLegacyProject_ASC',
  RedirectToLegacyProjectDesc = 'redirectToLegacyProject_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnderlineAsc = 'underline_ASC',
  UnderlineDesc = 'underline_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/listOfCountries) */
export type ListOfCountries = Entry &
  _Node & {
    __typename?: 'ListOfCountries';
    _id: Scalars['ID']['output'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    buttonLabelExpandedList?: Maybe<Scalars['String']['output']>;
    buttonLabelHiddenList?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    countriesCollection?: Maybe<ListOfCountriesCountriesCollection>;
    expandListText?: Maybe<Scalars['String']['output']>;
    header?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<ListOfCountriesLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    subheader?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    topCountries?: Maybe<PopularCountries>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/listOfCountries) */
export type ListOfCountriesAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/listOfCountries) */
export type ListOfCountriesAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/listOfCountries) */
export type ListOfCountriesButtonLabelExpandedListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/listOfCountries) */
export type ListOfCountriesButtonLabelHiddenListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/listOfCountries) */
export type ListOfCountriesCountriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<ListOfCountriesCountriesCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CountryFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/listOfCountries) */
export type ListOfCountriesExpandListTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/listOfCountries) */
export type ListOfCountriesHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/listOfCountries) */
export type ListOfCountriesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/listOfCountries) */
export type ListOfCountriesNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/listOfCountries) */
export type ListOfCountriesReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/listOfCountries) */
export type ListOfCountriesSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/listOfCountries) */
export type ListOfCountriesSubheaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/listOfCountries) */
export type ListOfCountriesTopCountriesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PopularCountriesFilter>;
};

export type ListOfCountriesCollection = {
  __typename?: 'ListOfCountriesCollection';
  items: Array<Maybe<ListOfCountries>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ListOfCountriesCountriesCollection = {
  __typename?: 'ListOfCountriesCountriesCollection';
  items: Array<Maybe<Country>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ListOfCountriesCountriesCollectionOrder {
  ArticleAsc = 'article_ASC',
  ArticleDesc = 'article_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrepositionFromAsc = 'prepositionFrom_ASC',
  PrepositionFromDesc = 'prepositionFrom_DESC',
  PrepositionInAsc = 'prepositionIn_ASC',
  PrepositionInDesc = 'prepositionIn_DESC',
  PrepositionToAsc = 'prepositionTo_ASC',
  PrepositionToDesc = 'prepositionTo_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ListOfCountriesFilter = {
  AND?: InputMaybe<Array<InputMaybe<ListOfCountriesFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ListOfCountriesFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  buttonLabelExpandedList?: InputMaybe<Scalars['String']['input']>;
  buttonLabelExpandedList_contains?: InputMaybe<Scalars['String']['input']>;
  buttonLabelExpandedList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  buttonLabelExpandedList_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buttonLabelExpandedList_not?: InputMaybe<Scalars['String']['input']>;
  buttonLabelExpandedList_not_contains?: InputMaybe<Scalars['String']['input']>;
  buttonLabelExpandedList_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buttonLabelHiddenList?: InputMaybe<Scalars['String']['input']>;
  buttonLabelHiddenList_contains?: InputMaybe<Scalars['String']['input']>;
  buttonLabelHiddenList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  buttonLabelHiddenList_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buttonLabelHiddenList_not?: InputMaybe<Scalars['String']['input']>;
  buttonLabelHiddenList_not_contains?: InputMaybe<Scalars['String']['input']>;
  buttonLabelHiddenList_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  countries?: InputMaybe<CfCountryNestedFilter>;
  countriesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  expandListText?: InputMaybe<Scalars['String']['input']>;
  expandListText_contains?: InputMaybe<Scalars['String']['input']>;
  expandListText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  expandListText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  expandListText_not?: InputMaybe<Scalars['String']['input']>;
  expandListText_not_contains?: InputMaybe<Scalars['String']['input']>;
  expandListText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subheader?: InputMaybe<Scalars['String']['input']>;
  subheader_contains?: InputMaybe<Scalars['String']['input']>;
  subheader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subheader_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subheader_not?: InputMaybe<Scalars['String']['input']>;
  subheader_not_contains?: InputMaybe<Scalars['String']['input']>;
  subheader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  topCountries?: InputMaybe<CfPopularCountriesNestedFilter>;
  topCountries_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ListOfCountriesLinkingCollections = {
  __typename?: 'ListOfCountriesLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type ListOfCountriesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ListOfCountriesOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  ButtonLabelExpandedListAsc = 'buttonLabelExpandedList_ASC',
  ButtonLabelExpandedListDesc = 'buttonLabelExpandedList_DESC',
  ButtonLabelHiddenListAsc = 'buttonLabelHiddenList_ASC',
  ButtonLabelHiddenListDesc = 'buttonLabelHiddenList_DESC',
  ExpandListTextAsc = 'expandListText_ASC',
  ExpandListTextDesc = 'expandListText_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubheaderAsc = 'subheader_ASC',
  SubheaderDesc = 'subheader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [web-processes] Login page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/loginPage) */
export type LoginPage = Entry &
  _Node & {
    __typename?: 'LoginPage';
    _id: Scalars['ID']['output'];
    appStoreIconLink?: Maybe<Link>;
    authErrorMessage?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    continueButtonLabel?: Maybe<Scalars['String']['output']>;
    downloadMobileAppLabel?: Maybe<Scalars['String']['output']>;
    emailHelperText?: Maybe<Scalars['String']['output']>;
    emailInputLabel?: Maybe<Scalars['String']['output']>;
    forgottenPasswordPageLink?: Maybe<Link>;
    header?: Maybe<Scalars['String']['output']>;
    imageCollection?: Maybe<AssetCollection>;
    linkedFrom?: Maybe<LoginPageLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    passwordHelperText?: Maybe<Scalars['String']['output']>;
    passwordInputLabel?: Maybe<Scalars['String']['output']>;
    playStoreIconLink?: Maybe<Link>;
    signUpPageLink?: Maybe<Link>;
    ssoWarning?: Maybe<LoginPageSsoWarning>;
    sys: Sys;
  };

/** [web-processes] Login page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/loginPage) */
export type LoginPageAppStoreIconLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-processes] Login page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/loginPage) */
export type LoginPageAuthErrorMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Login page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/loginPage) */
export type LoginPageContinueButtonLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Login page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/loginPage) */
export type LoginPageDownloadMobileAppLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Login page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/loginPage) */
export type LoginPageEmailHelperTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Login page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/loginPage) */
export type LoginPageEmailInputLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Login page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/loginPage) */
export type LoginPageForgottenPasswordPageLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-processes] Login page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/loginPage) */
export type LoginPageHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Login page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/loginPage) */
export type LoginPageImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** [web-processes] Login page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/loginPage) */
export type LoginPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-processes] Login page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/loginPage) */
export type LoginPageNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Login page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/loginPage) */
export type LoginPagePasswordHelperTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Login page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/loginPage) */
export type LoginPagePasswordInputLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Login page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/loginPage) */
export type LoginPagePlayStoreIconLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-processes] Login page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/loginPage) */
export type LoginPageSignUpPageLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-processes] Login page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/loginPage) */
export type LoginPageSsoWarningArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type LoginPageCollection = {
  __typename?: 'LoginPageCollection';
  items: Array<Maybe<LoginPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type LoginPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<LoginPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LoginPageFilter>>>;
  appStoreIconLink?: InputMaybe<CfLinkNestedFilter>;
  appStoreIconLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  authErrorMessage?: InputMaybe<Scalars['String']['input']>;
  authErrorMessage_contains?: InputMaybe<Scalars['String']['input']>;
  authErrorMessage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  authErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  authErrorMessage_not?: InputMaybe<Scalars['String']['input']>;
  authErrorMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  authErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  continueButtonLabel?: InputMaybe<Scalars['String']['input']>;
  continueButtonLabel_contains?: InputMaybe<Scalars['String']['input']>;
  continueButtonLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  continueButtonLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  continueButtonLabel_not?: InputMaybe<Scalars['String']['input']>;
  continueButtonLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  continueButtonLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  downloadMobileAppLabel?: InputMaybe<Scalars['String']['input']>;
  downloadMobileAppLabel_contains?: InputMaybe<Scalars['String']['input']>;
  downloadMobileAppLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  downloadMobileAppLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  downloadMobileAppLabel_not?: InputMaybe<Scalars['String']['input']>;
  downloadMobileAppLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  downloadMobileAppLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  emailHelperText?: InputMaybe<Scalars['String']['input']>;
  emailHelperText_contains?: InputMaybe<Scalars['String']['input']>;
  emailHelperText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  emailHelperText_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  emailHelperText_not?: InputMaybe<Scalars['String']['input']>;
  emailHelperText_not_contains?: InputMaybe<Scalars['String']['input']>;
  emailHelperText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  emailInputLabel?: InputMaybe<Scalars['String']['input']>;
  emailInputLabel_contains?: InputMaybe<Scalars['String']['input']>;
  emailInputLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  emailInputLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  emailInputLabel_not?: InputMaybe<Scalars['String']['input']>;
  emailInputLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  emailInputLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  forgottenPasswordPageLink?: InputMaybe<CfLinkNestedFilter>;
  forgottenPasswordPageLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  imageCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  passwordHelperText?: InputMaybe<Scalars['String']['input']>;
  passwordHelperText_contains?: InputMaybe<Scalars['String']['input']>;
  passwordHelperText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  passwordHelperText_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  passwordHelperText_not?: InputMaybe<Scalars['String']['input']>;
  passwordHelperText_not_contains?: InputMaybe<Scalars['String']['input']>;
  passwordHelperText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  passwordInputLabel?: InputMaybe<Scalars['String']['input']>;
  passwordInputLabel_contains?: InputMaybe<Scalars['String']['input']>;
  passwordInputLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  passwordInputLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  passwordInputLabel_not?: InputMaybe<Scalars['String']['input']>;
  passwordInputLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  passwordInputLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  playStoreIconLink?: InputMaybe<CfLinkNestedFilter>;
  playStoreIconLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  signUpPageLink?: InputMaybe<CfLinkNestedFilter>;
  signUpPageLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ssoWarning_contains?: InputMaybe<Scalars['String']['input']>;
  ssoWarning_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ssoWarning_not_contains?: InputMaybe<Scalars['String']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type LoginPageLinkingCollections = {
  __typename?: 'LoginPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type LoginPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum LoginPageOrder {
  AuthErrorMessageAsc = 'authErrorMessage_ASC',
  AuthErrorMessageDesc = 'authErrorMessage_DESC',
  ContinueButtonLabelAsc = 'continueButtonLabel_ASC',
  ContinueButtonLabelDesc = 'continueButtonLabel_DESC',
  DownloadMobileAppLabelAsc = 'downloadMobileAppLabel_ASC',
  DownloadMobileAppLabelDesc = 'downloadMobileAppLabel_DESC',
  EmailHelperTextAsc = 'emailHelperText_ASC',
  EmailHelperTextDesc = 'emailHelperText_DESC',
  EmailInputLabelAsc = 'emailInputLabel_ASC',
  EmailInputLabelDesc = 'emailInputLabel_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PasswordHelperTextAsc = 'passwordHelperText_ASC',
  PasswordHelperTextDesc = 'passwordHelperText_DESC',
  PasswordInputLabelAsc = 'passwordInputLabel_ASC',
  PasswordInputLabelDesc = 'passwordInputLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type LoginPageSsoWarning = {
  __typename?: 'LoginPageSsoWarning';
  json: Scalars['JSON']['output'];
  links: LoginPageSsoWarningLinks;
};

export type LoginPageSsoWarningAssets = {
  __typename?: 'LoginPageSsoWarningAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type LoginPageSsoWarningEntries = {
  __typename?: 'LoginPageSsoWarningEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type LoginPageSsoWarningLinks = {
  __typename?: 'LoginPageSsoWarningLinks';
  assets: LoginPageSsoWarningAssets;
  entries: LoginPageSsoWarningEntries;
  resources: LoginPageSsoWarningResources;
};

export type LoginPageSsoWarningResources = {
  __typename?: 'LoginPageSsoWarningResources';
  block: Array<LoginPageSsoWarningResourcesBlock>;
  hyperlink: Array<LoginPageSsoWarningResourcesHyperlink>;
  inline: Array<LoginPageSsoWarningResourcesInline>;
};

export type LoginPageSsoWarningResourcesBlock = ResourceLink & {
  __typename?: 'LoginPageSsoWarningResourcesBlock';
  sys: ResourceSys;
};

export type LoginPageSsoWarningResourcesHyperlink = ResourceLink & {
  __typename?: 'LoginPageSsoWarningResourcesHyperlink';
  sys: ResourceSys;
};

export type LoginPageSsoWarningResourcesInline = ResourceLink & {
  __typename?: 'LoginPageSsoWarningResourcesInline';
  sys: ResourceSys;
};

/** [web-cms]: Logos with id and link to open page. (ex: correspondent logos) [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/logo) */
export type Logo = Entry &
  _Node & {
    __typename?: 'Logo';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    id?: Maybe<Scalars['String']['output']>;
    link?: Maybe<LogoLink>;
    linkedFrom?: Maybe<LogoLinkingCollections>;
    logo?: Maybe<Asset>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** [web-cms]: Logos with id and link to open page. (ex: correspondent logos) [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/logo) */
export type LogoIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms]: Logos with id and link to open page. (ex: correspondent logos) [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/logo) */
export type LogoLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [web-cms]: Logos with id and link to open page. (ex: correspondent logos) [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/logo) */
export type LogoLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-cms]: Logos with id and link to open page. (ex: correspondent logos) [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/logo) */
export type LogoLogoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [web-cms]: Logos with id and link to open page. (ex: correspondent logos) [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/logo) */
export type LogoNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type LogoCollection = {
  __typename?: 'LogoCollection';
  items: Array<Maybe<Logo>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type LogoFilter = {
  AND?: InputMaybe<Array<InputMaybe<LogoFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LogoFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  logo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type LogoLink = Link | Page;

export type LogoLinkingCollections = {
  __typename?: 'LogoLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type LogoLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum LogoOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/member) */
export type Member = Entry &
  _Node & {
    __typename?: 'Member';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<MemberDescription>;
    fullName?: Maybe<Scalars['String']['output']>;
    image?: Maybe<Asset>;
    linkedFrom?: Maybe<MemberLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/member) */
export type MemberDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/member) */
export type MemberFullNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/member) */
export type MemberImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/member) */
export type MemberLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/member) */
export type MemberNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/member) */
export type MemberTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type MemberCollection = {
  __typename?: 'MemberCollection';
  items: Array<Maybe<Member>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type MemberDescription = {
  __typename?: 'MemberDescription';
  json: Scalars['JSON']['output'];
  links: MemberDescriptionLinks;
};

export type MemberDescriptionAssets = {
  __typename?: 'MemberDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type MemberDescriptionEntries = {
  __typename?: 'MemberDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type MemberDescriptionLinks = {
  __typename?: 'MemberDescriptionLinks';
  assets: MemberDescriptionAssets;
  entries: MemberDescriptionEntries;
  resources: MemberDescriptionResources;
};

export type MemberDescriptionResources = {
  __typename?: 'MemberDescriptionResources';
  block: Array<MemberDescriptionResourcesBlock>;
  hyperlink: Array<MemberDescriptionResourcesHyperlink>;
  inline: Array<MemberDescriptionResourcesInline>;
};

export type MemberDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'MemberDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type MemberDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'MemberDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type MemberDescriptionResourcesInline = ResourceLink & {
  __typename?: 'MemberDescriptionResourcesInline';
  sys: ResourceSys;
};

export type MemberFilter = {
  AND?: InputMaybe<Array<InputMaybe<MemberFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MemberFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullName_contains?: InputMaybe<Scalars['String']['input']>;
  fullName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fullName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fullName_not?: InputMaybe<Scalars['String']['input']>;
  fullName_not_contains?: InputMaybe<Scalars['String']['input']>;
  fullName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MemberLinkingCollections = {
  __typename?: 'MemberLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  membersListCollection?: Maybe<MembersListCollection>;
};

export type MemberLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type MemberLinkingCollectionsMembersListCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<MemberLinkingCollectionsMembersListCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum MemberLinkingCollectionsMembersListCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum MemberOrder {
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/membersList) */
export type MembersList = Entry &
  _Node & {
    __typename?: 'MembersList';
    _id: Scalars['ID']['output'];
    button?: Maybe<Link>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<MembersListLinkingCollections>;
    membersCollection?: Maybe<MembersListMembersCollection>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/membersList) */
export type MembersListButtonArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/membersList) */
export type MembersListLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/membersList) */
export type MembersListMembersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<MembersListMembersCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MemberFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/membersList) */
export type MembersListNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type MembersListCollection = {
  __typename?: 'MembersListCollection';
  items: Array<Maybe<MembersList>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type MembersListFilter = {
  AND?: InputMaybe<Array<InputMaybe<MembersListFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MembersListFilter>>>;
  button?: InputMaybe<CfLinkNestedFilter>;
  button_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  members?: InputMaybe<CfMemberNestedFilter>;
  membersCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type MembersListLinkingCollections = {
  __typename?: 'MembersListLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type MembersListLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type MembersListMembersCollection = {
  __typename?: 'MembersListMembersCollection';
  items: Array<Maybe<Member>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum MembersListMembersCollectionOrder {
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum MembersListOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type Messages = Entry &
  _Node & {
    __typename?: 'Messages';
    _id: Scalars['ID']['output'];
    all?: Maybe<Scalars['String']['output']>;
    autocompleteLabel?: Maybe<Scalars['String']['output']>;
    cancel?: Maybe<Scalars['String']['output']>;
    captchaPxLabel?: Maybe<Scalars['String']['output']>;
    chooseYourRegion?: Maybe<Scalars['String']['output']>;
    close?: Maybe<Scalars['String']['output']>;
    community?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    continue?: Maybe<Scalars['String']['output']>;
    countries?: Maybe<Scalars['String']['output']>;
    education?: Maybe<Scalars['String']['output']>;
    finance?: Maybe<Scalars['String']['output']>;
    geoLocationBannerContent?: Maybe<Scalars['String']['output']>;
    international?: Maybe<Scalars['String']['output']>;
    lifeabroad?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<MessagesLinkingCollections>;
    locationLangLabel?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    next?: Maybe<Scalars['String']['output']>;
    noOptions?: Maybe<Scalars['String']['output']>;
    noResults?: Maybe<Scalars['String']['output']>;
    previous?: Maybe<Scalars['String']['output']>;
    product?: Maybe<Scalars['String']['output']>;
    rafUrlInvalid?: Maybe<Scalars['String']['output']>;
    rafUrlUnavailable?: Maybe<Scalars['String']['output']>;
    readLess?: Maybe<Scalars['String']['output']>;
    readMore?: Maybe<Scalars['String']['output']>;
    referAFriendSendFromLabel?: Maybe<Scalars['String']['output']>;
    relatedPosts?: Maybe<Scalars['String']['output']>;
    searchCountry?: Maybe<Scalars['String']['output']>;
    showLess?: Maybe<Scalars['String']['output']>;
    showMore?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    updated?: Maybe<Scalars['String']['output']>;
  };

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesAllArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesAutocompleteLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesCancelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesCaptchaPxLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesChooseYourRegionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesCloseArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesCommunityArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesContinueArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesCountriesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesEducationArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesFinanceArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesGeoLocationBannerContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesInternationalArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesLifeabroadArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesLocationLangLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesNextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesNoOptionsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesNoResultsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesPreviousArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesProductArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesRafUrlInvalidArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesRafUrlUnavailableArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesReadLessArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesReadMoreArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesReferAFriendSendFromLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesRelatedPostsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesSearchCountryArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesShowLessArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesShowMoreArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/messages) */
export type MessagesUpdatedArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type MessagesCollection = {
  __typename?: 'MessagesCollection';
  items: Array<Maybe<Messages>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type MessagesFilter = {
  AND?: InputMaybe<Array<InputMaybe<MessagesFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MessagesFilter>>>;
  all?: InputMaybe<Scalars['String']['input']>;
  all_contains?: InputMaybe<Scalars['String']['input']>;
  all_exists?: InputMaybe<Scalars['Boolean']['input']>;
  all_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  all_not?: InputMaybe<Scalars['String']['input']>;
  all_not_contains?: InputMaybe<Scalars['String']['input']>;
  all_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  autocompleteLabel?: InputMaybe<Scalars['String']['input']>;
  autocompleteLabel_contains?: InputMaybe<Scalars['String']['input']>;
  autocompleteLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  autocompleteLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  autocompleteLabel_not?: InputMaybe<Scalars['String']['input']>;
  autocompleteLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  autocompleteLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  cancel?: InputMaybe<Scalars['String']['input']>;
  cancel_contains?: InputMaybe<Scalars['String']['input']>;
  cancel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  cancel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cancel_not?: InputMaybe<Scalars['String']['input']>;
  cancel_not_contains?: InputMaybe<Scalars['String']['input']>;
  cancel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  captchaPxLabel?: InputMaybe<Scalars['String']['input']>;
  captchaPxLabel_contains?: InputMaybe<Scalars['String']['input']>;
  captchaPxLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  captchaPxLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  captchaPxLabel_not?: InputMaybe<Scalars['String']['input']>;
  captchaPxLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  captchaPxLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  chooseYourRegion?: InputMaybe<Scalars['String']['input']>;
  chooseYourRegion_contains?: InputMaybe<Scalars['String']['input']>;
  chooseYourRegion_exists?: InputMaybe<Scalars['Boolean']['input']>;
  chooseYourRegion_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  chooseYourRegion_not?: InputMaybe<Scalars['String']['input']>;
  chooseYourRegion_not_contains?: InputMaybe<Scalars['String']['input']>;
  chooseYourRegion_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  close?: InputMaybe<Scalars['String']['input']>;
  close_contains?: InputMaybe<Scalars['String']['input']>;
  close_exists?: InputMaybe<Scalars['Boolean']['input']>;
  close_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  close_not?: InputMaybe<Scalars['String']['input']>;
  close_not_contains?: InputMaybe<Scalars['String']['input']>;
  close_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  community?: InputMaybe<Scalars['String']['input']>;
  community_contains?: InputMaybe<Scalars['String']['input']>;
  community_exists?: InputMaybe<Scalars['Boolean']['input']>;
  community_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  community_not?: InputMaybe<Scalars['String']['input']>;
  community_not_contains?: InputMaybe<Scalars['String']['input']>;
  community_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  continue?: InputMaybe<Scalars['String']['input']>;
  continue_contains?: InputMaybe<Scalars['String']['input']>;
  continue_exists?: InputMaybe<Scalars['Boolean']['input']>;
  continue_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  continue_not?: InputMaybe<Scalars['String']['input']>;
  continue_not_contains?: InputMaybe<Scalars['String']['input']>;
  continue_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  countries?: InputMaybe<Scalars['String']['input']>;
  countries_contains?: InputMaybe<Scalars['String']['input']>;
  countries_exists?: InputMaybe<Scalars['Boolean']['input']>;
  countries_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  countries_not?: InputMaybe<Scalars['String']['input']>;
  countries_not_contains?: InputMaybe<Scalars['String']['input']>;
  countries_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  education?: InputMaybe<Scalars['String']['input']>;
  education_contains?: InputMaybe<Scalars['String']['input']>;
  education_exists?: InputMaybe<Scalars['Boolean']['input']>;
  education_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  education_not?: InputMaybe<Scalars['String']['input']>;
  education_not_contains?: InputMaybe<Scalars['String']['input']>;
  education_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  finance?: InputMaybe<Scalars['String']['input']>;
  finance_contains?: InputMaybe<Scalars['String']['input']>;
  finance_exists?: InputMaybe<Scalars['Boolean']['input']>;
  finance_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  finance_not?: InputMaybe<Scalars['String']['input']>;
  finance_not_contains?: InputMaybe<Scalars['String']['input']>;
  finance_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  geoLocationBannerContent?: InputMaybe<Scalars['String']['input']>;
  geoLocationBannerContent_contains?: InputMaybe<Scalars['String']['input']>;
  geoLocationBannerContent_exists?: InputMaybe<Scalars['Boolean']['input']>;
  geoLocationBannerContent_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  geoLocationBannerContent_not?: InputMaybe<Scalars['String']['input']>;
  geoLocationBannerContent_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  geoLocationBannerContent_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  international?: InputMaybe<Scalars['String']['input']>;
  international_contains?: InputMaybe<Scalars['String']['input']>;
  international_exists?: InputMaybe<Scalars['Boolean']['input']>;
  international_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  international_not?: InputMaybe<Scalars['String']['input']>;
  international_not_contains?: InputMaybe<Scalars['String']['input']>;
  international_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  lifeabroad?: InputMaybe<Scalars['String']['input']>;
  lifeabroad_contains?: InputMaybe<Scalars['String']['input']>;
  lifeabroad_exists?: InputMaybe<Scalars['Boolean']['input']>;
  lifeabroad_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lifeabroad_not?: InputMaybe<Scalars['String']['input']>;
  lifeabroad_not_contains?: InputMaybe<Scalars['String']['input']>;
  lifeabroad_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locationLangLabel?: InputMaybe<Scalars['String']['input']>;
  locationLangLabel_contains?: InputMaybe<Scalars['String']['input']>;
  locationLangLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locationLangLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  locationLangLabel_not?: InputMaybe<Scalars['String']['input']>;
  locationLangLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  locationLangLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  next?: InputMaybe<Scalars['String']['input']>;
  next_contains?: InputMaybe<Scalars['String']['input']>;
  next_exists?: InputMaybe<Scalars['Boolean']['input']>;
  next_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  next_not?: InputMaybe<Scalars['String']['input']>;
  next_not_contains?: InputMaybe<Scalars['String']['input']>;
  next_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noOptions?: InputMaybe<Scalars['String']['input']>;
  noOptions_contains?: InputMaybe<Scalars['String']['input']>;
  noOptions_exists?: InputMaybe<Scalars['Boolean']['input']>;
  noOptions_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noOptions_not?: InputMaybe<Scalars['String']['input']>;
  noOptions_not_contains?: InputMaybe<Scalars['String']['input']>;
  noOptions_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noResults?: InputMaybe<Scalars['String']['input']>;
  noResults_contains?: InputMaybe<Scalars['String']['input']>;
  noResults_exists?: InputMaybe<Scalars['Boolean']['input']>;
  noResults_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noResults_not?: InputMaybe<Scalars['String']['input']>;
  noResults_not_contains?: InputMaybe<Scalars['String']['input']>;
  noResults_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  previous?: InputMaybe<Scalars['String']['input']>;
  previous_contains?: InputMaybe<Scalars['String']['input']>;
  previous_exists?: InputMaybe<Scalars['Boolean']['input']>;
  previous_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  previous_not?: InputMaybe<Scalars['String']['input']>;
  previous_not_contains?: InputMaybe<Scalars['String']['input']>;
  previous_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  product?: InputMaybe<Scalars['String']['input']>;
  product_contains?: InputMaybe<Scalars['String']['input']>;
  product_exists?: InputMaybe<Scalars['Boolean']['input']>;
  product_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  product_not?: InputMaybe<Scalars['String']['input']>;
  product_not_contains?: InputMaybe<Scalars['String']['input']>;
  product_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rafUrlInvalid?: InputMaybe<Scalars['String']['input']>;
  rafUrlInvalid_contains?: InputMaybe<Scalars['String']['input']>;
  rafUrlInvalid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  rafUrlInvalid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rafUrlInvalid_not?: InputMaybe<Scalars['String']['input']>;
  rafUrlInvalid_not_contains?: InputMaybe<Scalars['String']['input']>;
  rafUrlInvalid_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  rafUrlUnavailable?: InputMaybe<Scalars['String']['input']>;
  rafUrlUnavailable_contains?: InputMaybe<Scalars['String']['input']>;
  rafUrlUnavailable_exists?: InputMaybe<Scalars['Boolean']['input']>;
  rafUrlUnavailable_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  rafUrlUnavailable_not?: InputMaybe<Scalars['String']['input']>;
  rafUrlUnavailable_not_contains?: InputMaybe<Scalars['String']['input']>;
  rafUrlUnavailable_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  readLess?: InputMaybe<Scalars['String']['input']>;
  readLess_contains?: InputMaybe<Scalars['String']['input']>;
  readLess_exists?: InputMaybe<Scalars['Boolean']['input']>;
  readLess_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  readLess_not?: InputMaybe<Scalars['String']['input']>;
  readLess_not_contains?: InputMaybe<Scalars['String']['input']>;
  readLess_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  readMore?: InputMaybe<Scalars['String']['input']>;
  readMore_contains?: InputMaybe<Scalars['String']['input']>;
  readMore_exists?: InputMaybe<Scalars['Boolean']['input']>;
  readMore_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  readMore_not?: InputMaybe<Scalars['String']['input']>;
  readMore_not_contains?: InputMaybe<Scalars['String']['input']>;
  readMore_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  referAFriendSendFromLabel?: InputMaybe<Scalars['String']['input']>;
  referAFriendSendFromLabel_contains?: InputMaybe<Scalars['String']['input']>;
  referAFriendSendFromLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  referAFriendSendFromLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  referAFriendSendFromLabel_not?: InputMaybe<Scalars['String']['input']>;
  referAFriendSendFromLabel_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  referAFriendSendFromLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  relatedPosts?: InputMaybe<Scalars['String']['input']>;
  relatedPosts_contains?: InputMaybe<Scalars['String']['input']>;
  relatedPosts_exists?: InputMaybe<Scalars['Boolean']['input']>;
  relatedPosts_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedPosts_not?: InputMaybe<Scalars['String']['input']>;
  relatedPosts_not_contains?: InputMaybe<Scalars['String']['input']>;
  relatedPosts_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  searchCountry?: InputMaybe<Scalars['String']['input']>;
  searchCountry_contains?: InputMaybe<Scalars['String']['input']>;
  searchCountry_exists?: InputMaybe<Scalars['Boolean']['input']>;
  searchCountry_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  searchCountry_not?: InputMaybe<Scalars['String']['input']>;
  searchCountry_not_contains?: InputMaybe<Scalars['String']['input']>;
  searchCountry_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  showLess?: InputMaybe<Scalars['String']['input']>;
  showLess_contains?: InputMaybe<Scalars['String']['input']>;
  showLess_exists?: InputMaybe<Scalars['Boolean']['input']>;
  showLess_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  showLess_not?: InputMaybe<Scalars['String']['input']>;
  showLess_not_contains?: InputMaybe<Scalars['String']['input']>;
  showLess_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  showMore?: InputMaybe<Scalars['String']['input']>;
  showMore_contains?: InputMaybe<Scalars['String']['input']>;
  showMore_exists?: InputMaybe<Scalars['Boolean']['input']>;
  showMore_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  showMore_not?: InputMaybe<Scalars['String']['input']>;
  showMore_not_contains?: InputMaybe<Scalars['String']['input']>;
  showMore_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  updated?: InputMaybe<Scalars['String']['input']>;
  updated_contains?: InputMaybe<Scalars['String']['input']>;
  updated_exists?: InputMaybe<Scalars['Boolean']['input']>;
  updated_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_not?: InputMaybe<Scalars['String']['input']>;
  updated_not_contains?: InputMaybe<Scalars['String']['input']>;
  updated_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MessagesLinkingCollections = {
  __typename?: 'MessagesLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type MessagesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum MessagesOrder {
  AllAsc = 'all_ASC',
  AllDesc = 'all_DESC',
  AutocompleteLabelAsc = 'autocompleteLabel_ASC',
  AutocompleteLabelDesc = 'autocompleteLabel_DESC',
  CancelAsc = 'cancel_ASC',
  CancelDesc = 'cancel_DESC',
  CaptchaPxLabelAsc = 'captchaPxLabel_ASC',
  CaptchaPxLabelDesc = 'captchaPxLabel_DESC',
  ChooseYourRegionAsc = 'chooseYourRegion_ASC',
  ChooseYourRegionDesc = 'chooseYourRegion_DESC',
  CloseAsc = 'close_ASC',
  CloseDesc = 'close_DESC',
  CommunityAsc = 'community_ASC',
  CommunityDesc = 'community_DESC',
  ContinueAsc = 'continue_ASC',
  ContinueDesc = 'continue_DESC',
  CountriesAsc = 'countries_ASC',
  CountriesDesc = 'countries_DESC',
  EducationAsc = 'education_ASC',
  EducationDesc = 'education_DESC',
  FinanceAsc = 'finance_ASC',
  FinanceDesc = 'finance_DESC',
  GeoLocationBannerContentAsc = 'geoLocationBannerContent_ASC',
  GeoLocationBannerContentDesc = 'geoLocationBannerContent_DESC',
  InternationalAsc = 'international_ASC',
  InternationalDesc = 'international_DESC',
  LifeabroadAsc = 'lifeabroad_ASC',
  LifeabroadDesc = 'lifeabroad_DESC',
  LocationLangLabelAsc = 'locationLangLabel_ASC',
  LocationLangLabelDesc = 'locationLangLabel_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NextAsc = 'next_ASC',
  NextDesc = 'next_DESC',
  NoOptionsAsc = 'noOptions_ASC',
  NoOptionsDesc = 'noOptions_DESC',
  NoResultsAsc = 'noResults_ASC',
  NoResultsDesc = 'noResults_DESC',
  PreviousAsc = 'previous_ASC',
  PreviousDesc = 'previous_DESC',
  ProductAsc = 'product_ASC',
  ProductDesc = 'product_DESC',
  RafUrlInvalidAsc = 'rafUrlInvalid_ASC',
  RafUrlInvalidDesc = 'rafUrlInvalid_DESC',
  RafUrlUnavailableAsc = 'rafUrlUnavailable_ASC',
  RafUrlUnavailableDesc = 'rafUrlUnavailable_DESC',
  ReadLessAsc = 'readLess_ASC',
  ReadLessDesc = 'readLess_DESC',
  ReadMoreAsc = 'readMore_ASC',
  ReadMoreDesc = 'readMore_DESC',
  ReferAFriendSendFromLabelAsc = 'referAFriendSendFromLabel_ASC',
  ReferAFriendSendFromLabelDesc = 'referAFriendSendFromLabel_DESC',
  RelatedPostsAsc = 'relatedPosts_ASC',
  RelatedPostsDesc = 'relatedPosts_DESC',
  SearchCountryAsc = 'searchCountry_ASC',
  SearchCountryDesc = 'searchCountry_DESC',
  ShowLessAsc = 'showLess_ASC',
  ShowLessDesc = 'showLess_DESC',
  ShowMoreAsc = 'showMore_ASC',
  ShowMoreDesc = 'showMore_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UpdatedAsc = 'updated_ASC',
  UpdatedDesc = 'updated_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mfaFactors) */
export type MfaFactors = Entry &
  _Node & {
    __typename?: 'MfaFactors';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<MfaFactorsLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sendVerificationCta?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mfaFactors) */
export type MfaFactorsDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mfaFactors) */
export type MfaFactorsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mfaFactors) */
export type MfaFactorsNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mfaFactors) */
export type MfaFactorsSendVerificationCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mfaFactors) */
export type MfaFactorsTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type MfaFactorsCollection = {
  __typename?: 'MfaFactorsCollection';
  items: Array<Maybe<MfaFactors>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type MfaFactorsFilter = {
  AND?: InputMaybe<Array<InputMaybe<MfaFactorsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MfaFactorsFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sendVerificationCta?: InputMaybe<Scalars['String']['input']>;
  sendVerificationCta_contains?: InputMaybe<Scalars['String']['input']>;
  sendVerificationCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendVerificationCta_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendVerificationCta_not?: InputMaybe<Scalars['String']['input']>;
  sendVerificationCta_not_contains?: InputMaybe<Scalars['String']['input']>;
  sendVerificationCta_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MfaFactorsLinkingCollections = {
  __typename?: 'MfaFactorsLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type MfaFactorsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum MfaFactorsOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SendVerificationCtaAsc = 'sendVerificationCta_ASC',
  SendVerificationCtaDesc = 'sendVerificationCta_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mfaVerification) */
export type MfaVerification = Entry &
  _Node & {
    __typename?: 'MfaVerification';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<MfaVerificationLinkingCollections>;
    mfaLabel?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    submitCodeCta?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mfaVerification) */
export type MfaVerificationDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mfaVerification) */
export type MfaVerificationLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mfaVerification) */
export type MfaVerificationMfaLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mfaVerification) */
export type MfaVerificationNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mfaVerification) */
export type MfaVerificationSubmitCodeCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mfaVerification) */
export type MfaVerificationTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type MfaVerificationCollection = {
  __typename?: 'MfaVerificationCollection';
  items: Array<Maybe<MfaVerification>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type MfaVerificationFilter = {
  AND?: InputMaybe<Array<InputMaybe<MfaVerificationFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MfaVerificationFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  mfaLabel?: InputMaybe<Scalars['String']['input']>;
  mfaLabel_contains?: InputMaybe<Scalars['String']['input']>;
  mfaLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mfaLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mfaLabel_not?: InputMaybe<Scalars['String']['input']>;
  mfaLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  mfaLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  submitCodeCta?: InputMaybe<Scalars['String']['input']>;
  submitCodeCta_contains?: InputMaybe<Scalars['String']['input']>;
  submitCodeCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  submitCodeCta_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  submitCodeCta_not?: InputMaybe<Scalars['String']['input']>;
  submitCodeCta_not_contains?: InputMaybe<Scalars['String']['input']>;
  submitCodeCta_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MfaVerificationLinkingCollections = {
  __typename?: 'MfaVerificationLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type MfaVerificationLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum MfaVerificationOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  MfaLabelAsc = 'mfaLabel_ASC',
  MfaLabelDesc = 'mfaLabel_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubmitCodeCtaAsc = 'submitCodeCta_ASC',
  SubmitCodeCtaDesc = 'submitCodeCta_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mobileAppLeadingLinks) */
export type MobileAppLeadingLinks = Entry &
  _Node & {
    __typename?: 'MobileAppLeadingLinks';
    _id: Scalars['ID']['output'];
    androidLink?: Maybe<Link>;
    contentfulMetadata: ContentfulMetadata;
    desktopLink?: Maybe<Link>;
    iosLink?: Maybe<Link>;
    label?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<MobileAppLeadingLinksLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mobileAppLeadingLinks) */
export type MobileAppLeadingLinksAndroidLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mobileAppLeadingLinks) */
export type MobileAppLeadingLinksDesktopLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mobileAppLeadingLinks) */
export type MobileAppLeadingLinksIosLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mobileAppLeadingLinks) */
export type MobileAppLeadingLinksLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mobileAppLeadingLinks) */
export type MobileAppLeadingLinksLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mobileAppLeadingLinks) */
export type MobileAppLeadingLinksNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type MobileAppLeadingLinksCollection = {
  __typename?: 'MobileAppLeadingLinksCollection';
  items: Array<Maybe<MobileAppLeadingLinks>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type MobileAppLeadingLinksFilter = {
  AND?: InputMaybe<Array<InputMaybe<MobileAppLeadingLinksFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MobileAppLeadingLinksFilter>>>;
  androidLink?: InputMaybe<CfLinkNestedFilter>;
  androidLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  desktopLink?: InputMaybe<CfLinkNestedFilter>;
  desktopLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  iosLink?: InputMaybe<CfLinkNestedFilter>;
  iosLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type MobileAppLeadingLinksLinkingCollections = {
  __typename?: 'MobileAppLeadingLinksLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  linkCollection?: Maybe<LinkCollection>;
};

export type MobileAppLeadingLinksLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type MobileAppLeadingLinksLinkingCollectionsLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<MobileAppLeadingLinksLinkingCollectionsLinkCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum MobileAppLeadingLinksLinkingCollectionsLinkCollectionOrder {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  GaTrackNameAsc = 'gaTrackName_ASC',
  GaTrackNameDesc = 'gaTrackName_DESC',
  HighlightedAsc = 'highlighted_ASC',
  HighlightedDesc = 'highlighted_DESC',
  IsDisplayedWhenLoggedInAsc = 'isDisplayedWhenLoggedIn_ASC',
  IsDisplayedWhenLoggedInDesc = 'isDisplayedWhenLoggedIn_DESC',
  IsInternalAsc = 'isInternal_ASC',
  IsInternalDesc = 'isInternal_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  MobileMenuToggleAsc = 'mobileMenuToggle_ASC',
  MobileMenuToggleDesc = 'mobileMenuToggle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoFollowAsc = 'noFollow_ASC',
  NoFollowDesc = 'noFollow_DESC',
  OpenInNewTabAsc = 'openInNewTab_ASC',
  OpenInNewTabDesc = 'openInNewTab_DESC',
  RedirectToLegacyProjectAsc = 'redirectToLegacyProject_ASC',
  RedirectToLegacyProjectDesc = 'redirectToLegacyProject_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnderlineAsc = 'underline_ASC',
  UnderlineDesc = 'underline_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

export enum MobileAppLeadingLinksOrder {
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mobileStoreData) */
export type MobileStoreData = Entry &
  _Node & {
    __typename?: 'MobileStoreData';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    icon?: Maybe<Asset>;
    iconLink?: Maybe<Link>;
    linkedFrom?: Maybe<MobileStoreDataLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    rating?: Maybe<Scalars['Float']['output']>;
    ratingsAmount?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mobileStoreData) */
export type MobileStoreDataIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mobileStoreData) */
export type MobileStoreDataIconLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mobileStoreData) */
export type MobileStoreDataLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mobileStoreData) */
export type MobileStoreDataNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mobileStoreData) */
export type MobileStoreDataRatingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/mobileStoreData) */
export type MobileStoreDataRatingsAmountArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type MobileStoreDataCollection = {
  __typename?: 'MobileStoreDataCollection';
  items: Array<Maybe<MobileStoreData>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type MobileStoreDataFilter = {
  AND?: InputMaybe<Array<InputMaybe<MobileStoreDataFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MobileStoreDataFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  iconLink?: InputMaybe<CfLinkNestedFilter>;
  iconLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rating?: InputMaybe<Scalars['Float']['input']>;
  rating_exists?: InputMaybe<Scalars['Boolean']['input']>;
  rating_gt?: InputMaybe<Scalars['Float']['input']>;
  rating_gte?: InputMaybe<Scalars['Float']['input']>;
  rating_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  rating_lt?: InputMaybe<Scalars['Float']['input']>;
  rating_lte?: InputMaybe<Scalars['Float']['input']>;
  rating_not?: InputMaybe<Scalars['Float']['input']>;
  rating_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  ratingsAmount?: InputMaybe<Scalars['String']['input']>;
  ratingsAmount_contains?: InputMaybe<Scalars['String']['input']>;
  ratingsAmount_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ratingsAmount_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ratingsAmount_not?: InputMaybe<Scalars['String']['input']>;
  ratingsAmount_not_contains?: InputMaybe<Scalars['String']['input']>;
  ratingsAmount_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
};

export type MobileStoreDataLinkingCollections = {
  __typename?: 'MobileStoreDataLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  socialProofingModuleCollection?: Maybe<SocialProofingModuleCollection>;
};

export type MobileStoreDataLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type MobileStoreDataLinkingCollectionsSocialProofingModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<MobileStoreDataLinkingCollectionsSocialProofingModuleCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum MobileStoreDataLinkingCollectionsSocialProofingModuleCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum MobileStoreDataOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RatingAsc = 'rating_ASC',
  RatingDesc = 'rating_DESC',
  RatingsAmountAsc = 'ratingsAmount_ASC',
  RatingsAmountDesc = 'ratingsAmount_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** The dialog that warns a user that they're sending money to someone for the first time [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestConfirmationDialog) */
export type MoneyRequestConfirmationDialog = Entry &
  _Node & {
    __typename?: 'MoneyRequestConfirmationDialog';
    _id: Scalars['ID']['output'];
    acceptButtonText?: Maybe<Scalars['String']['output']>;
    content?: Maybe<MoneyRequestConfirmationDialogContent>;
    contentfulMetadata: ContentfulMetadata;
    declineButtonText?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<MoneyRequestConfirmationDialogLinkingCollections>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** The dialog that warns a user that they're sending money to someone for the first time [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestConfirmationDialog) */
export type MoneyRequestConfirmationDialogAcceptButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** The dialog that warns a user that they're sending money to someone for the first time [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestConfirmationDialog) */
export type MoneyRequestConfirmationDialogContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** The dialog that warns a user that they're sending money to someone for the first time [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestConfirmationDialog) */
export type MoneyRequestConfirmationDialogDeclineButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** The dialog that warns a user that they're sending money to someone for the first time [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestConfirmationDialog) */
export type MoneyRequestConfirmationDialogLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** The dialog that warns a user that they're sending money to someone for the first time [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestConfirmationDialog) */
export type MoneyRequestConfirmationDialogTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type MoneyRequestConfirmationDialogCollection = {
  __typename?: 'MoneyRequestConfirmationDialogCollection';
  items: Array<Maybe<MoneyRequestConfirmationDialog>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type MoneyRequestConfirmationDialogContent = {
  __typename?: 'MoneyRequestConfirmationDialogContent';
  json: Scalars['JSON']['output'];
  links: MoneyRequestConfirmationDialogContentLinks;
};

export type MoneyRequestConfirmationDialogContentAssets = {
  __typename?: 'MoneyRequestConfirmationDialogContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type MoneyRequestConfirmationDialogContentEntries = {
  __typename?: 'MoneyRequestConfirmationDialogContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type MoneyRequestConfirmationDialogContentLinks = {
  __typename?: 'MoneyRequestConfirmationDialogContentLinks';
  assets: MoneyRequestConfirmationDialogContentAssets;
  entries: MoneyRequestConfirmationDialogContentEntries;
  resources: MoneyRequestConfirmationDialogContentResources;
};

export type MoneyRequestConfirmationDialogContentResources = {
  __typename?: 'MoneyRequestConfirmationDialogContentResources';
  block: Array<MoneyRequestConfirmationDialogContentResourcesBlock>;
  hyperlink: Array<MoneyRequestConfirmationDialogContentResourcesHyperlink>;
  inline: Array<MoneyRequestConfirmationDialogContentResourcesInline>;
};

export type MoneyRequestConfirmationDialogContentResourcesBlock = ResourceLink & {
  __typename?: 'MoneyRequestConfirmationDialogContentResourcesBlock';
  sys: ResourceSys;
};

export type MoneyRequestConfirmationDialogContentResourcesHyperlink = ResourceLink & {
  __typename?: 'MoneyRequestConfirmationDialogContentResourcesHyperlink';
  sys: ResourceSys;
};

export type MoneyRequestConfirmationDialogContentResourcesInline = ResourceLink & {
  __typename?: 'MoneyRequestConfirmationDialogContentResourcesInline';
  sys: ResourceSys;
};

export type MoneyRequestConfirmationDialogFilter = {
  AND?: InputMaybe<Array<InputMaybe<MoneyRequestConfirmationDialogFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MoneyRequestConfirmationDialogFilter>>>;
  acceptButtonText?: InputMaybe<Scalars['String']['input']>;
  acceptButtonText_contains?: InputMaybe<Scalars['String']['input']>;
  acceptButtonText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  acceptButtonText_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  acceptButtonText_not?: InputMaybe<Scalars['String']['input']>;
  acceptButtonText_not_contains?: InputMaybe<Scalars['String']['input']>;
  acceptButtonText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  content_contains?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  declineButtonText?: InputMaybe<Scalars['String']['input']>;
  declineButtonText_contains?: InputMaybe<Scalars['String']['input']>;
  declineButtonText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  declineButtonText_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  declineButtonText_not?: InputMaybe<Scalars['String']['input']>;
  declineButtonText_not_contains?: InputMaybe<Scalars['String']['input']>;
  declineButtonText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MoneyRequestConfirmationDialogLinkingCollections = {
  __typename?: 'MoneyRequestConfirmationDialogLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  moneyRequestPaymentReasonCollection?: Maybe<MoneyRequestPaymentReasonCollection>;
};

export type MoneyRequestConfirmationDialogLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type MoneyRequestConfirmationDialogLinkingCollectionsMoneyRequestPaymentReasonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<MoneyRequestConfirmationDialogLinkingCollectionsMoneyRequestPaymentReasonCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum MoneyRequestConfirmationDialogLinkingCollectionsMoneyRequestPaymentReasonCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum MoneyRequestConfirmationDialogOrder {
  AcceptButtonTextAsc = 'acceptButtonText_ASC',
  AcceptButtonTextDesc = 'acceptButtonText_DESC',
  DeclineButtonTextAsc = 'declineButtonText_ASC',
  DeclineButtonTextDesc = 'declineButtonText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** The main module of the money request pay [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestPay) */
export type MoneyRequestPay = Entry &
  _Node & {
    __typename?: 'MoneyRequestPay';
    _id: Scalars['ID']['output'];
    accountNumberLabel?: Maybe<Scalars['String']['output']>;
    amountRequestedLabel?: Maybe<Scalars['String']['output']>;
    bankNameLabel?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    errorText?: Maybe<Scalars['String']['output']>;
    ibanLabel?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<MoneyRequestPayLinkingCollections>;
    mobileNumberLabel?: Maybe<Scalars['String']['output']>;
    moneyProviderLabel?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    paymentReason?: Maybe<MoneyRequestPaymentReason>;
    quoteIcon?: Maybe<Asset>;
    requestedByLabel?: Maybe<Scalars['String']['output']>;
    signIn?: Maybe<SignIn>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
    titleForNotLoggedIn?: Maybe<Scalars['String']['output']>;
  };

/** The main module of the money request pay [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestPay) */
export type MoneyRequestPayAccountNumberLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** The main module of the money request pay [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestPay) */
export type MoneyRequestPayAmountRequestedLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** The main module of the money request pay [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestPay) */
export type MoneyRequestPayBankNameLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** The main module of the money request pay [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestPay) */
export type MoneyRequestPayErrorTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** The main module of the money request pay [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestPay) */
export type MoneyRequestPayIbanLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** The main module of the money request pay [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestPay) */
export type MoneyRequestPayLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** The main module of the money request pay [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestPay) */
export type MoneyRequestPayMobileNumberLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** The main module of the money request pay [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestPay) */
export type MoneyRequestPayMoneyProviderLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** The main module of the money request pay [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestPay) */
export type MoneyRequestPayNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** The main module of the money request pay [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestPay) */
export type MoneyRequestPayPaymentReasonArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<MoneyRequestPaymentReasonFilter>;
};

/** The main module of the money request pay [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestPay) */
export type MoneyRequestPayQuoteIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The main module of the money request pay [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestPay) */
export type MoneyRequestPayRequestedByLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** The main module of the money request pay [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestPay) */
export type MoneyRequestPaySignInArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SignInFilter>;
};

/** The main module of the money request pay [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestPay) */
export type MoneyRequestPayTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** The main module of the money request pay [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestPay) */
export type MoneyRequestPayTitleForNotLoggedInArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type MoneyRequestPayCollection = {
  __typename?: 'MoneyRequestPayCollection';
  items: Array<Maybe<MoneyRequestPay>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type MoneyRequestPayFilter = {
  AND?: InputMaybe<Array<InputMaybe<MoneyRequestPayFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MoneyRequestPayFilter>>>;
  accountNumberLabel?: InputMaybe<Scalars['String']['input']>;
  accountNumberLabel_contains?: InputMaybe<Scalars['String']['input']>;
  accountNumberLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  accountNumberLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  accountNumberLabel_not?: InputMaybe<Scalars['String']['input']>;
  accountNumberLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  accountNumberLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  amountRequestedLabel?: InputMaybe<Scalars['String']['input']>;
  amountRequestedLabel_contains?: InputMaybe<Scalars['String']['input']>;
  amountRequestedLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  amountRequestedLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  amountRequestedLabel_not?: InputMaybe<Scalars['String']['input']>;
  amountRequestedLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  amountRequestedLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  bankNameLabel?: InputMaybe<Scalars['String']['input']>;
  bankNameLabel_contains?: InputMaybe<Scalars['String']['input']>;
  bankNameLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bankNameLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bankNameLabel_not?: InputMaybe<Scalars['String']['input']>;
  bankNameLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  bankNameLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  errorText?: InputMaybe<Scalars['String']['input']>;
  errorText_contains?: InputMaybe<Scalars['String']['input']>;
  errorText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  errorText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  errorText_not?: InputMaybe<Scalars['String']['input']>;
  errorText_not_contains?: InputMaybe<Scalars['String']['input']>;
  errorText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ibanLabel?: InputMaybe<Scalars['String']['input']>;
  ibanLabel_contains?: InputMaybe<Scalars['String']['input']>;
  ibanLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ibanLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ibanLabel_not?: InputMaybe<Scalars['String']['input']>;
  ibanLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  ibanLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mobileNumberLabel?: InputMaybe<Scalars['String']['input']>;
  mobileNumberLabel_contains?: InputMaybe<Scalars['String']['input']>;
  mobileNumberLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileNumberLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  mobileNumberLabel_not?: InputMaybe<Scalars['String']['input']>;
  mobileNumberLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  mobileNumberLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  moneyProviderLabel?: InputMaybe<Scalars['String']['input']>;
  moneyProviderLabel_contains?: InputMaybe<Scalars['String']['input']>;
  moneyProviderLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  moneyProviderLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  moneyProviderLabel_not?: InputMaybe<Scalars['String']['input']>;
  moneyProviderLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  moneyProviderLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  paymentReason?: InputMaybe<CfMoneyRequestPaymentReasonNestedFilter>;
  paymentReason_exists?: InputMaybe<Scalars['Boolean']['input']>;
  quoteIcon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  requestedByLabel?: InputMaybe<Scalars['String']['input']>;
  requestedByLabel_contains?: InputMaybe<Scalars['String']['input']>;
  requestedByLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  requestedByLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  requestedByLabel_not?: InputMaybe<Scalars['String']['input']>;
  requestedByLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  requestedByLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  signIn?: InputMaybe<CfSignInNestedFilter>;
  signIn_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleForNotLoggedIn?: InputMaybe<Scalars['String']['input']>;
  titleForNotLoggedIn_contains?: InputMaybe<Scalars['String']['input']>;
  titleForNotLoggedIn_exists?: InputMaybe<Scalars['Boolean']['input']>;
  titleForNotLoggedIn_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  titleForNotLoggedIn_not?: InputMaybe<Scalars['String']['input']>;
  titleForNotLoggedIn_not_contains?: InputMaybe<Scalars['String']['input']>;
  titleForNotLoggedIn_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MoneyRequestPayLinkingCollections = {
  __typename?: 'MoneyRequestPayLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type MoneyRequestPayLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum MoneyRequestPayOrder {
  AccountNumberLabelAsc = 'accountNumberLabel_ASC',
  AccountNumberLabelDesc = 'accountNumberLabel_DESC',
  AmountRequestedLabelAsc = 'amountRequestedLabel_ASC',
  AmountRequestedLabelDesc = 'amountRequestedLabel_DESC',
  BankNameLabelAsc = 'bankNameLabel_ASC',
  BankNameLabelDesc = 'bankNameLabel_DESC',
  ErrorTextAsc = 'errorText_ASC',
  ErrorTextDesc = 'errorText_DESC',
  IbanLabelAsc = 'ibanLabel_ASC',
  IbanLabelDesc = 'ibanLabel_DESC',
  MobileNumberLabelAsc = 'mobileNumberLabel_ASC',
  MobileNumberLabelDesc = 'mobileNumberLabel_DESC',
  MoneyProviderLabelAsc = 'moneyProviderLabel_ASC',
  MoneyProviderLabelDesc = 'moneyProviderLabel_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RequestedByLabelAsc = 'requestedByLabel_ASC',
  RequestedByLabelDesc = 'requestedByLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleForNotLoggedInAsc = 'titleForNotLoggedIn_ASC',
  TitleForNotLoggedInDesc = 'titleForNotLoggedIn_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestPaymentReason) */
export type MoneyRequestPaymentReason = Entry &
  _Node & {
    __typename?: 'MoneyRequestPaymentReason';
    _id: Scalars['ID']['output'];
    button?: Maybe<Link>;
    confirmationDialog?: Maybe<MoneyRequestConfirmationDialog>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<MoneyRequestPaymentReasonLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    reasonsCollection?: Maybe<MoneyRequestPaymentReasonReasonsCollection>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestPaymentReason) */
export type MoneyRequestPaymentReasonButtonArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestPaymentReason) */
export type MoneyRequestPaymentReasonConfirmationDialogArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<MoneyRequestConfirmationDialogFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestPaymentReason) */
export type MoneyRequestPaymentReasonDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestPaymentReason) */
export type MoneyRequestPaymentReasonLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestPaymentReason) */
export type MoneyRequestPaymentReasonNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestPaymentReason) */
export type MoneyRequestPaymentReasonReasonsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<MoneyRequestPaymentReasonReasonsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PaymentReasonFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyRequestPaymentReason) */
export type MoneyRequestPaymentReasonTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type MoneyRequestPaymentReasonCollection = {
  __typename?: 'MoneyRequestPaymentReasonCollection';
  items: Array<Maybe<MoneyRequestPaymentReason>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type MoneyRequestPaymentReasonFilter = {
  AND?: InputMaybe<Array<InputMaybe<MoneyRequestPaymentReasonFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MoneyRequestPaymentReasonFilter>>>;
  button?: InputMaybe<CfLinkNestedFilter>;
  button_exists?: InputMaybe<Scalars['Boolean']['input']>;
  confirmationDialog?: InputMaybe<CfMoneyRequestConfirmationDialogNestedFilter>;
  confirmationDialog_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reasons?: InputMaybe<CfPaymentReasonNestedFilter>;
  reasonsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MoneyRequestPaymentReasonLinkingCollections = {
  __typename?: 'MoneyRequestPaymentReasonLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  moneyRequestPayCollection?: Maybe<MoneyRequestPayCollection>;
};

export type MoneyRequestPaymentReasonLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type MoneyRequestPaymentReasonLinkingCollectionsMoneyRequestPayCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<MoneyRequestPaymentReasonLinkingCollectionsMoneyRequestPayCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum MoneyRequestPaymentReasonLinkingCollectionsMoneyRequestPayCollectionOrder {
  AccountNumberLabelAsc = 'accountNumberLabel_ASC',
  AccountNumberLabelDesc = 'accountNumberLabel_DESC',
  AmountRequestedLabelAsc = 'amountRequestedLabel_ASC',
  AmountRequestedLabelDesc = 'amountRequestedLabel_DESC',
  BankNameLabelAsc = 'bankNameLabel_ASC',
  BankNameLabelDesc = 'bankNameLabel_DESC',
  ErrorTextAsc = 'errorText_ASC',
  ErrorTextDesc = 'errorText_DESC',
  IbanLabelAsc = 'ibanLabel_ASC',
  IbanLabelDesc = 'ibanLabel_DESC',
  MobileNumberLabelAsc = 'mobileNumberLabel_ASC',
  MobileNumberLabelDesc = 'mobileNumberLabel_DESC',
  MoneyProviderLabelAsc = 'moneyProviderLabel_ASC',
  MoneyProviderLabelDesc = 'moneyProviderLabel_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RequestedByLabelAsc = 'requestedByLabel_ASC',
  RequestedByLabelDesc = 'requestedByLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleForNotLoggedInAsc = 'titleForNotLoggedIn_ASC',
  TitleForNotLoggedInDesc = 'titleForNotLoggedIn_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum MoneyRequestPaymentReasonOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type MoneyRequestPaymentReasonReasonsCollection = {
  __typename?: 'MoneyRequestPaymentReasonReasonsCollection';
  items: Array<Maybe<PaymentReason>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum MoneyRequestPaymentReasonReasonsCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ReasonIdAsc = 'reasonId_ASC',
  ReasonIdDesc = 'reasonId_DESC',
  ReasonAsc = 'reason_ASC',
  ReasonDesc = 'reason_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyTransfer) */
export type MoneyTransfer = Entry &
  _Node & {
    __typename?: 'MoneyTransfer';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    header?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<MoneyTransferLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    paymentIconsCollection?: Maybe<AssetCollection>;
    paymentText?: Maybe<Scalars['String']['output']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    richHeader?: Maybe<MoneyTransferRichHeader>;
    richSubheader?: Maybe<MoneyTransferRichSubheader>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    subheader?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    transferCardsCollection?: Maybe<MoneyTransferTransferCardsCollection>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyTransfer) */
export type MoneyTransferHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyTransfer) */
export type MoneyTransferLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyTransfer) */
export type MoneyTransferNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyTransfer) */
export type MoneyTransferPaymentIconsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyTransfer) */
export type MoneyTransferPaymentTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyTransfer) */
export type MoneyTransferReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyTransfer) */
export type MoneyTransferRichHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyTransfer) */
export type MoneyTransferRichSubheaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyTransfer) */
export type MoneyTransferSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyTransfer) */
export type MoneyTransferSubheaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyTransfer) */
export type MoneyTransferTransferCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<MoneyTransferTransferCardsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MoneyTransferCardFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyTransferCard) */
export type MoneyTransferCard = Entry &
  _Node & {
    __typename?: 'MoneyTransferCard';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    header?: Maybe<Scalars['String']['output']>;
    helpLink?: Maybe<Link>;
    image?: Maybe<Asset>;
    linkedFrom?: Maybe<MoneyTransferCardLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    payoutMethodId?: Maybe<Scalars['String']['output']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyTransferCard) */
export type MoneyTransferCardDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyTransferCard) */
export type MoneyTransferCardHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyTransferCard) */
export type MoneyTransferCardHelpLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyTransferCard) */
export type MoneyTransferCardImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyTransferCard) */
export type MoneyTransferCardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyTransferCard) */
export type MoneyTransferCardNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyTransferCard) */
export type MoneyTransferCardPayoutMethodIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyTransferCard) */
export type MoneyTransferCardReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/moneyTransferCard) */
export type MoneyTransferCardSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type MoneyTransferCardCollection = {
  __typename?: 'MoneyTransferCardCollection';
  items: Array<Maybe<MoneyTransferCard>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type MoneyTransferCardFilter = {
  AND?: InputMaybe<Array<InputMaybe<MoneyTransferCardFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MoneyTransferCardFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  helpLink?: InputMaybe<CfLinkNestedFilter>;
  helpLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  payoutMethodId?: InputMaybe<Scalars['String']['input']>;
  payoutMethodId_contains?: InputMaybe<Scalars['String']['input']>;
  payoutMethodId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  payoutMethodId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  payoutMethodId_not?: InputMaybe<Scalars['String']['input']>;
  payoutMethodId_not_contains?: InputMaybe<Scalars['String']['input']>;
  payoutMethodId_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type MoneyTransferCardLinkingCollections = {
  __typename?: 'MoneyTransferCardLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  moneyTransferCollection?: Maybe<MoneyTransferCollection>;
};

export type MoneyTransferCardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type MoneyTransferCardLinkingCollectionsMoneyTransferCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<MoneyTransferCardLinkingCollectionsMoneyTransferCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum MoneyTransferCardLinkingCollectionsMoneyTransferCollectionOrder {
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PaymentTextAsc = 'paymentText_ASC',
  PaymentTextDesc = 'paymentText_DESC',
  SubheaderAsc = 'subheader_ASC',
  SubheaderDesc = 'subheader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum MoneyTransferCardOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PayoutMethodIdAsc = 'payoutMethodId_ASC',
  PayoutMethodIdDesc = 'payoutMethodId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type MoneyTransferCollection = {
  __typename?: 'MoneyTransferCollection';
  items: Array<Maybe<MoneyTransfer>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type MoneyTransferFilter = {
  AND?: InputMaybe<Array<InputMaybe<MoneyTransferFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MoneyTransferFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  paymentIconsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  paymentText?: InputMaybe<Scalars['String']['input']>;
  paymentText_contains?: InputMaybe<Scalars['String']['input']>;
  paymentText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  paymentText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  paymentText_not?: InputMaybe<Scalars['String']['input']>;
  paymentText_not_contains?: InputMaybe<Scalars['String']['input']>;
  paymentText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  richHeader_contains?: InputMaybe<Scalars['String']['input']>;
  richHeader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  richHeader_not_contains?: InputMaybe<Scalars['String']['input']>;
  richSubheader_contains?: InputMaybe<Scalars['String']['input']>;
  richSubheader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  richSubheader_not_contains?: InputMaybe<Scalars['String']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subheader?: InputMaybe<Scalars['String']['input']>;
  subheader_contains?: InputMaybe<Scalars['String']['input']>;
  subheader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subheader_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subheader_not?: InputMaybe<Scalars['String']['input']>;
  subheader_not_contains?: InputMaybe<Scalars['String']['input']>;
  subheader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  transferCards?: InputMaybe<CfMoneyTransferCardNestedFilter>;
  transferCardsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MoneyTransferLinkingCollections = {
  __typename?: 'MoneyTransferLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type MoneyTransferLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum MoneyTransferOrder {
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PaymentTextAsc = 'paymentText_ASC',
  PaymentTextDesc = 'paymentText_DESC',
  SubheaderAsc = 'subheader_ASC',
  SubheaderDesc = 'subheader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type MoneyTransferRichHeader = {
  __typename?: 'MoneyTransferRichHeader';
  json: Scalars['JSON']['output'];
  links: MoneyTransferRichHeaderLinks;
};

export type MoneyTransferRichHeaderAssets = {
  __typename?: 'MoneyTransferRichHeaderAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type MoneyTransferRichHeaderEntries = {
  __typename?: 'MoneyTransferRichHeaderEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type MoneyTransferRichHeaderLinks = {
  __typename?: 'MoneyTransferRichHeaderLinks';
  assets: MoneyTransferRichHeaderAssets;
  entries: MoneyTransferRichHeaderEntries;
  resources: MoneyTransferRichHeaderResources;
};

export type MoneyTransferRichHeaderResources = {
  __typename?: 'MoneyTransferRichHeaderResources';
  block: Array<MoneyTransferRichHeaderResourcesBlock>;
  hyperlink: Array<MoneyTransferRichHeaderResourcesHyperlink>;
  inline: Array<MoneyTransferRichHeaderResourcesInline>;
};

export type MoneyTransferRichHeaderResourcesBlock = ResourceLink & {
  __typename?: 'MoneyTransferRichHeaderResourcesBlock';
  sys: ResourceSys;
};

export type MoneyTransferRichHeaderResourcesHyperlink = ResourceLink & {
  __typename?: 'MoneyTransferRichHeaderResourcesHyperlink';
  sys: ResourceSys;
};

export type MoneyTransferRichHeaderResourcesInline = ResourceLink & {
  __typename?: 'MoneyTransferRichHeaderResourcesInline';
  sys: ResourceSys;
};

export type MoneyTransferRichSubheader = {
  __typename?: 'MoneyTransferRichSubheader';
  json: Scalars['JSON']['output'];
  links: MoneyTransferRichSubheaderLinks;
};

export type MoneyTransferRichSubheaderAssets = {
  __typename?: 'MoneyTransferRichSubheaderAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type MoneyTransferRichSubheaderEntries = {
  __typename?: 'MoneyTransferRichSubheaderEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type MoneyTransferRichSubheaderLinks = {
  __typename?: 'MoneyTransferRichSubheaderLinks';
  assets: MoneyTransferRichSubheaderAssets;
  entries: MoneyTransferRichSubheaderEntries;
  resources: MoneyTransferRichSubheaderResources;
};

export type MoneyTransferRichSubheaderResources = {
  __typename?: 'MoneyTransferRichSubheaderResources';
  block: Array<MoneyTransferRichSubheaderResourcesBlock>;
  hyperlink: Array<MoneyTransferRichSubheaderResourcesHyperlink>;
  inline: Array<MoneyTransferRichSubheaderResourcesInline>;
};

export type MoneyTransferRichSubheaderResourcesBlock = ResourceLink & {
  __typename?: 'MoneyTransferRichSubheaderResourcesBlock';
  sys: ResourceSys;
};

export type MoneyTransferRichSubheaderResourcesHyperlink = ResourceLink & {
  __typename?: 'MoneyTransferRichSubheaderResourcesHyperlink';
  sys: ResourceSys;
};

export type MoneyTransferRichSubheaderResourcesInline = ResourceLink & {
  __typename?: 'MoneyTransferRichSubheaderResourcesInline';
  sys: ResourceSys;
};

export type MoneyTransferTransferCardsCollection = {
  __typename?: 'MoneyTransferTransferCardsCollection';
  items: Array<Maybe<MoneyTransferCard>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum MoneyTransferTransferCardsCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PayoutMethodIdAsc = 'payoutMethodId_ASC',
  PayoutMethodIdDesc = 'payoutMethodId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Module with the content in the not found page (404) [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundContent) */
export type NotFoundContent = Entry &
  _Node & {
    __typename?: 'NotFoundContent';
    _id: Scalars['ID']['output'];
    buttonsCollection?: Maybe<NotFoundContentButtonsCollection>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<NotFoundContentLinkingCollections>;
    message?: Maybe<NotFoundContentMessage>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** Module with the content in the not found page (404) [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundContent) */
export type NotFoundContentButtonsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<NotFoundContentButtonsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** Module with the content in the not found page (404) [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundContent) */
export type NotFoundContentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Module with the content in the not found page (404) [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundContent) */
export type NotFoundContentMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Module with the content in the not found page (404) [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundContent) */
export type NotFoundContentNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type NotFoundContentButtonsCollection = {
  __typename?: 'NotFoundContentButtonsCollection';
  items: Array<Maybe<Link>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum NotFoundContentButtonsCollectionOrder {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  GaTrackNameAsc = 'gaTrackName_ASC',
  GaTrackNameDesc = 'gaTrackName_DESC',
  HighlightedAsc = 'highlighted_ASC',
  HighlightedDesc = 'highlighted_DESC',
  IsDisplayedWhenLoggedInAsc = 'isDisplayedWhenLoggedIn_ASC',
  IsDisplayedWhenLoggedInDesc = 'isDisplayedWhenLoggedIn_DESC',
  IsInternalAsc = 'isInternal_ASC',
  IsInternalDesc = 'isInternal_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  MobileMenuToggleAsc = 'mobileMenuToggle_ASC',
  MobileMenuToggleDesc = 'mobileMenuToggle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoFollowAsc = 'noFollow_ASC',
  NoFollowDesc = 'noFollow_DESC',
  OpenInNewTabAsc = 'openInNewTab_ASC',
  OpenInNewTabDesc = 'openInNewTab_DESC',
  RedirectToLegacyProjectAsc = 'redirectToLegacyProject_ASC',
  RedirectToLegacyProjectDesc = 'redirectToLegacyProject_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnderlineAsc = 'underline_ASC',
  UnderlineDesc = 'underline_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

export type NotFoundContentCollection = {
  __typename?: 'NotFoundContentCollection';
  items: Array<Maybe<NotFoundContent>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type NotFoundContentFilter = {
  AND?: InputMaybe<Array<InputMaybe<NotFoundContentFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<NotFoundContentFilter>>>;
  buttons?: InputMaybe<CfLinkNestedFilter>;
  buttonsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  message_contains?: InputMaybe<Scalars['String']['input']>;
  message_exists?: InputMaybe<Scalars['Boolean']['input']>;
  message_not_contains?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type NotFoundContentLinkingCollections = {
  __typename?: 'NotFoundContentLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type NotFoundContentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type NotFoundContentMessage = {
  __typename?: 'NotFoundContentMessage';
  json: Scalars['JSON']['output'];
  links: NotFoundContentMessageLinks;
};

export type NotFoundContentMessageAssets = {
  __typename?: 'NotFoundContentMessageAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type NotFoundContentMessageEntries = {
  __typename?: 'NotFoundContentMessageEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type NotFoundContentMessageLinks = {
  __typename?: 'NotFoundContentMessageLinks';
  assets: NotFoundContentMessageAssets;
  entries: NotFoundContentMessageEntries;
  resources: NotFoundContentMessageResources;
};

export type NotFoundContentMessageResources = {
  __typename?: 'NotFoundContentMessageResources';
  block: Array<NotFoundContentMessageResourcesBlock>;
  hyperlink: Array<NotFoundContentMessageResourcesHyperlink>;
  inline: Array<NotFoundContentMessageResourcesInline>;
};

export type NotFoundContentMessageResourcesBlock = ResourceLink & {
  __typename?: 'NotFoundContentMessageResourcesBlock';
  sys: ResourceSys;
};

export type NotFoundContentMessageResourcesHyperlink = ResourceLink & {
  __typename?: 'NotFoundContentMessageResourcesHyperlink';
  sys: ResourceSys;
};

export type NotFoundContentMessageResourcesInline = ResourceLink & {
  __typename?: 'NotFoundContentMessageResourcesInline';
  sys: ResourceSys;
};

export enum NotFoundContentOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [web-processes] 404 page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundErrorPage) */
export type NotFoundErrorPage = Entry &
  _Node & {
    __typename?: 'NotFoundErrorPage';
    _id: Scalars['ID']['output'];
    buildLocales?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<NotFoundErrorPageDescription>;
    header?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<NotFoundErrorPageLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    subDescription?: Maybe<NotFoundErrorPageSubDescription>;
    sys: Sys;
  };

/** [web-processes] 404 page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundErrorPage) */
export type NotFoundErrorPageBuildLocalesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] 404 page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundErrorPage) */
export type NotFoundErrorPageDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] 404 page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundErrorPage) */
export type NotFoundErrorPageHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] 404 page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundErrorPage) */
export type NotFoundErrorPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-processes] 404 page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundErrorPage) */
export type NotFoundErrorPageNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] 404 page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundErrorPage) */
export type NotFoundErrorPageSubDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type NotFoundErrorPageCollection = {
  __typename?: 'NotFoundErrorPageCollection';
  items: Array<Maybe<NotFoundErrorPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type NotFoundErrorPageDescription = {
  __typename?: 'NotFoundErrorPageDescription';
  json: Scalars['JSON']['output'];
  links: NotFoundErrorPageDescriptionLinks;
};

export type NotFoundErrorPageDescriptionAssets = {
  __typename?: 'NotFoundErrorPageDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type NotFoundErrorPageDescriptionEntries = {
  __typename?: 'NotFoundErrorPageDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type NotFoundErrorPageDescriptionLinks = {
  __typename?: 'NotFoundErrorPageDescriptionLinks';
  assets: NotFoundErrorPageDescriptionAssets;
  entries: NotFoundErrorPageDescriptionEntries;
  resources: NotFoundErrorPageDescriptionResources;
};

export type NotFoundErrorPageDescriptionResources = {
  __typename?: 'NotFoundErrorPageDescriptionResources';
  block: Array<NotFoundErrorPageDescriptionResourcesBlock>;
  hyperlink: Array<NotFoundErrorPageDescriptionResourcesHyperlink>;
  inline: Array<NotFoundErrorPageDescriptionResourcesInline>;
};

export type NotFoundErrorPageDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'NotFoundErrorPageDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type NotFoundErrorPageDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'NotFoundErrorPageDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type NotFoundErrorPageDescriptionResourcesInline = ResourceLink & {
  __typename?: 'NotFoundErrorPageDescriptionResourcesInline';
  sys: ResourceSys;
};

export type NotFoundErrorPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<NotFoundErrorPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<NotFoundErrorPageFilter>>>;
  buildLocales_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subDescription_contains?: InputMaybe<Scalars['String']['input']>;
  subDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type NotFoundErrorPageLinkingCollections = {
  __typename?: 'NotFoundErrorPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type NotFoundErrorPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum NotFoundErrorPageOrder {
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type NotFoundErrorPageSubDescription = {
  __typename?: 'NotFoundErrorPageSubDescription';
  json: Scalars['JSON']['output'];
  links: NotFoundErrorPageSubDescriptionLinks;
};

export type NotFoundErrorPageSubDescriptionAssets = {
  __typename?: 'NotFoundErrorPageSubDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type NotFoundErrorPageSubDescriptionEntries = {
  __typename?: 'NotFoundErrorPageSubDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type NotFoundErrorPageSubDescriptionLinks = {
  __typename?: 'NotFoundErrorPageSubDescriptionLinks';
  assets: NotFoundErrorPageSubDescriptionAssets;
  entries: NotFoundErrorPageSubDescriptionEntries;
  resources: NotFoundErrorPageSubDescriptionResources;
};

export type NotFoundErrorPageSubDescriptionResources = {
  __typename?: 'NotFoundErrorPageSubDescriptionResources';
  block: Array<NotFoundErrorPageSubDescriptionResourcesBlock>;
  hyperlink: Array<NotFoundErrorPageSubDescriptionResourcesHyperlink>;
  inline: Array<NotFoundErrorPageSubDescriptionResourcesInline>;
};

export type NotFoundErrorPageSubDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'NotFoundErrorPageSubDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type NotFoundErrorPageSubDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'NotFoundErrorPageSubDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type NotFoundErrorPageSubDescriptionResourcesInline = ResourceLink & {
  __typename?: 'NotFoundErrorPageSubDescriptionResourcesInline';
  sys: ResourceSys;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundPage) */
export type NotFoundPage = Entry &
  _Node & {
    __typename?: 'NotFoundPage';
    _id: Scalars['ID']['output'];
    analyticsPageName?: Maybe<Scalars['String']['output']>;
    analyticsPageType?: Maybe<Scalars['String']['output']>;
    buildLocales?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    buttonsCollection?: Maybe<NotFoundPageButtonsCollection>;
    contentfulMetadata: ContentfulMetadata;
    footer?: Maybe<LayoutFooter>;
    heading?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<NotFoundPageLinkingCollections>;
    message?: Maybe<NotFoundPageMessage>;
    metaDescription?: Maybe<Scalars['String']['output']>;
    metaTitle?: Maybe<Scalars['String']['output']>;
    modulesCollection?: Maybe<NotFoundPageModulesCollection>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
    titleImage?: Maybe<Asset>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundPage) */
export type NotFoundPageAnalyticsPageNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundPage) */
export type NotFoundPageAnalyticsPageTypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundPage) */
export type NotFoundPageBuildLocalesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundPage) */
export type NotFoundPageButtonsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<NotFoundPageButtonsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundPage) */
export type NotFoundPageFooterArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LayoutFooterFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundPage) */
export type NotFoundPageHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundPage) */
export type NotFoundPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundPage) */
export type NotFoundPageMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundPage) */
export type NotFoundPageMetaDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundPage) */
export type NotFoundPageMetaTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundPage) */
export type NotFoundPageModulesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundPage) */
export type NotFoundPageNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundPage) */
export type NotFoundPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/notFoundPage) */
export type NotFoundPageTitleImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NotFoundPageButtonsCollection = {
  __typename?: 'NotFoundPageButtonsCollection';
  items: Array<Maybe<Link>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum NotFoundPageButtonsCollectionOrder {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  GaTrackNameAsc = 'gaTrackName_ASC',
  GaTrackNameDesc = 'gaTrackName_DESC',
  HighlightedAsc = 'highlighted_ASC',
  HighlightedDesc = 'highlighted_DESC',
  IsDisplayedWhenLoggedInAsc = 'isDisplayedWhenLoggedIn_ASC',
  IsDisplayedWhenLoggedInDesc = 'isDisplayedWhenLoggedIn_DESC',
  IsInternalAsc = 'isInternal_ASC',
  IsInternalDesc = 'isInternal_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  MobileMenuToggleAsc = 'mobileMenuToggle_ASC',
  MobileMenuToggleDesc = 'mobileMenuToggle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoFollowAsc = 'noFollow_ASC',
  NoFollowDesc = 'noFollow_DESC',
  OpenInNewTabAsc = 'openInNewTab_ASC',
  OpenInNewTabDesc = 'openInNewTab_DESC',
  RedirectToLegacyProjectAsc = 'redirectToLegacyProject_ASC',
  RedirectToLegacyProjectDesc = 'redirectToLegacyProject_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnderlineAsc = 'underline_ASC',
  UnderlineDesc = 'underline_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

export type NotFoundPageCollection = {
  __typename?: 'NotFoundPageCollection';
  items: Array<Maybe<NotFoundPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type NotFoundPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<NotFoundPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<NotFoundPageFilter>>>;
  analyticsPageName?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  analyticsPageName_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  analyticsPageName_not?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_not_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  analyticsPageType?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  analyticsPageType_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  analyticsPageType_not?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_not_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_exists?: InputMaybe<Scalars['Boolean']['input']>;
  buttons?: InputMaybe<CfLinkNestedFilter>;
  buttonsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  footer?: InputMaybe<CfLayoutFooterNestedFilter>;
  footer_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Scalars['String']['input']>;
  heading_contains?: InputMaybe<Scalars['String']['input']>;
  heading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heading_not?: InputMaybe<Scalars['String']['input']>;
  heading_not_contains?: InputMaybe<Scalars['String']['input']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  message_contains?: InputMaybe<Scalars['String']['input']>;
  message_exists?: InputMaybe<Scalars['Boolean']['input']>;
  message_not_contains?: InputMaybe<Scalars['String']['input']>;
  metaDescription?: InputMaybe<Scalars['String']['input']>;
  metaDescription_contains?: InputMaybe<Scalars['String']['input']>;
  metaDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  metaDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  metaDescription_not?: InputMaybe<Scalars['String']['input']>;
  metaDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  metaDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  metaTitle?: InputMaybe<Scalars['String']['input']>;
  metaTitle_contains?: InputMaybe<Scalars['String']['input']>;
  metaTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  metaTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  metaTitle_not?: InputMaybe<Scalars['String']['input']>;
  metaTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  metaTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  modulesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type NotFoundPageLinkingCollections = {
  __typename?: 'NotFoundPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type NotFoundPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type NotFoundPageMessage = {
  __typename?: 'NotFoundPageMessage';
  json: Scalars['JSON']['output'];
  links: NotFoundPageMessageLinks;
};

export type NotFoundPageMessageAssets = {
  __typename?: 'NotFoundPageMessageAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type NotFoundPageMessageEntries = {
  __typename?: 'NotFoundPageMessageEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type NotFoundPageMessageLinks = {
  __typename?: 'NotFoundPageMessageLinks';
  assets: NotFoundPageMessageAssets;
  entries: NotFoundPageMessageEntries;
  resources: NotFoundPageMessageResources;
};

export type NotFoundPageMessageResources = {
  __typename?: 'NotFoundPageMessageResources';
  block: Array<NotFoundPageMessageResourcesBlock>;
  hyperlink: Array<NotFoundPageMessageResourcesHyperlink>;
  inline: Array<NotFoundPageMessageResourcesInline>;
};

export type NotFoundPageMessageResourcesBlock = ResourceLink & {
  __typename?: 'NotFoundPageMessageResourcesBlock';
  sys: ResourceSys;
};

export type NotFoundPageMessageResourcesHyperlink = ResourceLink & {
  __typename?: 'NotFoundPageMessageResourcesHyperlink';
  sys: ResourceSys;
};

export type NotFoundPageMessageResourcesInline = ResourceLink & {
  __typename?: 'NotFoundPageMessageResourcesInline';
  sys: ResourceSys;
};

export type NotFoundPageModulesCollection = {
  __typename?: 'NotFoundPageModulesCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum NotFoundPageOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  MetaDescriptionAsc = 'metaDescription_ASC',
  MetaDescriptionDesc = 'metaDescription_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/onfidoErrorPage) */
export type OnfidoErrorPage = Entry &
  _Node & {
    __typename?: 'OnfidoErrorPage';
    _id: Scalars['ID']['output'];
    button?: Maybe<Link>;
    contactInfo?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    hardSubheader?: Maybe<Scalars['String']['output']>;
    header?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<OnfidoErrorPageLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    phone?: Maybe<Scalars['String']['output']>;
    settings?: Maybe<Scalars['String']['output']>;
    softSubheader?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/onfidoErrorPage) */
export type OnfidoErrorPageButtonArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/onfidoErrorPage) */
export type OnfidoErrorPageContactInfoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/onfidoErrorPage) */
export type OnfidoErrorPageHardSubheaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/onfidoErrorPage) */
export type OnfidoErrorPageHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/onfidoErrorPage) */
export type OnfidoErrorPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/onfidoErrorPage) */
export type OnfidoErrorPageNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/onfidoErrorPage) */
export type OnfidoErrorPagePhoneArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/onfidoErrorPage) */
export type OnfidoErrorPageSettingsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/onfidoErrorPage) */
export type OnfidoErrorPageSoftSubheaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type OnfidoErrorPageCollection = {
  __typename?: 'OnfidoErrorPageCollection';
  items: Array<Maybe<OnfidoErrorPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type OnfidoErrorPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<OnfidoErrorPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<OnfidoErrorPageFilter>>>;
  button?: InputMaybe<CfLinkNestedFilter>;
  button_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contactInfo?: InputMaybe<Scalars['String']['input']>;
  contactInfo_contains?: InputMaybe<Scalars['String']['input']>;
  contactInfo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contactInfo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contactInfo_not?: InputMaybe<Scalars['String']['input']>;
  contactInfo_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactInfo_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  hardSubheader?: InputMaybe<Scalars['String']['input']>;
  hardSubheader_contains?: InputMaybe<Scalars['String']['input']>;
  hardSubheader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hardSubheader_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hardSubheader_not?: InputMaybe<Scalars['String']['input']>;
  hardSubheader_not_contains?: InputMaybe<Scalars['String']['input']>;
  hardSubheader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phone_contains?: InputMaybe<Scalars['String']['input']>;
  phone_exists?: InputMaybe<Scalars['Boolean']['input']>;
  phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phone_not?: InputMaybe<Scalars['String']['input']>;
  phone_not_contains?: InputMaybe<Scalars['String']['input']>;
  phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  settings?: InputMaybe<Scalars['String']['input']>;
  settings_contains?: InputMaybe<Scalars['String']['input']>;
  settings_exists?: InputMaybe<Scalars['Boolean']['input']>;
  settings_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  settings_not?: InputMaybe<Scalars['String']['input']>;
  settings_not_contains?: InputMaybe<Scalars['String']['input']>;
  settings_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  softSubheader?: InputMaybe<Scalars['String']['input']>;
  softSubheader_contains?: InputMaybe<Scalars['String']['input']>;
  softSubheader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  softSubheader_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  softSubheader_not?: InputMaybe<Scalars['String']['input']>;
  softSubheader_not_contains?: InputMaybe<Scalars['String']['input']>;
  softSubheader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
};

export type OnfidoErrorPageLinkingCollections = {
  __typename?: 'OnfidoErrorPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type OnfidoErrorPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum OnfidoErrorPageOrder {
  ContactInfoAsc = 'contactInfo_ASC',
  ContactInfoDesc = 'contactInfo_DESC',
  HardSubheaderAsc = 'hardSubheader_ASC',
  HardSubheaderDesc = 'hardSubheader_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  SettingsAsc = 'settings_ASC',
  SettingsDesc = 'settings_DESC',
  SoftSubheaderAsc = 'softSubheader_ASC',
  SoftSubheaderDesc = 'softSubheader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/ourContactInformation) */
export type OurContactInformation = Entry &
  _Node & {
    __typename?: 'OurContactInformation';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    header?: Maybe<Scalars['String']['output']>;
    headquartersAccordionContentCollection?: Maybe<OurContactInformationHeadquartersAccordionContentCollection>;
    headquartersAccordionTitle?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<OurContactInformationLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    numbersAccordionContent?: Maybe<OurContactInformationNumbersAccordionContent>;
    numbersAccordionTitle?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/ourContactInformation) */
export type OurContactInformationHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/ourContactInformation) */
export type OurContactInformationHeadquartersAccordionContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/ourContactInformation) */
export type OurContactInformationHeadquartersAccordionTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/ourContactInformation) */
export type OurContactInformationLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/ourContactInformation) */
export type OurContactInformationNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/ourContactInformation) */
export type OurContactInformationNumbersAccordionContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/ourContactInformation) */
export type OurContactInformationNumbersAccordionTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type OurContactInformationCollection = {
  __typename?: 'OurContactInformationCollection';
  items: Array<Maybe<OurContactInformation>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type OurContactInformationFilter = {
  AND?: InputMaybe<Array<InputMaybe<OurContactInformationFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<OurContactInformationFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headquartersAccordionContentCollection_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  headquartersAccordionTitle?: InputMaybe<Scalars['String']['input']>;
  headquartersAccordionTitle_contains?: InputMaybe<Scalars['String']['input']>;
  headquartersAccordionTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headquartersAccordionTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  headquartersAccordionTitle_not?: InputMaybe<Scalars['String']['input']>;
  headquartersAccordionTitle_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  headquartersAccordionTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  numbersAccordionContent_contains?: InputMaybe<Scalars['String']['input']>;
  numbersAccordionContent_exists?: InputMaybe<Scalars['Boolean']['input']>;
  numbersAccordionContent_not_contains?: InputMaybe<Scalars['String']['input']>;
  numbersAccordionTitle?: InputMaybe<Scalars['String']['input']>;
  numbersAccordionTitle_contains?: InputMaybe<Scalars['String']['input']>;
  numbersAccordionTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  numbersAccordionTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  numbersAccordionTitle_not?: InputMaybe<Scalars['String']['input']>;
  numbersAccordionTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  numbersAccordionTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
};

export type OurContactInformationHeadquartersAccordionContentCollection = {
  __typename?: 'OurContactInformationHeadquartersAccordionContentCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type OurContactInformationLinkingCollections = {
  __typename?: 'OurContactInformationLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type OurContactInformationLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type OurContactInformationNumbersAccordionContent = {
  __typename?: 'OurContactInformationNumbersAccordionContent';
  json: Scalars['JSON']['output'];
  links: OurContactInformationNumbersAccordionContentLinks;
};

export type OurContactInformationNumbersAccordionContentAssets = {
  __typename?: 'OurContactInformationNumbersAccordionContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type OurContactInformationNumbersAccordionContentEntries = {
  __typename?: 'OurContactInformationNumbersAccordionContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type OurContactInformationNumbersAccordionContentLinks = {
  __typename?: 'OurContactInformationNumbersAccordionContentLinks';
  assets: OurContactInformationNumbersAccordionContentAssets;
  entries: OurContactInformationNumbersAccordionContentEntries;
  resources: OurContactInformationNumbersAccordionContentResources;
};

export type OurContactInformationNumbersAccordionContentResources = {
  __typename?: 'OurContactInformationNumbersAccordionContentResources';
  block: Array<OurContactInformationNumbersAccordionContentResourcesBlock>;
  hyperlink: Array<OurContactInformationNumbersAccordionContentResourcesHyperlink>;
  inline: Array<OurContactInformationNumbersAccordionContentResourcesInline>;
};

export type OurContactInformationNumbersAccordionContentResourcesBlock = ResourceLink & {
  __typename?: 'OurContactInformationNumbersAccordionContentResourcesBlock';
  sys: ResourceSys;
};

export type OurContactInformationNumbersAccordionContentResourcesHyperlink = ResourceLink & {
  __typename?: 'OurContactInformationNumbersAccordionContentResourcesHyperlink';
  sys: ResourceSys;
};

export type OurContactInformationNumbersAccordionContentResourcesInline = ResourceLink & {
  __typename?: 'OurContactInformationNumbersAccordionContentResourcesInline';
  sys: ResourceSys;
};

export enum OurContactInformationOrder {
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  HeadquartersAccordionTitleAsc = 'headquartersAccordionTitle_ASC',
  HeadquartersAccordionTitleDesc = 'headquartersAccordionTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NumbersAccordionTitleAsc = 'numbersAccordionTitle_ASC',
  NumbersAccordionTitleDesc = 'numbersAccordionTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type Page = Entry &
  _Node & {
    __typename?: 'Page';
    _id: Scalars['ID']['output'];
    analyticsPageName?: Maybe<Scalars['String']['output']>;
    analyticsPageType?: Maybe<Scalars['String']['output']>;
    author?: Maybe<Person>;
    banner?: Maybe<Asset>;
    content?: Maybe<PageContent>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    footer?: Maybe<LayoutFooter>;
    headerPosition?: Maybe<Scalars['String']['output']>;
    headerTheme?: Maybe<Scalars['String']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<PageLinkingCollections>;
    modulesCollection?: Maybe<PageModulesCollection>;
    name?: Maybe<Scalars['String']['output']>;
    noIndex?: Maybe<Scalars['Boolean']['output']>;
    ogImage?: Maybe<Asset>;
    publishedDate?: Maybe<Scalars['DateTime']['output']>;
    regions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    relatedPagesCollection?: Maybe<PageRelatedPagesCollection>;
    sitemap?: Maybe<Scalars['Boolean']['output']>;
    sitemapTitle?: Maybe<Scalars['String']['output']>;
    slug?: Maybe<Scalars['String']['output']>;
    submenuItemsCollection?: Maybe<PageSubmenuItemsCollection>;
    sys: Sys;
    timeToRead?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    updatedDate?: Maybe<Scalars['DateTime']['output']>;
  };

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type PageAnalyticsPageNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type PageAnalyticsPageTypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type PageAuthorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PersonFilter>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type PageBannerArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type PageContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type PageDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type PageFooterArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LayoutFooterFilter>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type PageHeaderPositionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type PageHeaderThemeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type PageHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type PageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type PageModulesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type PageNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type PageNoIndexArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type PageOgImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type PagePublishedDateArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type PageRegionsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type PageRelatedPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageRelatedPagesCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PageFilter>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type PageSitemapArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type PageSitemapTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type PageSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type PageSubmenuItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageSubmenuItemsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type PageTimeToReadArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type PageTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/page) */
export type PageUpdatedDateArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type PageCollection = {
  __typename?: 'PageCollection';
  items: Array<Maybe<Page>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageContent = {
  __typename?: 'PageContent';
  json: Scalars['JSON']['output'];
  links: PageContentLinks;
};

export type PageContentAssets = {
  __typename?: 'PageContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type PageContentEntries = {
  __typename?: 'PageContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type PageContentLinks = {
  __typename?: 'PageContentLinks';
  assets: PageContentAssets;
  entries: PageContentEntries;
  resources: PageContentResources;
};

export type PageContentResources = {
  __typename?: 'PageContentResources';
  block: Array<PageContentResourcesBlock>;
  hyperlink: Array<PageContentResourcesHyperlink>;
  inline: Array<PageContentResourcesInline>;
};

export type PageContentResourcesBlock = ResourceLink & {
  __typename?: 'PageContentResourcesBlock';
  sys: ResourceSys;
};

export type PageContentResourcesHyperlink = ResourceLink & {
  __typename?: 'PageContentResourcesHyperlink';
  sys: ResourceSys;
};

export type PageContentResourcesInline = ResourceLink & {
  __typename?: 'PageContentResourcesInline';
  sys: ResourceSys;
};

export type PageFilter = {
  AND?: InputMaybe<Array<InputMaybe<PageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PageFilter>>>;
  analyticsPageName?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  analyticsPageName_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  analyticsPageName_not?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_not_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  analyticsPageType?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  analyticsPageType_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  analyticsPageType_not?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_not_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  author?: InputMaybe<CfPersonNestedFilter>;
  author_exists?: InputMaybe<Scalars['Boolean']['input']>;
  banner_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_contains?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  footer?: InputMaybe<CfLayoutFooterNestedFilter>;
  footer_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headerPosition?: InputMaybe<Scalars['String']['input']>;
  headerPosition_contains?: InputMaybe<Scalars['String']['input']>;
  headerPosition_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headerPosition_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headerPosition_not?: InputMaybe<Scalars['String']['input']>;
  headerPosition_not_contains?: InputMaybe<Scalars['String']['input']>;
  headerPosition_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  headerTheme?: InputMaybe<Scalars['String']['input']>;
  headerTheme_contains?: InputMaybe<Scalars['String']['input']>;
  headerTheme_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headerTheme_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headerTheme_not?: InputMaybe<Scalars['String']['input']>;
  headerTheme_not_contains?: InputMaybe<Scalars['String']['input']>;
  headerTheme_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  heading?: InputMaybe<Scalars['String']['input']>;
  heading_contains?: InputMaybe<Scalars['String']['input']>;
  heading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heading_not?: InputMaybe<Scalars['String']['input']>;
  heading_not_contains?: InputMaybe<Scalars['String']['input']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  modulesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noIndex?: InputMaybe<Scalars['Boolean']['input']>;
  noIndex_exists?: InputMaybe<Scalars['Boolean']['input']>;
  noIndex_not?: InputMaybe<Scalars['Boolean']['input']>;
  ogImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  publishedDate_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publishedDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  publishedDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  publishedDate_in?: InputMaybe<
    Array<InputMaybe<Scalars['DateTime']['input']>>
  >;
  publishedDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  publishedDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  publishedDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  publishedDate_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['DateTime']['input']>>
  >;
  regions_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  regions_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  regions_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  regions_exists?: InputMaybe<Scalars['Boolean']['input']>;
  relatedPages?: InputMaybe<CfPageNestedFilter>;
  relatedPagesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sitemap?: InputMaybe<Scalars['Boolean']['input']>;
  sitemapTitle?: InputMaybe<Scalars['String']['input']>;
  sitemapTitle_contains?: InputMaybe<Scalars['String']['input']>;
  sitemapTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sitemapTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sitemapTitle_not?: InputMaybe<Scalars['String']['input']>;
  sitemapTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  sitemapTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sitemap_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sitemap_not?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  submenuItems?: InputMaybe<CfLinkNestedFilter>;
  submenuItemsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  timeToRead?: InputMaybe<Scalars['String']['input']>;
  timeToRead_contains?: InputMaybe<Scalars['String']['input']>;
  timeToRead_exists?: InputMaybe<Scalars['Boolean']['input']>;
  timeToRead_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  timeToRead_not?: InputMaybe<Scalars['String']['input']>;
  timeToRead_not_contains?: InputMaybe<Scalars['String']['input']>;
  timeToRead_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updatedDate?: InputMaybe<Scalars['DateTime']['input']>;
  updatedDate_exists?: InputMaybe<Scalars['Boolean']['input']>;
  updatedDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedDate_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['DateTime']['input']>>
  >;
};

export type PageLinkingCollections = {
  __typename?: 'PageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  faqPopularQuestionsCollection?: Maybe<FaqPopularQuestionsCollection>;
  faqTopicsModuleItemsCollection?: Maybe<FaqTopicsModuleItemsCollection>;
  logoCollection?: Maybe<LogoCollection>;
  pageCollection?: Maybe<PageCollection>;
  searchCollection?: Maybe<SearchCollection>;
};

export type PageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PageLinkingCollectionsFaqPopularQuestionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<PageLinkingCollectionsFaqPopularQuestionsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PageLinkingCollectionsFaqTopicsModuleItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<PageLinkingCollectionsFaqTopicsModuleItemsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PageLinkingCollectionsLogoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<PageLinkingCollectionsLogoCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PageLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<PageLinkingCollectionsPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PageLinkingCollectionsSearchCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<PageLinkingCollectionsSearchCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum PageLinkingCollectionsFaqPopularQuestionsCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PageLinkingCollectionsFaqTopicsModuleItemsCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PageLinkingCollectionsLogoCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PageLinkingCollectionsPageCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderPositionAsc = 'headerPosition_ASC',
  HeaderPositionDesc = 'headerPosition_DESC',
  HeaderThemeAsc = 'headerTheme_ASC',
  HeaderThemeDesc = 'headerTheme_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimeToReadAsc = 'timeToRead_ASC',
  TimeToReadDesc = 'timeToRead_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export enum PageLinkingCollectionsSearchCollectionOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  InputLabelAsc = 'inputLabel_ASC',
  InputLabelDesc = 'inputLabel_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ResultListLabelAsc = 'resultListLabel_ASC',
  ResultListLabelDesc = 'resultListLabel_DESC',
  SuggestionListLabelAsc = 'suggestionListLabel_ASC',
  SuggestionListLabelDesc = 'suggestionListLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PageModulesCollection = {
  __typename?: 'PageModulesCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum PageOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderPositionAsc = 'headerPosition_ASC',
  HeaderPositionDesc = 'headerPosition_DESC',
  HeaderThemeAsc = 'headerTheme_ASC',
  HeaderThemeDesc = 'headerTheme_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimeToReadAsc = 'timeToRead_ASC',
  TimeToReadDesc = 'timeToRead_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export type PageRelatedPagesCollection = {
  __typename?: 'PageRelatedPagesCollection';
  items: Array<Maybe<Page>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum PageRelatedPagesCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderPositionAsc = 'headerPosition_ASC',
  HeaderPositionDesc = 'headerPosition_DESC',
  HeaderThemeAsc = 'headerTheme_ASC',
  HeaderThemeDesc = 'headerTheme_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimeToReadAsc = 'timeToRead_ASC',
  TimeToReadDesc = 'timeToRead_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

/** Subsection of page combining multiple different entries in <section> tag with customizable background. This content type allows you to have different body text based on chosen Send From country [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/pageSection) */
export type PageSection = Entry &
  _Node & {
    __typename?: 'PageSection';
    _id: Scalars['ID']['output'];
    backgroundColor?: Maybe<Scalars['String']['output']>;
    backgroundGradient?: Maybe<Scalars['String']['output']>;
    backgroundType?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    countriesFromCollection?: Maybe<PageSectionCountriesFromCollection>;
    enablePadding?: Maybe<Scalars['Boolean']['output']>;
    linkedFrom?: Maybe<PageSectionLinkingCollections>;
    modulesCollection?: Maybe<PageSectionModulesCollection>;
    name?: Maybe<Scalars['String']['output']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sys: Sys;
  };

/** Subsection of page combining multiple different entries in <section> tag with customizable background. This content type allows you to have different body text based on chosen Send From country [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/pageSection) */
export type PageSectionBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Subsection of page combining multiple different entries in <section> tag with customizable background. This content type allows you to have different body text based on chosen Send From country [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/pageSection) */
export type PageSectionBackgroundGradientArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Subsection of page combining multiple different entries in <section> tag with customizable background. This content type allows you to have different body text based on chosen Send From country [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/pageSection) */
export type PageSectionBackgroundTypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Subsection of page combining multiple different entries in <section> tag with customizable background. This content type allows you to have different body text based on chosen Send From country [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/pageSection) */
export type PageSectionCountriesFromCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<PageSectionCountriesFromCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CountryFilter>;
};

/** Subsection of page combining multiple different entries in <section> tag with customizable background. This content type allows you to have different body text based on chosen Send From country [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/pageSection) */
export type PageSectionEnablePaddingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Subsection of page combining multiple different entries in <section> tag with customizable background. This content type allows you to have different body text based on chosen Send From country [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/pageSection) */
export type PageSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Subsection of page combining multiple different entries in <section> tag with customizable background. This content type allows you to have different body text based on chosen Send From country [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/pageSection) */
export type PageSectionModulesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PageSectionModulesFilter>;
};

/** Subsection of page combining multiple different entries in <section> tag with customizable background. This content type allows you to have different body text based on chosen Send From country [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/pageSection) */
export type PageSectionNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Subsection of page combining multiple different entries in <section> tag with customizable background. This content type allows you to have different body text based on chosen Send From country [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/pageSection) */
export type PageSectionReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Subsection of page combining multiple different entries in <section> tag with customizable background. This content type allows you to have different body text based on chosen Send From country [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/pageSection) */
export type PageSectionSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type PageSectionCollection = {
  __typename?: 'PageSectionCollection';
  items: Array<Maybe<PageSection>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageSectionCountriesFromCollection = {
  __typename?: 'PageSectionCountriesFromCollection';
  items: Array<Maybe<Country>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum PageSectionCountriesFromCollectionOrder {
  ArticleAsc = 'article_ASC',
  ArticleDesc = 'article_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrepositionFromAsc = 'prepositionFrom_ASC',
  PrepositionFromDesc = 'prepositionFrom_DESC',
  PrepositionInAsc = 'prepositionIn_ASC',
  PrepositionInDesc = 'prepositionIn_DESC',
  PrepositionToAsc = 'prepositionTo_ASC',
  PrepositionToDesc = 'prepositionTo_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PageSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<PageSectionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PageSectionFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundColor_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundGradient?: InputMaybe<Scalars['String']['input']>;
  backgroundGradient_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundGradient_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundGradient_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundGradient_not?: InputMaybe<Scalars['String']['input']>;
  backgroundGradient_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundGradient_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundType?: InputMaybe<Scalars['String']['input']>;
  backgroundType_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  backgroundType_not?: InputMaybe<Scalars['String']['input']>;
  backgroundType_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundType_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  countriesFrom?: InputMaybe<CfCountryNestedFilter>;
  countriesFromCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  enablePadding?: InputMaybe<Scalars['Boolean']['input']>;
  enablePadding_exists?: InputMaybe<Scalars['Boolean']['input']>;
  enablePadding_not?: InputMaybe<Scalars['Boolean']['input']>;
  modules?: InputMaybe<CfmodulesMultiTypeNestedFilter>;
  modulesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type PageSectionLinkingCollections = {
  __typename?: 'PageSectionLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type PageSectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PageSectionModulesCollection = {
  __typename?: 'PageSectionModulesCollection';
  items: Array<Maybe<PageSectionModulesItem>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageSectionModulesFilter = {
  AND?: InputMaybe<Array<InputMaybe<PageSectionModulesFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PageSectionModulesFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type PageSectionModulesItem =
  | BodyText
  | ComparisonTable
  | IconWithText
  | Reviews
  | StepByStep
  | TextWithColor
  | Video
  | WhyChoose;

export enum PageSectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundGradientAsc = 'backgroundGradient_ASC',
  BackgroundGradientDesc = 'backgroundGradient_DESC',
  BackgroundTypeAsc = 'backgroundType_ASC',
  BackgroundTypeDesc = 'backgroundType_DESC',
  EnablePaddingAsc = 'enablePadding_ASC',
  EnablePaddingDesc = 'enablePadding_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PageSubmenuItemsCollection = {
  __typename?: 'PageSubmenuItemsCollection';
  items: Array<Maybe<Link>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum PageSubmenuItemsCollectionOrder {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  GaTrackNameAsc = 'gaTrackName_ASC',
  GaTrackNameDesc = 'gaTrackName_DESC',
  HighlightedAsc = 'highlighted_ASC',
  HighlightedDesc = 'highlighted_DESC',
  IsDisplayedWhenLoggedInAsc = 'isDisplayedWhenLoggedIn_ASC',
  IsDisplayedWhenLoggedInDesc = 'isDisplayedWhenLoggedIn_DESC',
  IsInternalAsc = 'isInternal_ASC',
  IsInternalDesc = 'isInternal_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  MobileMenuToggleAsc = 'mobileMenuToggle_ASC',
  MobileMenuToggleDesc = 'mobileMenuToggle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoFollowAsc = 'noFollow_ASC',
  NoFollowDesc = 'noFollow_DESC',
  OpenInNewTabAsc = 'openInNewTab_ASC',
  OpenInNewTabDesc = 'openInNewTab_DESC',
  RedirectToLegacyProjectAsc = 'redirectToLegacyProject_ASC',
  RedirectToLegacyProjectDesc = 'redirectToLegacyProject_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnderlineAsc = 'underline_ASC',
  UnderlineDesc = 'underline_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/paymentReason) */
export type PaymentReason = Entry &
  _Node & {
    __typename?: 'PaymentReason';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<PaymentReasonLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    reason?: Maybe<Scalars['String']['output']>;
    reasonId?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/paymentReason) */
export type PaymentReasonLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/paymentReason) */
export type PaymentReasonNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/paymentReason) */
export type PaymentReasonReasonArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/paymentReason) */
export type PaymentReasonReasonIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentReasonCollection = {
  __typename?: 'PaymentReasonCollection';
  items: Array<Maybe<PaymentReason>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaymentReasonFilter = {
  AND?: InputMaybe<Array<InputMaybe<PaymentReasonFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PaymentReasonFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reason?: InputMaybe<Scalars['String']['input']>;
  reasonId?: InputMaybe<Scalars['String']['input']>;
  reasonId_contains?: InputMaybe<Scalars['String']['input']>;
  reasonId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  reasonId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reasonId_not?: InputMaybe<Scalars['String']['input']>;
  reasonId_not_contains?: InputMaybe<Scalars['String']['input']>;
  reasonId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reason_contains?: InputMaybe<Scalars['String']['input']>;
  reason_exists?: InputMaybe<Scalars['Boolean']['input']>;
  reason_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reason_not?: InputMaybe<Scalars['String']['input']>;
  reason_not_contains?: InputMaybe<Scalars['String']['input']>;
  reason_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type PaymentReasonLinkingCollections = {
  __typename?: 'PaymentReasonLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  moneyRequestPaymentReasonCollection?: Maybe<MoneyRequestPaymentReasonCollection>;
};

export type PaymentReasonLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PaymentReasonLinkingCollectionsMoneyRequestPaymentReasonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<PaymentReasonLinkingCollectionsMoneyRequestPaymentReasonCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum PaymentReasonLinkingCollectionsMoneyRequestPaymentReasonCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PaymentReasonOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ReasonIdAsc = 'reasonId_ASC',
  ReasonIdDesc = 'reasonId_DESC',
  ReasonAsc = 'reason_ASC',
  ReasonDesc = 'reason_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethod) */
export type PayoutMethod = Entry &
  _Node & {
    __typename?: 'PayoutMethod';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    cornerGraphic?: Maybe<Asset>;
    correspondentsTitle?: Maybe<Scalars['String']['output']>;
    countriesFromCollection?: Maybe<PayoutMethodCountriesFromCollection>;
    countriesToCollection?: Maybe<PayoutMethodCountriesToCollection>;
    ctaButtonBaseUrl?: Maybe<Scalars['String']['output']>;
    ctaButtonLabel?: Maybe<Scalars['String']['output']>;
    description?: Maybe<Scalars['String']['output']>;
    exchangeRateLabel?: Maybe<Scalars['String']['output']>;
    feesLabel?: Maybe<Scalars['String']['output']>;
    hideExchangeRate?: Maybe<Scalars['Boolean']['output']>;
    icon?: Maybe<Asset>;
    linkedFrom?: Maybe<PayoutMethodLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    payoutMethodId?: Maybe<Scalars['String']['output']>;
    receiveMethodDescription?: Maybe<Scalars['String']['output']>;
    receiveMethodLink?: Maybe<Link>;
    slug?: Maybe<Scalars['String']['output']>;
    supportLinkText?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    transferTimeLabel?: Maybe<Scalars['String']['output']>;
    transferTimeTooltip?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethod) */
export type PayoutMethodCornerGraphicArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethod) */
export type PayoutMethodCorrespondentsTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethod) */
export type PayoutMethodCountriesFromCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<PayoutMethodCountriesFromCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CountryFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethod) */
export type PayoutMethodCountriesToCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<PayoutMethodCountriesToCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CountryFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethod) */
export type PayoutMethodCtaButtonBaseUrlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethod) */
export type PayoutMethodCtaButtonLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethod) */
export type PayoutMethodDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethod) */
export type PayoutMethodExchangeRateLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethod) */
export type PayoutMethodFeesLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethod) */
export type PayoutMethodHideExchangeRateArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethod) */
export type PayoutMethodIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethod) */
export type PayoutMethodLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethod) */
export type PayoutMethodNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethod) */
export type PayoutMethodPayoutMethodIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethod) */
export type PayoutMethodReceiveMethodDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethod) */
export type PayoutMethodReceiveMethodLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethod) */
export type PayoutMethodSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethod) */
export type PayoutMethodSupportLinkTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethod) */
export type PayoutMethodTransferTimeLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethod) */
export type PayoutMethodTransferTimeTooltipArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Alert displayed on Payout Method tab [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethodAlert) */
export type PayoutMethodAlert = Entry &
  _Node & {
    __typename?: 'PayoutMethodAlert';
    _id: Scalars['ID']['output'];
    alertLabel?: Maybe<Scalars['String']['output']>;
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    contentfulMetadata: ContentfulMetadata;
    countriesFromCollection?: Maybe<PayoutMethodAlertCountriesFromCollection>;
    countriesToCollection?: Maybe<PayoutMethodAlertCountriesToCollection>;
    enabled?: Maybe<Scalars['Boolean']['output']>;
    icon?: Maybe<Asset>;
    linkedFrom?: Maybe<PayoutMethodAlertLinkingCollections>;
    message?: Maybe<PayoutMethodAlertMessage>;
    name?: Maybe<Scalars['String']['output']>;
    payoutMethodsCollection?: Maybe<PayoutMethodAlertPayoutMethodsCollection>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Alert displayed on Payout Method tab [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethodAlert) */
export type PayoutMethodAlertAlertLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Alert displayed on Payout Method tab [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethodAlert) */
export type PayoutMethodAlertAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Alert displayed on Payout Method tab [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethodAlert) */
export type PayoutMethodAlertAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Alert displayed on Payout Method tab [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethodAlert) */
export type PayoutMethodAlertCountriesFromCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<PayoutMethodAlertCountriesFromCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CountryFilter>;
};

/** Alert displayed on Payout Method tab [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethodAlert) */
export type PayoutMethodAlertCountriesToCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<PayoutMethodAlertCountriesToCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CountryFilter>;
};

/** Alert displayed on Payout Method tab [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethodAlert) */
export type PayoutMethodAlertEnabledArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Alert displayed on Payout Method tab [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethodAlert) */
export type PayoutMethodAlertIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Alert displayed on Payout Method tab [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethodAlert) */
export type PayoutMethodAlertLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Alert displayed on Payout Method tab [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethodAlert) */
export type PayoutMethodAlertMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Alert displayed on Payout Method tab [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethodAlert) */
export type PayoutMethodAlertNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Alert displayed on Payout Method tab [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethodAlert) */
export type PayoutMethodAlertPayoutMethodsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<PayoutMethodAlertPayoutMethodsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PayoutMethodFilter>;
};

/** Alert displayed on Payout Method tab [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethodAlert) */
export type PayoutMethodAlertReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Alert displayed on Payout Method tab [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethodAlert) */
export type PayoutMethodAlertSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Alert displayed on Payout Method tab [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/payoutMethodAlert) */
export type PayoutMethodAlertTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type PayoutMethodAlertCollection = {
  __typename?: 'PayoutMethodAlertCollection';
  items: Array<Maybe<PayoutMethodAlert>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PayoutMethodAlertCountriesFromCollection = {
  __typename?: 'PayoutMethodAlertCountriesFromCollection';
  items: Array<Maybe<Country>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum PayoutMethodAlertCountriesFromCollectionOrder {
  ArticleAsc = 'article_ASC',
  ArticleDesc = 'article_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrepositionFromAsc = 'prepositionFrom_ASC',
  PrepositionFromDesc = 'prepositionFrom_DESC',
  PrepositionInAsc = 'prepositionIn_ASC',
  PrepositionInDesc = 'prepositionIn_DESC',
  PrepositionToAsc = 'prepositionTo_ASC',
  PrepositionToDesc = 'prepositionTo_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PayoutMethodAlertCountriesToCollection = {
  __typename?: 'PayoutMethodAlertCountriesToCollection';
  items: Array<Maybe<Country>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum PayoutMethodAlertCountriesToCollectionOrder {
  ArticleAsc = 'article_ASC',
  ArticleDesc = 'article_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrepositionFromAsc = 'prepositionFrom_ASC',
  PrepositionFromDesc = 'prepositionFrom_DESC',
  PrepositionInAsc = 'prepositionIn_ASC',
  PrepositionInDesc = 'prepositionIn_DESC',
  PrepositionToAsc = 'prepositionTo_ASC',
  PrepositionToDesc = 'prepositionTo_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PayoutMethodAlertFilter = {
  AND?: InputMaybe<Array<InputMaybe<PayoutMethodAlertFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PayoutMethodAlertFilter>>>;
  alertLabel?: InputMaybe<Scalars['String']['input']>;
  alertLabel_contains?: InputMaybe<Scalars['String']['input']>;
  alertLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  alertLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  alertLabel_not?: InputMaybe<Scalars['String']['input']>;
  alertLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  alertLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  countriesFrom?: InputMaybe<CfCountryNestedFilter>;
  countriesFromCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  countriesTo?: InputMaybe<CfCountryNestedFilter>;
  countriesToCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  enabled_exists?: InputMaybe<Scalars['Boolean']['input']>;
  enabled_not?: InputMaybe<Scalars['Boolean']['input']>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  message_contains?: InputMaybe<Scalars['String']['input']>;
  message_exists?: InputMaybe<Scalars['Boolean']['input']>;
  message_not_contains?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  payoutMethods?: InputMaybe<CfPayoutMethodNestedFilter>;
  payoutMethodsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PayoutMethodAlertLinkingCollections = {
  __typename?: 'PayoutMethodAlertLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type PayoutMethodAlertLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PayoutMethodAlertMessage = {
  __typename?: 'PayoutMethodAlertMessage';
  json: Scalars['JSON']['output'];
  links: PayoutMethodAlertMessageLinks;
};

export type PayoutMethodAlertMessageAssets = {
  __typename?: 'PayoutMethodAlertMessageAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type PayoutMethodAlertMessageEntries = {
  __typename?: 'PayoutMethodAlertMessageEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type PayoutMethodAlertMessageLinks = {
  __typename?: 'PayoutMethodAlertMessageLinks';
  assets: PayoutMethodAlertMessageAssets;
  entries: PayoutMethodAlertMessageEntries;
  resources: PayoutMethodAlertMessageResources;
};

export type PayoutMethodAlertMessageResources = {
  __typename?: 'PayoutMethodAlertMessageResources';
  block: Array<PayoutMethodAlertMessageResourcesBlock>;
  hyperlink: Array<PayoutMethodAlertMessageResourcesHyperlink>;
  inline: Array<PayoutMethodAlertMessageResourcesInline>;
};

export type PayoutMethodAlertMessageResourcesBlock = ResourceLink & {
  __typename?: 'PayoutMethodAlertMessageResourcesBlock';
  sys: ResourceSys;
};

export type PayoutMethodAlertMessageResourcesHyperlink = ResourceLink & {
  __typename?: 'PayoutMethodAlertMessageResourcesHyperlink';
  sys: ResourceSys;
};

export type PayoutMethodAlertMessageResourcesInline = ResourceLink & {
  __typename?: 'PayoutMethodAlertMessageResourcesInline';
  sys: ResourceSys;
};

export enum PayoutMethodAlertOrder {
  AlertLabelAsc = 'alertLabel_ASC',
  AlertLabelDesc = 'alertLabel_DESC',
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  EnabledAsc = 'enabled_ASC',
  EnabledDesc = 'enabled_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type PayoutMethodAlertPayoutMethodsCollection = {
  __typename?: 'PayoutMethodAlertPayoutMethodsCollection';
  items: Array<Maybe<PayoutMethod>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum PayoutMethodAlertPayoutMethodsCollectionOrder {
  CorrespondentsTitleAsc = 'correspondentsTitle_ASC',
  CorrespondentsTitleDesc = 'correspondentsTitle_DESC',
  CtaButtonBaseUrlAsc = 'ctaButtonBaseUrl_ASC',
  CtaButtonBaseUrlDesc = 'ctaButtonBaseUrl_DESC',
  CtaButtonLabelAsc = 'ctaButtonLabel_ASC',
  CtaButtonLabelDesc = 'ctaButtonLabel_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ExchangeRateLabelAsc = 'exchangeRateLabel_ASC',
  ExchangeRateLabelDesc = 'exchangeRateLabel_DESC',
  FeesLabelAsc = 'feesLabel_ASC',
  FeesLabelDesc = 'feesLabel_DESC',
  HideExchangeRateAsc = 'hideExchangeRate_ASC',
  HideExchangeRateDesc = 'hideExchangeRate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PayoutMethodIdAsc = 'payoutMethodId_ASC',
  PayoutMethodIdDesc = 'payoutMethodId_DESC',
  ReceiveMethodDescriptionAsc = 'receiveMethodDescription_ASC',
  ReceiveMethodDescriptionDesc = 'receiveMethodDescription_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SupportLinkTextAsc = 'supportLinkText_ASC',
  SupportLinkTextDesc = 'supportLinkText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TransferTimeLabelAsc = 'transferTimeLabel_ASC',
  TransferTimeLabelDesc = 'transferTimeLabel_DESC',
  TransferTimeTooltipAsc = 'transferTimeTooltip_ASC',
  TransferTimeTooltipDesc = 'transferTimeTooltip_DESC',
}

export type PayoutMethodCollection = {
  __typename?: 'PayoutMethodCollection';
  items: Array<Maybe<PayoutMethod>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PayoutMethodCountriesFromCollection = {
  __typename?: 'PayoutMethodCountriesFromCollection';
  items: Array<Maybe<Country>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum PayoutMethodCountriesFromCollectionOrder {
  ArticleAsc = 'article_ASC',
  ArticleDesc = 'article_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrepositionFromAsc = 'prepositionFrom_ASC',
  PrepositionFromDesc = 'prepositionFrom_DESC',
  PrepositionInAsc = 'prepositionIn_ASC',
  PrepositionInDesc = 'prepositionIn_DESC',
  PrepositionToAsc = 'prepositionTo_ASC',
  PrepositionToDesc = 'prepositionTo_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PayoutMethodCountriesToCollection = {
  __typename?: 'PayoutMethodCountriesToCollection';
  items: Array<Maybe<Country>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum PayoutMethodCountriesToCollectionOrder {
  ArticleAsc = 'article_ASC',
  ArticleDesc = 'article_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrepositionFromAsc = 'prepositionFrom_ASC',
  PrepositionFromDesc = 'prepositionFrom_DESC',
  PrepositionInAsc = 'prepositionIn_ASC',
  PrepositionInDesc = 'prepositionIn_DESC',
  PrepositionToAsc = 'prepositionTo_ASC',
  PrepositionToDesc = 'prepositionTo_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PayoutMethodFilter = {
  AND?: InputMaybe<Array<InputMaybe<PayoutMethodFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PayoutMethodFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  cornerGraphic_exists?: InputMaybe<Scalars['Boolean']['input']>;
  correspondentsTitle?: InputMaybe<Scalars['String']['input']>;
  correspondentsTitle_contains?: InputMaybe<Scalars['String']['input']>;
  correspondentsTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  correspondentsTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  correspondentsTitle_not?: InputMaybe<Scalars['String']['input']>;
  correspondentsTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  correspondentsTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  countriesFrom?: InputMaybe<CfCountryNestedFilter>;
  countriesFromCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  countriesTo?: InputMaybe<CfCountryNestedFilter>;
  countriesToCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ctaButtonBaseUrl?: InputMaybe<Scalars['String']['input']>;
  ctaButtonBaseUrl_contains?: InputMaybe<Scalars['String']['input']>;
  ctaButtonBaseUrl_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ctaButtonBaseUrl_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  ctaButtonBaseUrl_not?: InputMaybe<Scalars['String']['input']>;
  ctaButtonBaseUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  ctaButtonBaseUrl_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  ctaButtonLabel?: InputMaybe<Scalars['String']['input']>;
  ctaButtonLabel_contains?: InputMaybe<Scalars['String']['input']>;
  ctaButtonLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ctaButtonLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ctaButtonLabel_not?: InputMaybe<Scalars['String']['input']>;
  ctaButtonLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  ctaButtonLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  exchangeRateLabel?: InputMaybe<Scalars['String']['input']>;
  exchangeRateLabel_contains?: InputMaybe<Scalars['String']['input']>;
  exchangeRateLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  exchangeRateLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  exchangeRateLabel_not?: InputMaybe<Scalars['String']['input']>;
  exchangeRateLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  exchangeRateLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  feesLabel?: InputMaybe<Scalars['String']['input']>;
  feesLabel_contains?: InputMaybe<Scalars['String']['input']>;
  feesLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  feesLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  feesLabel_not?: InputMaybe<Scalars['String']['input']>;
  feesLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  feesLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hideExchangeRate?: InputMaybe<Scalars['Boolean']['input']>;
  hideExchangeRate_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hideExchangeRate_not?: InputMaybe<Scalars['Boolean']['input']>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  payoutMethodId?: InputMaybe<Scalars['String']['input']>;
  payoutMethodId_contains?: InputMaybe<Scalars['String']['input']>;
  payoutMethodId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  payoutMethodId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  payoutMethodId_not?: InputMaybe<Scalars['String']['input']>;
  payoutMethodId_not_contains?: InputMaybe<Scalars['String']['input']>;
  payoutMethodId_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveMethodDescription?: InputMaybe<Scalars['String']['input']>;
  receiveMethodDescription_contains?: InputMaybe<Scalars['String']['input']>;
  receiveMethodDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  receiveMethodDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveMethodDescription_not?: InputMaybe<Scalars['String']['input']>;
  receiveMethodDescription_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  receiveMethodDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveMethodLink?: InputMaybe<CfLinkNestedFilter>;
  receiveMethodLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  supportLinkText?: InputMaybe<Scalars['String']['input']>;
  supportLinkText_contains?: InputMaybe<Scalars['String']['input']>;
  supportLinkText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  supportLinkText_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  supportLinkText_not?: InputMaybe<Scalars['String']['input']>;
  supportLinkText_not_contains?: InputMaybe<Scalars['String']['input']>;
  supportLinkText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  transferTimeLabel?: InputMaybe<Scalars['String']['input']>;
  transferTimeLabel_contains?: InputMaybe<Scalars['String']['input']>;
  transferTimeLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  transferTimeLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  transferTimeLabel_not?: InputMaybe<Scalars['String']['input']>;
  transferTimeLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  transferTimeLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  transferTimeTooltip?: InputMaybe<Scalars['String']['input']>;
  transferTimeTooltip_contains?: InputMaybe<Scalars['String']['input']>;
  transferTimeTooltip_exists?: InputMaybe<Scalars['Boolean']['input']>;
  transferTimeTooltip_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  transferTimeTooltip_not?: InputMaybe<Scalars['String']['input']>;
  transferTimeTooltip_not_contains?: InputMaybe<Scalars['String']['input']>;
  transferTimeTooltip_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
};

export type PayoutMethodLinkingCollections = {
  __typename?: 'PayoutMethodLinkingCollections';
  calculatorCollection?: Maybe<CalculatorCollection>;
  entryCollection?: Maybe<EntryCollection>;
  howDoesItWorkCollection?: Maybe<HowDoesItWorkCollection>;
  payoutMethodAlertCollection?: Maybe<PayoutMethodAlertCollection>;
};

export type PayoutMethodLinkingCollectionsCalculatorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<PayoutMethodLinkingCollectionsCalculatorCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PayoutMethodLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PayoutMethodLinkingCollectionsHowDoesItWorkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<PayoutMethodLinkingCollectionsHowDoesItWorkCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PayoutMethodLinkingCollectionsPayoutMethodAlertCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<PayoutMethodLinkingCollectionsPayoutMethodAlertCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum PayoutMethodLinkingCollectionsCalculatorCollectionOrder {
  BlendedRateLabelAsc = 'blendedRateLabel_ASC',
  BlendedRateLabelDesc = 'blendedRateLabel_DESC',
  CountriesSearchPlaceholderAsc = 'countriesSearchPlaceholder_ASC',
  CountriesSearchPlaceholderDesc = 'countriesSearchPlaceholder_DESC',
  EstimatedFeeLabelAsc = 'estimatedFeeLabel_ASC',
  EstimatedFeeLabelDesc = 'estimatedFeeLabel_DESC',
  ExchangeRateLabelAsc = 'exchangeRateLabel_ASC',
  ExchangeRateLabelDesc = 'exchangeRateLabel_DESC',
  ExchangeRatePromoLabelAsc = 'exchangeRatePromoLabel_ASC',
  ExchangeRatePromoLabelDesc = 'exchangeRatePromoLabel_DESC',
  FeeLabelAsc = 'feeLabel_ASC',
  FeeLabelDesc = 'feeLabel_DESC',
  IncorrectCountrySelectedErrorMessageAsc = 'incorrectCountrySelectedErrorMessage_ASC',
  IncorrectCountrySelectedErrorMessageDesc = 'incorrectCountrySelectedErrorMessage_DESC',
  InstantDiscountLabelAsc = 'instantDiscountLabel_ASC',
  InstantDiscountLabelDesc = 'instantDiscountLabel_DESC',
  IsLiteAsc = 'isLite_ASC',
  IsLiteDesc = 'isLite_DESC',
  MaxDiscountLabelAsc = 'maxDiscountLabel_ASC',
  MaxDiscountLabelDesc = 'maxDiscountLabel_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PartnerSelectErrorMessageAsc = 'partnerSelectErrorMessage_ASC',
  PartnerSelectErrorMessageDesc = 'partnerSelectErrorMessage_DESC',
  PartnersLabelAsc = 'partnersLabel_ASC',
  PartnersLabelDesc = 'partnersLabel_DESC',
  PartnersListDefaultValueAsc = 'partnersListDefaultValue_ASC',
  PartnersListDefaultValueDesc = 'partnersListDefaultValue_DESC',
  PayoutMethodsLabelAsc = 'payoutMethodsLabel_ASC',
  PayoutMethodsLabelDesc = 'payoutMethodsLabel_DESC',
  PhReceiveBankTransferMessageAsc = 'phReceiveBankTransferMessage_ASC',
  PhReceiveBankTransferMessageDesc = 'phReceiveBankTransferMessage_DESC',
  PhoneInputInfoMoreInfoLabelAsc = 'phoneInputInfoMoreInfoLabel_ASC',
  PhoneInputInfoMoreInfoLabelDesc = 'phoneInputInfoMoreInfoLabel_DESC',
  PhoneInputInfoPopupTextAsc = 'phoneInputInfoPopupText_ASC',
  PhoneInputInfoPopupTextDesc = 'phoneInputInfoPopupText_DESC',
  PhoneNumberInputErrorMessageAsc = 'phoneNumberInputErrorMessage_ASC',
  PhoneNumberInputErrorMessageDesc = 'phoneNumberInputErrorMessage_DESC',
  PhoneNumberInvalidErrorMessageAsc = 'phoneNumberInvalidErrorMessage_ASC',
  PhoneNumberInvalidErrorMessageDesc = 'phoneNumberInvalidErrorMessage_DESC',
  PhoneValidatorLabelAsc = 'phoneValidatorLabel_ASC',
  PhoneValidatorLabelDesc = 'phoneValidatorLabel_DESC',
  PhoneValidatorPlaceholderAsc = 'phoneValidatorPlaceholder_ASC',
  PhoneValidatorPlaceholderDesc = 'phoneValidatorPlaceholder_DESC',
  ReceiveLabelAsc = 'receiveLabel_ASC',
  ReceiveLabelDesc = 'receiveLabel_DESC',
  RecipientGetsLabelAsc = 'recipientGetsLabel_ASC',
  RecipientGetsLabelDesc = 'recipientGetsLabel_DESC',
  SendLabelAsc = 'sendLabel_ASC',
  SendLabelDesc = 'sendLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TopUpAmountsLabelAsc = 'topUpAmountsLabel_ASC',
  TopUpAmountsLabelDesc = 'topUpAmountsLabel_DESC',
  TopUpAmountsPlaceholderAsc = 'topUpAmountsPlaceholder_ASC',
  TopUpAmountsPlaceholderDesc = 'topUpAmountsPlaceholder_DESC',
  TopUpAmountsSelectErrorMessageAsc = 'topUpAmountsSelectErrorMessage_ASC',
  TopUpAmountsSelectErrorMessageDesc = 'topUpAmountsSelectErrorMessage_DESC',
  TopUpAmountsSelectRetrievalErrorMessageAsc = 'topUpAmountsSelectRetrievalErrorMessage_ASC',
  TopUpAmountsSelectRetrievalErrorMessageDesc = 'topUpAmountsSelectRetrievalErrorMessage_DESC',
  TopUpAmountsTitleAsc = 'topUpAmountsTitle_ASC',
  TopUpAmountsTitleDesc = 'topUpAmountsTitle_DESC',
  TotalToPayLabelAsc = 'totalToPayLabel_ASC',
  TotalToPayLabelDesc = 'totalToPayLabel_DESC',
  TransferTimeLabelAsc = 'transferTimeLabel_ASC',
  TransferTimeLabelDesc = 'transferTimeLabel_DESC',
  UnavailableCorridorErrorMessageAsc = 'unavailableCorridorErrorMessage_ASC',
  UnavailableCorridorErrorMessageDesc = 'unavailableCorridorErrorMessage_DESC',
  VersionAsc = 'version_ASC',
  VersionDesc = 'version_DESC',
}

export enum PayoutMethodLinkingCollectionsHowDoesItWorkCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PayoutMethodLinkingCollectionsPayoutMethodAlertCollectionOrder {
  AlertLabelAsc = 'alertLabel_ASC',
  AlertLabelDesc = 'alertLabel_DESC',
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  EnabledAsc = 'enabled_ASC',
  EnabledDesc = 'enabled_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PayoutMethodOrder {
  CorrespondentsTitleAsc = 'correspondentsTitle_ASC',
  CorrespondentsTitleDesc = 'correspondentsTitle_DESC',
  CtaButtonBaseUrlAsc = 'ctaButtonBaseUrl_ASC',
  CtaButtonBaseUrlDesc = 'ctaButtonBaseUrl_DESC',
  CtaButtonLabelAsc = 'ctaButtonLabel_ASC',
  CtaButtonLabelDesc = 'ctaButtonLabel_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ExchangeRateLabelAsc = 'exchangeRateLabel_ASC',
  ExchangeRateLabelDesc = 'exchangeRateLabel_DESC',
  FeesLabelAsc = 'feesLabel_ASC',
  FeesLabelDesc = 'feesLabel_DESC',
  HideExchangeRateAsc = 'hideExchangeRate_ASC',
  HideExchangeRateDesc = 'hideExchangeRate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PayoutMethodIdAsc = 'payoutMethodId_ASC',
  PayoutMethodIdDesc = 'payoutMethodId_DESC',
  ReceiveMethodDescriptionAsc = 'receiveMethodDescription_ASC',
  ReceiveMethodDescriptionDesc = 'receiveMethodDescription_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SupportLinkTextAsc = 'supportLinkText_ASC',
  SupportLinkTextDesc = 'supportLinkText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TransferTimeLabelAsc = 'transferTimeLabel_ASC',
  TransferTimeLabelDesc = 'transferTimeLabel_DESC',
  TransferTimeTooltipAsc = 'transferTimeTooltip_ASC',
  TransferTimeTooltipDesc = 'transferTimeTooltip_DESC',
}

/** [web-cms] Used for displaying e.g. blog author [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/person) */
export type Person = Entry &
  _Node & {
    __typename?: 'Person';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    firstName?: Maybe<Scalars['String']['output']>;
    image?: Maybe<Asset>;
    lastName?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<PersonLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    slug?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** [web-cms] Used for displaying e.g. blog author [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/person) */
export type PersonDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Used for displaying e.g. blog author [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/person) */
export type PersonFirstNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Used for displaying e.g. blog author [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/person) */
export type PersonImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [web-cms] Used for displaying e.g. blog author [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/person) */
export type PersonLastNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Used for displaying e.g. blog author [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/person) */
export type PersonLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-cms] Used for displaying e.g. blog author [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/person) */
export type PersonNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Used for displaying e.g. blog author [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/person) */
export type PersonSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type PersonCollection = {
  __typename?: 'PersonCollection';
  items: Array<Maybe<Person>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PersonFilter = {
  AND?: InputMaybe<Array<InputMaybe<PersonFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PersonFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstName_contains?: InputMaybe<Scalars['String']['input']>;
  firstName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  firstName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  firstName_not?: InputMaybe<Scalars['String']['input']>;
  firstName_not_contains?: InputMaybe<Scalars['String']['input']>;
  firstName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastName_contains?: InputMaybe<Scalars['String']['input']>;
  lastName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  lastName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lastName_not?: InputMaybe<Scalars['String']['input']>;
  lastName_not_contains?: InputMaybe<Scalars['String']['input']>;
  lastName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type PersonLinkingCollections = {
  __typename?: 'PersonLinkingCollections';
  blogContentCollection?: Maybe<BlogContentCollection>;
  entryCollection?: Maybe<EntryCollection>;
  pageCollection?: Maybe<PageCollection>;
};

export type PersonLinkingCollectionsBlogContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<PersonLinkingCollectionsBlogContentCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<PersonLinkingCollectionsPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum PersonLinkingCollectionsBlogContentCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimeToReadAsc = 'timeToRead_ASC',
  TimeToReadDesc = 'timeToRead_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export enum PersonLinkingCollectionsPageCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderPositionAsc = 'headerPosition_ASC',
  HeaderPositionDesc = 'headerPosition_DESC',
  HeaderThemeAsc = 'headerTheme_ASC',
  HeaderThemeDesc = 'headerTheme_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimeToReadAsc = 'timeToRead_ASC',
  TimeToReadDesc = 'timeToRead_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export enum PersonOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/phoneNumberVerificationPage) */
export type PhoneNumberVerificationPage = Entry &
  _Node & {
    __typename?: 'PhoneNumberVerificationPage';
    _id: Scalars['ID']['output'];
    buttonLabel?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    errorDescription?: Maybe<Scalars['String']['output']>;
    header?: Maybe<Scalars['String']['output']>;
    informationBannerLabel?: Maybe<Scalars['String']['output']>;
    label?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<PhoneNumberVerificationPageLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
    validationErrorDescription?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/phoneNumberVerificationPage) */
export type PhoneNumberVerificationPageButtonLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/phoneNumberVerificationPage) */
export type PhoneNumberVerificationPageDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/phoneNumberVerificationPage) */
export type PhoneNumberVerificationPageErrorDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/phoneNumberVerificationPage) */
export type PhoneNumberVerificationPageHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/phoneNumberVerificationPage) */
export type PhoneNumberVerificationPageInformationBannerLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/phoneNumberVerificationPage) */
export type PhoneNumberVerificationPageLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/phoneNumberVerificationPage) */
export type PhoneNumberVerificationPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/phoneNumberVerificationPage) */
export type PhoneNumberVerificationPageNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/phoneNumberVerificationPage) */
export type PhoneNumberVerificationPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/phoneNumberVerificationPage) */
export type PhoneNumberVerificationPageValidationErrorDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type PhoneNumberVerificationPageCollection = {
  __typename?: 'PhoneNumberVerificationPageCollection';
  items: Array<Maybe<PhoneNumberVerificationPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PhoneNumberVerificationPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<PhoneNumberVerificationPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PhoneNumberVerificationPageFilter>>>;
  buttonLabel?: InputMaybe<Scalars['String']['input']>;
  buttonLabel_contains?: InputMaybe<Scalars['String']['input']>;
  buttonLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  buttonLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  buttonLabel_not?: InputMaybe<Scalars['String']['input']>;
  buttonLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  buttonLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  errorDescription?: InputMaybe<Scalars['String']['input']>;
  errorDescription_contains?: InputMaybe<Scalars['String']['input']>;
  errorDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  errorDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  errorDescription_not?: InputMaybe<Scalars['String']['input']>;
  errorDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  errorDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  informationBannerLabel?: InputMaybe<Scalars['String']['input']>;
  informationBannerLabel_contains?: InputMaybe<Scalars['String']['input']>;
  informationBannerLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  informationBannerLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  informationBannerLabel_not?: InputMaybe<Scalars['String']['input']>;
  informationBannerLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  informationBannerLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  validationErrorDescription?: InputMaybe<Scalars['String']['input']>;
  validationErrorDescription_contains?: InputMaybe<Scalars['String']['input']>;
  validationErrorDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  validationErrorDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  validationErrorDescription_not?: InputMaybe<Scalars['String']['input']>;
  validationErrorDescription_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  validationErrorDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
};

export type PhoneNumberVerificationPageLinkingCollections = {
  __typename?: 'PhoneNumberVerificationPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type PhoneNumberVerificationPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum PhoneNumberVerificationPageOrder {
  ButtonLabelAsc = 'buttonLabel_ASC',
  ButtonLabelDesc = 'buttonLabel_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ErrorDescriptionAsc = 'errorDescription_ASC',
  ErrorDescriptionDesc = 'errorDescription_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  InformationBannerLabelAsc = 'informationBannerLabel_ASC',
  InformationBannerLabelDesc = 'informationBannerLabel_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ValidationErrorDescriptionAsc = 'validationErrorDescription_ASC',
  ValidationErrorDescriptionDesc = 'validationErrorDescription_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/popularCountries) */
export type PopularCountries = Entry &
  _Node & {
    __typename?: 'PopularCountries';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<PopularCountriesLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    popularCountriesCodes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    popularCountriesHeading?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/popularCountries) */
export type PopularCountriesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/popularCountries) */
export type PopularCountriesNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/popularCountries) */
export type PopularCountriesPopularCountriesCodesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/popularCountries) */
export type PopularCountriesPopularCountriesHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type PopularCountriesCollection = {
  __typename?: 'PopularCountriesCollection';
  items: Array<Maybe<PopularCountries>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PopularCountriesFilter = {
  AND?: InputMaybe<Array<InputMaybe<PopularCountriesFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PopularCountriesFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  popularCountriesCodes_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  popularCountriesCodes_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  popularCountriesCodes_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  popularCountriesCodes_exists?: InputMaybe<Scalars['Boolean']['input']>;
  popularCountriesHeading?: InputMaybe<Scalars['String']['input']>;
  popularCountriesHeading_contains?: InputMaybe<Scalars['String']['input']>;
  popularCountriesHeading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  popularCountriesHeading_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  popularCountriesHeading_not?: InputMaybe<Scalars['String']['input']>;
  popularCountriesHeading_not_contains?: InputMaybe<Scalars['String']['input']>;
  popularCountriesHeading_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
};

export type PopularCountriesLinkingCollections = {
  __typename?: 'PopularCountriesLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  listOfCountriesCollection?: Maybe<ListOfCountriesCollection>;
};

export type PopularCountriesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PopularCountriesLinkingCollectionsListOfCountriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<PopularCountriesLinkingCollectionsListOfCountriesCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum PopularCountriesLinkingCollectionsListOfCountriesCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  ButtonLabelExpandedListAsc = 'buttonLabelExpandedList_ASC',
  ButtonLabelExpandedListDesc = 'buttonLabelExpandedList_DESC',
  ButtonLabelHiddenListAsc = 'buttonLabelHiddenList_ASC',
  ButtonLabelHiddenListDesc = 'buttonLabelHiddenList_DESC',
  ExpandListTextAsc = 'expandListText_ASC',
  ExpandListTextDesc = 'expandListText_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubheaderAsc = 'subheader_ASC',
  SubheaderDesc = 'subheader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PopularCountriesOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PopularCountriesHeadingAsc = 'popularCountriesHeading_ASC',
  PopularCountriesHeadingDesc = 'popularCountriesHeading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/priceComparsion) */
export type PriceComparsion = Entry &
  _Node & {
    __typename?: 'PriceComparsion';
    _id: Scalars['ID']['output'];
    backgroundColor1?: Maybe<Scalars['String']['output']>;
    backgroundColor2?: Maybe<Scalars['String']['output']>;
    button?: Maybe<Link>;
    buttonColor?: Maybe<Scalars['String']['output']>;
    buttonHoverColor?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    descriptionColor?: Maybe<Scalars['String']['output']>;
    image?: Maybe<Asset>;
    linkedFrom?: Maybe<PriceComparsionLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/priceComparsion) */
export type PriceComparsionBackgroundColor1Args = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/priceComparsion) */
export type PriceComparsionBackgroundColor2Args = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/priceComparsion) */
export type PriceComparsionButtonArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/priceComparsion) */
export type PriceComparsionButtonColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/priceComparsion) */
export type PriceComparsionButtonHoverColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/priceComparsion) */
export type PriceComparsionDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/priceComparsion) */
export type PriceComparsionDescriptionColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/priceComparsion) */
export type PriceComparsionImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/priceComparsion) */
export type PriceComparsionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/priceComparsion) */
export type PriceComparsionNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type PriceComparsionCollection = {
  __typename?: 'PriceComparsionCollection';
  items: Array<Maybe<PriceComparsion>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PriceComparsionFilter = {
  AND?: InputMaybe<Array<InputMaybe<PriceComparsionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PriceComparsionFilter>>>;
  backgroundColor1?: InputMaybe<Scalars['String']['input']>;
  backgroundColor1_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor1_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor1_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundColor1_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColor1_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor1_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundColor2?: InputMaybe<Scalars['String']['input']>;
  backgroundColor2_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor2_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor2_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundColor2_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColor2_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor2_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  button?: InputMaybe<CfLinkNestedFilter>;
  buttonColor?: InputMaybe<Scalars['String']['input']>;
  buttonColor_contains?: InputMaybe<Scalars['String']['input']>;
  buttonColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  buttonColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  buttonColor_not?: InputMaybe<Scalars['String']['input']>;
  buttonColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  buttonColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buttonHoverColor?: InputMaybe<Scalars['String']['input']>;
  buttonHoverColor_contains?: InputMaybe<Scalars['String']['input']>;
  buttonHoverColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  buttonHoverColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buttonHoverColor_not?: InputMaybe<Scalars['String']['input']>;
  buttonHoverColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  buttonHoverColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  button_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionColor?: InputMaybe<Scalars['String']['input']>;
  descriptionColor_contains?: InputMaybe<Scalars['String']['input']>;
  descriptionColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  descriptionColor_not?: InputMaybe<Scalars['String']['input']>;
  descriptionColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  descriptionColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type PriceComparsionLinkingCollections = {
  __typename?: 'PriceComparsionLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type PriceComparsionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum PriceComparsionOrder {
  BackgroundColor1Asc = 'backgroundColor1_ASC',
  BackgroundColor1Desc = 'backgroundColor1_DESC',
  BackgroundColor2Asc = 'backgroundColor2_ASC',
  BackgroundColor2Desc = 'backgroundColor2_DESC',
  ButtonColorAsc = 'buttonColor_ASC',
  ButtonColorDesc = 'buttonColor_DESC',
  ButtonHoverColorAsc = 'buttonHoverColor_ASC',
  ButtonHoverColorDesc = 'buttonHoverColor_DESC',
  DescriptionColorAsc = 'descriptionColor_ASC',
  DescriptionColorDesc = 'descriptionColor_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/pricingCalculator) */
export type PricingCalculator = Entry &
  _Node & {
    __typename?: 'PricingCalculator';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<PricingCalculatorLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/pricingCalculator) */
export type PricingCalculatorLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/pricingCalculator) */
export type PricingCalculatorNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/pricingCalculator) */
export type PricingCalculatorTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type PricingCalculatorCollection = {
  __typename?: 'PricingCalculatorCollection';
  items: Array<Maybe<PricingCalculator>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PricingCalculatorFilter = {
  AND?: InputMaybe<Array<InputMaybe<PricingCalculatorFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PricingCalculatorFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PricingCalculatorLinkingCollections = {
  __typename?: 'PricingCalculatorLinkingCollections';
  calculatorPageCollection?: Maybe<CalculatorPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type PricingCalculatorLinkingCollectionsCalculatorPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<PricingCalculatorLinkingCollectionsCalculatorPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PricingCalculatorLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum PricingCalculatorLinkingCollectionsCalculatorPageCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IsTopPageAsc = 'isTopPage_ASC',
  IsTopPageDesc = 'isTopPage_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PricingCalculatorOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promoApplicability) */
export type PromoApplicability = Entry &
  _Node & {
    __typename?: 'PromoApplicability';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<PromoApplicabilityLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    showForCorridors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sys: Sys;
    visitorTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promoApplicability) */
export type PromoApplicabilityLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promoApplicability) */
export type PromoApplicabilityNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promoApplicability) */
export type PromoApplicabilityShowForCorridorsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promoApplicability) */
export type PromoApplicabilityVisitorTypesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type PromoApplicabilityCollection = {
  __typename?: 'PromoApplicabilityCollection';
  items: Array<Maybe<PromoApplicability>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PromoApplicabilityFilter = {
  AND?: InputMaybe<Array<InputMaybe<PromoApplicabilityFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PromoApplicabilityFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  showForCorridors_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  showForCorridors_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  showForCorridors_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  showForCorridors_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  visitorTypes_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  visitorTypes_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  visitorTypes_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  visitorTypes_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PromoApplicabilityLinkingCollections = {
  __typename?: 'PromoApplicabilityLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  promoMessageCollection?: Maybe<PromoMessageCollection>;
  promotionalBannerCollection?: Maybe<PromotionalBannerCollection>;
  promotionalBannerDisclaimerCollection?: Maybe<PromotionalBannerDisclaimerCollection>;
};

export type PromoApplicabilityLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PromoApplicabilityLinkingCollectionsPromoMessageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<PromoApplicabilityLinkingCollectionsPromoMessageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PromoApplicabilityLinkingCollectionsPromotionalBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<PromoApplicabilityLinkingCollectionsPromotionalBannerCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PromoApplicabilityLinkingCollectionsPromotionalBannerDisclaimerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<PromoApplicabilityLinkingCollectionsPromotionalBannerDisclaimerCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum PromoApplicabilityLinkingCollectionsPromoMessageCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PromoApplicabilityLinkingCollectionsPromotionalBannerCollectionOrder {
  AddToInitialHtmlAsc = 'addToInitialHtml_ASC',
  AddToInitialHtmlDesc = 'addToInitialHtml_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PromoApplicabilityLinkingCollectionsPromotionalBannerDisclaimerCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PromoApplicabilityOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promoMessage) */
export type PromoMessage = Entry &
  _Node & {
    __typename?: 'PromoMessage';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<PromoMessageLinkingCollections>;
    message?: Maybe<PromoMessageMessage>;
    name?: Maybe<Scalars['String']['output']>;
    promoApplicability?: Maybe<PromoApplicability>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promoMessage) */
export type PromoMessageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promoMessage) */
export type PromoMessageMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promoMessage) */
export type PromoMessageNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promoMessage) */
export type PromoMessagePromoApplicabilityArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PromoApplicabilityFilter>;
};

export type PromoMessageCollection = {
  __typename?: 'PromoMessageCollection';
  items: Array<Maybe<PromoMessage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PromoMessageFilter = {
  AND?: InputMaybe<Array<InputMaybe<PromoMessageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PromoMessageFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  message_contains?: InputMaybe<Scalars['String']['input']>;
  message_exists?: InputMaybe<Scalars['Boolean']['input']>;
  message_not_contains?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  promoApplicability?: InputMaybe<CfPromoApplicabilityNestedFilter>;
  promoApplicability_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type PromoMessageLinkingCollections = {
  __typename?: 'PromoMessageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type PromoMessageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PromoMessageMessage = {
  __typename?: 'PromoMessageMessage';
  json: Scalars['JSON']['output'];
  links: PromoMessageMessageLinks;
};

export type PromoMessageMessageAssets = {
  __typename?: 'PromoMessageMessageAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type PromoMessageMessageEntries = {
  __typename?: 'PromoMessageMessageEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type PromoMessageMessageLinks = {
  __typename?: 'PromoMessageMessageLinks';
  assets: PromoMessageMessageAssets;
  entries: PromoMessageMessageEntries;
  resources: PromoMessageMessageResources;
};

export type PromoMessageMessageResources = {
  __typename?: 'PromoMessageMessageResources';
  block: Array<PromoMessageMessageResourcesBlock>;
  hyperlink: Array<PromoMessageMessageResourcesHyperlink>;
  inline: Array<PromoMessageMessageResourcesInline>;
};

export type PromoMessageMessageResourcesBlock = ResourceLink & {
  __typename?: 'PromoMessageMessageResourcesBlock';
  sys: ResourceSys;
};

export type PromoMessageMessageResourcesHyperlink = ResourceLink & {
  __typename?: 'PromoMessageMessageResourcesHyperlink';
  sys: ResourceSys;
};

export type PromoMessageMessageResourcesInline = ResourceLink & {
  __typename?: 'PromoMessageMessageResourcesInline';
  sys: ResourceSys;
};

export enum PromoMessageOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promotionalBanner) */
export type PromotionalBanner = Entry &
  _Node & {
    __typename?: 'PromotionalBanner';
    _id: Scalars['ID']['output'];
    abTestVersionsCollection?: Maybe<PromotionalBannerAbTestVersionsCollection>;
    addToInitialHtml?: Maybe<Scalars['Boolean']['output']>;
    backgroundImage?: Maybe<Asset>;
    backgroundImageMobile?: Maybe<Asset>;
    button?: Maybe<Link>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<PromotionalBannerDescription>;
    link?: Maybe<Link>;
    linkedFrom?: Maybe<PromotionalBannerLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    promoApplicability?: Maybe<PromoApplicability>;
    promoIcon?: Maybe<Asset>;
    showForCorridors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sys: Sys;
    visitorTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promotionalBanner) */
export type PromotionalBannerAbTestVersionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<PromotionalBannerAbTestVersionsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromotionalBannerFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promotionalBanner) */
export type PromotionalBannerAddToInitialHtmlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promotionalBanner) */
export type PromotionalBannerBackgroundImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promotionalBanner) */
export type PromotionalBannerBackgroundImageMobileArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promotionalBanner) */
export type PromotionalBannerButtonArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promotionalBanner) */
export type PromotionalBannerDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promotionalBanner) */
export type PromotionalBannerLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promotionalBanner) */
export type PromotionalBannerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promotionalBanner) */
export type PromotionalBannerNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promotionalBanner) */
export type PromotionalBannerPromoApplicabilityArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PromoApplicabilityFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promotionalBanner) */
export type PromotionalBannerPromoIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promotionalBanner) */
export type PromotionalBannerShowForCorridorsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promotionalBanner) */
export type PromotionalBannerVisitorTypesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type PromotionalBannerAbTestVersionsCollection = {
  __typename?: 'PromotionalBannerAbTestVersionsCollection';
  items: Array<Maybe<PromotionalBanner>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum PromotionalBannerAbTestVersionsCollectionOrder {
  AddToInitialHtmlAsc = 'addToInitialHtml_ASC',
  AddToInitialHtmlDesc = 'addToInitialHtml_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PromotionalBannerCollection = {
  __typename?: 'PromotionalBannerCollection';
  items: Array<Maybe<PromotionalBanner>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PromotionalBannerDescription = {
  __typename?: 'PromotionalBannerDescription';
  json: Scalars['JSON']['output'];
  links: PromotionalBannerDescriptionLinks;
};

export type PromotionalBannerDescriptionAssets = {
  __typename?: 'PromotionalBannerDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type PromotionalBannerDescriptionEntries = {
  __typename?: 'PromotionalBannerDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type PromotionalBannerDescriptionLinks = {
  __typename?: 'PromotionalBannerDescriptionLinks';
  assets: PromotionalBannerDescriptionAssets;
  entries: PromotionalBannerDescriptionEntries;
  resources: PromotionalBannerDescriptionResources;
};

export type PromotionalBannerDescriptionResources = {
  __typename?: 'PromotionalBannerDescriptionResources';
  block: Array<PromotionalBannerDescriptionResourcesBlock>;
  hyperlink: Array<PromotionalBannerDescriptionResourcesHyperlink>;
  inline: Array<PromotionalBannerDescriptionResourcesInline>;
};

export type PromotionalBannerDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'PromotionalBannerDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type PromotionalBannerDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'PromotionalBannerDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type PromotionalBannerDescriptionResourcesInline = ResourceLink & {
  __typename?: 'PromotionalBannerDescriptionResourcesInline';
  sys: ResourceSys;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promotionalBannerDisclaimer) */
export type PromotionalBannerDisclaimer = Entry &
  _Node & {
    __typename?: 'PromotionalBannerDisclaimer';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<PromotionalBannerDisclaimerLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    promoApplicability?: Maybe<PromoApplicability>;
    showForCorridors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sys: Sys;
    visitorTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promotionalBannerDisclaimer) */
export type PromotionalBannerDisclaimerDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promotionalBannerDisclaimer) */
export type PromotionalBannerDisclaimerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promotionalBannerDisclaimer) */
export type PromotionalBannerDisclaimerNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promotionalBannerDisclaimer) */
export type PromotionalBannerDisclaimerPromoApplicabilityArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PromoApplicabilityFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promotionalBannerDisclaimer) */
export type PromotionalBannerDisclaimerShowForCorridorsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/promotionalBannerDisclaimer) */
export type PromotionalBannerDisclaimerVisitorTypesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type PromotionalBannerDisclaimerCollection = {
  __typename?: 'PromotionalBannerDisclaimerCollection';
  items: Array<Maybe<PromotionalBannerDisclaimer>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PromotionalBannerDisclaimerFilter = {
  AND?: InputMaybe<Array<InputMaybe<PromotionalBannerDisclaimerFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PromotionalBannerDisclaimerFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  promoApplicability?: InputMaybe<CfPromoApplicabilityNestedFilter>;
  promoApplicability_exists?: InputMaybe<Scalars['Boolean']['input']>;
  showForCorridors_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  showForCorridors_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  showForCorridors_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  showForCorridors_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  visitorTypes_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  visitorTypes_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  visitorTypes_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  visitorTypes_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PromotionalBannerDisclaimerLinkingCollections = {
  __typename?: 'PromotionalBannerDisclaimerLinkingCollections';
  calculatorPageCollection?: Maybe<CalculatorPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type PromotionalBannerDisclaimerLinkingCollectionsCalculatorPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<PromotionalBannerDisclaimerLinkingCollectionsCalculatorPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PromotionalBannerDisclaimerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum PromotionalBannerDisclaimerLinkingCollectionsCalculatorPageCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IsTopPageAsc = 'isTopPage_ASC',
  IsTopPageDesc = 'isTopPage_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PromotionalBannerDisclaimerOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PromotionalBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<PromotionalBannerFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PromotionalBannerFilter>>>;
  abTestVersions?: InputMaybe<CfPromotionalBannerNestedFilter>;
  abTestVersionsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  addToInitialHtml?: InputMaybe<Scalars['Boolean']['input']>;
  addToInitialHtml_exists?: InputMaybe<Scalars['Boolean']['input']>;
  addToInitialHtml_not?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundImageMobile_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  button?: InputMaybe<CfLinkNestedFilter>;
  button_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<CfLinkNestedFilter>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  promoApplicability?: InputMaybe<CfPromoApplicabilityNestedFilter>;
  promoApplicability_exists?: InputMaybe<Scalars['Boolean']['input']>;
  promoIcon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  showForCorridors_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  showForCorridors_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  showForCorridors_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  showForCorridors_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  visitorTypes_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  visitorTypes_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  visitorTypes_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  visitorTypes_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PromotionalBannerLinkingCollections = {
  __typename?: 'PromotionalBannerLinkingCollections';
  calculatorPageCollection?: Maybe<CalculatorPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
  promotionalBannerCollection?: Maybe<PromotionalBannerCollection>;
};

export type PromotionalBannerLinkingCollectionsCalculatorPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<PromotionalBannerLinkingCollectionsCalculatorPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PromotionalBannerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PromotionalBannerLinkingCollectionsPromotionalBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<PromotionalBannerLinkingCollectionsPromotionalBannerCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum PromotionalBannerLinkingCollectionsCalculatorPageCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IsTopPageAsc = 'isTopPage_ASC',
  IsTopPageDesc = 'isTopPage_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PromotionalBannerLinkingCollectionsPromotionalBannerCollectionOrder {
  AddToInitialHtmlAsc = 'addToInitialHtml_ASC',
  AddToInitialHtmlDesc = 'addToInitialHtml_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PromotionalBannerOrder {
  AddToInitialHtmlAsc = 'addToInitialHtml_ASC',
  AddToInitialHtmlDesc = 'addToInitialHtml_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type Query = {
  __typename?: 'Query';
  _node?: Maybe<_Node>;
  accountDuplicationPage?: Maybe<AccountDuplicationPage>;
  accountDuplicationPageCollection?: Maybe<AccountDuplicationPageCollection>;
  actionResult?: Maybe<ActionResult>;
  actionResultCollection?: Maybe<ActionResultCollection>;
  appBanner?: Maybe<AppBanner>;
  appBannerCollection?: Maybe<AppBannerCollection>;
  appReview?: Maybe<AppReview>;
  appReviewCollection?: Maybe<AppReviewCollection>;
  appReviews?: Maybe<AppReviews>;
  appReviewsCollection?: Maybe<AppReviewsCollection>;
  asset?: Maybe<Asset>;
  assetCollection?: Maybe<AssetCollection>;
  backToSchool?: Maybe<BackToSchool>;
  backToSchoolCollection?: Maybe<BackToSchoolCollection>;
  backToSchoolCostCategory?: Maybe<BackToSchoolCostCategory>;
  backToSchoolCostCategoryCollection?: Maybe<BackToSchoolCostCategoryCollection>;
  backToSchoolCountryData?: Maybe<BackToSchoolCountryData>;
  backToSchoolCountryDataCollection?: Maybe<BackToSchoolCountryDataCollection>;
  betterWayTop?: Maybe<BetterWayTop>;
  betterWayTopCollection?: Maybe<BetterWayTopCollection>;
  blogContent?: Maybe<BlogContent>;
  blogContentCollection?: Maybe<BlogContentCollection>;
  bodyText?: Maybe<BodyText>;
  bodyTextCollection?: Maybe<BodyTextCollection>;
  calculator?: Maybe<Calculator>;
  calculatorAppLeadingLinks?: Maybe<CalculatorAppLeadingLinks>;
  calculatorAppLeadingLinksCollection?: Maybe<CalculatorAppLeadingLinksCollection>;
  calculatorCollection?: Maybe<CalculatorCollection>;
  calculatorPage?: Maybe<CalculatorPage>;
  calculatorPageCollection?: Maybe<CalculatorPageCollection>;
  card?: Maybe<Card>;
  cardCollection?: Maybe<CardCollection>;
  cards?: Maybe<Cards>;
  cardsCollection?: Maybe<CardsCollection>;
  cexChartFilterOptions?: Maybe<CexChartFilterOptions>;
  cexChartFilterOptionsCollection?: Maybe<CexChartFilterOptionsCollection>;
  cexCurrency?: Maybe<CexCurrency>;
  cexCurrencyCollection?: Maybe<CexCurrencyCollection>;
  cexCurrencyCorridor?: Maybe<CexCurrencyCorridor>;
  cexCurrencyCorridorCollection?: Maybe<CexCurrencyCorridorCollection>;
  cexLineChart?: Maybe<CexLineChart>;
  cexLineChartCollection?: Maybe<CexLineChartCollection>;
  cexRateAlerts?: Maybe<CexRateAlerts>;
  cexRateAlertsCollection?: Maybe<CexRateAlertsCollection>;
  cexRateTable?: Maybe<CexRateTable>;
  cexRateTableCollection?: Maybe<CexRateTableCollection>;
  cexSearch?: Maybe<CexSearch>;
  cexSearchCollection?: Maybe<CexSearchCollection>;
  changePasswordPage?: Maybe<ChangePasswordPage>;
  changePasswordPageCollection?: Maybe<ChangePasswordPageCollection>;
  comparisonTable?: Maybe<ComparisonTable>;
  comparisonTableCell?: Maybe<ComparisonTableCell>;
  comparisonTableCellCollection?: Maybe<ComparisonTableCellCollection>;
  comparisonTableCollection?: Maybe<ComparisonTableCollection>;
  comparisonTableRow?: Maybe<ComparisonTableRow>;
  comparisonTableRowCollection?: Maybe<ComparisonTableRowCollection>;
  contextualPromoBanner?: Maybe<ContextualPromoBanner>;
  contextualPromoBannerCollection?: Maybe<ContextualPromoBannerCollection>;
  correspondent?: Maybe<Correspondent>;
  correspondentCollection?: Maybe<CorrespondentCollection>;
  correspondentLocations?: Maybe<CorrespondentLocations>;
  correspondentLocationsCollection?: Maybe<CorrespondentLocationsCollection>;
  correspondents?: Maybe<Correspondents>;
  correspondentsCollection?: Maybe<CorrespondentsCollection>;
  country?: Maybe<Country>;
  countryCollection?: Maybe<CountryCollection>;
  countryPayoutMethod?: Maybe<CountryPayoutMethod>;
  countryPayoutMethodCollection?: Maybe<CountryPayoutMethodCollection>;
  currencyPricingConverter?: Maybe<CurrencyPricingConverter>;
  currencyPricingConverterCollection?: Maybe<CurrencyPricingConverterCollection>;
  embeddedTableEntry?: Maybe<EmbeddedTableEntry>;
  embeddedTableEntryCollection?: Maybe<EmbeddedTableEntryCollection>;
  entryCollection?: Maybe<EntryCollection>;
  externalScript?: Maybe<ExternalScript>;
  externalScriptCollection?: Maybe<ExternalScriptCollection>;
  faqFeedbackModule?: Maybe<FaqFeedbackModule>;
  faqFeedbackModuleCollection?: Maybe<FaqFeedbackModuleCollection>;
  faqFeedbackModuleItem?: Maybe<FaqFeedbackModuleItem>;
  faqFeedbackModuleItemCollection?: Maybe<FaqFeedbackModuleItemCollection>;
  faqHero?: Maybe<FaqHero>;
  faqHeroCollection?: Maybe<FaqHeroCollection>;
  faqModule?: Maybe<FaqModule>;
  faqModuleCollection?: Maybe<FaqModuleCollection>;
  faqModuleItem?: Maybe<FaqModuleItem>;
  faqModuleItemCollection?: Maybe<FaqModuleItemCollection>;
  faqPopularQuestions?: Maybe<FaqPopularQuestions>;
  faqPopularQuestionsCollection?: Maybe<FaqPopularQuestionsCollection>;
  faqSearchHelp?: Maybe<FaqSearchHelp>;
  faqSearchHelpCollection?: Maybe<FaqSearchHelpCollection>;
  faqSearchHelpItems?: Maybe<FaqSearchHelpItems>;
  faqSearchHelpItemsCollection?: Maybe<FaqSearchHelpItemsCollection>;
  faqTopicsModule?: Maybe<FaqTopicsModule>;
  faqTopicsModuleCollection?: Maybe<FaqTopicsModuleCollection>;
  faqTopicsModuleItems?: Maybe<FaqTopicsModuleItems>;
  faqTopicsModuleItemsCollection?: Maybe<FaqTopicsModuleItemsCollection>;
  featureFlag?: Maybe<FeatureFlag>;
  featureFlagCollection?: Maybe<FeatureFlagCollection>;
  footer?: Maybe<Footer>;
  footerCollection?: Maybe<FooterCollection>;
  footerNavigationGroup?: Maybe<FooterNavigationGroup>;
  footerNavigationGroupCollection?: Maybe<FooterNavigationGroupCollection>;
  footerNavigationGroupExtras?: Maybe<FooterNavigationGroupExtras>;
  footerNavigationGroupExtrasCollection?: Maybe<FooterNavigationGroupExtrasCollection>;
  forgottenPasswordPage?: Maybe<ForgottenPasswordPage>;
  forgottenPasswordPageCollection?: Maybe<ForgottenPasswordPageCollection>;
  fullWidthBanner?: Maybe<FullWidthBanner>;
  fullWidthBannerCollection?: Maybe<FullWidthBannerCollection>;
  genericErrorPage?: Maybe<GenericErrorPage>;
  genericErrorPageCollection?: Maybe<GenericErrorPageCollection>;
  heroSlot?: Maybe<HeroSlot>;
  heroSlotCollection?: Maybe<HeroSlotCollection>;
  howDoesItWork?: Maybe<HowDoesItWork>;
  howDoesItWorkCollection?: Maybe<HowDoesItWorkCollection>;
  howItWorksStepRelaunch?: Maybe<HowItWorksStepRelaunch>;
  howItWorksStepRelaunchCollection?: Maybe<HowItWorksStepRelaunchCollection>;
  howSendingWorks?: Maybe<HowSendingWorks>;
  howSendingWorksCollection?: Maybe<HowSendingWorksCollection>;
  iconWithText?: Maybe<IconWithText>;
  iconWithTextCollection?: Maybe<IconWithTextCollection>;
  image?: Maybe<Image>;
  imageCollection?: Maybe<ImageCollection>;
  informationDialog?: Maybe<InformationDialog>;
  informationDialogCollection?: Maybe<InformationDialogCollection>;
  jazzCashPage?: Maybe<JazzCashPage>;
  jazzCashPageCollection?: Maybe<JazzCashPageCollection>;
  jazzCashRequest?: Maybe<JazzCashRequest>;
  jazzCashRequestCollection?: Maybe<JazzCashRequestCollection>;
  layoutFooter?: Maybe<LayoutFooter>;
  layoutFooterCollection?: Maybe<LayoutFooterCollection>;
  layoutHeader?: Maybe<LayoutHeader>;
  layoutHeaderCollection?: Maybe<LayoutHeaderCollection>;
  legalMessaging?: Maybe<LegalMessaging>;
  legalMessagingCollection?: Maybe<LegalMessagingCollection>;
  link?: Maybe<Link>;
  linkCollection?: Maybe<LinkCollection>;
  listOfCountries?: Maybe<ListOfCountries>;
  listOfCountriesCollection?: Maybe<ListOfCountriesCollection>;
  loginPage?: Maybe<LoginPage>;
  loginPageCollection?: Maybe<LoginPageCollection>;
  logo?: Maybe<Logo>;
  logoCollection?: Maybe<LogoCollection>;
  member?: Maybe<Member>;
  memberCollection?: Maybe<MemberCollection>;
  membersList?: Maybe<MembersList>;
  membersListCollection?: Maybe<MembersListCollection>;
  messages?: Maybe<Messages>;
  messagesCollection?: Maybe<MessagesCollection>;
  mfaFactors?: Maybe<MfaFactors>;
  mfaFactorsCollection?: Maybe<MfaFactorsCollection>;
  mfaVerification?: Maybe<MfaVerification>;
  mfaVerificationCollection?: Maybe<MfaVerificationCollection>;
  mobileAppLeadingLinks?: Maybe<MobileAppLeadingLinks>;
  mobileAppLeadingLinksCollection?: Maybe<MobileAppLeadingLinksCollection>;
  mobileStoreData?: Maybe<MobileStoreData>;
  mobileStoreDataCollection?: Maybe<MobileStoreDataCollection>;
  moneyRequestConfirmationDialog?: Maybe<MoneyRequestConfirmationDialog>;
  moneyRequestConfirmationDialogCollection?: Maybe<MoneyRequestConfirmationDialogCollection>;
  moneyRequestPay?: Maybe<MoneyRequestPay>;
  moneyRequestPayCollection?: Maybe<MoneyRequestPayCollection>;
  moneyRequestPaymentReason?: Maybe<MoneyRequestPaymentReason>;
  moneyRequestPaymentReasonCollection?: Maybe<MoneyRequestPaymentReasonCollection>;
  moneyTransfer?: Maybe<MoneyTransfer>;
  moneyTransferCard?: Maybe<MoneyTransferCard>;
  moneyTransferCardCollection?: Maybe<MoneyTransferCardCollection>;
  moneyTransferCollection?: Maybe<MoneyTransferCollection>;
  notFoundContent?: Maybe<NotFoundContent>;
  notFoundContentCollection?: Maybe<NotFoundContentCollection>;
  notFoundErrorPage?: Maybe<NotFoundErrorPage>;
  notFoundErrorPageCollection?: Maybe<NotFoundErrorPageCollection>;
  notFoundPage?: Maybe<NotFoundPage>;
  notFoundPageCollection?: Maybe<NotFoundPageCollection>;
  onfidoErrorPage?: Maybe<OnfidoErrorPage>;
  onfidoErrorPageCollection?: Maybe<OnfidoErrorPageCollection>;
  ourContactInformation?: Maybe<OurContactInformation>;
  ourContactInformationCollection?: Maybe<OurContactInformationCollection>;
  page?: Maybe<Page>;
  pageCollection?: Maybe<PageCollection>;
  pageSection?: Maybe<PageSection>;
  pageSectionCollection?: Maybe<PageSectionCollection>;
  paymentReason?: Maybe<PaymentReason>;
  paymentReasonCollection?: Maybe<PaymentReasonCollection>;
  payoutMethod?: Maybe<PayoutMethod>;
  payoutMethodAlert?: Maybe<PayoutMethodAlert>;
  payoutMethodAlertCollection?: Maybe<PayoutMethodAlertCollection>;
  payoutMethodCollection?: Maybe<PayoutMethodCollection>;
  person?: Maybe<Person>;
  personCollection?: Maybe<PersonCollection>;
  phoneNumberVerificationPage?: Maybe<PhoneNumberVerificationPage>;
  phoneNumberVerificationPageCollection?: Maybe<PhoneNumberVerificationPageCollection>;
  popularCountries?: Maybe<PopularCountries>;
  popularCountriesCollection?: Maybe<PopularCountriesCollection>;
  priceComparsion?: Maybe<PriceComparsion>;
  priceComparsionCollection?: Maybe<PriceComparsionCollection>;
  pricingCalculator?: Maybe<PricingCalculator>;
  pricingCalculatorCollection?: Maybe<PricingCalculatorCollection>;
  promoApplicability?: Maybe<PromoApplicability>;
  promoApplicabilityCollection?: Maybe<PromoApplicabilityCollection>;
  promoMessage?: Maybe<PromoMessage>;
  promoMessageCollection?: Maybe<PromoMessageCollection>;
  promotionalBanner?: Maybe<PromotionalBanner>;
  promotionalBannerCollection?: Maybe<PromotionalBannerCollection>;
  promotionalBannerDisclaimer?: Maybe<PromotionalBannerDisclaimer>;
  promotionalBannerDisclaimerCollection?: Maybe<PromotionalBannerDisclaimerCollection>;
  redirect?: Maybe<Redirect>;
  redirectCollection?: Maybe<RedirectCollection>;
  referAFriendGreenfieldEnabledCountries?: Maybe<ReferAFriendGreenfieldEnabledCountries>;
  referAFriendGreenfieldEnabledCountriesCollection?: Maybe<ReferAFriendGreenfieldEnabledCountriesCollection>;
  referFriendHeader?: Maybe<ReferFriendHeader>;
  referFriendHeaderCollection?: Maybe<ReferFriendHeaderCollection>;
  regionCode?: Maybe<RegionCode>;
  regionCodeCollection?: Maybe<RegionCodeCollection>;
  regulator?: Maybe<Regulator>;
  regulatorCollection?: Maybe<RegulatorCollection>;
  requestMoneyNewRequestEmail?: Maybe<RequestMoneyNewRequestEmail>;
  requestMoneyNewRequestEmailCollection?: Maybe<RequestMoneyNewRequestEmailCollection>;
  requestMoneyPage?: Maybe<RequestMoneyPage>;
  requestMoneyPageCollection?: Maybe<RequestMoneyPageCollection>;
  review?: Maybe<Review>;
  reviewCollection?: Maybe<ReviewCollection>;
  reviews?: Maybe<Reviews>;
  reviewsCollection?: Maybe<ReviewsCollection>;
  reviewsTimeDictionary?: Maybe<ReviewsTimeDictionary>;
  reviewsTimeDictionaryCollection?: Maybe<ReviewsTimeDictionaryCollection>;
  robotsConfiguration?: Maybe<RobotsConfiguration>;
  robotsConfigurationCollection?: Maybe<RobotsConfigurationCollection>;
  search?: Maybe<Search>;
  searchCollection?: Maybe<SearchCollection>;
  sendToCountryModule?: Maybe<SendToCountryModule>;
  sendToCountryModuleCollection?: Maybe<SendToCountryModuleCollection>;
  senderFormPage?: Maybe<SenderFormPage>;
  senderFormPageCollection?: Maybe<SenderFormPageCollection>;
  sendingPartnership?: Maybe<SendingPartnership>;
  sendingPartnershipCollection?: Maybe<SendingPartnershipCollection>;
  seoContactPoint?: Maybe<SeoContactPoint>;
  seoContactPointCollection?: Maybe<SeoContactPointCollection>;
  seoOrganizationStructuredData?: Maybe<SeoOrganizationStructuredData>;
  seoOrganizationStructuredDataCollection?: Maybe<SeoOrganizationStructuredDataCollection>;
  seoSameAs?: Maybe<SeoSameAs>;
  seoSameAsCollection?: Maybe<SeoSameAsCollection>;
  shareSocialLink?: Maybe<ShareSocialLink>;
  shareSocialLinkCollection?: Maybe<ShareSocialLinkCollection>;
  signIn?: Maybe<SignIn>;
  signInCollection?: Maybe<SignInCollection>;
  sitemap?: Maybe<Sitemap>;
  sitemapCollection?: Maybe<SitemapCollection>;
  smsVerificationCodePage?: Maybe<SmsVerificationCodePage>;
  smsVerificationCodePageCollection?: Maybe<SmsVerificationCodePageCollection>;
  socialProofingModule?: Maybe<SocialProofingModule>;
  socialProofingModuleCollection?: Maybe<SocialProofingModuleCollection>;
  solutionsModule?: Maybe<SolutionsModule>;
  solutionsModuleCollection?: Maybe<SolutionsModuleCollection>;
  step?: Maybe<Step>;
  stepByStep?: Maybe<StepByStep>;
  stepByStepCollection?: Maybe<StepByStepCollection>;
  stepCollection?: Maybe<StepCollection>;
  stickyBanner?: Maybe<StickyBanner>;
  stickyBannerCollection?: Maybe<StickyBannerCollection>;
  tagLinks?: Maybe<TagLinks>;
  tagLinksCollection?: Maybe<TagLinksCollection>;
  textMeTheAppModule?: Maybe<TextMeTheAppModule>;
  textMeTheAppModuleCollection?: Maybe<TextMeTheAppModuleCollection>;
  textSection?: Maybe<TextSection>;
  textSectionCollection?: Maybe<TextSectionCollection>;
  textWithColor?: Maybe<TextWithColor>;
  textWithColorCollection?: Maybe<TextWithColorCollection>;
  verificationInProgress?: Maybe<VerificationInProgress>;
  verificationInProgressCollection?: Maybe<VerificationInProgressCollection>;
  video?: Maybe<Video>;
  videoCollection?: Maybe<VideoCollection>;
  weAreRegulated?: Maybe<WeAreRegulated>;
  weAreRegulatedCollection?: Maybe<WeAreRegulatedCollection>;
  webForm?: Maybe<WebForm>;
  webFormCollection?: Maybe<WebFormCollection>;
  whyChoose?: Maybe<WhyChoose>;
  whyChooseCollection?: Maybe<WhyChooseCollection>;
  whyChooseReason?: Maybe<WhyChooseReason>;
  whyChooseReasonCollection?: Maybe<WhyChooseReasonCollection>;
};

export type Query_NodeArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAccountDuplicationPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAccountDuplicationPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<AccountDuplicationPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccountDuplicationPageFilter>;
};

export type QueryActionResultArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryActionResultCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ActionResultOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActionResultFilter>;
};

export type QueryAppBannerArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAppBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<AppBannerOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AppBannerFilter>;
};

export type QueryAppReviewArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAppReviewCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<AppReviewOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AppReviewFilter>;
};

export type QueryAppReviewsArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAppReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<AppReviewsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AppReviewsFilter>;
};

export type QueryAssetArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAssetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<AssetOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AssetFilter>;
};

export type QueryBackToSchoolArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryBackToSchoolCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<BackToSchoolOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BackToSchoolFilter>;
};

export type QueryBackToSchoolCostCategoryArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryBackToSchoolCostCategoryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<BackToSchoolCostCategoryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BackToSchoolCostCategoryFilter>;
};

export type QueryBackToSchoolCountryDataArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryBackToSchoolCountryDataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<BackToSchoolCountryDataOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BackToSchoolCountryDataFilter>;
};

export type QueryBetterWayTopArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryBetterWayTopCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<BetterWayTopOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BetterWayTopFilter>;
};

export type QueryBlogContentArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryBlogContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<BlogContentOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BlogContentFilter>;
};

export type QueryBodyTextArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryBodyTextCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<BodyTextOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BodyTextFilter>;
};

export type QueryCalculatorArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCalculatorAppLeadingLinksArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCalculatorAppLeadingLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<CalculatorAppLeadingLinksOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CalculatorAppLeadingLinksFilter>;
};

export type QueryCalculatorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<CalculatorOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CalculatorFilter>;
};

export type QueryCalculatorPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCalculatorPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<CalculatorPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CalculatorPageFilter>;
};

export type QueryCardArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<CardOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CardFilter>;
};

export type QueryCardsArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<CardsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CardsFilter>;
};

export type QueryCexChartFilterOptionsArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCexChartFilterOptionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<CexChartFilterOptionsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CexChartFilterOptionsFilter>;
};

export type QueryCexCurrencyArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCexCurrencyCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<CexCurrencyOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CexCurrencyFilter>;
};

export type QueryCexCurrencyCorridorArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCexCurrencyCorridorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<CexCurrencyCorridorOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CexCurrencyCorridorFilter>;
};

export type QueryCexLineChartArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCexLineChartCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<CexLineChartOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CexLineChartFilter>;
};

export type QueryCexRateAlertsArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCexRateAlertsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<CexRateAlertsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CexRateAlertsFilter>;
};

export type QueryCexRateTableArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCexRateTableCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<CexRateTableOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CexRateTableFilter>;
};

export type QueryCexSearchArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCexSearchCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<CexSearchOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CexSearchFilter>;
};

export type QueryChangePasswordPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryChangePasswordPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ChangePasswordPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ChangePasswordPageFilter>;
};

export type QueryComparisonTableArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryComparisonTableCellArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryComparisonTableCellCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ComparisonTableCellOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ComparisonTableCellFilter>;
};

export type QueryComparisonTableCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ComparisonTableOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ComparisonTableFilter>;
};

export type QueryComparisonTableRowArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryComparisonTableRowCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ComparisonTableRowOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ComparisonTableRowFilter>;
};

export type QueryContextualPromoBannerArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContextualPromoBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ContextualPromoBannerOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContextualPromoBannerFilter>;
};

export type QueryCorrespondentArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCorrespondentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<CorrespondentOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CorrespondentFilter>;
};

export type QueryCorrespondentLocationsArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCorrespondentLocationsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<CorrespondentLocationsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CorrespondentLocationsFilter>;
};

export type QueryCorrespondentsArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCorrespondentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<CorrespondentsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CorrespondentsFilter>;
};

export type QueryCountryArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCountryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<CountryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CountryFilter>;
};

export type QueryCountryPayoutMethodArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCountryPayoutMethodCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<CountryPayoutMethodOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CountryPayoutMethodFilter>;
};

export type QueryCurrencyPricingConverterArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCurrencyPricingConverterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<CurrencyPricingConverterOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CurrencyPricingConverterFilter>;
};

export type QueryEmbeddedTableEntryArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryEmbeddedTableEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<EmbeddedTableEntryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EmbeddedTableEntryFilter>;
};

export type QueryEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<EntryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EntryFilter>;
};

export type QueryExternalScriptArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryExternalScriptCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ExternalScriptOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ExternalScriptFilter>;
};

export type QueryFaqFeedbackModuleArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFaqFeedbackModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FaqFeedbackModuleOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaqFeedbackModuleFilter>;
};

export type QueryFaqFeedbackModuleItemArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFaqFeedbackModuleItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FaqFeedbackModuleItemOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaqFeedbackModuleItemFilter>;
};

export type QueryFaqHeroArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFaqHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FaqHeroOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaqHeroFilter>;
};

export type QueryFaqModuleArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFaqModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FaqModuleOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaqModuleFilter>;
};

export type QueryFaqModuleItemArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFaqModuleItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FaqModuleItemOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaqModuleItemFilter>;
};

export type QueryFaqPopularQuestionsArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFaqPopularQuestionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FaqPopularQuestionsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaqPopularQuestionsFilter>;
};

export type QueryFaqSearchHelpArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFaqSearchHelpCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FaqSearchHelpOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaqSearchHelpFilter>;
};

export type QueryFaqSearchHelpItemsArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFaqSearchHelpItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FaqSearchHelpItemsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaqSearchHelpItemsFilter>;
};

export type QueryFaqTopicsModuleArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFaqTopicsModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FaqTopicsModuleOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaqTopicsModuleFilter>;
};

export type QueryFaqTopicsModuleItemsArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFaqTopicsModuleItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FaqTopicsModuleItemsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaqTopicsModuleItemsFilter>;
};

export type QueryFeatureFlagArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFeatureFlagCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FeatureFlagOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FeatureFlagFilter>;
};

export type QueryFooterArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFooterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FooterOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FooterFilter>;
};

export type QueryFooterNavigationGroupArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFooterNavigationGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FooterNavigationGroupOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FooterNavigationGroupFilter>;
};

export type QueryFooterNavigationGroupExtrasArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFooterNavigationGroupExtrasCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FooterNavigationGroupExtrasOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FooterNavigationGroupExtrasFilter>;
};

export type QueryForgottenPasswordPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryForgottenPasswordPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ForgottenPasswordPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ForgottenPasswordPageFilter>;
};

export type QueryFullWidthBannerArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFullWidthBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FullWidthBannerOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FullWidthBannerFilter>;
};

export type QueryGenericErrorPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGenericErrorPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<GenericErrorPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GenericErrorPageFilter>;
};

export type QueryHeroSlotArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryHeroSlotCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<HeroSlotOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HeroSlotFilter>;
};

export type QueryHowDoesItWorkArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryHowDoesItWorkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<HowDoesItWorkOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HowDoesItWorkFilter>;
};

export type QueryHowItWorksStepRelaunchArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryHowItWorksStepRelaunchCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<HowItWorksStepRelaunchOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HowItWorksStepRelaunchFilter>;
};

export type QueryHowSendingWorksArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryHowSendingWorksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<HowSendingWorksOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HowSendingWorksFilter>;
};

export type QueryIconWithTextArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryIconWithTextCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<IconWithTextOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IconWithTextFilter>;
};

export type QueryImageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ImageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ImageFilter>;
};

export type QueryInformationDialogArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryInformationDialogCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<InformationDialogOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InformationDialogFilter>;
};

export type QueryJazzCashPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryJazzCashPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<JazzCashPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<JazzCashPageFilter>;
};

export type QueryJazzCashRequestArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryJazzCashRequestCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<JazzCashRequestOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<JazzCashRequestFilter>;
};

export type QueryLayoutFooterArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryLayoutFooterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<LayoutFooterOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LayoutFooterFilter>;
};

export type QueryLayoutHeaderArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryLayoutHeaderCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<LayoutHeaderOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LayoutHeaderFilter>;
};

export type QueryLegalMessagingArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryLegalMessagingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<LegalMessagingOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LegalMessagingFilter>;
};

export type QueryLinkArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<LinkOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LinkFilter>;
};

export type QueryListOfCountriesArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryListOfCountriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ListOfCountriesOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ListOfCountriesFilter>;
};

export type QueryLoginPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryLoginPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<LoginPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LoginPageFilter>;
};

export type QueryLogoArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryLogoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<LogoOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LogoFilter>;
};

export type QueryMemberArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryMemberCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<MemberOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MemberFilter>;
};

export type QueryMembersListArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryMembersListCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<MembersListOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MembersListFilter>;
};

export type QueryMessagesArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryMessagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<MessagesOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MessagesFilter>;
};

export type QueryMfaFactorsArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryMfaFactorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<MfaFactorsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MfaFactorsFilter>;
};

export type QueryMfaVerificationArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryMfaVerificationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<MfaVerificationOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MfaVerificationFilter>;
};

export type QueryMobileAppLeadingLinksArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryMobileAppLeadingLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<MobileAppLeadingLinksOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MobileAppLeadingLinksFilter>;
};

export type QueryMobileStoreDataArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryMobileStoreDataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<MobileStoreDataOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MobileStoreDataFilter>;
};

export type QueryMoneyRequestConfirmationDialogArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryMoneyRequestConfirmationDialogCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<MoneyRequestConfirmationDialogOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MoneyRequestConfirmationDialogFilter>;
};

export type QueryMoneyRequestPayArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryMoneyRequestPayCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<MoneyRequestPayOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MoneyRequestPayFilter>;
};

export type QueryMoneyRequestPaymentReasonArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryMoneyRequestPaymentReasonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<MoneyRequestPaymentReasonOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MoneyRequestPaymentReasonFilter>;
};

export type QueryMoneyTransferArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryMoneyTransferCardArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryMoneyTransferCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<MoneyTransferCardOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MoneyTransferCardFilter>;
};

export type QueryMoneyTransferCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<MoneyTransferOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MoneyTransferFilter>;
};

export type QueryNotFoundContentArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryNotFoundContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<NotFoundContentOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NotFoundContentFilter>;
};

export type QueryNotFoundErrorPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryNotFoundErrorPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<NotFoundErrorPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NotFoundErrorPageFilter>;
};

export type QueryNotFoundPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryNotFoundPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<NotFoundPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NotFoundPageFilter>;
};

export type QueryOnfidoErrorPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryOnfidoErrorPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<OnfidoErrorPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OnfidoErrorPageFilter>;
};

export type QueryOurContactInformationArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryOurContactInformationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<OurContactInformationOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OurContactInformationFilter>;
};

export type QueryPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PageFilter>;
};

export type QueryPageSectionArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPageSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageSectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PageSectionFilter>;
};

export type QueryPaymentReasonArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPaymentReasonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<PaymentReasonOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PaymentReasonFilter>;
};

export type QueryPayoutMethodArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPayoutMethodAlertArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPayoutMethodAlertCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<PayoutMethodAlertOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PayoutMethodAlertFilter>;
};

export type QueryPayoutMethodCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<PayoutMethodOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PayoutMethodFilter>;
};

export type QueryPersonArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPersonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<PersonOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PersonFilter>;
};

export type QueryPhoneNumberVerificationPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPhoneNumberVerificationPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<PhoneNumberVerificationPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PhoneNumberVerificationPageFilter>;
};

export type QueryPopularCountriesArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPopularCountriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<PopularCountriesOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PopularCountriesFilter>;
};

export type QueryPriceComparsionArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPriceComparsionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<PriceComparsionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PriceComparsionFilter>;
};

export type QueryPricingCalculatorArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPricingCalculatorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<PricingCalculatorOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PricingCalculatorFilter>;
};

export type QueryPromoApplicabilityArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPromoApplicabilityCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<PromoApplicabilityOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromoApplicabilityFilter>;
};

export type QueryPromoMessageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPromoMessageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<PromoMessageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromoMessageFilter>;
};

export type QueryPromotionalBannerArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPromotionalBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<PromotionalBannerOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromotionalBannerFilter>;
};

export type QueryPromotionalBannerDisclaimerArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPromotionalBannerDisclaimerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<PromotionalBannerDisclaimerOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromotionalBannerDisclaimerFilter>;
};

export type QueryRedirectArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryRedirectCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<RedirectOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RedirectFilter>;
};

export type QueryReferAFriendGreenfieldEnabledCountriesArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryReferAFriendGreenfieldEnabledCountriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<ReferAFriendGreenfieldEnabledCountriesOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReferAFriendGreenfieldEnabledCountriesFilter>;
};

export type QueryReferFriendHeaderArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryReferFriendHeaderCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ReferFriendHeaderOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReferFriendHeaderFilter>;
};

export type QueryRegionCodeArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryRegionCodeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<RegionCodeOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RegionCodeFilter>;
};

export type QueryRegulatorArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryRegulatorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<RegulatorOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RegulatorFilter>;
};

export type QueryRequestMoneyNewRequestEmailArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryRequestMoneyNewRequestEmailCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<RequestMoneyNewRequestEmailOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RequestMoneyNewRequestEmailFilter>;
};

export type QueryRequestMoneyPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryRequestMoneyPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<RequestMoneyPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RequestMoneyPageFilter>;
};

export type QueryReviewArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryReviewCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ReviewOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReviewFilter>;
};

export type QueryReviewsArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ReviewsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReviewsFilter>;
};

export type QueryReviewsTimeDictionaryArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryReviewsTimeDictionaryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ReviewsTimeDictionaryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReviewsTimeDictionaryFilter>;
};

export type QueryRobotsConfigurationArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryRobotsConfigurationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<RobotsConfigurationOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RobotsConfigurationFilter>;
};

export type QuerySearchArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySearchCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<SearchOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SearchFilter>;
};

export type QuerySendToCountryModuleArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySendToCountryModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<SendToCountryModuleOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SendToCountryModuleFilter>;
};

export type QuerySenderFormPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySenderFormPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<SenderFormPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SenderFormPageFilter>;
};

export type QuerySendingPartnershipArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySendingPartnershipCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<SendingPartnershipOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SendingPartnershipFilter>;
};

export type QuerySeoContactPointArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySeoContactPointCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<SeoContactPointOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SeoContactPointFilter>;
};

export type QuerySeoOrganizationStructuredDataArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySeoOrganizationStructuredDataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<SeoOrganizationStructuredDataOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SeoOrganizationStructuredDataFilter>;
};

export type QuerySeoSameAsArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySeoSameAsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<SeoSameAsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SeoSameAsFilter>;
};

export type QueryShareSocialLinkArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryShareSocialLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ShareSocialLinkOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ShareSocialLinkFilter>;
};

export type QuerySignInArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySignInCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<SignInOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SignInFilter>;
};

export type QuerySitemapArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySitemapCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<SitemapOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SitemapFilter>;
};

export type QuerySmsVerificationCodePageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySmsVerificationCodePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<SmsVerificationCodePageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SmsVerificationCodePageFilter>;
};

export type QuerySocialProofingModuleArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySocialProofingModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<SocialProofingModuleOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SocialProofingModuleFilter>;
};

export type QuerySolutionsModuleArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySolutionsModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<SolutionsModuleOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SolutionsModuleFilter>;
};

export type QueryStepArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryStepByStepArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryStepByStepCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<StepByStepOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StepByStepFilter>;
};

export type QueryStepCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<StepOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StepFilter>;
};

export type QueryStickyBannerArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryStickyBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<StickyBannerOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StickyBannerFilter>;
};

export type QueryTagLinksArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryTagLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<TagLinksOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TagLinksFilter>;
};

export type QueryTextMeTheAppModuleArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryTextMeTheAppModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<TextMeTheAppModuleOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TextMeTheAppModuleFilter>;
};

export type QueryTextSectionArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryTextSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<TextSectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TextSectionFilter>;
};

export type QueryTextWithColorArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryTextWithColorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<TextWithColorOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TextWithColorFilter>;
};

export type QueryVerificationInProgressArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryVerificationInProgressCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<VerificationInProgressOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VerificationInProgressFilter>;
};

export type QueryVideoArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryVideoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<VideoOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VideoFilter>;
};

export type QueryWeAreRegulatedArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryWeAreRegulatedCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<WeAreRegulatedOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WeAreRegulatedFilter>;
};

export type QueryWebFormArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryWebFormCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<WebFormOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WebFormFilter>;
};

export type QueryWhyChooseArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryWhyChooseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<WhyChooseOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WhyChooseFilter>;
};

export type QueryWhyChooseReasonArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryWhyChooseReasonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<WhyChooseReasonOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WhyChooseReasonFilter>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/redirect) */
export type Redirect = Entry &
  _Node & {
    __typename?: 'Redirect';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    destination?: Maybe<Scalars['String']['output']>;
    from?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    fromRegex?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<RedirectLinkingCollections>;
    locale?: Maybe<Scalars['Boolean']['output']>;
    locales?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    name?: Maybe<Scalars['String']['output']>;
    permanent?: Maybe<Scalars['Boolean']['output']>;
    redirectToParentPage?: Maybe<Scalars['Boolean']['output']>;
    shouldKeepParameters?: Maybe<Scalars['Boolean']['output']>;
    source?: Maybe<Scalars['String']['output']>;
    status?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    to?: Maybe<Scalars['String']['output']>;
    toRegex?: Maybe<Scalars['String']['output']>;
  };

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/redirect) */
export type RedirectDestinationArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/redirect) */
export type RedirectFromArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/redirect) */
export type RedirectFromRegexArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/redirect) */
export type RedirectLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/redirect) */
export type RedirectLocaleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/redirect) */
export type RedirectLocalesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/redirect) */
export type RedirectNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/redirect) */
export type RedirectPermanentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/redirect) */
export type RedirectRedirectToParentPageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/redirect) */
export type RedirectShouldKeepParametersArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/redirect) */
export type RedirectSourceArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/redirect) */
export type RedirectStatusArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/redirect) */
export type RedirectToArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/redirect) */
export type RedirectToRegexArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type RedirectCollection = {
  __typename?: 'RedirectCollection';
  items: Array<Maybe<Redirect>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RedirectFilter = {
  AND?: InputMaybe<Array<InputMaybe<RedirectFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<RedirectFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  destination?: InputMaybe<Scalars['String']['input']>;
  destination_contains?: InputMaybe<Scalars['String']['input']>;
  destination_exists?: InputMaybe<Scalars['Boolean']['input']>;
  destination_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  destination_not?: InputMaybe<Scalars['String']['input']>;
  destination_not_contains?: InputMaybe<Scalars['String']['input']>;
  destination_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  fromRegex?: InputMaybe<Scalars['String']['input']>;
  fromRegex_contains?: InputMaybe<Scalars['String']['input']>;
  fromRegex_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fromRegex_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fromRegex_not?: InputMaybe<Scalars['String']['input']>;
  fromRegex_not_contains?: InputMaybe<Scalars['String']['input']>;
  fromRegex_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  from_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  from_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  from_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  from_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_not?: InputMaybe<Scalars['Boolean']['input']>;
  locales_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  locales_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  locales_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  locales_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  permanent?: InputMaybe<Scalars['Boolean']['input']>;
  permanent_exists?: InputMaybe<Scalars['Boolean']['input']>;
  permanent_not?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToParentPage?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToParentPage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToParentPage_not?: InputMaybe<Scalars['Boolean']['input']>;
  shouldKeepParameters?: InputMaybe<Scalars['Boolean']['input']>;
  shouldKeepParameters_exists?: InputMaybe<Scalars['Boolean']['input']>;
  shouldKeepParameters_not?: InputMaybe<Scalars['Boolean']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  source_contains?: InputMaybe<Scalars['String']['input']>;
  source_exists?: InputMaybe<Scalars['Boolean']['input']>;
  source_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  source_not?: InputMaybe<Scalars['String']['input']>;
  source_not_contains?: InputMaybe<Scalars['String']['input']>;
  source_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<Scalars['String']['input']>;
  status_contains?: InputMaybe<Scalars['String']['input']>;
  status_exists?: InputMaybe<Scalars['Boolean']['input']>;
  status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status_not?: InputMaybe<Scalars['String']['input']>;
  status_not_contains?: InputMaybe<Scalars['String']['input']>;
  status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  to?: InputMaybe<Scalars['String']['input']>;
  toRegex?: InputMaybe<Scalars['String']['input']>;
  toRegex_contains?: InputMaybe<Scalars['String']['input']>;
  toRegex_exists?: InputMaybe<Scalars['Boolean']['input']>;
  toRegex_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  toRegex_not?: InputMaybe<Scalars['String']['input']>;
  toRegex_not_contains?: InputMaybe<Scalars['String']['input']>;
  toRegex_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  to_contains?: InputMaybe<Scalars['String']['input']>;
  to_exists?: InputMaybe<Scalars['Boolean']['input']>;
  to_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  to_not?: InputMaybe<Scalars['String']['input']>;
  to_not_contains?: InputMaybe<Scalars['String']['input']>;
  to_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RedirectLinkingCollections = {
  __typename?: 'RedirectLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type RedirectLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum RedirectOrder {
  DestinationAsc = 'destination_ASC',
  DestinationDesc = 'destination_DESC',
  FromRegexAsc = 'fromRegex_ASC',
  FromRegexDesc = 'fromRegex_DESC',
  LocaleAsc = 'locale_ASC',
  LocaleDesc = 'locale_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PermanentAsc = 'permanent_ASC',
  PermanentDesc = 'permanent_DESC',
  RedirectToParentPageAsc = 'redirectToParentPage_ASC',
  RedirectToParentPageDesc = 'redirectToParentPage_DESC',
  ShouldKeepParametersAsc = 'shouldKeepParameters_ASC',
  ShouldKeepParametersDesc = 'shouldKeepParameters_DESC',
  SourceAsc = 'source_ASC',
  SourceDesc = 'source_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ToRegexAsc = 'toRegex_ASC',
  ToRegexDesc = 'toRegex_DESC',
  ToAsc = 'to_ASC',
  ToDesc = 'to_DESC',
}

/** There are the countries that are enabled in our loyalty program [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/referAFriendGreenfieldEnabledCountries) */
export type ReferAFriendGreenfieldEnabledCountries = Entry &
  _Node & {
    __typename?: 'ReferAFriendGreenfieldEnabledCountries';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    greenfieldCountriesCollection?: Maybe<ReferAFriendGreenfieldEnabledCountriesGreenfieldCountriesCollection>;
    linkedFrom?: Maybe<ReferAFriendGreenfieldEnabledCountriesLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** There are the countries that are enabled in our loyalty program [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/referAFriendGreenfieldEnabledCountries) */
export type ReferAFriendGreenfieldEnabledCountriesGreenfieldCountriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<ReferAFriendGreenfieldEnabledCountriesGreenfieldCountriesCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CountryFilter>;
};

/** There are the countries that are enabled in our loyalty program [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/referAFriendGreenfieldEnabledCountries) */
export type ReferAFriendGreenfieldEnabledCountriesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** There are the countries that are enabled in our loyalty program [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/referAFriendGreenfieldEnabledCountries) */
export type ReferAFriendGreenfieldEnabledCountriesNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type ReferAFriendGreenfieldEnabledCountriesCollection = {
  __typename?: 'ReferAFriendGreenfieldEnabledCountriesCollection';
  items: Array<Maybe<ReferAFriendGreenfieldEnabledCountries>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ReferAFriendGreenfieldEnabledCountriesFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ReferAFriendGreenfieldEnabledCountriesFilter>>
  >;
  OR?: InputMaybe<
    Array<InputMaybe<ReferAFriendGreenfieldEnabledCountriesFilter>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  greenfieldCountries?: InputMaybe<CfCountryNestedFilter>;
  greenfieldCountriesCollection_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type ReferAFriendGreenfieldEnabledCountriesGreenfieldCountriesCollection = {
  __typename?: 'ReferAFriendGreenfieldEnabledCountriesGreenfieldCountriesCollection';
  items: Array<Maybe<Country>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ReferAFriendGreenfieldEnabledCountriesGreenfieldCountriesCollectionOrder {
  ArticleAsc = 'article_ASC',
  ArticleDesc = 'article_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrepositionFromAsc = 'prepositionFrom_ASC',
  PrepositionFromDesc = 'prepositionFrom_DESC',
  PrepositionInAsc = 'prepositionIn_ASC',
  PrepositionInDesc = 'prepositionIn_DESC',
  PrepositionToAsc = 'prepositionTo_ASC',
  PrepositionToDesc = 'prepositionTo_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ReferAFriendGreenfieldEnabledCountriesLinkingCollections = {
  __typename?: 'ReferAFriendGreenfieldEnabledCountriesLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type ReferAFriendGreenfieldEnabledCountriesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ReferAFriendGreenfieldEnabledCountriesOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/referFriendHeader)
 */
export type ReferFriendHeader = Entry &
  _Node & {
    __typename?: 'ReferFriendHeader';
    _id: Scalars['ID']['output'];
    backgroundColor?: Maybe<Scalars['String']['output']>;
    backgroundImage?: Maybe<Asset>;
    backgroundImageMobile?: Maybe<Asset>;
    borderColor?: Maybe<Scalars['String']['output']>;
    contentWidth?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    heading?: Maybe<Scalars['String']['output']>;
    hintText?: Maybe<Scalars['String']['output']>;
    image?: Maybe<Asset>;
    linkedFrom?: Maybe<ReferFriendHeaderLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    secondBackgroundColor?: Maybe<Scalars['String']['output']>;
    secondBorderColor?: Maybe<Scalars['String']['output']>;
    signedOutButton?: Maybe<Link>;
    subheading?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    textAlign?: Maybe<Scalars['String']['output']>;
    textColor?: Maybe<Scalars['String']['output']>;
    textVerticalAlign?: Maybe<Scalars['String']['output']>;
  };

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/referFriendHeader)
 */
export type ReferFriendHeaderBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/referFriendHeader)
 */
export type ReferFriendHeaderBackgroundImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/referFriendHeader)
 */
export type ReferFriendHeaderBackgroundImageMobileArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/referFriendHeader)
 */
export type ReferFriendHeaderBorderColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/referFriendHeader)
 */
export type ReferFriendHeaderContentWidthArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/referFriendHeader)
 */
export type ReferFriendHeaderHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/referFriendHeader)
 */
export type ReferFriendHeaderHintTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/referFriendHeader)
 */
export type ReferFriendHeaderImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/referFriendHeader)
 */
export type ReferFriendHeaderLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/referFriendHeader)
 */
export type ReferFriendHeaderNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/referFriendHeader)
 */
export type ReferFriendHeaderSecondBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/referFriendHeader)
 */
export type ReferFriendHeaderSecondBorderColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/referFriendHeader)
 */
export type ReferFriendHeaderSignedOutButtonArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/referFriendHeader)
 */
export type ReferFriendHeaderSubheadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/referFriendHeader)
 */
export type ReferFriendHeaderTextAlignArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/referFriendHeader)
 */
export type ReferFriendHeaderTextColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/referFriendHeader)
 */
export type ReferFriendHeaderTextVerticalAlignArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type ReferFriendHeaderCollection = {
  __typename?: 'ReferFriendHeaderCollection';
  items: Array<Maybe<ReferFriendHeader>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ReferFriendHeaderFilter = {
  AND?: InputMaybe<Array<InputMaybe<ReferFriendHeaderFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ReferFriendHeaderFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundColor_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundImageMobile_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  borderColor?: InputMaybe<Scalars['String']['input']>;
  borderColor_contains?: InputMaybe<Scalars['String']['input']>;
  borderColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  borderColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  borderColor_not?: InputMaybe<Scalars['String']['input']>;
  borderColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  borderColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentWidth?: InputMaybe<Scalars['String']['input']>;
  contentWidth_contains?: InputMaybe<Scalars['String']['input']>;
  contentWidth_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentWidth_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentWidth_not?: InputMaybe<Scalars['String']['input']>;
  contentWidth_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentWidth_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  heading?: InputMaybe<Scalars['String']['input']>;
  heading_contains?: InputMaybe<Scalars['String']['input']>;
  heading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heading_not?: InputMaybe<Scalars['String']['input']>;
  heading_not_contains?: InputMaybe<Scalars['String']['input']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hintText?: InputMaybe<Scalars['String']['input']>;
  hintText_contains?: InputMaybe<Scalars['String']['input']>;
  hintText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hintText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hintText_not?: InputMaybe<Scalars['String']['input']>;
  hintText_not_contains?: InputMaybe<Scalars['String']['input']>;
  hintText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  secondBackgroundColor?: InputMaybe<Scalars['String']['input']>;
  secondBackgroundColor_contains?: InputMaybe<Scalars['String']['input']>;
  secondBackgroundColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  secondBackgroundColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  secondBackgroundColor_not?: InputMaybe<Scalars['String']['input']>;
  secondBackgroundColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  secondBackgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  secondBorderColor?: InputMaybe<Scalars['String']['input']>;
  secondBorderColor_contains?: InputMaybe<Scalars['String']['input']>;
  secondBorderColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  secondBorderColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  secondBorderColor_not?: InputMaybe<Scalars['String']['input']>;
  secondBorderColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  secondBorderColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  signedOutButton?: InputMaybe<CfLinkNestedFilter>;
  signedOutButton_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subheading?: InputMaybe<Scalars['String']['input']>;
  subheading_contains?: InputMaybe<Scalars['String']['input']>;
  subheading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subheading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subheading_not?: InputMaybe<Scalars['String']['input']>;
  subheading_not_contains?: InputMaybe<Scalars['String']['input']>;
  subheading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  textAlign?: InputMaybe<Scalars['String']['input']>;
  textAlign_contains?: InputMaybe<Scalars['String']['input']>;
  textAlign_exists?: InputMaybe<Scalars['Boolean']['input']>;
  textAlign_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  textAlign_not?: InputMaybe<Scalars['String']['input']>;
  textAlign_not_contains?: InputMaybe<Scalars['String']['input']>;
  textAlign_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  textColor?: InputMaybe<Scalars['String']['input']>;
  textColor_contains?: InputMaybe<Scalars['String']['input']>;
  textColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  textColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  textColor_not?: InputMaybe<Scalars['String']['input']>;
  textColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  textColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  textVerticalAlign?: InputMaybe<Scalars['String']['input']>;
  textVerticalAlign_contains?: InputMaybe<Scalars['String']['input']>;
  textVerticalAlign_exists?: InputMaybe<Scalars['Boolean']['input']>;
  textVerticalAlign_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  textVerticalAlign_not?: InputMaybe<Scalars['String']['input']>;
  textVerticalAlign_not_contains?: InputMaybe<Scalars['String']['input']>;
  textVerticalAlign_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
};

export type ReferFriendHeaderLinkingCollections = {
  __typename?: 'ReferFriendHeaderLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type ReferFriendHeaderLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ReferFriendHeaderOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BorderColorAsc = 'borderColor_ASC',
  BorderColorDesc = 'borderColor_DESC',
  ContentWidthAsc = 'contentWidth_ASC',
  ContentWidthDesc = 'contentWidth_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  HintTextAsc = 'hintText_ASC',
  HintTextDesc = 'hintText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  SecondBorderColorAsc = 'secondBorderColor_ASC',
  SecondBorderColorDesc = 'secondBorderColor_DESC',
  SubheadingAsc = 'subheading_ASC',
  SubheadingDesc = 'subheading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAlignAsc = 'textAlign_ASC',
  TextAlignDesc = 'textAlign_DESC',
  TextColorAsc = 'textColor_ASC',
  TextColorDesc = 'textColor_DESC',
  TextVerticalAlignAsc = 'textVerticalAlign_ASC',
  TextVerticalAlignDesc = 'textVerticalAlign_DESC',
}

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/regionCode) */
export type RegionCode = Entry &
  _Node & {
    __typename?: 'RegionCode';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    daDk?: Maybe<Scalars['Boolean']['output']>;
    en?: Maybe<Scalars['Boolean']['output']>;
    enAu?: Maybe<Scalars['Boolean']['output']>;
    enCa?: Maybe<Scalars['Boolean']['output']>;
    enGb?: Maybe<Scalars['Boolean']['output']>;
    enMy?: Maybe<Scalars['Boolean']['output']>;
    enNz?: Maybe<Scalars['Boolean']['output']>;
    enSe?: Maybe<Scalars['Boolean']['output']>;
    enUs?: Maybe<Scalars['Boolean']['output']>;
    esUs?: Maybe<Scalars['Boolean']['output']>;
    frCa?: Maybe<Scalars['Boolean']['output']>;
    linkedFrom?: Maybe<RegionCodeLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/regionCode) */
export type RegionCodeDaDkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/regionCode) */
export type RegionCodeEnArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/regionCode) */
export type RegionCodeEnAuArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/regionCode) */
export type RegionCodeEnCaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/regionCode) */
export type RegionCodeEnGbArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/regionCode) */
export type RegionCodeEnMyArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/regionCode) */
export type RegionCodeEnNzArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/regionCode) */
export type RegionCodeEnSeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/regionCode) */
export type RegionCodeEnUsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/regionCode) */
export type RegionCodeEsUsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/regionCode) */
export type RegionCodeFrCaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/regionCode) */
export type RegionCodeLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/regionCode) */
export type RegionCodeNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type RegionCodeCollection = {
  __typename?: 'RegionCodeCollection';
  items: Array<Maybe<RegionCode>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RegionCodeFilter = {
  AND?: InputMaybe<Array<InputMaybe<RegionCodeFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<RegionCodeFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  daDk?: InputMaybe<Scalars['Boolean']['input']>;
  daDk_exists?: InputMaybe<Scalars['Boolean']['input']>;
  daDk_not?: InputMaybe<Scalars['Boolean']['input']>;
  en?: InputMaybe<Scalars['Boolean']['input']>;
  enAu?: InputMaybe<Scalars['Boolean']['input']>;
  enAu_exists?: InputMaybe<Scalars['Boolean']['input']>;
  enAu_not?: InputMaybe<Scalars['Boolean']['input']>;
  enCa?: InputMaybe<Scalars['Boolean']['input']>;
  enCa_exists?: InputMaybe<Scalars['Boolean']['input']>;
  enCa_not?: InputMaybe<Scalars['Boolean']['input']>;
  enGb?: InputMaybe<Scalars['Boolean']['input']>;
  enGb_exists?: InputMaybe<Scalars['Boolean']['input']>;
  enGb_not?: InputMaybe<Scalars['Boolean']['input']>;
  enMy?: InputMaybe<Scalars['Boolean']['input']>;
  enMy_exists?: InputMaybe<Scalars['Boolean']['input']>;
  enMy_not?: InputMaybe<Scalars['Boolean']['input']>;
  enNz?: InputMaybe<Scalars['Boolean']['input']>;
  enNz_exists?: InputMaybe<Scalars['Boolean']['input']>;
  enNz_not?: InputMaybe<Scalars['Boolean']['input']>;
  enSe?: InputMaybe<Scalars['Boolean']['input']>;
  enSe_exists?: InputMaybe<Scalars['Boolean']['input']>;
  enSe_not?: InputMaybe<Scalars['Boolean']['input']>;
  enUs?: InputMaybe<Scalars['Boolean']['input']>;
  enUs_exists?: InputMaybe<Scalars['Boolean']['input']>;
  enUs_not?: InputMaybe<Scalars['Boolean']['input']>;
  en_exists?: InputMaybe<Scalars['Boolean']['input']>;
  en_not?: InputMaybe<Scalars['Boolean']['input']>;
  esUs?: InputMaybe<Scalars['Boolean']['input']>;
  esUs_exists?: InputMaybe<Scalars['Boolean']['input']>;
  esUs_not?: InputMaybe<Scalars['Boolean']['input']>;
  frCa?: InputMaybe<Scalars['Boolean']['input']>;
  frCa_exists?: InputMaybe<Scalars['Boolean']['input']>;
  frCa_not?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type RegionCodeLinkingCollections = {
  __typename?: 'RegionCodeLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type RegionCodeLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum RegionCodeOrder {
  DaDkAsc = 'daDk_ASC',
  DaDkDesc = 'daDk_DESC',
  EnAuAsc = 'enAu_ASC',
  EnAuDesc = 'enAu_DESC',
  EnCaAsc = 'enCa_ASC',
  EnCaDesc = 'enCa_DESC',
  EnGbAsc = 'enGb_ASC',
  EnGbDesc = 'enGb_DESC',
  EnMyAsc = 'enMy_ASC',
  EnMyDesc = 'enMy_DESC',
  EnNzAsc = 'enNz_ASC',
  EnNzDesc = 'enNz_DESC',
  EnSeAsc = 'enSe_ASC',
  EnSeDesc = 'enSe_DESC',
  EnUsAsc = 'enUs_ASC',
  EnUsDesc = 'enUs_DESC',
  EnAsc = 'en_ASC',
  EnDesc = 'en_DESC',
  EsUsAsc = 'esUs_ASC',
  EsUsDesc = 'esUs_DESC',
  FrCaAsc = 'frCa_ASC',
  FrCaDesc = 'frCa_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [web-cms] Country code with regulatory body [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/regulator) */
export type Regulator = Entry &
  _Node & {
    __typename?: 'Regulator';
    _id: Scalars['ID']['output'];
    code?: Maybe<Scalars['String']['output']>;
    content?: Maybe<RegulatorContent>;
    contentfulMetadata: ContentfulMetadata;
    countriesListCollection?: Maybe<RegulatorCountriesListCollection>;
    customFlagCode?: Maybe<Scalars['String']['output']>;
    customRegulatorName?: Maybe<Scalars['String']['output']>;
    description?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<RegulatorLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** [web-cms] Country code with regulatory body [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/regulator) */
export type RegulatorCodeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Country code with regulatory body [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/regulator) */
export type RegulatorContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Country code with regulatory body [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/regulator) */
export type RegulatorCountriesListCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<RegulatorCountriesListCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CountryFilter>;
};

/** [web-cms] Country code with regulatory body [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/regulator) */
export type RegulatorCustomFlagCodeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Country code with regulatory body [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/regulator) */
export type RegulatorCustomRegulatorNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Country code with regulatory body [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/regulator) */
export type RegulatorDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Country code with regulatory body [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/regulator) */
export type RegulatorLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-cms] Country code with regulatory body [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/regulator) */
export type RegulatorNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type RegulatorCollection = {
  __typename?: 'RegulatorCollection';
  items: Array<Maybe<Regulator>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RegulatorContent = {
  __typename?: 'RegulatorContent';
  json: Scalars['JSON']['output'];
  links: RegulatorContentLinks;
};

export type RegulatorContentAssets = {
  __typename?: 'RegulatorContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type RegulatorContentEntries = {
  __typename?: 'RegulatorContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type RegulatorContentLinks = {
  __typename?: 'RegulatorContentLinks';
  assets: RegulatorContentAssets;
  entries: RegulatorContentEntries;
  resources: RegulatorContentResources;
};

export type RegulatorContentResources = {
  __typename?: 'RegulatorContentResources';
  block: Array<RegulatorContentResourcesBlock>;
  hyperlink: Array<RegulatorContentResourcesHyperlink>;
  inline: Array<RegulatorContentResourcesInline>;
};

export type RegulatorContentResourcesBlock = ResourceLink & {
  __typename?: 'RegulatorContentResourcesBlock';
  sys: ResourceSys;
};

export type RegulatorContentResourcesHyperlink = ResourceLink & {
  __typename?: 'RegulatorContentResourcesHyperlink';
  sys: ResourceSys;
};

export type RegulatorContentResourcesInline = ResourceLink & {
  __typename?: 'RegulatorContentResourcesInline';
  sys: ResourceSys;
};

export type RegulatorCountriesListCollection = {
  __typename?: 'RegulatorCountriesListCollection';
  items: Array<Maybe<Country>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum RegulatorCountriesListCollectionOrder {
  ArticleAsc = 'article_ASC',
  ArticleDesc = 'article_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrepositionFromAsc = 'prepositionFrom_ASC',
  PrepositionFromDesc = 'prepositionFrom_DESC',
  PrepositionInAsc = 'prepositionIn_ASC',
  PrepositionInDesc = 'prepositionIn_DESC',
  PrepositionToAsc = 'prepositionTo_ASC',
  PrepositionToDesc = 'prepositionTo_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type RegulatorFilter = {
  AND?: InputMaybe<Array<InputMaybe<RegulatorFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<RegulatorFilter>>>;
  code?: InputMaybe<Scalars['String']['input']>;
  code_contains?: InputMaybe<Scalars['String']['input']>;
  code_exists?: InputMaybe<Scalars['Boolean']['input']>;
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  code_not?: InputMaybe<Scalars['String']['input']>;
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  content_contains?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  countriesList?: InputMaybe<CfCountryNestedFilter>;
  countriesListCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  customFlagCode?: InputMaybe<Scalars['String']['input']>;
  customFlagCode_contains?: InputMaybe<Scalars['String']['input']>;
  customFlagCode_exists?: InputMaybe<Scalars['Boolean']['input']>;
  customFlagCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  customFlagCode_not?: InputMaybe<Scalars['String']['input']>;
  customFlagCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  customFlagCode_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  customRegulatorName?: InputMaybe<Scalars['String']['input']>;
  customRegulatorName_contains?: InputMaybe<Scalars['String']['input']>;
  customRegulatorName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  customRegulatorName_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  customRegulatorName_not?: InputMaybe<Scalars['String']['input']>;
  customRegulatorName_not_contains?: InputMaybe<Scalars['String']['input']>;
  customRegulatorName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type RegulatorLinkingCollections = {
  __typename?: 'RegulatorLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  weAreRegulatedCollection?: Maybe<WeAreRegulatedCollection>;
};

export type RegulatorLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type RegulatorLinkingCollectionsWeAreRegulatedCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<RegulatorLinkingCollectionsWeAreRegulatedCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum RegulatorLinkingCollectionsWeAreRegulatedCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RegulatorTitleAsc = 'regulatorTitle_ASC',
  RegulatorTitleDesc = 'regulatorTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum RegulatorOrder {
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  CustomFlagCodeAsc = 'customFlagCode_ASC',
  CustomFlagCodeDesc = 'customFlagCode_DESC',
  CustomRegulatorNameAsc = 'customRegulatorName_ASC',
  CustomRegulatorNameDesc = 'customRegulatorName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Assets for the email sent to a prospective sender when a new money request is created by a recipient. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/requestMoneyNewRequestEmail) */
export type RequestMoneyNewRequestEmail = Entry &
  _Node & {
    __typename?: 'RequestMoneyNewRequestEmail';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<RequestMoneyNewRequestEmailLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    quotationMarksCollection?: Maybe<AssetCollection>;
    socialMediaLogosCollection?: Maybe<AssetCollection>;
    sys: Sys;
    trustpilotStarsAndLogoCollection?: Maybe<AssetCollection>;
    worldRemitLogo?: Maybe<Asset>;
  };

/** Assets for the email sent to a prospective sender when a new money request is created by a recipient. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/requestMoneyNewRequestEmail) */
export type RequestMoneyNewRequestEmailLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Assets for the email sent to a prospective sender when a new money request is created by a recipient. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/requestMoneyNewRequestEmail) */
export type RequestMoneyNewRequestEmailNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Assets for the email sent to a prospective sender when a new money request is created by a recipient. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/requestMoneyNewRequestEmail) */
export type RequestMoneyNewRequestEmailQuotationMarksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** Assets for the email sent to a prospective sender when a new money request is created by a recipient. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/requestMoneyNewRequestEmail) */
export type RequestMoneyNewRequestEmailSocialMediaLogosCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** Assets for the email sent to a prospective sender when a new money request is created by a recipient. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/requestMoneyNewRequestEmail) */
export type RequestMoneyNewRequestEmailTrustpilotStarsAndLogoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** Assets for the email sent to a prospective sender when a new money request is created by a recipient. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/requestMoneyNewRequestEmail) */
export type RequestMoneyNewRequestEmailWorldRemitLogoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RequestMoneyNewRequestEmailCollection = {
  __typename?: 'RequestMoneyNewRequestEmailCollection';
  items: Array<Maybe<RequestMoneyNewRequestEmail>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RequestMoneyNewRequestEmailFilter = {
  AND?: InputMaybe<Array<InputMaybe<RequestMoneyNewRequestEmailFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<RequestMoneyNewRequestEmailFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  quotationMarksCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  socialMediaLogosCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  trustpilotStarsAndLogoCollection_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  worldRemitLogo_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RequestMoneyNewRequestEmailLinkingCollections = {
  __typename?: 'RequestMoneyNewRequestEmailLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type RequestMoneyNewRequestEmailLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum RequestMoneyNewRequestEmailOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Content type used for creating request money page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/requestMoneyPage) */
export type RequestMoneyPage = Entry &
  _Node & {
    __typename?: 'RequestMoneyPage';
    _id: Scalars['ID']['output'];
    analyticsPageName?: Maybe<Scalars['String']['output']>;
    analyticsPageType?: Maybe<Scalars['String']['output']>;
    buildLocales?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    footer?: Maybe<LayoutFooter>;
    isTopPage?: Maybe<Scalars['Boolean']['output']>;
    linkedFrom?: Maybe<RequestMoneyPageLinkingCollections>;
    modulesCollection?: Maybe<RequestMoneyPageModulesCollection>;
    modulesForNotLoggedInCollection?: Maybe<RequestMoneyPageModulesForNotLoggedInCollection>;
    name?: Maybe<Scalars['String']['output']>;
    noIndex?: Maybe<Scalars['Boolean']['output']>;
    sitemap?: Maybe<Scalars['Boolean']['output']>;
    sitemapTitle?: Maybe<Scalars['String']['output']>;
    slug?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Content type used for creating request money page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/requestMoneyPage) */
export type RequestMoneyPageAnalyticsPageNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Content type used for creating request money page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/requestMoneyPage) */
export type RequestMoneyPageAnalyticsPageTypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Content type used for creating request money page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/requestMoneyPage) */
export type RequestMoneyPageBuildLocalesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Content type used for creating request money page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/requestMoneyPage) */
export type RequestMoneyPageDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Content type used for creating request money page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/requestMoneyPage) */
export type RequestMoneyPageFooterArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LayoutFooterFilter>;
};

/** Content type used for creating request money page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/requestMoneyPage) */
export type RequestMoneyPageIsTopPageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Content type used for creating request money page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/requestMoneyPage) */
export type RequestMoneyPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Content type used for creating request money page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/requestMoneyPage) */
export type RequestMoneyPageModulesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** Content type used for creating request money page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/requestMoneyPage) */
export type RequestMoneyPageModulesForNotLoggedInCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** Content type used for creating request money page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/requestMoneyPage) */
export type RequestMoneyPageNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Content type used for creating request money page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/requestMoneyPage) */
export type RequestMoneyPageNoIndexArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Content type used for creating request money page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/requestMoneyPage) */
export type RequestMoneyPageSitemapArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Content type used for creating request money page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/requestMoneyPage) */
export type RequestMoneyPageSitemapTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Content type used for creating request money page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/requestMoneyPage) */
export type RequestMoneyPageSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Content type used for creating request money page template. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/requestMoneyPage) */
export type RequestMoneyPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type RequestMoneyPageCollection = {
  __typename?: 'RequestMoneyPageCollection';
  items: Array<Maybe<RequestMoneyPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RequestMoneyPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<RequestMoneyPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<RequestMoneyPageFilter>>>;
  analyticsPageName?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  analyticsPageName_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  analyticsPageName_not?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_not_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  analyticsPageType?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  analyticsPageType_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  analyticsPageType_not?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_not_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  footer?: InputMaybe<CfLayoutFooterNestedFilter>;
  footer_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isTopPage?: InputMaybe<Scalars['Boolean']['input']>;
  isTopPage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isTopPage_not?: InputMaybe<Scalars['Boolean']['input']>;
  modulesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  modulesForNotLoggedInCollection_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noIndex?: InputMaybe<Scalars['Boolean']['input']>;
  noIndex_exists?: InputMaybe<Scalars['Boolean']['input']>;
  noIndex_not?: InputMaybe<Scalars['Boolean']['input']>;
  sitemap?: InputMaybe<Scalars['Boolean']['input']>;
  sitemapTitle?: InputMaybe<Scalars['String']['input']>;
  sitemapTitle_contains?: InputMaybe<Scalars['String']['input']>;
  sitemapTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sitemapTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sitemapTitle_not?: InputMaybe<Scalars['String']['input']>;
  sitemapTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  sitemapTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sitemap_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sitemap_not?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RequestMoneyPageLinkingCollections = {
  __typename?: 'RequestMoneyPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type RequestMoneyPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type RequestMoneyPageModulesCollection = {
  __typename?: 'RequestMoneyPageModulesCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RequestMoneyPageModulesForNotLoggedInCollection = {
  __typename?: 'RequestMoneyPageModulesForNotLoggedInCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum RequestMoneyPageOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IsTopPageAsc = 'isTopPage_ASC',
  IsTopPageDesc = 'isTopPage_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ResourceLink = {
  sys: ResourceSys;
};

export type ResourceSys = {
  __typename?: 'ResourceSys';
  linkType: Scalars['String']['output'];
  urn: Scalars['String']['output'];
};

/**
 * [web-cms] Reviews which display on the home page.
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4597%3A145493&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/review)
 */
export type Review = Entry &
  _Node & {
    __typename?: 'Review';
    _id: Scalars['ID']['output'];
    comment?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    countryCode?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<ReviewLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    reviewDate?: Maybe<Scalars['DateTime']['output']>;
    reviewer?: Maybe<Scalars['String']['output']>;
    reviewsNumberText?: Maybe<Scalars['String']['output']>;
    stars?: Maybe<Scalars['Int']['output']>;
    sys: Sys;
  };

/**
 * [web-cms] Reviews which display on the home page.
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4597%3A145493&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/review)
 */
export type ReviewCommentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * [web-cms] Reviews which display on the home page.
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4597%3A145493&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/review)
 */
export type ReviewCountryCodeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * [web-cms] Reviews which display on the home page.
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4597%3A145493&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/review)
 */
export type ReviewLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/**
 * [web-cms] Reviews which display on the home page.
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4597%3A145493&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/review)
 */
export type ReviewNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * [web-cms] Reviews which display on the home page.
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4597%3A145493&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/review)
 */
export type ReviewReviewDateArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * [web-cms] Reviews which display on the home page.
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4597%3A145493&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/review)
 */
export type ReviewReviewerArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * [web-cms] Reviews which display on the home page.
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4597%3A145493&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/review)
 */
export type ReviewReviewsNumberTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * [web-cms] Reviews which display on the home page.
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4597%3A145493&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/review)
 */
export type ReviewStarsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type ReviewCollection = {
  __typename?: 'ReviewCollection';
  items: Array<Maybe<Review>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ReviewFilter = {
  AND?: InputMaybe<Array<InputMaybe<ReviewFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ReviewFilter>>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  comment_contains?: InputMaybe<Scalars['String']['input']>;
  comment_exists?: InputMaybe<Scalars['Boolean']['input']>;
  comment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  comment_not?: InputMaybe<Scalars['String']['input']>;
  comment_not_contains?: InputMaybe<Scalars['String']['input']>;
  comment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  countryCode_contains?: InputMaybe<Scalars['String']['input']>;
  countryCode_exists?: InputMaybe<Scalars['Boolean']['input']>;
  countryCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  countryCode_not?: InputMaybe<Scalars['String']['input']>;
  countryCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  countryCode_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reviewDate?: InputMaybe<Scalars['DateTime']['input']>;
  reviewDate_exists?: InputMaybe<Scalars['Boolean']['input']>;
  reviewDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  reviewDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  reviewDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  reviewDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  reviewDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  reviewDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  reviewDate_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['DateTime']['input']>>
  >;
  reviewer?: InputMaybe<Scalars['String']['input']>;
  reviewer_contains?: InputMaybe<Scalars['String']['input']>;
  reviewer_exists?: InputMaybe<Scalars['Boolean']['input']>;
  reviewer_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reviewer_not?: InputMaybe<Scalars['String']['input']>;
  reviewer_not_contains?: InputMaybe<Scalars['String']['input']>;
  reviewer_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reviewsNumberText?: InputMaybe<Scalars['String']['input']>;
  reviewsNumberText_contains?: InputMaybe<Scalars['String']['input']>;
  reviewsNumberText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  reviewsNumberText_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  reviewsNumberText_not?: InputMaybe<Scalars['String']['input']>;
  reviewsNumberText_not_contains?: InputMaybe<Scalars['String']['input']>;
  reviewsNumberText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  stars?: InputMaybe<Scalars['Int']['input']>;
  stars_exists?: InputMaybe<Scalars['Boolean']['input']>;
  stars_gt?: InputMaybe<Scalars['Int']['input']>;
  stars_gte?: InputMaybe<Scalars['Int']['input']>;
  stars_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  stars_lt?: InputMaybe<Scalars['Int']['input']>;
  stars_lte?: InputMaybe<Scalars['Int']['input']>;
  stars_not?: InputMaybe<Scalars['Int']['input']>;
  stars_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type ReviewLinkingCollections = {
  __typename?: 'ReviewLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  reviewsCollection?: Maybe<ReviewsCollection>;
};

export type ReviewLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ReviewLinkingCollectionsReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<ReviewLinkingCollectionsReviewsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ReviewLinkingCollectionsReviewsCollectionOrder {
  CarouselTextAsc = 'carouselText_ASC',
  CarouselTextDesc = 'carouselText_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  HideTrustpilotHeaderAsc = 'hideTrustpilotHeader_ASC',
  HideTrustpilotHeaderDesc = 'hideTrustpilotHeader_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ReviewsNumberLabelAsc = 'reviewsNumberLabel_ASC',
  ReviewsNumberLabelDesc = 'reviewsNumberLabel_DESC',
  ReviewsTotalScoreAsc = 'reviewsTotalScore_ASC',
  ReviewsTotalScoreDesc = 'reviewsTotalScore_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ReviewOrder {
  CountryCodeAsc = 'countryCode_ASC',
  CountryCodeDesc = 'countryCode_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ReviewDateAsc = 'reviewDate_ASC',
  ReviewDateDesc = 'reviewDate_DESC',
  ReviewerAsc = 'reviewer_ASC',
  ReviewerDesc = 'reviewer_DESC',
  ReviewsNumberTextAsc = 'reviewsNumberText_ASC',
  ReviewsNumberTextDesc = 'reviewsNumberText_DESC',
  StarsAsc = 'stars_ASC',
  StarsDesc = 'stars_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4597%3A145493&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/reviews) */
export type Reviews = Entry &
  _Node & {
    __typename?: 'Reviews';
    _id: Scalars['ID']['output'];
    avatarIcon?: Maybe<Asset>;
    carouselText?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    heading?: Maybe<Scalars['String']['output']>;
    hideTrustpilotHeader?: Maybe<Scalars['Boolean']['output']>;
    linkedFrom?: Maybe<ReviewsLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    reviewsCollection?: Maybe<ReviewsReviewsCollection>;
    reviewsNumberLabel?: Maybe<Scalars['String']['output']>;
    reviewsTimeTranslation?: Maybe<ReviewsTimeDictionary>;
    reviewsTotalScore?: Maybe<Scalars['Float']['output']>;
    starIcon?: Maybe<Asset>;
    sys: Sys;
    trustpilotLink?: Maybe<Link>;
    trustpilotLogo?: Maybe<Asset>;
  };

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4597%3A145493&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/reviews) */
export type ReviewsAvatarIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4597%3A145493&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/reviews) */
export type ReviewsCarouselTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4597%3A145493&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/reviews) */
export type ReviewsHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4597%3A145493&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/reviews) */
export type ReviewsHideTrustpilotHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4597%3A145493&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/reviews) */
export type ReviewsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4597%3A145493&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/reviews) */
export type ReviewsNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4597%3A145493&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/reviews) */
export type ReviewsReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ReviewsReviewsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReviewFilter>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4597%3A145493&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/reviews) */
export type ReviewsReviewsNumberLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4597%3A145493&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/reviews) */
export type ReviewsReviewsTimeTranslationArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ReviewsTimeDictionaryFilter>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4597%3A145493&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/reviews) */
export type ReviewsReviewsTotalScoreArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4597%3A145493&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/reviews) */
export type ReviewsStarIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4597%3A145493&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/reviews) */
export type ReviewsTrustpilotLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4597%3A145493&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/reviews) */
export type ReviewsTrustpilotLogoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReviewsCollection = {
  __typename?: 'ReviewsCollection';
  items: Array<Maybe<Reviews>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ReviewsFilter = {
  AND?: InputMaybe<Array<InputMaybe<ReviewsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ReviewsFilter>>>;
  avatarIcon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  carouselText?: InputMaybe<Scalars['String']['input']>;
  carouselText_contains?: InputMaybe<Scalars['String']['input']>;
  carouselText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  carouselText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  carouselText_not?: InputMaybe<Scalars['String']['input']>;
  carouselText_not_contains?: InputMaybe<Scalars['String']['input']>;
  carouselText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  heading?: InputMaybe<Scalars['String']['input']>;
  heading_contains?: InputMaybe<Scalars['String']['input']>;
  heading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heading_not?: InputMaybe<Scalars['String']['input']>;
  heading_not_contains?: InputMaybe<Scalars['String']['input']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hideTrustpilotHeader?: InputMaybe<Scalars['Boolean']['input']>;
  hideTrustpilotHeader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hideTrustpilotHeader_not?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reviews?: InputMaybe<CfReviewNestedFilter>;
  reviewsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  reviewsNumberLabel?: InputMaybe<Scalars['String']['input']>;
  reviewsNumberLabel_contains?: InputMaybe<Scalars['String']['input']>;
  reviewsNumberLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  reviewsNumberLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  reviewsNumberLabel_not?: InputMaybe<Scalars['String']['input']>;
  reviewsNumberLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  reviewsNumberLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  reviewsTimeTranslation?: InputMaybe<CfReviewsTimeDictionaryNestedFilter>;
  reviewsTimeTranslation_exists?: InputMaybe<Scalars['Boolean']['input']>;
  reviewsTotalScore?: InputMaybe<Scalars['Float']['input']>;
  reviewsTotalScore_exists?: InputMaybe<Scalars['Boolean']['input']>;
  reviewsTotalScore_gt?: InputMaybe<Scalars['Float']['input']>;
  reviewsTotalScore_gte?: InputMaybe<Scalars['Float']['input']>;
  reviewsTotalScore_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
  reviewsTotalScore_lt?: InputMaybe<Scalars['Float']['input']>;
  reviewsTotalScore_lte?: InputMaybe<Scalars['Float']['input']>;
  reviewsTotalScore_not?: InputMaybe<Scalars['Float']['input']>;
  reviewsTotalScore_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
  starIcon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  trustpilotLink?: InputMaybe<CfLinkNestedFilter>;
  trustpilotLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  trustpilotLogo_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReviewsLinkingCollections = {
  __typename?: 'ReviewsLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pageSectionCollection?: Maybe<PageSectionCollection>;
};

export type ReviewsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ReviewsLinkingCollectionsPageSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<ReviewsLinkingCollectionsPageSectionCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ReviewsLinkingCollectionsPageSectionCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundGradientAsc = 'backgroundGradient_ASC',
  BackgroundGradientDesc = 'backgroundGradient_DESC',
  BackgroundTypeAsc = 'backgroundType_ASC',
  BackgroundTypeDesc = 'backgroundType_DESC',
  EnablePaddingAsc = 'enablePadding_ASC',
  EnablePaddingDesc = 'enablePadding_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ReviewsOrder {
  CarouselTextAsc = 'carouselText_ASC',
  CarouselTextDesc = 'carouselText_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  HideTrustpilotHeaderAsc = 'hideTrustpilotHeader_ASC',
  HideTrustpilotHeaderDesc = 'hideTrustpilotHeader_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ReviewsNumberLabelAsc = 'reviewsNumberLabel_ASC',
  ReviewsNumberLabelDesc = 'reviewsNumberLabel_DESC',
  ReviewsTotalScoreAsc = 'reviewsTotalScore_ASC',
  ReviewsTotalScoreDesc = 'reviewsTotalScore_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ReviewsReviewsCollection = {
  __typename?: 'ReviewsReviewsCollection';
  items: Array<Maybe<Review>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ReviewsReviewsCollectionOrder {
  CountryCodeAsc = 'countryCode_ASC',
  CountryCodeDesc = 'countryCode_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ReviewDateAsc = 'reviewDate_ASC',
  ReviewDateDesc = 'reviewDate_DESC',
  ReviewerAsc = 'reviewer_ASC',
  ReviewerDesc = 'reviewer_DESC',
  ReviewsNumberTextAsc = 'reviewsNumberText_ASC',
  ReviewsNumberTextDesc = 'reviewsNumberText_DESC',
  StarsAsc = 'stars_ASC',
  StarsDesc = 'stars_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/reviewsTimeDictionary) */
export type ReviewsTimeDictionary = Entry &
  _Node & {
    __typename?: 'ReviewsTimeDictionary';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    fewDaysAgo?: Maybe<Scalars['String']['output']>;
    fewMonthsAgo?: Maybe<Scalars['String']['output']>;
    fewWeeksAgo?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<ReviewsTimeDictionaryLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    oneDayAgo?: Maybe<Scalars['String']['output']>;
    oneMonthAgo?: Maybe<Scalars['String']['output']>;
    oneWeekAgo?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    today?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/reviewsTimeDictionary) */
export type ReviewsTimeDictionaryFewDaysAgoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/reviewsTimeDictionary) */
export type ReviewsTimeDictionaryFewMonthsAgoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/reviewsTimeDictionary) */
export type ReviewsTimeDictionaryFewWeeksAgoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/reviewsTimeDictionary) */
export type ReviewsTimeDictionaryLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/reviewsTimeDictionary) */
export type ReviewsTimeDictionaryNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/reviewsTimeDictionary) */
export type ReviewsTimeDictionaryOneDayAgoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/reviewsTimeDictionary) */
export type ReviewsTimeDictionaryOneMonthAgoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/reviewsTimeDictionary) */
export type ReviewsTimeDictionaryOneWeekAgoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/reviewsTimeDictionary) */
export type ReviewsTimeDictionaryTodayArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type ReviewsTimeDictionaryCollection = {
  __typename?: 'ReviewsTimeDictionaryCollection';
  items: Array<Maybe<ReviewsTimeDictionary>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ReviewsTimeDictionaryFilter = {
  AND?: InputMaybe<Array<InputMaybe<ReviewsTimeDictionaryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ReviewsTimeDictionaryFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  fewDaysAgo?: InputMaybe<Scalars['String']['input']>;
  fewDaysAgo_contains?: InputMaybe<Scalars['String']['input']>;
  fewDaysAgo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fewDaysAgo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fewDaysAgo_not?: InputMaybe<Scalars['String']['input']>;
  fewDaysAgo_not_contains?: InputMaybe<Scalars['String']['input']>;
  fewDaysAgo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fewMonthsAgo?: InputMaybe<Scalars['String']['input']>;
  fewMonthsAgo_contains?: InputMaybe<Scalars['String']['input']>;
  fewMonthsAgo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fewMonthsAgo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fewMonthsAgo_not?: InputMaybe<Scalars['String']['input']>;
  fewMonthsAgo_not_contains?: InputMaybe<Scalars['String']['input']>;
  fewMonthsAgo_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  fewWeeksAgo?: InputMaybe<Scalars['String']['input']>;
  fewWeeksAgo_contains?: InputMaybe<Scalars['String']['input']>;
  fewWeeksAgo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fewWeeksAgo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fewWeeksAgo_not?: InputMaybe<Scalars['String']['input']>;
  fewWeeksAgo_not_contains?: InputMaybe<Scalars['String']['input']>;
  fewWeeksAgo_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  oneDayAgo?: InputMaybe<Scalars['String']['input']>;
  oneDayAgo_contains?: InputMaybe<Scalars['String']['input']>;
  oneDayAgo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  oneDayAgo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  oneDayAgo_not?: InputMaybe<Scalars['String']['input']>;
  oneDayAgo_not_contains?: InputMaybe<Scalars['String']['input']>;
  oneDayAgo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  oneMonthAgo?: InputMaybe<Scalars['String']['input']>;
  oneMonthAgo_contains?: InputMaybe<Scalars['String']['input']>;
  oneMonthAgo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  oneMonthAgo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  oneMonthAgo_not?: InputMaybe<Scalars['String']['input']>;
  oneMonthAgo_not_contains?: InputMaybe<Scalars['String']['input']>;
  oneMonthAgo_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  oneWeekAgo?: InputMaybe<Scalars['String']['input']>;
  oneWeekAgo_contains?: InputMaybe<Scalars['String']['input']>;
  oneWeekAgo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  oneWeekAgo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  oneWeekAgo_not?: InputMaybe<Scalars['String']['input']>;
  oneWeekAgo_not_contains?: InputMaybe<Scalars['String']['input']>;
  oneWeekAgo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  today?: InputMaybe<Scalars['String']['input']>;
  today_contains?: InputMaybe<Scalars['String']['input']>;
  today_exists?: InputMaybe<Scalars['Boolean']['input']>;
  today_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  today_not?: InputMaybe<Scalars['String']['input']>;
  today_not_contains?: InputMaybe<Scalars['String']['input']>;
  today_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ReviewsTimeDictionaryLinkingCollections = {
  __typename?: 'ReviewsTimeDictionaryLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  reviewsCollection?: Maybe<ReviewsCollection>;
};

export type ReviewsTimeDictionaryLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ReviewsTimeDictionaryLinkingCollectionsReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<ReviewsTimeDictionaryLinkingCollectionsReviewsCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ReviewsTimeDictionaryLinkingCollectionsReviewsCollectionOrder {
  CarouselTextAsc = 'carouselText_ASC',
  CarouselTextDesc = 'carouselText_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  HideTrustpilotHeaderAsc = 'hideTrustpilotHeader_ASC',
  HideTrustpilotHeaderDesc = 'hideTrustpilotHeader_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ReviewsNumberLabelAsc = 'reviewsNumberLabel_ASC',
  ReviewsNumberLabelDesc = 'reviewsNumberLabel_DESC',
  ReviewsTotalScoreAsc = 'reviewsTotalScore_ASC',
  ReviewsTotalScoreDesc = 'reviewsTotalScore_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ReviewsTimeDictionaryOrder {
  FewDaysAgoAsc = 'fewDaysAgo_ASC',
  FewDaysAgoDesc = 'fewDaysAgo_DESC',
  FewMonthsAgoAsc = 'fewMonthsAgo_ASC',
  FewMonthsAgoDesc = 'fewMonthsAgo_DESC',
  FewWeeksAgoAsc = 'fewWeeksAgo_ASC',
  FewWeeksAgoDesc = 'fewWeeksAgo_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OneDayAgoAsc = 'oneDayAgo_ASC',
  OneDayAgoDesc = 'oneDayAgo_DESC',
  OneMonthAgoAsc = 'oneMonthAgo_ASC',
  OneMonthAgoDesc = 'oneMonthAgo_DESC',
  OneWeekAgoAsc = 'oneWeekAgo_ASC',
  OneWeekAgoDesc = 'oneWeekAgo_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TodayAsc = 'today_ASC',
  TodayDesc = 'today_DESC',
}

/** [web-cms] Configuration for robots.txt file. For more information https://moz.com/learn/seo/robotstxt [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/robotsConfiguration) */
export type RobotsConfiguration = Entry &
  _Node & {
    __typename?: 'RobotsConfiguration';
    _id: Scalars['ID']['output'];
    allow?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    blogSitemapUrl?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    disallow?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    linkedFrom?: Maybe<RobotsConfigurationLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sitemaps?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sys: Sys;
    userAgent?: Maybe<Scalars['String']['output']>;
  };

/** [web-cms] Configuration for robots.txt file. For more information https://moz.com/learn/seo/robotstxt [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/robotsConfiguration) */
export type RobotsConfigurationAllowArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Configuration for robots.txt file. For more information https://moz.com/learn/seo/robotstxt [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/robotsConfiguration) */
export type RobotsConfigurationBlogSitemapUrlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Configuration for robots.txt file. For more information https://moz.com/learn/seo/robotstxt [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/robotsConfiguration) */
export type RobotsConfigurationDisallowArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Configuration for robots.txt file. For more information https://moz.com/learn/seo/robotstxt [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/robotsConfiguration) */
export type RobotsConfigurationLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-cms] Configuration for robots.txt file. For more information https://moz.com/learn/seo/robotstxt [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/robotsConfiguration) */
export type RobotsConfigurationNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Configuration for robots.txt file. For more information https://moz.com/learn/seo/robotstxt [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/robotsConfiguration) */
export type RobotsConfigurationSitemapsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Configuration for robots.txt file. For more information https://moz.com/learn/seo/robotstxt [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/robotsConfiguration) */
export type RobotsConfigurationUserAgentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type RobotsConfigurationCollection = {
  __typename?: 'RobotsConfigurationCollection';
  items: Array<Maybe<RobotsConfiguration>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RobotsConfigurationFilter = {
  AND?: InputMaybe<Array<InputMaybe<RobotsConfigurationFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<RobotsConfigurationFilter>>>;
  allow_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  allow_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  allow_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  allow_exists?: InputMaybe<Scalars['Boolean']['input']>;
  blogSitemapUrl?: InputMaybe<Scalars['String']['input']>;
  blogSitemapUrl_contains?: InputMaybe<Scalars['String']['input']>;
  blogSitemapUrl_exists?: InputMaybe<Scalars['Boolean']['input']>;
  blogSitemapUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  blogSitemapUrl_not?: InputMaybe<Scalars['String']['input']>;
  blogSitemapUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  blogSitemapUrl_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  disallow_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  disallow_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  disallow_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  disallow_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sitemaps_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sitemaps_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sitemaps_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sitemaps_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
  userAgent_contains?: InputMaybe<Scalars['String']['input']>;
  userAgent_exists?: InputMaybe<Scalars['Boolean']['input']>;
  userAgent_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userAgent_not?: InputMaybe<Scalars['String']['input']>;
  userAgent_not_contains?: InputMaybe<Scalars['String']['input']>;
  userAgent_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RobotsConfigurationLinkingCollections = {
  __typename?: 'RobotsConfigurationLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type RobotsConfigurationLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum RobotsConfigurationOrder {
  BlogSitemapUrlAsc = 'blogSitemapUrl_ASC',
  BlogSitemapUrlDesc = 'blogSitemapUrl_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UserAgentAsc = 'userAgent_ASC',
  UserAgentDesc = 'userAgent_DESC',
}

/** [web-cms] Search module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/search) */
export type Search = Entry &
  _Node & {
    __typename?: 'Search';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    heading?: Maybe<Scalars['String']['output']>;
    inputLabel?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<SearchLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    resultListLabel?: Maybe<Scalars['String']['output']>;
    suggestionListCollection?: Maybe<SearchSuggestionListCollection>;
    suggestionListLabel?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** [web-cms] Search module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/search) */
export type SearchHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Search module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/search) */
export type SearchInputLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Search module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/search) */
export type SearchLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-cms] Search module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/search) */
export type SearchNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Search module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/search) */
export type SearchResultListLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Search module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/search) */
export type SearchSuggestionListCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<SearchSuggestionListCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PageFilter>;
};

/** [web-cms] Search module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/search) */
export type SearchSuggestionListLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type SearchCollection = {
  __typename?: 'SearchCollection';
  items: Array<Maybe<Search>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type SearchFilter = {
  AND?: InputMaybe<Array<InputMaybe<SearchFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SearchFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  heading?: InputMaybe<Scalars['String']['input']>;
  heading_contains?: InputMaybe<Scalars['String']['input']>;
  heading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heading_not?: InputMaybe<Scalars['String']['input']>;
  heading_not_contains?: InputMaybe<Scalars['String']['input']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  inputLabel?: InputMaybe<Scalars['String']['input']>;
  inputLabel_contains?: InputMaybe<Scalars['String']['input']>;
  inputLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  inputLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  inputLabel_not?: InputMaybe<Scalars['String']['input']>;
  inputLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  inputLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  resultListLabel?: InputMaybe<Scalars['String']['input']>;
  resultListLabel_contains?: InputMaybe<Scalars['String']['input']>;
  resultListLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  resultListLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  resultListLabel_not?: InputMaybe<Scalars['String']['input']>;
  resultListLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  resultListLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  suggestionList?: InputMaybe<CfPageNestedFilter>;
  suggestionListCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  suggestionListLabel?: InputMaybe<Scalars['String']['input']>;
  suggestionListLabel_contains?: InputMaybe<Scalars['String']['input']>;
  suggestionListLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  suggestionListLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  suggestionListLabel_not?: InputMaybe<Scalars['String']['input']>;
  suggestionListLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  suggestionListLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
};

export type SearchLinkingCollections = {
  __typename?: 'SearchLinkingCollections';
  betterWayTopCollection?: Maybe<BetterWayTopCollection>;
  entryCollection?: Maybe<EntryCollection>;
  faqHeroCollection?: Maybe<FaqHeroCollection>;
};

export type SearchLinkingCollectionsBetterWayTopCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<SearchLinkingCollectionsBetterWayTopCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchLinkingCollectionsFaqHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<SearchLinkingCollectionsFaqHeroCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum SearchLinkingCollectionsBetterWayTopCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentAlignmentAsc = 'contentAlignment_ASC',
  ContentAlignmentDesc = 'contentAlignment_DESC',
  ContentFlexDirectionAsc = 'contentFlexDirection_ASC',
  ContentFlexDirectionDesc = 'contentFlexDirection_DESC',
  HeadingColorAsc = 'headingColor_ASC',
  HeadingColorDesc = 'headingColor_DESC',
  HideDescriptionOnMobileAsc = 'hideDescriptionOnMobile_ASC',
  HideDescriptionOnMobileDesc = 'hideDescriptionOnMobile_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrustpilotMessageAsc = 'trustpilotMessage_ASC',
  TrustpilotMessageDesc = 'trustpilotMessage_DESC',
}

export enum SearchLinkingCollectionsFaqHeroCollectionOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  MenuTitleAsc = 'menuTitle_ASC',
  MenuTitleDesc = 'menuTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrimaryBackgroundColorAsc = 'primaryBackgroundColor_ASC',
  PrimaryBackgroundColorDesc = 'primaryBackgroundColor_DESC',
  SecondaryBackgroundColorAsc = 'secondaryBackgroundColor_ASC',
  SecondaryBackgroundColorDesc = 'secondaryBackgroundColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SearchOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  InputLabelAsc = 'inputLabel_ASC',
  InputLabelDesc = 'inputLabel_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ResultListLabelAsc = 'resultListLabel_ASC',
  ResultListLabelDesc = 'resultListLabel_DESC',
  SuggestionListLabelAsc = 'suggestionListLabel_ASC',
  SuggestionListLabelDesc = 'suggestionListLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type SearchSuggestionListCollection = {
  __typename?: 'SearchSuggestionListCollection';
  items: Array<Maybe<Page>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum SearchSuggestionListCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderPositionAsc = 'headerPosition_ASC',
  HeaderPositionDesc = 'headerPosition_DESC',
  HeaderThemeAsc = 'headerTheme_ASC',
  HeaderThemeDesc = 'headerTheme_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimeToReadAsc = 'timeToRead_ASC',
  TimeToReadDesc = 'timeToRead_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

/** Module with destination country search field, supposed to be used instead of lite calculator on home page (if a/b test results will be good) [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/sendToCountryModule) */
export type SendToCountryModule = Entry &
  _Node & {
    __typename?: 'SendToCountryModule';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    continueButtonText?: Maybe<Scalars['String']['output']>;
    description?: Maybe<SendToCountryModuleDescription>;
    linkedFrom?: Maybe<SendToCountryModuleLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    searchPlaceholder?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** Module with destination country search field, supposed to be used instead of lite calculator on home page (if a/b test results will be good) [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/sendToCountryModule) */
export type SendToCountryModuleContinueButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Module with destination country search field, supposed to be used instead of lite calculator on home page (if a/b test results will be good) [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/sendToCountryModule) */
export type SendToCountryModuleDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Module with destination country search field, supposed to be used instead of lite calculator on home page (if a/b test results will be good) [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/sendToCountryModule) */
export type SendToCountryModuleLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Module with destination country search field, supposed to be used instead of lite calculator on home page (if a/b test results will be good) [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/sendToCountryModule) */
export type SendToCountryModuleNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Module with destination country search field, supposed to be used instead of lite calculator on home page (if a/b test results will be good) [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/sendToCountryModule) */
export type SendToCountryModuleSearchPlaceholderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type SendToCountryModuleCollection = {
  __typename?: 'SendToCountryModuleCollection';
  items: Array<Maybe<SendToCountryModule>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type SendToCountryModuleDescription = {
  __typename?: 'SendToCountryModuleDescription';
  json: Scalars['JSON']['output'];
  links: SendToCountryModuleDescriptionLinks;
};

export type SendToCountryModuleDescriptionAssets = {
  __typename?: 'SendToCountryModuleDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type SendToCountryModuleDescriptionEntries = {
  __typename?: 'SendToCountryModuleDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type SendToCountryModuleDescriptionLinks = {
  __typename?: 'SendToCountryModuleDescriptionLinks';
  assets: SendToCountryModuleDescriptionAssets;
  entries: SendToCountryModuleDescriptionEntries;
  resources: SendToCountryModuleDescriptionResources;
};

export type SendToCountryModuleDescriptionResources = {
  __typename?: 'SendToCountryModuleDescriptionResources';
  block: Array<SendToCountryModuleDescriptionResourcesBlock>;
  hyperlink: Array<SendToCountryModuleDescriptionResourcesHyperlink>;
  inline: Array<SendToCountryModuleDescriptionResourcesInline>;
};

export type SendToCountryModuleDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'SendToCountryModuleDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type SendToCountryModuleDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'SendToCountryModuleDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type SendToCountryModuleDescriptionResourcesInline = ResourceLink & {
  __typename?: 'SendToCountryModuleDescriptionResourcesInline';
  sys: ResourceSys;
};

export type SendToCountryModuleFilter = {
  AND?: InputMaybe<Array<InputMaybe<SendToCountryModuleFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SendToCountryModuleFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  continueButtonText?: InputMaybe<Scalars['String']['input']>;
  continueButtonText_contains?: InputMaybe<Scalars['String']['input']>;
  continueButtonText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  continueButtonText_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  continueButtonText_not?: InputMaybe<Scalars['String']['input']>;
  continueButtonText_not_contains?: InputMaybe<Scalars['String']['input']>;
  continueButtonText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  searchPlaceholder?: InputMaybe<Scalars['String']['input']>;
  searchPlaceholder_contains?: InputMaybe<Scalars['String']['input']>;
  searchPlaceholder_exists?: InputMaybe<Scalars['Boolean']['input']>;
  searchPlaceholder_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  searchPlaceholder_not?: InputMaybe<Scalars['String']['input']>;
  searchPlaceholder_not_contains?: InputMaybe<Scalars['String']['input']>;
  searchPlaceholder_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
};

export type SendToCountryModuleLinkingCollections = {
  __typename?: 'SendToCountryModuleLinkingCollections';
  betterWayTopCollection?: Maybe<BetterWayTopCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type SendToCountryModuleLinkingCollectionsBetterWayTopCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<SendToCountryModuleLinkingCollectionsBetterWayTopCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SendToCountryModuleLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum SendToCountryModuleLinkingCollectionsBetterWayTopCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentAlignmentAsc = 'contentAlignment_ASC',
  ContentAlignmentDesc = 'contentAlignment_DESC',
  ContentFlexDirectionAsc = 'contentFlexDirection_ASC',
  ContentFlexDirectionDesc = 'contentFlexDirection_DESC',
  HeadingColorAsc = 'headingColor_ASC',
  HeadingColorDesc = 'headingColor_DESC',
  HideDescriptionOnMobileAsc = 'hideDescriptionOnMobile_ASC',
  HideDescriptionOnMobileDesc = 'hideDescriptionOnMobile_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrustpilotMessageAsc = 'trustpilotMessage_ASC',
  TrustpilotMessageDesc = 'trustpilotMessage_DESC',
}

export enum SendToCountryModuleOrder {
  ContinueButtonTextAsc = 'continueButtonText_ASC',
  ContinueButtonTextDesc = 'continueButtonText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SearchPlaceholderAsc = 'searchPlaceholder_ASC',
  SearchPlaceholderDesc = 'searchPlaceholder_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [web-processes] Page which displays the beginning of KYC flow. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/senderFormPage) */
export type SenderFormPage = Entry &
  _Node & {
    __typename?: 'SenderFormPage';
    _id: Scalars['ID']['output'];
    addressLookupPlaceholder?: Maybe<Scalars['String']['output']>;
    breadcrumbCurrent?: Maybe<Scalars['String']['output']>;
    breadcrumbNext?: Maybe<Scalars['String']['output']>;
    buildLocales?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    disclaimer?: Maybe<SenderFormPageDisclaimer>;
    enhancement?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<SenderFormPageLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    stickyBanner?: Maybe<StickyBanner>;
    submitButtonLabel?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [web-processes] Page which displays the beginning of KYC flow. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/senderFormPage) */
export type SenderFormPageAddressLookupPlaceholderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Page which displays the beginning of KYC flow. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/senderFormPage) */
export type SenderFormPageBreadcrumbCurrentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Page which displays the beginning of KYC flow. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/senderFormPage) */
export type SenderFormPageBreadcrumbNextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Page which displays the beginning of KYC flow. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/senderFormPage) */
export type SenderFormPageBuildLocalesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Page which displays the beginning of KYC flow. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/senderFormPage) */
export type SenderFormPageDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Page which displays the beginning of KYC flow. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/senderFormPage) */
export type SenderFormPageDisclaimerArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Page which displays the beginning of KYC flow. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/senderFormPage) */
export type SenderFormPageEnhancementArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Page which displays the beginning of KYC flow. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/senderFormPage) */
export type SenderFormPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-processes] Page which displays the beginning of KYC flow. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/senderFormPage) */
export type SenderFormPageNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Page which displays the beginning of KYC flow. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/senderFormPage) */
export type SenderFormPageStickyBannerArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<StickyBannerFilter>;
};

/** [web-processes] Page which displays the beginning of KYC flow. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/senderFormPage) */
export type SenderFormPageSubmitButtonLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-processes] Page which displays the beginning of KYC flow. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/senderFormPage) */
export type SenderFormPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type SenderFormPageCollection = {
  __typename?: 'SenderFormPageCollection';
  items: Array<Maybe<SenderFormPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type SenderFormPageDisclaimer = {
  __typename?: 'SenderFormPageDisclaimer';
  json: Scalars['JSON']['output'];
  links: SenderFormPageDisclaimerLinks;
};

export type SenderFormPageDisclaimerAssets = {
  __typename?: 'SenderFormPageDisclaimerAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type SenderFormPageDisclaimerEntries = {
  __typename?: 'SenderFormPageDisclaimerEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type SenderFormPageDisclaimerLinks = {
  __typename?: 'SenderFormPageDisclaimerLinks';
  assets: SenderFormPageDisclaimerAssets;
  entries: SenderFormPageDisclaimerEntries;
  resources: SenderFormPageDisclaimerResources;
};

export type SenderFormPageDisclaimerResources = {
  __typename?: 'SenderFormPageDisclaimerResources';
  block: Array<SenderFormPageDisclaimerResourcesBlock>;
  hyperlink: Array<SenderFormPageDisclaimerResourcesHyperlink>;
  inline: Array<SenderFormPageDisclaimerResourcesInline>;
};

export type SenderFormPageDisclaimerResourcesBlock = ResourceLink & {
  __typename?: 'SenderFormPageDisclaimerResourcesBlock';
  sys: ResourceSys;
};

export type SenderFormPageDisclaimerResourcesHyperlink = ResourceLink & {
  __typename?: 'SenderFormPageDisclaimerResourcesHyperlink';
  sys: ResourceSys;
};

export type SenderFormPageDisclaimerResourcesInline = ResourceLink & {
  __typename?: 'SenderFormPageDisclaimerResourcesInline';
  sys: ResourceSys;
};

export type SenderFormPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<SenderFormPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SenderFormPageFilter>>>;
  addressLookupPlaceholder?: InputMaybe<Scalars['String']['input']>;
  addressLookupPlaceholder_contains?: InputMaybe<Scalars['String']['input']>;
  addressLookupPlaceholder_exists?: InputMaybe<Scalars['Boolean']['input']>;
  addressLookupPlaceholder_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  addressLookupPlaceholder_not?: InputMaybe<Scalars['String']['input']>;
  addressLookupPlaceholder_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  addressLookupPlaceholder_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  breadcrumbCurrent?: InputMaybe<Scalars['String']['input']>;
  breadcrumbCurrent_contains?: InputMaybe<Scalars['String']['input']>;
  breadcrumbCurrent_exists?: InputMaybe<Scalars['Boolean']['input']>;
  breadcrumbCurrent_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  breadcrumbCurrent_not?: InputMaybe<Scalars['String']['input']>;
  breadcrumbCurrent_not_contains?: InputMaybe<Scalars['String']['input']>;
  breadcrumbCurrent_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  breadcrumbNext?: InputMaybe<Scalars['String']['input']>;
  breadcrumbNext_contains?: InputMaybe<Scalars['String']['input']>;
  breadcrumbNext_exists?: InputMaybe<Scalars['Boolean']['input']>;
  breadcrumbNext_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  breadcrumbNext_not?: InputMaybe<Scalars['String']['input']>;
  breadcrumbNext_not_contains?: InputMaybe<Scalars['String']['input']>;
  breadcrumbNext_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  disclaimer_contains?: InputMaybe<Scalars['String']['input']>;
  disclaimer_exists?: InputMaybe<Scalars['Boolean']['input']>;
  disclaimer_not_contains?: InputMaybe<Scalars['String']['input']>;
  enhancement?: InputMaybe<Scalars['String']['input']>;
  enhancement_contains?: InputMaybe<Scalars['String']['input']>;
  enhancement_exists?: InputMaybe<Scalars['Boolean']['input']>;
  enhancement_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  enhancement_not?: InputMaybe<Scalars['String']['input']>;
  enhancement_not_contains?: InputMaybe<Scalars['String']['input']>;
  enhancement_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  stickyBanner?: InputMaybe<CfStickyBannerNestedFilter>;
  stickyBanner_exists?: InputMaybe<Scalars['Boolean']['input']>;
  submitButtonLabel?: InputMaybe<Scalars['String']['input']>;
  submitButtonLabel_contains?: InputMaybe<Scalars['String']['input']>;
  submitButtonLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  submitButtonLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  submitButtonLabel_not?: InputMaybe<Scalars['String']['input']>;
  submitButtonLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  submitButtonLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SenderFormPageLinkingCollections = {
  __typename?: 'SenderFormPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type SenderFormPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum SenderFormPageOrder {
  AddressLookupPlaceholderAsc = 'addressLookupPlaceholder_ASC',
  AddressLookupPlaceholderDesc = 'addressLookupPlaceholder_DESC',
  BreadcrumbCurrentAsc = 'breadcrumbCurrent_ASC',
  BreadcrumbCurrentDesc = 'breadcrumbCurrent_DESC',
  BreadcrumbNextAsc = 'breadcrumbNext_ASC',
  BreadcrumbNextDesc = 'breadcrumbNext_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  EnhancementAsc = 'enhancement_ASC',
  EnhancementDesc = 'enhancement_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubmitButtonLabelAsc = 'submitButtonLabel_ASC',
  SubmitButtonLabelDesc = 'submitButtonLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/sendingPartnership) */
export type SendingPartnership = Entry &
  _Node & {
    __typename?: 'SendingPartnership';
    _id: Scalars['ID']['output'];
    button?: Maybe<Link>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    header?: Maybe<Scalars['String']['output']>;
    image?: Maybe<Asset>;
    linkedFrom?: Maybe<SendingPartnershipLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/sendingPartnership) */
export type SendingPartnershipButtonArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/sendingPartnership) */
export type SendingPartnershipDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/sendingPartnership) */
export type SendingPartnershipHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/sendingPartnership) */
export type SendingPartnershipImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/sendingPartnership) */
export type SendingPartnershipLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/sendingPartnership) */
export type SendingPartnershipNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type SendingPartnershipCollection = {
  __typename?: 'SendingPartnershipCollection';
  items: Array<Maybe<SendingPartnership>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type SendingPartnershipFilter = {
  AND?: InputMaybe<Array<InputMaybe<SendingPartnershipFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SendingPartnershipFilter>>>;
  button?: InputMaybe<CfLinkNestedFilter>;
  button_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type SendingPartnershipLinkingCollections = {
  __typename?: 'SendingPartnershipLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type SendingPartnershipLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum SendingPartnershipOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/seoContactPoint) */
export type SeoContactPoint = Entry &
  _Node & {
    __typename?: 'SeoContactPoint';
    _id: Scalars['ID']['output'];
    areaServed?: Maybe<Scalars['String']['output']>;
    contactType?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<SeoContactPointLinkingCollections>;
    sys: Sys;
    telephone?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/seoContactPoint) */
export type SeoContactPointAreaServedArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/seoContactPoint) */
export type SeoContactPointContactTypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/seoContactPoint) */
export type SeoContactPointLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/seoContactPoint) */
export type SeoContactPointTelephoneArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type SeoContactPointCollection = {
  __typename?: 'SeoContactPointCollection';
  items: Array<Maybe<SeoContactPoint>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type SeoContactPointFilter = {
  AND?: InputMaybe<Array<InputMaybe<SeoContactPointFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SeoContactPointFilter>>>;
  areaServed?: InputMaybe<Scalars['String']['input']>;
  areaServed_contains?: InputMaybe<Scalars['String']['input']>;
  areaServed_exists?: InputMaybe<Scalars['Boolean']['input']>;
  areaServed_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  areaServed_not?: InputMaybe<Scalars['String']['input']>;
  areaServed_not_contains?: InputMaybe<Scalars['String']['input']>;
  areaServed_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contactType?: InputMaybe<Scalars['String']['input']>;
  contactType_contains?: InputMaybe<Scalars['String']['input']>;
  contactType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contactType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contactType_not?: InputMaybe<Scalars['String']['input']>;
  contactType_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactType_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  telephone?: InputMaybe<Scalars['String']['input']>;
  telephone_contains?: InputMaybe<Scalars['String']['input']>;
  telephone_exists?: InputMaybe<Scalars['Boolean']['input']>;
  telephone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  telephone_not?: InputMaybe<Scalars['String']['input']>;
  telephone_not_contains?: InputMaybe<Scalars['String']['input']>;
  telephone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SeoContactPointLinkingCollections = {
  __typename?: 'SeoContactPointLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  seoOrganizationStructuredDataCollection?: Maybe<SeoOrganizationStructuredDataCollection>;
};

export type SeoContactPointLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SeoContactPointLinkingCollectionsSeoOrganizationStructuredDataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<SeoContactPointLinkingCollectionsSeoOrganizationStructuredDataCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum SeoContactPointLinkingCollectionsSeoOrganizationStructuredDataCollectionOrder {
  AddressCountryAsc = 'addressCountry_ASC',
  AddressCountryDesc = 'addressCountry_DESC',
  AddressLocalityAsc = 'addressLocality_ASC',
  AddressLocalityDesc = 'addressLocality_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  LogoUrlAsc = 'logoUrl_ASC',
  LogoUrlDesc = 'logoUrl_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PostalCodeAsc = 'postalCode_ASC',
  PostalCodeDesc = 'postalCode_DESC',
  StreetAddressAsc = 'streetAddress_ASC',
  StreetAddressDesc = 'streetAddress_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

export enum SeoContactPointOrder {
  AreaServedAsc = 'areaServed_ASC',
  AreaServedDesc = 'areaServed_DESC',
  ContactTypeAsc = 'contactType_ASC',
  ContactTypeDesc = 'contactType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TelephoneAsc = 'telephone_ASC',
  TelephoneDesc = 'telephone_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredData = Entry &
  _Node & {
    __typename?: 'SeoOrganizationStructuredData';
    _id: Scalars['ID']['output'];
    addressCountry?: Maybe<Scalars['String']['output']>;
    addressLocality?: Maybe<Scalars['String']['output']>;
    contactPointCollection?: Maybe<SeoOrganizationStructuredDataContactPointCollection>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    email?: Maybe<Scalars['String']['output']>;
    image?: Maybe<Asset>;
    linkedFrom?: Maybe<SeoOrganizationStructuredDataLinkingCollections>;
    logoAsset?: Maybe<Asset>;
    logoUrl?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    postalCode?: Maybe<Scalars['String']['output']>;
    sameAsCollection?: Maybe<SeoOrganizationStructuredDataSameAsCollection>;
    streetAddress?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    url?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataAddressCountryArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataAddressLocalityArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataContactPointCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<SeoOrganizationStructuredDataContactPointCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SeoContactPointFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataEmailArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataLogoAssetArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataLogoUrlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataPostalCodeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataSameAsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<SeoOrganizationStructuredDataSameAsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SeoSameAsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataStreetAddressArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataUrlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type SeoOrganizationStructuredDataCollection = {
  __typename?: 'SeoOrganizationStructuredDataCollection';
  items: Array<Maybe<SeoOrganizationStructuredData>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type SeoOrganizationStructuredDataContactPointCollection = {
  __typename?: 'SeoOrganizationStructuredDataContactPointCollection';
  items: Array<Maybe<SeoContactPoint>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum SeoOrganizationStructuredDataContactPointCollectionOrder {
  AreaServedAsc = 'areaServed_ASC',
  AreaServedDesc = 'areaServed_DESC',
  ContactTypeAsc = 'contactType_ASC',
  ContactTypeDesc = 'contactType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TelephoneAsc = 'telephone_ASC',
  TelephoneDesc = 'telephone_DESC',
}

export type SeoOrganizationStructuredDataFilter = {
  AND?: InputMaybe<Array<InputMaybe<SeoOrganizationStructuredDataFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SeoOrganizationStructuredDataFilter>>>;
  addressCountry?: InputMaybe<Scalars['String']['input']>;
  addressCountry_contains?: InputMaybe<Scalars['String']['input']>;
  addressCountry_exists?: InputMaybe<Scalars['Boolean']['input']>;
  addressCountry_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  addressCountry_not?: InputMaybe<Scalars['String']['input']>;
  addressCountry_not_contains?: InputMaybe<Scalars['String']['input']>;
  addressCountry_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  addressLocality?: InputMaybe<Scalars['String']['input']>;
  addressLocality_contains?: InputMaybe<Scalars['String']['input']>;
  addressLocality_exists?: InputMaybe<Scalars['Boolean']['input']>;
  addressLocality_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  addressLocality_not?: InputMaybe<Scalars['String']['input']>;
  addressLocality_not_contains?: InputMaybe<Scalars['String']['input']>;
  addressLocality_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contactPoint?: InputMaybe<CfSeoContactPointNestedFilter>;
  contactPointCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  email?: InputMaybe<Scalars['String']['input']>;
  email_contains?: InputMaybe<Scalars['String']['input']>;
  email_exists?: InputMaybe<Scalars['Boolean']['input']>;
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email_not?: InputMaybe<Scalars['String']['input']>;
  email_not_contains?: InputMaybe<Scalars['String']['input']>;
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  logoAsset_exists?: InputMaybe<Scalars['Boolean']['input']>;
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  logoUrl_contains?: InputMaybe<Scalars['String']['input']>;
  logoUrl_exists?: InputMaybe<Scalars['Boolean']['input']>;
  logoUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  logoUrl_not?: InputMaybe<Scalars['String']['input']>;
  logoUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  logoUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  postalCode_contains?: InputMaybe<Scalars['String']['input']>;
  postalCode_exists?: InputMaybe<Scalars['Boolean']['input']>;
  postalCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode_not?: InputMaybe<Scalars['String']['input']>;
  postalCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  postalCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sameAs?: InputMaybe<CfSeoSameAsNestedFilter>;
  sameAsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  streetAddress_contains?: InputMaybe<Scalars['String']['input']>;
  streetAddress_exists?: InputMaybe<Scalars['Boolean']['input']>;
  streetAddress_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  streetAddress_not?: InputMaybe<Scalars['String']['input']>;
  streetAddress_not_contains?: InputMaybe<Scalars['String']['input']>;
  streetAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_contains?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_not?: InputMaybe<Scalars['String']['input']>;
  url_not_contains?: InputMaybe<Scalars['String']['input']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SeoOrganizationStructuredDataLinkingCollections = {
  __typename?: 'SeoOrganizationStructuredDataLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type SeoOrganizationStructuredDataLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum SeoOrganizationStructuredDataOrder {
  AddressCountryAsc = 'addressCountry_ASC',
  AddressCountryDesc = 'addressCountry_DESC',
  AddressLocalityAsc = 'addressLocality_ASC',
  AddressLocalityDesc = 'addressLocality_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  LogoUrlAsc = 'logoUrl_ASC',
  LogoUrlDesc = 'logoUrl_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PostalCodeAsc = 'postalCode_ASC',
  PostalCodeDesc = 'postalCode_DESC',
  StreetAddressAsc = 'streetAddress_ASC',
  StreetAddressDesc = 'streetAddress_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

export type SeoOrganizationStructuredDataSameAsCollection = {
  __typename?: 'SeoOrganizationStructuredDataSameAsCollection';
  items: Array<Maybe<SeoSameAs>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum SeoOrganizationStructuredDataSameAsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/seoSameAs) */
export type SeoSameAs = Entry &
  _Node & {
    __typename?: 'SeoSameAs';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<SeoSameAsLinkingCollections>;
    sys: Sys;
    value?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/seoSameAs) */
export type SeoSameAsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/seoSameAs) */
export type SeoSameAsValueArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type SeoSameAsCollection = {
  __typename?: 'SeoSameAsCollection';
  items: Array<Maybe<SeoSameAs>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type SeoSameAsFilter = {
  AND?: InputMaybe<Array<InputMaybe<SeoSameAsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SeoSameAsFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_exists?: InputMaybe<Scalars['Boolean']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SeoSameAsLinkingCollections = {
  __typename?: 'SeoSameAsLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  seoOrganizationStructuredDataCollection?: Maybe<SeoOrganizationStructuredDataCollection>;
};

export type SeoSameAsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SeoSameAsLinkingCollectionsSeoOrganizationStructuredDataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<SeoSameAsLinkingCollectionsSeoOrganizationStructuredDataCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum SeoSameAsLinkingCollectionsSeoOrganizationStructuredDataCollectionOrder {
  AddressCountryAsc = 'addressCountry_ASC',
  AddressCountryDesc = 'addressCountry_DESC',
  AddressLocalityAsc = 'addressLocality_ASC',
  AddressLocalityDesc = 'addressLocality_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  LogoUrlAsc = 'logoUrl_ASC',
  LogoUrlDesc = 'logoUrl_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PostalCodeAsc = 'postalCode_ASC',
  PostalCodeDesc = 'postalCode_DESC',
  StreetAddressAsc = 'streetAddress_ASC',
  StreetAddressDesc = 'streetAddress_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

export enum SeoSameAsOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/shareSocialLink) */
export type ShareSocialLink = Entry &
  _Node & {
    __typename?: 'ShareSocialLink';
    _id: Scalars['ID']['output'];
    backgroundColor?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    displayOnDesktop?: Maybe<Scalars['Boolean']['output']>;
    icon?: Maybe<Asset>;
    label?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<ShareSocialLinkLinkingCollections>;
    mobileUrl?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    preWrittenMessage?: Maybe<Scalars['String']['output']>;
    relaunchBackgroundColor?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    url?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/shareSocialLink) */
export type ShareSocialLinkBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/shareSocialLink) */
export type ShareSocialLinkDisplayOnDesktopArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/shareSocialLink) */
export type ShareSocialLinkIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/shareSocialLink) */
export type ShareSocialLinkLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/shareSocialLink) */
export type ShareSocialLinkLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/shareSocialLink) */
export type ShareSocialLinkMobileUrlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/shareSocialLink) */
export type ShareSocialLinkNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/shareSocialLink) */
export type ShareSocialLinkPreWrittenMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/shareSocialLink) */
export type ShareSocialLinkRelaunchBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/shareSocialLink) */
export type ShareSocialLinkUrlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type ShareSocialLinkCollection = {
  __typename?: 'ShareSocialLinkCollection';
  items: Array<Maybe<ShareSocialLink>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ShareSocialLinkFilter = {
  AND?: InputMaybe<Array<InputMaybe<ShareSocialLinkFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ShareSocialLinkFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundColor_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  displayOnDesktop?: InputMaybe<Scalars['Boolean']['input']>;
  displayOnDesktop_exists?: InputMaybe<Scalars['Boolean']['input']>;
  displayOnDesktop_not?: InputMaybe<Scalars['Boolean']['input']>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mobileUrl?: InputMaybe<Scalars['String']['input']>;
  mobileUrl_contains?: InputMaybe<Scalars['String']['input']>;
  mobileUrl_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mobileUrl_not?: InputMaybe<Scalars['String']['input']>;
  mobileUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  mobileUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preWrittenMessage?: InputMaybe<Scalars['String']['input']>;
  preWrittenMessage_contains?: InputMaybe<Scalars['String']['input']>;
  preWrittenMessage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  preWrittenMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  preWrittenMessage_not?: InputMaybe<Scalars['String']['input']>;
  preWrittenMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  preWrittenMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  relaunchBackgroundColor?: InputMaybe<Scalars['String']['input']>;
  relaunchBackgroundColor_contains?: InputMaybe<Scalars['String']['input']>;
  relaunchBackgroundColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  relaunchBackgroundColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  relaunchBackgroundColor_not?: InputMaybe<Scalars['String']['input']>;
  relaunchBackgroundColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  relaunchBackgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_contains?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_not?: InputMaybe<Scalars['String']['input']>;
  url_not_contains?: InputMaybe<Scalars['String']['input']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ShareSocialLinkLinkingCollections = {
  __typename?: 'ShareSocialLinkLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type ShareSocialLinkLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ShareSocialLinkOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  DisplayOnDesktopAsc = 'displayOnDesktop_ASC',
  DisplayOnDesktopDesc = 'displayOnDesktop_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RelaunchBackgroundColorAsc = 'relaunchBackgroundColor_ASC',
  RelaunchBackgroundColorDesc = 'relaunchBackgroundColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Section with buttons sign up and login [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/signIn) */
export type SignIn = Entry &
  _Node & {
    __typename?: 'SignIn';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<SignInLinkingCollections>;
    loginButton?: Maybe<Link>;
    loginDescription?: Maybe<Scalars['String']['output']>;
    loginTitle?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    signUpButton?: Maybe<Link>;
    signUpDescription?: Maybe<Scalars['String']['output']>;
    signUpTitle?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Section with buttons sign up and login [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/signIn) */
export type SignInLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Section with buttons sign up and login [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/signIn) */
export type SignInLoginButtonArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** Section with buttons sign up and login [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/signIn) */
export type SignInLoginDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Section with buttons sign up and login [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/signIn) */
export type SignInLoginTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Section with buttons sign up and login [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/signIn) */
export type SignInNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Section with buttons sign up and login [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/signIn) */
export type SignInSignUpButtonArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** Section with buttons sign up and login [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/signIn) */
export type SignInSignUpDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Section with buttons sign up and login [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/signIn) */
export type SignInSignUpTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Section with buttons sign up and login [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/signIn) */
export type SignInTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type SignInCollection = {
  __typename?: 'SignInCollection';
  items: Array<Maybe<SignIn>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type SignInFilter = {
  AND?: InputMaybe<Array<InputMaybe<SignInFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SignInFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  loginButton?: InputMaybe<CfLinkNestedFilter>;
  loginButton_exists?: InputMaybe<Scalars['Boolean']['input']>;
  loginDescription?: InputMaybe<Scalars['String']['input']>;
  loginDescription_contains?: InputMaybe<Scalars['String']['input']>;
  loginDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  loginDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  loginDescription_not?: InputMaybe<Scalars['String']['input']>;
  loginDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  loginDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  loginTitle?: InputMaybe<Scalars['String']['input']>;
  loginTitle_contains?: InputMaybe<Scalars['String']['input']>;
  loginTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  loginTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  loginTitle_not?: InputMaybe<Scalars['String']['input']>;
  loginTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  loginTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  signUpButton?: InputMaybe<CfLinkNestedFilter>;
  signUpButton_exists?: InputMaybe<Scalars['Boolean']['input']>;
  signUpDescription?: InputMaybe<Scalars['String']['input']>;
  signUpDescription_contains?: InputMaybe<Scalars['String']['input']>;
  signUpDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  signUpDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  signUpDescription_not?: InputMaybe<Scalars['String']['input']>;
  signUpDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  signUpDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  signUpTitle?: InputMaybe<Scalars['String']['input']>;
  signUpTitle_contains?: InputMaybe<Scalars['String']['input']>;
  signUpTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  signUpTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  signUpTitle_not?: InputMaybe<Scalars['String']['input']>;
  signUpTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  signUpTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SignInLinkingCollections = {
  __typename?: 'SignInLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  moneyRequestPayCollection?: Maybe<MoneyRequestPayCollection>;
};

export type SignInLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SignInLinkingCollectionsMoneyRequestPayCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<SignInLinkingCollectionsMoneyRequestPayCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum SignInLinkingCollectionsMoneyRequestPayCollectionOrder {
  AccountNumberLabelAsc = 'accountNumberLabel_ASC',
  AccountNumberLabelDesc = 'accountNumberLabel_DESC',
  AmountRequestedLabelAsc = 'amountRequestedLabel_ASC',
  AmountRequestedLabelDesc = 'amountRequestedLabel_DESC',
  BankNameLabelAsc = 'bankNameLabel_ASC',
  BankNameLabelDesc = 'bankNameLabel_DESC',
  ErrorTextAsc = 'errorText_ASC',
  ErrorTextDesc = 'errorText_DESC',
  IbanLabelAsc = 'ibanLabel_ASC',
  IbanLabelDesc = 'ibanLabel_DESC',
  MobileNumberLabelAsc = 'mobileNumberLabel_ASC',
  MobileNumberLabelDesc = 'mobileNumberLabel_DESC',
  MoneyProviderLabelAsc = 'moneyProviderLabel_ASC',
  MoneyProviderLabelDesc = 'moneyProviderLabel_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RequestedByLabelAsc = 'requestedByLabel_ASC',
  RequestedByLabelDesc = 'requestedByLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleForNotLoggedInAsc = 'titleForNotLoggedIn_ASC',
  TitleForNotLoggedInDesc = 'titleForNotLoggedIn_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SignInOrder {
  LoginDescriptionAsc = 'loginDescription_ASC',
  LoginDescriptionDesc = 'loginDescription_DESC',
  LoginTitleAsc = 'loginTitle_ASC',
  LoginTitleDesc = 'loginTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SignUpDescriptionAsc = 'signUpDescription_ASC',
  SignUpDescriptionDesc = 'signUpDescription_DESC',
  SignUpTitleAsc = 'signUpTitle_ASC',
  SignUpTitleDesc = 'signUpTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [web-cms] Sitemap settings used on HTML sitemap page and XML sitemap/sub-sitemaps files [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/sitemap) */
export type Sitemap = Entry &
  _Node & {
    __typename?: 'Sitemap';
    _id: Scalars['ID']['output'];
    blogSitemapUrl?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<SitemapLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sitemapXml?: Maybe<Scalars['JSON']['output']>;
    sys: Sys;
  };

/** [web-cms] Sitemap settings used on HTML sitemap page and XML sitemap/sub-sitemaps files [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/sitemap) */
export type SitemapBlogSitemapUrlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Sitemap settings used on HTML sitemap page and XML sitemap/sub-sitemaps files [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/sitemap) */
export type SitemapLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-cms] Sitemap settings used on HTML sitemap page and XML sitemap/sub-sitemaps files [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/sitemap) */
export type SitemapNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Sitemap settings used on HTML sitemap page and XML sitemap/sub-sitemaps files [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/sitemap) */
export type SitemapSitemapXmlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type SitemapCollection = {
  __typename?: 'SitemapCollection';
  items: Array<Maybe<Sitemap>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type SitemapFilter = {
  AND?: InputMaybe<Array<InputMaybe<SitemapFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SitemapFilter>>>;
  blogSitemapUrl?: InputMaybe<Scalars['String']['input']>;
  blogSitemapUrl_contains?: InputMaybe<Scalars['String']['input']>;
  blogSitemapUrl_exists?: InputMaybe<Scalars['Boolean']['input']>;
  blogSitemapUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  blogSitemapUrl_not?: InputMaybe<Scalars['String']['input']>;
  blogSitemapUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  blogSitemapUrl_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sitemapXml_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type SitemapLinkingCollections = {
  __typename?: 'SitemapLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type SitemapLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum SitemapOrder {
  BlogSitemapUrlAsc = 'blogSitemapUrl_ASC',
  BlogSitemapUrlDesc = 'blogSitemapUrl_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/smsVerificationCodePage) */
export type SmsVerificationCodePage = Entry &
  _Node & {
    __typename?: 'SmsVerificationCodePage';
    _id: Scalars['ID']['output'];
    buttonLabel?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<SmsVerificationCodePageDescription>;
    errorDescription?: Maybe<Scalars['String']['output']>;
    header?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<SmsVerificationCodePageLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    resendCodeButtonLabel?: Maybe<Scalars['String']['output']>;
    resendCountDownLabel?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/smsVerificationCodePage) */
export type SmsVerificationCodePageButtonLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/smsVerificationCodePage) */
export type SmsVerificationCodePageDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/smsVerificationCodePage) */
export type SmsVerificationCodePageErrorDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/smsVerificationCodePage) */
export type SmsVerificationCodePageHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/smsVerificationCodePage) */
export type SmsVerificationCodePageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/smsVerificationCodePage) */
export type SmsVerificationCodePageNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/smsVerificationCodePage) */
export type SmsVerificationCodePageResendCodeButtonLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/smsVerificationCodePage) */
export type SmsVerificationCodePageResendCountDownLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/smsVerificationCodePage) */
export type SmsVerificationCodePageTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type SmsVerificationCodePageCollection = {
  __typename?: 'SmsVerificationCodePageCollection';
  items: Array<Maybe<SmsVerificationCodePage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type SmsVerificationCodePageDescription = {
  __typename?: 'SmsVerificationCodePageDescription';
  json: Scalars['JSON']['output'];
  links: SmsVerificationCodePageDescriptionLinks;
};

export type SmsVerificationCodePageDescriptionAssets = {
  __typename?: 'SmsVerificationCodePageDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type SmsVerificationCodePageDescriptionEntries = {
  __typename?: 'SmsVerificationCodePageDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type SmsVerificationCodePageDescriptionLinks = {
  __typename?: 'SmsVerificationCodePageDescriptionLinks';
  assets: SmsVerificationCodePageDescriptionAssets;
  entries: SmsVerificationCodePageDescriptionEntries;
  resources: SmsVerificationCodePageDescriptionResources;
};

export type SmsVerificationCodePageDescriptionResources = {
  __typename?: 'SmsVerificationCodePageDescriptionResources';
  block: Array<SmsVerificationCodePageDescriptionResourcesBlock>;
  hyperlink: Array<SmsVerificationCodePageDescriptionResourcesHyperlink>;
  inline: Array<SmsVerificationCodePageDescriptionResourcesInline>;
};

export type SmsVerificationCodePageDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'SmsVerificationCodePageDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type SmsVerificationCodePageDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'SmsVerificationCodePageDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type SmsVerificationCodePageDescriptionResourcesInline = ResourceLink & {
  __typename?: 'SmsVerificationCodePageDescriptionResourcesInline';
  sys: ResourceSys;
};

export type SmsVerificationCodePageFilter = {
  AND?: InputMaybe<Array<InputMaybe<SmsVerificationCodePageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SmsVerificationCodePageFilter>>>;
  buttonLabel?: InputMaybe<Scalars['String']['input']>;
  buttonLabel_contains?: InputMaybe<Scalars['String']['input']>;
  buttonLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  buttonLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  buttonLabel_not?: InputMaybe<Scalars['String']['input']>;
  buttonLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  buttonLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  errorDescription?: InputMaybe<Scalars['String']['input']>;
  errorDescription_contains?: InputMaybe<Scalars['String']['input']>;
  errorDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  errorDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  errorDescription_not?: InputMaybe<Scalars['String']['input']>;
  errorDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  errorDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  resendCodeButtonLabel?: InputMaybe<Scalars['String']['input']>;
  resendCodeButtonLabel_contains?: InputMaybe<Scalars['String']['input']>;
  resendCodeButtonLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  resendCodeButtonLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  resendCodeButtonLabel_not?: InputMaybe<Scalars['String']['input']>;
  resendCodeButtonLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  resendCodeButtonLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  resendCountDownLabel?: InputMaybe<Scalars['String']['input']>;
  resendCountDownLabel_contains?: InputMaybe<Scalars['String']['input']>;
  resendCountDownLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  resendCountDownLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  resendCountDownLabel_not?: InputMaybe<Scalars['String']['input']>;
  resendCountDownLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  resendCountDownLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SmsVerificationCodePageLinkingCollections = {
  __typename?: 'SmsVerificationCodePageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type SmsVerificationCodePageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum SmsVerificationCodePageOrder {
  ButtonLabelAsc = 'buttonLabel_ASC',
  ButtonLabelDesc = 'buttonLabel_DESC',
  ErrorDescriptionAsc = 'errorDescription_ASC',
  ErrorDescriptionDesc = 'errorDescription_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ResendCodeButtonLabelAsc = 'resendCodeButtonLabel_ASC',
  ResendCodeButtonLabelDesc = 'resendCodeButtonLabel_DESC',
  ResendCountDownLabelAsc = 'resendCountDownLabel_ASC',
  ResendCountDownLabelDesc = 'resendCountDownLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/socialProofingModule) */
export type SocialProofingModule = Entry &
  _Node & {
    __typename?: 'SocialProofingModule';
    _id: Scalars['ID']['output'];
    androidAppStoreData?: Maybe<MobileStoreData>;
    appStoreIconLink?: Maybe<Link>;
    appStoreRatingsLink?: Maybe<Link>;
    contentfulMetadata: ContentfulMetadata;
    iosAppStoreData?: Maybe<MobileStoreData>;
    linkedFrom?: Maybe<SocialProofingModuleLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    playStoreIconLink?: Maybe<Link>;
    playStoreRatingsLink?: Maybe<Link>;
    subtitle?: Maybe<SocialProofingModuleSubtitle>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/socialProofingModule) */
export type SocialProofingModuleAndroidAppStoreDataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<MobileStoreDataFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/socialProofingModule) */
export type SocialProofingModuleAppStoreIconLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/socialProofingModule) */
export type SocialProofingModuleAppStoreRatingsLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/socialProofingModule) */
export type SocialProofingModuleIosAppStoreDataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<MobileStoreDataFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/socialProofingModule) */
export type SocialProofingModuleLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/socialProofingModule) */
export type SocialProofingModuleNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/socialProofingModule) */
export type SocialProofingModulePlayStoreIconLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/socialProofingModule) */
export type SocialProofingModulePlayStoreRatingsLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/socialProofingModule) */
export type SocialProofingModuleSubtitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/socialProofingModule) */
export type SocialProofingModuleTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type SocialProofingModuleCollection = {
  __typename?: 'SocialProofingModuleCollection';
  items: Array<Maybe<SocialProofingModule>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type SocialProofingModuleFilter = {
  AND?: InputMaybe<Array<InputMaybe<SocialProofingModuleFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SocialProofingModuleFilter>>>;
  androidAppStoreData?: InputMaybe<CfMobileStoreDataNestedFilter>;
  androidAppStoreData_exists?: InputMaybe<Scalars['Boolean']['input']>;
  appStoreIconLink?: InputMaybe<CfLinkNestedFilter>;
  appStoreIconLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  appStoreRatingsLink?: InputMaybe<CfLinkNestedFilter>;
  appStoreRatingsLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  iosAppStoreData?: InputMaybe<CfMobileStoreDataNestedFilter>;
  iosAppStoreData_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  playStoreIconLink?: InputMaybe<CfLinkNestedFilter>;
  playStoreIconLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  playStoreRatingsLink?: InputMaybe<CfLinkNestedFilter>;
  playStoreRatingsLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle_contains?: InputMaybe<Scalars['String']['input']>;
  subtitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SocialProofingModuleLinkingCollections = {
  __typename?: 'SocialProofingModuleLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type SocialProofingModuleLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum SocialProofingModuleOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SocialProofingModuleSubtitle = {
  __typename?: 'SocialProofingModuleSubtitle';
  json: Scalars['JSON']['output'];
  links: SocialProofingModuleSubtitleLinks;
};

export type SocialProofingModuleSubtitleAssets = {
  __typename?: 'SocialProofingModuleSubtitleAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type SocialProofingModuleSubtitleEntries = {
  __typename?: 'SocialProofingModuleSubtitleEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type SocialProofingModuleSubtitleLinks = {
  __typename?: 'SocialProofingModuleSubtitleLinks';
  assets: SocialProofingModuleSubtitleAssets;
  entries: SocialProofingModuleSubtitleEntries;
  resources: SocialProofingModuleSubtitleResources;
};

export type SocialProofingModuleSubtitleResources = {
  __typename?: 'SocialProofingModuleSubtitleResources';
  block: Array<SocialProofingModuleSubtitleResourcesBlock>;
  hyperlink: Array<SocialProofingModuleSubtitleResourcesHyperlink>;
  inline: Array<SocialProofingModuleSubtitleResourcesInline>;
};

export type SocialProofingModuleSubtitleResourcesBlock = ResourceLink & {
  __typename?: 'SocialProofingModuleSubtitleResourcesBlock';
  sys: ResourceSys;
};

export type SocialProofingModuleSubtitleResourcesHyperlink = ResourceLink & {
  __typename?: 'SocialProofingModuleSubtitleResourcesHyperlink';
  sys: ResourceSys;
};

export type SocialProofingModuleSubtitleResourcesInline = ResourceLink & {
  __typename?: 'SocialProofingModuleSubtitleResourcesInline';
  sys: ResourceSys;
};

/** Request money solutions module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/solutionsModule) */
export type SolutionsModule = Entry &
  _Node & {
    __typename?: 'SolutionsModule';
    _id: Scalars['ID']['output'];
    buttonLabel?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    header?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<SolutionsModuleLinkingCollections>;
    modal?: Maybe<SolutionsModuleModal>;
    name?: Maybe<Scalars['String']['output']>;
    solutionsCollection?: Maybe<SolutionsModuleSolutionsCollection>;
    sys: Sys;
  };

/** Request money solutions module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/solutionsModule) */
export type SolutionsModuleButtonLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Request money solutions module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/solutionsModule) */
export type SolutionsModuleDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Request money solutions module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/solutionsModule) */
export type SolutionsModuleHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Request money solutions module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/solutionsModule) */
export type SolutionsModuleLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Request money solutions module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/solutionsModule) */
export type SolutionsModuleModalArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Request money solutions module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/solutionsModule) */
export type SolutionsModuleNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Request money solutions module [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/solutionsModule) */
export type SolutionsModuleSolutionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<SolutionsModuleSolutionsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WhyChooseReasonFilter>;
};

export type SolutionsModuleCollection = {
  __typename?: 'SolutionsModuleCollection';
  items: Array<Maybe<SolutionsModule>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type SolutionsModuleFilter = {
  AND?: InputMaybe<Array<InputMaybe<SolutionsModuleFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SolutionsModuleFilter>>>;
  buttonLabel?: InputMaybe<Scalars['String']['input']>;
  buttonLabel_contains?: InputMaybe<Scalars['String']['input']>;
  buttonLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  buttonLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  buttonLabel_not?: InputMaybe<Scalars['String']['input']>;
  buttonLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  buttonLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  modal_contains?: InputMaybe<Scalars['String']['input']>;
  modal_exists?: InputMaybe<Scalars['Boolean']['input']>;
  modal_not_contains?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  solutions?: InputMaybe<CfWhyChooseReasonNestedFilter>;
  solutionsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type SolutionsModuleLinkingCollections = {
  __typename?: 'SolutionsModuleLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type SolutionsModuleLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SolutionsModuleModal = {
  __typename?: 'SolutionsModuleModal';
  json: Scalars['JSON']['output'];
  links: SolutionsModuleModalLinks;
};

export type SolutionsModuleModalAssets = {
  __typename?: 'SolutionsModuleModalAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type SolutionsModuleModalEntries = {
  __typename?: 'SolutionsModuleModalEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type SolutionsModuleModalLinks = {
  __typename?: 'SolutionsModuleModalLinks';
  assets: SolutionsModuleModalAssets;
  entries: SolutionsModuleModalEntries;
  resources: SolutionsModuleModalResources;
};

export type SolutionsModuleModalResources = {
  __typename?: 'SolutionsModuleModalResources';
  block: Array<SolutionsModuleModalResourcesBlock>;
  hyperlink: Array<SolutionsModuleModalResourcesHyperlink>;
  inline: Array<SolutionsModuleModalResourcesInline>;
};

export type SolutionsModuleModalResourcesBlock = ResourceLink & {
  __typename?: 'SolutionsModuleModalResourcesBlock';
  sys: ResourceSys;
};

export type SolutionsModuleModalResourcesHyperlink = ResourceLink & {
  __typename?: 'SolutionsModuleModalResourcesHyperlink';
  sys: ResourceSys;
};

export type SolutionsModuleModalResourcesInline = ResourceLink & {
  __typename?: 'SolutionsModuleModalResourcesInline';
  sys: ResourceSys;
};

export enum SolutionsModuleOrder {
  ButtonLabelAsc = 'buttonLabel_ASC',
  ButtonLabelDesc = 'buttonLabel_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type SolutionsModuleSolutionsCollection = {
  __typename?: 'SolutionsModuleSolutionsCollection';
  items: Array<Maybe<WhyChooseReason>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum SolutionsModuleSolutionsCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  ImagePositionAsc = 'imagePosition_ASC',
  ImagePositionDesc = 'imagePosition_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/**
 * [web-cms] Card with header, description and icon
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/step)
 */
export type Step = Entry &
  _Node & {
    __typename?: 'Step';
    _id: Scalars['ID']['output'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<StepDescription>;
    header?: Maybe<Scalars['String']['output']>;
    image?: Maybe<Asset>;
    linkedFrom?: Maybe<StepLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sys: Sys;
  };

/**
 * [web-cms] Card with header, description and icon
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/step)
 */
export type StepAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * [web-cms] Card with header, description and icon
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/step)
 */
export type StepAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * [web-cms] Card with header, description and icon
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/step)
 */
export type StepDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * [web-cms] Card with header, description and icon
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/step)
 */
export type StepHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * [web-cms] Card with header, description and icon
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/step)
 */
export type StepImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * [web-cms] Card with header, description and icon
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/step)
 */
export type StepLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/**
 * [web-cms] Card with header, description and icon
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/step)
 */
export type StepNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * [web-cms] Card with header, description and icon
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/step)
 */
export type StepReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * [web-cms] Card with header, description and icon
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/step)
 */
export type StepSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Wrapper for steps with header https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stepByStep) */
export type StepByStep = Entry &
  _Node & {
    __typename?: 'StepByStep';
    _id: Scalars['ID']['output'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    contentfulMetadata: ContentfulMetadata;
    header?: Maybe<Scalars['String']['output']>;
    link?: Maybe<Link>;
    linkedFrom?: Maybe<StepByStepLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    stepsCollection?: Maybe<StepByStepStepsCollection>;
    sys: Sys;
  };

/** [web-cms] Wrapper for steps with header https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stepByStep) */
export type StepByStepAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Wrapper for steps with header https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stepByStep) */
export type StepByStepAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Wrapper for steps with header https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stepByStep) */
export type StepByStepHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Wrapper for steps with header https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stepByStep) */
export type StepByStepLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-cms] Wrapper for steps with header https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stepByStep) */
export type StepByStepLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-cms] Wrapper for steps with header https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stepByStep) */
export type StepByStepNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Wrapper for steps with header https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stepByStep) */
export type StepByStepReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Wrapper for steps with header https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stepByStep) */
export type StepByStepSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Wrapper for steps with header https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stepByStep) */
export type StepByStepStepsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<StepByStepStepsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StepFilter>;
};

export type StepByStepCollection = {
  __typename?: 'StepByStepCollection';
  items: Array<Maybe<StepByStep>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type StepByStepFilter = {
  AND?: InputMaybe<Array<InputMaybe<StepByStepFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<StepByStepFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  link?: InputMaybe<CfLinkNestedFilter>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  steps?: InputMaybe<CfStepNestedFilter>;
  stepsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type StepByStepLinkingCollections = {
  __typename?: 'StepByStepLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pageSectionCollection?: Maybe<PageSectionCollection>;
};

export type StepByStepLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type StepByStepLinkingCollectionsPageSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<StepByStepLinkingCollectionsPageSectionCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum StepByStepLinkingCollectionsPageSectionCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundGradientAsc = 'backgroundGradient_ASC',
  BackgroundGradientDesc = 'backgroundGradient_DESC',
  BackgroundTypeAsc = 'backgroundType_ASC',
  BackgroundTypeDesc = 'backgroundType_DESC',
  EnablePaddingAsc = 'enablePadding_ASC',
  EnablePaddingDesc = 'enablePadding_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum StepByStepOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type StepByStepStepsCollection = {
  __typename?: 'StepByStepStepsCollection';
  items: Array<Maybe<Step>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum StepByStepStepsCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type StepCollection = {
  __typename?: 'StepCollection';
  items: Array<Maybe<Step>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type StepDescription = {
  __typename?: 'StepDescription';
  json: Scalars['JSON']['output'];
  links: StepDescriptionLinks;
};

export type StepDescriptionAssets = {
  __typename?: 'StepDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type StepDescriptionEntries = {
  __typename?: 'StepDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type StepDescriptionLinks = {
  __typename?: 'StepDescriptionLinks';
  assets: StepDescriptionAssets;
  entries: StepDescriptionEntries;
  resources: StepDescriptionResources;
};

export type StepDescriptionResources = {
  __typename?: 'StepDescriptionResources';
  block: Array<StepDescriptionResourcesBlock>;
  hyperlink: Array<StepDescriptionResourcesHyperlink>;
  inline: Array<StepDescriptionResourcesInline>;
};

export type StepDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'StepDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type StepDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'StepDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type StepDescriptionResourcesInline = ResourceLink & {
  __typename?: 'StepDescriptionResourcesInline';
  sys: ResourceSys;
};

export type StepFilter = {
  AND?: InputMaybe<Array<InputMaybe<StepFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<StepFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type StepLinkingCollections = {
  __typename?: 'StepLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  stepByStepCollection?: Maybe<StepByStepCollection>;
};

export type StepLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type StepLinkingCollectionsStepByStepCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<StepLinkingCollectionsStepByStepCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum StepLinkingCollectionsStepByStepCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum StepOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/**
 * [web-cms] Promotional, Emergency banners that stick to the top of the page
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2415%3A72245&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stickyBanner)
 */
export type StickyBanner = Entry &
  _Node & {
    __typename?: 'StickyBanner';
    _id: Scalars['ID']['output'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    backgroundColor?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    cookies?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    description?: Maybe<StickyBannerDescription>;
    image?: Maybe<Asset>;
    linkedFrom?: Maybe<StickyBannerLinkingCollections>;
    marketingChannel?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    name?: Maybe<Scalars['String']['output']>;
    pageTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    queryParams?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    receiveCountriesCollection?: Maybe<StickyBannerReceiveCountriesCollection>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sendCountriesCollection?: Maybe<StickyBannerSendCountriesCollection>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    shouldMatchAllCookies?: Maybe<Scalars['Boolean']['output']>;
    sys: Sys;
    textColor?: Maybe<Scalars['String']['output']>;
    visitorTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  };

/**
 * [web-cms] Promotional, Emergency banners that stick to the top of the page
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2415%3A72245&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stickyBanner)
 */
export type StickyBannerAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * [web-cms] Promotional, Emergency banners that stick to the top of the page
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2415%3A72245&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stickyBanner)
 */
export type StickyBannerAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * [web-cms] Promotional, Emergency banners that stick to the top of the page
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2415%3A72245&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stickyBanner)
 */
export type StickyBannerBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * [web-cms] Promotional, Emergency banners that stick to the top of the page
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2415%3A72245&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stickyBanner)
 */
export type StickyBannerCookiesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * [web-cms] Promotional, Emergency banners that stick to the top of the page
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2415%3A72245&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stickyBanner)
 */
export type StickyBannerDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * [web-cms] Promotional, Emergency banners that stick to the top of the page
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2415%3A72245&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stickyBanner)
 */
export type StickyBannerImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * [web-cms] Promotional, Emergency banners that stick to the top of the page
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2415%3A72245&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stickyBanner)
 */
export type StickyBannerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/**
 * [web-cms] Promotional, Emergency banners that stick to the top of the page
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2415%3A72245&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stickyBanner)
 */
export type StickyBannerMarketingChannelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * [web-cms] Promotional, Emergency banners that stick to the top of the page
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2415%3A72245&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stickyBanner)
 */
export type StickyBannerNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * [web-cms] Promotional, Emergency banners that stick to the top of the page
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2415%3A72245&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stickyBanner)
 */
export type StickyBannerPageTypesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * [web-cms] Promotional, Emergency banners that stick to the top of the page
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2415%3A72245&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stickyBanner)
 */
export type StickyBannerQueryParamsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * [web-cms] Promotional, Emergency banners that stick to the top of the page
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2415%3A72245&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stickyBanner)
 */
export type StickyBannerReceiveCountriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<StickyBannerReceiveCountriesCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CountryFilter>;
};

/**
 * [web-cms] Promotional, Emergency banners that stick to the top of the page
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2415%3A72245&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stickyBanner)
 */
export type StickyBannerReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * [web-cms] Promotional, Emergency banners that stick to the top of the page
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2415%3A72245&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stickyBanner)
 */
export type StickyBannerSendCountriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<StickyBannerSendCountriesCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CountryFilter>;
};

/**
 * [web-cms] Promotional, Emergency banners that stick to the top of the page
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2415%3A72245&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stickyBanner)
 */
export type StickyBannerSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * [web-cms] Promotional, Emergency banners that stick to the top of the page
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2415%3A72245&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stickyBanner)
 */
export type StickyBannerShouldMatchAllCookiesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * [web-cms] Promotional, Emergency banners that stick to the top of the page
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2415%3A72245&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stickyBanner)
 */
export type StickyBannerTextColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * [web-cms] Promotional, Emergency banners that stick to the top of the page
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2415%3A72245&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/stickyBanner)
 */
export type StickyBannerVisitorTypesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type StickyBannerCollection = {
  __typename?: 'StickyBannerCollection';
  items: Array<Maybe<StickyBanner>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type StickyBannerDescription = {
  __typename?: 'StickyBannerDescription';
  json: Scalars['JSON']['output'];
  links: StickyBannerDescriptionLinks;
};

export type StickyBannerDescriptionAssets = {
  __typename?: 'StickyBannerDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type StickyBannerDescriptionEntries = {
  __typename?: 'StickyBannerDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type StickyBannerDescriptionLinks = {
  __typename?: 'StickyBannerDescriptionLinks';
  assets: StickyBannerDescriptionAssets;
  entries: StickyBannerDescriptionEntries;
  resources: StickyBannerDescriptionResources;
};

export type StickyBannerDescriptionResources = {
  __typename?: 'StickyBannerDescriptionResources';
  block: Array<StickyBannerDescriptionResourcesBlock>;
  hyperlink: Array<StickyBannerDescriptionResourcesHyperlink>;
  inline: Array<StickyBannerDescriptionResourcesInline>;
};

export type StickyBannerDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'StickyBannerDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type StickyBannerDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'StickyBannerDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type StickyBannerDescriptionResourcesInline = ResourceLink & {
  __typename?: 'StickyBannerDescriptionResourcesInline';
  sys: ResourceSys;
};

export type StickyBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<StickyBannerFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<StickyBannerFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundColor_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  cookies_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  cookies_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  cookies_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  cookies_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  marketingChannel_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  marketingChannel_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  marketingChannel_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  marketingChannel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pageTypes_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  pageTypes_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  pageTypes_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  pageTypes_exists?: InputMaybe<Scalars['Boolean']['input']>;
  queryParams_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  queryParams_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  queryParams_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  queryParams_exists?: InputMaybe<Scalars['Boolean']['input']>;
  receiveCountries?: InputMaybe<CfCountryNestedFilter>;
  receiveCountriesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountries?: InputMaybe<CfCountryNestedFilter>;
  sendCountriesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  shouldMatchAllCookies?: InputMaybe<Scalars['Boolean']['input']>;
  shouldMatchAllCookies_exists?: InputMaybe<Scalars['Boolean']['input']>;
  shouldMatchAllCookies_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  textColor?: InputMaybe<Scalars['String']['input']>;
  textColor_contains?: InputMaybe<Scalars['String']['input']>;
  textColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  textColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  textColor_not?: InputMaybe<Scalars['String']['input']>;
  textColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  textColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  visitorTypes_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  visitorTypes_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  visitorTypes_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  visitorTypes_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StickyBannerLinkingCollections = {
  __typename?: 'StickyBannerLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  forgottenPasswordPageCollection?: Maybe<ForgottenPasswordPageCollection>;
  senderFormPageCollection?: Maybe<SenderFormPageCollection>;
};

export type StickyBannerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type StickyBannerLinkingCollectionsForgottenPasswordPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<StickyBannerLinkingCollectionsForgottenPasswordPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type StickyBannerLinkingCollectionsSenderFormPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<StickyBannerLinkingCollectionsSenderFormPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum StickyBannerLinkingCollectionsForgottenPasswordPageCollectionOrder {
  ContinueButtonLabelAsc = 'continueButtonLabel_ASC',
  ContinueButtonLabelDesc = 'continueButtonLabel_DESC',
  EmailHelperTextAsc = 'emailHelperText_ASC',
  EmailHelperTextDesc = 'emailHelperText_DESC',
  EmailInputLabelAsc = 'emailInputLabel_ASC',
  EmailInputLabelDesc = 'emailInputLabel_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ResetPasswordTimerTextAsc = 'resetPasswordTimerText_ASC',
  ResetPasswordTimerTextDesc = 'resetPasswordTimerText_DESC',
  SuccessEmailSentAlertAsc = 'successEmailSentAlert_ASC',
  SuccessEmailSentAlertDesc = 'successEmailSentAlert_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum StickyBannerLinkingCollectionsSenderFormPageCollectionOrder {
  AddressLookupPlaceholderAsc = 'addressLookupPlaceholder_ASC',
  AddressLookupPlaceholderDesc = 'addressLookupPlaceholder_DESC',
  BreadcrumbCurrentAsc = 'breadcrumbCurrent_ASC',
  BreadcrumbCurrentDesc = 'breadcrumbCurrent_DESC',
  BreadcrumbNextAsc = 'breadcrumbNext_ASC',
  BreadcrumbNextDesc = 'breadcrumbNext_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  EnhancementAsc = 'enhancement_ASC',
  EnhancementDesc = 'enhancement_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubmitButtonLabelAsc = 'submitButtonLabel_ASC',
  SubmitButtonLabelDesc = 'submitButtonLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum StickyBannerOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ShouldMatchAllCookiesAsc = 'shouldMatchAllCookies_ASC',
  ShouldMatchAllCookiesDesc = 'shouldMatchAllCookies_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextColorAsc = 'textColor_ASC',
  TextColorDesc = 'textColor_DESC',
}

export type StickyBannerReceiveCountriesCollection = {
  __typename?: 'StickyBannerReceiveCountriesCollection';
  items: Array<Maybe<Country>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum StickyBannerReceiveCountriesCollectionOrder {
  ArticleAsc = 'article_ASC',
  ArticleDesc = 'article_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrepositionFromAsc = 'prepositionFrom_ASC',
  PrepositionFromDesc = 'prepositionFrom_DESC',
  PrepositionInAsc = 'prepositionIn_ASC',
  PrepositionInDesc = 'prepositionIn_DESC',
  PrepositionToAsc = 'prepositionTo_ASC',
  PrepositionToDesc = 'prepositionTo_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type StickyBannerSendCountriesCollection = {
  __typename?: 'StickyBannerSendCountriesCollection';
  items: Array<Maybe<Country>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum StickyBannerSendCountriesCollectionOrder {
  ArticleAsc = 'article_ASC',
  ArticleDesc = 'article_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrepositionFromAsc = 'prepositionFrom_ASC',
  PrepositionFromDesc = 'prepositionFrom_DESC',
  PrepositionInAsc = 'prepositionIn_ASC',
  PrepositionInDesc = 'prepositionIn_DESC',
  PrepositionToAsc = 'prepositionTo_ASC',
  PrepositionToDesc = 'prepositionTo_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type Sys = {
  __typename?: 'Sys';
  environmentId: Scalars['String']['output'];
  firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  /** The locale that was requested. */
  locale?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  publishedVersion?: Maybe<Scalars['Int']['output']>;
  spaceId: Scalars['String']['output'];
};

export type SysFilter = {
  firstPublishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  firstPublishedAt_exists?: InputMaybe<Scalars['Boolean']['input']>;
  firstPublishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  firstPublishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  firstPublishedAt_in?: InputMaybe<
    Array<InputMaybe<Scalars['DateTime']['input']>>
  >;
  firstPublishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  firstPublishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  firstPublishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  firstPublishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['DateTime']['input']>>
  >;
  id?: InputMaybe<Scalars['String']['input']>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  publishedAt_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['DateTime']['input']>>
  >;
  publishedVersion?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publishedVersion_gt?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_gte?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
  publishedVersion_lt?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_lte?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_not?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/tagLinks) */
export type TagLinks = Entry &
  _Node & {
    __typename?: 'TagLinks';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<TagLinksLinkingCollections>;
    rel?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    urls?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/tagLinks) */
export type TagLinksLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/tagLinks) */
export type TagLinksRelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/tagLinks) */
export type TagLinksUrlsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type TagLinksCollection = {
  __typename?: 'TagLinksCollection';
  items: Array<Maybe<TagLinks>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type TagLinksFilter = {
  AND?: InputMaybe<Array<InputMaybe<TagLinksFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TagLinksFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  rel?: InputMaybe<Scalars['String']['input']>;
  rel_contains?: InputMaybe<Scalars['String']['input']>;
  rel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  rel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rel_not?: InputMaybe<Scalars['String']['input']>;
  rel_not_contains?: InputMaybe<Scalars['String']['input']>;
  rel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  urls_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  urls_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  urls_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  urls_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TagLinksLinkingCollections = {
  __typename?: 'TagLinksLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type TagLinksLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum TagLinksOrder {
  RelAsc = 'rel_ASC',
  RelDesc = 'rel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/**
 * Represents a tag entity for finding and organizing content easily.
 *         Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-concepts
 */
export type TaxonomyConcept = {
  __typename?: 'TaxonomyConcept';
  id?: Maybe<Scalars['String']['output']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textMeTheAppModule) */
export type TextMeTheAppModule = Entry &
  _Node & {
    __typename?: 'TextMeTheAppModule';
    _id: Scalars['ID']['output'];
    changeDialCodeButtonText?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    errorNoPhoneProvided?: Maybe<Scalars['String']['output']>;
    errorPhoneNumberInvalid?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<TextMeTheAppModuleLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    phoneInputLabel?: Maybe<Scalars['String']['output']>;
    phoneInputPlaceholder?: Maybe<Scalars['String']['output']>;
    smsContent?: Maybe<Scalars['String']['output']>;
    smsLink?: Maybe<Scalars['String']['output']>;
    submitButtonText?: Maybe<Scalars['String']['output']>;
    successPopupMessageContent?: Maybe<Scalars['String']['output']>;
    successPopupMessageTitle?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    toastSuccessIcon?: Maybe<Asset>;
    unexpectedError?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textMeTheAppModule) */
export type TextMeTheAppModuleChangeDialCodeButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textMeTheAppModule) */
export type TextMeTheAppModuleErrorNoPhoneProvidedArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textMeTheAppModule) */
export type TextMeTheAppModuleErrorPhoneNumberInvalidArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textMeTheAppModule) */
export type TextMeTheAppModuleLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textMeTheAppModule) */
export type TextMeTheAppModuleNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textMeTheAppModule) */
export type TextMeTheAppModulePhoneInputLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textMeTheAppModule) */
export type TextMeTheAppModulePhoneInputPlaceholderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textMeTheAppModule) */
export type TextMeTheAppModuleSmsContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textMeTheAppModule) */
export type TextMeTheAppModuleSmsLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textMeTheAppModule) */
export type TextMeTheAppModuleSubmitButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textMeTheAppModule) */
export type TextMeTheAppModuleSuccessPopupMessageContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textMeTheAppModule) */
export type TextMeTheAppModuleSuccessPopupMessageTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textMeTheAppModule) */
export type TextMeTheAppModuleToastSuccessIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textMeTheAppModule) */
export type TextMeTheAppModuleUnexpectedErrorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type TextMeTheAppModuleCollection = {
  __typename?: 'TextMeTheAppModuleCollection';
  items: Array<Maybe<TextMeTheAppModule>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type TextMeTheAppModuleFilter = {
  AND?: InputMaybe<Array<InputMaybe<TextMeTheAppModuleFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TextMeTheAppModuleFilter>>>;
  changeDialCodeButtonText?: InputMaybe<Scalars['String']['input']>;
  changeDialCodeButtonText_contains?: InputMaybe<Scalars['String']['input']>;
  changeDialCodeButtonText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  changeDialCodeButtonText_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  changeDialCodeButtonText_not?: InputMaybe<Scalars['String']['input']>;
  changeDialCodeButtonText_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  changeDialCodeButtonText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  errorNoPhoneProvided?: InputMaybe<Scalars['String']['input']>;
  errorNoPhoneProvided_contains?: InputMaybe<Scalars['String']['input']>;
  errorNoPhoneProvided_exists?: InputMaybe<Scalars['Boolean']['input']>;
  errorNoPhoneProvided_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  errorNoPhoneProvided_not?: InputMaybe<Scalars['String']['input']>;
  errorNoPhoneProvided_not_contains?: InputMaybe<Scalars['String']['input']>;
  errorNoPhoneProvided_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  errorPhoneNumberInvalid?: InputMaybe<Scalars['String']['input']>;
  errorPhoneNumberInvalid_contains?: InputMaybe<Scalars['String']['input']>;
  errorPhoneNumberInvalid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  errorPhoneNumberInvalid_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  errorPhoneNumberInvalid_not?: InputMaybe<Scalars['String']['input']>;
  errorPhoneNumberInvalid_not_contains?: InputMaybe<Scalars['String']['input']>;
  errorPhoneNumberInvalid_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phoneInputLabel?: InputMaybe<Scalars['String']['input']>;
  phoneInputLabel_contains?: InputMaybe<Scalars['String']['input']>;
  phoneInputLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  phoneInputLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneInputLabel_not?: InputMaybe<Scalars['String']['input']>;
  phoneInputLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  phoneInputLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneInputPlaceholder?: InputMaybe<Scalars['String']['input']>;
  phoneInputPlaceholder_contains?: InputMaybe<Scalars['String']['input']>;
  phoneInputPlaceholder_exists?: InputMaybe<Scalars['Boolean']['input']>;
  phoneInputPlaceholder_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneInputPlaceholder_not?: InputMaybe<Scalars['String']['input']>;
  phoneInputPlaceholder_not_contains?: InputMaybe<Scalars['String']['input']>;
  phoneInputPlaceholder_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  smsContent?: InputMaybe<Scalars['String']['input']>;
  smsContent_contains?: InputMaybe<Scalars['String']['input']>;
  smsContent_exists?: InputMaybe<Scalars['Boolean']['input']>;
  smsContent_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  smsContent_not?: InputMaybe<Scalars['String']['input']>;
  smsContent_not_contains?: InputMaybe<Scalars['String']['input']>;
  smsContent_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  smsLink?: InputMaybe<Scalars['String']['input']>;
  smsLink_contains?: InputMaybe<Scalars['String']['input']>;
  smsLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  smsLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  smsLink_not?: InputMaybe<Scalars['String']['input']>;
  smsLink_not_contains?: InputMaybe<Scalars['String']['input']>;
  smsLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  submitButtonText?: InputMaybe<Scalars['String']['input']>;
  submitButtonText_contains?: InputMaybe<Scalars['String']['input']>;
  submitButtonText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  submitButtonText_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  submitButtonText_not?: InputMaybe<Scalars['String']['input']>;
  submitButtonText_not_contains?: InputMaybe<Scalars['String']['input']>;
  submitButtonText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  successPopupMessageContent?: InputMaybe<Scalars['String']['input']>;
  successPopupMessageContent_contains?: InputMaybe<Scalars['String']['input']>;
  successPopupMessageContent_exists?: InputMaybe<Scalars['Boolean']['input']>;
  successPopupMessageContent_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  successPopupMessageContent_not?: InputMaybe<Scalars['String']['input']>;
  successPopupMessageContent_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  successPopupMessageContent_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  successPopupMessageTitle?: InputMaybe<Scalars['String']['input']>;
  successPopupMessageTitle_contains?: InputMaybe<Scalars['String']['input']>;
  successPopupMessageTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  successPopupMessageTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  successPopupMessageTitle_not?: InputMaybe<Scalars['String']['input']>;
  successPopupMessageTitle_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  successPopupMessageTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  toastSuccessIcon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  unexpectedError?: InputMaybe<Scalars['String']['input']>;
  unexpectedError_contains?: InputMaybe<Scalars['String']['input']>;
  unexpectedError_exists?: InputMaybe<Scalars['Boolean']['input']>;
  unexpectedError_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  unexpectedError_not?: InputMaybe<Scalars['String']['input']>;
  unexpectedError_not_contains?: InputMaybe<Scalars['String']['input']>;
  unexpectedError_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
};

export type TextMeTheAppModuleLinkingCollections = {
  __typename?: 'TextMeTheAppModuleLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type TextMeTheAppModuleLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum TextMeTheAppModuleOrder {
  ChangeDialCodeButtonTextAsc = 'changeDialCodeButtonText_ASC',
  ChangeDialCodeButtonTextDesc = 'changeDialCodeButtonText_DESC',
  ErrorNoPhoneProvidedAsc = 'errorNoPhoneProvided_ASC',
  ErrorNoPhoneProvidedDesc = 'errorNoPhoneProvided_DESC',
  ErrorPhoneNumberInvalidAsc = 'errorPhoneNumberInvalid_ASC',
  ErrorPhoneNumberInvalidDesc = 'errorPhoneNumberInvalid_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PhoneInputLabelAsc = 'phoneInputLabel_ASC',
  PhoneInputLabelDesc = 'phoneInputLabel_DESC',
  PhoneInputPlaceholderAsc = 'phoneInputPlaceholder_ASC',
  PhoneInputPlaceholderDesc = 'phoneInputPlaceholder_DESC',
  SmsContentAsc = 'smsContent_ASC',
  SmsContentDesc = 'smsContent_DESC',
  SmsLinkAsc = 'smsLink_ASC',
  SmsLinkDesc = 'smsLink_DESC',
  SubmitButtonTextAsc = 'submitButtonText_ASC',
  SubmitButtonTextDesc = 'submitButtonText_DESC',
  SuccessPopupMessageContentAsc = 'successPopupMessageContent_ASC',
  SuccessPopupMessageContentDesc = 'successPopupMessageContent_DESC',
  SuccessPopupMessageTitleAsc = 'successPopupMessageTitle_ASC',
  SuccessPopupMessageTitleDesc = 'successPopupMessageTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnexpectedErrorAsc = 'unexpectedError_ASC',
  UnexpectedErrorDesc = 'unexpectedError_DESC',
}

/** [web-cms] Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textSection) */
export type TextSection = Entry &
  _Node & {
    __typename?: 'TextSection';
    _id: Scalars['ID']['output'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']['output']>;
    backgroundColor?: Maybe<Scalars['String']['output']>;
    content?: Maybe<TextSectionContent>;
    contentfulMetadata: ContentfulMetadata;
    imageCollection?: Maybe<AssetCollection>;
    imagePositions?: Maybe<Scalars['JSON']['output']>;
    isOrderReversed?: Maybe<Scalars['Boolean']['output']>;
    linkedFrom?: Maybe<TextSectionLinkingCollections>;
    maxWidth?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sys: Sys;
  };

/** [web-cms] Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textSection) */
export type TextSectionAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textSection) */
export type TextSectionAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textSection) */
export type TextSectionBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textSection) */
export type TextSectionContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textSection) */
export type TextSectionImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** [web-cms] Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textSection) */
export type TextSectionImagePositionsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textSection) */
export type TextSectionIsOrderReversedArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textSection) */
export type TextSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-cms] Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textSection) */
export type TextSectionMaxWidthArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textSection) */
export type TextSectionNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textSection) */
export type TextSectionReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textSection) */
export type TextSectionSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type TextSectionCollection = {
  __typename?: 'TextSectionCollection';
  items: Array<Maybe<TextSection>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type TextSectionContent = {
  __typename?: 'TextSectionContent';
  json: Scalars['JSON']['output'];
  links: TextSectionContentLinks;
};

export type TextSectionContentAssets = {
  __typename?: 'TextSectionContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type TextSectionContentEntries = {
  __typename?: 'TextSectionContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type TextSectionContentLinks = {
  __typename?: 'TextSectionContentLinks';
  assets: TextSectionContentAssets;
  entries: TextSectionContentEntries;
  resources: TextSectionContentResources;
};

export type TextSectionContentResources = {
  __typename?: 'TextSectionContentResources';
  block: Array<TextSectionContentResourcesBlock>;
  hyperlink: Array<TextSectionContentResourcesHyperlink>;
  inline: Array<TextSectionContentResourcesInline>;
};

export type TextSectionContentResourcesBlock = ResourceLink & {
  __typename?: 'TextSectionContentResourcesBlock';
  sys: ResourceSys;
};

export type TextSectionContentResourcesHyperlink = ResourceLink & {
  __typename?: 'TextSectionContentResourcesHyperlink';
  sys: ResourceSys;
};

export type TextSectionContentResourcesInline = ResourceLink & {
  __typename?: 'TextSectionContentResourcesInline';
  sys: ResourceSys;
};

export type TextSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<TextSectionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TextSectionFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundColor_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  content_contains?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  imageCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  imagePositions_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isOrderReversed?: InputMaybe<Scalars['Boolean']['input']>;
  isOrderReversed_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isOrderReversed_not?: InputMaybe<Scalars['Boolean']['input']>;
  maxWidth?: InputMaybe<Scalars['String']['input']>;
  maxWidth_contains?: InputMaybe<Scalars['String']['input']>;
  maxWidth_exists?: InputMaybe<Scalars['Boolean']['input']>;
  maxWidth_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  maxWidth_not?: InputMaybe<Scalars['String']['input']>;
  maxWidth_not_contains?: InputMaybe<Scalars['String']['input']>;
  maxWidth_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type TextSectionLinkingCollections = {
  __typename?: 'TextSectionLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type TextSectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum TextSectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  IsOrderReversedAsc = 'isOrderReversed_ASC',
  IsOrderReversedDesc = 'isOrderReversed_DESC',
  MaxWidthAsc = 'maxWidth_ASC',
  MaxWidthDesc = 'maxWidth_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [web-cms] Rich text editor with options to specify text alignment, display, color, background color, and optional icon. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textWithColor) */
export type TextWithColor = Entry &
  _Node & {
    __typename?: 'TextWithColor';
    _id: Scalars['ID']['output'];
    align?: Maybe<Scalars['String']['output']>;
    backgroundColor?: Maybe<Scalars['String']['output']>;
    color?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    icon?: Maybe<Asset>;
    inline?: Maybe<Scalars['Boolean']['output']>;
    linkedFrom?: Maybe<TextWithColorLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    text?: Maybe<TextWithColorText>;
  };

/** [web-cms] Rich text editor with options to specify text alignment, display, color, background color, and optional icon. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textWithColor) */
export type TextWithColorAlignArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Rich text editor with options to specify text alignment, display, color, background color, and optional icon. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textWithColor) */
export type TextWithColorBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Rich text editor with options to specify text alignment, display, color, background color, and optional icon. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textWithColor) */
export type TextWithColorColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Rich text editor with options to specify text alignment, display, color, background color, and optional icon. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textWithColor) */
export type TextWithColorIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [web-cms] Rich text editor with options to specify text alignment, display, color, background color, and optional icon. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textWithColor) */
export type TextWithColorInlineArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Rich text editor with options to specify text alignment, display, color, background color, and optional icon. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textWithColor) */
export type TextWithColorLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-cms] Rich text editor with options to specify text alignment, display, color, background color, and optional icon. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textWithColor) */
export type TextWithColorNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] Rich text editor with options to specify text alignment, display, color, background color, and optional icon. [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/textWithColor) */
export type TextWithColorTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type TextWithColorCollection = {
  __typename?: 'TextWithColorCollection';
  items: Array<Maybe<TextWithColor>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type TextWithColorFilter = {
  AND?: InputMaybe<Array<InputMaybe<TextWithColorFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TextWithColorFilter>>>;
  align?: InputMaybe<Scalars['String']['input']>;
  align_contains?: InputMaybe<Scalars['String']['input']>;
  align_exists?: InputMaybe<Scalars['Boolean']['input']>;
  align_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  align_not?: InputMaybe<Scalars['String']['input']>;
  align_not_contains?: InputMaybe<Scalars['String']['input']>;
  align_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundColor_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  color?: InputMaybe<Scalars['String']['input']>;
  color_contains?: InputMaybe<Scalars['String']['input']>;
  color_exists?: InputMaybe<Scalars['Boolean']['input']>;
  color_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color_not?: InputMaybe<Scalars['String']['input']>;
  color_not_contains?: InputMaybe<Scalars['String']['input']>;
  color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  inline?: InputMaybe<Scalars['Boolean']['input']>;
  inline_exists?: InputMaybe<Scalars['Boolean']['input']>;
  inline_not?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  text_contains?: InputMaybe<Scalars['String']['input']>;
  text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_not_contains?: InputMaybe<Scalars['String']['input']>;
};

export type TextWithColorLinkingCollections = {
  __typename?: 'TextWithColorLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  iconWithTextCollection?: Maybe<IconWithTextCollection>;
  pageSectionCollection?: Maybe<PageSectionCollection>;
};

export type TextWithColorLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type TextWithColorLinkingCollectionsIconWithTextCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<TextWithColorLinkingCollectionsIconWithTextCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type TextWithColorLinkingCollectionsPageSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<TextWithColorLinkingCollectionsPageSectionCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum TextWithColorLinkingCollectionsIconWithTextCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TextWithColorLinkingCollectionsPageSectionCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundGradientAsc = 'backgroundGradient_ASC',
  BackgroundGradientDesc = 'backgroundGradient_DESC',
  BackgroundTypeAsc = 'backgroundType_ASC',
  BackgroundTypeDesc = 'backgroundType_DESC',
  EnablePaddingAsc = 'enablePadding_ASC',
  EnablePaddingDesc = 'enablePadding_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TextWithColorOrder {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  InlineAsc = 'inline_ASC',
  InlineDesc = 'inline_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type TextWithColorText = {
  __typename?: 'TextWithColorText';
  json: Scalars['JSON']['output'];
  links: TextWithColorTextLinks;
};

export type TextWithColorTextAssets = {
  __typename?: 'TextWithColorTextAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type TextWithColorTextEntries = {
  __typename?: 'TextWithColorTextEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type TextWithColorTextLinks = {
  __typename?: 'TextWithColorTextLinks';
  assets: TextWithColorTextAssets;
  entries: TextWithColorTextEntries;
  resources: TextWithColorTextResources;
};

export type TextWithColorTextResources = {
  __typename?: 'TextWithColorTextResources';
  block: Array<TextWithColorTextResourcesBlock>;
  hyperlink: Array<TextWithColorTextResourcesHyperlink>;
  inline: Array<TextWithColorTextResourcesInline>;
};

export type TextWithColorTextResourcesBlock = ResourceLink & {
  __typename?: 'TextWithColorTextResourcesBlock';
  sys: ResourceSys;
};

export type TextWithColorTextResourcesHyperlink = ResourceLink & {
  __typename?: 'TextWithColorTextResourcesHyperlink';
  sys: ResourceSys;
};

export type TextWithColorTextResourcesInline = ResourceLink & {
  __typename?: 'TextWithColorTextResourcesInline';
  sys: ResourceSys;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/verificationInProgress) */
export type VerificationInProgress = Entry &
  _Node & {
    __typename?: 'VerificationInProgress';
    _id: Scalars['ID']['output'];
    contactInfo?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    header?: Maybe<Scalars['String']['output']>;
    image?: Maybe<Asset>;
    linkedFrom?: Maybe<VerificationInProgressLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    subHeader?: Maybe<Scalars['String']['output']>;
    subHeaderOnfido?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/verificationInProgress) */
export type VerificationInProgressContactInfoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/verificationInProgress) */
export type VerificationInProgressHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/verificationInProgress) */
export type VerificationInProgressImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/verificationInProgress) */
export type VerificationInProgressLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/verificationInProgress) */
export type VerificationInProgressNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/verificationInProgress) */
export type VerificationInProgressSubHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/verificationInProgress) */
export type VerificationInProgressSubHeaderOnfidoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type VerificationInProgressCollection = {
  __typename?: 'VerificationInProgressCollection';
  items: Array<Maybe<VerificationInProgress>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type VerificationInProgressFilter = {
  AND?: InputMaybe<Array<InputMaybe<VerificationInProgressFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<VerificationInProgressFilter>>>;
  contactInfo?: InputMaybe<Scalars['String']['input']>;
  contactInfo_contains?: InputMaybe<Scalars['String']['input']>;
  contactInfo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contactInfo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contactInfo_not?: InputMaybe<Scalars['String']['input']>;
  contactInfo_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactInfo_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subHeader?: InputMaybe<Scalars['String']['input']>;
  subHeaderOnfido?: InputMaybe<Scalars['String']['input']>;
  subHeaderOnfido_contains?: InputMaybe<Scalars['String']['input']>;
  subHeaderOnfido_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subHeaderOnfido_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  subHeaderOnfido_not?: InputMaybe<Scalars['String']['input']>;
  subHeaderOnfido_not_contains?: InputMaybe<Scalars['String']['input']>;
  subHeaderOnfido_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  subHeader_contains?: InputMaybe<Scalars['String']['input']>;
  subHeader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subHeader_not?: InputMaybe<Scalars['String']['input']>;
  subHeader_not_contains?: InputMaybe<Scalars['String']['input']>;
  subHeader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type VerificationInProgressLinkingCollections = {
  __typename?: 'VerificationInProgressLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type VerificationInProgressLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum VerificationInProgressOrder {
  ContactInfoAsc = 'contactInfo_ASC',
  ContactInfoDesc = 'contactInfo_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubHeaderOnfidoAsc = 'subHeaderOnfido_ASC',
  SubHeaderOnfidoDesc = 'subHeaderOnfido_DESC',
  SubHeaderAsc = 'subHeader_ASC',
  SubHeaderDesc = 'subHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [web-cms] You Tube video id with background image and content [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/video) */
export type Video = Entry &
  _Node & {
    __typename?: 'Video';
    _id: Scalars['ID']['output'];
    backgroundImage?: Maybe<Asset>;
    content?: Maybe<VideoContent>;
    contentfulMetadata: ContentfulMetadata;
    header?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<VideoLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    playIcon?: Maybe<Asset>;
    subheader?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    videoId?: Maybe<Scalars['String']['output']>;
  };

/** [web-cms] You Tube video id with background image and content [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/video) */
export type VideoBackgroundImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [web-cms] You Tube video id with background image and content [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/video) */
export type VideoContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] You Tube video id with background image and content [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/video) */
export type VideoHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] You Tube video id with background image and content [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/video) */
export type VideoLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-cms] You Tube video id with background image and content [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/video) */
export type VideoNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] You Tube video id with background image and content [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/video) */
export type VideoPlayIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [web-cms] You Tube video id with background image and content [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/video) */
export type VideoSubheaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] You Tube video id with background image and content [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/video) */
export type VideoVideoIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type VideoCollection = {
  __typename?: 'VideoCollection';
  items: Array<Maybe<Video>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type VideoContent = {
  __typename?: 'VideoContent';
  json: Scalars['JSON']['output'];
  links: VideoContentLinks;
};

export type VideoContentAssets = {
  __typename?: 'VideoContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type VideoContentEntries = {
  __typename?: 'VideoContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type VideoContentLinks = {
  __typename?: 'VideoContentLinks';
  assets: VideoContentAssets;
  entries: VideoContentEntries;
  resources: VideoContentResources;
};

export type VideoContentResources = {
  __typename?: 'VideoContentResources';
  block: Array<VideoContentResourcesBlock>;
  hyperlink: Array<VideoContentResourcesHyperlink>;
  inline: Array<VideoContentResourcesInline>;
};

export type VideoContentResourcesBlock = ResourceLink & {
  __typename?: 'VideoContentResourcesBlock';
  sys: ResourceSys;
};

export type VideoContentResourcesHyperlink = ResourceLink & {
  __typename?: 'VideoContentResourcesHyperlink';
  sys: ResourceSys;
};

export type VideoContentResourcesInline = ResourceLink & {
  __typename?: 'VideoContentResourcesInline';
  sys: ResourceSys;
};

export type VideoFilter = {
  AND?: InputMaybe<Array<InputMaybe<VideoFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<VideoFilter>>>;
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_contains?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  playIcon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subheader?: InputMaybe<Scalars['String']['input']>;
  subheader_contains?: InputMaybe<Scalars['String']['input']>;
  subheader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subheader_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subheader_not?: InputMaybe<Scalars['String']['input']>;
  subheader_not_contains?: InputMaybe<Scalars['String']['input']>;
  subheader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  videoId?: InputMaybe<Scalars['String']['input']>;
  videoId_contains?: InputMaybe<Scalars['String']['input']>;
  videoId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  videoId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  videoId_not?: InputMaybe<Scalars['String']['input']>;
  videoId_not_contains?: InputMaybe<Scalars['String']['input']>;
  videoId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VideoLinkingCollections = {
  __typename?: 'VideoLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pageSectionCollection?: Maybe<PageSectionCollection>;
};

export type VideoLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type VideoLinkingCollectionsPageSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<VideoLinkingCollectionsPageSectionCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum VideoLinkingCollectionsPageSectionCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundGradientAsc = 'backgroundGradient_ASC',
  BackgroundGradientDesc = 'backgroundGradient_DESC',
  BackgroundTypeAsc = 'backgroundType_ASC',
  BackgroundTypeDesc = 'backgroundType_DESC',
  EnablePaddingAsc = 'enablePadding_ASC',
  EnablePaddingDesc = 'enablePadding_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum VideoOrder {
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubheaderAsc = 'subheader_ASC',
  SubheaderDesc = 'subheader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VideoIdAsc = 'videoId_ASC',
  VideoIdDesc = 'videoId_DESC',
}

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2303%3A47501&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/weAreRegulated) */
export type WeAreRegulated = Entry &
  _Node & {
    __typename?: 'WeAreRegulated';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<WeAreRegulatedLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    regulatorTitle?: Maybe<Scalars['String']['output']>;
    regulatorsCollection?: Maybe<WeAreRegulatedRegulatorsCollection>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2303%3A47501&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/weAreRegulated) */
export type WeAreRegulatedDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2303%3A47501&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/weAreRegulated) */
export type WeAreRegulatedLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2303%3A47501&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/weAreRegulated) */
export type WeAreRegulatedNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2303%3A47501&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/weAreRegulated) */
export type WeAreRegulatedRegulatorTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2303%3A47501&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/weAreRegulated) */
export type WeAreRegulatedRegulatorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<WeAreRegulatedRegulatorsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RegulatorFilter>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2303%3A47501&t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/weAreRegulated) */
export type WeAreRegulatedTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type WeAreRegulatedCollection = {
  __typename?: 'WeAreRegulatedCollection';
  items: Array<Maybe<WeAreRegulated>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type WeAreRegulatedFilter = {
  AND?: InputMaybe<Array<InputMaybe<WeAreRegulatedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<WeAreRegulatedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regulatorTitle?: InputMaybe<Scalars['String']['input']>;
  regulatorTitle_contains?: InputMaybe<Scalars['String']['input']>;
  regulatorTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  regulatorTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regulatorTitle_not?: InputMaybe<Scalars['String']['input']>;
  regulatorTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  regulatorTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  regulators?: InputMaybe<CfRegulatorNestedFilter>;
  regulatorsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type WeAreRegulatedLinkingCollections = {
  __typename?: 'WeAreRegulatedLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type WeAreRegulatedLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum WeAreRegulatedOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RegulatorTitleAsc = 'regulatorTitle_ASC',
  RegulatorTitleDesc = 'regulatorTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type WeAreRegulatedRegulatorsCollection = {
  __typename?: 'WeAreRegulatedRegulatorsCollection';
  items: Array<Maybe<Regulator>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum WeAreRegulatedRegulatorsCollectionOrder {
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  CustomFlagCodeAsc = 'customFlagCode_ASC',
  CustomFlagCodeDesc = 'customFlagCode_DESC',
  CustomRegulatorNameAsc = 'customRegulatorName_ASC',
  CustomRegulatorNameDesc = 'customRegulatorName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/**
 * HTML form out of a JSON schema
 * https://react-jsonschema-form.readthedocs.io/
 * https://rjsf-team.github.io/react-jsonschema-form/ [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/webForm)
 */
export type WebForm = Entry &
  _Node & {
    __typename?: 'WebForm';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<WebFormDescription>;
    disclaimer?: Maybe<Scalars['String']['output']>;
    formAction?: Maybe<Scalars['String']['output']>;
    header?: Maybe<Scalars['String']['output']>;
    jsonSchema?: Maybe<Scalars['JSON']['output']>;
    linkedFrom?: Maybe<WebFormLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    submitButtonLabel?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    uiSchema?: Maybe<Scalars['JSON']['output']>;
  };

/**
 * HTML form out of a JSON schema
 * https://react-jsonschema-form.readthedocs.io/
 * https://rjsf-team.github.io/react-jsonschema-form/ [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/webForm)
 */
export type WebFormDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * HTML form out of a JSON schema
 * https://react-jsonschema-form.readthedocs.io/
 * https://rjsf-team.github.io/react-jsonschema-form/ [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/webForm)
 */
export type WebFormDisclaimerArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * HTML form out of a JSON schema
 * https://react-jsonschema-form.readthedocs.io/
 * https://rjsf-team.github.io/react-jsonschema-form/ [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/webForm)
 */
export type WebFormFormActionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * HTML form out of a JSON schema
 * https://react-jsonschema-form.readthedocs.io/
 * https://rjsf-team.github.io/react-jsonschema-form/ [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/webForm)
 */
export type WebFormHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * HTML form out of a JSON schema
 * https://react-jsonschema-form.readthedocs.io/
 * https://rjsf-team.github.io/react-jsonschema-form/ [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/webForm)
 */
export type WebFormJsonSchemaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * HTML form out of a JSON schema
 * https://react-jsonschema-form.readthedocs.io/
 * https://rjsf-team.github.io/react-jsonschema-form/ [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/webForm)
 */
export type WebFormLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/**
 * HTML form out of a JSON schema
 * https://react-jsonschema-form.readthedocs.io/
 * https://rjsf-team.github.io/react-jsonschema-form/ [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/webForm)
 */
export type WebFormNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * HTML form out of a JSON schema
 * https://react-jsonschema-form.readthedocs.io/
 * https://rjsf-team.github.io/react-jsonschema-form/ [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/webForm)
 */
export type WebFormReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * HTML form out of a JSON schema
 * https://react-jsonschema-form.readthedocs.io/
 * https://rjsf-team.github.io/react-jsonschema-form/ [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/webForm)
 */
export type WebFormSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * HTML form out of a JSON schema
 * https://react-jsonschema-form.readthedocs.io/
 * https://rjsf-team.github.io/react-jsonschema-form/ [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/webForm)
 */
export type WebFormSubmitButtonLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * HTML form out of a JSON schema
 * https://react-jsonschema-form.readthedocs.io/
 * https://rjsf-team.github.io/react-jsonschema-form/ [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/webForm)
 */
export type WebFormUiSchemaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type WebFormCollection = {
  __typename?: 'WebFormCollection';
  items: Array<Maybe<WebForm>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type WebFormDescription = {
  __typename?: 'WebFormDescription';
  json: Scalars['JSON']['output'];
  links: WebFormDescriptionLinks;
};

export type WebFormDescriptionAssets = {
  __typename?: 'WebFormDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type WebFormDescriptionEntries = {
  __typename?: 'WebFormDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type WebFormDescriptionLinks = {
  __typename?: 'WebFormDescriptionLinks';
  assets: WebFormDescriptionAssets;
  entries: WebFormDescriptionEntries;
  resources: WebFormDescriptionResources;
};

export type WebFormDescriptionResources = {
  __typename?: 'WebFormDescriptionResources';
  block: Array<WebFormDescriptionResourcesBlock>;
  hyperlink: Array<WebFormDescriptionResourcesHyperlink>;
  inline: Array<WebFormDescriptionResourcesInline>;
};

export type WebFormDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'WebFormDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type WebFormDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'WebFormDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type WebFormDescriptionResourcesInline = ResourceLink & {
  __typename?: 'WebFormDescriptionResourcesInline';
  sys: ResourceSys;
};

export type WebFormFilter = {
  AND?: InputMaybe<Array<InputMaybe<WebFormFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<WebFormFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  disclaimer?: InputMaybe<Scalars['String']['input']>;
  disclaimer_contains?: InputMaybe<Scalars['String']['input']>;
  disclaimer_exists?: InputMaybe<Scalars['Boolean']['input']>;
  disclaimer_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  disclaimer_not?: InputMaybe<Scalars['String']['input']>;
  disclaimer_not_contains?: InputMaybe<Scalars['String']['input']>;
  disclaimer_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formAction?: InputMaybe<Scalars['String']['input']>;
  formAction_contains?: InputMaybe<Scalars['String']['input']>;
  formAction_exists?: InputMaybe<Scalars['Boolean']['input']>;
  formAction_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formAction_not?: InputMaybe<Scalars['String']['input']>;
  formAction_not_contains?: InputMaybe<Scalars['String']['input']>;
  formAction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  jsonSchema_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  submitButtonLabel?: InputMaybe<Scalars['String']['input']>;
  submitButtonLabel_contains?: InputMaybe<Scalars['String']['input']>;
  submitButtonLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  submitButtonLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  submitButtonLabel_not?: InputMaybe<Scalars['String']['input']>;
  submitButtonLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  submitButtonLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  uiSchema_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WebFormLinkingCollections = {
  __typename?: 'WebFormLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type WebFormLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum WebFormOrder {
  DisclaimerAsc = 'disclaimer_ASC',
  DisclaimerDesc = 'disclaimer_DESC',
  FormActionAsc = 'formAction_ASC',
  FormActionDesc = 'formAction_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubmitButtonLabelAsc = 'submitButtonLabel_ASC',
  SubmitButtonLabelDesc = 'submitButtonLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/whyChoose) */
export type WhyChoose = Entry &
  _Node & {
    __typename?: 'WhyChoose';
    _id: Scalars['ID']['output'];
    backgroundColor?: Maybe<Scalars['String']['output']>;
    backgroundImage?: Maybe<Asset>;
    contentfulMetadata: ContentfulMetadata;
    header?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<WhyChooseLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    reasonsCollection?: Maybe<WhyChooseReasonsCollection>;
    secondBackgroundColor?: Maybe<Scalars['String']['output']>;
    showHeader?: Maybe<Scalars['Boolean']['output']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/whyChoose) */
export type WhyChooseBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/whyChoose) */
export type WhyChooseBackgroundImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/whyChoose) */
export type WhyChooseHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/whyChoose) */
export type WhyChooseLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/whyChoose) */
export type WhyChooseNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/whyChoose) */
export type WhyChooseReasonsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<WhyChooseReasonsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WhyChooseReasonFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/whyChoose) */
export type WhyChooseSecondBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/whyChoose) */
export type WhyChooseShowHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type WhyChooseCollection = {
  __typename?: 'WhyChooseCollection';
  items: Array<Maybe<WhyChoose>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type WhyChooseFilter = {
  AND?: InputMaybe<Array<InputMaybe<WhyChooseFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<WhyChooseFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundColor_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reasons?: InputMaybe<CfWhyChooseReasonNestedFilter>;
  reasonsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  secondBackgroundColor?: InputMaybe<Scalars['String']['input']>;
  secondBackgroundColor_contains?: InputMaybe<Scalars['String']['input']>;
  secondBackgroundColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  secondBackgroundColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  secondBackgroundColor_not?: InputMaybe<Scalars['String']['input']>;
  secondBackgroundColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  secondBackgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  showHeader?: InputMaybe<Scalars['Boolean']['input']>;
  showHeader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  showHeader_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type WhyChooseLinkingCollections = {
  __typename?: 'WhyChooseLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pageSectionCollection?: Maybe<PageSectionCollection>;
};

export type WhyChooseLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type WhyChooseLinkingCollectionsPageSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<WhyChooseLinkingCollectionsPageSectionCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum WhyChooseLinkingCollectionsPageSectionCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundGradientAsc = 'backgroundGradient_ASC',
  BackgroundGradientDesc = 'backgroundGradient_DESC',
  BackgroundTypeAsc = 'backgroundType_ASC',
  BackgroundTypeDesc = 'backgroundType_DESC',
  EnablePaddingAsc = 'enablePadding_ASC',
  EnablePaddingDesc = 'enablePadding_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum WhyChooseOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/whyChooseReason) */
export type WhyChooseReason = Entry &
  _Node & {
    __typename?: 'WhyChooseReason';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    header?: Maybe<Scalars['String']['output']>;
    image?: Maybe<Asset>;
    imagePosition?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<WhyChooseReasonLinkingCollections>;
    name?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/whyChooseReason) */
export type WhyChooseReasonDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/whyChooseReason) */
export type WhyChooseReasonHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/whyChooseReason) */
export type WhyChooseReasonImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/whyChooseReason) */
export type WhyChooseReasonImagePositionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/whyChooseReason) */
export type WhyChooseReasonLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/sb7j5o4oxtgv/content_types/whyChooseReason) */
export type WhyChooseReasonNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type WhyChooseReasonCollection = {
  __typename?: 'WhyChooseReasonCollection';
  items: Array<Maybe<WhyChooseReason>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type WhyChooseReasonFilter = {
  AND?: InputMaybe<Array<InputMaybe<WhyChooseReasonFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<WhyChooseReasonFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  imagePosition?: InputMaybe<Scalars['String']['input']>;
  imagePosition_contains?: InputMaybe<Scalars['String']['input']>;
  imagePosition_exists?: InputMaybe<Scalars['Boolean']['input']>;
  imagePosition_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  imagePosition_not?: InputMaybe<Scalars['String']['input']>;
  imagePosition_not_contains?: InputMaybe<Scalars['String']['input']>;
  imagePosition_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type WhyChooseReasonLinkingCollections = {
  __typename?: 'WhyChooseReasonLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  solutionsModuleCollection?: Maybe<SolutionsModuleCollection>;
  whyChooseCollection?: Maybe<WhyChooseCollection>;
};

export type WhyChooseReasonLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type WhyChooseReasonLinkingCollectionsSolutionsModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<WhyChooseReasonLinkingCollectionsSolutionsModuleCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type WhyChooseReasonLinkingCollectionsWhyChooseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<WhyChooseReasonLinkingCollectionsWhyChooseCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum WhyChooseReasonLinkingCollectionsSolutionsModuleCollectionOrder {
  ButtonLabelAsc = 'buttonLabel_ASC',
  ButtonLabelDesc = 'buttonLabel_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum WhyChooseReasonLinkingCollectionsWhyChooseCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum WhyChooseReasonOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  ImagePositionAsc = 'imagePosition_ASC',
  ImagePositionDesc = 'imagePosition_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type WhyChooseReasonsCollection = {
  __typename?: 'WhyChooseReasonsCollection';
  items: Array<Maybe<WhyChooseReason>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum WhyChooseReasonsCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  ImagePositionAsc = 'imagePosition_ASC',
  ImagePositionDesc = 'imagePosition_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type _Node = {
  _id: Scalars['ID']['output'];
};

export type CfActionResultNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfActionResultNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfActionResultNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  linksCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfAppReviewNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfAppReviewNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfAppReviewNestedFilter>>>;
  connectedComponent?: InputMaybe<Scalars['String']['input']>;
  connectedComponent_contains?: InputMaybe<Scalars['String']['input']>;
  connectedComponent_exists?: InputMaybe<Scalars['Boolean']['input']>;
  connectedComponent_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  connectedComponent_not?: InputMaybe<Scalars['String']['input']>;
  connectedComponent_not_contains?: InputMaybe<Scalars['String']['input']>;
  connectedComponent_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  isCentred?: InputMaybe<Scalars['Boolean']['input']>;
  isCentred_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isCentred_not?: InputMaybe<Scalars['Boolean']['input']>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  logo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  moduleBlockStyles_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  starIcon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  stars?: InputMaybe<Scalars['Float']['input']>;
  stars_exists?: InputMaybe<Scalars['Boolean']['input']>;
  stars_gt?: InputMaybe<Scalars['Float']['input']>;
  stars_gte?: InputMaybe<Scalars['Float']['input']>;
  stars_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  stars_lt?: InputMaybe<Scalars['Float']['input']>;
  stars_lte?: InputMaybe<Scalars['Float']['input']>;
  stars_not?: InputMaybe<Scalars['Float']['input']>;
  stars_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfBackToSchoolCostCategoryNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfBackToSchoolCostCategoryNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfBackToSchoolCostCategoryNestedFilter>>>;
  categoryHexColour?: InputMaybe<Scalars['String']['input']>;
  categoryHexColour_contains?: InputMaybe<Scalars['String']['input']>;
  categoryHexColour_exists?: InputMaybe<Scalars['Boolean']['input']>;
  categoryHexColour_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  categoryHexColour_not?: InputMaybe<Scalars['String']['input']>;
  categoryHexColour_not_contains?: InputMaybe<Scalars['String']['input']>;
  categoryHexColour_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  categoryImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  categoryKey?: InputMaybe<Scalars['String']['input']>;
  categoryKey_contains?: InputMaybe<Scalars['String']['input']>;
  categoryKey_exists?: InputMaybe<Scalars['Boolean']['input']>;
  categoryKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  categoryKey_not?: InputMaybe<Scalars['String']['input']>;
  categoryKey_not_contains?: InputMaybe<Scalars['String']['input']>;
  categoryKey_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  categoryTitle?: InputMaybe<Scalars['String']['input']>;
  categoryTitle_contains?: InputMaybe<Scalars['String']['input']>;
  categoryTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  categoryTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  categoryTitle_not?: InputMaybe<Scalars['String']['input']>;
  categoryTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  categoryTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfBackToSchoolCountryDataNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfBackToSchoolCountryDataNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfBackToSchoolCountryDataNestedFilter>>>;
  childrenRatio?: InputMaybe<Scalars['Float']['input']>;
  childrenRatio_exists?: InputMaybe<Scalars['Boolean']['input']>;
  childrenRatio_gt?: InputMaybe<Scalars['Float']['input']>;
  childrenRatio_gte?: InputMaybe<Scalars['Float']['input']>;
  childrenRatio_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  childrenRatio_lt?: InputMaybe<Scalars['Float']['input']>;
  childrenRatio_lte?: InputMaybe<Scalars['Float']['input']>;
  childrenRatio_not?: InputMaybe<Scalars['Float']['input']>;
  childrenRatio_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
  circleRatio?: InputMaybe<Scalars['Float']['input']>;
  circleRatio_exists?: InputMaybe<Scalars['Boolean']['input']>;
  circleRatio_gt?: InputMaybe<Scalars['Float']['input']>;
  circleRatio_gte?: InputMaybe<Scalars['Float']['input']>;
  circleRatio_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  circleRatio_lt?: InputMaybe<Scalars['Float']['input']>;
  circleRatio_lte?: InputMaybe<Scalars['Float']['input']>;
  circleRatio_not?: InputMaybe<Scalars['Float']['input']>;
  circleRatio_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  costs_exists?: InputMaybe<Scalars['Boolean']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  countryFlag_exists?: InputMaybe<Scalars['Boolean']['input']>;
  country_contains?: InputMaybe<Scalars['String']['input']>;
  country_exists?: InputMaybe<Scalars['Boolean']['input']>;
  country_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  country_not?: InputMaybe<Scalars['String']['input']>;
  country_not_contains?: InputMaybe<Scalars['String']['input']>;
  country_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  currency?: InputMaybe<Scalars['String']['input']>;
  currencySymbol?: InputMaybe<Scalars['String']['input']>;
  currencySymbol_contains?: InputMaybe<Scalars['String']['input']>;
  currencySymbol_exists?: InputMaybe<Scalars['Boolean']['input']>;
  currencySymbol_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  currencySymbol_not?: InputMaybe<Scalars['String']['input']>;
  currencySymbol_not_contains?: InputMaybe<Scalars['String']['input']>;
  currencySymbol_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  currency_contains?: InputMaybe<Scalars['String']['input']>;
  currency_exists?: InputMaybe<Scalars['Boolean']['input']>;
  currency_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  currency_not?: InputMaybe<Scalars['String']['input']>;
  currency_not_contains?: InputMaybe<Scalars['String']['input']>;
  currency_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exchangeRate?: InputMaybe<Scalars['Float']['input']>;
  exchangeRate_exists?: InputMaybe<Scalars['Boolean']['input']>;
  exchangeRate_gt?: InputMaybe<Scalars['Float']['input']>;
  exchangeRate_gte?: InputMaybe<Scalars['Float']['input']>;
  exchangeRate_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  exchangeRate_lt?: InputMaybe<Scalars['Float']['input']>;
  exchangeRate_lte?: InputMaybe<Scalars['Float']['input']>;
  exchangeRate_not?: InputMaybe<Scalars['Float']['input']>;
  exchangeRate_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
  flagXPosition?: InputMaybe<Scalars['Float']['input']>;
  flagXPosition_exists?: InputMaybe<Scalars['Boolean']['input']>;
  flagXPosition_gt?: InputMaybe<Scalars['Float']['input']>;
  flagXPosition_gte?: InputMaybe<Scalars['Float']['input']>;
  flagXPosition_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  flagXPosition_lt?: InputMaybe<Scalars['Float']['input']>;
  flagXPosition_lte?: InputMaybe<Scalars['Float']['input']>;
  flagXPosition_not?: InputMaybe<Scalars['Float']['input']>;
  flagXPosition_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
  flagYPosition?: InputMaybe<Scalars['Float']['input']>;
  flagYPosition_exists?: InputMaybe<Scalars['Boolean']['input']>;
  flagYPosition_gt?: InputMaybe<Scalars['Float']['input']>;
  flagYPosition_gte?: InputMaybe<Scalars['Float']['input']>;
  flagYPosition_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  flagYPosition_lt?: InputMaybe<Scalars['Float']['input']>;
  flagYPosition_lte?: InputMaybe<Scalars['Float']['input']>;
  flagYPosition_not?: InputMaybe<Scalars['Float']['input']>;
  flagYPosition_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
  householdIncome?: InputMaybe<Scalars['Float']['input']>;
  householdIncome_exists?: InputMaybe<Scalars['Boolean']['input']>;
  householdIncome_gt?: InputMaybe<Scalars['Float']['input']>;
  householdIncome_gte?: InputMaybe<Scalars['Float']['input']>;
  householdIncome_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  householdIncome_lt?: InputMaybe<Scalars['Float']['input']>;
  householdIncome_lte?: InputMaybe<Scalars['Float']['input']>;
  householdIncome_not?: InputMaybe<Scalars['Float']['input']>;
  householdIncome_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  totalChildCost?: InputMaybe<Scalars['Float']['input']>;
  totalChildCost_exists?: InputMaybe<Scalars['Boolean']['input']>;
  totalChildCost_gt?: InputMaybe<Scalars['Float']['input']>;
  totalChildCost_gte?: InputMaybe<Scalars['Float']['input']>;
  totalChildCost_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  totalChildCost_lt?: InputMaybe<Scalars['Float']['input']>;
  totalChildCost_lte?: InputMaybe<Scalars['Float']['input']>;
  totalChildCost_not?: InputMaybe<Scalars['Float']['input']>;
  totalChildCost_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
  totalHouseholdCost?: InputMaybe<Scalars['Float']['input']>;
  totalHouseholdCost_exists?: InputMaybe<Scalars['Boolean']['input']>;
  totalHouseholdCost_gt?: InputMaybe<Scalars['Float']['input']>;
  totalHouseholdCost_gte?: InputMaybe<Scalars['Float']['input']>;
  totalHouseholdCost_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
  totalHouseholdCost_lt?: InputMaybe<Scalars['Float']['input']>;
  totalHouseholdCost_lte?: InputMaybe<Scalars['Float']['input']>;
  totalHouseholdCost_not?: InputMaybe<Scalars['Float']['input']>;
  totalHouseholdCost_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
};

export type CfCalculatorNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCalculatorNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfCalculatorNestedFilter>>>;
  airtimeTopupQuoteAvailableInApp?: InputMaybe<Scalars['String']['input']>;
  airtimeTopupQuoteAvailableInApp_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  airtimeTopupQuoteAvailableInApp_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  airtimeTopupQuoteAvailableInApp_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  airtimeTopupQuoteAvailableInApp_not?: InputMaybe<Scalars['String']['input']>;
  airtimeTopupQuoteAvailableInApp_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  airtimeTopupQuoteAvailableInApp_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  blendedRateLabel?: InputMaybe<Scalars['String']['input']>;
  blendedRateLabel_contains?: InputMaybe<Scalars['String']['input']>;
  blendedRateLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  blendedRateLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  blendedRateLabel_not?: InputMaybe<Scalars['String']['input']>;
  blendedRateLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  blendedRateLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  countriesSearchPlaceholder?: InputMaybe<Scalars['String']['input']>;
  countriesSearchPlaceholder_contains?: InputMaybe<Scalars['String']['input']>;
  countriesSearchPlaceholder_exists?: InputMaybe<Scalars['Boolean']['input']>;
  countriesSearchPlaceholder_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  countriesSearchPlaceholder_not?: InputMaybe<Scalars['String']['input']>;
  countriesSearchPlaceholder_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  countriesSearchPlaceholder_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  ctaLinksCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  estimatedFeeLabel?: InputMaybe<Scalars['String']['input']>;
  estimatedFeeLabel_contains?: InputMaybe<Scalars['String']['input']>;
  estimatedFeeLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  estimatedFeeLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  estimatedFeeLabel_not?: InputMaybe<Scalars['String']['input']>;
  estimatedFeeLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  estimatedFeeLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  exchangeRateLabel?: InputMaybe<Scalars['String']['input']>;
  exchangeRateLabel_contains?: InputMaybe<Scalars['String']['input']>;
  exchangeRateLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  exchangeRateLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  exchangeRateLabel_not?: InputMaybe<Scalars['String']['input']>;
  exchangeRateLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  exchangeRateLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  exchangeRatePromoLabel?: InputMaybe<Scalars['String']['input']>;
  exchangeRatePromoLabel_contains?: InputMaybe<Scalars['String']['input']>;
  exchangeRatePromoLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  exchangeRatePromoLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  exchangeRatePromoLabel_not?: InputMaybe<Scalars['String']['input']>;
  exchangeRatePromoLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  exchangeRatePromoLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  feeLabel?: InputMaybe<Scalars['String']['input']>;
  feeLabel_contains?: InputMaybe<Scalars['String']['input']>;
  feeLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  feeLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  feeLabel_not?: InputMaybe<Scalars['String']['input']>;
  feeLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  feeLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  generalErrorMessage?: InputMaybe<Scalars['String']['input']>;
  generalErrorMessage_contains?: InputMaybe<Scalars['String']['input']>;
  generalErrorMessage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  generalErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  generalErrorMessage_not?: InputMaybe<Scalars['String']['input']>;
  generalErrorMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  generalErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  incorrectCountrySelectedErrorMessage?: InputMaybe<Scalars['String']['input']>;
  incorrectCountrySelectedErrorMessage_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  incorrectCountrySelectedErrorMessage_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  incorrectCountrySelectedErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  incorrectCountrySelectedErrorMessage_not?: InputMaybe<
    Scalars['String']['input']
  >;
  incorrectCountrySelectedErrorMessage_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  incorrectCountrySelectedErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  instantDiscountLabel?: InputMaybe<Scalars['String']['input']>;
  instantDiscountLabel_contains?: InputMaybe<Scalars['String']['input']>;
  instantDiscountLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  instantDiscountLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  instantDiscountLabel_not?: InputMaybe<Scalars['String']['input']>;
  instantDiscountLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  instantDiscountLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  isLite?: InputMaybe<Scalars['Boolean']['input']>;
  isLite_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isLite_not?: InputMaybe<Scalars['Boolean']['input']>;
  loginLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  maxDiscountLabel?: InputMaybe<Scalars['String']['input']>;
  maxDiscountLabel_contains?: InputMaybe<Scalars['String']['input']>;
  maxDiscountLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  maxDiscountLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  maxDiscountLabel_not?: InputMaybe<Scalars['String']['input']>;
  maxDiscountLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  maxDiscountLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  partnerSelectErrorMessage?: InputMaybe<Scalars['String']['input']>;
  partnerSelectErrorMessage_contains?: InputMaybe<Scalars['String']['input']>;
  partnerSelectErrorMessage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  partnerSelectErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  partnerSelectErrorMessage_not?: InputMaybe<Scalars['String']['input']>;
  partnerSelectErrorMessage_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  partnerSelectErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  partnersLabel?: InputMaybe<Scalars['String']['input']>;
  partnersLabel_contains?: InputMaybe<Scalars['String']['input']>;
  partnersLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  partnersLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  partnersLabel_not?: InputMaybe<Scalars['String']['input']>;
  partnersLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  partnersLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  partnersListDefaultValue?: InputMaybe<Scalars['String']['input']>;
  partnersListDefaultValue_contains?: InputMaybe<Scalars['String']['input']>;
  partnersListDefaultValue_exists?: InputMaybe<Scalars['Boolean']['input']>;
  partnersListDefaultValue_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  partnersListDefaultValue_not?: InputMaybe<Scalars['String']['input']>;
  partnersListDefaultValue_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  partnersListDefaultValue_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  payoutMethodsLabel?: InputMaybe<Scalars['String']['input']>;
  payoutMethodsLabel_contains?: InputMaybe<Scalars['String']['input']>;
  payoutMethodsLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  payoutMethodsLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  payoutMethodsLabel_not?: InputMaybe<Scalars['String']['input']>;
  payoutMethodsLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  payoutMethodsLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  payoutMethodsListCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  phReceiveBankTransferMessage?: InputMaybe<Scalars['String']['input']>;
  phReceiveBankTransferMessage_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  phReceiveBankTransferMessage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  phReceiveBankTransferMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phReceiveBankTransferMessage_not?: InputMaybe<Scalars['String']['input']>;
  phReceiveBankTransferMessage_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  phReceiveBankTransferMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneInputInfoMoreInfoLabel?: InputMaybe<Scalars['String']['input']>;
  phoneInputInfoMoreInfoLabel_contains?: InputMaybe<Scalars['String']['input']>;
  phoneInputInfoMoreInfoLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  phoneInputInfoMoreInfoLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneInputInfoMoreInfoLabel_not?: InputMaybe<Scalars['String']['input']>;
  phoneInputInfoMoreInfoLabel_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  phoneInputInfoMoreInfoLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneInputInfoPopupText?: InputMaybe<Scalars['String']['input']>;
  phoneInputInfoPopupText_contains?: InputMaybe<Scalars['String']['input']>;
  phoneInputInfoPopupText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  phoneInputInfoPopupText_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneInputInfoPopupText_not?: InputMaybe<Scalars['String']['input']>;
  phoneInputInfoPopupText_not_contains?: InputMaybe<Scalars['String']['input']>;
  phoneInputInfoPopupText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneNumberInputErrorMessage?: InputMaybe<Scalars['String']['input']>;
  phoneNumberInputErrorMessage_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  phoneNumberInputErrorMessage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumberInputErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneNumberInputErrorMessage_not?: InputMaybe<Scalars['String']['input']>;
  phoneNumberInputErrorMessage_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  phoneNumberInputErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneNumberInvalidErrorMessage?: InputMaybe<Scalars['String']['input']>;
  phoneNumberInvalidErrorMessage_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  phoneNumberInvalidErrorMessage_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  phoneNumberInvalidErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneNumberInvalidErrorMessage_not?: InputMaybe<Scalars['String']['input']>;
  phoneNumberInvalidErrorMessage_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  phoneNumberInvalidErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneValidatorLabel?: InputMaybe<Scalars['String']['input']>;
  phoneValidatorLabel_contains?: InputMaybe<Scalars['String']['input']>;
  phoneValidatorLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  phoneValidatorLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneValidatorLabel_not?: InputMaybe<Scalars['String']['input']>;
  phoneValidatorLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  phoneValidatorLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneValidatorPlaceholder?: InputMaybe<Scalars['String']['input']>;
  phoneValidatorPlaceholder_contains?: InputMaybe<Scalars['String']['input']>;
  phoneValidatorPlaceholder_exists?: InputMaybe<Scalars['Boolean']['input']>;
  phoneValidatorPlaceholder_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phoneValidatorPlaceholder_not?: InputMaybe<Scalars['String']['input']>;
  phoneValidatorPlaceholder_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  phoneValidatorPlaceholder_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  promotionalTermsLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  receiveLabel?: InputMaybe<Scalars['String']['input']>;
  receiveLabel_contains?: InputMaybe<Scalars['String']['input']>;
  receiveLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  receiveLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  receiveLabel_not?: InputMaybe<Scalars['String']['input']>;
  receiveLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  receiveLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiverPayoutMethodUnavailableMessage?: InputMaybe<
    Scalars['String']['input']
  >;
  receiverPayoutMethodUnavailableMessage_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  receiverPayoutMethodUnavailableMessage_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  receiverPayoutMethodUnavailableMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiverPayoutMethodUnavailableMessage_not?: InputMaybe<
    Scalars['String']['input']
  >;
  receiverPayoutMethodUnavailableMessage_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  receiverPayoutMethodUnavailableMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  recipientGetsLabel?: InputMaybe<Scalars['String']['input']>;
  recipientGetsLabel_contains?: InputMaybe<Scalars['String']['input']>;
  recipientGetsLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  recipientGetsLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  recipientGetsLabel_not?: InputMaybe<Scalars['String']['input']>;
  recipientGetsLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  recipientGetsLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendLabel?: InputMaybe<Scalars['String']['input']>;
  sendLabel_contains?: InputMaybe<Scalars['String']['input']>;
  sendLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sendLabel_not?: InputMaybe<Scalars['String']['input']>;
  sendLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  sendLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  senderPayoutMethodUnavailableMessage?: InputMaybe<Scalars['String']['input']>;
  senderPayoutMethodUnavailableMessage_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  senderPayoutMethodUnavailableMessage_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  senderPayoutMethodUnavailableMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  senderPayoutMethodUnavailableMessage_not?: InputMaybe<
    Scalars['String']['input']
  >;
  senderPayoutMethodUnavailableMessage_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  senderPayoutMethodUnavailableMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  signUpLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  topUpAmountsLabel?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsLabel_contains?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  topUpAmountsLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  topUpAmountsLabel_not?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  topUpAmountsPlaceholder?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsPlaceholder_contains?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsPlaceholder_exists?: InputMaybe<Scalars['Boolean']['input']>;
  topUpAmountsPlaceholder_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  topUpAmountsPlaceholder_not?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsPlaceholder_not_contains?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsPlaceholder_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  topUpAmountsSelectErrorMessage?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsSelectErrorMessage_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  topUpAmountsSelectErrorMessage_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  topUpAmountsSelectErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  topUpAmountsSelectErrorMessage_not?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsSelectErrorMessage_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  topUpAmountsSelectErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  topUpAmountsSelectRetrievalErrorMessage?: InputMaybe<
    Scalars['String']['input']
  >;
  topUpAmountsSelectRetrievalErrorMessage_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  topUpAmountsSelectRetrievalErrorMessage_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  topUpAmountsSelectRetrievalErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  topUpAmountsSelectRetrievalErrorMessage_not?: InputMaybe<
    Scalars['String']['input']
  >;
  topUpAmountsSelectRetrievalErrorMessage_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  topUpAmountsSelectRetrievalErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  topUpAmountsTitle?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsTitle_contains?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  topUpAmountsTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  topUpAmountsTitle_not?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  topUpAmountsTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  totalToPayLabel?: InputMaybe<Scalars['String']['input']>;
  totalToPayLabel_contains?: InputMaybe<Scalars['String']['input']>;
  totalToPayLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  totalToPayLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  totalToPayLabel_not?: InputMaybe<Scalars['String']['input']>;
  totalToPayLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  totalToPayLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  transferTimeLabel?: InputMaybe<Scalars['String']['input']>;
  transferTimeLabel_contains?: InputMaybe<Scalars['String']['input']>;
  transferTimeLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  transferTimeLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  transferTimeLabel_not?: InputMaybe<Scalars['String']['input']>;
  transferTimeLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  transferTimeLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  unavailableCorridorErrorMessage?: InputMaybe<Scalars['String']['input']>;
  unavailableCorridorErrorMessage_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  unavailableCorridorErrorMessage_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  unavailableCorridorErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  unavailableCorridorErrorMessage_not?: InputMaybe<Scalars['String']['input']>;
  unavailableCorridorErrorMessage_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  unavailableCorridorErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  version?: InputMaybe<Scalars['String']['input']>;
  version_contains?: InputMaybe<Scalars['String']['input']>;
  version_exists?: InputMaybe<Scalars['Boolean']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version_not?: InputMaybe<Scalars['String']['input']>;
  version_not_contains?: InputMaybe<Scalars['String']['input']>;
  version_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfCalculatorPageNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCalculatorPageNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfCalculatorPageNestedFilter>>>;
  analyticsPageName?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  analyticsPageName_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  analyticsPageName_not?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_not_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  analyticsPageType?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  analyticsPageType_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  analyticsPageType_not?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_not_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  buildLocales_exists?: InputMaybe<Scalars['Boolean']['input']>;
  calculator_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  footer_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isTopPage?: InputMaybe<Scalars['Boolean']['input']>;
  isTopPage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isTopPage_not?: InputMaybe<Scalars['Boolean']['input']>;
  meta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  modulesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noIndex?: InputMaybe<Scalars['Boolean']['input']>;
  noIndex_exists?: InputMaybe<Scalars['Boolean']['input']>;
  noIndex_not?: InputMaybe<Scalars['Boolean']['input']>;
  pricingCalculator_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sitemap?: InputMaybe<Scalars['Boolean']['input']>;
  sitemapTitle?: InputMaybe<Scalars['String']['input']>;
  sitemapTitle_contains?: InputMaybe<Scalars['String']['input']>;
  sitemapTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sitemapTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sitemapTitle_not?: InputMaybe<Scalars['String']['input']>;
  sitemapTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  sitemapTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sitemap_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sitemap_not?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfCardNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCardNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfCardNestedFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundColor_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  payoutMethodId?: InputMaybe<Scalars['String']['input']>;
  payoutMethodId_contains?: InputMaybe<Scalars['String']['input']>;
  payoutMethodId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  payoutMethodId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  payoutMethodId_not?: InputMaybe<Scalars['String']['input']>;
  payoutMethodId_not_contains?: InputMaybe<Scalars['String']['input']>;
  payoutMethodId_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfCexChartFilterOptionsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCexChartFilterOptionsNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfCexChartFilterOptionsNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  filterOption?: InputMaybe<Scalars['String']['input']>;
  filterOption_contains?: InputMaybe<Scalars['String']['input']>;
  filterOption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  filterOption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filterOption_not?: InputMaybe<Scalars['String']['input']>;
  filterOption_not_contains?: InputMaybe<Scalars['String']['input']>;
  filterOption_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  setDefault?: InputMaybe<Scalars['Boolean']['input']>;
  setDefault_exists?: InputMaybe<Scalars['Boolean']['input']>;
  setDefault_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfCexCurrencyNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCexCurrencyNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfCexCurrencyNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  corridorGridTitle?: InputMaybe<Scalars['String']['input']>;
  corridorGridTitle_contains?: InputMaybe<Scalars['String']['input']>;
  corridorGridTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  corridorGridTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  corridorGridTitle_not?: InputMaybe<Scalars['String']['input']>;
  corridorGridTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  corridorGridTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  countryCode_contains?: InputMaybe<Scalars['String']['input']>;
  countryCode_exists?: InputMaybe<Scalars['Boolean']['input']>;
  countryCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  countryCode_not?: InputMaybe<Scalars['String']['input']>;
  countryCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  countryCode_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  countryName?: InputMaybe<Scalars['String']['input']>;
  countryName_contains?: InputMaybe<Scalars['String']['input']>;
  countryName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  countryName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  countryName_not?: InputMaybe<Scalars['String']['input']>;
  countryName_not_contains?: InputMaybe<Scalars['String']['input']>;
  countryName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  currencyCode_contains?: InputMaybe<Scalars['String']['input']>;
  currencyCode_exists?: InputMaybe<Scalars['Boolean']['input']>;
  currencyCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  currencyCode_not?: InputMaybe<Scalars['String']['input']>;
  currencyCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  currencyCode_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  currencyName?: InputMaybe<Scalars['String']['input']>;
  currencyName_contains?: InputMaybe<Scalars['String']['input']>;
  currencyName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  currencyName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  currencyName_not?: InputMaybe<Scalars['String']['input']>;
  currencyName_not_contains?: InputMaybe<Scalars['String']['input']>;
  currencyName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  faqContent_contains?: InputMaybe<Scalars['String']['input']>;
  faqContent_exists?: InputMaybe<Scalars['Boolean']['input']>;
  faqContent_not_contains?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pageSlug?: InputMaybe<Scalars['String']['input']>;
  pageSlug_contains?: InputMaybe<Scalars['String']['input']>;
  pageSlug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  pageSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pageSlug_not?: InputMaybe<Scalars['String']['input']>;
  pageSlug_not_contains?: InputMaybe<Scalars['String']['input']>;
  pageSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  topCurrencyPairsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CfComparisonTableCellNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfComparisonTableCellNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfComparisonTableCellNestedFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['String']['input']>;
  amount_contains?: InputMaybe<Scalars['String']['input']>;
  amount_exists?: InputMaybe<Scalars['Boolean']['input']>;
  amount_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  amount_not?: InputMaybe<Scalars['String']['input']>;
  amount_not_contains?: InputMaybe<Scalars['String']['input']>;
  amount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  fee?: InputMaybe<Scalars['String']['input']>;
  fee_contains?: InputMaybe<Scalars['String']['input']>;
  fee_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fee_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fee_not?: InputMaybe<Scalars['String']['input']>;
  fee_not_contains?: InputMaybe<Scalars['String']['input']>;
  fee_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfComparisonTableRowNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfComparisonTableRowNestedFilter>>>;
  ATPCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  BNKCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  CSHCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  MOBCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  OR?: InputMaybe<Array<InputMaybe<CfComparisonTableRowNestedFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  provider_exists?: InputMaybe<Scalars['Boolean']['input']>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfCorrespondentNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCorrespondentNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfCorrespondentNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfCountryNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCountryNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfCountryNestedFilter>>>;
  article?: InputMaybe<Scalars['String']['input']>;
  article_contains?: InputMaybe<Scalars['String']['input']>;
  article_exists?: InputMaybe<Scalars['Boolean']['input']>;
  article_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  article_not?: InputMaybe<Scalars['String']['input']>;
  article_not_contains?: InputMaybe<Scalars['String']['input']>;
  article_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  calculatorReceiveCountry_exists?: InputMaybe<Scalars['Boolean']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  code_contains?: InputMaybe<Scalars['String']['input']>;
  code_exists?: InputMaybe<Scalars['Boolean']['input']>;
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  code_not?: InputMaybe<Scalars['String']['input']>;
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  prepositionFrom?: InputMaybe<Scalars['String']['input']>;
  prepositionFrom_contains?: InputMaybe<Scalars['String']['input']>;
  prepositionFrom_exists?: InputMaybe<Scalars['Boolean']['input']>;
  prepositionFrom_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  prepositionFrom_not?: InputMaybe<Scalars['String']['input']>;
  prepositionFrom_not_contains?: InputMaybe<Scalars['String']['input']>;
  prepositionFrom_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  prepositionIn?: InputMaybe<Scalars['String']['input']>;
  prepositionIn_contains?: InputMaybe<Scalars['String']['input']>;
  prepositionIn_exists?: InputMaybe<Scalars['Boolean']['input']>;
  prepositionIn_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  prepositionIn_not?: InputMaybe<Scalars['String']['input']>;
  prepositionIn_not_contains?: InputMaybe<Scalars['String']['input']>;
  prepositionIn_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  prepositionTo?: InputMaybe<Scalars['String']['input']>;
  prepositionTo_contains?: InputMaybe<Scalars['String']['input']>;
  prepositionTo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  prepositionTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  prepositionTo_not?: InputMaybe<Scalars['String']['input']>;
  prepositionTo_not_contains?: InputMaybe<Scalars['String']['input']>;
  prepositionTo_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfFaqFeedbackModuleItemNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFaqFeedbackModuleItemNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFaqFeedbackModuleItemNestedFilter>>>;
  analyticsTagId?: InputMaybe<Scalars['String']['input']>;
  analyticsTagId_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsTagId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  analyticsTagId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  analyticsTagId_not?: InputMaybe<Scalars['String']['input']>;
  analyticsTagId_not_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsTagId_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfFaqModuleItemNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFaqModuleItemNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFaqModuleItemNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  path?: InputMaybe<Scalars['String']['input']>;
  path_contains?: InputMaybe<Scalars['String']['input']>;
  path_exists?: InputMaybe<Scalars['Boolean']['input']>;
  path_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  path_not?: InputMaybe<Scalars['String']['input']>;
  path_not_contains?: InputMaybe<Scalars['String']['input']>;
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sortOrder?: InputMaybe<Scalars['String']['input']>;
  sortOrder_contains?: InputMaybe<Scalars['String']['input']>;
  sortOrder_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sortOrder_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sortOrder_not?: InputMaybe<Scalars['String']['input']>;
  sortOrder_not_contains?: InputMaybe<Scalars['String']['input']>;
  sortOrder_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  umbracoId?: InputMaybe<Scalars['String']['input']>;
  umbracoId_contains?: InputMaybe<Scalars['String']['input']>;
  umbracoId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  umbracoId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  umbracoId_not?: InputMaybe<Scalars['String']['input']>;
  umbracoId_not_contains?: InputMaybe<Scalars['String']['input']>;
  umbracoId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfFaqPopularQuestionsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFaqPopularQuestionsNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFaqPopularQuestionsNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  questionsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfFaqSearchHelpItemsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFaqSearchHelpItemsNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFaqSearchHelpItemsNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  gradientColor?: InputMaybe<Scalars['String']['input']>;
  gradientColor_contains?: InputMaybe<Scalars['String']['input']>;
  gradientColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  gradientColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gradientColor_not?: InputMaybe<Scalars['String']['input']>;
  gradientColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  gradientColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  gradientPercentage?: InputMaybe<Scalars['String']['input']>;
  gradientPercentage_contains?: InputMaybe<Scalars['String']['input']>;
  gradientPercentage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  gradientPercentage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  gradientPercentage_not?: InputMaybe<Scalars['String']['input']>;
  gradientPercentage_not_contains?: InputMaybe<Scalars['String']['input']>;
  gradientPercentage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  heading?: InputMaybe<Scalars['String']['input']>;
  heading_contains?: InputMaybe<Scalars['String']['input']>;
  heading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heading_not?: InputMaybe<Scalars['String']['input']>;
  heading_not_contains?: InputMaybe<Scalars['String']['input']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfFaqTopicsModuleItemsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFaqTopicsModuleItemsNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFaqTopicsModuleItemsNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topicLinksCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CfFooterNavigationGroupExtrasNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<CfFooterNavigationGroupExtrasNestedFilter>>
  >;
  OR?: InputMaybe<Array<InputMaybe<CfFooterNavigationGroupExtrasNestedFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundColor_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  color?: InputMaybe<Scalars['String']['input']>;
  color_contains?: InputMaybe<Scalars['String']['input']>;
  color_exists?: InputMaybe<Scalars['Boolean']['input']>;
  color_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color_not?: InputMaybe<Scalars['String']['input']>;
  color_not_contains?: InputMaybe<Scalars['String']['input']>;
  color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfFooterNavigationGroupNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFooterNavigationGroupNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFooterNavigationGroupNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  extras_exists?: InputMaybe<Scalars['Boolean']['input']>;
  linksCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfLayoutFooterNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfLayoutFooterNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfLayoutFooterNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  copyrightText?: InputMaybe<Scalars['String']['input']>;
  copyrightText_contains?: InputMaybe<Scalars['String']['input']>;
  copyrightText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  copyrightText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  copyrightText_not?: InputMaybe<Scalars['String']['input']>;
  copyrightText_not_contains?: InputMaybe<Scalars['String']['input']>;
  copyrightText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  footerLogo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  navigationCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  region_contains?: InputMaybe<Scalars['String']['input']>;
  region_exists?: InputMaybe<Scalars['Boolean']['input']>;
  region_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  region_not?: InputMaybe<Scalars['String']['input']>;
  region_not_contains?: InputMaybe<Scalars['String']['input']>;
  region_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  socialIconsLinksCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfLinkNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfLinkNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfLinkNestedFilter>>>;
  align?: InputMaybe<Scalars['String']['input']>;
  align_contains?: InputMaybe<Scalars['String']['input']>;
  align_exists?: InputMaybe<Scalars['Boolean']['input']>;
  align_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  align_not?: InputMaybe<Scalars['String']['input']>;
  align_not_contains?: InputMaybe<Scalars['String']['input']>;
  align_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ariaLabel?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_contains?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ariaLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ariaLabel_not?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color?: InputMaybe<Scalars['String']['input']>;
  color_contains?: InputMaybe<Scalars['String']['input']>;
  color_exists?: InputMaybe<Scalars['Boolean']['input']>;
  color_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color_not?: InputMaybe<Scalars['String']['input']>;
  color_not_contains?: InputMaybe<Scalars['String']['input']>;
  color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  gaTrackName?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_contains?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  gaTrackName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gaTrackName_not?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_not_contains?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  highlighted?: InputMaybe<Scalars['Boolean']['input']>;
  highlighted_exists?: InputMaybe<Scalars['Boolean']['input']>;
  highlighted_not?: InputMaybe<Scalars['Boolean']['input']>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn_not?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal_not?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mobileAppLeadingLinks_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileMenuToggle?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_contains?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileMenuToggle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  mobileMenuToggle_not?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_not_contains?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noFollow?: InputMaybe<Scalars['Boolean']['input']>;
  noFollow_exists?: InputMaybe<Scalars['Boolean']['input']>;
  noFollow_not?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab_not?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject_exists?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  underline?: InputMaybe<Scalars['String']['input']>;
  underline_contains?: InputMaybe<Scalars['String']['input']>;
  underline_exists?: InputMaybe<Scalars['Boolean']['input']>;
  underline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  underline_not?: InputMaybe<Scalars['String']['input']>;
  underline_not_contains?: InputMaybe<Scalars['String']['input']>;
  underline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_contains?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_not?: InputMaybe<Scalars['String']['input']>;
  url_not_contains?: InputMaybe<Scalars['String']['input']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variant?: InputMaybe<Scalars['String']['input']>;
  variant_contains?: InputMaybe<Scalars['String']['input']>;
  variant_exists?: InputMaybe<Scalars['Boolean']['input']>;
  variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variant_not?: InputMaybe<Scalars['String']['input']>;
  variant_not_contains?: InputMaybe<Scalars['String']['input']>;
  variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfMemberNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfMemberNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfMemberNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullName_contains?: InputMaybe<Scalars['String']['input']>;
  fullName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fullName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fullName_not?: InputMaybe<Scalars['String']['input']>;
  fullName_not_contains?: InputMaybe<Scalars['String']['input']>;
  fullName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfMobileAppLeadingLinksNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfMobileAppLeadingLinksNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfMobileAppLeadingLinksNestedFilter>>>;
  androidLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  desktopLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  iosLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfMobileStoreDataNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfMobileStoreDataNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfMobileStoreDataNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  iconLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rating?: InputMaybe<Scalars['Float']['input']>;
  rating_exists?: InputMaybe<Scalars['Boolean']['input']>;
  rating_gt?: InputMaybe<Scalars['Float']['input']>;
  rating_gte?: InputMaybe<Scalars['Float']['input']>;
  rating_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  rating_lt?: InputMaybe<Scalars['Float']['input']>;
  rating_lte?: InputMaybe<Scalars['Float']['input']>;
  rating_not?: InputMaybe<Scalars['Float']['input']>;
  rating_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  ratingsAmount?: InputMaybe<Scalars['String']['input']>;
  ratingsAmount_contains?: InputMaybe<Scalars['String']['input']>;
  ratingsAmount_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ratingsAmount_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ratingsAmount_not?: InputMaybe<Scalars['String']['input']>;
  ratingsAmount_not_contains?: InputMaybe<Scalars['String']['input']>;
  ratingsAmount_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
};

export type CfMoneyRequestConfirmationDialogNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<CfMoneyRequestConfirmationDialogNestedFilter>>
  >;
  OR?: InputMaybe<
    Array<InputMaybe<CfMoneyRequestConfirmationDialogNestedFilter>>
  >;
  acceptButtonText?: InputMaybe<Scalars['String']['input']>;
  acceptButtonText_contains?: InputMaybe<Scalars['String']['input']>;
  acceptButtonText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  acceptButtonText_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  acceptButtonText_not?: InputMaybe<Scalars['String']['input']>;
  acceptButtonText_not_contains?: InputMaybe<Scalars['String']['input']>;
  acceptButtonText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  content_contains?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  declineButtonText?: InputMaybe<Scalars['String']['input']>;
  declineButtonText_contains?: InputMaybe<Scalars['String']['input']>;
  declineButtonText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  declineButtonText_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  declineButtonText_not?: InputMaybe<Scalars['String']['input']>;
  declineButtonText_not_contains?: InputMaybe<Scalars['String']['input']>;
  declineButtonText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfMoneyRequestPaymentReasonNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfMoneyRequestPaymentReasonNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfMoneyRequestPaymentReasonNestedFilter>>>;
  button_exists?: InputMaybe<Scalars['Boolean']['input']>;
  confirmationDialog_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reasonsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfMoneyTransferCardNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfMoneyTransferCardNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfMoneyTransferCardNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  helpLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  payoutMethodId?: InputMaybe<Scalars['String']['input']>;
  payoutMethodId_contains?: InputMaybe<Scalars['String']['input']>;
  payoutMethodId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  payoutMethodId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  payoutMethodId_not?: InputMaybe<Scalars['String']['input']>;
  payoutMethodId_not_contains?: InputMaybe<Scalars['String']['input']>;
  payoutMethodId_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfPageNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPageNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfPageNestedFilter>>>;
  analyticsPageName?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  analyticsPageName_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  analyticsPageName_not?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_not_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  analyticsPageType?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  analyticsPageType_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  analyticsPageType_not?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_not_contains?: InputMaybe<Scalars['String']['input']>;
  analyticsPageType_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  author_exists?: InputMaybe<Scalars['Boolean']['input']>;
  banner_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_contains?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  footer_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headerPosition?: InputMaybe<Scalars['String']['input']>;
  headerPosition_contains?: InputMaybe<Scalars['String']['input']>;
  headerPosition_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headerPosition_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headerPosition_not?: InputMaybe<Scalars['String']['input']>;
  headerPosition_not_contains?: InputMaybe<Scalars['String']['input']>;
  headerPosition_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  headerTheme?: InputMaybe<Scalars['String']['input']>;
  headerTheme_contains?: InputMaybe<Scalars['String']['input']>;
  headerTheme_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headerTheme_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headerTheme_not?: InputMaybe<Scalars['String']['input']>;
  headerTheme_not_contains?: InputMaybe<Scalars['String']['input']>;
  headerTheme_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  heading?: InputMaybe<Scalars['String']['input']>;
  heading_contains?: InputMaybe<Scalars['String']['input']>;
  heading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heading_not?: InputMaybe<Scalars['String']['input']>;
  heading_not_contains?: InputMaybe<Scalars['String']['input']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  modulesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noIndex?: InputMaybe<Scalars['Boolean']['input']>;
  noIndex_exists?: InputMaybe<Scalars['Boolean']['input']>;
  noIndex_not?: InputMaybe<Scalars['Boolean']['input']>;
  ogImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  publishedDate_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publishedDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  publishedDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  publishedDate_in?: InputMaybe<
    Array<InputMaybe<Scalars['DateTime']['input']>>
  >;
  publishedDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  publishedDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  publishedDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  publishedDate_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['DateTime']['input']>>
  >;
  regions_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  regions_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  regions_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  regions_exists?: InputMaybe<Scalars['Boolean']['input']>;
  relatedPagesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sitemap?: InputMaybe<Scalars['Boolean']['input']>;
  sitemapTitle?: InputMaybe<Scalars['String']['input']>;
  sitemapTitle_contains?: InputMaybe<Scalars['String']['input']>;
  sitemapTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sitemapTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sitemapTitle_not?: InputMaybe<Scalars['String']['input']>;
  sitemapTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  sitemapTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sitemap_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sitemap_not?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  submenuItemsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  timeToRead?: InputMaybe<Scalars['String']['input']>;
  timeToRead_contains?: InputMaybe<Scalars['String']['input']>;
  timeToRead_exists?: InputMaybe<Scalars['Boolean']['input']>;
  timeToRead_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  timeToRead_not?: InputMaybe<Scalars['String']['input']>;
  timeToRead_not_contains?: InputMaybe<Scalars['String']['input']>;
  timeToRead_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updatedDate?: InputMaybe<Scalars['DateTime']['input']>;
  updatedDate_exists?: InputMaybe<Scalars['Boolean']['input']>;
  updatedDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedDate_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['DateTime']['input']>>
  >;
};

export type CfPaymentReasonNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPaymentReasonNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfPaymentReasonNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reason?: InputMaybe<Scalars['String']['input']>;
  reasonId?: InputMaybe<Scalars['String']['input']>;
  reasonId_contains?: InputMaybe<Scalars['String']['input']>;
  reasonId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  reasonId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reasonId_not?: InputMaybe<Scalars['String']['input']>;
  reasonId_not_contains?: InputMaybe<Scalars['String']['input']>;
  reasonId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reason_contains?: InputMaybe<Scalars['String']['input']>;
  reason_exists?: InputMaybe<Scalars['Boolean']['input']>;
  reason_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reason_not?: InputMaybe<Scalars['String']['input']>;
  reason_not_contains?: InputMaybe<Scalars['String']['input']>;
  reason_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfPayoutMethodNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPayoutMethodNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfPayoutMethodNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  cornerGraphic_exists?: InputMaybe<Scalars['Boolean']['input']>;
  correspondentsTitle?: InputMaybe<Scalars['String']['input']>;
  correspondentsTitle_contains?: InputMaybe<Scalars['String']['input']>;
  correspondentsTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  correspondentsTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  correspondentsTitle_not?: InputMaybe<Scalars['String']['input']>;
  correspondentsTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  correspondentsTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  countriesFromCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  countriesToCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ctaButtonBaseUrl?: InputMaybe<Scalars['String']['input']>;
  ctaButtonBaseUrl_contains?: InputMaybe<Scalars['String']['input']>;
  ctaButtonBaseUrl_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ctaButtonBaseUrl_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  ctaButtonBaseUrl_not?: InputMaybe<Scalars['String']['input']>;
  ctaButtonBaseUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  ctaButtonBaseUrl_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  ctaButtonLabel?: InputMaybe<Scalars['String']['input']>;
  ctaButtonLabel_contains?: InputMaybe<Scalars['String']['input']>;
  ctaButtonLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ctaButtonLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ctaButtonLabel_not?: InputMaybe<Scalars['String']['input']>;
  ctaButtonLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  ctaButtonLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  exchangeRateLabel?: InputMaybe<Scalars['String']['input']>;
  exchangeRateLabel_contains?: InputMaybe<Scalars['String']['input']>;
  exchangeRateLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  exchangeRateLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  exchangeRateLabel_not?: InputMaybe<Scalars['String']['input']>;
  exchangeRateLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  exchangeRateLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  feesLabel?: InputMaybe<Scalars['String']['input']>;
  feesLabel_contains?: InputMaybe<Scalars['String']['input']>;
  feesLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  feesLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  feesLabel_not?: InputMaybe<Scalars['String']['input']>;
  feesLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  feesLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hideExchangeRate?: InputMaybe<Scalars['Boolean']['input']>;
  hideExchangeRate_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hideExchangeRate_not?: InputMaybe<Scalars['Boolean']['input']>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  payoutMethodId?: InputMaybe<Scalars['String']['input']>;
  payoutMethodId_contains?: InputMaybe<Scalars['String']['input']>;
  payoutMethodId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  payoutMethodId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  payoutMethodId_not?: InputMaybe<Scalars['String']['input']>;
  payoutMethodId_not_contains?: InputMaybe<Scalars['String']['input']>;
  payoutMethodId_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveMethodDescription?: InputMaybe<Scalars['String']['input']>;
  receiveMethodDescription_contains?: InputMaybe<Scalars['String']['input']>;
  receiveMethodDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  receiveMethodDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveMethodDescription_not?: InputMaybe<Scalars['String']['input']>;
  receiveMethodDescription_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  receiveMethodDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveMethodLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  supportLinkText?: InputMaybe<Scalars['String']['input']>;
  supportLinkText_contains?: InputMaybe<Scalars['String']['input']>;
  supportLinkText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  supportLinkText_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  supportLinkText_not?: InputMaybe<Scalars['String']['input']>;
  supportLinkText_not_contains?: InputMaybe<Scalars['String']['input']>;
  supportLinkText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  transferTimeLabel?: InputMaybe<Scalars['String']['input']>;
  transferTimeLabel_contains?: InputMaybe<Scalars['String']['input']>;
  transferTimeLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  transferTimeLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  transferTimeLabel_not?: InputMaybe<Scalars['String']['input']>;
  transferTimeLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  transferTimeLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  transferTimeTooltip?: InputMaybe<Scalars['String']['input']>;
  transferTimeTooltip_contains?: InputMaybe<Scalars['String']['input']>;
  transferTimeTooltip_exists?: InputMaybe<Scalars['Boolean']['input']>;
  transferTimeTooltip_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  transferTimeTooltip_not?: InputMaybe<Scalars['String']['input']>;
  transferTimeTooltip_not_contains?: InputMaybe<Scalars['String']['input']>;
  transferTimeTooltip_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
};

export type CfPersonNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPersonNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfPersonNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstName_contains?: InputMaybe<Scalars['String']['input']>;
  firstName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  firstName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  firstName_not?: InputMaybe<Scalars['String']['input']>;
  firstName_not_contains?: InputMaybe<Scalars['String']['input']>;
  firstName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastName_contains?: InputMaybe<Scalars['String']['input']>;
  lastName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  lastName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lastName_not?: InputMaybe<Scalars['String']['input']>;
  lastName_not_contains?: InputMaybe<Scalars['String']['input']>;
  lastName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfPopularCountriesNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPopularCountriesNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfPopularCountriesNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  popularCountriesCodes_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  popularCountriesCodes_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  popularCountriesCodes_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  popularCountriesCodes_exists?: InputMaybe<Scalars['Boolean']['input']>;
  popularCountriesHeading?: InputMaybe<Scalars['String']['input']>;
  popularCountriesHeading_contains?: InputMaybe<Scalars['String']['input']>;
  popularCountriesHeading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  popularCountriesHeading_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  popularCountriesHeading_not?: InputMaybe<Scalars['String']['input']>;
  popularCountriesHeading_not_contains?: InputMaybe<Scalars['String']['input']>;
  popularCountriesHeading_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
};

export type CfPricingCalculatorNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPricingCalculatorNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfPricingCalculatorNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfPromoApplicabilityNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPromoApplicabilityNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfPromoApplicabilityNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  showForCorridors_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  showForCorridors_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  showForCorridors_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  showForCorridors_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  visitorTypes_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  visitorTypes_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  visitorTypes_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  visitorTypes_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CfPromotionalBannerNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPromotionalBannerNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfPromotionalBannerNestedFilter>>>;
  abTestVersionsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  addToInitialHtml?: InputMaybe<Scalars['Boolean']['input']>;
  addToInitialHtml_exists?: InputMaybe<Scalars['Boolean']['input']>;
  addToInitialHtml_not?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundImageMobile_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  button_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  promoApplicability_exists?: InputMaybe<Scalars['Boolean']['input']>;
  promoIcon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  showForCorridors_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  showForCorridors_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  showForCorridors_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  showForCorridors_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  visitorTypes_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  visitorTypes_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  visitorTypes_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  visitorTypes_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CfRegulatorNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfRegulatorNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfRegulatorNestedFilter>>>;
  code?: InputMaybe<Scalars['String']['input']>;
  code_contains?: InputMaybe<Scalars['String']['input']>;
  code_exists?: InputMaybe<Scalars['Boolean']['input']>;
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  code_not?: InputMaybe<Scalars['String']['input']>;
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  content_contains?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  countriesListCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  customFlagCode?: InputMaybe<Scalars['String']['input']>;
  customFlagCode_contains?: InputMaybe<Scalars['String']['input']>;
  customFlagCode_exists?: InputMaybe<Scalars['Boolean']['input']>;
  customFlagCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  customFlagCode_not?: InputMaybe<Scalars['String']['input']>;
  customFlagCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  customFlagCode_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  customRegulatorName?: InputMaybe<Scalars['String']['input']>;
  customRegulatorName_contains?: InputMaybe<Scalars['String']['input']>;
  customRegulatorName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  customRegulatorName_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  customRegulatorName_not?: InputMaybe<Scalars['String']['input']>;
  customRegulatorName_not_contains?: InputMaybe<Scalars['String']['input']>;
  customRegulatorName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfReviewNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfReviewNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfReviewNestedFilter>>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  comment_contains?: InputMaybe<Scalars['String']['input']>;
  comment_exists?: InputMaybe<Scalars['Boolean']['input']>;
  comment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  comment_not?: InputMaybe<Scalars['String']['input']>;
  comment_not_contains?: InputMaybe<Scalars['String']['input']>;
  comment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  countryCode_contains?: InputMaybe<Scalars['String']['input']>;
  countryCode_exists?: InputMaybe<Scalars['Boolean']['input']>;
  countryCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  countryCode_not?: InputMaybe<Scalars['String']['input']>;
  countryCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  countryCode_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reviewDate?: InputMaybe<Scalars['DateTime']['input']>;
  reviewDate_exists?: InputMaybe<Scalars['Boolean']['input']>;
  reviewDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  reviewDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  reviewDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  reviewDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  reviewDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  reviewDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  reviewDate_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['DateTime']['input']>>
  >;
  reviewer?: InputMaybe<Scalars['String']['input']>;
  reviewer_contains?: InputMaybe<Scalars['String']['input']>;
  reviewer_exists?: InputMaybe<Scalars['Boolean']['input']>;
  reviewer_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reviewer_not?: InputMaybe<Scalars['String']['input']>;
  reviewer_not_contains?: InputMaybe<Scalars['String']['input']>;
  reviewer_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reviewsNumberText?: InputMaybe<Scalars['String']['input']>;
  reviewsNumberText_contains?: InputMaybe<Scalars['String']['input']>;
  reviewsNumberText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  reviewsNumberText_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  reviewsNumberText_not?: InputMaybe<Scalars['String']['input']>;
  reviewsNumberText_not_contains?: InputMaybe<Scalars['String']['input']>;
  reviewsNumberText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  stars?: InputMaybe<Scalars['Int']['input']>;
  stars_exists?: InputMaybe<Scalars['Boolean']['input']>;
  stars_gt?: InputMaybe<Scalars['Int']['input']>;
  stars_gte?: InputMaybe<Scalars['Int']['input']>;
  stars_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  stars_lt?: InputMaybe<Scalars['Int']['input']>;
  stars_lte?: InputMaybe<Scalars['Int']['input']>;
  stars_not?: InputMaybe<Scalars['Int']['input']>;
  stars_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfReviewsTimeDictionaryNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfReviewsTimeDictionaryNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfReviewsTimeDictionaryNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  fewDaysAgo?: InputMaybe<Scalars['String']['input']>;
  fewDaysAgo_contains?: InputMaybe<Scalars['String']['input']>;
  fewDaysAgo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fewDaysAgo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fewDaysAgo_not?: InputMaybe<Scalars['String']['input']>;
  fewDaysAgo_not_contains?: InputMaybe<Scalars['String']['input']>;
  fewDaysAgo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fewMonthsAgo?: InputMaybe<Scalars['String']['input']>;
  fewMonthsAgo_contains?: InputMaybe<Scalars['String']['input']>;
  fewMonthsAgo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fewMonthsAgo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fewMonthsAgo_not?: InputMaybe<Scalars['String']['input']>;
  fewMonthsAgo_not_contains?: InputMaybe<Scalars['String']['input']>;
  fewMonthsAgo_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  fewWeeksAgo?: InputMaybe<Scalars['String']['input']>;
  fewWeeksAgo_contains?: InputMaybe<Scalars['String']['input']>;
  fewWeeksAgo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fewWeeksAgo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fewWeeksAgo_not?: InputMaybe<Scalars['String']['input']>;
  fewWeeksAgo_not_contains?: InputMaybe<Scalars['String']['input']>;
  fewWeeksAgo_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  oneDayAgo?: InputMaybe<Scalars['String']['input']>;
  oneDayAgo_contains?: InputMaybe<Scalars['String']['input']>;
  oneDayAgo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  oneDayAgo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  oneDayAgo_not?: InputMaybe<Scalars['String']['input']>;
  oneDayAgo_not_contains?: InputMaybe<Scalars['String']['input']>;
  oneDayAgo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  oneMonthAgo?: InputMaybe<Scalars['String']['input']>;
  oneMonthAgo_contains?: InputMaybe<Scalars['String']['input']>;
  oneMonthAgo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  oneMonthAgo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  oneMonthAgo_not?: InputMaybe<Scalars['String']['input']>;
  oneMonthAgo_not_contains?: InputMaybe<Scalars['String']['input']>;
  oneMonthAgo_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  oneWeekAgo?: InputMaybe<Scalars['String']['input']>;
  oneWeekAgo_contains?: InputMaybe<Scalars['String']['input']>;
  oneWeekAgo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  oneWeekAgo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  oneWeekAgo_not?: InputMaybe<Scalars['String']['input']>;
  oneWeekAgo_not_contains?: InputMaybe<Scalars['String']['input']>;
  oneWeekAgo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  today?: InputMaybe<Scalars['String']['input']>;
  today_contains?: InputMaybe<Scalars['String']['input']>;
  today_exists?: InputMaybe<Scalars['Boolean']['input']>;
  today_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  today_not?: InputMaybe<Scalars['String']['input']>;
  today_not_contains?: InputMaybe<Scalars['String']['input']>;
  today_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfSearchNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSearchNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfSearchNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  heading?: InputMaybe<Scalars['String']['input']>;
  heading_contains?: InputMaybe<Scalars['String']['input']>;
  heading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heading_not?: InputMaybe<Scalars['String']['input']>;
  heading_not_contains?: InputMaybe<Scalars['String']['input']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  inputLabel?: InputMaybe<Scalars['String']['input']>;
  inputLabel_contains?: InputMaybe<Scalars['String']['input']>;
  inputLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  inputLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  inputLabel_not?: InputMaybe<Scalars['String']['input']>;
  inputLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  inputLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  resultListLabel?: InputMaybe<Scalars['String']['input']>;
  resultListLabel_contains?: InputMaybe<Scalars['String']['input']>;
  resultListLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  resultListLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  resultListLabel_not?: InputMaybe<Scalars['String']['input']>;
  resultListLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  resultListLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  suggestionListCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  suggestionListLabel?: InputMaybe<Scalars['String']['input']>;
  suggestionListLabel_contains?: InputMaybe<Scalars['String']['input']>;
  suggestionListLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  suggestionListLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  suggestionListLabel_not?: InputMaybe<Scalars['String']['input']>;
  suggestionListLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  suggestionListLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
};

export type CfSeoContactPointNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSeoContactPointNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfSeoContactPointNestedFilter>>>;
  areaServed?: InputMaybe<Scalars['String']['input']>;
  areaServed_contains?: InputMaybe<Scalars['String']['input']>;
  areaServed_exists?: InputMaybe<Scalars['Boolean']['input']>;
  areaServed_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  areaServed_not?: InputMaybe<Scalars['String']['input']>;
  areaServed_not_contains?: InputMaybe<Scalars['String']['input']>;
  areaServed_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contactType?: InputMaybe<Scalars['String']['input']>;
  contactType_contains?: InputMaybe<Scalars['String']['input']>;
  contactType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contactType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contactType_not?: InputMaybe<Scalars['String']['input']>;
  contactType_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactType_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  telephone?: InputMaybe<Scalars['String']['input']>;
  telephone_contains?: InputMaybe<Scalars['String']['input']>;
  telephone_exists?: InputMaybe<Scalars['Boolean']['input']>;
  telephone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  telephone_not?: InputMaybe<Scalars['String']['input']>;
  telephone_not_contains?: InputMaybe<Scalars['String']['input']>;
  telephone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfSeoSameAsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSeoSameAsNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfSeoSameAsNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_exists?: InputMaybe<Scalars['Boolean']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfSignInNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSignInNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfSignInNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  loginButton_exists?: InputMaybe<Scalars['Boolean']['input']>;
  loginDescription?: InputMaybe<Scalars['String']['input']>;
  loginDescription_contains?: InputMaybe<Scalars['String']['input']>;
  loginDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  loginDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  loginDescription_not?: InputMaybe<Scalars['String']['input']>;
  loginDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  loginDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  loginTitle?: InputMaybe<Scalars['String']['input']>;
  loginTitle_contains?: InputMaybe<Scalars['String']['input']>;
  loginTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  loginTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  loginTitle_not?: InputMaybe<Scalars['String']['input']>;
  loginTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  loginTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  signUpButton_exists?: InputMaybe<Scalars['Boolean']['input']>;
  signUpDescription?: InputMaybe<Scalars['String']['input']>;
  signUpDescription_contains?: InputMaybe<Scalars['String']['input']>;
  signUpDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  signUpDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  signUpDescription_not?: InputMaybe<Scalars['String']['input']>;
  signUpDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  signUpDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  signUpTitle?: InputMaybe<Scalars['String']['input']>;
  signUpTitle_contains?: InputMaybe<Scalars['String']['input']>;
  signUpTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  signUpTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  signUpTitle_not?: InputMaybe<Scalars['String']['input']>;
  signUpTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  signUpTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfStepNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfStepNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfStepNestedFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfStickyBannerNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfStickyBannerNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfStickyBannerNestedFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundColor_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  cookies_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  cookies_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  cookies_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  cookies_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  marketingChannel_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  marketingChannel_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  marketingChannel_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  marketingChannel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pageTypes_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  pageTypes_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  pageTypes_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  pageTypes_exists?: InputMaybe<Scalars['Boolean']['input']>;
  queryParams_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  queryParams_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  queryParams_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  queryParams_exists?: InputMaybe<Scalars['Boolean']['input']>;
  receiveCountriesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountriesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  shouldMatchAllCookies?: InputMaybe<Scalars['Boolean']['input']>;
  shouldMatchAllCookies_exists?: InputMaybe<Scalars['Boolean']['input']>;
  shouldMatchAllCookies_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  textColor?: InputMaybe<Scalars['String']['input']>;
  textColor_contains?: InputMaybe<Scalars['String']['input']>;
  textColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  textColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  textColor_not?: InputMaybe<Scalars['String']['input']>;
  textColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  textColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  visitorTypes_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  visitorTypes_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  visitorTypes_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  visitorTypes_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CfTextWithColorNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfTextWithColorNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfTextWithColorNestedFilter>>>;
  align?: InputMaybe<Scalars['String']['input']>;
  align_contains?: InputMaybe<Scalars['String']['input']>;
  align_exists?: InputMaybe<Scalars['Boolean']['input']>;
  align_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  align_not?: InputMaybe<Scalars['String']['input']>;
  align_not_contains?: InputMaybe<Scalars['String']['input']>;
  align_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backgroundColor_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  color?: InputMaybe<Scalars['String']['input']>;
  color_contains?: InputMaybe<Scalars['String']['input']>;
  color_exists?: InputMaybe<Scalars['Boolean']['input']>;
  color_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color_not?: InputMaybe<Scalars['String']['input']>;
  color_not_contains?: InputMaybe<Scalars['String']['input']>;
  color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  inline?: InputMaybe<Scalars['Boolean']['input']>;
  inline_exists?: InputMaybe<Scalars['Boolean']['input']>;
  inline_not?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  text_contains?: InputMaybe<Scalars['String']['input']>;
  text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_not_contains?: InputMaybe<Scalars['String']['input']>;
};

export type CfWhyChooseReasonNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfWhyChooseReasonNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfWhyChooseReasonNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  imagePosition?: InputMaybe<Scalars['String']['input']>;
  imagePosition_contains?: InputMaybe<Scalars['String']['input']>;
  imagePosition_exists?: InputMaybe<Scalars['Boolean']['input']>;
  imagePosition_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  imagePosition_not?: InputMaybe<Scalars['String']['input']>;
  imagePosition_not_contains?: InputMaybe<Scalars['String']['input']>;
  imagePosition_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfanimationStepsMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfanimationStepsMultiTypeNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfanimationStepsMultiTypeNestedFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']['input']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfloggedInMenuItemsMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfloggedInMenuItemsMultiTypeNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfloggedInMenuItemsMultiTypeNestedFilter>>>;
  align?: InputMaybe<Scalars['String']['input']>;
  align_contains?: InputMaybe<Scalars['String']['input']>;
  align_exists?: InputMaybe<Scalars['Boolean']['input']>;
  align_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  align_not?: InputMaybe<Scalars['String']['input']>;
  align_not_contains?: InputMaybe<Scalars['String']['input']>;
  align_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ariaLabel?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_contains?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ariaLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ariaLabel_not?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color?: InputMaybe<Scalars['String']['input']>;
  color_contains?: InputMaybe<Scalars['String']['input']>;
  color_exists?: InputMaybe<Scalars['Boolean']['input']>;
  color_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color_not?: InputMaybe<Scalars['String']['input']>;
  color_not_contains?: InputMaybe<Scalars['String']['input']>;
  color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  gaTrackName?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_contains?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  gaTrackName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gaTrackName_not?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_not_contains?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  highlighted?: InputMaybe<Scalars['Boolean']['input']>;
  highlighted_exists?: InputMaybe<Scalars['Boolean']['input']>;
  highlighted_not?: InputMaybe<Scalars['Boolean']['input']>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn_not?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal_not?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mobileAppLeadingLinks_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileMenuToggle?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_contains?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileMenuToggle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  mobileMenuToggle_not?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_not_contains?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noFollow?: InputMaybe<Scalars['Boolean']['input']>;
  noFollow_exists?: InputMaybe<Scalars['Boolean']['input']>;
  noFollow_not?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab_not?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject_exists?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  underline?: InputMaybe<Scalars['String']['input']>;
  underline_contains?: InputMaybe<Scalars['String']['input']>;
  underline_exists?: InputMaybe<Scalars['Boolean']['input']>;
  underline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  underline_not?: InputMaybe<Scalars['String']['input']>;
  underline_not_contains?: InputMaybe<Scalars['String']['input']>;
  underline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_contains?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_not?: InputMaybe<Scalars['String']['input']>;
  url_not_contains?: InputMaybe<Scalars['String']['input']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variant?: InputMaybe<Scalars['String']['input']>;
  variant_contains?: InputMaybe<Scalars['String']['input']>;
  variant_exists?: InputMaybe<Scalars['Boolean']['input']>;
  variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variant_not?: InputMaybe<Scalars['String']['input']>;
  variant_not_contains?: InputMaybe<Scalars['String']['input']>;
  variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfmodulesMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfmodulesMultiTypeNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfmodulesMultiTypeNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfnotLoggedInMenuItemsMultiTypeNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<CfnotLoggedInMenuItemsMultiTypeNestedFilter>>
  >;
  OR?: InputMaybe<
    Array<InputMaybe<CfnotLoggedInMenuItemsMultiTypeNestedFilter>>
  >;
  align?: InputMaybe<Scalars['String']['input']>;
  align_contains?: InputMaybe<Scalars['String']['input']>;
  align_exists?: InputMaybe<Scalars['Boolean']['input']>;
  align_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  align_not?: InputMaybe<Scalars['String']['input']>;
  align_not_contains?: InputMaybe<Scalars['String']['input']>;
  align_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ariaLabel?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_contains?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ariaLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ariaLabel_not?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color?: InputMaybe<Scalars['String']['input']>;
  color_contains?: InputMaybe<Scalars['String']['input']>;
  color_exists?: InputMaybe<Scalars['Boolean']['input']>;
  color_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color_not?: InputMaybe<Scalars['String']['input']>;
  color_not_contains?: InputMaybe<Scalars['String']['input']>;
  color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  gaTrackName?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_contains?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  gaTrackName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gaTrackName_not?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_not_contains?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  highlighted?: InputMaybe<Scalars['Boolean']['input']>;
  highlighted_exists?: InputMaybe<Scalars['Boolean']['input']>;
  highlighted_not?: InputMaybe<Scalars['Boolean']['input']>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn_not?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal_not?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mobileAppLeadingLinks_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileMenuToggle?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_contains?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileMenuToggle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  mobileMenuToggle_not?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_not_contains?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noFollow?: InputMaybe<Scalars['Boolean']['input']>;
  noFollow_exists?: InputMaybe<Scalars['Boolean']['input']>;
  noFollow_not?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab_not?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject_exists?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  underline?: InputMaybe<Scalars['String']['input']>;
  underline_contains?: InputMaybe<Scalars['String']['input']>;
  underline_exists?: InputMaybe<Scalars['Boolean']['input']>;
  underline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  underline_not?: InputMaybe<Scalars['String']['input']>;
  underline_not_contains?: InputMaybe<Scalars['String']['input']>;
  underline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_contains?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_not?: InputMaybe<Scalars['String']['input']>;
  url_not_contains?: InputMaybe<Scalars['String']['input']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variant?: InputMaybe<Scalars['String']['input']>;
  variant_contains?: InputMaybe<Scalars['String']['input']>;
  variant_exists?: InputMaybe<Scalars['Boolean']['input']>;
  variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variant_not?: InputMaybe<Scalars['String']['input']>;
  variant_not_contains?: InputMaybe<Scalars['String']['input']>;
  variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfreferFriendLinkMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfreferFriendLinkMultiTypeNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfreferFriendLinkMultiTypeNestedFilter>>>;
  align?: InputMaybe<Scalars['String']['input']>;
  align_contains?: InputMaybe<Scalars['String']['input']>;
  align_exists?: InputMaybe<Scalars['Boolean']['input']>;
  align_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  align_not?: InputMaybe<Scalars['String']['input']>;
  align_not_contains?: InputMaybe<Scalars['String']['input']>;
  align_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ariaLabel?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_contains?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ariaLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ariaLabel_not?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color?: InputMaybe<Scalars['String']['input']>;
  color_contains?: InputMaybe<Scalars['String']['input']>;
  color_exists?: InputMaybe<Scalars['Boolean']['input']>;
  color_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color_not?: InputMaybe<Scalars['String']['input']>;
  color_not_contains?: InputMaybe<Scalars['String']['input']>;
  color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  gaTrackName?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_contains?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  gaTrackName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gaTrackName_not?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_not_contains?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  highlighted?: InputMaybe<Scalars['Boolean']['input']>;
  highlighted_exists?: InputMaybe<Scalars['Boolean']['input']>;
  highlighted_not?: InputMaybe<Scalars['Boolean']['input']>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn_not?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal_not?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mobileAppLeadingLinks_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileMenuToggle?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_contains?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileMenuToggle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  mobileMenuToggle_not?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_not_contains?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noFollow?: InputMaybe<Scalars['Boolean']['input']>;
  noFollow_exists?: InputMaybe<Scalars['Boolean']['input']>;
  noFollow_not?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab_not?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject_exists?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  underline?: InputMaybe<Scalars['String']['input']>;
  underline_contains?: InputMaybe<Scalars['String']['input']>;
  underline_exists?: InputMaybe<Scalars['Boolean']['input']>;
  underline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  underline_not?: InputMaybe<Scalars['String']['input']>;
  underline_not_contains?: InputMaybe<Scalars['String']['input']>;
  underline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_contains?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_not?: InputMaybe<Scalars['String']['input']>;
  url_not_contains?: InputMaybe<Scalars['String']['input']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variant?: InputMaybe<Scalars['String']['input']>;
  variant_contains?: InputMaybe<Scalars['String']['input']>;
  variant_exists?: InputMaybe<Scalars['Boolean']['input']>;
  variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variant_not?: InputMaybe<Scalars['String']['input']>;
  variant_not_contains?: InputMaybe<Scalars['String']['input']>;
  variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfuserDropdownMenuItemsMultiTypeNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<CfuserDropdownMenuItemsMultiTypeNestedFilter>>
  >;
  OR?: InputMaybe<
    Array<InputMaybe<CfuserDropdownMenuItemsMultiTypeNestedFilter>>
  >;
  align?: InputMaybe<Scalars['String']['input']>;
  align_contains?: InputMaybe<Scalars['String']['input']>;
  align_exists?: InputMaybe<Scalars['Boolean']['input']>;
  align_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  align_not?: InputMaybe<Scalars['String']['input']>;
  align_not_contains?: InputMaybe<Scalars['String']['input']>;
  align_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ariaLabel?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_contains?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ariaLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ariaLabel_not?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  ariaLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color?: InputMaybe<Scalars['String']['input']>;
  color_contains?: InputMaybe<Scalars['String']['input']>;
  color_exists?: InputMaybe<Scalars['Boolean']['input']>;
  color_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color_not?: InputMaybe<Scalars['String']['input']>;
  color_not_contains?: InputMaybe<Scalars['String']['input']>;
  color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  gaTrackName?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_contains?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  gaTrackName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gaTrackName_not?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_not_contains?: InputMaybe<Scalars['String']['input']>;
  gaTrackName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  highlighted?: InputMaybe<Scalars['Boolean']['input']>;
  highlighted_exists?: InputMaybe<Scalars['Boolean']['input']>;
  highlighted_not?: InputMaybe<Scalars['Boolean']['input']>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayedWhenLoggedIn_not?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal_not?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mobileAppLeadingLinks_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileMenuToggle?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_contains?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileMenuToggle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  mobileMenuToggle_not?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_not_contains?: InputMaybe<Scalars['String']['input']>;
  mobileMenuToggle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noFollow?: InputMaybe<Scalars['Boolean']['input']>;
  noFollow_exists?: InputMaybe<Scalars['Boolean']['input']>;
  noFollow_not?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewTab_not?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject_exists?: InputMaybe<Scalars['Boolean']['input']>;
  redirectToLegacyProject_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  underline?: InputMaybe<Scalars['String']['input']>;
  underline_contains?: InputMaybe<Scalars['String']['input']>;
  underline_exists?: InputMaybe<Scalars['Boolean']['input']>;
  underline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  underline_not?: InputMaybe<Scalars['String']['input']>;
  underline_not_contains?: InputMaybe<Scalars['String']['input']>;
  underline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_contains?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_not?: InputMaybe<Scalars['String']['input']>;
  url_not_contains?: InputMaybe<Scalars['String']['input']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variant?: InputMaybe<Scalars['String']['input']>;
  variant_contains?: InputMaybe<Scalars['String']['input']>;
  variant_exists?: InputMaybe<Scalars['Boolean']['input']>;
  variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variant_not?: InputMaybe<Scalars['String']['input']>;
  variant_not_contains?: InputMaybe<Scalars['String']['input']>;
  variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AssetFragment = {
  __typename?: 'Asset';
  title?: string | null;
  width?: number | null;
  height?: number | null;
  alt?: string | null;
};

export type CoreImageFragment = {
  __typename?: 'Asset';
  url?: string | null;
  title?: string | null;
  width?: number | null;
  height?: number | null;
  alt?: string | null;
};

export type ImageXsFragment = {
  __typename?: 'Asset';
  url?: string | null;
  title?: string | null;
  width?: number | null;
  height?: number | null;
  alt?: string | null;
};

export type AppBannerFragment = {
  __typename: 'AppBanner';
  name?: string | null;
  bannerDescription?: string | null;
  bannerTitle?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  image?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  appStoreIconLink?: {
    __typename?: 'Link';
    url?: string | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
  playStoreIconLink?: {
    __typename?: 'Link';
    url?: string | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
};

export type AppBannerQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AppBannerQueryQuery = {
  __typename?: 'Query';
  AppBanner?: {
    __typename: 'AppBanner';
    name?: string | null;
    bannerDescription?: string | null;
    bannerTitle?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
    appStoreIconLink?: {
      __typename?: 'Link';
      url?: string | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null;
    playStoreIconLink?: {
      __typename?: 'Link';
      url?: string | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null;
  } | null;
};

export type BackToSchoolFragment = {
  __typename: 'BackToSchool';
  name?: string | null;
  averageHouseholdIncomeLabel?: string | null;
  chooseCurrencyLabel?: string | null;
  totalCostPerChildLabel?: string | null;
  totalCostPerHouseholdLabel?: string | null;
  totalSpendPerChildLabel?: string | null;
  mapSectionHeading?: string | null;
  hasCurrencyChart?: boolean | null;
  itemsListHeading?: string | null;
  incomesHeading?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  mapSectionMessage?: {
    __typename?: 'BackToSchoolMapSectionMessage';
    json: Document;
  } | null;
  mapSectionImage?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  itemsListMessage?: {
    __typename?: 'BackToSchoolItemsListMessage';
    json: Document;
  } | null;
  incomesMessage?: {
    __typename?: 'BackToSchoolIncomesMessage';
    json: Document;
  } | null;
  countriesCollection?: {
    __typename?: 'BackToSchoolCountriesCollection';
    items: Array<{
      __typename?: 'BackToSchoolCountryData';
      name?: string | null;
      country?: string | null;
      flagXPosition?: number | null;
      flagYPosition?: number | null;
      circleRatio?: number | null;
      totalChildCost?: number | null;
      totalHouseholdCost?: number | null;
      childrenRatio?: number | null;
      householdIncome?: number | null;
      currency?: string | null;
      exchangeRate?: number | null;
      currencySymbol?: string | null;
      costs?: Document | null;
      countryFlag?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      link?: {
        __typename?: 'Link';
        label?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
      } | null;
    } | null>;
  } | null;
  categoriesCollection?: {
    __typename?: 'BackToSchoolCategoriesCollection';
    items: Array<{
      __typename?: 'BackToSchoolCostCategory';
      name?: string | null;
      categoryTitle?: string | null;
      categoryHexColour?: string | null;
      categoryKey?: string | null;
      categoryImage?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type BackToSchoolQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type BackToSchoolQueryQuery = {
  __typename?: 'Query';
  BackToSchool?: {
    __typename: 'BackToSchool';
    name?: string | null;
    averageHouseholdIncomeLabel?: string | null;
    chooseCurrencyLabel?: string | null;
    totalCostPerChildLabel?: string | null;
    totalCostPerHouseholdLabel?: string | null;
    totalSpendPerChildLabel?: string | null;
    mapSectionHeading?: string | null;
    hasCurrencyChart?: boolean | null;
    itemsListHeading?: string | null;
    incomesHeading?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    mapSectionMessage?: {
      __typename?: 'BackToSchoolMapSectionMessage';
      json: Document;
    } | null;
    mapSectionImage?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
    itemsListMessage?: {
      __typename?: 'BackToSchoolItemsListMessage';
      json: Document;
    } | null;
    incomesMessage?: {
      __typename?: 'BackToSchoolIncomesMessage';
      json: Document;
    } | null;
    countriesCollection?: {
      __typename?: 'BackToSchoolCountriesCollection';
      items: Array<{
        __typename?: 'BackToSchoolCountryData';
        name?: string | null;
        country?: string | null;
        flagXPosition?: number | null;
        flagYPosition?: number | null;
        circleRatio?: number | null;
        totalChildCost?: number | null;
        totalHouseholdCost?: number | null;
        childrenRatio?: number | null;
        householdIncome?: number | null;
        currency?: string | null;
        exchangeRate?: number | null;
        currencySymbol?: string | null;
        costs?: Document | null;
        countryFlag?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
        link?: {
          __typename?: 'Link';
          label?: string | null;
          url?: string | null;
          isInternal?: boolean | null;
        } | null;
      } | null>;
    } | null;
    categoriesCollection?: {
      __typename?: 'BackToSchoolCategoriesCollection';
      items: Array<{
        __typename?: 'BackToSchoolCostCategory';
        name?: string | null;
        categoryTitle?: string | null;
        categoryHexColour?: string | null;
        categoryKey?: string | null;
        categoryImage?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type BodyTextFragment = {
  __typename: 'BodyText';
  name?: string | null;
  headingAlign?: string | null;
  contentAlign?: string | null;
  listIndent?: number | null;
  paragraphIndent?: number | null;
  isPageSection?: boolean | null;
  sys: { __typename?: 'Sys'; id: string };
  bodyTextHeading?: { __typename?: 'BodyTextHeading'; json: Document } | null;
  bodyTextContent?: {
    __typename?: 'BodyTextContent';
    json: Document;
    links: {
      __typename?: 'BodyTextContentLinks';
      assets: {
        __typename?: 'BodyTextContentAssets';
        block: Array<{
          __typename?: 'Asset';
          contentType?: string | null;
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      };
      entries: {
        __typename?: 'BodyTextContentEntries';
        block: Array<
          | {
              __typename?: 'AccountDuplicationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ActionResult';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReview';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReviews';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchool';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCostCategory';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCountryData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BlogContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexChartFilterOptions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexLineChart';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateAlerts';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ChangePasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContextualPromoBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CorrespondentLocations';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CountryPayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CurrencyPricingConverter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'EmbeddedTableEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ExternalScript';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelp';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelpItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ForgottenPasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HowDoesItWork';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowItWorksStepRelaunch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowSendingWorks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'Image';
              name?: string | null;
              align?: string | null;
              position?: string | null;
              width?: number | null;
              height?: number | null;
              sys: { __typename?: 'Sys'; id: string };
              asset?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'InformationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashRequest';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LoginPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MembersList';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MfaFactors';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MfaVerification';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileStoreData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestConfirmationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPay';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransfer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransferCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OnfidoErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OurContactInformation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethodAlert';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhoneNumberVerificationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PriceComparsion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReferAFriendGreenfieldEnabledCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReferFriendHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Regulator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyNewRequestEmail';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Reviews'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SenderFormPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SendingPartnership';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ShareSocialLink';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SmsVerificationCodePage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SocialProofingModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SolutionsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'StickyBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TagLinks'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              name?: string | null;
              inline?: boolean | null;
              color?: string | null;
              backgroundColor?: string | null;
              align?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColorText';
                json: Document;
              } | null;
            }
          | {
              __typename?: 'VerificationInProgress';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'Video';
              name?: string | null;
              videoId?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              content?: { __typename?: 'VideoContent'; json: Document } | null;
              backgroundImage?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'WeAreRegulated';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        inline: Array<
          | {
              __typename?: 'AccountDuplicationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ActionResult';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReview';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReviews';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchool';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCostCategory';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCountryData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BlogContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexChartFilterOptions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexLineChart';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateAlerts';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ChangePasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContextualPromoBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CorrespondentLocations';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CountryPayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CurrencyPricingConverter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'EmbeddedTableEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ExternalScript';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelp';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelpItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ForgottenPasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HowDoesItWork';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowItWorksStepRelaunch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowSendingWorks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'InformationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashRequest';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LoginPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MembersList';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MfaFactors';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MfaVerification';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileStoreData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestConfirmationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPay';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransfer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransferCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OnfidoErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OurContactInformation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethodAlert';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhoneNumberVerificationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PriceComparsion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReferAFriendGreenfieldEnabledCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReferFriendHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Regulator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyNewRequestEmail';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Reviews'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SenderFormPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SendingPartnership';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ShareSocialLink';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SmsVerificationCodePage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SocialProofingModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SolutionsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'StickyBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TagLinks'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              name?: string | null;
              inline?: boolean | null;
              color?: string | null;
              backgroundColor?: string | null;
              align?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColorText';
                json: Document;
              } | null;
            }
          | {
              __typename?: 'VerificationInProgress';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WeAreRegulated';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        hyperlink: Array<
          | {
              __typename?: 'AccountDuplicationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ActionResult';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReview';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReviews';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchool';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCostCategory';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCountryData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BlogContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexChartFilterOptions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexLineChart';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateAlerts';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ChangePasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContextualPromoBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CorrespondentLocations';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CountryPayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CurrencyPricingConverter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'EmbeddedTableEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ExternalScript';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelp';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelpItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ForgottenPasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HowDoesItWork';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowItWorksStepRelaunch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowSendingWorks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'InformationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashRequest';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LoginPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MembersList';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MfaFactors';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MfaVerification';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileStoreData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestConfirmationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPay';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransfer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransferCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OnfidoErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OurContactInformation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethodAlert';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhoneNumberVerificationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PriceComparsion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReferAFriendGreenfieldEnabledCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReferFriendHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Regulator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyNewRequestEmail';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Reviews'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SenderFormPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SendingPartnership';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ShareSocialLink';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SmsVerificationCodePage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SocialProofingModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SolutionsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'StickyBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TagLinks'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'VerificationInProgress';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WeAreRegulated';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
      };
    };
  } | null;
  bodyTextContentTwo?: {
    __typename?: 'BodyTextContentTwo';
    json: Document;
    links: {
      __typename?: 'BodyTextContentTwoLinks';
      assets: {
        __typename?: 'BodyTextContentTwoAssets';
        block: Array<{
          __typename?: 'Asset';
          contentType?: string | null;
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      };
      entries: {
        __typename?: 'BodyTextContentTwoEntries';
        block: Array<
          | {
              __typename?: 'AccountDuplicationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ActionResult';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReview';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReviews';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchool';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCostCategory';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCountryData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BlogContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexChartFilterOptions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexLineChart';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateAlerts';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ChangePasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContextualPromoBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CorrespondentLocations';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CountryPayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CurrencyPricingConverter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'EmbeddedTableEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ExternalScript';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelp';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelpItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ForgottenPasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HowDoesItWork';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowItWorksStepRelaunch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowSendingWorks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'Image';
              name?: string | null;
              align?: string | null;
              position?: string | null;
              width?: number | null;
              height?: number | null;
              sys: { __typename?: 'Sys'; id: string };
              asset?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'InformationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashRequest';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LoginPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MembersList';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MfaFactors';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MfaVerification';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileStoreData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestConfirmationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPay';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransfer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransferCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OnfidoErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OurContactInformation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethodAlert';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhoneNumberVerificationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PriceComparsion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReferAFriendGreenfieldEnabledCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReferFriendHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Regulator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyNewRequestEmail';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Reviews'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SenderFormPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SendingPartnership';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ShareSocialLink';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SmsVerificationCodePage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SocialProofingModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SolutionsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'StickyBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TagLinks'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              name?: string | null;
              inline?: boolean | null;
              color?: string | null;
              backgroundColor?: string | null;
              align?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColorText';
                json: Document;
              } | null;
            }
          | {
              __typename?: 'VerificationInProgress';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'Video';
              name?: string | null;
              videoId?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              content?: { __typename?: 'VideoContent'; json: Document } | null;
              backgroundImage?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'WeAreRegulated';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        inline: Array<
          | {
              __typename?: 'AccountDuplicationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ActionResult';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReview';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReviews';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchool';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCostCategory';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCountryData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BlogContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexChartFilterOptions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexLineChart';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateAlerts';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ChangePasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContextualPromoBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CorrespondentLocations';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CountryPayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CurrencyPricingConverter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'EmbeddedTableEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ExternalScript';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelp';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelpItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ForgottenPasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HowDoesItWork';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowItWorksStepRelaunch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowSendingWorks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'InformationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashRequest';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LoginPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MembersList';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MfaFactors';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MfaVerification';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileStoreData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestConfirmationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPay';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransfer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransferCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OnfidoErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OurContactInformation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethodAlert';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhoneNumberVerificationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PriceComparsion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReferAFriendGreenfieldEnabledCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReferFriendHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Regulator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyNewRequestEmail';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Reviews'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SenderFormPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SendingPartnership';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ShareSocialLink';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SmsVerificationCodePage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SocialProofingModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SolutionsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'StickyBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TagLinks'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              name?: string | null;
              inline?: boolean | null;
              color?: string | null;
              backgroundColor?: string | null;
              align?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColorText';
                json: Document;
              } | null;
            }
          | {
              __typename?: 'VerificationInProgress';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WeAreRegulated';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        hyperlink: Array<
          | {
              __typename?: 'AccountDuplicationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ActionResult';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReview';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReviews';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchool';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCostCategory';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCountryData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BlogContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexChartFilterOptions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexLineChart';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateAlerts';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ChangePasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContextualPromoBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CorrespondentLocations';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CountryPayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CurrencyPricingConverter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'EmbeddedTableEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ExternalScript';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelp';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelpItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ForgottenPasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HowDoesItWork';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowItWorksStepRelaunch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowSendingWorks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'InformationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashRequest';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LoginPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MembersList';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MfaFactors';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MfaVerification';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileStoreData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestConfirmationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPay';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransfer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransferCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OnfidoErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OurContactInformation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethodAlert';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhoneNumberVerificationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PriceComparsion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReferAFriendGreenfieldEnabledCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReferFriendHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Regulator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyNewRequestEmail';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Reviews'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SenderFormPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SendingPartnership';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ShareSocialLink';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SmsVerificationCodePage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SocialProofingModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SolutionsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'StickyBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TagLinks'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'VerificationInProgress';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WeAreRegulated';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
      };
    };
  } | null;
};

export type BodyTextQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryCode?: InputMaybe<Scalars['String']['input']>;
  receiveCountryCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type BodyTextQueryQuery = {
  __typename?: 'Query';
  BodyText?: {
    __typename?: 'BodyTextCollection';
    items: Array<{
      __typename: 'BodyText';
      name?: string | null;
      headingAlign?: string | null;
      contentAlign?: string | null;
      listIndent?: number | null;
      paragraphIndent?: number | null;
      isPageSection?: boolean | null;
      sys: { __typename?: 'Sys'; id: string };
      bodyTextHeading?: {
        __typename?: 'BodyTextHeading';
        json: Document;
      } | null;
      bodyTextContent?: {
        __typename?: 'BodyTextContent';
        json: Document;
        links: {
          __typename?: 'BodyTextContentLinks';
          assets: {
            __typename?: 'BodyTextContentAssets';
            block: Array<{
              __typename?: 'Asset';
              contentType?: string | null;
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            } | null>;
          };
          entries: {
            __typename?: 'BodyTextContentEntries';
            block: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  name?: string | null;
                  align?: string | null;
                  position?: string | null;
                  width?: number | null;
                  height?: number | null;
                  sys: { __typename?: 'Sys'; id: string };
                  asset?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename: 'Video';
                  name?: string | null;
                  videoId?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  content?: {
                    __typename?: 'VideoContent';
                    json: Document;
                  } | null;
                  backgroundImage?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            inline: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            hyperlink: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
      bodyTextContentTwo?: {
        __typename?: 'BodyTextContentTwo';
        json: Document;
        links: {
          __typename?: 'BodyTextContentTwoLinks';
          assets: {
            __typename?: 'BodyTextContentTwoAssets';
            block: Array<{
              __typename?: 'Asset';
              contentType?: string | null;
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            } | null>;
          };
          entries: {
            __typename?: 'BodyTextContentTwoEntries';
            block: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  name?: string | null;
                  align?: string | null;
                  position?: string | null;
                  width?: number | null;
                  height?: number | null;
                  sys: { __typename?: 'Sys'; id: string };
                  asset?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename: 'Video';
                  name?: string | null;
                  videoId?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  content?: {
                    __typename?: 'VideoContent';
                    json: Document;
                  } | null;
                  backgroundImage?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            inline: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            hyperlink: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
    } | null>;
  } | null;
};

export type CalculatorPayoutMethodFragment = {
  __typename?: 'PayoutMethod';
  name?: string | null;
  description?: string | null;
  payoutMethodId?: string | null;
  transferTimeTooltip?: string | null;
};

export type CalculatorFragment = {
  __typename?: 'Calculator';
  name?: string | null;
  sendLabel?: string | null;
  receiveLabel?: string | null;
  isLite?: boolean | null;
  payoutMethodsLabel?: string | null;
  countriesSearchPlaceholder?: string | null;
  unavailableCorridorErrorMessage?: string | null;
  airtimeTopupQuoteAvailableInApp?: string | null;
  exchangeRateLabel?: string | null;
  exchangeRatePromoLabel?: string | null;
  receiverPayoutMethodUnavailableMessage?: string | null;
  senderPayoutMethodUnavailableMessage?: string | null;
  generalErrorMessage?: string | null;
  partnersLabel?: string | null;
  partnersListDefaultValue?: string | null;
  partnerSelectErrorMessage?: string | null;
  phoneValidatorLabel?: string | null;
  phoneValidatorPlaceholder?: string | null;
  phoneInputInfoMoreInfoLabel?: string | null;
  phoneInputInfoPopupText?: string | null;
  phoneNumberInputErrorMessage?: string | null;
  phoneNumberInvalidErrorMessage?: string | null;
  topUpAmountsLabel?: string | null;
  topUpAmountsTitle?: string | null;
  topUpAmountsPlaceholder?: string | null;
  topUpAmountsSelectErrorMessage?: string | null;
  topUpAmountsSelectRetrievalErrorMessage?: string | null;
  feeLabel?: string | null;
  instantDiscountLabel?: string | null;
  transferTimeLabel?: string | null;
  totalToPayLabel?: string | null;
  blendedRateLabel?: string | null;
  phReceiveBankTransferMessage?: string | null;
  version?: string | null;
  loginLink?: { __typename?: 'Link'; url?: string | null } | null;
  signUpLink?: { __typename?: 'Link'; url?: string | null } | null;
  contentfulPayoutMethodsList?: {
    __typename?: 'CalculatorPayoutMethodsListCollection';
    items: Array<{
      __typename?: 'PayoutMethod';
      name?: string | null;
      description?: string | null;
      payoutMethodId?: string | null;
      transferTimeTooltip?: string | null;
    } | null>;
  } | null;
  promotionalTermsLink?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
  ctaLinks?: {
    __typename?: 'CalculatorCtaLinksCollection';
    items: Array<{
      __typename?: 'Link';
      name?: string | null;
      url?: string | null;
      isInternal?: boolean | null;
      label?: string | null;
      ariaLabel?: string | null;
      openInNewTab?: boolean | null;
      gaTrackName?: string | null;
      isDisplayedWhenLoggedIn?: boolean | null;
      color?: string | null;
      variant?: string | null;
      underline?: string | null;
      align?: string | null;
      noFollow?: boolean | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type CardFragment = {
  __typename: 'Card';
  name?: string | null;
  payoutMethodId?: string | null;
  header?: string | null;
  description?: string | null;
  backgroundColor?: string | null;
  sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null };
  backgroundImage?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  link?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
};

export type CardsFragment = {
  __typename: 'Cards';
  name?: string | null;
  header?: string | null;
  description?: string | null;
  carouselText?: string | null;
  paymentText?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  cardsCollection?: {
    __typename?: 'CardsCardsCollection';
    items: Array<{
      __typename: 'Card';
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
  paymentIconsCollection?: {
    __typename?: 'AssetCollection';
    items: Array<{
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null>;
  } | null;
};

export type CardsQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryCode?: InputMaybe<Scalars['String']['input']>;
  receiveCountryCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type CardsQueryQuery = {
  __typename?: 'Query';
  Cards?: {
    __typename?: 'CardsCollection';
    items: Array<{
      __typename: 'Cards';
      name?: string | null;
      header?: string | null;
      description?: string | null;
      carouselText?: string | null;
      paymentText?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      cardsCollection?: {
        __typename?: 'CardsCardsCollection';
        items: Array<{
          __typename: 'Card';
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      } | null;
      paymentIconsCollection?: {
        __typename?: 'AssetCollection';
        items: Array<{
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type CardQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryCode?: InputMaybe<Scalars['String']['input']>;
  receiveCountryCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type CardQueryQuery = {
  __typename?: 'Query';
  Card?: {
    __typename?: 'CardCollection';
    items: Array<{
      __typename: 'Card';
      name?: string | null;
      payoutMethodId?: string | null;
      header?: string | null;
      description?: string | null;
      backgroundColor?: string | null;
      sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null };
      backgroundImage?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      link?: {
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type ComparisonTableCellFragment = {
  __typename?: 'ComparisonTableCell';
  name?: string | null;
  amount?: string | null;
  fee?: string | null;
  receiveCountryList?: Array<string | null> | null;
};

export type ComparisonTableRowFragment = {
  __typename?: 'ComparisonTableRow';
  name?: string | null;
  image?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  ATP?: {
    __typename?: 'ComparisonTableRowATPCollection';
    items: Array<{
      __typename: 'ComparisonTableCell';
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
  CSH?: {
    __typename?: 'ComparisonTableRowCSHCollection';
    items: Array<{
      __typename: 'ComparisonTableCell';
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
  BNK?: {
    __typename?: 'ComparisonTableRowBNKCollection';
    items: Array<{
      __typename: 'ComparisonTableCell';
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
  MOB?: {
    __typename?: 'ComparisonTableRowMOBCollection';
    items: Array<{
      __typename: 'ComparisonTableCell';
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
};

export type ComparisonTableFragment = {
  __typename: 'ComparisonTable';
  name?: string | null;
  providersLabel?: string | null;
  caption?: string | null;
  footer?: string | null;
  sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null };
  content?: { __typename?: 'ComparisonTableContent'; json: Document } | null;
  rowsCollection?: {
    __typename?: 'ComparisonTableRowsCollection';
    items: Array<{
      __typename: 'ComparisonTableRow';
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
};

export type ComparisonTableQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryCode?: InputMaybe<Scalars['String']['input']>;
  receiveCountryCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type ComparisonTableQueryQuery = {
  __typename?: 'Query';
  ComparisonTable?: {
    __typename?: 'ComparisonTableCollection';
    items: Array<{
      __typename: 'ComparisonTable';
      name?: string | null;
      providersLabel?: string | null;
      caption?: string | null;
      footer?: string | null;
      sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null };
      content?: {
        __typename?: 'ComparisonTableContent';
        json: Document;
      } | null;
      rowsCollection?: {
        __typename?: 'ComparisonTableRowsCollection';
        items: Array<{
          __typename: 'ComparisonTableRow';
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type ComparisonTableRowQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryCode?: InputMaybe<Scalars['String']['input']>;
  receiveCountryCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type ComparisonTableRowQueryQuery = {
  __typename?: 'Query';
  ComparisonTableRow?: {
    __typename?: 'ComparisonTableRowCollection';
    items: Array<{
      __typename?: 'ComparisonTableRow';
      name?: string | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      ATP?: {
        __typename?: 'ComparisonTableRowATPCollection';
        items: Array<{
          __typename: 'ComparisonTableCell';
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      } | null;
      CSH?: {
        __typename?: 'ComparisonTableRowCSHCollection';
        items: Array<{
          __typename: 'ComparisonTableCell';
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      } | null;
      BNK?: {
        __typename?: 'ComparisonTableRowBNKCollection';
        items: Array<{
          __typename: 'ComparisonTableCell';
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      } | null;
      MOB?: {
        __typename?: 'ComparisonTableRowMOBCollection';
        items: Array<{
          __typename: 'ComparisonTableCell';
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type ComparisonTableCellQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryCode?: InputMaybe<Scalars['String']['input']>;
  receiveCountryCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type ComparisonTableCellQueryQuery = {
  __typename?: 'Query';
  ComparisonTableCell?: {
    __typename?: 'ComparisonTableCellCollection';
    items: Array<{
      __typename?: 'ComparisonTableCell';
      name?: string | null;
      amount?: string | null;
      fee?: string | null;
      receiveCountryList?: Array<string | null> | null;
    } | null>;
  } | null;
};

export type CorrespondentLocationsFragment = {
  __typename: 'CorrespondentLocations';
  title?: string | null;
  name?: string | null;
  ctaButton?: string | null;
  locationDetailsNames?: Array<string | null> | null;
  locationDetailsIds?: Array<string | null> | null;
  sys: { __typename?: 'Sys'; id: string };
};

export type CorrespondentLocationsQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryCode?: InputMaybe<Scalars['String']['input']>;
  receiveCountryCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type CorrespondentLocationsQueryQuery = {
  __typename?: 'Query';
  CorrespondentLocations?: {
    __typename?: 'CorrespondentLocationsCollection';
    items: Array<{
      __typename: 'CorrespondentLocations';
      title?: string | null;
      name?: string | null;
      ctaButton?: string | null;
      locationDetailsNames?: Array<string | null> | null;
      locationDetailsIds?: Array<string | null> | null;
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
};

export type CorrespondentsFragment = {
  __typename: 'Correspondents';
  name?: string | null;
  title?: string | null;
  subtitle?: string | null;
  sys: { __typename?: 'Sys'; id: string };
};

export type CorrespondentsQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryCode?: InputMaybe<Scalars['String']['input']>;
  receiveCountryCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type CorrespondentsQueryQuery = {
  __typename?: 'Query';
  Correspondents?: {
    __typename?: 'CorrespondentsCollection';
    items: Array<{
      __typename: 'Correspondents';
      name?: string | null;
      title?: string | null;
      subtitle?: string | null;
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
};

export type CexCurrencyFragment = {
  __typename?: 'CexCurrency';
  corridorGridTitle?: string | null;
  name?: string | null;
  countryName?: string | null;
  currencyCode?: string | null;
  countryCode?: string | null;
  currencyName?: string | null;
  pageSlug?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  description?: {
    __typename?: 'CexCurrencyDescription';
    json: Document;
    links: {
      __typename?: 'CexCurrencyDescriptionLinks';
      assets: {
        __typename?: 'CexCurrencyDescriptionAssets';
        block: Array<{
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null>;
      };
      entries: {
        __typename?: 'CexCurrencyDescriptionEntries';
        block: Array<
          | {
              __typename?: 'AccountDuplicationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ActionResult';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReview';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReviews';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchool';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCostCategory';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCountryData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BlogContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexChartFilterOptions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexLineChart';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateAlerts';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ChangePasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContextualPromoBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CorrespondentLocations';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CountryPayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CurrencyPricingConverter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'EmbeddedTableEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ExternalScript';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelp';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelpItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ForgottenPasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HowDoesItWork';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowItWorksStepRelaunch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowSendingWorks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'Image';
              name?: string | null;
              align?: string | null;
              position?: string | null;
              width?: number | null;
              height?: number | null;
              sys: { __typename?: 'Sys'; id: string };
              asset?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'InformationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashRequest';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LoginPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MembersList';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MfaFactors';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MfaVerification';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileStoreData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestConfirmationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPay';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransfer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransferCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OnfidoErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OurContactInformation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethodAlert';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhoneNumberVerificationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PriceComparsion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReferAFriendGreenfieldEnabledCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReferFriendHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Regulator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyNewRequestEmail';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Reviews'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SenderFormPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SendingPartnership';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ShareSocialLink';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SmsVerificationCodePage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SocialProofingModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SolutionsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'StickyBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TagLinks'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              name?: string | null;
              inline?: boolean | null;
              color?: string | null;
              backgroundColor?: string | null;
              align?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColorText';
                json: Document;
              } | null;
            }
          | {
              __typename?: 'VerificationInProgress';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'Video';
              name?: string | null;
              videoId?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              content?: { __typename?: 'VideoContent'; json: Document } | null;
              backgroundImage?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'WeAreRegulated';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        inline: Array<
          | {
              __typename?: 'AccountDuplicationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ActionResult';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReview';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReviews';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchool';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCostCategory';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCountryData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BlogContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexChartFilterOptions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexLineChart';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateAlerts';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ChangePasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContextualPromoBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CorrespondentLocations';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CountryPayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CurrencyPricingConverter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'EmbeddedTableEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ExternalScript';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelp';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelpItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ForgottenPasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HowDoesItWork';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowItWorksStepRelaunch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowSendingWorks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'InformationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashRequest';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LoginPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MembersList';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MfaFactors';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MfaVerification';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileStoreData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestConfirmationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPay';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransfer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransferCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OnfidoErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OurContactInformation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethodAlert';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhoneNumberVerificationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PriceComparsion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReferAFriendGreenfieldEnabledCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReferFriendHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Regulator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyNewRequestEmail';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Reviews'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SenderFormPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SendingPartnership';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ShareSocialLink';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SmsVerificationCodePage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SocialProofingModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SolutionsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'StickyBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TagLinks'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              name?: string | null;
              inline?: boolean | null;
              color?: string | null;
              backgroundColor?: string | null;
              align?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColorText';
                json: Document;
              } | null;
            }
          | {
              __typename?: 'VerificationInProgress';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WeAreRegulated';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        hyperlink: Array<
          | {
              __typename?: 'AccountDuplicationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ActionResult';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReview';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReviews';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchool';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCostCategory';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCountryData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BlogContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexChartFilterOptions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexLineChart';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateAlerts';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ChangePasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContextualPromoBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CorrespondentLocations';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CountryPayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CurrencyPricingConverter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'EmbeddedTableEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ExternalScript';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelp';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelpItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ForgottenPasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HowDoesItWork';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowItWorksStepRelaunch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowSendingWorks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'InformationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashRequest';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LoginPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MembersList';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MfaFactors';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MfaVerification';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileStoreData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestConfirmationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPay';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransfer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransferCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OnfidoErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OurContactInformation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethodAlert';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhoneNumberVerificationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PriceComparsion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReferAFriendGreenfieldEnabledCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReferFriendHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Regulator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyNewRequestEmail';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Reviews'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SenderFormPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SendingPartnership';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ShareSocialLink';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SmsVerificationCodePage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SocialProofingModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SolutionsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'StickyBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TagLinks'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'VerificationInProgress';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WeAreRegulated';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
      };
    };
  } | null;
  faqContent?: {
    __typename?: 'CexCurrencyFaqContent';
    json: Document;
    links: {
      __typename?: 'CexCurrencyFaqContentLinks';
      assets: {
        __typename?: 'CexCurrencyFaqContentAssets';
        block: Array<{
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null>;
      };
      entries: {
        __typename?: 'CexCurrencyFaqContentEntries';
        block: Array<
          | {
              __typename?: 'AccountDuplicationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ActionResult';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReview';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReviews';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchool';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCostCategory';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCountryData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BlogContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexChartFilterOptions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexLineChart';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateAlerts';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ChangePasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContextualPromoBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CorrespondentLocations';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CountryPayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CurrencyPricingConverter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'EmbeddedTableEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ExternalScript';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelp';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelpItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ForgottenPasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HowDoesItWork';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowItWorksStepRelaunch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowSendingWorks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'Image';
              name?: string | null;
              align?: string | null;
              position?: string | null;
              width?: number | null;
              height?: number | null;
              sys: { __typename?: 'Sys'; id: string };
              asset?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'InformationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashRequest';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LoginPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MembersList';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MfaFactors';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MfaVerification';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileStoreData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestConfirmationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPay';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransfer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransferCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OnfidoErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OurContactInformation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethodAlert';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhoneNumberVerificationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PriceComparsion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReferAFriendGreenfieldEnabledCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReferFriendHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Regulator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyNewRequestEmail';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Reviews'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SenderFormPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SendingPartnership';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ShareSocialLink';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SmsVerificationCodePage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SocialProofingModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SolutionsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'StickyBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TagLinks'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              name?: string | null;
              inline?: boolean | null;
              color?: string | null;
              backgroundColor?: string | null;
              align?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColorText';
                json: Document;
              } | null;
            }
          | {
              __typename?: 'VerificationInProgress';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'Video';
              name?: string | null;
              videoId?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              content?: { __typename?: 'VideoContent'; json: Document } | null;
              backgroundImage?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'WeAreRegulated';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        inline: Array<
          | {
              __typename?: 'AccountDuplicationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ActionResult';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReview';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReviews';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchool';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCostCategory';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCountryData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BlogContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexChartFilterOptions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexLineChart';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateAlerts';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ChangePasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContextualPromoBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CorrespondentLocations';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CountryPayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CurrencyPricingConverter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'EmbeddedTableEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ExternalScript';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelp';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelpItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ForgottenPasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HowDoesItWork';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowItWorksStepRelaunch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowSendingWorks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'InformationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashRequest';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LoginPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MembersList';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MfaFactors';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MfaVerification';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileStoreData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestConfirmationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPay';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransfer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransferCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OnfidoErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OurContactInformation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethodAlert';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhoneNumberVerificationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PriceComparsion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReferAFriendGreenfieldEnabledCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReferFriendHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Regulator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyNewRequestEmail';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Reviews'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SenderFormPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SendingPartnership';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ShareSocialLink';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SmsVerificationCodePage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SocialProofingModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SolutionsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'StickyBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TagLinks'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              name?: string | null;
              inline?: boolean | null;
              color?: string | null;
              backgroundColor?: string | null;
              align?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColorText';
                json: Document;
              } | null;
            }
          | {
              __typename?: 'VerificationInProgress';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WeAreRegulated';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        hyperlink: Array<
          | {
              __typename?: 'AccountDuplicationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ActionResult';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReview';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReviews';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchool';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCostCategory';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCountryData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BlogContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexChartFilterOptions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexLineChart';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateAlerts';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ChangePasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContextualPromoBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CorrespondentLocations';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CountryPayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CurrencyPricingConverter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'EmbeddedTableEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ExternalScript';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelp';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelpItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ForgottenPasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HowDoesItWork';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowItWorksStepRelaunch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowSendingWorks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'InformationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashRequest';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LoginPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MembersList';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MfaFactors';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MfaVerification';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileStoreData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestConfirmationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPay';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransfer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransferCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OnfidoErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OurContactInformation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethodAlert';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhoneNumberVerificationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PriceComparsion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReferAFriendGreenfieldEnabledCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReferFriendHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Regulator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyNewRequestEmail';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Reviews'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SenderFormPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SendingPartnership';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ShareSocialLink';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SmsVerificationCodePage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SocialProofingModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SolutionsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'StickyBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TagLinks'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'VerificationInProgress';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WeAreRegulated';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
      };
    };
  } | null;
  topCurrencyPairs?: {
    __typename?: 'CexCurrencyTopCurrencyPairsCollection';
    items: Array<{
      __typename?: 'CexCurrency';
      name?: string | null;
      pageSlug?: string | null;
      countryCode?: string | null;
      countryName?: string | null;
      currencyCode?: string | null;
      currencyName?: string | null;
      description?: {
        __typename?: 'CexCurrencyDescription';
        json: Document;
        links: {
          __typename?: 'CexCurrencyDescriptionLinks';
          assets: {
            __typename?: 'CexCurrencyDescriptionAssets';
            block: Array<{
              __typename?: 'Asset';
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
            } | null>;
          };
          entries: {
            __typename?: 'CexCurrencyDescriptionEntries';
            block: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  name?: string | null;
                  align?: string | null;
                  position?: string | null;
                  width?: number | null;
                  height?: number | null;
                  sys: { __typename?: 'Sys'; id: string };
                  asset?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename: 'Video';
                  name?: string | null;
                  videoId?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  content?: {
                    __typename?: 'VideoContent';
                    json: Document;
                  } | null;
                  backgroundImage?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            inline: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            hyperlink: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
    } | null>;
  } | null;
};

export type CexCurrencyQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CexCurrencyQueryQuery = {
  __typename?: 'Query';
  CexCurrency?: {
    __typename?: 'CexCurrency';
    corridorGridTitle?: string | null;
    name?: string | null;
    countryName?: string | null;
    currencyCode?: string | null;
    countryCode?: string | null;
    currencyName?: string | null;
    pageSlug?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    description?: {
      __typename?: 'CexCurrencyDescription';
      json: Document;
      links: {
        __typename?: 'CexCurrencyDescriptionLinks';
        assets: {
          __typename?: 'CexCurrencyDescriptionAssets';
          block: Array<{
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null>;
        };
        entries: {
          __typename?: 'CexCurrencyDescriptionEntries';
          block: Array<
            | {
                __typename?: 'AccountDuplicationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ActionResult';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReview';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchool';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCostCategory';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCountryData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BetterWayTop';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BlogContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BodyText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Calculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'CexChartFilterOptions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrency';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrencyCorridor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexLineChart';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateAlerts';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexSearch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ChangePasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableCell';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableRow';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ContextualPromoBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CorrespondentLocations';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondents';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Country';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CountryPayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CurrencyPricingConverter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'EmbeddedTableEntry';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ExternalScript';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqHero';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqPopularQuestions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelp';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelpItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModuleItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FeatureFlag';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'FooterNavigationGroup';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FooterNavigationGroupExtras';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ForgottenPasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FullWidthBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'GenericErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HeroSlot';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowDoesItWork';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowItWorksStepRelaunch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowSendingWorks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'IconWithText';
                name?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                } | null;
                icon?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'Image';
                name?: string | null;
                align?: string | null;
                position?: string | null;
                width?: number | null;
                height?: number | null;
                sys: { __typename?: 'Sys'; id: string };
                asset?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'InformationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashRequest';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutFooter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LegalMessaging';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                sys: { __typename?: 'Sys'; id: string };
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'ListOfCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LoginPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'MembersList';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Messages';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaFactors';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaVerification';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileStoreData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestConfirmationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPay';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransfer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransferCard';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OnfidoErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OurContactInformation';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PageSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethodAlert';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PhoneNumberVerificationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PopularCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PriceComparsion';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PricingCalculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoApplicability';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoMessage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBannerDisclaimer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Redirect';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferFriendHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RegionCode';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Regulator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyNewRequestEmail';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Reviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReviewsTimeDictionary';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RobotsConfiguration';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'SendToCountryModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SenderFormPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SendingPartnership';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoContactPoint';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoOrganizationStructuredData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoSameAs';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ShareSocialLink';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Sitemap';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SmsVerificationCodePage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SocialProofingModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SolutionsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'StepByStep';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'StickyBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TagLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextMeTheAppModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              }
            | {
                __typename?: 'VerificationInProgress';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename: 'Video';
                name?: string | null;
                videoId?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                content?: {
                  __typename?: 'VideoContent';
                  json: Document;
                } | null;
                backgroundImage?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'WeAreRegulated';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WebForm';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChoose';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChooseReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | null
          >;
          inline: Array<
            | {
                __typename?: 'AccountDuplicationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ActionResult';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReview';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchool';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCostCategory';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCountryData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BetterWayTop';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BlogContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BodyText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Calculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'CexChartFilterOptions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrency';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrencyCorridor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexLineChart';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateAlerts';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexSearch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ChangePasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableCell';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableRow';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ContextualPromoBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CorrespondentLocations';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondents';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Country';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CountryPayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CurrencyPricingConverter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'EmbeddedTableEntry';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ExternalScript';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqHero';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqPopularQuestions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelp';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelpItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModuleItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FeatureFlag';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'FooterNavigationGroup';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FooterNavigationGroupExtras';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ForgottenPasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FullWidthBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'GenericErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HeroSlot';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowDoesItWork';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowItWorksStepRelaunch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowSendingWorks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'IconWithText';
                name?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                } | null;
                icon?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'InformationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashRequest';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutFooter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LegalMessaging';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                sys: { __typename?: 'Sys'; id: string };
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'ListOfCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LoginPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'MembersList';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Messages';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaFactors';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaVerification';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileStoreData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestConfirmationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPay';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransfer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransferCard';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OnfidoErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OurContactInformation';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PageSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethodAlert';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PhoneNumberVerificationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PopularCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PriceComparsion';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PricingCalculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoApplicability';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoMessage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBannerDisclaimer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Redirect';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferFriendHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RegionCode';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Regulator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyNewRequestEmail';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Reviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReviewsTimeDictionary';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RobotsConfiguration';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'SendToCountryModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SenderFormPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SendingPartnership';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoContactPoint';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoOrganizationStructuredData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoSameAs';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ShareSocialLink';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Sitemap';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SmsVerificationCodePage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SocialProofingModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SolutionsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'StepByStep';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'StickyBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TagLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextMeTheAppModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              }
            | {
                __typename?: 'VerificationInProgress';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'WeAreRegulated';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WebForm';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChoose';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChooseReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | null
          >;
          hyperlink: Array<
            | {
                __typename?: 'AccountDuplicationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ActionResult';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReview';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchool';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCostCategory';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCountryData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BetterWayTop';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BlogContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BodyText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Calculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'CexChartFilterOptions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrency';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrencyCorridor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexLineChart';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateAlerts';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexSearch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ChangePasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableCell';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableRow';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ContextualPromoBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CorrespondentLocations';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondents';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Country';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CountryPayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CurrencyPricingConverter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'EmbeddedTableEntry';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ExternalScript';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqHero';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqPopularQuestions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelp';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelpItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModuleItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FeatureFlag';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'FooterNavigationGroup';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FooterNavigationGroupExtras';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ForgottenPasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FullWidthBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'GenericErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HeroSlot';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowDoesItWork';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowItWorksStepRelaunch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowSendingWorks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'IconWithText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'InformationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashRequest';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutFooter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LegalMessaging';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                sys: { __typename?: 'Sys'; id: string };
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'ListOfCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LoginPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'MembersList';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Messages';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaFactors';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaVerification';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileStoreData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestConfirmationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPay';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransfer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransferCard';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OnfidoErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OurContactInformation';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PageSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethodAlert';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PhoneNumberVerificationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PopularCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PriceComparsion';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PricingCalculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoApplicability';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoMessage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBannerDisclaimer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Redirect';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferFriendHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RegionCode';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Regulator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyNewRequestEmail';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Reviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReviewsTimeDictionary';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RobotsConfiguration';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'SendToCountryModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SenderFormPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SendingPartnership';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoContactPoint';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoOrganizationStructuredData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoSameAs';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ShareSocialLink';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Sitemap';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SmsVerificationCodePage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SocialProofingModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SolutionsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'StepByStep';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'StickyBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TagLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextMeTheAppModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextWithColor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'VerificationInProgress';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'WeAreRegulated';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WebForm';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChoose';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChooseReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | null
          >;
        };
      };
    } | null;
    faqContent?: {
      __typename?: 'CexCurrencyFaqContent';
      json: Document;
      links: {
        __typename?: 'CexCurrencyFaqContentLinks';
        assets: {
          __typename?: 'CexCurrencyFaqContentAssets';
          block: Array<{
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null>;
        };
        entries: {
          __typename?: 'CexCurrencyFaqContentEntries';
          block: Array<
            | {
                __typename?: 'AccountDuplicationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ActionResult';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReview';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchool';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCostCategory';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCountryData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BetterWayTop';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BlogContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BodyText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Calculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'CexChartFilterOptions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrency';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrencyCorridor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexLineChart';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateAlerts';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexSearch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ChangePasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableCell';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableRow';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ContextualPromoBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CorrespondentLocations';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondents';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Country';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CountryPayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CurrencyPricingConverter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'EmbeddedTableEntry';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ExternalScript';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqHero';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqPopularQuestions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelp';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelpItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModuleItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FeatureFlag';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'FooterNavigationGroup';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FooterNavigationGroupExtras';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ForgottenPasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FullWidthBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'GenericErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HeroSlot';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowDoesItWork';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowItWorksStepRelaunch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowSendingWorks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'IconWithText';
                name?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                } | null;
                icon?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'Image';
                name?: string | null;
                align?: string | null;
                position?: string | null;
                width?: number | null;
                height?: number | null;
                sys: { __typename?: 'Sys'; id: string };
                asset?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'InformationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashRequest';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutFooter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LegalMessaging';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                sys: { __typename?: 'Sys'; id: string };
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'ListOfCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LoginPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'MembersList';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Messages';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaFactors';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaVerification';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileStoreData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestConfirmationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPay';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransfer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransferCard';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OnfidoErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OurContactInformation';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PageSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethodAlert';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PhoneNumberVerificationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PopularCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PriceComparsion';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PricingCalculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoApplicability';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoMessage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBannerDisclaimer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Redirect';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferFriendHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RegionCode';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Regulator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyNewRequestEmail';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Reviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReviewsTimeDictionary';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RobotsConfiguration';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'SendToCountryModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SenderFormPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SendingPartnership';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoContactPoint';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoOrganizationStructuredData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoSameAs';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ShareSocialLink';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Sitemap';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SmsVerificationCodePage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SocialProofingModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SolutionsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'StepByStep';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'StickyBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TagLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextMeTheAppModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              }
            | {
                __typename?: 'VerificationInProgress';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename: 'Video';
                name?: string | null;
                videoId?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                content?: {
                  __typename?: 'VideoContent';
                  json: Document;
                } | null;
                backgroundImage?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'WeAreRegulated';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WebForm';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChoose';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChooseReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | null
          >;
          inline: Array<
            | {
                __typename?: 'AccountDuplicationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ActionResult';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReview';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchool';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCostCategory';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCountryData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BetterWayTop';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BlogContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BodyText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Calculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'CexChartFilterOptions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrency';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrencyCorridor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexLineChart';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateAlerts';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexSearch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ChangePasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableCell';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableRow';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ContextualPromoBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CorrespondentLocations';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondents';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Country';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CountryPayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CurrencyPricingConverter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'EmbeddedTableEntry';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ExternalScript';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqHero';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqPopularQuestions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelp';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelpItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModuleItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FeatureFlag';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'FooterNavigationGroup';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FooterNavigationGroupExtras';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ForgottenPasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FullWidthBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'GenericErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HeroSlot';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowDoesItWork';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowItWorksStepRelaunch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowSendingWorks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'IconWithText';
                name?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                } | null;
                icon?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'InformationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashRequest';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutFooter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LegalMessaging';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                sys: { __typename?: 'Sys'; id: string };
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'ListOfCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LoginPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'MembersList';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Messages';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaFactors';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaVerification';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileStoreData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestConfirmationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPay';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransfer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransferCard';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OnfidoErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OurContactInformation';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PageSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethodAlert';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PhoneNumberVerificationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PopularCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PriceComparsion';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PricingCalculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoApplicability';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoMessage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBannerDisclaimer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Redirect';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferFriendHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RegionCode';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Regulator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyNewRequestEmail';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Reviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReviewsTimeDictionary';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RobotsConfiguration';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'SendToCountryModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SenderFormPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SendingPartnership';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoContactPoint';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoOrganizationStructuredData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoSameAs';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ShareSocialLink';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Sitemap';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SmsVerificationCodePage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SocialProofingModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SolutionsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'StepByStep';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'StickyBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TagLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextMeTheAppModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              }
            | {
                __typename?: 'VerificationInProgress';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'WeAreRegulated';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WebForm';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChoose';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChooseReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | null
          >;
          hyperlink: Array<
            | {
                __typename?: 'AccountDuplicationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ActionResult';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReview';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchool';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCostCategory';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCountryData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BetterWayTop';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BlogContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BodyText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Calculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'CexChartFilterOptions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrency';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrencyCorridor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexLineChart';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateAlerts';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexSearch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ChangePasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableCell';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableRow';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ContextualPromoBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CorrespondentLocations';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondents';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Country';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CountryPayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CurrencyPricingConverter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'EmbeddedTableEntry';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ExternalScript';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqHero';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqPopularQuestions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelp';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelpItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModuleItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FeatureFlag';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'FooterNavigationGroup';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FooterNavigationGroupExtras';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ForgottenPasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FullWidthBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'GenericErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HeroSlot';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowDoesItWork';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowItWorksStepRelaunch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowSendingWorks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'IconWithText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'InformationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashRequest';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutFooter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LegalMessaging';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                sys: { __typename?: 'Sys'; id: string };
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'ListOfCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LoginPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'MembersList';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Messages';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaFactors';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaVerification';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileStoreData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestConfirmationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPay';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransfer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransferCard';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OnfidoErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OurContactInformation';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PageSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethodAlert';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PhoneNumberVerificationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PopularCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PriceComparsion';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PricingCalculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoApplicability';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoMessage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBannerDisclaimer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Redirect';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferFriendHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RegionCode';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Regulator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyNewRequestEmail';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Reviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReviewsTimeDictionary';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RobotsConfiguration';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'SendToCountryModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SenderFormPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SendingPartnership';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoContactPoint';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoOrganizationStructuredData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoSameAs';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ShareSocialLink';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Sitemap';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SmsVerificationCodePage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SocialProofingModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SolutionsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'StepByStep';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'StickyBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TagLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextMeTheAppModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextWithColor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'VerificationInProgress';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'WeAreRegulated';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WebForm';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChoose';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChooseReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | null
          >;
        };
      };
    } | null;
    topCurrencyPairs?: {
      __typename?: 'CexCurrencyTopCurrencyPairsCollection';
      items: Array<{
        __typename?: 'CexCurrency';
        name?: string | null;
        pageSlug?: string | null;
        countryCode?: string | null;
        countryName?: string | null;
        currencyCode?: string | null;
        currencyName?: string | null;
        description?: {
          __typename?: 'CexCurrencyDescription';
          json: Document;
          links: {
            __typename?: 'CexCurrencyDescriptionLinks';
            assets: {
              __typename?: 'CexCurrencyDescriptionAssets';
              block: Array<{
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null>;
            };
            entries: {
              __typename?: 'CexCurrencyDescriptionEntries';
              block: Array<
                | {
                    __typename?: 'AccountDuplicationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ActionResult';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReview';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchool';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCostCategory';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCountryData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BlogContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexChartFilterOptions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexLineChart';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateAlerts';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ChangePasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContextualPromoBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CorrespondentLocations';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountryPayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CurrencyPricingConverter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'EmbeddedTableEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExternalScript';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelp';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelpItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ForgottenPasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowDoesItWork';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowItWorksStepRelaunch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowSendingWorks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    name?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    } | null;
                    icon?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'Image';
                    name?: string | null;
                    align?: string | null;
                    position?: string | null;
                    width?: number | null;
                    height?: number | null;
                    sys: { __typename?: 'Sys'; id: string };
                    asset?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'InformationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashRequest';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LoginPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Logo';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Member';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MembersList';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaFactors';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaVerification';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileStoreData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestConfirmationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPay';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransfer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransferCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OnfidoErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OurContactInformation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethodAlert';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhoneNumberVerificationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PriceComparsion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferFriendHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Regulator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyNewRequestEmail';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Review';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Reviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SenderFormPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendingPartnership';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ShareSocialLink';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SignIn';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SmsVerificationCodePage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SocialProofingModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SolutionsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StickyBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TagLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  }
                | {
                    __typename?: 'VerificationInProgress';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename: 'Video';
                    name?: string | null;
                    videoId?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    content?: {
                      __typename?: 'VideoContent';
                      json: Document;
                    } | null;
                    backgroundImage?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'WeAreRegulated';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
              inline: Array<
                | {
                    __typename?: 'AccountDuplicationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ActionResult';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReview';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchool';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCostCategory';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCountryData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BlogContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexChartFilterOptions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexLineChart';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateAlerts';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ChangePasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContextualPromoBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CorrespondentLocations';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountryPayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CurrencyPricingConverter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'EmbeddedTableEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExternalScript';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelp';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelpItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ForgottenPasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowDoesItWork';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowItWorksStepRelaunch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowSendingWorks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    name?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    } | null;
                    icon?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'Image';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'InformationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashRequest';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LoginPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Logo';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Member';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MembersList';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaFactors';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaVerification';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileStoreData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestConfirmationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPay';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransfer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransferCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OnfidoErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OurContactInformation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethodAlert';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhoneNumberVerificationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PriceComparsion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferFriendHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Regulator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyNewRequestEmail';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Review';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Reviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SenderFormPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendingPartnership';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ShareSocialLink';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SignIn';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SmsVerificationCodePage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SocialProofingModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SolutionsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StickyBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TagLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  }
                | {
                    __typename?: 'VerificationInProgress';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Video';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WeAreRegulated';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
              hyperlink: Array<
                | {
                    __typename?: 'AccountDuplicationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ActionResult';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReview';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchool';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCostCategory';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCountryData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BlogContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexChartFilterOptions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexLineChart';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateAlerts';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ChangePasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContextualPromoBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CorrespondentLocations';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountryPayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CurrencyPricingConverter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'EmbeddedTableEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExternalScript';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelp';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelpItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ForgottenPasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowDoesItWork';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowItWorksStepRelaunch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowSendingWorks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Image';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'InformationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashRequest';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LoginPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Logo';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Member';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MembersList';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaFactors';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaVerification';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileStoreData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestConfirmationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPay';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransfer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransferCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OnfidoErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OurContactInformation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethodAlert';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhoneNumberVerificationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PriceComparsion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferFriendHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Regulator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyNewRequestEmail';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Review';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Reviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SenderFormPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendingPartnership';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ShareSocialLink';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SignIn';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SmsVerificationCodePage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SocialProofingModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SolutionsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StickyBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TagLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'VerificationInProgress';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Video';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WeAreRegulated';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
            };
          };
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type CexLineChartFragment = {
  __typename?: 'CexLineChart';
  name?: string | null;
  titleTemplate?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  chartFilterOptions?: {
    __typename?: 'CexLineChartFilterOptionsCollection';
    items: Array<{
      __typename?: 'CexChartFilterOptions';
      name?: string | null;
      label?: string | null;
      filterOption?: string | null;
      setDefault?: boolean | null;
    } | null>;
  } | null;
};

export type CexLineChartQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CexLineChartQueryQuery = {
  __typename?: 'Query';
  CexLineChart?: {
    __typename?: 'CexLineChart';
    name?: string | null;
    titleTemplate?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    chartFilterOptions?: {
      __typename?: 'CexLineChartFilterOptionsCollection';
      items: Array<{
        __typename?: 'CexChartFilterOptions';
        name?: string | null;
        label?: string | null;
        filterOption?: string | null;
        setDefault?: boolean | null;
      } | null>;
    } | null;
  } | null;
};

export type CexRateAlertsFragment = {
  __typename: 'CexRateAlerts';
  name?: string | null;
  titleTemplate?: string | null;
  description?: string | null;
  textFieldPlaceholder?: string | null;
  buttonTitle?: string | null;
  message?: string | null;
  receiveMethodLabel?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  informationDialog?:
    | { __typename?: 'AccountDuplicationPage' }
    | { __typename?: 'ActionResult' }
    | { __typename?: 'AppBanner' }
    | { __typename?: 'AppReview' }
    | { __typename?: 'AppReviews' }
    | { __typename?: 'BackToSchool' }
    | { __typename?: 'BackToSchoolCostCategory' }
    | { __typename?: 'BackToSchoolCountryData' }
    | { __typename?: 'BetterWayTop' }
    | { __typename?: 'BlogContent' }
    | { __typename?: 'BodyText' }
    | { __typename?: 'Calculator' }
    | { __typename?: 'CalculatorAppLeadingLinks' }
    | { __typename?: 'CalculatorPage' }
    | { __typename?: 'Card' }
    | { __typename?: 'Cards' }
    | { __typename?: 'CexChartFilterOptions' }
    | { __typename?: 'CexCurrency' }
    | { __typename?: 'CexCurrencyCorridor' }
    | { __typename?: 'CexLineChart' }
    | { __typename?: 'CexRateAlerts' }
    | { __typename?: 'CexRateTable' }
    | { __typename?: 'CexSearch' }
    | { __typename?: 'ChangePasswordPage' }
    | { __typename?: 'ComparisonTable' }
    | { __typename?: 'ComparisonTableCell' }
    | { __typename?: 'ComparisonTableRow' }
    | { __typename?: 'ContextualPromoBanner' }
    | { __typename?: 'Correspondent' }
    | { __typename?: 'CorrespondentLocations' }
    | { __typename?: 'Correspondents' }
    | { __typename?: 'Country' }
    | { __typename?: 'CountryPayoutMethod' }
    | { __typename?: 'CurrencyPricingConverter' }
    | { __typename?: 'EmbeddedTableEntry' }
    | { __typename?: 'ExternalScript' }
    | { __typename?: 'FaqFeedbackModule' }
    | { __typename?: 'FaqFeedbackModuleItem' }
    | { __typename?: 'FaqHero' }
    | { __typename?: 'FaqModule' }
    | { __typename?: 'FaqModuleItem' }
    | { __typename?: 'FaqPopularQuestions' }
    | { __typename?: 'FaqSearchHelp' }
    | { __typename?: 'FaqSearchHelpItems' }
    | { __typename?: 'FaqTopicsModule' }
    | { __typename?: 'FaqTopicsModuleItems' }
    | { __typename?: 'FeatureFlag' }
    | { __typename?: 'Footer' }
    | { __typename?: 'FooterNavigationGroup' }
    | { __typename?: 'FooterNavigationGroupExtras' }
    | { __typename?: 'ForgottenPasswordPage' }
    | { __typename?: 'FullWidthBanner' }
    | { __typename?: 'GenericErrorPage' }
    | { __typename?: 'HeroSlot' }
    | { __typename?: 'HowDoesItWork' }
    | { __typename?: 'HowItWorksStepRelaunch' }
    | { __typename?: 'HowSendingWorks' }
    | { __typename?: 'IconWithText' }
    | { __typename?: 'Image' }
    | {
        __typename: 'InformationDialog';
        name?: string | null;
        title?: string | null;
        sys: { __typename?: 'Sys'; id: string };
        content?: {
          __typename?: 'InformationDialogContent';
          json: Document;
        } | null;
      }
    | { __typename?: 'JazzCashPage' }
    | { __typename?: 'JazzCashRequest' }
    | { __typename?: 'LayoutFooter' }
    | { __typename?: 'LayoutHeader' }
    | { __typename?: 'LegalMessaging' }
    | { __typename?: 'Link' }
    | { __typename?: 'ListOfCountries' }
    | { __typename?: 'LoginPage' }
    | { __typename?: 'Logo' }
    | { __typename?: 'Member' }
    | { __typename?: 'MembersList' }
    | { __typename?: 'Messages' }
    | { __typename?: 'MfaFactors' }
    | { __typename?: 'MfaVerification' }
    | { __typename?: 'MobileAppLeadingLinks' }
    | { __typename?: 'MobileStoreData' }
    | { __typename?: 'MoneyRequestConfirmationDialog' }
    | { __typename?: 'MoneyRequestPay' }
    | { __typename?: 'MoneyRequestPaymentReason' }
    | { __typename?: 'MoneyTransfer' }
    | { __typename?: 'MoneyTransferCard' }
    | { __typename?: 'NotFoundContent' }
    | { __typename?: 'NotFoundErrorPage' }
    | { __typename?: 'NotFoundPage' }
    | { __typename?: 'OnfidoErrorPage' }
    | { __typename?: 'OurContactInformation' }
    | { __typename?: 'Page' }
    | { __typename?: 'PageSection' }
    | { __typename?: 'PaymentReason' }
    | { __typename?: 'PayoutMethod' }
    | { __typename?: 'PayoutMethodAlert' }
    | { __typename?: 'Person' }
    | { __typename?: 'PhoneNumberVerificationPage' }
    | { __typename?: 'PopularCountries' }
    | { __typename?: 'PriceComparsion' }
    | { __typename?: 'PricingCalculator' }
    | { __typename?: 'PromoApplicability' }
    | { __typename?: 'PromoMessage' }
    | { __typename?: 'PromotionalBanner' }
    | { __typename?: 'PromotionalBannerDisclaimer' }
    | { __typename?: 'Redirect' }
    | { __typename?: 'ReferAFriendGreenfieldEnabledCountries' }
    | { __typename?: 'ReferFriendHeader' }
    | { __typename?: 'RegionCode' }
    | { __typename?: 'Regulator' }
    | { __typename?: 'RequestMoneyNewRequestEmail' }
    | { __typename?: 'RequestMoneyPage' }
    | { __typename?: 'Review' }
    | { __typename?: 'Reviews' }
    | { __typename?: 'ReviewsTimeDictionary' }
    | { __typename?: 'RobotsConfiguration' }
    | { __typename?: 'Search' }
    | { __typename?: 'SendToCountryModule' }
    | { __typename?: 'SenderFormPage' }
    | { __typename?: 'SendingPartnership' }
    | { __typename?: 'SeoContactPoint' }
    | { __typename?: 'SeoOrganizationStructuredData' }
    | { __typename?: 'SeoSameAs' }
    | { __typename?: 'ShareSocialLink' }
    | { __typename?: 'SignIn' }
    | { __typename?: 'Sitemap' }
    | { __typename?: 'SmsVerificationCodePage' }
    | { __typename?: 'SocialProofingModule' }
    | { __typename?: 'SolutionsModule' }
    | { __typename?: 'Step' }
    | { __typename?: 'StepByStep' }
    | { __typename?: 'StickyBanner' }
    | { __typename?: 'TagLinks' }
    | { __typename?: 'TextMeTheAppModule' }
    | { __typename?: 'TextSection' }
    | { __typename?: 'TextWithColor' }
    | { __typename?: 'VerificationInProgress' }
    | { __typename?: 'Video' }
    | { __typename?: 'WeAreRegulated' }
    | { __typename?: 'WebForm' }
    | { __typename?: 'WhyChoose' }
    | { __typename?: 'WhyChooseReason' }
    | null;
};

export type CexRateAlertsQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CexRateAlertsQueryQuery = {
  __typename?: 'Query';
  CexRateAlerts?: {
    __typename?: 'CexRateAlertsCollection';
    items: Array<{
      __typename: 'CexRateAlerts';
      name?: string | null;
      titleTemplate?: string | null;
      description?: string | null;
      textFieldPlaceholder?: string | null;
      buttonTitle?: string | null;
      message?: string | null;
      receiveMethodLabel?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      informationDialog?:
        | { __typename?: 'AccountDuplicationPage' }
        | { __typename?: 'ActionResult' }
        | { __typename?: 'AppBanner' }
        | { __typename?: 'AppReview' }
        | { __typename?: 'AppReviews' }
        | { __typename?: 'BackToSchool' }
        | { __typename?: 'BackToSchoolCostCategory' }
        | { __typename?: 'BackToSchoolCountryData' }
        | { __typename?: 'BetterWayTop' }
        | { __typename?: 'BlogContent' }
        | { __typename?: 'BodyText' }
        | { __typename?: 'Calculator' }
        | { __typename?: 'CalculatorAppLeadingLinks' }
        | { __typename?: 'CalculatorPage' }
        | { __typename?: 'Card' }
        | { __typename?: 'Cards' }
        | { __typename?: 'CexChartFilterOptions' }
        | { __typename?: 'CexCurrency' }
        | { __typename?: 'CexCurrencyCorridor' }
        | { __typename?: 'CexLineChart' }
        | { __typename?: 'CexRateAlerts' }
        | { __typename?: 'CexRateTable' }
        | { __typename?: 'CexSearch' }
        | { __typename?: 'ChangePasswordPage' }
        | { __typename?: 'ComparisonTable' }
        | { __typename?: 'ComparisonTableCell' }
        | { __typename?: 'ComparisonTableRow' }
        | { __typename?: 'ContextualPromoBanner' }
        | { __typename?: 'Correspondent' }
        | { __typename?: 'CorrespondentLocations' }
        | { __typename?: 'Correspondents' }
        | { __typename?: 'Country' }
        | { __typename?: 'CountryPayoutMethod' }
        | { __typename?: 'CurrencyPricingConverter' }
        | { __typename?: 'EmbeddedTableEntry' }
        | { __typename?: 'ExternalScript' }
        | { __typename?: 'FaqFeedbackModule' }
        | { __typename?: 'FaqFeedbackModuleItem' }
        | { __typename?: 'FaqHero' }
        | { __typename?: 'FaqModule' }
        | { __typename?: 'FaqModuleItem' }
        | { __typename?: 'FaqPopularQuestions' }
        | { __typename?: 'FaqSearchHelp' }
        | { __typename?: 'FaqSearchHelpItems' }
        | { __typename?: 'FaqTopicsModule' }
        | { __typename?: 'FaqTopicsModuleItems' }
        | { __typename?: 'FeatureFlag' }
        | { __typename?: 'Footer' }
        | { __typename?: 'FooterNavigationGroup' }
        | { __typename?: 'FooterNavigationGroupExtras' }
        | { __typename?: 'ForgottenPasswordPage' }
        | { __typename?: 'FullWidthBanner' }
        | { __typename?: 'GenericErrorPage' }
        | { __typename?: 'HeroSlot' }
        | { __typename?: 'HowDoesItWork' }
        | { __typename?: 'HowItWorksStepRelaunch' }
        | { __typename?: 'HowSendingWorks' }
        | { __typename?: 'IconWithText' }
        | { __typename?: 'Image' }
        | {
            __typename: 'InformationDialog';
            name?: string | null;
            title?: string | null;
            sys: { __typename?: 'Sys'; id: string };
            content?: {
              __typename?: 'InformationDialogContent';
              json: Document;
            } | null;
          }
        | { __typename?: 'JazzCashPage' }
        | { __typename?: 'JazzCashRequest' }
        | { __typename?: 'LayoutFooter' }
        | { __typename?: 'LayoutHeader' }
        | { __typename?: 'LegalMessaging' }
        | { __typename?: 'Link' }
        | { __typename?: 'ListOfCountries' }
        | { __typename?: 'LoginPage' }
        | { __typename?: 'Logo' }
        | { __typename?: 'Member' }
        | { __typename?: 'MembersList' }
        | { __typename?: 'Messages' }
        | { __typename?: 'MfaFactors' }
        | { __typename?: 'MfaVerification' }
        | { __typename?: 'MobileAppLeadingLinks' }
        | { __typename?: 'MobileStoreData' }
        | { __typename?: 'MoneyRequestConfirmationDialog' }
        | { __typename?: 'MoneyRequestPay' }
        | { __typename?: 'MoneyRequestPaymentReason' }
        | { __typename?: 'MoneyTransfer' }
        | { __typename?: 'MoneyTransferCard' }
        | { __typename?: 'NotFoundContent' }
        | { __typename?: 'NotFoundErrorPage' }
        | { __typename?: 'NotFoundPage' }
        | { __typename?: 'OnfidoErrorPage' }
        | { __typename?: 'OurContactInformation' }
        | { __typename?: 'Page' }
        | { __typename?: 'PageSection' }
        | { __typename?: 'PaymentReason' }
        | { __typename?: 'PayoutMethod' }
        | { __typename?: 'PayoutMethodAlert' }
        | { __typename?: 'Person' }
        | { __typename?: 'PhoneNumberVerificationPage' }
        | { __typename?: 'PopularCountries' }
        | { __typename?: 'PriceComparsion' }
        | { __typename?: 'PricingCalculator' }
        | { __typename?: 'PromoApplicability' }
        | { __typename?: 'PromoMessage' }
        | { __typename?: 'PromotionalBanner' }
        | { __typename?: 'PromotionalBannerDisclaimer' }
        | { __typename?: 'Redirect' }
        | { __typename?: 'ReferAFriendGreenfieldEnabledCountries' }
        | { __typename?: 'ReferFriendHeader' }
        | { __typename?: 'RegionCode' }
        | { __typename?: 'Regulator' }
        | { __typename?: 'RequestMoneyNewRequestEmail' }
        | { __typename?: 'RequestMoneyPage' }
        | { __typename?: 'Review' }
        | { __typename?: 'Reviews' }
        | { __typename?: 'ReviewsTimeDictionary' }
        | { __typename?: 'RobotsConfiguration' }
        | { __typename?: 'Search' }
        | { __typename?: 'SendToCountryModule' }
        | { __typename?: 'SenderFormPage' }
        | { __typename?: 'SendingPartnership' }
        | { __typename?: 'SeoContactPoint' }
        | { __typename?: 'SeoOrganizationStructuredData' }
        | { __typename?: 'SeoSameAs' }
        | { __typename?: 'ShareSocialLink' }
        | { __typename?: 'SignIn' }
        | { __typename?: 'Sitemap' }
        | { __typename?: 'SmsVerificationCodePage' }
        | { __typename?: 'SocialProofingModule' }
        | { __typename?: 'SolutionsModule' }
        | { __typename?: 'Step' }
        | { __typename?: 'StepByStep' }
        | { __typename?: 'StickyBanner' }
        | { __typename?: 'TagLinks' }
        | { __typename?: 'TextMeTheAppModule' }
        | { __typename?: 'TextSection' }
        | { __typename?: 'TextWithColor' }
        | { __typename?: 'VerificationInProgress' }
        | { __typename?: 'Video' }
        | { __typename?: 'WeAreRegulated' }
        | { __typename?: 'WebForm' }
        | { __typename?: 'WhyChoose' }
        | { __typename?: 'WhyChooseReason' }
        | null;
    } | null>;
  } | null;
};

export type CexRateTableFragment = {
  __typename?: 'CexRateTable';
  name?: string | null;
  tableTitleLabel?: string | null;
  conversionAmounts?: Array<string | null> | null;
  tableSubtitleOne?: string | null;
  tableSubtitleTwo?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  title?: { __typename?: 'CexRateTableTitle'; json: Document } | null;
};

export type CexRateTableQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CexRateTableQueryQuery = {
  __typename?: 'Query';
  CexRateTable?: {
    __typename?: 'CexRateTable';
    name?: string | null;
    tableTitleLabel?: string | null;
    conversionAmounts?: Array<string | null> | null;
    tableSubtitleOne?: string | null;
    tableSubtitleTwo?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    title?: { __typename?: 'CexRateTableTitle'; json: Document } | null;
  } | null;
};

export type CexSearchFragment = {
  __typename?: 'CexSearch';
  name?: string | null;
  searchInputLabel?: string | null;
  searchResultListLabel?: string | null;
  showCurrencyGrid?: boolean | null;
  sys: { __typename?: 'Sys'; id: string };
  titleTemplate?: {
    __typename?: 'CexSearchTitleTemplate';
    json: Document;
  } | null;
};

export type CexSearchQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CexSearchQueryQuery = {
  __typename?: 'Query';
  CexSearch?: {
    __typename?: 'CexSearch';
    name?: string | null;
    searchInputLabel?: string | null;
    searchResultListLabel?: string | null;
    showCurrencyGrid?: boolean | null;
    sys: { __typename?: 'Sys'; id: string };
    titleTemplate?: {
      __typename?: 'CexSearchTitleTemplate';
      json: Document;
    } | null;
  } | null;
};

export type EmbeddedTableEntryFragment = {
  __typename: 'EmbeddedTableEntry';
  name?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  description?: {
    __typename?: 'EmbeddedTableEntryDescription';
    json: Document;
    links: {
      __typename?: 'EmbeddedTableEntryDescriptionLinks';
      assets: {
        __typename?: 'EmbeddedTableEntryDescriptionAssets';
        block: Array<{
          __typename?: 'Asset';
          contentType?: string | null;
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      };
      entries: {
        __typename?: 'EmbeddedTableEntryDescriptionEntries';
        block: Array<
          | {
              __typename?: 'AccountDuplicationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ActionResult';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReview';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReviews';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchool';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCostCategory';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCountryData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BlogContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexChartFilterOptions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexLineChart';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateAlerts';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ChangePasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContextualPromoBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CorrespondentLocations';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CountryPayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CurrencyPricingConverter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'EmbeddedTableEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ExternalScript';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelp';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelpItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ForgottenPasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HowDoesItWork';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowItWorksStepRelaunch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowSendingWorks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'Image';
              name?: string | null;
              align?: string | null;
              position?: string | null;
              width?: number | null;
              height?: number | null;
              sys: { __typename?: 'Sys'; id: string };
              asset?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'InformationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashRequest';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LoginPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MembersList';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MfaFactors';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MfaVerification';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileStoreData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestConfirmationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPay';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransfer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransferCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OnfidoErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OurContactInformation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethodAlert';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhoneNumberVerificationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PriceComparsion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReferAFriendGreenfieldEnabledCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReferFriendHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Regulator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyNewRequestEmail';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Reviews'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SenderFormPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SendingPartnership';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ShareSocialLink';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SmsVerificationCodePage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SocialProofingModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SolutionsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'StickyBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TagLinks'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              name?: string | null;
              inline?: boolean | null;
              color?: string | null;
              backgroundColor?: string | null;
              align?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColorText';
                json: Document;
              } | null;
            }
          | {
              __typename?: 'VerificationInProgress';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'Video';
              name?: string | null;
              videoId?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              content?: { __typename?: 'VideoContent'; json: Document } | null;
              backgroundImage?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'WeAreRegulated';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        inline: Array<
          | {
              __typename?: 'AccountDuplicationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ActionResult';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReview';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReviews';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchool';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCostCategory';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCountryData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BlogContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexChartFilterOptions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexLineChart';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateAlerts';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ChangePasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContextualPromoBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CorrespondentLocations';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CountryPayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CurrencyPricingConverter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'EmbeddedTableEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ExternalScript';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelp';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelpItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ForgottenPasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HowDoesItWork';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowItWorksStepRelaunch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowSendingWorks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'InformationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashRequest';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LoginPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MembersList';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MfaFactors';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MfaVerification';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileStoreData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestConfirmationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPay';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransfer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransferCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OnfidoErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OurContactInformation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethodAlert';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhoneNumberVerificationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PriceComparsion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReferAFriendGreenfieldEnabledCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReferFriendHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Regulator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyNewRequestEmail';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Reviews'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SenderFormPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SendingPartnership';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ShareSocialLink';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SmsVerificationCodePage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SocialProofingModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SolutionsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'StickyBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TagLinks'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              name?: string | null;
              inline?: boolean | null;
              color?: string | null;
              backgroundColor?: string | null;
              align?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColorText';
                json: Document;
              } | null;
            }
          | {
              __typename?: 'VerificationInProgress';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WeAreRegulated';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        hyperlink: Array<
          | {
              __typename?: 'AccountDuplicationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ActionResult';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReview';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReviews';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchool';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCostCategory';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCountryData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BlogContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexChartFilterOptions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexLineChart';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateAlerts';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ChangePasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContextualPromoBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CorrespondentLocations';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CountryPayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CurrencyPricingConverter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'EmbeddedTableEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ExternalScript';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelp';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelpItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ForgottenPasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HowDoesItWork';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowItWorksStepRelaunch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowSendingWorks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'InformationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashRequest';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LoginPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MembersList';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MfaFactors';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MfaVerification';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileStoreData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestConfirmationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPay';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransfer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransferCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OnfidoErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OurContactInformation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethodAlert';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhoneNumberVerificationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PriceComparsion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReferAFriendGreenfieldEnabledCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReferFriendHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Regulator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyNewRequestEmail';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Reviews'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SenderFormPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SendingPartnership';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ShareSocialLink';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SmsVerificationCodePage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SocialProofingModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SolutionsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'StickyBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TagLinks'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'VerificationInProgress';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WeAreRegulated';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
      };
    };
  } | null;
};

export type EmbeddedTableEntryQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type EmbeddedTableEntryQueryQuery = {
  __typename?: 'Query';
  EmbeddedTableEntry?: {
    __typename: 'EmbeddedTableEntry';
    name?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    description?: {
      __typename?: 'EmbeddedTableEntryDescription';
      json: Document;
      links: {
        __typename?: 'EmbeddedTableEntryDescriptionLinks';
        assets: {
          __typename?: 'EmbeddedTableEntryDescriptionAssets';
          block: Array<{
            __typename?: 'Asset';
            contentType?: string | null;
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
            sys: { __typename?: 'Sys'; id: string };
          } | null>;
        };
        entries: {
          __typename?: 'EmbeddedTableEntryDescriptionEntries';
          block: Array<
            | {
                __typename?: 'AccountDuplicationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ActionResult';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReview';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchool';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCostCategory';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCountryData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BetterWayTop';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BlogContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BodyText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Calculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'CexChartFilterOptions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrency';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrencyCorridor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexLineChart';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateAlerts';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexSearch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ChangePasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableCell';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableRow';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ContextualPromoBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CorrespondentLocations';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondents';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Country';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CountryPayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CurrencyPricingConverter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'EmbeddedTableEntry';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ExternalScript';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqHero';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqPopularQuestions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelp';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelpItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModuleItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FeatureFlag';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'FooterNavigationGroup';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FooterNavigationGroupExtras';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ForgottenPasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FullWidthBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'GenericErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HeroSlot';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowDoesItWork';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowItWorksStepRelaunch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowSendingWorks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'IconWithText';
                name?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                } | null;
                icon?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'Image';
                name?: string | null;
                align?: string | null;
                position?: string | null;
                width?: number | null;
                height?: number | null;
                sys: { __typename?: 'Sys'; id: string };
                asset?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'InformationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashRequest';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutFooter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LegalMessaging';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                sys: { __typename?: 'Sys'; id: string };
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'ListOfCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LoginPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'MembersList';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Messages';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaFactors';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaVerification';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileStoreData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestConfirmationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPay';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransfer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransferCard';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OnfidoErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OurContactInformation';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PageSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethodAlert';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PhoneNumberVerificationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PopularCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PriceComparsion';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PricingCalculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoApplicability';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoMessage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBannerDisclaimer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Redirect';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferFriendHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RegionCode';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Regulator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyNewRequestEmail';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Reviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReviewsTimeDictionary';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RobotsConfiguration';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'SendToCountryModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SenderFormPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SendingPartnership';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoContactPoint';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoOrganizationStructuredData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoSameAs';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ShareSocialLink';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Sitemap';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SmsVerificationCodePage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SocialProofingModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SolutionsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'StepByStep';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'StickyBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TagLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextMeTheAppModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              }
            | {
                __typename?: 'VerificationInProgress';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename: 'Video';
                name?: string | null;
                videoId?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                content?: {
                  __typename?: 'VideoContent';
                  json: Document;
                } | null;
                backgroundImage?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'WeAreRegulated';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WebForm';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChoose';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChooseReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | null
          >;
          inline: Array<
            | {
                __typename?: 'AccountDuplicationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ActionResult';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReview';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchool';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCostCategory';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCountryData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BetterWayTop';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BlogContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BodyText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Calculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'CexChartFilterOptions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrency';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrencyCorridor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexLineChart';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateAlerts';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexSearch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ChangePasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableCell';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableRow';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ContextualPromoBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CorrespondentLocations';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondents';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Country';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CountryPayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CurrencyPricingConverter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'EmbeddedTableEntry';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ExternalScript';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqHero';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqPopularQuestions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelp';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelpItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModuleItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FeatureFlag';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'FooterNavigationGroup';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FooterNavigationGroupExtras';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ForgottenPasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FullWidthBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'GenericErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HeroSlot';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowDoesItWork';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowItWorksStepRelaunch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowSendingWorks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'IconWithText';
                name?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                } | null;
                icon?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'InformationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashRequest';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutFooter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LegalMessaging';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                sys: { __typename?: 'Sys'; id: string };
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'ListOfCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LoginPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'MembersList';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Messages';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaFactors';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaVerification';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileStoreData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestConfirmationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPay';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransfer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransferCard';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OnfidoErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OurContactInformation';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PageSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethodAlert';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PhoneNumberVerificationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PopularCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PriceComparsion';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PricingCalculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoApplicability';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoMessage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBannerDisclaimer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Redirect';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferFriendHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RegionCode';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Regulator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyNewRequestEmail';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Reviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReviewsTimeDictionary';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RobotsConfiguration';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'SendToCountryModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SenderFormPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SendingPartnership';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoContactPoint';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoOrganizationStructuredData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoSameAs';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ShareSocialLink';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Sitemap';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SmsVerificationCodePage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SocialProofingModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SolutionsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'StepByStep';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'StickyBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TagLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextMeTheAppModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              }
            | {
                __typename?: 'VerificationInProgress';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'WeAreRegulated';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WebForm';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChoose';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChooseReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | null
          >;
          hyperlink: Array<
            | {
                __typename?: 'AccountDuplicationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ActionResult';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReview';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchool';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCostCategory';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCountryData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BetterWayTop';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BlogContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BodyText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Calculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'CexChartFilterOptions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrency';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrencyCorridor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexLineChart';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateAlerts';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexSearch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ChangePasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableCell';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableRow';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ContextualPromoBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CorrespondentLocations';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondents';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Country';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CountryPayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CurrencyPricingConverter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'EmbeddedTableEntry';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ExternalScript';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqHero';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqPopularQuestions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelp';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelpItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModuleItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FeatureFlag';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'FooterNavigationGroup';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FooterNavigationGroupExtras';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ForgottenPasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FullWidthBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'GenericErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HeroSlot';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowDoesItWork';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowItWorksStepRelaunch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowSendingWorks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'IconWithText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'InformationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashRequest';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutFooter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LegalMessaging';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                sys: { __typename?: 'Sys'; id: string };
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'ListOfCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LoginPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'MembersList';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Messages';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaFactors';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaVerification';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileStoreData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestConfirmationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPay';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransfer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransferCard';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OnfidoErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OurContactInformation';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PageSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethodAlert';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PhoneNumberVerificationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PopularCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PriceComparsion';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PricingCalculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoApplicability';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoMessage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBannerDisclaimer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Redirect';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferFriendHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RegionCode';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Regulator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyNewRequestEmail';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Reviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReviewsTimeDictionary';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RobotsConfiguration';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'SendToCountryModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SenderFormPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SendingPartnership';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoContactPoint';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoOrganizationStructuredData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoSameAs';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ShareSocialLink';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Sitemap';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SmsVerificationCodePage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SocialProofingModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SolutionsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'StepByStep';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'StickyBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TagLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextMeTheAppModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextWithColor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'VerificationInProgress';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'WeAreRegulated';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WebForm';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChoose';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChooseReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | null
          >;
        };
      };
    } | null;
  } | null;
};

export type ExternalScriptFragment = {
  __typename?: 'ExternalScript';
  name?: string | null;
  scriptType?: string | null;
  id?: string | null;
  key?: string | null;
};

export type ExternalScriptQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ExternalScriptQueryQuery = {
  __typename?: 'Query';
  ExternalScript?: {
    __typename?: 'ExternalScript';
    name?: string | null;
    scriptType?: string | null;
    id?: string | null;
    key?: string | null;
  } | null;
};

export type FaqFeedbackModuleFragment = {
  __typename?: 'FaqFeedbackModule';
  name?: string | null;
  title?: string | null;
  postFeedbackMessage?: string | null;
  analyticsTagId?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  feedbackOptions?: {
    __typename?: 'FaqFeedbackModuleFeedbackOptionsCollection';
    items: Array<{
      __typename?: 'FaqFeedbackModuleItem';
      name?: string | null;
      label?: string | null;
      analyticsTagId?: string | null;
      icon?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type FaqFeedbackModuleQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type FaqFeedbackModuleQueryQuery = {
  __typename?: 'Query';
  FaqFeedbackModule?: {
    __typename?: 'FaqFeedbackModule';
    name?: string | null;
    title?: string | null;
    postFeedbackMessage?: string | null;
    analyticsTagId?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    feedbackOptions?: {
      __typename?: 'FaqFeedbackModuleFeedbackOptionsCollection';
      items: Array<{
        __typename?: 'FaqFeedbackModuleItem';
        name?: string | null;
        label?: string | null;
        analyticsTagId?: string | null;
        icon?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type FaqHeroFragment = {
  __typename?: 'FaqHero';
  name?: string | null;
  heading?: string | null;
  menuTitle?: string | null;
  primaryBackgroundColor?: string | null;
  secondaryBackgroundColor?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  heroImage?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  search?: {
    __typename: 'Search';
    name?: string | null;
    heading?: string | null;
    inputLabel?: string | null;
    resultListLabel?: string | null;
    suggestionListLabel?: string | null;
    suggestionListCollection?: {
      __typename?: 'SearchSuggestionListCollection';
      items: Array<{
        __typename?: 'Page';
        name?: string | null;
        slug?: string | null;
        sys: { __typename?: 'Sys'; id: string };
      } | null>;
    } | null;
  } | null;
  links?: {
    __typename?: 'FaqHeroLinksCollection';
    items: Array<{
      __typename?: 'Link';
      name?: string | null;
      label?: string | null;
      url?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type FaqHeroQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type FaqHeroQueryQuery = {
  __typename?: 'Query';
  FaqHero?: {
    __typename?: 'FaqHero';
    name?: string | null;
    heading?: string | null;
    menuTitle?: string | null;
    primaryBackgroundColor?: string | null;
    secondaryBackgroundColor?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    heroImage?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
    search?: {
      __typename: 'Search';
      name?: string | null;
      heading?: string | null;
      inputLabel?: string | null;
      resultListLabel?: string | null;
      suggestionListLabel?: string | null;
      suggestionListCollection?: {
        __typename?: 'SearchSuggestionListCollection';
        items: Array<{
          __typename?: 'Page';
          name?: string | null;
          slug?: string | null;
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      } | null;
    } | null;
    links?: {
      __typename?: 'FaqHeroLinksCollection';
      items: Array<{
        __typename?: 'Link';
        name?: string | null;
        label?: string | null;
        url?: string | null;
        sys: { __typename?: 'Sys'; id: string };
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type FaqModuleItemFragment = {
  __typename: 'FaqModuleItem';
  name?: string | null;
  title?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  description?: {
    __typename?: 'FaqModuleItemDescription';
    json: Document;
    links: {
      __typename?: 'FaqModuleItemDescriptionLinks';
      assets: {
        __typename?: 'FaqModuleItemDescriptionAssets';
        block: Array<{
          __typename?: 'Asset';
          contentType?: string | null;
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      };
      entries: {
        __typename?: 'FaqModuleItemDescriptionEntries';
        block: Array<
          | {
              __typename?: 'AccountDuplicationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ActionResult';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReview';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReviews';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchool';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCostCategory';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCountryData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BlogContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexChartFilterOptions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexLineChart';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateAlerts';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ChangePasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContextualPromoBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CorrespondentLocations';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CountryPayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CurrencyPricingConverter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'EmbeddedTableEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ExternalScript';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelp';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelpItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ForgottenPasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HowDoesItWork';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowItWorksStepRelaunch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowSendingWorks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'Image';
              name?: string | null;
              align?: string | null;
              position?: string | null;
              width?: number | null;
              height?: number | null;
              sys: { __typename?: 'Sys'; id: string };
              asset?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'InformationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashRequest';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LoginPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MembersList';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MfaFactors';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MfaVerification';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileStoreData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestConfirmationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPay';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransfer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransferCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OnfidoErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OurContactInformation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethodAlert';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhoneNumberVerificationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PriceComparsion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReferAFriendGreenfieldEnabledCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReferFriendHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Regulator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyNewRequestEmail';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Reviews'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SenderFormPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SendingPartnership';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ShareSocialLink';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SmsVerificationCodePage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SocialProofingModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SolutionsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'StickyBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TagLinks'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              name?: string | null;
              inline?: boolean | null;
              color?: string | null;
              backgroundColor?: string | null;
              align?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColorText';
                json: Document;
              } | null;
            }
          | {
              __typename?: 'VerificationInProgress';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'Video';
              name?: string | null;
              videoId?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              content?: { __typename?: 'VideoContent'; json: Document } | null;
              backgroundImage?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'WeAreRegulated';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        inline: Array<
          | {
              __typename?: 'AccountDuplicationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ActionResult';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReview';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReviews';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchool';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCostCategory';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCountryData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BlogContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexChartFilterOptions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexLineChart';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateAlerts';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ChangePasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContextualPromoBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CorrespondentLocations';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CountryPayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CurrencyPricingConverter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'EmbeddedTableEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ExternalScript';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelp';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelpItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ForgottenPasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HowDoesItWork';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowItWorksStepRelaunch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowSendingWorks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'InformationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashRequest';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LoginPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MembersList';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MfaFactors';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MfaVerification';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileStoreData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestConfirmationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPay';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransfer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransferCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OnfidoErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OurContactInformation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethodAlert';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhoneNumberVerificationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PriceComparsion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReferAFriendGreenfieldEnabledCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReferFriendHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Regulator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyNewRequestEmail';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Reviews'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SenderFormPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SendingPartnership';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ShareSocialLink';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SmsVerificationCodePage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SocialProofingModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SolutionsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'StickyBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TagLinks'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              name?: string | null;
              inline?: boolean | null;
              color?: string | null;
              backgroundColor?: string | null;
              align?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColorText';
                json: Document;
              } | null;
            }
          | {
              __typename?: 'VerificationInProgress';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WeAreRegulated';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        hyperlink: Array<
          | {
              __typename?: 'AccountDuplicationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ActionResult';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReview';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReviews';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchool';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCostCategory';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCountryData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BlogContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexChartFilterOptions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexLineChart';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateAlerts';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ChangePasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContextualPromoBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CorrespondentLocations';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CountryPayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CurrencyPricingConverter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'EmbeddedTableEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ExternalScript';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelp';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelpItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ForgottenPasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HowDoesItWork';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowItWorksStepRelaunch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowSendingWorks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'InformationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashRequest';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LoginPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MembersList';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MfaFactors';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MfaVerification';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileStoreData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestConfirmationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPay';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransfer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransferCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OnfidoErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OurContactInformation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethodAlert';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhoneNumberVerificationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PriceComparsion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReferAFriendGreenfieldEnabledCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReferFriendHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Regulator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyNewRequestEmail';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Reviews'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SenderFormPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SendingPartnership';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ShareSocialLink';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SmsVerificationCodePage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SocialProofingModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SolutionsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'StickyBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TagLinks'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'VerificationInProgress';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WeAreRegulated';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
      };
    };
  } | null;
};

export type FaqModuleItemQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type FaqModuleItemQueryQuery = {
  __typename?: 'Query';
  FaqModuleItem?: {
    __typename?: 'FaqModuleItemCollection';
    items: Array<{
      __typename: 'FaqModuleItem';
      name?: string | null;
      title?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      description?: {
        __typename?: 'FaqModuleItemDescription';
        json: Document;
        links: {
          __typename?: 'FaqModuleItemDescriptionLinks';
          assets: {
            __typename?: 'FaqModuleItemDescriptionAssets';
            block: Array<{
              __typename?: 'Asset';
              contentType?: string | null;
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            } | null>;
          };
          entries: {
            __typename?: 'FaqModuleItemDescriptionEntries';
            block: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  name?: string | null;
                  align?: string | null;
                  position?: string | null;
                  width?: number | null;
                  height?: number | null;
                  sys: { __typename?: 'Sys'; id: string };
                  asset?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename: 'Video';
                  name?: string | null;
                  videoId?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  content?: {
                    __typename?: 'VideoContent';
                    json: Document;
                  } | null;
                  backgroundImage?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            inline: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            hyperlink: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
    } | null>;
  } | null;
};

export type FaqModuleFragment = {
  __typename: 'FaqModule';
  name?: string | null;
  title?: string | null;
  subheading?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  image?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  questions?: {
    __typename?: 'FaqModuleQuestionsCollection';
    items: Array<{
      __typename?: 'FaqModuleItem';
      name?: string | null;
      title?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      description?: {
        __typename?: 'FaqModuleItemDescription';
        json: Document;
      } | null;
    } | null>;
  } | null;
  ctaButton?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
};

export type FaqModuleQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryCode?: InputMaybe<Scalars['String']['input']>;
  receiveCountryCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type FaqModuleQueryQuery = {
  __typename?: 'Query';
  FaqModule?: {
    __typename?: 'FaqModuleCollection';
    items: Array<{
      __typename: 'FaqModule';
      name?: string | null;
      title?: string | null;
      subheading?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      questions?: {
        __typename?: 'FaqModuleQuestionsCollection';
        items: Array<{
          __typename?: 'FaqModuleItem';
          name?: string | null;
          title?: string | null;
          sys: { __typename?: 'Sys'; id: string };
          description?: {
            __typename?: 'FaqModuleItemDescription';
            json: Document;
          } | null;
        } | null>;
      } | null;
      ctaButton?: {
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type FaqPopularQuestionsFragment = {
  __typename?: 'FaqPopularQuestions';
  name?: string | null;
  title?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  questions?: {
    __typename?: 'FaqPopularQuestionsQuestionsCollection';
    items: Array<{
      __typename: 'Page';
      name?: string | null;
      slug?: string | null;
      title?: string | null;
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
};

export type FaqPopularQuestionsQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type FaqPopularQuestionsQueryQuery = {
  __typename?: 'Query';
  FaqPopularQuestions?: {
    __typename?: 'FaqPopularQuestions';
    name?: string | null;
    title?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    questions?: {
      __typename?: 'FaqPopularQuestionsQuestionsCollection';
      items: Array<{
        __typename: 'Page';
        name?: string | null;
        slug?: string | null;
        title?: string | null;
        sys: { __typename?: 'Sys'; id: string };
      } | null>;
    } | null;
  } | null;
};

export type FaqTopicsModuleFragment = {
  __typename?: 'FaqTopicsModule';
  name?: string | null;
  title?: string | null;
  backgroundColor?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  topics?: {
    __typename?: 'FaqTopicsModuleTopicsCollection';
    items: Array<{
      __typename?: 'FaqTopicsModuleItems';
      title?: string | null;
      description?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      icon?: { __typename?: 'Asset'; url?: string | null } | null;
      topicLinks?: {
        __typename?: 'FaqTopicsModuleItemsTopicLinksCollection';
        items: Array<{
          __typename: 'Page';
          name?: string | null;
          slug?: string | null;
          title?: string | null;
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      } | null;
    } | null>;
  } | null;
  popularQuestions?: {
    __typename?: 'FaqPopularQuestions';
    name?: string | null;
    title?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    questions?: {
      __typename?: 'FaqPopularQuestionsQuestionsCollection';
      items: Array<{
        __typename: 'Page';
        name?: string | null;
        slug?: string | null;
        title?: string | null;
        sys: { __typename?: 'Sys'; id: string };
      } | null>;
    } | null;
  } | null;
};

export type FaqTopicsModuleQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type FaqTopicsModuleQueryQuery = {
  __typename?: 'Query';
  FaqTopicsModule?: {
    __typename?: 'FaqTopicsModule';
    name?: string | null;
    title?: string | null;
    backgroundColor?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    topics?: {
      __typename?: 'FaqTopicsModuleTopicsCollection';
      items: Array<{
        __typename?: 'FaqTopicsModuleItems';
        title?: string | null;
        description?: string | null;
        sys: { __typename?: 'Sys'; id: string };
        icon?: { __typename?: 'Asset'; url?: string | null } | null;
        topicLinks?: {
          __typename?: 'FaqTopicsModuleItemsTopicLinksCollection';
          items: Array<{
            __typename: 'Page';
            name?: string | null;
            slug?: string | null;
            title?: string | null;
            sys: { __typename?: 'Sys'; id: string };
          } | null>;
        } | null;
      } | null>;
    } | null;
    popularQuestions?: {
      __typename?: 'FaqPopularQuestions';
      name?: string | null;
      title?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      questions?: {
        __typename?: 'FaqPopularQuestionsQuestionsCollection';
        items: Array<{
          __typename: 'Page';
          name?: string | null;
          slug?: string | null;
          title?: string | null;
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type FooterNavigationGroupExtrasFragment = {
  __typename?: 'FooterNavigationGroupExtras';
  backgroundColor?: string | null;
  color?: string | null;
  title?: string | null;
};

export type FooterNavigationGroupFragment = {
  __typename?: 'FooterNavigationGroup';
  title?: string | null;
  linksCollection?: {
    __typename?: 'FooterNavigationGroupLinksCollection';
    items: Array<{
      __typename?: 'Link';
      name?: string | null;
      url?: string | null;
      isInternal?: boolean | null;
      label?: string | null;
      ariaLabel?: string | null;
      openInNewTab?: boolean | null;
      gaTrackName?: string | null;
      isDisplayedWhenLoggedIn?: boolean | null;
      color?: string | null;
      variant?: string | null;
      underline?: string | null;
      align?: string | null;
      noFollow?: boolean | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
  extras?: {
    __typename?: 'FooterNavigationGroupExtras';
    backgroundColor?: string | null;
    color?: string | null;
    title?: string | null;
  } | null;
};

export type LegalMessagingFragment = {
  __typename: 'LegalMessaging';
  name?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  content?: { __typename?: 'LegalMessagingContent'; json: Document } | null;
};

export type FooterFragment = {
  __typename: 'LayoutFooter';
  name?: string | null;
  copyrightText?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  socialIconsLinksCollection?: {
    __typename?: 'LayoutFooterSocialIconsLinksCollection';
    items: Array<{
      __typename?: 'Link';
      name?: string | null;
      url?: string | null;
      isInternal?: boolean | null;
      label?: string | null;
      ariaLabel?: string | null;
      openInNewTab?: boolean | null;
      gaTrackName?: string | null;
      isDisplayedWhenLoggedIn?: boolean | null;
      color?: string | null;
      variant?: string | null;
      underline?: string | null;
      align?: string | null;
      noFollow?: boolean | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
  navigationCollection?: {
    __typename?: 'LayoutFooterNavigationCollection';
    items: Array<{
      __typename?: 'FooterNavigationGroup';
      title?: string | null;
      linksCollection?: {
        __typename?: 'FooterNavigationGroupLinksCollection';
        items: Array<{
          __typename?: 'Link';
          name?: string | null;
          url?: string | null;
          isInternal?: boolean | null;
          label?: string | null;
          ariaLabel?: string | null;
          openInNewTab?: boolean | null;
          gaTrackName?: string | null;
          isDisplayedWhenLoggedIn?: boolean | null;
          color?: string | null;
          variant?: string | null;
          underline?: string | null;
          align?: string | null;
          noFollow?: boolean | null;
          image?: {
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null;
        } | null>;
      } | null;
      extras?: {
        __typename?: 'FooterNavigationGroupExtras';
        backgroundColor?: string | null;
        color?: string | null;
        title?: string | null;
      } | null;
    } | null>;
  } | null;
  footerLogo?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
};

export type ForgottenPasswordPageQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type ForgottenPasswordPageQuery = {
  __typename?: 'Query';
  forgottenPasswordPageCollection?: {
    __typename?: 'ForgottenPasswordPageCollection';
    items: Array<{
      __typename?: 'ForgottenPasswordPage';
      name?: string | null;
      header?: string | null;
      description?: string | null;
      emailInputLabel?: string | null;
      continueButtonLabel?: string | null;
      successEmailSentAlert?: string | null;
      emailHelperText?: string | null;
      resetPasswordTimerText?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      helpText?: {
        __typename?: 'ForgottenPasswordPageHelpText';
        json: Document;
      } | null;
      stickyBanner?: {
        __typename?: 'StickyBanner';
        name?: string | null;
        textColor?: string | null;
        backgroundColor?: string | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
        description?: {
          __typename?: 'StickyBannerDescription';
          json: Document;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type FullWidthBannerFragment = {
  __typename: 'FullWidthBanner';
  name?: string | null;
  backgroundColor?: string | null;
  secondBackgroundColor?: string | null;
  textColor?: string | null;
  textAlign?: string | null;
  borderColor?: string | null;
  secondBorderColor?: string | null;
  contentWidth?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  backgroundImage?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  backgroundImageMobile?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  image?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  text?: { __typename?: 'FullWidthBannerText'; json: Document } | null;
  link?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
};

export type FullWidthBannerQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryCode?: InputMaybe<Scalars['String']['input']>;
  receiveCountryCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type FullWidthBannerQueryQuery = {
  __typename?: 'Query';
  FullWidthBanner?: {
    __typename?: 'FullWidthBannerCollection';
    items: Array<{
      __typename: 'FullWidthBanner';
      name?: string | null;
      backgroundColor?: string | null;
      secondBackgroundColor?: string | null;
      textColor?: string | null;
      textAlign?: string | null;
      borderColor?: string | null;
      secondBorderColor?: string | null;
      contentWidth?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      backgroundImage?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      backgroundImageMobile?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      text?: { __typename?: 'FullWidthBannerText'; json: Document } | null;
      link?: {
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type HeaderFragment = {
  __typename?: 'LayoutHeader';
  name?: string | null;
  menuLabel?: string | null;
  welcome?: string | null;
  lastLogin?: string | null;
  myAccount?: string | null;
  notLoggedInMenuItemsCollection?: {
    __typename?: 'LayoutHeaderNotLoggedInMenuItemsCollection';
    items: Array<{
      __typename?: 'Link';
      name?: string | null;
      url?: string | null;
      isInternal?: boolean | null;
      label?: string | null;
      ariaLabel?: string | null;
      openInNewTab?: boolean | null;
      gaTrackName?: string | null;
      isDisplayedWhenLoggedIn?: boolean | null;
      color?: string | null;
      variant?: string | null;
      underline?: string | null;
      align?: string | null;
      noFollow?: boolean | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
  loggedInMenuItemsCollection?: {
    __typename?: 'LayoutHeaderLoggedInMenuItemsCollection';
    items: Array<{
      __typename?: 'Link';
      name?: string | null;
      url?: string | null;
      isInternal?: boolean | null;
      label?: string | null;
      ariaLabel?: string | null;
      openInNewTab?: boolean | null;
      gaTrackName?: string | null;
      isDisplayedWhenLoggedIn?: boolean | null;
      color?: string | null;
      variant?: string | null;
      underline?: string | null;
      align?: string | null;
      noFollow?: boolean | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
  userDropdownMenuItemsCollection?: {
    __typename?: 'LayoutHeaderUserDropdownMenuItemsCollection';
    items: Array<{
      __typename?: 'Link';
      name?: string | null;
      url?: string | null;
      isInternal?: boolean | null;
      label?: string | null;
      ariaLabel?: string | null;
      openInNewTab?: boolean | null;
      gaTrackName?: string | null;
      isDisplayedWhenLoggedIn?: boolean | null;
      color?: string | null;
      variant?: string | null;
      underline?: string | null;
      align?: string | null;
      noFollow?: boolean | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
  referFriendLink?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
  logoLight?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  logoLightMobile?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  logoDark?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  logoDarkMobile?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
};

export type HeroSlotFragment = {
  __typename: 'HeroSlot';
  name?: string | null;
  header?: string | null;
  subheader?: string | null;
  loginInText?: string | null;
  buttonTitle?: string | null;
  loginInLinkTitle?: string | null;
  qrCodeLabel?: string | null;
  dividerLabel?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  image?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  loginPageLink?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
  appStoreLink?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
  googlePlayStoreLink?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
  downloadAppsPageLink?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
  qrCode?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
};

export type HeroSlotQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type HeroSlotQueryQuery = {
  __typename?: 'Query';
  HeroSlot?: {
    __typename?: 'HeroSlotCollection';
    items: Array<{
      __typename: 'HeroSlot';
      name?: string | null;
      header?: string | null;
      subheader?: string | null;
      loginInText?: string | null;
      buttonTitle?: string | null;
      loginInLinkTitle?: string | null;
      qrCodeLabel?: string | null;
      dividerLabel?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      loginPageLink?: {
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
      appStoreLink?: {
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
      googlePlayStoreLink?: {
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
      downloadAppsPageLink?: {
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
      qrCode?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type BetterWayTopFragment = {
  __typename: 'BetterWayTop';
  name?: string | null;
  headingColor?: string | null;
  backgroundColor?: string | null;
  secondBackgroundColor?: string | null;
  hideDescriptionOnMobile?: boolean | null;
  trustpilotMessage?: string | null;
  contentAlignment?: string | null;
  contentFlexDirection?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  rightModule?:
    | {
        __typename?: 'Calculator';
        name?: string | null;
        sendLabel?: string | null;
        receiveLabel?: string | null;
        isLite?: boolean | null;
        payoutMethodsLabel?: string | null;
        countriesSearchPlaceholder?: string | null;
        unavailableCorridorErrorMessage?: string | null;
        airtimeTopupQuoteAvailableInApp?: string | null;
        exchangeRateLabel?: string | null;
        exchangeRatePromoLabel?: string | null;
        receiverPayoutMethodUnavailableMessage?: string | null;
        senderPayoutMethodUnavailableMessage?: string | null;
        generalErrorMessage?: string | null;
        partnersLabel?: string | null;
        partnersListDefaultValue?: string | null;
        partnerSelectErrorMessage?: string | null;
        phoneValidatorLabel?: string | null;
        phoneValidatorPlaceholder?: string | null;
        phoneInputInfoMoreInfoLabel?: string | null;
        phoneInputInfoPopupText?: string | null;
        phoneNumberInputErrorMessage?: string | null;
        phoneNumberInvalidErrorMessage?: string | null;
        topUpAmountsLabel?: string | null;
        topUpAmountsTitle?: string | null;
        topUpAmountsPlaceholder?: string | null;
        topUpAmountsSelectErrorMessage?: string | null;
        topUpAmountsSelectRetrievalErrorMessage?: string | null;
        feeLabel?: string | null;
        instantDiscountLabel?: string | null;
        transferTimeLabel?: string | null;
        totalToPayLabel?: string | null;
        blendedRateLabel?: string | null;
        phReceiveBankTransferMessage?: string | null;
        version?: string | null;
        loginLink?: { __typename?: 'Link'; url?: string | null } | null;
        signUpLink?: { __typename?: 'Link'; url?: string | null } | null;
        contentfulPayoutMethodsList?: {
          __typename?: 'CalculatorPayoutMethodsListCollection';
          items: Array<{
            __typename?: 'PayoutMethod';
            name?: string | null;
            description?: string | null;
            payoutMethodId?: string | null;
            transferTimeTooltip?: string | null;
          } | null>;
        } | null;
        promotionalTermsLink?: {
          __typename?: 'Link';
          name?: string | null;
          url?: string | null;
          isInternal?: boolean | null;
          label?: string | null;
          ariaLabel?: string | null;
          openInNewTab?: boolean | null;
          gaTrackName?: string | null;
          isDisplayedWhenLoggedIn?: boolean | null;
          color?: string | null;
          variant?: string | null;
          underline?: string | null;
          align?: string | null;
          noFollow?: boolean | null;
          image?: {
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null;
        } | null;
        ctaLinks?: {
          __typename?: 'CalculatorCtaLinksCollection';
          items: Array<{
            __typename?: 'Link';
            name?: string | null;
            url?: string | null;
            isInternal?: boolean | null;
            label?: string | null;
            ariaLabel?: string | null;
            openInNewTab?: boolean | null;
            gaTrackName?: string | null;
            isDisplayedWhenLoggedIn?: boolean | null;
            color?: string | null;
            variant?: string | null;
            underline?: string | null;
            align?: string | null;
            noFollow?: boolean | null;
            image?: {
              __typename?: 'Asset';
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
            } | null;
          } | null>;
        } | null;
      }
    | {
        __typename: 'Search';
        name?: string | null;
        heading?: string | null;
        inputLabel?: string | null;
        resultListLabel?: string | null;
        suggestionListLabel?: string | null;
        suggestionListCollection?: {
          __typename?: 'SearchSuggestionListCollection';
          items: Array<{
            __typename?: 'Page';
            name?: string | null;
            slug?: string | null;
            sys: { __typename?: 'Sys'; id: string };
          } | null>;
        } | null;
      }
    | {
        __typename: 'SendToCountryModule';
        name?: string | null;
        searchPlaceholder?: string | null;
        continueButtonText?: string | null;
        sys: { __typename?: 'Sys'; id: string };
        description?: {
          __typename?: 'SendToCountryModuleDescription';
          json: Document;
        } | null;
      }
    | null;
  headingRichText?: {
    __typename?: 'BetterWayTopHeading';
    json: Document;
  } | null;
  subHeadingRichText?: {
    __typename?: 'BetterWayTopSubheading';
    json: Document;
  } | null;
  link?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
  trustpilotLogo?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  trustpilotLink?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
  backgroundImage?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  backgroundImageMobile?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  calculatorPage?: {
    __typename?: 'CalculatorPage';
    slug?: string | null;
  } | null;
};

export type BetterWayTopQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryCode?: InputMaybe<Scalars['String']['input']>;
  receiveCountryCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type BetterWayTopQueryQuery = {
  __typename?: 'Query';
  BetterWayTop?: {
    __typename?: 'BetterWayTopCollection';
    items: Array<{
      __typename: 'BetterWayTop';
      name?: string | null;
      headingColor?: string | null;
      backgroundColor?: string | null;
      secondBackgroundColor?: string | null;
      hideDescriptionOnMobile?: boolean | null;
      trustpilotMessage?: string | null;
      contentAlignment?: string | null;
      contentFlexDirection?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      rightModule?:
        | {
            __typename?: 'Calculator';
            name?: string | null;
            sendLabel?: string | null;
            receiveLabel?: string | null;
            isLite?: boolean | null;
            payoutMethodsLabel?: string | null;
            countriesSearchPlaceholder?: string | null;
            unavailableCorridorErrorMessage?: string | null;
            airtimeTopupQuoteAvailableInApp?: string | null;
            exchangeRateLabel?: string | null;
            exchangeRatePromoLabel?: string | null;
            receiverPayoutMethodUnavailableMessage?: string | null;
            senderPayoutMethodUnavailableMessage?: string | null;
            generalErrorMessage?: string | null;
            partnersLabel?: string | null;
            partnersListDefaultValue?: string | null;
            partnerSelectErrorMessage?: string | null;
            phoneValidatorLabel?: string | null;
            phoneValidatorPlaceholder?: string | null;
            phoneInputInfoMoreInfoLabel?: string | null;
            phoneInputInfoPopupText?: string | null;
            phoneNumberInputErrorMessage?: string | null;
            phoneNumberInvalidErrorMessage?: string | null;
            topUpAmountsLabel?: string | null;
            topUpAmountsTitle?: string | null;
            topUpAmountsPlaceholder?: string | null;
            topUpAmountsSelectErrorMessage?: string | null;
            topUpAmountsSelectRetrievalErrorMessage?: string | null;
            feeLabel?: string | null;
            instantDiscountLabel?: string | null;
            transferTimeLabel?: string | null;
            totalToPayLabel?: string | null;
            blendedRateLabel?: string | null;
            phReceiveBankTransferMessage?: string | null;
            version?: string | null;
            loginLink?: { __typename?: 'Link'; url?: string | null } | null;
            signUpLink?: { __typename?: 'Link'; url?: string | null } | null;
            contentfulPayoutMethodsList?: {
              __typename?: 'CalculatorPayoutMethodsListCollection';
              items: Array<{
                __typename?: 'PayoutMethod';
                name?: string | null;
                description?: string | null;
                payoutMethodId?: string | null;
                transferTimeTooltip?: string | null;
              } | null>;
            } | null;
            promotionalTermsLink?: {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            } | null;
            ctaLinks?: {
              __typename?: 'CalculatorCtaLinksCollection';
              items: Array<{
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              } | null>;
            } | null;
          }
        | {
            __typename: 'Search';
            name?: string | null;
            heading?: string | null;
            inputLabel?: string | null;
            resultListLabel?: string | null;
            suggestionListLabel?: string | null;
            suggestionListCollection?: {
              __typename?: 'SearchSuggestionListCollection';
              items: Array<{
                __typename?: 'Page';
                name?: string | null;
                slug?: string | null;
                sys: { __typename?: 'Sys'; id: string };
              } | null>;
            } | null;
          }
        | {
            __typename: 'SendToCountryModule';
            name?: string | null;
            searchPlaceholder?: string | null;
            continueButtonText?: string | null;
            sys: { __typename?: 'Sys'; id: string };
            description?: {
              __typename?: 'SendToCountryModuleDescription';
              json: Document;
            } | null;
          }
        | null;
      headingRichText?: {
        __typename?: 'BetterWayTopHeading';
        json: Document;
      } | null;
      subHeadingRichText?: {
        __typename?: 'BetterWayTopSubheading';
        json: Document;
      } | null;
      link?: {
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
      trustpilotLogo?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      trustpilotLink?: {
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
      backgroundImage?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      backgroundImageMobile?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      calculatorPage?: {
        __typename?: 'CalculatorPage';
        slug?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type HowItWorksStepRelaunchFragment = {
  __typename?: 'HowItWorksStepRelaunch';
  name?: string | null;
  header?: string | null;
  description?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  image?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
};

export type HowDoesItWorkFragment = {
  __typename?: 'HowDoesItWork';
  name?: string | null;
  title?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  animationSteps?: {
    __typename?: 'HowDoesItWorkAnimationStepsCollection';
    items: Array<{
      __typename: 'HowItWorksStepRelaunch';
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
};

export type HowDoesItWorkQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryCode?: InputMaybe<Scalars['String']['input']>;
  receiveCountryCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type HowDoesItWorkQueryQuery = {
  __typename?: 'Query';
  HowDoesItWork?: {
    __typename?: 'HowDoesItWorkCollection';
    items: Array<{
      __typename?: 'HowDoesItWork';
      name?: string | null;
      title?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      animationSteps?: {
        __typename?: 'HowDoesItWorkAnimationStepsCollection';
        items: Array<{
          __typename: 'HowItWorksStepRelaunch';
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type HowItWorksStepRelaunchQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryCode?: InputMaybe<Scalars['String']['input']>;
  receiveCountryCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type HowItWorksStepRelaunchQueryQuery = {
  __typename?: 'Query';
  HowItWorksStepRelaunch?: {
    __typename?: 'HowItWorksStepRelaunchCollection';
    items: Array<{
      __typename?: 'HowItWorksStepRelaunch';
      name?: string | null;
      header?: string | null;
      description?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type WhyChooseReasonFragment = {
  __typename: 'WhyChooseReason';
  name?: string | null;
  header?: string | null;
  description?: string | null;
  imagePosition?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  image?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
};

export type WhyChooseFragment = {
  __typename: 'WhyChoose';
  name?: string | null;
  header?: string | null;
  backgroundColor?: string | null;
  secondBackgroundColor?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  reasons?: {
    __typename?: 'WhyChooseReasonsCollection';
    items: Array<{
      __typename: 'WhyChooseReason';
      name?: string | null;
      header?: string | null;
      description?: string | null;
      imagePosition?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type WhyChooseQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type WhyChooseQueryQuery = {
  __typename?: 'Query';
  WhyChoose?: {
    __typename: 'WhyChoose';
    name?: string | null;
    header?: string | null;
    backgroundColor?: string | null;
    secondBackgroundColor?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    reasons?: {
      __typename?: 'WhyChooseReasonsCollection';
      items: Array<{
        __typename: 'WhyChooseReason';
        name?: string | null;
        header?: string | null;
        description?: string | null;
        imagePosition?: string | null;
        sys: { __typename?: 'Sys'; id: string };
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type InformationDialogFragment = {
  __typename: 'InformationDialog';
  name?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  content?: { __typename?: 'InformationDialogContent'; json: Document } | null;
};

export type InformationDialogQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type InformationDialogQueryQuery = {
  __typename?: 'Query';
  InformationDialog?: {
    __typename: 'InformationDialog';
    name?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    content?: {
      __typename?: 'InformationDialogContent';
      json: Document;
    } | null;
  } | null;
};

export type LinkFragment = {
  __typename?: 'Link';
  name?: string | null;
  url?: string | null;
  isInternal?: boolean | null;
  label?: string | null;
  ariaLabel?: string | null;
  openInNewTab?: boolean | null;
  gaTrackName?: string | null;
  isDisplayedWhenLoggedIn?: boolean | null;
  color?: string | null;
  variant?: string | null;
  underline?: string | null;
  align?: string | null;
  noFollow?: boolean | null;
  image?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
};

export type LinkQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type LinkQueryQuery = {
  __typename?: 'Query';
  Link?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
};

export type ListOfCountriesFragment = {
  __typename: 'ListOfCountries';
  name?: string | null;
  header?: string | null;
  subheader?: string | null;
  buttonLabelExpandedList?: string | null;
  buttonLabelHiddenList?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  countriesCollection?: {
    __typename?: 'ListOfCountriesCountriesCollection';
    items: Array<{
      __typename?: 'Country';
      name?: string | null;
      code?: string | null;
      slug?: string | null;
    } | null>;
  } | null;
};

export type ListOfCountriesQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryCode?: InputMaybe<Scalars['String']['input']>;
  receiveCountryCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListOfCountriesQueryQuery = {
  __typename?: 'Query';
  ListOfCountries?: {
    __typename?: 'ListOfCountriesCollection';
    items: Array<{
      __typename: 'ListOfCountries';
      name?: string | null;
      header?: string | null;
      subheader?: string | null;
      buttonLabelExpandedList?: string | null;
      buttonLabelHiddenList?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      countriesCollection?: {
        __typename?: 'ListOfCountriesCountriesCollection';
        items: Array<{
          __typename?: 'Country';
          name?: string | null;
          code?: string | null;
          slug?: string | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type MessagesFragment = {
  __typename?: 'Messages';
  readMore?: string | null;
  readLess?: string | null;
  captchaPxLabel?: string | null;
  previous?: string | null;
  next?: string | null;
  chooseYourRegion?: string | null;
  countries?: string | null;
  international?: string | null;
  continue?: string | null;
  cancel?: string | null;
  close?: string | null;
  geoLocationBannerContent?: string | null;
  updated?: string | null;
  all?: string | null;
  autocompleteLabel?: string | null;
  noResults?: string | null;
  relatedPosts?: string | null;
  lifeabroad?: string | null;
  community?: string | null;
  education?: string | null;
  finance?: string | null;
  product?: string | null;
  referAFriendSendFromLabel?: string | null;
  locationLangLabel?: string | null;
  rafUrlUnavailable?: string | null;
  rafUrlInvalid?: string | null;
};

export type NotFoundContentFragment = {
  __typename: 'NotFoundContent';
  name?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  message?: { __typename?: 'NotFoundContentMessage'; json: Document } | null;
  buttonsCollection?: {
    __typename?: 'NotFoundContentButtonsCollection';
    items: Array<{
      __typename?: 'Link';
      name?: string | null;
      url?: string | null;
      isInternal?: boolean | null;
      label?: string | null;
      ariaLabel?: string | null;
      openInNewTab?: boolean | null;
      gaTrackName?: string | null;
      isDisplayedWhenLoggedIn?: boolean | null;
      color?: string | null;
      variant?: string | null;
      underline?: string | null;
      align?: string | null;
      noFollow?: boolean | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type NotFoundContentQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type NotFoundContentQueryQuery = {
  __typename?: 'Query';
  NotFoundContent?: {
    __typename?: 'NotFoundContentCollection';
    items: Array<{
      __typename: 'NotFoundContent';
      name?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      message?: {
        __typename?: 'NotFoundContentMessage';
        json: Document;
      } | null;
      buttonsCollection?: {
        __typename?: 'NotFoundContentButtonsCollection';
        items: Array<{
          __typename?: 'Link';
          name?: string | null;
          url?: string | null;
          isInternal?: boolean | null;
          label?: string | null;
          ariaLabel?: string | null;
          openInNewTab?: boolean | null;
          gaTrackName?: string | null;
          isDisplayedWhenLoggedIn?: boolean | null;
          color?: string | null;
          variant?: string | null;
          underline?: string | null;
          align?: string | null;
          noFollow?: boolean | null;
          image?: {
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type ContextualPromoBannerFragment = {
  __typename: 'ContextualPromoBanner';
  name?: string | null;
  backgroundColor?: string | null;
  disclaimer?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  description?: {
    __typename?: 'ContextualPromoBannerDescription';
    json: Document;
    links: {
      __typename?: 'ContextualPromoBannerDescriptionLinks';
      assets: {
        __typename?: 'ContextualPromoBannerDescriptionAssets';
        block: Array<{
          __typename?: 'Asset';
          contentType?: string | null;
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      };
    };
  } | null;
  link?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
  button?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
  verticalBackgroundImage?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  promoIcon?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
};

export type ContextualPromoBannerQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryCode?: InputMaybe<Scalars['String']['input']>;
  receiveCountryCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type ContextualPromoBannerQueryQuery = {
  __typename?: 'Query';
  ContextualPromoBanner?: {
    __typename?: 'ContextualPromoBannerCollection';
    items: Array<{
      __typename: 'ContextualPromoBanner';
      name?: string | null;
      backgroundColor?: string | null;
      disclaimer?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      description?: {
        __typename?: 'ContextualPromoBannerDescription';
        json: Document;
        links: {
          __typename?: 'ContextualPromoBannerDescriptionLinks';
          assets: {
            __typename?: 'ContextualPromoBannerDescriptionAssets';
            block: Array<{
              __typename?: 'Asset';
              contentType?: string | null;
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            } | null>;
          };
        };
      } | null;
      link?: {
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
      button?: {
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
      verticalBackgroundImage?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      promoIcon?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type PromotionalApplicabilityFragment = {
  __typename?: 'PromoApplicability';
  name?: string | null;
  visitorTypes?: Array<string | null> | null;
  showForCorridors?: Array<string | null> | null;
};

export type PromotionalBannerFragment = {
  __typename: 'PromotionalBanner';
  name?: string | null;
  addToInitialHtml?: boolean | null;
  sys: { __typename?: 'Sys'; id: string };
  bannerDescription?: {
    __typename?: 'PromotionalBannerDescription';
    json: Document;
  } | null;
  applicability?: {
    __typename?: 'PromoApplicability';
    name?: string | null;
    visitorTypes?: Array<string | null> | null;
    showForCorridors?: Array<string | null> | null;
  } | null;
};

export type PromotionalBannerQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type PromotionalBannerQueryQuery = {
  __typename?: 'Query';
  PromotionalBanner?: {
    __typename: 'PromotionalBanner';
    name?: string | null;
    addToInitialHtml?: boolean | null;
    sys: { __typename?: 'Sys'; id: string };
    bannerDescription?: {
      __typename?: 'PromotionalBannerDescription';
      json: Document;
    } | null;
    applicability?: {
      __typename?: 'PromoApplicability';
      name?: string | null;
      visitorTypes?: Array<string | null> | null;
      showForCorridors?: Array<string | null> | null;
    } | null;
  } | null;
};

export type ReferFriendHeaderFragment = {
  __typename: 'ReferFriendHeader';
  name?: string | null;
  heading?: string | null;
  subheading?: string | null;
  hintText?: string | null;
  textColor?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  backgroundImage?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  signedOutButton?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
};

export type ReferFriendHeaderQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ReferFriendHeaderQueryQuery = {
  __typename?: 'Query';
  ReferFriendHeader?: {
    __typename: 'ReferFriendHeader';
    name?: string | null;
    heading?: string | null;
    subheading?: string | null;
    hintText?: string | null;
    textColor?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    backgroundImage?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
    signedOutButton?: {
      __typename?: 'Link';
      name?: string | null;
      url?: string | null;
      isInternal?: boolean | null;
      label?: string | null;
      ariaLabel?: string | null;
      openInNewTab?: boolean | null;
      gaTrackName?: string | null;
      isDisplayedWhenLoggedIn?: boolean | null;
      color?: string | null;
      variant?: string | null;
      underline?: string | null;
      align?: string | null;
      noFollow?: boolean | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null;
  } | null;
};

export type RelatedPageFragment = {
  __typename: 'Page';
  name?: string | null;
  slug?: string | null;
  title?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  banner?: {
    __typename?: 'Asset';
    title?: string | null;
    url?: string | null;
    alt?: string | null;
  } | null;
};

export type ChangePasswordPageQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type ChangePasswordPageQuery = {
  __typename?: 'Query';
  changePasswordPageCollection?: {
    __typename?: 'ChangePasswordPageCollection';
    items: Array<{
      __typename?: 'ChangePasswordPage';
      name?: string | null;
      header?: string | null;
      label?: string | null;
      button?: string | null;
      validationLowerCaseLetterLabel?: string | null;
      validationMinimumCharactersLabel?: string | null;
      validationNumericNumberLabel?: string | null;
      validationUpperCaseLetterLabel?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      successInfo?: {
        __typename?: 'ActionResult';
        sys: { __typename?: 'Sys'; id: string };
      } | null;
      helpText?: {
        __typename?: 'ChangePasswordPageHelpText';
        json: Document;
      } | null;
    } | null>;
  } | null;
};

export type ReviewsSectionFragment = {
  __typename?: 'Reviews';
  name?: string | null;
  heading?: string | null;
  reviewsTotalScore?: number | null;
  carouselText?: string | null;
  reviewsNumberLabel?: string | null;
  hideTrustpilotHeader?: boolean | null;
  sys: { __typename?: 'Sys'; id: string };
  starIcon?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  avatarIcon?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  reviews?: {
    __typename?: 'ReviewsReviewsCollection';
    items: Array<{
      __typename?: 'Review';
      name?: string | null;
      comment?: string | null;
      stars?: number | null;
      countryCode?: string | null;
      reviewer?: string | null;
      reviewsNumberText?: string | null;
      reviewDate?: string | null;
    } | null>;
  } | null;
  trustpilotLink?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
  trustpilotLogo?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
};

export type ReviewsQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ReviewsQueryQuery = {
  __typename?: 'Query';
  Reviews?: {
    __typename?: 'Reviews';
    name?: string | null;
    heading?: string | null;
    reviewsTotalScore?: number | null;
    carouselText?: string | null;
    reviewsNumberLabel?: string | null;
    hideTrustpilotHeader?: boolean | null;
    sys: { __typename?: 'Sys'; id: string };
    starIcon?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
    avatarIcon?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
    reviews?: {
      __typename?: 'ReviewsReviewsCollection';
      items: Array<{
        __typename?: 'Review';
        name?: string | null;
        comment?: string | null;
        stars?: number | null;
        countryCode?: string | null;
        reviewer?: string | null;
        reviewsNumberText?: string | null;
        reviewDate?: string | null;
      } | null>;
    } | null;
    trustpilotLink?: {
      __typename?: 'Link';
      name?: string | null;
      url?: string | null;
      isInternal?: boolean | null;
      label?: string | null;
      ariaLabel?: string | null;
      openInNewTab?: boolean | null;
      gaTrackName?: string | null;
      isDisplayedWhenLoggedIn?: boolean | null;
      color?: string | null;
      variant?: string | null;
      underline?: string | null;
      align?: string | null;
      noFollow?: boolean | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null;
    trustpilotLogo?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
};

export type EmbeddedImageFragment = {
  __typename?: 'Image';
  name?: string | null;
  align?: string | null;
  position?: string | null;
  width?: number | null;
  height?: number | null;
  asset?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
};

export type IconWithTextFragment = {
  __typename?: 'IconWithText';
  name?: string | null;
  text?: {
    __typename?: 'TextWithColor';
    name?: string | null;
    inline?: boolean | null;
    color?: string | null;
    backgroundColor?: string | null;
    align?: string | null;
    text?: { __typename?: 'TextWithColorText'; json: Document } | null;
  } | null;
  icon?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
};

export type SendToCountryModuleFragment = {
  __typename: 'SendToCountryModule';
  name?: string | null;
  searchPlaceholder?: string | null;
  continueButtonText?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  description?: {
    __typename?: 'SendToCountryModuleDescription';
    json: Document;
  } | null;
};

export type SendToCountryModuleQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SendToCountryModuleQueryQuery = {
  __typename?: 'Query';
  SendToCountryModule?: {
    __typename?: 'SendToCountryModuleCollection';
    items: Array<{
      __typename: 'SendToCountryModule';
      name?: string | null;
      searchPlaceholder?: string | null;
      continueButtonText?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      description?: {
        __typename?: 'SendToCountryModuleDescription';
        json: Document;
      } | null;
    } | null>;
  } | null;
};

export type SitemapFragment = {
  __typename: 'Sitemap';
  name?: string | null;
  sys: { __typename?: 'Sys'; id: string };
};

export type SitemapQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SitemapQueryQuery = {
  __typename?: 'Query';
  Sitemap?: {
    __typename: 'Sitemap';
    name?: string | null;
    sys: { __typename?: 'Sys'; id: string };
  } | null;
};

export type StepFragment = {
  __typename?: 'Step';
  name?: string | null;
  header?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  description?: { __typename?: 'StepDescription'; json: Document } | null;
  image?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
};

export type StepByStepFragment = {
  __typename?: 'StepByStep';
  name?: string | null;
  header?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  stepsCollection?: {
    __typename?: 'StepByStepStepsCollection';
    items: Array<{
      __typename: 'Step';
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
  link?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
};

export type StepByStepQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryCode?: InputMaybe<Scalars['String']['input']>;
  receiveCountryCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type StepByStepQueryQuery = {
  __typename?: 'Query';
  StepByStep?: {
    __typename?: 'StepByStepCollection';
    items: Array<{
      __typename?: 'StepByStep';
      name?: string | null;
      header?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      stepsCollection?: {
        __typename?: 'StepByStepStepsCollection';
        items: Array<{
          __typename: 'Step';
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      } | null;
      link?: {
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type StepQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryCode?: InputMaybe<Scalars['String']['input']>;
  receiveCountryCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type StepQueryQuery = {
  __typename?: 'Query';
  Step?: {
    __typename?: 'StepCollection';
    items: Array<{
      __typename?: 'Step';
      name?: string | null;
      header?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      description?: { __typename?: 'StepDescription'; json: Document } | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type CountryInfoFragment = {
  __typename?: 'Country';
  name?: string | null;
  code?: string | null;
  slug?: string | null;
  article?: string | null;
  prepositionFrom?: string | null;
  prepositionIn?: string | null;
  sys: { __typename?: 'Sys'; id: string };
};

export type StickyBannerFragment = {
  __typename: 'StickyBanner';
  name?: string | null;
  textColor?: string | null;
  backgroundColor?: string | null;
  marketingChannel?: Array<string | null> | null;
  sys: { __typename?: 'Sys'; id: string };
  image?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  description?: {
    __typename?: 'StickyBannerDescription';
    json: Document;
  } | null;
  sendCountries?: {
    __typename?: 'StickyBannerSendCountriesCollection';
    items: Array<{
      __typename?: 'Country';
      name?: string | null;
      code?: string | null;
      slug?: string | null;
      article?: string | null;
      prepositionFrom?: string | null;
      prepositionIn?: string | null;
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
  receiveCountries?: {
    __typename?: 'StickyBannerReceiveCountriesCollection';
    items: Array<{
      __typename?: 'Country';
      name?: string | null;
      code?: string | null;
      slug?: string | null;
      article?: string | null;
      prepositionFrom?: string | null;
      prepositionIn?: string | null;
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
};

export type TextSectionFragment = {
  __typename: 'TextSection';
  name?: string | null;
  maxWidth?: string | null;
  backgroundColor?: string | null;
  isOrderReversed?: boolean | null;
  sys: { __typename?: 'Sys'; id: string };
  content?: {
    __typename?: 'TextSectionContent';
    json: Document;
    links: {
      __typename?: 'TextSectionContentLinks';
      assets: {
        __typename?: 'TextSectionContentAssets';
        block: Array<{
          __typename?: 'Asset';
          contentType?: string | null;
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      };
      entries: {
        __typename?: 'TextSectionContentEntries';
        block: Array<
          | {
              __typename?: 'AccountDuplicationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ActionResult';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReview';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReviews';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchool';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCostCategory';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCountryData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BlogContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexChartFilterOptions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexLineChart';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateAlerts';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ChangePasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContextualPromoBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CorrespondentLocations';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CountryPayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CurrencyPricingConverter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'EmbeddedTableEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ExternalScript';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelp';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelpItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ForgottenPasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HowDoesItWork';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowItWorksStepRelaunch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowSendingWorks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'InformationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashRequest';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LoginPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MembersList';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MfaFactors';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MfaVerification';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileStoreData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestConfirmationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPay';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransfer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransferCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OnfidoErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OurContactInformation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethodAlert';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhoneNumberVerificationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PriceComparsion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReferAFriendGreenfieldEnabledCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReferFriendHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Regulator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyNewRequestEmail';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Reviews'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SenderFormPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SendingPartnership';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ShareSocialLink';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SmsVerificationCodePage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SocialProofingModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SolutionsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'StickyBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TagLinks'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              name?: string | null;
              inline?: boolean | null;
              color?: string | null;
              backgroundColor?: string | null;
              align?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColorText';
                json: Document;
              } | null;
            }
          | {
              __typename?: 'VerificationInProgress';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'Video';
              name?: string | null;
              videoId?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              content?: { __typename?: 'VideoContent'; json: Document } | null;
              backgroundImage?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'WeAreRegulated';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        inline: Array<
          | {
              __typename?: 'AccountDuplicationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ActionResult';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReview';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReviews';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchool';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCostCategory';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCountryData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BlogContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexChartFilterOptions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexLineChart';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateAlerts';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ChangePasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContextualPromoBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CorrespondentLocations';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CountryPayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CurrencyPricingConverter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'EmbeddedTableEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ExternalScript';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              name?: string | null;
              title?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelp';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelpItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ForgottenPasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HowDoesItWork';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowItWorksStepRelaunch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowSendingWorks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'InformationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashRequest';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LoginPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MembersList';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MfaFactors';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MfaVerification';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileStoreData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestConfirmationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPay';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransfer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransferCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OnfidoErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OurContactInformation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethodAlert';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhoneNumberVerificationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PriceComparsion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReferAFriendGreenfieldEnabledCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReferFriendHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Regulator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyNewRequestEmail';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Reviews'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SenderFormPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SendingPartnership';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ShareSocialLink';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SmsVerificationCodePage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SocialProofingModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SolutionsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'StickyBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TagLinks'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              name?: string | null;
              inline?: boolean | null;
              color?: string | null;
              backgroundColor?: string | null;
              align?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColorText';
                json: Document;
              } | null;
            }
          | {
              __typename?: 'VerificationInProgress';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WeAreRegulated';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        hyperlink: Array<
          | {
              __typename?: 'AccountDuplicationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ActionResult';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReview';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReviews';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchool';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCostCategory';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCountryData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BlogContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexChartFilterOptions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexLineChart';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateAlerts';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ChangePasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContextualPromoBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CorrespondentLocations';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CountryPayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CurrencyPricingConverter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'EmbeddedTableEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ExternalScript';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelp';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelpItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ForgottenPasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HowDoesItWork';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowItWorksStepRelaunch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowSendingWorks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'InformationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashRequest';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LoginPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MembersList';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MfaFactors';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MfaVerification';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileStoreData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestConfirmationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPay';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransfer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransferCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OnfidoErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OurContactInformation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethodAlert';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhoneNumberVerificationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PriceComparsion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReferAFriendGreenfieldEnabledCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReferFriendHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Regulator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyNewRequestEmail';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Reviews'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SenderFormPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SendingPartnership';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ShareSocialLink';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SmsVerificationCodePage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SocialProofingModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SolutionsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'StickyBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TagLinks'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'TextSection';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'VerificationInProgress';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WeAreRegulated';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
      };
    };
  } | null;
  imageCollection?: {
    __typename?: 'AssetCollection';
    items: Array<{
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null>;
  } | null;
};

export type TextSectionQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryCode?: InputMaybe<Scalars['String']['input']>;
  receiveCountryCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type TextSectionQueryQuery = {
  __typename?: 'Query';
  TextSection?: {
    __typename?: 'TextSectionCollection';
    items: Array<{
      __typename: 'TextSection';
      name?: string | null;
      maxWidth?: string | null;
      backgroundColor?: string | null;
      isOrderReversed?: boolean | null;
      sys: { __typename?: 'Sys'; id: string };
      content?: {
        __typename?: 'TextSectionContent';
        json: Document;
        links: {
          __typename?: 'TextSectionContentLinks';
          assets: {
            __typename?: 'TextSectionContentAssets';
            block: Array<{
              __typename?: 'Asset';
              contentType?: string | null;
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            } | null>;
          };
          entries: {
            __typename?: 'TextSectionContentEntries';
            block: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename: 'Video';
                  name?: string | null;
                  videoId?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  content?: {
                    __typename?: 'VideoContent';
                    json: Document;
                  } | null;
                  backgroundImage?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            inline: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  name?: string | null;
                  title?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            hyperlink: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename: 'TextSection';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
      imageCollection?: {
        __typename?: 'AssetCollection';
        items: Array<{
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type TextWithColorFragment = {
  __typename?: 'TextWithColor';
  name?: string | null;
  inline?: boolean | null;
  color?: string | null;
  backgroundColor?: string | null;
  align?: string | null;
  text?: { __typename?: 'TextWithColorText'; json: Document } | null;
};

export type VideoFragment = {
  __typename: 'Video';
  name?: string | null;
  videoId?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  content?: { __typename?: 'VideoContent'; json: Document } | null;
  backgroundImage?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
};

export type VideoQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type VideoQueryQuery = {
  __typename?: 'Query';
  Video?: {
    __typename: 'Video';
    name?: string | null;
    videoId?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    content?: { __typename?: 'VideoContent'; json: Document } | null;
    backgroundImage?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
};

export type RegulatorFragment = {
  __typename: 'Regulator';
  name?: string | null;
  customRegulatorName?: string | null;
  customFlagCode?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  content?: { __typename?: 'RegulatorContent'; json: Document } | null;
  countriesListCollection?: {
    __typename?: 'RegulatorCountriesListCollection';
    items: Array<{
      __typename?: 'Country';
      code?: string | null;
      name?: string | null;
    } | null>;
  } | null;
};

export type WeAreRegulatedFragment = {
  __typename?: 'WeAreRegulated';
  name?: string | null;
  title?: string | null;
  description?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  regulatorsCollection?: {
    __typename?: 'WeAreRegulatedRegulatorsCollection';
    items: Array<{
      __typename: 'Regulator';
      name?: string | null;
      customRegulatorName?: string | null;
      customFlagCode?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      content?: { __typename?: 'RegulatorContent'; json: Document } | null;
      countriesListCollection?: {
        __typename?: 'RegulatorCountriesListCollection';
        items: Array<{
          __typename?: 'Country';
          code?: string | null;
          name?: string | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type WeAreRegulatedQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type WeAreRegulatedQueryQuery = {
  __typename?: 'Query';
  WeAreRegulated?: {
    __typename?: 'WeAreRegulated';
    name?: string | null;
    title?: string | null;
    description?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    regulatorsCollection?: {
      __typename?: 'WeAreRegulatedRegulatorsCollection';
      items: Array<{
        __typename: 'Regulator';
        name?: string | null;
        customRegulatorName?: string | null;
        customFlagCode?: string | null;
        sys: { __typename?: 'Sys'; id: string };
        content?: { __typename?: 'RegulatorContent'; json: Document } | null;
        countriesListCollection?: {
          __typename?: 'RegulatorCountriesListCollection';
          items: Array<{
            __typename?: 'Country';
            code?: string | null;
            name?: string | null;
          } | null>;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GetActionResultQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
}>;

export type GetActionResultQuery = {
  __typename?: 'Query';
  actionResult?: {
    __typename: 'ActionResult';
    description?: string | null;
    title?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    linksCollection?: {
      __typename?: 'ActionResultLinksCollection';
      items: Array<{
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GetCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCountriesQuery = {
  __typename?: 'Query';
  countryCollection?: {
    __typename?: 'CountryCollection';
    items: Array<{
      __typename?: 'Country';
      code?: string | null;
      slug?: string | null;
      name?: string | null;
      calculatorReceiveCountry?: {
        __typename?: 'Country';
        code?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type CountryArticlesAndPrepositionsQueryVariables = Exact<{
  countryCodes?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  locale: Scalars['String']['input'];
  preview: Scalars['Boolean']['input'];
}>;

export type CountryArticlesAndPrepositionsQuery = {
  __typename?: 'Query';
  countryCollection?: {
    __typename?: 'CountryCollection';
    items: Array<{
      __typename?: 'Country';
      name?: string | null;
      slug?: string | null;
      code?: string | null;
      article?: string | null;
      prepositionFrom?: string | null;
      prepositionTo?: string | null;
      prepositionIn?: string | null;
    } | null>;
  } | null;
};

export type GreenfieldLoyaltyCountriesQueryVariables = Exact<{
  locale: Scalars['String']['input'];
  preview: Scalars['Boolean']['input'];
}>;

export type GreenfieldLoyaltyCountriesQuery = {
  __typename?: 'Query';
  referAFriendGreenfieldEnabledCountriesCollection?: {
    __typename?: 'ReferAFriendGreenfieldEnabledCountriesCollection';
    items: Array<{
      __typename?: 'ReferAFriendGreenfieldEnabledCountries';
      name?: string | null;
      greenfieldCountriesCollection?: {
        __typename?: 'ReferAFriendGreenfieldEnabledCountriesGreenfieldCountriesCollection';
        items: Array<{
          __typename?: 'Country';
          name?: string | null;
          code?: string | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type CexCurrencyCorridorDataFragment = {
  __typename?: 'CexCurrencyCorridor';
  name?: string | null;
  pageSlug?: string | null;
  sendCurrency?: {
    __typename?: 'CexCurrency';
    name?: string | null;
    countryName?: string | null;
    currencyCode?: string | null;
    countryCode?: string | null;
    currencyName?: string | null;
    pageSlug?: string | null;
  } | null;
  receiveCurrency?: {
    __typename?: 'CexCurrency';
    name?: string | null;
    countryName?: string | null;
    currencyCode?: string | null;
    countryCode?: string | null;
    currencyName?: string | null;
    pageSlug?: string | null;
  } | null;
};

export type CexCurrencyCorridorFragment = {
  __typename?: 'CexCurrencyCorridor';
  name?: string | null;
  pageSlug?: string | null;
  sendCurrency?: {
    __typename?: 'CexCurrency';
    corridorGridTitle?: string | null;
    name?: string | null;
    countryName?: string | null;
    currencyCode?: string | null;
    countryCode?: string | null;
    currencyName?: string | null;
    pageSlug?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    description?: {
      __typename?: 'CexCurrencyDescription';
      json: Document;
      links: {
        __typename?: 'CexCurrencyDescriptionLinks';
        assets: {
          __typename?: 'CexCurrencyDescriptionAssets';
          block: Array<{
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null>;
        };
        entries: {
          __typename?: 'CexCurrencyDescriptionEntries';
          block: Array<
            | {
                __typename?: 'AccountDuplicationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ActionResult';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReview';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchool';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCostCategory';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCountryData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BetterWayTop';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BlogContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BodyText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Calculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'CexChartFilterOptions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrency';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrencyCorridor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexLineChart';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateAlerts';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexSearch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ChangePasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableCell';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableRow';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ContextualPromoBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CorrespondentLocations';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondents';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Country';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CountryPayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CurrencyPricingConverter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'EmbeddedTableEntry';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ExternalScript';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqHero';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqPopularQuestions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelp';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelpItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModuleItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FeatureFlag';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'FooterNavigationGroup';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FooterNavigationGroupExtras';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ForgottenPasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FullWidthBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'GenericErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HeroSlot';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowDoesItWork';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowItWorksStepRelaunch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowSendingWorks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'IconWithText';
                name?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                } | null;
                icon?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'Image';
                name?: string | null;
                align?: string | null;
                position?: string | null;
                width?: number | null;
                height?: number | null;
                sys: { __typename?: 'Sys'; id: string };
                asset?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'InformationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashRequest';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutFooter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LegalMessaging';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                sys: { __typename?: 'Sys'; id: string };
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'ListOfCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LoginPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'MembersList';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Messages';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaFactors';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaVerification';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileStoreData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestConfirmationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPay';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransfer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransferCard';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OnfidoErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OurContactInformation';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PageSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethodAlert';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PhoneNumberVerificationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PopularCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PriceComparsion';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PricingCalculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoApplicability';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoMessage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBannerDisclaimer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Redirect';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferFriendHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RegionCode';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Regulator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyNewRequestEmail';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Reviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReviewsTimeDictionary';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RobotsConfiguration';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'SendToCountryModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SenderFormPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SendingPartnership';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoContactPoint';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoOrganizationStructuredData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoSameAs';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ShareSocialLink';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Sitemap';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SmsVerificationCodePage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SocialProofingModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SolutionsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'StepByStep';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'StickyBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TagLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextMeTheAppModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              }
            | {
                __typename?: 'VerificationInProgress';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename: 'Video';
                name?: string | null;
                videoId?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                content?: {
                  __typename?: 'VideoContent';
                  json: Document;
                } | null;
                backgroundImage?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'WeAreRegulated';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WebForm';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChoose';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChooseReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | null
          >;
          inline: Array<
            | {
                __typename?: 'AccountDuplicationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ActionResult';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReview';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchool';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCostCategory';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCountryData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BetterWayTop';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BlogContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BodyText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Calculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'CexChartFilterOptions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrency';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrencyCorridor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexLineChart';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateAlerts';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexSearch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ChangePasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableCell';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableRow';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ContextualPromoBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CorrespondentLocations';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondents';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Country';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CountryPayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CurrencyPricingConverter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'EmbeddedTableEntry';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ExternalScript';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqHero';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqPopularQuestions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelp';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelpItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModuleItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FeatureFlag';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'FooterNavigationGroup';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FooterNavigationGroupExtras';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ForgottenPasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FullWidthBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'GenericErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HeroSlot';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowDoesItWork';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowItWorksStepRelaunch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowSendingWorks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'IconWithText';
                name?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                } | null;
                icon?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'InformationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashRequest';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutFooter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LegalMessaging';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                sys: { __typename?: 'Sys'; id: string };
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'ListOfCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LoginPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'MembersList';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Messages';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaFactors';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaVerification';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileStoreData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestConfirmationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPay';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransfer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransferCard';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OnfidoErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OurContactInformation';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PageSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethodAlert';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PhoneNumberVerificationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PopularCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PriceComparsion';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PricingCalculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoApplicability';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoMessage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBannerDisclaimer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Redirect';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferFriendHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RegionCode';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Regulator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyNewRequestEmail';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Reviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReviewsTimeDictionary';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RobotsConfiguration';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'SendToCountryModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SenderFormPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SendingPartnership';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoContactPoint';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoOrganizationStructuredData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoSameAs';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ShareSocialLink';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Sitemap';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SmsVerificationCodePage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SocialProofingModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SolutionsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'StepByStep';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'StickyBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TagLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextMeTheAppModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              }
            | {
                __typename?: 'VerificationInProgress';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'WeAreRegulated';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WebForm';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChoose';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChooseReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | null
          >;
          hyperlink: Array<
            | {
                __typename?: 'AccountDuplicationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ActionResult';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReview';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchool';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCostCategory';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCountryData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BetterWayTop';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BlogContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BodyText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Calculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'CexChartFilterOptions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrency';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrencyCorridor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexLineChart';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateAlerts';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexSearch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ChangePasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableCell';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableRow';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ContextualPromoBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CorrespondentLocations';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondents';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Country';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CountryPayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CurrencyPricingConverter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'EmbeddedTableEntry';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ExternalScript';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqHero';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqPopularQuestions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelp';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelpItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModuleItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FeatureFlag';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'FooterNavigationGroup';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FooterNavigationGroupExtras';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ForgottenPasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FullWidthBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'GenericErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HeroSlot';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowDoesItWork';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowItWorksStepRelaunch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowSendingWorks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'IconWithText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'InformationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashRequest';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutFooter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LegalMessaging';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                sys: { __typename?: 'Sys'; id: string };
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'ListOfCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LoginPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'MembersList';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Messages';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaFactors';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaVerification';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileStoreData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestConfirmationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPay';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransfer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransferCard';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OnfidoErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OurContactInformation';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PageSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethodAlert';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PhoneNumberVerificationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PopularCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PriceComparsion';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PricingCalculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoApplicability';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoMessage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBannerDisclaimer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Redirect';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferFriendHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RegionCode';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Regulator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyNewRequestEmail';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Reviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReviewsTimeDictionary';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RobotsConfiguration';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'SendToCountryModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SenderFormPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SendingPartnership';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoContactPoint';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoOrganizationStructuredData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoSameAs';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ShareSocialLink';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Sitemap';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SmsVerificationCodePage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SocialProofingModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SolutionsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'StepByStep';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'StickyBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TagLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextMeTheAppModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextWithColor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'VerificationInProgress';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'WeAreRegulated';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WebForm';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChoose';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChooseReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | null
          >;
        };
      };
    } | null;
    faqContent?: {
      __typename?: 'CexCurrencyFaqContent';
      json: Document;
      links: {
        __typename?: 'CexCurrencyFaqContentLinks';
        assets: {
          __typename?: 'CexCurrencyFaqContentAssets';
          block: Array<{
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null>;
        };
        entries: {
          __typename?: 'CexCurrencyFaqContentEntries';
          block: Array<
            | {
                __typename?: 'AccountDuplicationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ActionResult';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReview';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchool';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCostCategory';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCountryData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BetterWayTop';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BlogContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BodyText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Calculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'CexChartFilterOptions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrency';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrencyCorridor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexLineChart';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateAlerts';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexSearch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ChangePasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableCell';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableRow';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ContextualPromoBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CorrespondentLocations';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondents';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Country';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CountryPayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CurrencyPricingConverter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'EmbeddedTableEntry';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ExternalScript';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqHero';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqPopularQuestions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelp';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelpItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModuleItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FeatureFlag';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'FooterNavigationGroup';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FooterNavigationGroupExtras';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ForgottenPasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FullWidthBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'GenericErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HeroSlot';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowDoesItWork';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowItWorksStepRelaunch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowSendingWorks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'IconWithText';
                name?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                } | null;
                icon?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'Image';
                name?: string | null;
                align?: string | null;
                position?: string | null;
                width?: number | null;
                height?: number | null;
                sys: { __typename?: 'Sys'; id: string };
                asset?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'InformationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashRequest';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutFooter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LegalMessaging';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                sys: { __typename?: 'Sys'; id: string };
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'ListOfCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LoginPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'MembersList';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Messages';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaFactors';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaVerification';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileStoreData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestConfirmationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPay';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransfer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransferCard';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OnfidoErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OurContactInformation';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PageSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethodAlert';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PhoneNumberVerificationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PopularCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PriceComparsion';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PricingCalculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoApplicability';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoMessage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBannerDisclaimer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Redirect';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferFriendHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RegionCode';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Regulator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyNewRequestEmail';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Reviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReviewsTimeDictionary';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RobotsConfiguration';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'SendToCountryModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SenderFormPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SendingPartnership';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoContactPoint';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoOrganizationStructuredData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoSameAs';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ShareSocialLink';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Sitemap';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SmsVerificationCodePage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SocialProofingModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SolutionsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'StepByStep';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'StickyBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TagLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextMeTheAppModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              }
            | {
                __typename?: 'VerificationInProgress';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename: 'Video';
                name?: string | null;
                videoId?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                content?: {
                  __typename?: 'VideoContent';
                  json: Document;
                } | null;
                backgroundImage?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'WeAreRegulated';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WebForm';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChoose';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChooseReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | null
          >;
          inline: Array<
            | {
                __typename?: 'AccountDuplicationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ActionResult';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReview';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchool';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCostCategory';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCountryData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BetterWayTop';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BlogContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BodyText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Calculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'CexChartFilterOptions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrency';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrencyCorridor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexLineChart';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateAlerts';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexSearch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ChangePasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableCell';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableRow';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ContextualPromoBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CorrespondentLocations';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondents';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Country';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CountryPayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CurrencyPricingConverter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'EmbeddedTableEntry';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ExternalScript';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqHero';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqPopularQuestions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelp';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelpItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModuleItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FeatureFlag';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'FooterNavigationGroup';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FooterNavigationGroupExtras';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ForgottenPasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FullWidthBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'GenericErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HeroSlot';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowDoesItWork';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowItWorksStepRelaunch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowSendingWorks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'IconWithText';
                name?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                } | null;
                icon?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'InformationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashRequest';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutFooter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LegalMessaging';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                sys: { __typename?: 'Sys'; id: string };
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'ListOfCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LoginPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'MembersList';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Messages';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaFactors';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaVerification';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileStoreData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestConfirmationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPay';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransfer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransferCard';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OnfidoErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OurContactInformation';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PageSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethodAlert';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PhoneNumberVerificationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PopularCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PriceComparsion';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PricingCalculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoApplicability';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoMessage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBannerDisclaimer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Redirect';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferFriendHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RegionCode';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Regulator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyNewRequestEmail';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Reviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReviewsTimeDictionary';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RobotsConfiguration';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'SendToCountryModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SenderFormPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SendingPartnership';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoContactPoint';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoOrganizationStructuredData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoSameAs';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ShareSocialLink';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Sitemap';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SmsVerificationCodePage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SocialProofingModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SolutionsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'StepByStep';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'StickyBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TagLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextMeTheAppModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              }
            | {
                __typename?: 'VerificationInProgress';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'WeAreRegulated';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WebForm';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChoose';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChooseReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | null
          >;
          hyperlink: Array<
            | {
                __typename?: 'AccountDuplicationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ActionResult';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReview';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchool';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCostCategory';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCountryData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BetterWayTop';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BlogContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BodyText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Calculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'CexChartFilterOptions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrency';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrencyCorridor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexLineChart';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateAlerts';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexSearch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ChangePasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableCell';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableRow';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ContextualPromoBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CorrespondentLocations';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondents';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Country';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CountryPayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CurrencyPricingConverter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'EmbeddedTableEntry';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ExternalScript';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqHero';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqPopularQuestions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelp';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelpItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModuleItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FeatureFlag';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'FooterNavigationGroup';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FooterNavigationGroupExtras';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ForgottenPasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FullWidthBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'GenericErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HeroSlot';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowDoesItWork';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowItWorksStepRelaunch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowSendingWorks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'IconWithText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'InformationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashRequest';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutFooter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LegalMessaging';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                sys: { __typename?: 'Sys'; id: string };
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'ListOfCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LoginPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'MembersList';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Messages';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaFactors';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaVerification';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileStoreData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestConfirmationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPay';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransfer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransferCard';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OnfidoErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OurContactInformation';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PageSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethodAlert';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PhoneNumberVerificationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PopularCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PriceComparsion';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PricingCalculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoApplicability';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoMessage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBannerDisclaimer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Redirect';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferFriendHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RegionCode';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Regulator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyNewRequestEmail';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Reviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReviewsTimeDictionary';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RobotsConfiguration';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'SendToCountryModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SenderFormPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SendingPartnership';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoContactPoint';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoOrganizationStructuredData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoSameAs';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ShareSocialLink';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Sitemap';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SmsVerificationCodePage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SocialProofingModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SolutionsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'StepByStep';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'StickyBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TagLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextMeTheAppModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextWithColor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'VerificationInProgress';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'WeAreRegulated';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WebForm';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChoose';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChooseReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | null
          >;
        };
      };
    } | null;
    topCurrencyPairs?: {
      __typename?: 'CexCurrencyTopCurrencyPairsCollection';
      items: Array<{
        __typename?: 'CexCurrency';
        name?: string | null;
        pageSlug?: string | null;
        countryCode?: string | null;
        countryName?: string | null;
        currencyCode?: string | null;
        currencyName?: string | null;
        description?: {
          __typename?: 'CexCurrencyDescription';
          json: Document;
          links: {
            __typename?: 'CexCurrencyDescriptionLinks';
            assets: {
              __typename?: 'CexCurrencyDescriptionAssets';
              block: Array<{
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null>;
            };
            entries: {
              __typename?: 'CexCurrencyDescriptionEntries';
              block: Array<
                | {
                    __typename?: 'AccountDuplicationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ActionResult';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReview';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchool';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCostCategory';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCountryData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BlogContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexChartFilterOptions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexLineChart';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateAlerts';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ChangePasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContextualPromoBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CorrespondentLocations';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountryPayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CurrencyPricingConverter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'EmbeddedTableEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExternalScript';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelp';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelpItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ForgottenPasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowDoesItWork';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowItWorksStepRelaunch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowSendingWorks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    name?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    } | null;
                    icon?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'Image';
                    name?: string | null;
                    align?: string | null;
                    position?: string | null;
                    width?: number | null;
                    height?: number | null;
                    sys: { __typename?: 'Sys'; id: string };
                    asset?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'InformationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashRequest';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LoginPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Logo';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Member';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MembersList';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaFactors';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaVerification';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileStoreData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestConfirmationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPay';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransfer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransferCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OnfidoErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OurContactInformation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethodAlert';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhoneNumberVerificationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PriceComparsion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferFriendHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Regulator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyNewRequestEmail';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Review';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Reviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SenderFormPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendingPartnership';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ShareSocialLink';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SignIn';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SmsVerificationCodePage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SocialProofingModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SolutionsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StickyBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TagLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  }
                | {
                    __typename?: 'VerificationInProgress';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename: 'Video';
                    name?: string | null;
                    videoId?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    content?: {
                      __typename?: 'VideoContent';
                      json: Document;
                    } | null;
                    backgroundImage?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'WeAreRegulated';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
              inline: Array<
                | {
                    __typename?: 'AccountDuplicationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ActionResult';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReview';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchool';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCostCategory';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCountryData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BlogContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexChartFilterOptions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexLineChart';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateAlerts';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ChangePasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContextualPromoBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CorrespondentLocations';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountryPayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CurrencyPricingConverter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'EmbeddedTableEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExternalScript';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelp';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelpItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ForgottenPasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowDoesItWork';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowItWorksStepRelaunch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowSendingWorks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    name?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    } | null;
                    icon?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'Image';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'InformationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashRequest';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LoginPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Logo';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Member';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MembersList';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaFactors';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaVerification';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileStoreData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestConfirmationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPay';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransfer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransferCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OnfidoErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OurContactInformation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethodAlert';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhoneNumberVerificationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PriceComparsion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferFriendHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Regulator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyNewRequestEmail';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Review';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Reviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SenderFormPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendingPartnership';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ShareSocialLink';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SignIn';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SmsVerificationCodePage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SocialProofingModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SolutionsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StickyBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TagLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  }
                | {
                    __typename?: 'VerificationInProgress';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Video';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WeAreRegulated';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
              hyperlink: Array<
                | {
                    __typename?: 'AccountDuplicationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ActionResult';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReview';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchool';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCostCategory';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCountryData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BlogContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexChartFilterOptions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexLineChart';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateAlerts';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ChangePasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContextualPromoBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CorrespondentLocations';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountryPayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CurrencyPricingConverter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'EmbeddedTableEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExternalScript';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelp';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelpItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ForgottenPasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowDoesItWork';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowItWorksStepRelaunch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowSendingWorks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Image';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'InformationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashRequest';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LoginPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Logo';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Member';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MembersList';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaFactors';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaVerification';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileStoreData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestConfirmationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPay';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransfer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransferCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OnfidoErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OurContactInformation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethodAlert';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhoneNumberVerificationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PriceComparsion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferFriendHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Regulator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyNewRequestEmail';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Review';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Reviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SenderFormPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendingPartnership';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ShareSocialLink';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SignIn';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SmsVerificationCodePage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SocialProofingModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SolutionsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StickyBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TagLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'VerificationInProgress';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Video';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WeAreRegulated';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
            };
          };
        } | null;
      } | null>;
    } | null;
  } | null;
  receiveCurrency?: {
    __typename?: 'CexCurrency';
    corridorGridTitle?: string | null;
    name?: string | null;
    countryName?: string | null;
    currencyCode?: string | null;
    countryCode?: string | null;
    currencyName?: string | null;
    pageSlug?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    description?: {
      __typename?: 'CexCurrencyDescription';
      json: Document;
      links: {
        __typename?: 'CexCurrencyDescriptionLinks';
        assets: {
          __typename?: 'CexCurrencyDescriptionAssets';
          block: Array<{
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null>;
        };
        entries: {
          __typename?: 'CexCurrencyDescriptionEntries';
          block: Array<
            | {
                __typename?: 'AccountDuplicationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ActionResult';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReview';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchool';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCostCategory';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCountryData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BetterWayTop';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BlogContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BodyText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Calculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'CexChartFilterOptions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrency';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrencyCorridor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexLineChart';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateAlerts';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexSearch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ChangePasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableCell';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableRow';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ContextualPromoBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CorrespondentLocations';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondents';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Country';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CountryPayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CurrencyPricingConverter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'EmbeddedTableEntry';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ExternalScript';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqHero';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqPopularQuestions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelp';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelpItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModuleItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FeatureFlag';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'FooterNavigationGroup';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FooterNavigationGroupExtras';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ForgottenPasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FullWidthBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'GenericErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HeroSlot';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowDoesItWork';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowItWorksStepRelaunch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowSendingWorks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'IconWithText';
                name?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                } | null;
                icon?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'Image';
                name?: string | null;
                align?: string | null;
                position?: string | null;
                width?: number | null;
                height?: number | null;
                sys: { __typename?: 'Sys'; id: string };
                asset?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'InformationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashRequest';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutFooter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LegalMessaging';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                sys: { __typename?: 'Sys'; id: string };
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'ListOfCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LoginPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'MembersList';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Messages';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaFactors';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaVerification';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileStoreData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestConfirmationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPay';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransfer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransferCard';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OnfidoErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OurContactInformation';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PageSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethodAlert';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PhoneNumberVerificationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PopularCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PriceComparsion';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PricingCalculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoApplicability';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoMessage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBannerDisclaimer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Redirect';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferFriendHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RegionCode';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Regulator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyNewRequestEmail';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Reviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReviewsTimeDictionary';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RobotsConfiguration';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'SendToCountryModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SenderFormPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SendingPartnership';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoContactPoint';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoOrganizationStructuredData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoSameAs';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ShareSocialLink';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Sitemap';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SmsVerificationCodePage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SocialProofingModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SolutionsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'StepByStep';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'StickyBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TagLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextMeTheAppModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              }
            | {
                __typename?: 'VerificationInProgress';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename: 'Video';
                name?: string | null;
                videoId?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                content?: {
                  __typename?: 'VideoContent';
                  json: Document;
                } | null;
                backgroundImage?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'WeAreRegulated';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WebForm';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChoose';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChooseReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | null
          >;
          inline: Array<
            | {
                __typename?: 'AccountDuplicationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ActionResult';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReview';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchool';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCostCategory';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCountryData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BetterWayTop';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BlogContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BodyText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Calculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'CexChartFilterOptions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrency';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrencyCorridor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexLineChart';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateAlerts';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexSearch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ChangePasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableCell';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableRow';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ContextualPromoBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CorrespondentLocations';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondents';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Country';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CountryPayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CurrencyPricingConverter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'EmbeddedTableEntry';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ExternalScript';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqHero';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqPopularQuestions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelp';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelpItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModuleItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FeatureFlag';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'FooterNavigationGroup';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FooterNavigationGroupExtras';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ForgottenPasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FullWidthBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'GenericErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HeroSlot';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowDoesItWork';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowItWorksStepRelaunch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowSendingWorks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'IconWithText';
                name?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                } | null;
                icon?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'InformationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashRequest';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutFooter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LegalMessaging';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                sys: { __typename?: 'Sys'; id: string };
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'ListOfCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LoginPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'MembersList';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Messages';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaFactors';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaVerification';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileStoreData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestConfirmationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPay';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransfer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransferCard';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OnfidoErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OurContactInformation';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PageSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethodAlert';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PhoneNumberVerificationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PopularCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PriceComparsion';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PricingCalculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoApplicability';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoMessage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBannerDisclaimer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Redirect';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferFriendHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RegionCode';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Regulator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyNewRequestEmail';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Reviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReviewsTimeDictionary';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RobotsConfiguration';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'SendToCountryModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SenderFormPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SendingPartnership';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoContactPoint';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoOrganizationStructuredData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoSameAs';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ShareSocialLink';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Sitemap';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SmsVerificationCodePage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SocialProofingModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SolutionsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'StepByStep';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'StickyBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TagLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextMeTheAppModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              }
            | {
                __typename?: 'VerificationInProgress';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'WeAreRegulated';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WebForm';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChoose';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChooseReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | null
          >;
          hyperlink: Array<
            | {
                __typename?: 'AccountDuplicationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ActionResult';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReview';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchool';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCostCategory';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCountryData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BetterWayTop';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BlogContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BodyText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Calculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'CexChartFilterOptions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrency';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrencyCorridor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexLineChart';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateAlerts';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexSearch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ChangePasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableCell';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableRow';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ContextualPromoBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CorrespondentLocations';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondents';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Country';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CountryPayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CurrencyPricingConverter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'EmbeddedTableEntry';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ExternalScript';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqHero';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqPopularQuestions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelp';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelpItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModuleItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FeatureFlag';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'FooterNavigationGroup';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FooterNavigationGroupExtras';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ForgottenPasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FullWidthBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'GenericErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HeroSlot';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowDoesItWork';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowItWorksStepRelaunch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowSendingWorks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'IconWithText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'InformationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashRequest';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutFooter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LegalMessaging';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                sys: { __typename?: 'Sys'; id: string };
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'ListOfCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LoginPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'MembersList';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Messages';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaFactors';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaVerification';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileStoreData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestConfirmationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPay';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransfer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransferCard';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OnfidoErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OurContactInformation';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PageSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethodAlert';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PhoneNumberVerificationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PopularCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PriceComparsion';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PricingCalculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoApplicability';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoMessage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBannerDisclaimer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Redirect';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferFriendHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RegionCode';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Regulator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyNewRequestEmail';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Reviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReviewsTimeDictionary';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RobotsConfiguration';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'SendToCountryModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SenderFormPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SendingPartnership';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoContactPoint';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoOrganizationStructuredData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoSameAs';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ShareSocialLink';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Sitemap';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SmsVerificationCodePage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SocialProofingModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SolutionsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'StepByStep';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'StickyBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TagLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextMeTheAppModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextWithColor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'VerificationInProgress';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'WeAreRegulated';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WebForm';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChoose';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChooseReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | null
          >;
        };
      };
    } | null;
    faqContent?: {
      __typename?: 'CexCurrencyFaqContent';
      json: Document;
      links: {
        __typename?: 'CexCurrencyFaqContentLinks';
        assets: {
          __typename?: 'CexCurrencyFaqContentAssets';
          block: Array<{
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null>;
        };
        entries: {
          __typename?: 'CexCurrencyFaqContentEntries';
          block: Array<
            | {
                __typename?: 'AccountDuplicationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ActionResult';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReview';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchool';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCostCategory';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCountryData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BetterWayTop';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BlogContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BodyText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Calculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'CexChartFilterOptions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrency';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrencyCorridor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexLineChart';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateAlerts';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexSearch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ChangePasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableCell';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableRow';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ContextualPromoBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CorrespondentLocations';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondents';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Country';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CountryPayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CurrencyPricingConverter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'EmbeddedTableEntry';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ExternalScript';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqHero';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqPopularQuestions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelp';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelpItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModuleItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FeatureFlag';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'FooterNavigationGroup';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FooterNavigationGroupExtras';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ForgottenPasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FullWidthBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'GenericErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HeroSlot';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowDoesItWork';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowItWorksStepRelaunch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowSendingWorks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'IconWithText';
                name?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                } | null;
                icon?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'Image';
                name?: string | null;
                align?: string | null;
                position?: string | null;
                width?: number | null;
                height?: number | null;
                sys: { __typename?: 'Sys'; id: string };
                asset?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'InformationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashRequest';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutFooter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LegalMessaging';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                sys: { __typename?: 'Sys'; id: string };
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'ListOfCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LoginPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'MembersList';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Messages';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaFactors';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaVerification';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileStoreData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestConfirmationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPay';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransfer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransferCard';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OnfidoErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OurContactInformation';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PageSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethodAlert';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PhoneNumberVerificationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PopularCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PriceComparsion';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PricingCalculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoApplicability';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoMessage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBannerDisclaimer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Redirect';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferFriendHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RegionCode';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Regulator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyNewRequestEmail';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Reviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReviewsTimeDictionary';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RobotsConfiguration';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'SendToCountryModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SenderFormPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SendingPartnership';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoContactPoint';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoOrganizationStructuredData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoSameAs';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ShareSocialLink';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Sitemap';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SmsVerificationCodePage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SocialProofingModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SolutionsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'StepByStep';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'StickyBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TagLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextMeTheAppModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              }
            | {
                __typename?: 'VerificationInProgress';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename: 'Video';
                name?: string | null;
                videoId?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                content?: {
                  __typename?: 'VideoContent';
                  json: Document;
                } | null;
                backgroundImage?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'WeAreRegulated';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WebForm';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChoose';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChooseReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | null
          >;
          inline: Array<
            | {
                __typename?: 'AccountDuplicationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ActionResult';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReview';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchool';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCostCategory';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCountryData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BetterWayTop';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BlogContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BodyText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Calculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'CexChartFilterOptions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrency';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrencyCorridor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexLineChart';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateAlerts';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexSearch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ChangePasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableCell';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableRow';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ContextualPromoBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CorrespondentLocations';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondents';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Country';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CountryPayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CurrencyPricingConverter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'EmbeddedTableEntry';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ExternalScript';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqHero';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqPopularQuestions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelp';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelpItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModuleItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FeatureFlag';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'FooterNavigationGroup';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FooterNavigationGroupExtras';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ForgottenPasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FullWidthBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'GenericErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HeroSlot';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowDoesItWork';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowItWorksStepRelaunch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowSendingWorks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'IconWithText';
                name?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                } | null;
                icon?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'InformationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashRequest';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutFooter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LegalMessaging';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                sys: { __typename?: 'Sys'; id: string };
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'ListOfCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LoginPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'MembersList';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Messages';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaFactors';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaVerification';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileStoreData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestConfirmationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPay';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransfer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransferCard';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OnfidoErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OurContactInformation';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PageSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethodAlert';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PhoneNumberVerificationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PopularCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PriceComparsion';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PricingCalculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoApplicability';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoMessage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBannerDisclaimer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Redirect';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferFriendHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RegionCode';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Regulator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyNewRequestEmail';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Reviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReviewsTimeDictionary';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RobotsConfiguration';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'SendToCountryModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SenderFormPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SendingPartnership';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoContactPoint';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoOrganizationStructuredData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoSameAs';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ShareSocialLink';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Sitemap';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SmsVerificationCodePage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SocialProofingModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SolutionsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'StepByStep';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'StickyBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TagLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextMeTheAppModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                sys: { __typename?: 'Sys'; id: string };
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              }
            | {
                __typename?: 'VerificationInProgress';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'WeAreRegulated';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WebForm';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChoose';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChooseReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | null
          >;
          hyperlink: Array<
            | {
                __typename?: 'AccountDuplicationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ActionResult';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReview';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'AppReviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchool';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCostCategory';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BackToSchoolCountryData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BetterWayTop';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BlogContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'BodyText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Calculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CalculatorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'CexChartFilterOptions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrency';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexCurrencyCorridor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexLineChart';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateAlerts';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexRateTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CexSearch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ChangePasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTable';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableCell';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ComparisonTableRow';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ContextualPromoBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CorrespondentLocations';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Correspondents';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Country';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CountryPayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'CurrencyPricingConverter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'EmbeddedTableEntry';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ExternalScript';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqFeedbackModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqHero';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqModuleItem';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqPopularQuestions';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelp';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqSearchHelpItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FaqTopicsModuleItems';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FeatureFlag';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'FooterNavigationGroup';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FooterNavigationGroupExtras';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ForgottenPasswordPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'FullWidthBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'GenericErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HeroSlot';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowDoesItWork';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowItWorksStepRelaunch';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'HowSendingWorks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'IconWithText';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'InformationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'JazzCashRequest';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutFooter';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LayoutHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LegalMessaging';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                sys: { __typename?: 'Sys'; id: string };
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              }
            | {
                __typename?: 'ListOfCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'LoginPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
            | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'MembersList';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Messages';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaFactors';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MfaVerification';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileAppLeadingLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MobileStoreData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestConfirmationDialog';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPay';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyRequestPaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransfer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'MoneyTransferCard';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundContent';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'NotFoundPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OnfidoErrorPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'OurContactInformation';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PageSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PaymentReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethod';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PayoutMethodAlert';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'PhoneNumberVerificationPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PopularCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PriceComparsion';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PricingCalculator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoApplicability';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromoMessage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'PromotionalBannerDisclaimer';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Redirect';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReferFriendHeader';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RegionCode';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'Regulator';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyNewRequestEmail';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RequestMoneyPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Reviews';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ReviewsTimeDictionary';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'RobotsConfiguration';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'SendToCountryModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SenderFormPage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SendingPartnership';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoContactPoint';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoOrganizationStructuredData';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SeoSameAs';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'ShareSocialLink';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'Sitemap';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SmsVerificationCodePage';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SocialProofingModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'SolutionsModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'StepByStep';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'StickyBanner';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TagLinks';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextMeTheAppModule';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextSection';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'TextWithColor';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'VerificationInProgress';
                sys: { __typename?: 'Sys'; id: string };
              }
            | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
            | {
                __typename?: 'WeAreRegulated';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WebForm';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChoose';
                sys: { __typename?: 'Sys'; id: string };
              }
            | {
                __typename?: 'WhyChooseReason';
                sys: { __typename?: 'Sys'; id: string };
              }
            | null
          >;
        };
      };
    } | null;
    topCurrencyPairs?: {
      __typename?: 'CexCurrencyTopCurrencyPairsCollection';
      items: Array<{
        __typename?: 'CexCurrency';
        name?: string | null;
        pageSlug?: string | null;
        countryCode?: string | null;
        countryName?: string | null;
        currencyCode?: string | null;
        currencyName?: string | null;
        description?: {
          __typename?: 'CexCurrencyDescription';
          json: Document;
          links: {
            __typename?: 'CexCurrencyDescriptionLinks';
            assets: {
              __typename?: 'CexCurrencyDescriptionAssets';
              block: Array<{
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null>;
            };
            entries: {
              __typename?: 'CexCurrencyDescriptionEntries';
              block: Array<
                | {
                    __typename?: 'AccountDuplicationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ActionResult';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReview';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchool';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCostCategory';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCountryData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BlogContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexChartFilterOptions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexLineChart';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateAlerts';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ChangePasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContextualPromoBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CorrespondentLocations';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountryPayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CurrencyPricingConverter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'EmbeddedTableEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExternalScript';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelp';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelpItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ForgottenPasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowDoesItWork';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowItWorksStepRelaunch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowSendingWorks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    name?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    } | null;
                    icon?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'Image';
                    name?: string | null;
                    align?: string | null;
                    position?: string | null;
                    width?: number | null;
                    height?: number | null;
                    sys: { __typename?: 'Sys'; id: string };
                    asset?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'InformationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashRequest';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LoginPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Logo';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Member';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MembersList';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaFactors';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaVerification';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileStoreData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestConfirmationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPay';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransfer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransferCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OnfidoErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OurContactInformation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethodAlert';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhoneNumberVerificationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PriceComparsion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferFriendHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Regulator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyNewRequestEmail';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Review';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Reviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SenderFormPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendingPartnership';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ShareSocialLink';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SignIn';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SmsVerificationCodePage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SocialProofingModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SolutionsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StickyBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TagLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  }
                | {
                    __typename?: 'VerificationInProgress';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename: 'Video';
                    name?: string | null;
                    videoId?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    content?: {
                      __typename?: 'VideoContent';
                      json: Document;
                    } | null;
                    backgroundImage?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'WeAreRegulated';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
              inline: Array<
                | {
                    __typename?: 'AccountDuplicationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ActionResult';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReview';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchool';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCostCategory';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCountryData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BlogContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexChartFilterOptions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexLineChart';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateAlerts';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ChangePasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContextualPromoBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CorrespondentLocations';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountryPayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CurrencyPricingConverter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'EmbeddedTableEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExternalScript';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelp';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelpItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ForgottenPasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowDoesItWork';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowItWorksStepRelaunch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowSendingWorks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    name?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    } | null;
                    icon?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'Image';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'InformationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashRequest';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LoginPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Logo';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Member';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MembersList';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaFactors';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaVerification';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileStoreData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestConfirmationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPay';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransfer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransferCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OnfidoErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OurContactInformation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethodAlert';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhoneNumberVerificationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PriceComparsion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferFriendHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Regulator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyNewRequestEmail';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Review';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Reviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SenderFormPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendingPartnership';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ShareSocialLink';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SignIn';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SmsVerificationCodePage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SocialProofingModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SolutionsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StickyBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TagLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  }
                | {
                    __typename?: 'VerificationInProgress';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Video';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WeAreRegulated';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
              hyperlink: Array<
                | {
                    __typename?: 'AccountDuplicationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ActionResult';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReview';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchool';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCostCategory';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCountryData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BlogContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexChartFilterOptions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexLineChart';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateAlerts';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ChangePasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContextualPromoBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CorrespondentLocations';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountryPayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CurrencyPricingConverter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'EmbeddedTableEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExternalScript';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelp';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelpItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ForgottenPasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowDoesItWork';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowItWorksStepRelaunch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowSendingWorks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Image';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'InformationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashRequest';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LoginPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Logo';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Member';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MembersList';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaFactors';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaVerification';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileStoreData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestConfirmationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPay';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransfer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransferCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OnfidoErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OurContactInformation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethodAlert';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhoneNumberVerificationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PriceComparsion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferFriendHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Regulator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyNewRequestEmail';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Review';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Reviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SenderFormPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendingPartnership';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ShareSocialLink';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SignIn';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SmsVerificationCodePage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SocialProofingModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SolutionsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StickyBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TagLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'VerificationInProgress';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Video';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WeAreRegulated';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
            };
          };
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type CexCurrencyCorridorsListQueryVariables = Exact<{
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CexCurrencyCorridorsListQuery = {
  __typename?: 'Query';
  cexCurrencyCorridorCollection?: {
    __typename?: 'CexCurrencyCorridorCollection';
    items: Array<{
      __typename?: 'CexCurrencyCorridor';
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
};

export type CexCorridorSlugsQueryVariables = Exact<{
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CexCorridorSlugsQuery = {
  __typename?: 'Query';
  cexCurrencyCorridorCollection?: {
    __typename?: 'CexCurrencyCorridorCollection';
    items: Array<{
      __typename?: 'CexCurrencyCorridor';
      pageSlug?: string | null;
    } | null>;
  } | null;
};

export type CexCurrencyCorridorQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CexCurrencyCorridorQueryQuery = {
  __typename?: 'Query';
  CexCurrencyCorridor?: {
    __typename?: 'CexCurrencyCorridor';
    name?: string | null;
    pageSlug?: string | null;
    sendCurrency?: {
      __typename?: 'CexCurrency';
      name?: string | null;
      countryName?: string | null;
      currencyCode?: string | null;
      countryCode?: string | null;
      currencyName?: string | null;
      pageSlug?: string | null;
    } | null;
    receiveCurrency?: {
      __typename?: 'CexCurrency';
      name?: string | null;
      countryName?: string | null;
      currencyCode?: string | null;
      countryCode?: string | null;
      currencyName?: string | null;
      pageSlug?: string | null;
    } | null;
  } | null;
};

export type CexCurrencyCorridorBySendCurrencyCodeQueryVariables = Exact<{
  sendCurrencyCode?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CexCurrencyCorridorBySendCurrencyCodeQuery = {
  __typename?: 'Query';
  cexCurrencyCorridorCollection?: {
    __typename?: 'CexCurrencyCorridorCollection';
    items: Array<{
      __typename?: 'CexCurrencyCorridor';
      name?: string | null;
      pageSlug?: string | null;
      sendCurrency?: {
        __typename?: 'CexCurrency';
        name?: string | null;
        countryName?: string | null;
        currencyCode?: string | null;
        countryCode?: string | null;
        currencyName?: string | null;
        pageSlug?: string | null;
      } | null;
      receiveCurrency?: {
        __typename?: 'CexCurrency';
        name?: string | null;
        countryName?: string | null;
        currencyCode?: string | null;
        countryCode?: string | null;
        currencyName?: string | null;
        pageSlug?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type CexCurrencyCorridorBySlugQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CexCurrencyCorridorBySlugQuery = {
  __typename?: 'Query';
  cexCurrencyCorridorCollection?: {
    __typename?: 'CexCurrencyCorridorCollection';
    items: Array<{
      __typename?: 'CexCurrencyCorridor';
      name?: string | null;
      pageSlug?: string | null;
      sendCurrency?: {
        __typename?: 'CexCurrency';
        corridorGridTitle?: string | null;
        name?: string | null;
        countryName?: string | null;
        currencyCode?: string | null;
        countryCode?: string | null;
        currencyName?: string | null;
        pageSlug?: string | null;
        sys: { __typename?: 'Sys'; id: string };
        description?: {
          __typename?: 'CexCurrencyDescription';
          json: Document;
          links: {
            __typename?: 'CexCurrencyDescriptionLinks';
            assets: {
              __typename?: 'CexCurrencyDescriptionAssets';
              block: Array<{
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null>;
            };
            entries: {
              __typename?: 'CexCurrencyDescriptionEntries';
              block: Array<
                | {
                    __typename?: 'AccountDuplicationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ActionResult';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReview';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchool';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCostCategory';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCountryData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BlogContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexChartFilterOptions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexLineChart';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateAlerts';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ChangePasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContextualPromoBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CorrespondentLocations';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountryPayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CurrencyPricingConverter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'EmbeddedTableEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExternalScript';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelp';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelpItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ForgottenPasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowDoesItWork';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowItWorksStepRelaunch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowSendingWorks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    name?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    } | null;
                    icon?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'Image';
                    name?: string | null;
                    align?: string | null;
                    position?: string | null;
                    width?: number | null;
                    height?: number | null;
                    sys: { __typename?: 'Sys'; id: string };
                    asset?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'InformationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashRequest';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LoginPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Logo';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Member';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MembersList';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaFactors';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaVerification';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileStoreData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestConfirmationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPay';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransfer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransferCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OnfidoErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OurContactInformation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethodAlert';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhoneNumberVerificationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PriceComparsion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferFriendHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Regulator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyNewRequestEmail';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Review';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Reviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SenderFormPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendingPartnership';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ShareSocialLink';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SignIn';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SmsVerificationCodePage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SocialProofingModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SolutionsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StickyBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TagLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  }
                | {
                    __typename?: 'VerificationInProgress';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename: 'Video';
                    name?: string | null;
                    videoId?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    content?: {
                      __typename?: 'VideoContent';
                      json: Document;
                    } | null;
                    backgroundImage?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'WeAreRegulated';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
              inline: Array<
                | {
                    __typename?: 'AccountDuplicationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ActionResult';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReview';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchool';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCostCategory';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCountryData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BlogContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexChartFilterOptions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexLineChart';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateAlerts';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ChangePasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContextualPromoBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CorrespondentLocations';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountryPayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CurrencyPricingConverter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'EmbeddedTableEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExternalScript';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelp';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelpItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ForgottenPasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowDoesItWork';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowItWorksStepRelaunch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowSendingWorks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    name?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    } | null;
                    icon?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'Image';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'InformationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashRequest';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LoginPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Logo';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Member';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MembersList';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaFactors';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaVerification';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileStoreData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestConfirmationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPay';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransfer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransferCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OnfidoErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OurContactInformation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethodAlert';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhoneNumberVerificationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PriceComparsion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferFriendHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Regulator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyNewRequestEmail';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Review';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Reviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SenderFormPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendingPartnership';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ShareSocialLink';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SignIn';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SmsVerificationCodePage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SocialProofingModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SolutionsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StickyBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TagLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  }
                | {
                    __typename?: 'VerificationInProgress';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Video';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WeAreRegulated';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
              hyperlink: Array<
                | {
                    __typename?: 'AccountDuplicationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ActionResult';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReview';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchool';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCostCategory';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCountryData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BlogContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexChartFilterOptions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexLineChart';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateAlerts';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ChangePasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContextualPromoBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CorrespondentLocations';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountryPayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CurrencyPricingConverter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'EmbeddedTableEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExternalScript';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelp';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelpItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ForgottenPasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowDoesItWork';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowItWorksStepRelaunch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowSendingWorks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Image';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'InformationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashRequest';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LoginPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Logo';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Member';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MembersList';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaFactors';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaVerification';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileStoreData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestConfirmationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPay';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransfer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransferCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OnfidoErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OurContactInformation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethodAlert';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhoneNumberVerificationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PriceComparsion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferFriendHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Regulator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyNewRequestEmail';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Review';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Reviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SenderFormPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendingPartnership';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ShareSocialLink';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SignIn';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SmsVerificationCodePage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SocialProofingModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SolutionsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StickyBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TagLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'VerificationInProgress';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Video';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WeAreRegulated';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
            };
          };
        } | null;
        faqContent?: {
          __typename?: 'CexCurrencyFaqContent';
          json: Document;
          links: {
            __typename?: 'CexCurrencyFaqContentLinks';
            assets: {
              __typename?: 'CexCurrencyFaqContentAssets';
              block: Array<{
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null>;
            };
            entries: {
              __typename?: 'CexCurrencyFaqContentEntries';
              block: Array<
                | {
                    __typename?: 'AccountDuplicationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ActionResult';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReview';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchool';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCostCategory';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCountryData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BlogContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexChartFilterOptions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexLineChart';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateAlerts';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ChangePasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContextualPromoBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CorrespondentLocations';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountryPayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CurrencyPricingConverter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'EmbeddedTableEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExternalScript';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelp';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelpItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ForgottenPasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowDoesItWork';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowItWorksStepRelaunch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowSendingWorks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    name?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    } | null;
                    icon?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'Image';
                    name?: string | null;
                    align?: string | null;
                    position?: string | null;
                    width?: number | null;
                    height?: number | null;
                    sys: { __typename?: 'Sys'; id: string };
                    asset?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'InformationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashRequest';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LoginPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Logo';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Member';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MembersList';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaFactors';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaVerification';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileStoreData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestConfirmationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPay';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransfer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransferCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OnfidoErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OurContactInformation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethodAlert';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhoneNumberVerificationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PriceComparsion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferFriendHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Regulator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyNewRequestEmail';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Review';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Reviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SenderFormPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendingPartnership';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ShareSocialLink';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SignIn';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SmsVerificationCodePage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SocialProofingModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SolutionsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StickyBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TagLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  }
                | {
                    __typename?: 'VerificationInProgress';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename: 'Video';
                    name?: string | null;
                    videoId?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    content?: {
                      __typename?: 'VideoContent';
                      json: Document;
                    } | null;
                    backgroundImage?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'WeAreRegulated';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
              inline: Array<
                | {
                    __typename?: 'AccountDuplicationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ActionResult';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReview';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchool';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCostCategory';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCountryData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BlogContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexChartFilterOptions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexLineChart';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateAlerts';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ChangePasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContextualPromoBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CorrespondentLocations';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountryPayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CurrencyPricingConverter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'EmbeddedTableEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExternalScript';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelp';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelpItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ForgottenPasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowDoesItWork';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowItWorksStepRelaunch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowSendingWorks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    name?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    } | null;
                    icon?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'Image';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'InformationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashRequest';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LoginPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Logo';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Member';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MembersList';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaFactors';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaVerification';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileStoreData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestConfirmationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPay';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransfer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransferCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OnfidoErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OurContactInformation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethodAlert';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhoneNumberVerificationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PriceComparsion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferFriendHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Regulator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyNewRequestEmail';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Review';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Reviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SenderFormPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendingPartnership';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ShareSocialLink';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SignIn';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SmsVerificationCodePage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SocialProofingModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SolutionsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StickyBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TagLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  }
                | {
                    __typename?: 'VerificationInProgress';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Video';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WeAreRegulated';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
              hyperlink: Array<
                | {
                    __typename?: 'AccountDuplicationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ActionResult';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReview';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchool';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCostCategory';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCountryData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BlogContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexChartFilterOptions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexLineChart';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateAlerts';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ChangePasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContextualPromoBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CorrespondentLocations';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountryPayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CurrencyPricingConverter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'EmbeddedTableEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExternalScript';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelp';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelpItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ForgottenPasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowDoesItWork';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowItWorksStepRelaunch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowSendingWorks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Image';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'InformationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashRequest';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LoginPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Logo';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Member';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MembersList';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaFactors';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaVerification';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileStoreData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestConfirmationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPay';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransfer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransferCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OnfidoErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OurContactInformation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethodAlert';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhoneNumberVerificationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PriceComparsion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferFriendHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Regulator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyNewRequestEmail';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Review';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Reviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SenderFormPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendingPartnership';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ShareSocialLink';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SignIn';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SmsVerificationCodePage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SocialProofingModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SolutionsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StickyBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TagLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'VerificationInProgress';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Video';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WeAreRegulated';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
            };
          };
        } | null;
        topCurrencyPairs?: {
          __typename?: 'CexCurrencyTopCurrencyPairsCollection';
          items: Array<{
            __typename?: 'CexCurrency';
            name?: string | null;
            pageSlug?: string | null;
            countryCode?: string | null;
            countryName?: string | null;
            currencyCode?: string | null;
            currencyName?: string | null;
            description?: {
              __typename?: 'CexCurrencyDescription';
              json: Document;
              links: {
                __typename?: 'CexCurrencyDescriptionLinks';
                assets: {
                  __typename?: 'CexCurrencyDescriptionAssets';
                  block: Array<{
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null>;
                };
                entries: {
                  __typename?: 'CexCurrencyDescriptionEntries';
                  block: Array<
                    | {
                        __typename?: 'AccountDuplicationPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ActionResult';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppReview';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppReviews';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BackToSchool';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BackToSchoolCostCategory';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BackToSchoolCountryData';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BetterWayTop';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BlogContent';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BodyText';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Calculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CalculatorAppLeadingLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CalculatorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Card';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Cards';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexChartFilterOptions';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexCurrency';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexCurrencyCorridor';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexLineChart';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexRateAlerts';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexRateTable';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexSearch';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ChangePasswordPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTable';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTableCell';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTableRow';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ContextualPromoBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Correspondent';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CorrespondentLocations';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Correspondents';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Country';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CountryPayoutMethod';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CurrencyPricingConverter';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'EmbeddedTableEntry';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ExternalScript';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqFeedbackModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqFeedbackModuleItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqHero';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqModuleItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqPopularQuestions';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqSearchHelp';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqSearchHelpItems';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopicsModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopicsModuleItems';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FeatureFlag';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Footer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FooterNavigationGroup';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FooterNavigationGroupExtras';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ForgottenPasswordPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FullWidthBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'GenericErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HeroSlot';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HowDoesItWork';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HowItWorksStepRelaunch';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HowSendingWorks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'IconWithText';
                        name?: string | null;
                        sys: { __typename?: 'Sys'; id: string };
                        text?: {
                          __typename?: 'TextWithColor';
                          name?: string | null;
                          inline?: boolean | null;
                          color?: string | null;
                          backgroundColor?: string | null;
                          align?: string | null;
                          text?: {
                            __typename?: 'TextWithColorText';
                            json: Document;
                          } | null;
                        } | null;
                        icon?: {
                          __typename?: 'Asset';
                          url?: string | null;
                          title?: string | null;
                          width?: number | null;
                          height?: number | null;
                          alt?: string | null;
                        } | null;
                      }
                    | {
                        __typename?: 'Image';
                        name?: string | null;
                        align?: string | null;
                        position?: string | null;
                        width?: number | null;
                        height?: number | null;
                        sys: { __typename?: 'Sys'; id: string };
                        asset?: {
                          __typename?: 'Asset';
                          url?: string | null;
                          title?: string | null;
                          width?: number | null;
                          height?: number | null;
                          alt?: string | null;
                        } | null;
                      }
                    | {
                        __typename?: 'InformationDialog';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'JazzCashPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'JazzCashRequest';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LayoutFooter';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LayoutHeader';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LegalMessaging';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Link';
                        name?: string | null;
                        url?: string | null;
                        isInternal?: boolean | null;
                        label?: string | null;
                        ariaLabel?: string | null;
                        openInNewTab?: boolean | null;
                        gaTrackName?: string | null;
                        isDisplayedWhenLoggedIn?: boolean | null;
                        color?: string | null;
                        variant?: string | null;
                        underline?: string | null;
                        align?: string | null;
                        noFollow?: boolean | null;
                        sys: { __typename?: 'Sys'; id: string };
                        image?: {
                          __typename?: 'Asset';
                          url?: string | null;
                          title?: string | null;
                          width?: number | null;
                          height?: number | null;
                          alt?: string | null;
                        } | null;
                      }
                    | {
                        __typename?: 'ListOfCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LoginPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Logo';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Member';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MembersList';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Messages';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MfaFactors';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MfaVerification';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MobileAppLeadingLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MobileStoreData';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyRequestConfirmationDialog';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyRequestPay';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyRequestPaymentReason';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyTransfer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyTransferCard';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundContent';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'OnfidoErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'OurContactInformation';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Page';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PageSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PaymentReason';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PayoutMethod';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PayoutMethodAlert';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Person';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PhoneNumberVerificationPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PopularCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PriceComparsion';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PricingCalculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromoApplicability';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromoMessage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromotionalBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromotionalBannerDisclaimer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Redirect';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReferFriendHeader';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RegionCode';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Regulator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RequestMoneyNewRequestEmail';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RequestMoneyPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Review';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Reviews';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReviewsTimeDictionary';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RobotsConfiguration';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Search';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SendToCountryModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SenderFormPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SendingPartnership';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoContactPoint';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoOrganizationStructuredData';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoSameAs';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ShareSocialLink';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SignIn';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Sitemap';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SmsVerificationCodePage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SocialProofingModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SolutionsModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Step';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'StepByStep';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'StickyBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TagLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextMeTheAppModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextWithColor';
                        name?: string | null;
                        inline?: boolean | null;
                        color?: string | null;
                        backgroundColor?: string | null;
                        align?: string | null;
                        sys: { __typename?: 'Sys'; id: string };
                        text?: {
                          __typename?: 'TextWithColorText';
                          json: Document;
                        } | null;
                      }
                    | {
                        __typename?: 'VerificationInProgress';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename: 'Video';
                        name?: string | null;
                        videoId?: string | null;
                        sys: { __typename?: 'Sys'; id: string };
                        content?: {
                          __typename?: 'VideoContent';
                          json: Document;
                        } | null;
                        backgroundImage?: {
                          __typename?: 'Asset';
                          url?: string | null;
                          title?: string | null;
                          width?: number | null;
                          height?: number | null;
                          alt?: string | null;
                        } | null;
                      }
                    | {
                        __typename?: 'WeAreRegulated';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WebForm';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WhyChoose';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WhyChooseReason';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | null
                  >;
                  inline: Array<
                    | {
                        __typename?: 'AccountDuplicationPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ActionResult';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppReview';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppReviews';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BackToSchool';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BackToSchoolCostCategory';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BackToSchoolCountryData';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BetterWayTop';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BlogContent';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BodyText';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Calculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CalculatorAppLeadingLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CalculatorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Card';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Cards';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexChartFilterOptions';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexCurrency';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexCurrencyCorridor';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexLineChart';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexRateAlerts';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexRateTable';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexSearch';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ChangePasswordPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTable';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTableCell';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTableRow';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ContextualPromoBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Correspondent';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CorrespondentLocations';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Correspondents';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Country';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CountryPayoutMethod';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CurrencyPricingConverter';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'EmbeddedTableEntry';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ExternalScript';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqFeedbackModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqFeedbackModuleItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqHero';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqModuleItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqPopularQuestions';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqSearchHelp';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqSearchHelpItems';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopicsModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopicsModuleItems';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FeatureFlag';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Footer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FooterNavigationGroup';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FooterNavigationGroupExtras';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ForgottenPasswordPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FullWidthBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'GenericErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HeroSlot';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HowDoesItWork';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HowItWorksStepRelaunch';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HowSendingWorks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'IconWithText';
                        name?: string | null;
                        sys: { __typename?: 'Sys'; id: string };
                        text?: {
                          __typename?: 'TextWithColor';
                          name?: string | null;
                          inline?: boolean | null;
                          color?: string | null;
                          backgroundColor?: string | null;
                          align?: string | null;
                          text?: {
                            __typename?: 'TextWithColorText';
                            json: Document;
                          } | null;
                        } | null;
                        icon?: {
                          __typename?: 'Asset';
                          url?: string | null;
                          title?: string | null;
                          width?: number | null;
                          height?: number | null;
                          alt?: string | null;
                        } | null;
                      }
                    | {
                        __typename?: 'Image';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'InformationDialog';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'JazzCashPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'JazzCashRequest';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LayoutFooter';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LayoutHeader';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LegalMessaging';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Link';
                        name?: string | null;
                        url?: string | null;
                        isInternal?: boolean | null;
                        label?: string | null;
                        ariaLabel?: string | null;
                        openInNewTab?: boolean | null;
                        gaTrackName?: string | null;
                        isDisplayedWhenLoggedIn?: boolean | null;
                        color?: string | null;
                        variant?: string | null;
                        underline?: string | null;
                        align?: string | null;
                        noFollow?: boolean | null;
                        sys: { __typename?: 'Sys'; id: string };
                        image?: {
                          __typename?: 'Asset';
                          url?: string | null;
                          title?: string | null;
                          width?: number | null;
                          height?: number | null;
                          alt?: string | null;
                        } | null;
                      }
                    | {
                        __typename?: 'ListOfCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LoginPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Logo';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Member';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MembersList';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Messages';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MfaFactors';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MfaVerification';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MobileAppLeadingLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MobileStoreData';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyRequestConfirmationDialog';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyRequestPay';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyRequestPaymentReason';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyTransfer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyTransferCard';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundContent';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'OnfidoErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'OurContactInformation';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Page';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PageSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PaymentReason';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PayoutMethod';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PayoutMethodAlert';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Person';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PhoneNumberVerificationPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PopularCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PriceComparsion';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PricingCalculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromoApplicability';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromoMessage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromotionalBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromotionalBannerDisclaimer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Redirect';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReferFriendHeader';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RegionCode';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Regulator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RequestMoneyNewRequestEmail';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RequestMoneyPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Review';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Reviews';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReviewsTimeDictionary';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RobotsConfiguration';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Search';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SendToCountryModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SenderFormPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SendingPartnership';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoContactPoint';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoOrganizationStructuredData';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoSameAs';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ShareSocialLink';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SignIn';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Sitemap';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SmsVerificationCodePage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SocialProofingModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SolutionsModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Step';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'StepByStep';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'StickyBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TagLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextMeTheAppModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextWithColor';
                        name?: string | null;
                        inline?: boolean | null;
                        color?: string | null;
                        backgroundColor?: string | null;
                        align?: string | null;
                        sys: { __typename?: 'Sys'; id: string };
                        text?: {
                          __typename?: 'TextWithColorText';
                          json: Document;
                        } | null;
                      }
                    | {
                        __typename?: 'VerificationInProgress';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Video';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WeAreRegulated';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WebForm';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WhyChoose';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WhyChooseReason';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | null
                  >;
                  hyperlink: Array<
                    | {
                        __typename?: 'AccountDuplicationPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ActionResult';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppReview';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppReviews';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BackToSchool';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BackToSchoolCostCategory';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BackToSchoolCountryData';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BetterWayTop';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BlogContent';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BodyText';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Calculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CalculatorAppLeadingLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CalculatorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Card';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Cards';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexChartFilterOptions';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexCurrency';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexCurrencyCorridor';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexLineChart';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexRateAlerts';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexRateTable';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexSearch';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ChangePasswordPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTable';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTableCell';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTableRow';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ContextualPromoBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Correspondent';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CorrespondentLocations';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Correspondents';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Country';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CountryPayoutMethod';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CurrencyPricingConverter';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'EmbeddedTableEntry';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ExternalScript';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqFeedbackModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqFeedbackModuleItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqHero';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqModuleItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqPopularQuestions';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqSearchHelp';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqSearchHelpItems';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopicsModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopicsModuleItems';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FeatureFlag';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Footer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FooterNavigationGroup';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FooterNavigationGroupExtras';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ForgottenPasswordPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FullWidthBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'GenericErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HeroSlot';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HowDoesItWork';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HowItWorksStepRelaunch';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HowSendingWorks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'IconWithText';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Image';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'InformationDialog';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'JazzCashPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'JazzCashRequest';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LayoutFooter';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LayoutHeader';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LegalMessaging';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Link';
                        name?: string | null;
                        url?: string | null;
                        isInternal?: boolean | null;
                        label?: string | null;
                        ariaLabel?: string | null;
                        openInNewTab?: boolean | null;
                        gaTrackName?: string | null;
                        isDisplayedWhenLoggedIn?: boolean | null;
                        color?: string | null;
                        variant?: string | null;
                        underline?: string | null;
                        align?: string | null;
                        noFollow?: boolean | null;
                        sys: { __typename?: 'Sys'; id: string };
                        image?: {
                          __typename?: 'Asset';
                          url?: string | null;
                          title?: string | null;
                          width?: number | null;
                          height?: number | null;
                          alt?: string | null;
                        } | null;
                      }
                    | {
                        __typename?: 'ListOfCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LoginPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Logo';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Member';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MembersList';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Messages';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MfaFactors';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MfaVerification';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MobileAppLeadingLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MobileStoreData';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyRequestConfirmationDialog';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyRequestPay';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyRequestPaymentReason';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyTransfer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyTransferCard';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundContent';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'OnfidoErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'OurContactInformation';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Page';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PageSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PaymentReason';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PayoutMethod';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PayoutMethodAlert';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Person';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PhoneNumberVerificationPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PopularCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PriceComparsion';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PricingCalculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromoApplicability';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromoMessage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromotionalBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromotionalBannerDisclaimer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Redirect';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReferFriendHeader';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RegionCode';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Regulator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RequestMoneyNewRequestEmail';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RequestMoneyPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Review';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Reviews';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReviewsTimeDictionary';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RobotsConfiguration';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Search';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SendToCountryModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SenderFormPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SendingPartnership';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoContactPoint';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoOrganizationStructuredData';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoSameAs';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ShareSocialLink';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SignIn';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Sitemap';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SmsVerificationCodePage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SocialProofingModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SolutionsModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Step';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'StepByStep';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'StickyBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TagLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextMeTheAppModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextWithColor';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'VerificationInProgress';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Video';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WeAreRegulated';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WebForm';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WhyChoose';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WhyChooseReason';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | null
                  >;
                };
              };
            } | null;
          } | null>;
        } | null;
      } | null;
      receiveCurrency?: {
        __typename?: 'CexCurrency';
        corridorGridTitle?: string | null;
        name?: string | null;
        countryName?: string | null;
        currencyCode?: string | null;
        countryCode?: string | null;
        currencyName?: string | null;
        pageSlug?: string | null;
        sys: { __typename?: 'Sys'; id: string };
        description?: {
          __typename?: 'CexCurrencyDescription';
          json: Document;
          links: {
            __typename?: 'CexCurrencyDescriptionLinks';
            assets: {
              __typename?: 'CexCurrencyDescriptionAssets';
              block: Array<{
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null>;
            };
            entries: {
              __typename?: 'CexCurrencyDescriptionEntries';
              block: Array<
                | {
                    __typename?: 'AccountDuplicationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ActionResult';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReview';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchool';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCostCategory';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCountryData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BlogContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexChartFilterOptions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexLineChart';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateAlerts';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ChangePasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContextualPromoBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CorrespondentLocations';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountryPayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CurrencyPricingConverter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'EmbeddedTableEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExternalScript';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelp';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelpItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ForgottenPasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowDoesItWork';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowItWorksStepRelaunch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowSendingWorks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    name?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    } | null;
                    icon?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'Image';
                    name?: string | null;
                    align?: string | null;
                    position?: string | null;
                    width?: number | null;
                    height?: number | null;
                    sys: { __typename?: 'Sys'; id: string };
                    asset?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'InformationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashRequest';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LoginPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Logo';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Member';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MembersList';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaFactors';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaVerification';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileStoreData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestConfirmationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPay';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransfer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransferCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OnfidoErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OurContactInformation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethodAlert';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhoneNumberVerificationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PriceComparsion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferFriendHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Regulator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyNewRequestEmail';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Review';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Reviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SenderFormPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendingPartnership';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ShareSocialLink';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SignIn';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SmsVerificationCodePage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SocialProofingModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SolutionsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StickyBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TagLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  }
                | {
                    __typename?: 'VerificationInProgress';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename: 'Video';
                    name?: string | null;
                    videoId?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    content?: {
                      __typename?: 'VideoContent';
                      json: Document;
                    } | null;
                    backgroundImage?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'WeAreRegulated';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
              inline: Array<
                | {
                    __typename?: 'AccountDuplicationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ActionResult';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReview';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchool';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCostCategory';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCountryData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BlogContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexChartFilterOptions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexLineChart';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateAlerts';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ChangePasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContextualPromoBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CorrespondentLocations';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountryPayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CurrencyPricingConverter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'EmbeddedTableEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExternalScript';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelp';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelpItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ForgottenPasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowDoesItWork';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowItWorksStepRelaunch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowSendingWorks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    name?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    } | null;
                    icon?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'Image';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'InformationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashRequest';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LoginPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Logo';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Member';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MembersList';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaFactors';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaVerification';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileStoreData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestConfirmationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPay';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransfer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransferCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OnfidoErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OurContactInformation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethodAlert';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhoneNumberVerificationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PriceComparsion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferFriendHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Regulator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyNewRequestEmail';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Review';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Reviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SenderFormPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendingPartnership';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ShareSocialLink';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SignIn';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SmsVerificationCodePage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SocialProofingModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SolutionsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StickyBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TagLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  }
                | {
                    __typename?: 'VerificationInProgress';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Video';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WeAreRegulated';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
              hyperlink: Array<
                | {
                    __typename?: 'AccountDuplicationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ActionResult';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReview';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchool';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCostCategory';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCountryData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BlogContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexChartFilterOptions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexLineChart';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateAlerts';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ChangePasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContextualPromoBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CorrespondentLocations';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountryPayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CurrencyPricingConverter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'EmbeddedTableEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExternalScript';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelp';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelpItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ForgottenPasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowDoesItWork';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowItWorksStepRelaunch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowSendingWorks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Image';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'InformationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashRequest';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LoginPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Logo';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Member';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MembersList';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaFactors';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaVerification';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileStoreData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestConfirmationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPay';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransfer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransferCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OnfidoErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OurContactInformation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethodAlert';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhoneNumberVerificationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PriceComparsion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferFriendHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Regulator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyNewRequestEmail';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Review';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Reviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SenderFormPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendingPartnership';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ShareSocialLink';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SignIn';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SmsVerificationCodePage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SocialProofingModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SolutionsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StickyBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TagLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'VerificationInProgress';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Video';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WeAreRegulated';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
            };
          };
        } | null;
        faqContent?: {
          __typename?: 'CexCurrencyFaqContent';
          json: Document;
          links: {
            __typename?: 'CexCurrencyFaqContentLinks';
            assets: {
              __typename?: 'CexCurrencyFaqContentAssets';
              block: Array<{
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null>;
            };
            entries: {
              __typename?: 'CexCurrencyFaqContentEntries';
              block: Array<
                | {
                    __typename?: 'AccountDuplicationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ActionResult';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReview';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchool';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCostCategory';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCountryData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BlogContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexChartFilterOptions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexLineChart';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateAlerts';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ChangePasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContextualPromoBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CorrespondentLocations';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountryPayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CurrencyPricingConverter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'EmbeddedTableEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExternalScript';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelp';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelpItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ForgottenPasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowDoesItWork';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowItWorksStepRelaunch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowSendingWorks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    name?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    } | null;
                    icon?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'Image';
                    name?: string | null;
                    align?: string | null;
                    position?: string | null;
                    width?: number | null;
                    height?: number | null;
                    sys: { __typename?: 'Sys'; id: string };
                    asset?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'InformationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashRequest';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LoginPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Logo';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Member';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MembersList';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaFactors';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaVerification';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileStoreData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestConfirmationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPay';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransfer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransferCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OnfidoErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OurContactInformation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethodAlert';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhoneNumberVerificationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PriceComparsion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferFriendHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Regulator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyNewRequestEmail';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Review';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Reviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SenderFormPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendingPartnership';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ShareSocialLink';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SignIn';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SmsVerificationCodePage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SocialProofingModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SolutionsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StickyBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TagLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  }
                | {
                    __typename?: 'VerificationInProgress';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename: 'Video';
                    name?: string | null;
                    videoId?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    content?: {
                      __typename?: 'VideoContent';
                      json: Document;
                    } | null;
                    backgroundImage?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'WeAreRegulated';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
              inline: Array<
                | {
                    __typename?: 'AccountDuplicationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ActionResult';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReview';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchool';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCostCategory';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCountryData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BlogContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexChartFilterOptions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexLineChart';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateAlerts';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ChangePasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContextualPromoBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CorrespondentLocations';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountryPayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CurrencyPricingConverter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'EmbeddedTableEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExternalScript';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelp';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelpItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ForgottenPasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowDoesItWork';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowItWorksStepRelaunch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowSendingWorks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    name?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    } | null;
                    icon?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'Image';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'InformationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashRequest';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LoginPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Logo';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Member';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MembersList';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaFactors';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaVerification';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileStoreData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestConfirmationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPay';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransfer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransferCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OnfidoErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OurContactInformation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethodAlert';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhoneNumberVerificationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PriceComparsion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferFriendHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Regulator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyNewRequestEmail';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Review';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Reviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SenderFormPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendingPartnership';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ShareSocialLink';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SignIn';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SmsVerificationCodePage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SocialProofingModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SolutionsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StickyBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TagLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  }
                | {
                    __typename?: 'VerificationInProgress';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Video';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WeAreRegulated';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
              hyperlink: Array<
                | {
                    __typename?: 'AccountDuplicationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ActionResult';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReview';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppReviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchool';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCostCategory';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BackToSchoolCountryData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BlogContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexChartFilterOptions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexLineChart';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateAlerts';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexRateTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ChangePasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContextualPromoBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CorrespondentLocations';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountryPayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CurrencyPricingConverter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'EmbeddedTableEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExternalScript';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqFeedbackModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelp';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSearchHelpItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ForgottenPasswordPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowDoesItWork';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowItWorksStepRelaunch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HowSendingWorks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Image';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'InformationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'JazzCashRequest';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LoginPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Logo';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Member';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MembersList';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaFactors';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MfaVerification';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileStoreData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestConfirmationDialog';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPay';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyRequestPaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransfer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MoneyTransferCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OnfidoErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'OurContactInformation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PaymentReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethodAlert';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhoneNumberVerificationPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PriceComparsion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReferFriendHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Regulator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyNewRequestEmail';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RequestMoneyPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Review';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Reviews';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SenderFormPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendingPartnership';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ShareSocialLink';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SignIn';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SmsVerificationCodePage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SocialProofingModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SolutionsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StickyBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TagLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'VerificationInProgress';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Video';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WeAreRegulated';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
            };
          };
        } | null;
        topCurrencyPairs?: {
          __typename?: 'CexCurrencyTopCurrencyPairsCollection';
          items: Array<{
            __typename?: 'CexCurrency';
            name?: string | null;
            pageSlug?: string | null;
            countryCode?: string | null;
            countryName?: string | null;
            currencyCode?: string | null;
            currencyName?: string | null;
            description?: {
              __typename?: 'CexCurrencyDescription';
              json: Document;
              links: {
                __typename?: 'CexCurrencyDescriptionLinks';
                assets: {
                  __typename?: 'CexCurrencyDescriptionAssets';
                  block: Array<{
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null>;
                };
                entries: {
                  __typename?: 'CexCurrencyDescriptionEntries';
                  block: Array<
                    | {
                        __typename?: 'AccountDuplicationPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ActionResult';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppReview';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppReviews';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BackToSchool';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BackToSchoolCostCategory';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BackToSchoolCountryData';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BetterWayTop';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BlogContent';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BodyText';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Calculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CalculatorAppLeadingLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CalculatorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Card';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Cards';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexChartFilterOptions';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexCurrency';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexCurrencyCorridor';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexLineChart';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexRateAlerts';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexRateTable';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexSearch';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ChangePasswordPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTable';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTableCell';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTableRow';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ContextualPromoBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Correspondent';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CorrespondentLocations';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Correspondents';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Country';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CountryPayoutMethod';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CurrencyPricingConverter';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'EmbeddedTableEntry';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ExternalScript';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqFeedbackModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqFeedbackModuleItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqHero';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqModuleItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqPopularQuestions';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqSearchHelp';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqSearchHelpItems';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopicsModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopicsModuleItems';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FeatureFlag';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Footer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FooterNavigationGroup';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FooterNavigationGroupExtras';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ForgottenPasswordPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FullWidthBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'GenericErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HeroSlot';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HowDoesItWork';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HowItWorksStepRelaunch';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HowSendingWorks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'IconWithText';
                        name?: string | null;
                        sys: { __typename?: 'Sys'; id: string };
                        text?: {
                          __typename?: 'TextWithColor';
                          name?: string | null;
                          inline?: boolean | null;
                          color?: string | null;
                          backgroundColor?: string | null;
                          align?: string | null;
                          text?: {
                            __typename?: 'TextWithColorText';
                            json: Document;
                          } | null;
                        } | null;
                        icon?: {
                          __typename?: 'Asset';
                          url?: string | null;
                          title?: string | null;
                          width?: number | null;
                          height?: number | null;
                          alt?: string | null;
                        } | null;
                      }
                    | {
                        __typename?: 'Image';
                        name?: string | null;
                        align?: string | null;
                        position?: string | null;
                        width?: number | null;
                        height?: number | null;
                        sys: { __typename?: 'Sys'; id: string };
                        asset?: {
                          __typename?: 'Asset';
                          url?: string | null;
                          title?: string | null;
                          width?: number | null;
                          height?: number | null;
                          alt?: string | null;
                        } | null;
                      }
                    | {
                        __typename?: 'InformationDialog';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'JazzCashPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'JazzCashRequest';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LayoutFooter';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LayoutHeader';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LegalMessaging';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Link';
                        name?: string | null;
                        url?: string | null;
                        isInternal?: boolean | null;
                        label?: string | null;
                        ariaLabel?: string | null;
                        openInNewTab?: boolean | null;
                        gaTrackName?: string | null;
                        isDisplayedWhenLoggedIn?: boolean | null;
                        color?: string | null;
                        variant?: string | null;
                        underline?: string | null;
                        align?: string | null;
                        noFollow?: boolean | null;
                        sys: { __typename?: 'Sys'; id: string };
                        image?: {
                          __typename?: 'Asset';
                          url?: string | null;
                          title?: string | null;
                          width?: number | null;
                          height?: number | null;
                          alt?: string | null;
                        } | null;
                      }
                    | {
                        __typename?: 'ListOfCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LoginPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Logo';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Member';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MembersList';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Messages';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MfaFactors';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MfaVerification';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MobileAppLeadingLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MobileStoreData';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyRequestConfirmationDialog';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyRequestPay';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyRequestPaymentReason';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyTransfer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyTransferCard';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundContent';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'OnfidoErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'OurContactInformation';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Page';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PageSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PaymentReason';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PayoutMethod';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PayoutMethodAlert';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Person';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PhoneNumberVerificationPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PopularCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PriceComparsion';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PricingCalculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromoApplicability';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromoMessage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromotionalBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromotionalBannerDisclaimer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Redirect';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReferFriendHeader';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RegionCode';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Regulator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RequestMoneyNewRequestEmail';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RequestMoneyPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Review';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Reviews';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReviewsTimeDictionary';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RobotsConfiguration';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Search';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SendToCountryModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SenderFormPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SendingPartnership';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoContactPoint';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoOrganizationStructuredData';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoSameAs';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ShareSocialLink';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SignIn';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Sitemap';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SmsVerificationCodePage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SocialProofingModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SolutionsModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Step';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'StepByStep';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'StickyBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TagLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextMeTheAppModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextWithColor';
                        name?: string | null;
                        inline?: boolean | null;
                        color?: string | null;
                        backgroundColor?: string | null;
                        align?: string | null;
                        sys: { __typename?: 'Sys'; id: string };
                        text?: {
                          __typename?: 'TextWithColorText';
                          json: Document;
                        } | null;
                      }
                    | {
                        __typename?: 'VerificationInProgress';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename: 'Video';
                        name?: string | null;
                        videoId?: string | null;
                        sys: { __typename?: 'Sys'; id: string };
                        content?: {
                          __typename?: 'VideoContent';
                          json: Document;
                        } | null;
                        backgroundImage?: {
                          __typename?: 'Asset';
                          url?: string | null;
                          title?: string | null;
                          width?: number | null;
                          height?: number | null;
                          alt?: string | null;
                        } | null;
                      }
                    | {
                        __typename?: 'WeAreRegulated';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WebForm';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WhyChoose';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WhyChooseReason';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | null
                  >;
                  inline: Array<
                    | {
                        __typename?: 'AccountDuplicationPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ActionResult';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppReview';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppReviews';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BackToSchool';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BackToSchoolCostCategory';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BackToSchoolCountryData';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BetterWayTop';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BlogContent';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BodyText';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Calculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CalculatorAppLeadingLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CalculatorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Card';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Cards';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexChartFilterOptions';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexCurrency';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexCurrencyCorridor';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexLineChart';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexRateAlerts';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexRateTable';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexSearch';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ChangePasswordPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTable';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTableCell';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTableRow';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ContextualPromoBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Correspondent';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CorrespondentLocations';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Correspondents';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Country';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CountryPayoutMethod';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CurrencyPricingConverter';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'EmbeddedTableEntry';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ExternalScript';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqFeedbackModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqFeedbackModuleItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqHero';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqModuleItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqPopularQuestions';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqSearchHelp';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqSearchHelpItems';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopicsModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopicsModuleItems';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FeatureFlag';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Footer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FooterNavigationGroup';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FooterNavigationGroupExtras';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ForgottenPasswordPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FullWidthBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'GenericErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HeroSlot';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HowDoesItWork';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HowItWorksStepRelaunch';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HowSendingWorks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'IconWithText';
                        name?: string | null;
                        sys: { __typename?: 'Sys'; id: string };
                        text?: {
                          __typename?: 'TextWithColor';
                          name?: string | null;
                          inline?: boolean | null;
                          color?: string | null;
                          backgroundColor?: string | null;
                          align?: string | null;
                          text?: {
                            __typename?: 'TextWithColorText';
                            json: Document;
                          } | null;
                        } | null;
                        icon?: {
                          __typename?: 'Asset';
                          url?: string | null;
                          title?: string | null;
                          width?: number | null;
                          height?: number | null;
                          alt?: string | null;
                        } | null;
                      }
                    | {
                        __typename?: 'Image';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'InformationDialog';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'JazzCashPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'JazzCashRequest';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LayoutFooter';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LayoutHeader';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LegalMessaging';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Link';
                        name?: string | null;
                        url?: string | null;
                        isInternal?: boolean | null;
                        label?: string | null;
                        ariaLabel?: string | null;
                        openInNewTab?: boolean | null;
                        gaTrackName?: string | null;
                        isDisplayedWhenLoggedIn?: boolean | null;
                        color?: string | null;
                        variant?: string | null;
                        underline?: string | null;
                        align?: string | null;
                        noFollow?: boolean | null;
                        sys: { __typename?: 'Sys'; id: string };
                        image?: {
                          __typename?: 'Asset';
                          url?: string | null;
                          title?: string | null;
                          width?: number | null;
                          height?: number | null;
                          alt?: string | null;
                        } | null;
                      }
                    | {
                        __typename?: 'ListOfCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LoginPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Logo';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Member';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MembersList';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Messages';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MfaFactors';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MfaVerification';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MobileAppLeadingLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MobileStoreData';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyRequestConfirmationDialog';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyRequestPay';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyRequestPaymentReason';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyTransfer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyTransferCard';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundContent';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'OnfidoErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'OurContactInformation';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Page';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PageSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PaymentReason';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PayoutMethod';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PayoutMethodAlert';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Person';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PhoneNumberVerificationPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PopularCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PriceComparsion';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PricingCalculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromoApplicability';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromoMessage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromotionalBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromotionalBannerDisclaimer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Redirect';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReferFriendHeader';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RegionCode';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Regulator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RequestMoneyNewRequestEmail';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RequestMoneyPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Review';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Reviews';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReviewsTimeDictionary';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RobotsConfiguration';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Search';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SendToCountryModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SenderFormPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SendingPartnership';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoContactPoint';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoOrganizationStructuredData';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoSameAs';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ShareSocialLink';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SignIn';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Sitemap';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SmsVerificationCodePage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SocialProofingModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SolutionsModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Step';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'StepByStep';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'StickyBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TagLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextMeTheAppModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextWithColor';
                        name?: string | null;
                        inline?: boolean | null;
                        color?: string | null;
                        backgroundColor?: string | null;
                        align?: string | null;
                        sys: { __typename?: 'Sys'; id: string };
                        text?: {
                          __typename?: 'TextWithColorText';
                          json: Document;
                        } | null;
                      }
                    | {
                        __typename?: 'VerificationInProgress';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Video';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WeAreRegulated';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WebForm';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WhyChoose';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WhyChooseReason';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | null
                  >;
                  hyperlink: Array<
                    | {
                        __typename?: 'AccountDuplicationPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ActionResult';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppReview';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppReviews';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BackToSchool';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BackToSchoolCostCategory';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BackToSchoolCountryData';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BetterWayTop';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BlogContent';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BodyText';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Calculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CalculatorAppLeadingLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CalculatorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Card';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Cards';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexChartFilterOptions';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexCurrency';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexCurrencyCorridor';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexLineChart';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexRateAlerts';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexRateTable';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexSearch';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ChangePasswordPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTable';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTableCell';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTableRow';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ContextualPromoBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Correspondent';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CorrespondentLocations';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Correspondents';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Country';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CountryPayoutMethod';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CurrencyPricingConverter';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'EmbeddedTableEntry';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ExternalScript';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqFeedbackModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqFeedbackModuleItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqHero';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqModuleItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqPopularQuestions';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqSearchHelp';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqSearchHelpItems';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopicsModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopicsModuleItems';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FeatureFlag';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Footer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FooterNavigationGroup';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FooterNavigationGroupExtras';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ForgottenPasswordPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FullWidthBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'GenericErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HeroSlot';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HowDoesItWork';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HowItWorksStepRelaunch';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HowSendingWorks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'IconWithText';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Image';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'InformationDialog';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'JazzCashPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'JazzCashRequest';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LayoutFooter';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LayoutHeader';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LegalMessaging';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Link';
                        name?: string | null;
                        url?: string | null;
                        isInternal?: boolean | null;
                        label?: string | null;
                        ariaLabel?: string | null;
                        openInNewTab?: boolean | null;
                        gaTrackName?: string | null;
                        isDisplayedWhenLoggedIn?: boolean | null;
                        color?: string | null;
                        variant?: string | null;
                        underline?: string | null;
                        align?: string | null;
                        noFollow?: boolean | null;
                        sys: { __typename?: 'Sys'; id: string };
                        image?: {
                          __typename?: 'Asset';
                          url?: string | null;
                          title?: string | null;
                          width?: number | null;
                          height?: number | null;
                          alt?: string | null;
                        } | null;
                      }
                    | {
                        __typename?: 'ListOfCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LoginPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Logo';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Member';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MembersList';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Messages';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MfaFactors';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MfaVerification';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MobileAppLeadingLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MobileStoreData';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyRequestConfirmationDialog';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyRequestPay';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyRequestPaymentReason';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyTransfer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MoneyTransferCard';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundContent';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'OnfidoErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'OurContactInformation';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Page';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PageSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PaymentReason';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PayoutMethod';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PayoutMethodAlert';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Person';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PhoneNumberVerificationPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PopularCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PriceComparsion';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PricingCalculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromoApplicability';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromoMessage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromotionalBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromotionalBannerDisclaimer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Redirect';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReferFriendHeader';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RegionCode';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Regulator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RequestMoneyNewRequestEmail';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RequestMoneyPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Review';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Reviews';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReviewsTimeDictionary';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RobotsConfiguration';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Search';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SendToCountryModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SenderFormPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SendingPartnership';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoContactPoint';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoOrganizationStructuredData';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoSameAs';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ShareSocialLink';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SignIn';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Sitemap';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SmsVerificationCodePage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SocialProofingModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SolutionsModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Step';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'StepByStep';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'StickyBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TagLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextMeTheAppModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextWithColor';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'VerificationInProgress';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Video';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WeAreRegulated';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WebForm';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WhyChoose';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WhyChooseReason';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | null
                  >;
                };
              };
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type CexCurrencyDataFragment = {
  __typename?: 'CexCurrency';
  name?: string | null;
  countryName?: string | null;
  currencyCode?: string | null;
  countryCode?: string | null;
  currencyName?: string | null;
  pageSlug?: string | null;
};

export type CexCurrencyListQueryVariables = Exact<{
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CexCurrencyListQuery = {
  __typename?: 'Query';
  cexCurrencyCollection?: {
    __typename?: 'CexCurrencyCollection';
    items: Array<{
      __typename?: 'CexCurrency';
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
};

export type CexCurrencySlugsQueryVariables = Exact<{
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CexCurrencySlugsQuery = {
  __typename?: 'Query';
  cexCurrencyCollection?: {
    __typename?: 'CexCurrencyCollection';
    items: Array<{
      __typename?: 'CexCurrency';
      name?: string | null;
      countryName?: string | null;
      currencyCode?: string | null;
      countryCode?: string | null;
      currencyName?: string | null;
      pageSlug?: string | null;
    } | null>;
  } | null;
};

export type CexCurrencyBySlugQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CexCurrencyBySlugQuery = {
  __typename?: 'Query';
  cexCurrencyCollection?: {
    __typename?: 'CexCurrencyCollection';
    items: Array<{
      __typename?: 'CexCurrency';
      corridorGridTitle?: string | null;
      name?: string | null;
      countryName?: string | null;
      currencyCode?: string | null;
      countryCode?: string | null;
      currencyName?: string | null;
      pageSlug?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      description?: {
        __typename?: 'CexCurrencyDescription';
        json: Document;
        links: {
          __typename?: 'CexCurrencyDescriptionLinks';
          assets: {
            __typename?: 'CexCurrencyDescriptionAssets';
            block: Array<{
              __typename?: 'Asset';
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
            } | null>;
          };
          entries: {
            __typename?: 'CexCurrencyDescriptionEntries';
            block: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  name?: string | null;
                  align?: string | null;
                  position?: string | null;
                  width?: number | null;
                  height?: number | null;
                  sys: { __typename?: 'Sys'; id: string };
                  asset?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename: 'Video';
                  name?: string | null;
                  videoId?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  content?: {
                    __typename?: 'VideoContent';
                    json: Document;
                  } | null;
                  backgroundImage?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            inline: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            hyperlink: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
      faqContent?: {
        __typename?: 'CexCurrencyFaqContent';
        json: Document;
        links: {
          __typename?: 'CexCurrencyFaqContentLinks';
          assets: {
            __typename?: 'CexCurrencyFaqContentAssets';
            block: Array<{
              __typename?: 'Asset';
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
            } | null>;
          };
          entries: {
            __typename?: 'CexCurrencyFaqContentEntries';
            block: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  name?: string | null;
                  align?: string | null;
                  position?: string | null;
                  width?: number | null;
                  height?: number | null;
                  sys: { __typename?: 'Sys'; id: string };
                  asset?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename: 'Video';
                  name?: string | null;
                  videoId?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  content?: {
                    __typename?: 'VideoContent';
                    json: Document;
                  } | null;
                  backgroundImage?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            inline: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            hyperlink: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
      topCurrencyPairs?: {
        __typename?: 'CexCurrencyTopCurrencyPairsCollection';
        items: Array<{
          __typename?: 'CexCurrency';
          name?: string | null;
          pageSlug?: string | null;
          countryCode?: string | null;
          countryName?: string | null;
          currencyCode?: string | null;
          currencyName?: string | null;
          description?: {
            __typename?: 'CexCurrencyDescription';
            json: Document;
            links: {
              __typename?: 'CexCurrencyDescriptionLinks';
              assets: {
                __typename?: 'CexCurrencyDescriptionAssets';
                block: Array<{
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null>;
              };
              entries: {
                __typename?: 'CexCurrencyDescriptionEntries';
                block: Array<
                  | {
                      __typename?: 'AccountDuplicationPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ActionResult';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppReview';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppReviews';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchool';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchoolCostCategory';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchoolCountryData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BetterWayTop';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BlogContent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BodyText';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Calculator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CalculatorAppLeadingLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CalculatorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Card';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Cards';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexChartFilterOptions';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexCurrency';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexCurrencyCorridor';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexLineChart';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexRateAlerts';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexRateTable';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexSearch';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ChangePasswordPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTable';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTableCell';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTableRow';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ContextualPromoBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Correspondent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CorrespondentLocations';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Correspondents';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Country';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CountryPayoutMethod';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CurrencyPricingConverter';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'EmbeddedTableEntry';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ExternalScript';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqFeedbackModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqFeedbackModuleItem';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqHero';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqModuleItem';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqPopularQuestions';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqSearchHelp';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqSearchHelpItems';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqTopicsModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqTopicsModuleItems';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FeatureFlag';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Footer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FooterNavigationGroup';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FooterNavigationGroupExtras';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ForgottenPasswordPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FullWidthBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'GenericErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HeroSlot';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowDoesItWork';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowItWorksStepRelaunch';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowSendingWorks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'IconWithText';
                      name?: string | null;
                      sys: { __typename?: 'Sys'; id: string };
                      text?: {
                        __typename?: 'TextWithColor';
                        name?: string | null;
                        inline?: boolean | null;
                        color?: string | null;
                        backgroundColor?: string | null;
                        align?: string | null;
                        text?: {
                          __typename?: 'TextWithColorText';
                          json: Document;
                        } | null;
                      } | null;
                      icon?: {
                        __typename?: 'Asset';
                        url?: string | null;
                        title?: string | null;
                        width?: number | null;
                        height?: number | null;
                        alt?: string | null;
                      } | null;
                    }
                  | {
                      __typename?: 'Image';
                      name?: string | null;
                      align?: string | null;
                      position?: string | null;
                      width?: number | null;
                      height?: number | null;
                      sys: { __typename?: 'Sys'; id: string };
                      asset?: {
                        __typename?: 'Asset';
                        url?: string | null;
                        title?: string | null;
                        width?: number | null;
                        height?: number | null;
                        alt?: string | null;
                      } | null;
                    }
                  | {
                      __typename?: 'InformationDialog';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'JazzCashPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'JazzCashRequest';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LayoutFooter';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LayoutHeader';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LegalMessaging';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Link';
                      name?: string | null;
                      url?: string | null;
                      isInternal?: boolean | null;
                      label?: string | null;
                      ariaLabel?: string | null;
                      openInNewTab?: boolean | null;
                      gaTrackName?: string | null;
                      isDisplayedWhenLoggedIn?: boolean | null;
                      color?: string | null;
                      variant?: string | null;
                      underline?: string | null;
                      align?: string | null;
                      noFollow?: boolean | null;
                      sys: { __typename?: 'Sys'; id: string };
                      image?: {
                        __typename?: 'Asset';
                        url?: string | null;
                        title?: string | null;
                        width?: number | null;
                        height?: number | null;
                        alt?: string | null;
                      } | null;
                    }
                  | {
                      __typename?: 'ListOfCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LoginPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Logo';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Member';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MembersList';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Messages';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MfaFactors';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MfaVerification';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MobileAppLeadingLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MobileStoreData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestConfirmationDialog';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestPay';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestPaymentReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyTransfer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyTransferCard';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundContent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'OnfidoErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'OurContactInformation';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Page';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PageSection';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PaymentReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PayoutMethod';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PayoutMethodAlert';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Person';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PhoneNumberVerificationPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PopularCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PriceComparsion';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PricingCalculator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromoApplicability';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromoMessage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromotionalBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromotionalBannerDisclaimer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Redirect';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReferFriendHeader';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RegionCode';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Regulator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RequestMoneyNewRequestEmail';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RequestMoneyPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Review';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Reviews';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReviewsTimeDictionary';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RobotsConfiguration';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Search';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SendToCountryModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SenderFormPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SendingPartnership';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoContactPoint';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoOrganizationStructuredData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoSameAs';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ShareSocialLink';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SignIn';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Sitemap';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SmsVerificationCodePage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SocialProofingModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SolutionsModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Step';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'StepByStep';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'StickyBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TagLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextMeTheAppModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextSection';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      sys: { __typename?: 'Sys'; id: string };
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    }
                  | {
                      __typename?: 'VerificationInProgress';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename: 'Video';
                      name?: string | null;
                      videoId?: string | null;
                      sys: { __typename?: 'Sys'; id: string };
                      content?: {
                        __typename?: 'VideoContent';
                        json: Document;
                      } | null;
                      backgroundImage?: {
                        __typename?: 'Asset';
                        url?: string | null;
                        title?: string | null;
                        width?: number | null;
                        height?: number | null;
                        alt?: string | null;
                      } | null;
                    }
                  | {
                      __typename?: 'WeAreRegulated';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WebForm';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WhyChoose';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WhyChooseReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | null
                >;
                inline: Array<
                  | {
                      __typename?: 'AccountDuplicationPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ActionResult';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppReview';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppReviews';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchool';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchoolCostCategory';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchoolCountryData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BetterWayTop';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BlogContent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BodyText';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Calculator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CalculatorAppLeadingLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CalculatorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Card';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Cards';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexChartFilterOptions';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexCurrency';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexCurrencyCorridor';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexLineChart';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexRateAlerts';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexRateTable';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexSearch';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ChangePasswordPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTable';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTableCell';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTableRow';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ContextualPromoBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Correspondent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CorrespondentLocations';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Correspondents';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Country';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CountryPayoutMethod';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CurrencyPricingConverter';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'EmbeddedTableEntry';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ExternalScript';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqFeedbackModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqFeedbackModuleItem';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqHero';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqModuleItem';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqPopularQuestions';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqSearchHelp';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqSearchHelpItems';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqTopicsModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqTopicsModuleItems';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FeatureFlag';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Footer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FooterNavigationGroup';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FooterNavigationGroupExtras';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ForgottenPasswordPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FullWidthBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'GenericErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HeroSlot';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowDoesItWork';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowItWorksStepRelaunch';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowSendingWorks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'IconWithText';
                      name?: string | null;
                      sys: { __typename?: 'Sys'; id: string };
                      text?: {
                        __typename?: 'TextWithColor';
                        name?: string | null;
                        inline?: boolean | null;
                        color?: string | null;
                        backgroundColor?: string | null;
                        align?: string | null;
                        text?: {
                          __typename?: 'TextWithColorText';
                          json: Document;
                        } | null;
                      } | null;
                      icon?: {
                        __typename?: 'Asset';
                        url?: string | null;
                        title?: string | null;
                        width?: number | null;
                        height?: number | null;
                        alt?: string | null;
                      } | null;
                    }
                  | {
                      __typename?: 'Image';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'InformationDialog';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'JazzCashPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'JazzCashRequest';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LayoutFooter';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LayoutHeader';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LegalMessaging';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Link';
                      name?: string | null;
                      url?: string | null;
                      isInternal?: boolean | null;
                      label?: string | null;
                      ariaLabel?: string | null;
                      openInNewTab?: boolean | null;
                      gaTrackName?: string | null;
                      isDisplayedWhenLoggedIn?: boolean | null;
                      color?: string | null;
                      variant?: string | null;
                      underline?: string | null;
                      align?: string | null;
                      noFollow?: boolean | null;
                      sys: { __typename?: 'Sys'; id: string };
                      image?: {
                        __typename?: 'Asset';
                        url?: string | null;
                        title?: string | null;
                        width?: number | null;
                        height?: number | null;
                        alt?: string | null;
                      } | null;
                    }
                  | {
                      __typename?: 'ListOfCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LoginPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Logo';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Member';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MembersList';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Messages';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MfaFactors';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MfaVerification';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MobileAppLeadingLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MobileStoreData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestConfirmationDialog';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestPay';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestPaymentReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyTransfer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyTransferCard';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundContent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'OnfidoErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'OurContactInformation';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Page';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PageSection';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PaymentReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PayoutMethod';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PayoutMethodAlert';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Person';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PhoneNumberVerificationPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PopularCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PriceComparsion';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PricingCalculator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromoApplicability';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromoMessage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromotionalBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromotionalBannerDisclaimer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Redirect';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReferFriendHeader';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RegionCode';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Regulator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RequestMoneyNewRequestEmail';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RequestMoneyPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Review';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Reviews';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReviewsTimeDictionary';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RobotsConfiguration';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Search';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SendToCountryModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SenderFormPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SendingPartnership';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoContactPoint';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoOrganizationStructuredData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoSameAs';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ShareSocialLink';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SignIn';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Sitemap';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SmsVerificationCodePage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SocialProofingModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SolutionsModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Step';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'StepByStep';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'StickyBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TagLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextMeTheAppModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextSection';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      sys: { __typename?: 'Sys'; id: string };
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    }
                  | {
                      __typename?: 'VerificationInProgress';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Video';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WeAreRegulated';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WebForm';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WhyChoose';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WhyChooseReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | null
                >;
                hyperlink: Array<
                  | {
                      __typename?: 'AccountDuplicationPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ActionResult';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppReview';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppReviews';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchool';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchoolCostCategory';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchoolCountryData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BetterWayTop';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BlogContent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BodyText';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Calculator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CalculatorAppLeadingLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CalculatorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Card';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Cards';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexChartFilterOptions';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexCurrency';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexCurrencyCorridor';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexLineChart';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexRateAlerts';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexRateTable';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexSearch';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ChangePasswordPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTable';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTableCell';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTableRow';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ContextualPromoBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Correspondent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CorrespondentLocations';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Correspondents';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Country';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CountryPayoutMethod';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CurrencyPricingConverter';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'EmbeddedTableEntry';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ExternalScript';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqFeedbackModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqFeedbackModuleItem';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqHero';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqModuleItem';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqPopularQuestions';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqSearchHelp';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqSearchHelpItems';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqTopicsModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqTopicsModuleItems';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FeatureFlag';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Footer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FooterNavigationGroup';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FooterNavigationGroupExtras';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ForgottenPasswordPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FullWidthBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'GenericErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HeroSlot';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowDoesItWork';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowItWorksStepRelaunch';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowSendingWorks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'IconWithText';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Image';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'InformationDialog';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'JazzCashPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'JazzCashRequest';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LayoutFooter';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LayoutHeader';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LegalMessaging';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Link';
                      name?: string | null;
                      url?: string | null;
                      isInternal?: boolean | null;
                      label?: string | null;
                      ariaLabel?: string | null;
                      openInNewTab?: boolean | null;
                      gaTrackName?: string | null;
                      isDisplayedWhenLoggedIn?: boolean | null;
                      color?: string | null;
                      variant?: string | null;
                      underline?: string | null;
                      align?: string | null;
                      noFollow?: boolean | null;
                      sys: { __typename?: 'Sys'; id: string };
                      image?: {
                        __typename?: 'Asset';
                        url?: string | null;
                        title?: string | null;
                        width?: number | null;
                        height?: number | null;
                        alt?: string | null;
                      } | null;
                    }
                  | {
                      __typename?: 'ListOfCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LoginPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Logo';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Member';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MembersList';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Messages';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MfaFactors';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MfaVerification';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MobileAppLeadingLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MobileStoreData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestConfirmationDialog';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestPay';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestPaymentReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyTransfer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyTransferCard';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundContent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'OnfidoErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'OurContactInformation';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Page';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PageSection';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PaymentReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PayoutMethod';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PayoutMethodAlert';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Person';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PhoneNumberVerificationPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PopularCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PriceComparsion';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PricingCalculator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromoApplicability';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromoMessage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromotionalBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromotionalBannerDisclaimer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Redirect';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReferFriendHeader';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RegionCode';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Regulator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RequestMoneyNewRequestEmail';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RequestMoneyPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Review';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Reviews';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReviewsTimeDictionary';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RobotsConfiguration';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Search';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SendToCountryModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SenderFormPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SendingPartnership';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoContactPoint';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoOrganizationStructuredData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoSameAs';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ShareSocialLink';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SignIn';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Sitemap';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SmsVerificationCodePage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SocialProofingModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SolutionsModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Step';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'StepByStep';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'StickyBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TagLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextMeTheAppModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextSection';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextWithColor';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'VerificationInProgress';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Video';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WeAreRegulated';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WebForm';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WhyChoose';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WhyChooseReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | null
                >;
              };
            };
          } | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type CexCurrencyByCodeQueryVariables = Exact<{
  code?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CexCurrencyByCodeQuery = {
  __typename?: 'Query';
  cexCurrencyCollection?: {
    __typename?: 'CexCurrencyCollection';
    items: Array<{
      __typename?: 'CexCurrency';
      corridorGridTitle?: string | null;
      name?: string | null;
      countryName?: string | null;
      currencyCode?: string | null;
      countryCode?: string | null;
      currencyName?: string | null;
      pageSlug?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      description?: {
        __typename?: 'CexCurrencyDescription';
        json: Document;
        links: {
          __typename?: 'CexCurrencyDescriptionLinks';
          assets: {
            __typename?: 'CexCurrencyDescriptionAssets';
            block: Array<{
              __typename?: 'Asset';
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
            } | null>;
          };
          entries: {
            __typename?: 'CexCurrencyDescriptionEntries';
            block: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  name?: string | null;
                  align?: string | null;
                  position?: string | null;
                  width?: number | null;
                  height?: number | null;
                  sys: { __typename?: 'Sys'; id: string };
                  asset?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename: 'Video';
                  name?: string | null;
                  videoId?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  content?: {
                    __typename?: 'VideoContent';
                    json: Document;
                  } | null;
                  backgroundImage?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            inline: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            hyperlink: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
      faqContent?: {
        __typename?: 'CexCurrencyFaqContent';
        json: Document;
        links: {
          __typename?: 'CexCurrencyFaqContentLinks';
          assets: {
            __typename?: 'CexCurrencyFaqContentAssets';
            block: Array<{
              __typename?: 'Asset';
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
            } | null>;
          };
          entries: {
            __typename?: 'CexCurrencyFaqContentEntries';
            block: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  name?: string | null;
                  align?: string | null;
                  position?: string | null;
                  width?: number | null;
                  height?: number | null;
                  sys: { __typename?: 'Sys'; id: string };
                  asset?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename: 'Video';
                  name?: string | null;
                  videoId?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  content?: {
                    __typename?: 'VideoContent';
                    json: Document;
                  } | null;
                  backgroundImage?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            inline: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            hyperlink: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
      topCurrencyPairs?: {
        __typename?: 'CexCurrencyTopCurrencyPairsCollection';
        items: Array<{
          __typename?: 'CexCurrency';
          name?: string | null;
          pageSlug?: string | null;
          countryCode?: string | null;
          countryName?: string | null;
          currencyCode?: string | null;
          currencyName?: string | null;
          description?: {
            __typename?: 'CexCurrencyDescription';
            json: Document;
            links: {
              __typename?: 'CexCurrencyDescriptionLinks';
              assets: {
                __typename?: 'CexCurrencyDescriptionAssets';
                block: Array<{
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null>;
              };
              entries: {
                __typename?: 'CexCurrencyDescriptionEntries';
                block: Array<
                  | {
                      __typename?: 'AccountDuplicationPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ActionResult';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppReview';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppReviews';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchool';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchoolCostCategory';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchoolCountryData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BetterWayTop';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BlogContent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BodyText';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Calculator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CalculatorAppLeadingLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CalculatorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Card';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Cards';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexChartFilterOptions';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexCurrency';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexCurrencyCorridor';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexLineChart';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexRateAlerts';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexRateTable';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexSearch';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ChangePasswordPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTable';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTableCell';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTableRow';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ContextualPromoBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Correspondent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CorrespondentLocations';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Correspondents';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Country';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CountryPayoutMethod';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CurrencyPricingConverter';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'EmbeddedTableEntry';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ExternalScript';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqFeedbackModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqFeedbackModuleItem';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqHero';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqModuleItem';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqPopularQuestions';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqSearchHelp';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqSearchHelpItems';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqTopicsModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqTopicsModuleItems';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FeatureFlag';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Footer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FooterNavigationGroup';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FooterNavigationGroupExtras';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ForgottenPasswordPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FullWidthBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'GenericErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HeroSlot';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowDoesItWork';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowItWorksStepRelaunch';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowSendingWorks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'IconWithText';
                      name?: string | null;
                      sys: { __typename?: 'Sys'; id: string };
                      text?: {
                        __typename?: 'TextWithColor';
                        name?: string | null;
                        inline?: boolean | null;
                        color?: string | null;
                        backgroundColor?: string | null;
                        align?: string | null;
                        text?: {
                          __typename?: 'TextWithColorText';
                          json: Document;
                        } | null;
                      } | null;
                      icon?: {
                        __typename?: 'Asset';
                        url?: string | null;
                        title?: string | null;
                        width?: number | null;
                        height?: number | null;
                        alt?: string | null;
                      } | null;
                    }
                  | {
                      __typename?: 'Image';
                      name?: string | null;
                      align?: string | null;
                      position?: string | null;
                      width?: number | null;
                      height?: number | null;
                      sys: { __typename?: 'Sys'; id: string };
                      asset?: {
                        __typename?: 'Asset';
                        url?: string | null;
                        title?: string | null;
                        width?: number | null;
                        height?: number | null;
                        alt?: string | null;
                      } | null;
                    }
                  | {
                      __typename?: 'InformationDialog';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'JazzCashPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'JazzCashRequest';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LayoutFooter';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LayoutHeader';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LegalMessaging';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Link';
                      name?: string | null;
                      url?: string | null;
                      isInternal?: boolean | null;
                      label?: string | null;
                      ariaLabel?: string | null;
                      openInNewTab?: boolean | null;
                      gaTrackName?: string | null;
                      isDisplayedWhenLoggedIn?: boolean | null;
                      color?: string | null;
                      variant?: string | null;
                      underline?: string | null;
                      align?: string | null;
                      noFollow?: boolean | null;
                      sys: { __typename?: 'Sys'; id: string };
                      image?: {
                        __typename?: 'Asset';
                        url?: string | null;
                        title?: string | null;
                        width?: number | null;
                        height?: number | null;
                        alt?: string | null;
                      } | null;
                    }
                  | {
                      __typename?: 'ListOfCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LoginPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Logo';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Member';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MembersList';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Messages';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MfaFactors';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MfaVerification';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MobileAppLeadingLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MobileStoreData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestConfirmationDialog';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestPay';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestPaymentReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyTransfer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyTransferCard';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundContent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'OnfidoErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'OurContactInformation';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Page';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PageSection';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PaymentReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PayoutMethod';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PayoutMethodAlert';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Person';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PhoneNumberVerificationPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PopularCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PriceComparsion';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PricingCalculator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromoApplicability';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromoMessage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromotionalBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromotionalBannerDisclaimer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Redirect';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReferFriendHeader';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RegionCode';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Regulator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RequestMoneyNewRequestEmail';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RequestMoneyPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Review';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Reviews';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReviewsTimeDictionary';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RobotsConfiguration';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Search';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SendToCountryModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SenderFormPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SendingPartnership';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoContactPoint';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoOrganizationStructuredData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoSameAs';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ShareSocialLink';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SignIn';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Sitemap';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SmsVerificationCodePage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SocialProofingModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SolutionsModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Step';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'StepByStep';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'StickyBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TagLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextMeTheAppModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextSection';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      sys: { __typename?: 'Sys'; id: string };
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    }
                  | {
                      __typename?: 'VerificationInProgress';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename: 'Video';
                      name?: string | null;
                      videoId?: string | null;
                      sys: { __typename?: 'Sys'; id: string };
                      content?: {
                        __typename?: 'VideoContent';
                        json: Document;
                      } | null;
                      backgroundImage?: {
                        __typename?: 'Asset';
                        url?: string | null;
                        title?: string | null;
                        width?: number | null;
                        height?: number | null;
                        alt?: string | null;
                      } | null;
                    }
                  | {
                      __typename?: 'WeAreRegulated';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WebForm';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WhyChoose';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WhyChooseReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | null
                >;
                inline: Array<
                  | {
                      __typename?: 'AccountDuplicationPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ActionResult';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppReview';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppReviews';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchool';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchoolCostCategory';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchoolCountryData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BetterWayTop';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BlogContent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BodyText';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Calculator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CalculatorAppLeadingLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CalculatorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Card';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Cards';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexChartFilterOptions';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexCurrency';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexCurrencyCorridor';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexLineChart';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexRateAlerts';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexRateTable';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexSearch';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ChangePasswordPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTable';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTableCell';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTableRow';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ContextualPromoBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Correspondent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CorrespondentLocations';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Correspondents';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Country';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CountryPayoutMethod';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CurrencyPricingConverter';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'EmbeddedTableEntry';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ExternalScript';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqFeedbackModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqFeedbackModuleItem';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqHero';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqModuleItem';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqPopularQuestions';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqSearchHelp';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqSearchHelpItems';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqTopicsModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqTopicsModuleItems';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FeatureFlag';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Footer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FooterNavigationGroup';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FooterNavigationGroupExtras';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ForgottenPasswordPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FullWidthBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'GenericErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HeroSlot';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowDoesItWork';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowItWorksStepRelaunch';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowSendingWorks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'IconWithText';
                      name?: string | null;
                      sys: { __typename?: 'Sys'; id: string };
                      text?: {
                        __typename?: 'TextWithColor';
                        name?: string | null;
                        inline?: boolean | null;
                        color?: string | null;
                        backgroundColor?: string | null;
                        align?: string | null;
                        text?: {
                          __typename?: 'TextWithColorText';
                          json: Document;
                        } | null;
                      } | null;
                      icon?: {
                        __typename?: 'Asset';
                        url?: string | null;
                        title?: string | null;
                        width?: number | null;
                        height?: number | null;
                        alt?: string | null;
                      } | null;
                    }
                  | {
                      __typename?: 'Image';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'InformationDialog';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'JazzCashPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'JazzCashRequest';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LayoutFooter';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LayoutHeader';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LegalMessaging';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Link';
                      name?: string | null;
                      url?: string | null;
                      isInternal?: boolean | null;
                      label?: string | null;
                      ariaLabel?: string | null;
                      openInNewTab?: boolean | null;
                      gaTrackName?: string | null;
                      isDisplayedWhenLoggedIn?: boolean | null;
                      color?: string | null;
                      variant?: string | null;
                      underline?: string | null;
                      align?: string | null;
                      noFollow?: boolean | null;
                      sys: { __typename?: 'Sys'; id: string };
                      image?: {
                        __typename?: 'Asset';
                        url?: string | null;
                        title?: string | null;
                        width?: number | null;
                        height?: number | null;
                        alt?: string | null;
                      } | null;
                    }
                  | {
                      __typename?: 'ListOfCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LoginPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Logo';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Member';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MembersList';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Messages';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MfaFactors';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MfaVerification';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MobileAppLeadingLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MobileStoreData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestConfirmationDialog';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestPay';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestPaymentReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyTransfer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyTransferCard';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundContent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'OnfidoErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'OurContactInformation';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Page';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PageSection';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PaymentReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PayoutMethod';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PayoutMethodAlert';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Person';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PhoneNumberVerificationPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PopularCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PriceComparsion';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PricingCalculator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromoApplicability';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromoMessage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromotionalBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromotionalBannerDisclaimer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Redirect';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReferFriendHeader';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RegionCode';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Regulator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RequestMoneyNewRequestEmail';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RequestMoneyPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Review';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Reviews';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReviewsTimeDictionary';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RobotsConfiguration';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Search';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SendToCountryModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SenderFormPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SendingPartnership';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoContactPoint';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoOrganizationStructuredData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoSameAs';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ShareSocialLink';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SignIn';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Sitemap';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SmsVerificationCodePage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SocialProofingModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SolutionsModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Step';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'StepByStep';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'StickyBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TagLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextMeTheAppModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextSection';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      sys: { __typename?: 'Sys'; id: string };
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    }
                  | {
                      __typename?: 'VerificationInProgress';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Video';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WeAreRegulated';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WebForm';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WhyChoose';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WhyChooseReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | null
                >;
                hyperlink: Array<
                  | {
                      __typename?: 'AccountDuplicationPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ActionResult';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppReview';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppReviews';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchool';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchoolCostCategory';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchoolCountryData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BetterWayTop';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BlogContent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BodyText';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Calculator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CalculatorAppLeadingLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CalculatorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Card';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Cards';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexChartFilterOptions';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexCurrency';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexCurrencyCorridor';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexLineChart';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexRateAlerts';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexRateTable';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexSearch';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ChangePasswordPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTable';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTableCell';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTableRow';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ContextualPromoBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Correspondent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CorrespondentLocations';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Correspondents';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Country';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CountryPayoutMethod';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CurrencyPricingConverter';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'EmbeddedTableEntry';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ExternalScript';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqFeedbackModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqFeedbackModuleItem';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqHero';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqModuleItem';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqPopularQuestions';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqSearchHelp';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqSearchHelpItems';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqTopicsModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqTopicsModuleItems';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FeatureFlag';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Footer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FooterNavigationGroup';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FooterNavigationGroupExtras';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ForgottenPasswordPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FullWidthBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'GenericErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HeroSlot';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowDoesItWork';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowItWorksStepRelaunch';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowSendingWorks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'IconWithText';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Image';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'InformationDialog';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'JazzCashPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'JazzCashRequest';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LayoutFooter';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LayoutHeader';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LegalMessaging';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Link';
                      name?: string | null;
                      url?: string | null;
                      isInternal?: boolean | null;
                      label?: string | null;
                      ariaLabel?: string | null;
                      openInNewTab?: boolean | null;
                      gaTrackName?: string | null;
                      isDisplayedWhenLoggedIn?: boolean | null;
                      color?: string | null;
                      variant?: string | null;
                      underline?: string | null;
                      align?: string | null;
                      noFollow?: boolean | null;
                      sys: { __typename?: 'Sys'; id: string };
                      image?: {
                        __typename?: 'Asset';
                        url?: string | null;
                        title?: string | null;
                        width?: number | null;
                        height?: number | null;
                        alt?: string | null;
                      } | null;
                    }
                  | {
                      __typename?: 'ListOfCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LoginPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Logo';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Member';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MembersList';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Messages';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MfaFactors';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MfaVerification';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MobileAppLeadingLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MobileStoreData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestConfirmationDialog';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestPay';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestPaymentReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyTransfer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyTransferCard';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundContent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'OnfidoErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'OurContactInformation';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Page';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PageSection';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PaymentReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PayoutMethod';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PayoutMethodAlert';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Person';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PhoneNumberVerificationPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PopularCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PriceComparsion';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PricingCalculator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromoApplicability';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromoMessage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromotionalBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromotionalBannerDisclaimer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Redirect';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReferFriendHeader';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RegionCode';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Regulator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RequestMoneyNewRequestEmail';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RequestMoneyPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Review';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Reviews';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReviewsTimeDictionary';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RobotsConfiguration';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Search';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SendToCountryModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SenderFormPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SendingPartnership';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoContactPoint';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoOrganizationStructuredData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoSameAs';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ShareSocialLink';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SignIn';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Sitemap';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SmsVerificationCodePage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SocialProofingModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SolutionsModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Step';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'StepByStep';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'StickyBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TagLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextMeTheAppModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextSection';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextWithColor';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'VerificationInProgress';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Video';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WeAreRegulated';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WebForm';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WhyChoose';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WhyChooseReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | null
                >;
              };
            };
          } | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type CexCurrencyByCurrencyAndCountryCodeQueryVariables = Exact<{
  currencyCode?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  countryCode?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CexCurrencyByCurrencyAndCountryCodeQuery = {
  __typename?: 'Query';
  cexCurrencyCollection?: {
    __typename?: 'CexCurrencyCollection';
    items: Array<{
      __typename?: 'CexCurrency';
      corridorGridTitle?: string | null;
      name?: string | null;
      countryName?: string | null;
      currencyCode?: string | null;
      countryCode?: string | null;
      currencyName?: string | null;
      pageSlug?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      description?: {
        __typename?: 'CexCurrencyDescription';
        json: Document;
        links: {
          __typename?: 'CexCurrencyDescriptionLinks';
          assets: {
            __typename?: 'CexCurrencyDescriptionAssets';
            block: Array<{
              __typename?: 'Asset';
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
            } | null>;
          };
          entries: {
            __typename?: 'CexCurrencyDescriptionEntries';
            block: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  name?: string | null;
                  align?: string | null;
                  position?: string | null;
                  width?: number | null;
                  height?: number | null;
                  sys: { __typename?: 'Sys'; id: string };
                  asset?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename: 'Video';
                  name?: string | null;
                  videoId?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  content?: {
                    __typename?: 'VideoContent';
                    json: Document;
                  } | null;
                  backgroundImage?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            inline: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            hyperlink: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
      faqContent?: {
        __typename?: 'CexCurrencyFaqContent';
        json: Document;
        links: {
          __typename?: 'CexCurrencyFaqContentLinks';
          assets: {
            __typename?: 'CexCurrencyFaqContentAssets';
            block: Array<{
              __typename?: 'Asset';
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
            } | null>;
          };
          entries: {
            __typename?: 'CexCurrencyFaqContentEntries';
            block: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  name?: string | null;
                  align?: string | null;
                  position?: string | null;
                  width?: number | null;
                  height?: number | null;
                  sys: { __typename?: 'Sys'; id: string };
                  asset?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename: 'Video';
                  name?: string | null;
                  videoId?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  content?: {
                    __typename?: 'VideoContent';
                    json: Document;
                  } | null;
                  backgroundImage?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            inline: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            hyperlink: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
      topCurrencyPairs?: {
        __typename?: 'CexCurrencyTopCurrencyPairsCollection';
        items: Array<{
          __typename?: 'CexCurrency';
          name?: string | null;
          pageSlug?: string | null;
          countryCode?: string | null;
          countryName?: string | null;
          currencyCode?: string | null;
          currencyName?: string | null;
          description?: {
            __typename?: 'CexCurrencyDescription';
            json: Document;
            links: {
              __typename?: 'CexCurrencyDescriptionLinks';
              assets: {
                __typename?: 'CexCurrencyDescriptionAssets';
                block: Array<{
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null>;
              };
              entries: {
                __typename?: 'CexCurrencyDescriptionEntries';
                block: Array<
                  | {
                      __typename?: 'AccountDuplicationPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ActionResult';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppReview';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppReviews';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchool';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchoolCostCategory';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchoolCountryData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BetterWayTop';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BlogContent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BodyText';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Calculator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CalculatorAppLeadingLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CalculatorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Card';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Cards';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexChartFilterOptions';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexCurrency';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexCurrencyCorridor';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexLineChart';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexRateAlerts';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexRateTable';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexSearch';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ChangePasswordPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTable';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTableCell';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTableRow';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ContextualPromoBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Correspondent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CorrespondentLocations';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Correspondents';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Country';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CountryPayoutMethod';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CurrencyPricingConverter';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'EmbeddedTableEntry';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ExternalScript';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqFeedbackModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqFeedbackModuleItem';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqHero';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqModuleItem';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqPopularQuestions';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqSearchHelp';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqSearchHelpItems';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqTopicsModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqTopicsModuleItems';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FeatureFlag';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Footer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FooterNavigationGroup';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FooterNavigationGroupExtras';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ForgottenPasswordPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FullWidthBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'GenericErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HeroSlot';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowDoesItWork';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowItWorksStepRelaunch';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowSendingWorks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'IconWithText';
                      name?: string | null;
                      sys: { __typename?: 'Sys'; id: string };
                      text?: {
                        __typename?: 'TextWithColor';
                        name?: string | null;
                        inline?: boolean | null;
                        color?: string | null;
                        backgroundColor?: string | null;
                        align?: string | null;
                        text?: {
                          __typename?: 'TextWithColorText';
                          json: Document;
                        } | null;
                      } | null;
                      icon?: {
                        __typename?: 'Asset';
                        url?: string | null;
                        title?: string | null;
                        width?: number | null;
                        height?: number | null;
                        alt?: string | null;
                      } | null;
                    }
                  | {
                      __typename?: 'Image';
                      name?: string | null;
                      align?: string | null;
                      position?: string | null;
                      width?: number | null;
                      height?: number | null;
                      sys: { __typename?: 'Sys'; id: string };
                      asset?: {
                        __typename?: 'Asset';
                        url?: string | null;
                        title?: string | null;
                        width?: number | null;
                        height?: number | null;
                        alt?: string | null;
                      } | null;
                    }
                  | {
                      __typename?: 'InformationDialog';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'JazzCashPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'JazzCashRequest';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LayoutFooter';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LayoutHeader';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LegalMessaging';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Link';
                      name?: string | null;
                      url?: string | null;
                      isInternal?: boolean | null;
                      label?: string | null;
                      ariaLabel?: string | null;
                      openInNewTab?: boolean | null;
                      gaTrackName?: string | null;
                      isDisplayedWhenLoggedIn?: boolean | null;
                      color?: string | null;
                      variant?: string | null;
                      underline?: string | null;
                      align?: string | null;
                      noFollow?: boolean | null;
                      sys: { __typename?: 'Sys'; id: string };
                      image?: {
                        __typename?: 'Asset';
                        url?: string | null;
                        title?: string | null;
                        width?: number | null;
                        height?: number | null;
                        alt?: string | null;
                      } | null;
                    }
                  | {
                      __typename?: 'ListOfCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LoginPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Logo';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Member';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MembersList';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Messages';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MfaFactors';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MfaVerification';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MobileAppLeadingLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MobileStoreData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestConfirmationDialog';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestPay';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestPaymentReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyTransfer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyTransferCard';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundContent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'OnfidoErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'OurContactInformation';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Page';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PageSection';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PaymentReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PayoutMethod';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PayoutMethodAlert';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Person';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PhoneNumberVerificationPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PopularCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PriceComparsion';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PricingCalculator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromoApplicability';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromoMessage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromotionalBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromotionalBannerDisclaimer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Redirect';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReferFriendHeader';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RegionCode';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Regulator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RequestMoneyNewRequestEmail';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RequestMoneyPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Review';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Reviews';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReviewsTimeDictionary';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RobotsConfiguration';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Search';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SendToCountryModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SenderFormPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SendingPartnership';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoContactPoint';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoOrganizationStructuredData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoSameAs';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ShareSocialLink';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SignIn';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Sitemap';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SmsVerificationCodePage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SocialProofingModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SolutionsModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Step';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'StepByStep';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'StickyBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TagLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextMeTheAppModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextSection';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      sys: { __typename?: 'Sys'; id: string };
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    }
                  | {
                      __typename?: 'VerificationInProgress';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename: 'Video';
                      name?: string | null;
                      videoId?: string | null;
                      sys: { __typename?: 'Sys'; id: string };
                      content?: {
                        __typename?: 'VideoContent';
                        json: Document;
                      } | null;
                      backgroundImage?: {
                        __typename?: 'Asset';
                        url?: string | null;
                        title?: string | null;
                        width?: number | null;
                        height?: number | null;
                        alt?: string | null;
                      } | null;
                    }
                  | {
                      __typename?: 'WeAreRegulated';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WebForm';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WhyChoose';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WhyChooseReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | null
                >;
                inline: Array<
                  | {
                      __typename?: 'AccountDuplicationPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ActionResult';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppReview';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppReviews';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchool';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchoolCostCategory';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchoolCountryData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BetterWayTop';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BlogContent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BodyText';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Calculator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CalculatorAppLeadingLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CalculatorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Card';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Cards';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexChartFilterOptions';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexCurrency';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexCurrencyCorridor';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexLineChart';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexRateAlerts';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexRateTable';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexSearch';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ChangePasswordPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTable';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTableCell';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTableRow';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ContextualPromoBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Correspondent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CorrespondentLocations';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Correspondents';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Country';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CountryPayoutMethod';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CurrencyPricingConverter';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'EmbeddedTableEntry';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ExternalScript';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqFeedbackModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqFeedbackModuleItem';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqHero';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqModuleItem';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqPopularQuestions';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqSearchHelp';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqSearchHelpItems';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqTopicsModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqTopicsModuleItems';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FeatureFlag';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Footer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FooterNavigationGroup';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FooterNavigationGroupExtras';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ForgottenPasswordPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FullWidthBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'GenericErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HeroSlot';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowDoesItWork';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowItWorksStepRelaunch';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowSendingWorks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'IconWithText';
                      name?: string | null;
                      sys: { __typename?: 'Sys'; id: string };
                      text?: {
                        __typename?: 'TextWithColor';
                        name?: string | null;
                        inline?: boolean | null;
                        color?: string | null;
                        backgroundColor?: string | null;
                        align?: string | null;
                        text?: {
                          __typename?: 'TextWithColorText';
                          json: Document;
                        } | null;
                      } | null;
                      icon?: {
                        __typename?: 'Asset';
                        url?: string | null;
                        title?: string | null;
                        width?: number | null;
                        height?: number | null;
                        alt?: string | null;
                      } | null;
                    }
                  | {
                      __typename?: 'Image';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'InformationDialog';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'JazzCashPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'JazzCashRequest';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LayoutFooter';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LayoutHeader';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LegalMessaging';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Link';
                      name?: string | null;
                      url?: string | null;
                      isInternal?: boolean | null;
                      label?: string | null;
                      ariaLabel?: string | null;
                      openInNewTab?: boolean | null;
                      gaTrackName?: string | null;
                      isDisplayedWhenLoggedIn?: boolean | null;
                      color?: string | null;
                      variant?: string | null;
                      underline?: string | null;
                      align?: string | null;
                      noFollow?: boolean | null;
                      sys: { __typename?: 'Sys'; id: string };
                      image?: {
                        __typename?: 'Asset';
                        url?: string | null;
                        title?: string | null;
                        width?: number | null;
                        height?: number | null;
                        alt?: string | null;
                      } | null;
                    }
                  | {
                      __typename?: 'ListOfCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LoginPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Logo';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Member';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MembersList';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Messages';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MfaFactors';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MfaVerification';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MobileAppLeadingLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MobileStoreData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestConfirmationDialog';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestPay';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestPaymentReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyTransfer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyTransferCard';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundContent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'OnfidoErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'OurContactInformation';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Page';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PageSection';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PaymentReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PayoutMethod';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PayoutMethodAlert';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Person';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PhoneNumberVerificationPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PopularCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PriceComparsion';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PricingCalculator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromoApplicability';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromoMessage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromotionalBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromotionalBannerDisclaimer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Redirect';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReferFriendHeader';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RegionCode';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Regulator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RequestMoneyNewRequestEmail';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RequestMoneyPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Review';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Reviews';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReviewsTimeDictionary';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RobotsConfiguration';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Search';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SendToCountryModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SenderFormPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SendingPartnership';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoContactPoint';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoOrganizationStructuredData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoSameAs';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ShareSocialLink';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SignIn';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Sitemap';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SmsVerificationCodePage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SocialProofingModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SolutionsModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Step';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'StepByStep';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'StickyBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TagLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextMeTheAppModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextSection';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      sys: { __typename?: 'Sys'; id: string };
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    }
                  | {
                      __typename?: 'VerificationInProgress';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Video';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WeAreRegulated';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WebForm';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WhyChoose';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WhyChooseReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | null
                >;
                hyperlink: Array<
                  | {
                      __typename?: 'AccountDuplicationPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ActionResult';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppReview';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'AppReviews';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchool';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchoolCostCategory';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BackToSchoolCountryData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BetterWayTop';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BlogContent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'BodyText';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Calculator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CalculatorAppLeadingLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CalculatorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Card';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Cards';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexChartFilterOptions';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexCurrency';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexCurrencyCorridor';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexLineChart';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexRateAlerts';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexRateTable';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CexSearch';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ChangePasswordPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTable';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTableCell';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ComparisonTableRow';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ContextualPromoBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Correspondent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CorrespondentLocations';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Correspondents';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Country';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CountryPayoutMethod';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'CurrencyPricingConverter';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'EmbeddedTableEntry';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ExternalScript';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqFeedbackModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqFeedbackModuleItem';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqHero';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqModuleItem';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqPopularQuestions';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqSearchHelp';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqSearchHelpItems';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqTopicsModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FaqTopicsModuleItems';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FeatureFlag';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Footer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FooterNavigationGroup';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FooterNavigationGroupExtras';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ForgottenPasswordPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'FullWidthBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'GenericErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HeroSlot';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowDoesItWork';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowItWorksStepRelaunch';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'HowSendingWorks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'IconWithText';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Image';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'InformationDialog';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'JazzCashPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'JazzCashRequest';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LayoutFooter';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LayoutHeader';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LegalMessaging';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Link';
                      name?: string | null;
                      url?: string | null;
                      isInternal?: boolean | null;
                      label?: string | null;
                      ariaLabel?: string | null;
                      openInNewTab?: boolean | null;
                      gaTrackName?: string | null;
                      isDisplayedWhenLoggedIn?: boolean | null;
                      color?: string | null;
                      variant?: string | null;
                      underline?: string | null;
                      align?: string | null;
                      noFollow?: boolean | null;
                      sys: { __typename?: 'Sys'; id: string };
                      image?: {
                        __typename?: 'Asset';
                        url?: string | null;
                        title?: string | null;
                        width?: number | null;
                        height?: number | null;
                        alt?: string | null;
                      } | null;
                    }
                  | {
                      __typename?: 'ListOfCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'LoginPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Logo';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Member';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MembersList';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Messages';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MfaFactors';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MfaVerification';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MobileAppLeadingLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MobileStoreData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestConfirmationDialog';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestPay';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyRequestPaymentReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyTransfer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'MoneyTransferCard';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundContent';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'NotFoundPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'OnfidoErrorPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'OurContactInformation';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Page';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PageSection';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PaymentReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PayoutMethod';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PayoutMethodAlert';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Person';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PhoneNumberVerificationPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PopularCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PriceComparsion';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PricingCalculator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromoApplicability';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromoMessage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromotionalBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'PromotionalBannerDisclaimer';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Redirect';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReferFriendHeader';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RegionCode';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Regulator';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RequestMoneyNewRequestEmail';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RequestMoneyPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Review';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Reviews';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ReviewsTimeDictionary';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'RobotsConfiguration';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Search';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SendToCountryModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SenderFormPage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SendingPartnership';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoContactPoint';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoOrganizationStructuredData';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SeoSameAs';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'ShareSocialLink';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SignIn';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Sitemap';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SmsVerificationCodePage';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SocialProofingModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'SolutionsModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Step';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'StepByStep';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'StickyBanner';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TagLinks';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextMeTheAppModule';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextSection';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'TextWithColor';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'VerificationInProgress';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'Video';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WeAreRegulated';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WebForm';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WhyChoose';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | {
                      __typename?: 'WhyChooseReason';
                      sys: { __typename?: 'Sys'; id: string };
                    }
                  | null
                >;
              };
            };
          } | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type FeatureFlagsQueryVariables = Exact<{
  preview: Scalars['Boolean']['input'];
}>;

export type FeatureFlagsQuery = {
  __typename?: 'Query';
  featureFlagCollection?: {
    __typename?: 'FeatureFlagCollection';
    items: Array<{
      __typename?: 'FeatureFlag';
      name?: string | null;
      value?: boolean | null;
    } | null>;
  } | null;
};

export type GetFooterQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetFooterQuery = {
  __typename?: 'Query';
  layoutFooterCollection?: {
    __typename?: 'LayoutFooterCollection';
    items: Array<{
      __typename: 'LayoutFooter';
      name?: string | null;
      copyrightText?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      socialIconsLinksCollection?: {
        __typename?: 'LayoutFooterSocialIconsLinksCollection';
        items: Array<{
          __typename?: 'Link';
          name?: string | null;
          url?: string | null;
          isInternal?: boolean | null;
          label?: string | null;
          ariaLabel?: string | null;
          openInNewTab?: boolean | null;
          gaTrackName?: string | null;
          isDisplayedWhenLoggedIn?: boolean | null;
          color?: string | null;
          variant?: string | null;
          underline?: string | null;
          align?: string | null;
          noFollow?: boolean | null;
          image?: {
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null;
        } | null>;
      } | null;
      navigationCollection?: {
        __typename?: 'LayoutFooterNavigationCollection';
        items: Array<{
          __typename?: 'FooterNavigationGroup';
          title?: string | null;
          linksCollection?: {
            __typename?: 'FooterNavigationGroupLinksCollection';
            items: Array<{
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            } | null>;
          } | null;
          extras?: {
            __typename?: 'FooterNavigationGroupExtras';
            backgroundColor?: string | null;
            color?: string | null;
            title?: string | null;
          } | null;
        } | null>;
      } | null;
      footerLogo?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
  legalMessagingCollection?: {
    __typename?: 'LegalMessagingCollection';
    items: Array<{
      __typename: 'LegalMessaging';
      name?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      content?: { __typename?: 'LegalMessagingContent'; json: Document } | null;
    } | null>;
  } | null;
};

export type GenericErrorPageQueryVariables = Exact<{
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GenericErrorPageQuery = {
  __typename?: 'Query';
  genericErrorPageCollection?: {
    __typename?: 'GenericErrorPageCollection';
    items: Array<{
      __typename?: 'GenericErrorPage';
      header?: string | null;
      name?: string | null;
      description?: {
        __typename?: 'GenericErrorPageDescription';
        json: Document;
      } | null;
      subDescription?: {
        __typename?: 'GenericErrorPageSubDescription';
        json: Document;
      } | null;
    } | null>;
  } | null;
};

export type LayoutHeaderQueryQueryVariables = Exact<{
  locale: Scalars['String']['input'];
}>;

export type LayoutHeaderQueryQuery = {
  __typename?: 'Query';
  layoutHeaderCollection?: {
    __typename?: 'LayoutHeaderCollection';
    items: Array<{
      __typename?: 'LayoutHeader';
      name?: string | null;
      menuLabel?: string | null;
      welcome?: string | null;
      lastLogin?: string | null;
      myAccount?: string | null;
      notLoggedInMenuItemsCollection?: {
        __typename?: 'LayoutHeaderNotLoggedInMenuItemsCollection';
        items: Array<{
          __typename?: 'Link';
          name?: string | null;
          url?: string | null;
          isInternal?: boolean | null;
          label?: string | null;
          ariaLabel?: string | null;
          openInNewTab?: boolean | null;
          gaTrackName?: string | null;
          isDisplayedWhenLoggedIn?: boolean | null;
          color?: string | null;
          variant?: string | null;
          underline?: string | null;
          align?: string | null;
          noFollow?: boolean | null;
          image?: {
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null;
        } | null>;
      } | null;
      loggedInMenuItemsCollection?: {
        __typename?: 'LayoutHeaderLoggedInMenuItemsCollection';
        items: Array<{
          __typename?: 'Link';
          name?: string | null;
          url?: string | null;
          isInternal?: boolean | null;
          label?: string | null;
          ariaLabel?: string | null;
          openInNewTab?: boolean | null;
          gaTrackName?: string | null;
          isDisplayedWhenLoggedIn?: boolean | null;
          color?: string | null;
          variant?: string | null;
          underline?: string | null;
          align?: string | null;
          noFollow?: boolean | null;
          image?: {
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null;
        } | null>;
      } | null;
      userDropdownMenuItemsCollection?: {
        __typename?: 'LayoutHeaderUserDropdownMenuItemsCollection';
        items: Array<{
          __typename?: 'Link';
          name?: string | null;
          url?: string | null;
          isInternal?: boolean | null;
          label?: string | null;
          ariaLabel?: string | null;
          openInNewTab?: boolean | null;
          gaTrackName?: string | null;
          isDisplayedWhenLoggedIn?: boolean | null;
          color?: string | null;
          variant?: string | null;
          underline?: string | null;
          align?: string | null;
          noFollow?: boolean | null;
          image?: {
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null;
        } | null>;
      } | null;
      referFriendLink?: {
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
      logoLight?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      logoLightMobile?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      logoDark?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      logoDarkMobile?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type LogoFragmentFragment = {
  __typename: 'Logo';
  name?: string | null;
  id?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  logo?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  link?:
    | { __typename?: 'Link' }
    | { __typename?: 'Page'; slug?: string | null }
    | null;
};

export type LogoQueryVariables = Exact<{
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type LogoQuery = {
  __typename?: 'Query';
  logoCollection?: {
    __typename?: 'LogoCollection';
    items: Array<{
      __typename: 'Logo';
      name?: string | null;
      id?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      logo?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      link?:
        | { __typename?: 'Link' }
        | { __typename?: 'Page'; slug?: string | null }
        | null;
    } | null>;
  } | null;
};

export type SeoOrganizationStructuredDataFragment = {
  __typename?: 'SeoOrganizationStructuredData';
  addressCountry?: string | null;
  addressLocality?: string | null;
  description?: string | null;
  email?: string | null;
  name?: string | null;
  postalCode?: string | null;
  streetAddress?: string | null;
  url?: string | null;
  image?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  sameAsCollection?: {
    __typename?: 'SeoOrganizationStructuredDataSameAsCollection';
    items: Array<{ __typename?: 'SeoSameAs'; value?: string | null } | null>;
  } | null;
  contactPointCollection?: {
    __typename?: 'SeoOrganizationStructuredDataContactPointCollection';
    items: Array<{
      __typename?: 'SeoContactPoint';
      telephone?: string | null;
      areaServed?: string | null;
      contactType?: string | null;
    } | null>;
  } | null;
};

export type OrganizationSchemaQueryVariables = Exact<{
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type OrganizationSchemaQuery = {
  __typename?: 'Query';
  seoOrganizationStructuredDataCollection?: {
    __typename?: 'SeoOrganizationStructuredDataCollection';
    items: Array<{
      __typename?: 'SeoOrganizationStructuredData';
      addressCountry?: string | null;
      addressLocality?: string | null;
      description?: string | null;
      email?: string | null;
      name?: string | null;
      postalCode?: string | null;
      streetAddress?: string | null;
      url?: string | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      sameAsCollection?: {
        __typename?: 'SeoOrganizationStructuredDataSameAsCollection';
        items: Array<{
          __typename?: 'SeoSameAs';
          value?: string | null;
        } | null>;
      } | null;
      contactPointCollection?: {
        __typename?: 'SeoOrganizationStructuredDataContactPointCollection';
        items: Array<{
          __typename?: 'SeoContactPoint';
          telephone?: string | null;
          areaServed?: string | null;
          contactType?: string | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type PageFragment = {
  __typename: 'Page';
  name?: string | null;
  slug?: string | null;
  title?: string | null;
  description?: string | null;
  noIndex?: boolean | null;
  regions?: Array<string | null> | null;
  headerPosition?: string | null;
  headerTheme?: string | null;
  analyticsPageName?: string | null;
  analyticsPageType?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  ogImage?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  modulesCollection?: {
    __typename?: 'PageModulesCollection';
    items: Array<
      | {
          __typename: 'AccountDuplicationPage';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'ActionResult'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'AppBanner'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'AppReview'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'AppReviews'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'BackToSchool'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'BackToSchoolCostCategory';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'BackToSchoolCountryData';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'BetterWayTop'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'BlogContent'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'Calculator'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'CalculatorAppLeadingLinks';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'CalculatorPage';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'Card'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'CexChartFilterOptions';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'CexCurrency'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'CexCurrencyCorridor';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'CexLineChart'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'CexRateAlerts'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'CexRateTable'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'CexSearch'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'ChangePasswordPage';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'ComparisonTable';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'ComparisonTableCell';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'ComparisonTableRow';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'ContextualPromoBanner';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'Correspondent'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'CorrespondentLocations';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'Correspondents';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'Country'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'CountryPayoutMethod';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'CurrencyPricingConverter';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'EmbeddedTableEntry';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'ExternalScript';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'FaqFeedbackModule';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'FaqFeedbackModuleItem';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'FaqModule'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'FaqModuleItem'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'FaqPopularQuestions';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'FaqSearchHelp'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'FaqSearchHelpItems';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'FaqTopicsModule';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'FaqTopicsModuleItems';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'FeatureFlag'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'FooterNavigationGroup';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'FooterNavigationGroupExtras';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'ForgottenPasswordPage';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'FullWidthBanner';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'GenericErrorPage';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'HowDoesItWork'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'HowItWorksStepRelaunch';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'HowSendingWorks';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'IconWithText'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'Image'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'InformationDialog';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'JazzCashPage'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'JazzCashRequest';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'LayoutFooter'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'LayoutHeader'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'LegalMessaging';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'Link'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'ListOfCountries';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'LoginPage'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'Member'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'MembersList'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'MfaFactors'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'MfaVerification';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'MobileAppLeadingLinks';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'MobileStoreData';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'MoneyRequestConfirmationDialog';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'MoneyRequestPay';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'MoneyRequestPaymentReason';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'MoneyTransfer'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'MoneyTransferCard';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'NotFoundContent';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'NotFoundErrorPage';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'NotFoundPage'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'OnfidoErrorPage';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'OurContactInformation';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'Page'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'PageSection'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'PaymentReason'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'PayoutMethod'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'PayoutMethodAlert';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'Person'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'PhoneNumberVerificationPage';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'PopularCountries';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'PriceComparsion';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'PricingCalculator';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'PromoApplicability';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'PromoMessage'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'PromotionalBanner';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'PromotionalBannerDisclaimer';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'ReferAFriendGreenfieldEnabledCountries';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'ReferFriendHeader';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'RegionCode'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'Regulator'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'RequestMoneyNewRequestEmail';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'RequestMoneyPage';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'Review'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'Reviews'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'ReviewsTimeDictionary';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'RobotsConfiguration';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'Search'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'SendToCountryModule';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'SenderFormPage';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'SendingPartnership';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'SeoContactPoint';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'SeoOrganizationStructuredData';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'SeoSameAs'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'ShareSocialLink';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'SmsVerificationCodePage';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'SocialProofingModule';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'SolutionsModule';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'Step'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'StepByStep'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'StickyBanner'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'TagLinks'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'TextMeTheAppModule';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'TextSection'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'TextWithColor'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'VerificationInProgress';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'Video'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'WeAreRegulated';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'WhyChoose'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'WhyChooseReason';
          sys: { __typename?: 'Sys'; id: string };
        }
      | null
    >;
  } | null;
  footer?: {
    __typename: 'LayoutFooter';
    name?: string | null;
    copyrightText?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    socialIconsLinksCollection?: {
      __typename?: 'LayoutFooterSocialIconsLinksCollection';
      items: Array<{
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null>;
    } | null;
    navigationCollection?: {
      __typename?: 'LayoutFooterNavigationCollection';
      items: Array<{
        __typename?: 'FooterNavigationGroup';
        title?: string | null;
        linksCollection?: {
          __typename?: 'FooterNavigationGroupLinksCollection';
          items: Array<{
            __typename?: 'Link';
            name?: string | null;
            url?: string | null;
            isInternal?: boolean | null;
            label?: string | null;
            ariaLabel?: string | null;
            openInNewTab?: boolean | null;
            gaTrackName?: string | null;
            isDisplayedWhenLoggedIn?: boolean | null;
            color?: string | null;
            variant?: string | null;
            underline?: string | null;
            align?: string | null;
            noFollow?: boolean | null;
            image?: {
              __typename?: 'Asset';
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
            } | null;
          } | null>;
        } | null;
        extras?: {
          __typename?: 'FooterNavigationGroupExtras';
          backgroundColor?: string | null;
          color?: string | null;
          title?: string | null;
        } | null;
      } | null>;
    } | null;
    footerLogo?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
  submenuItemsCollection?: {
    __typename?: 'PageSubmenuItemsCollection';
    items: Array<{
      __typename?: 'Link';
      name?: string | null;
      url?: string | null;
      isInternal?: boolean | null;
      label?: string | null;
      ariaLabel?: string | null;
      openInNewTab?: boolean | null;
      gaTrackName?: string | null;
      isDisplayedWhenLoggedIn?: boolean | null;
      color?: string | null;
      variant?: string | null;
      underline?: string | null;
      align?: string | null;
      noFollow?: boolean | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type BlogPageFragment = {
  __typename?: 'Page';
  timeToRead?: string | null;
  publishedDate?: string | null;
  updatedDate?: string | null;
  sys: {
    __typename?: 'Sys';
    id: string;
    publishedAt?: string | null;
    firstPublishedAt?: string | null;
  };
  content?: {
    __typename?: 'PageContent';
    json: Document;
    links: {
      __typename?: 'PageContentLinks';
      entries: {
        __typename?: 'PageContentEntries';
        hyperlink: Array<
          | {
              __typename?: 'AccountDuplicationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ActionResult';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReview';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppReviews';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchool';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCostCategory';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BackToSchoolCountryData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BlogContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexChartFilterOptions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexLineChart';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateAlerts';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexRateTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ChangePasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContextualPromoBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CorrespondentLocations';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CountryPayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CurrencyPricingConverter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'EmbeddedTableEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ExternalScript';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqFeedbackModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelp';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSearchHelpItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ForgottenPasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HowDoesItWork';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowItWorksStepRelaunch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'HowSendingWorks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'InformationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'JazzCashRequest';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Link'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LoginPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Member'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MembersList';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MfaFactors';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MfaVerification';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileStoreData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestConfirmationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPay';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyRequestPaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransfer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MoneyTransferCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OnfidoErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'OurContactInformation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethodAlert';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhoneNumberVerificationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PriceComparsion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReferAFriendGreenfieldEnabledCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReferFriendHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Regulator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyNewRequestEmail';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RequestMoneyPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Review'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Reviews'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SenderFormPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SendingPartnership';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ShareSocialLink';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SmsVerificationCodePage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SocialProofingModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SolutionsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'StickyBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TagLinks'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'TextSection';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'VerificationInProgress';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WeAreRegulated';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
      };
    };
  } | null;
  author?: {
    __typename: 'Person';
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    description?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
  banner?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
};

export type HomePageQueryVariables = Exact<{
  locale: Scalars['String']['input'];
  region?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type HomePageQuery = {
  __typename?: 'Query';
  pageCollection?: {
    __typename?: 'PageCollection';
    items: Array<{
      __typename: 'Page';
      name?: string | null;
      slug?: string | null;
      title?: string | null;
      description?: string | null;
      noIndex?: boolean | null;
      regions?: Array<string | null> | null;
      headerPosition?: string | null;
      headerTheme?: string | null;
      analyticsPageName?: string | null;
      analyticsPageType?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      ogImage?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      modulesCollection?: {
        __typename?: 'PageModulesCollection';
        items: Array<
          | {
              __typename: 'AccountDuplicationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ActionResult';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'AppBanner'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'AppReview'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'AppReviews';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'BackToSchool';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'BackToSchoolCostCategory';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'BackToSchoolCountryData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'BlogContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CexChartFilterOptions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CexLineChart';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CexRateAlerts';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CexRateTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'CexSearch'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ChangePasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ContextualPromoBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'Correspondent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CorrespondentLocations';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CountryPayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CurrencyPricingConverter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'EmbeddedTableEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ExternalScript';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FaqFeedbackModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FaqFeedbackModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'FaqModule'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FaqSearchHelp';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FaqSearchHelpItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ForgottenPasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'HowDoesItWork';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'HowItWorksStepRelaunch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'HowSendingWorks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'IconWithText';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'InformationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'JazzCashPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'JazzCashRequest';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Link'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'LoginPage'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Member'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'MembersList';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'MfaFactors';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'MfaVerification';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'MobileStoreData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'MoneyRequestConfirmationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'MoneyRequestPay';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'MoneyRequestPaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'MoneyTransfer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'MoneyTransferCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'NotFoundPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'OnfidoErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'OurContactInformation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PayoutMethodAlert';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'PhoneNumberVerificationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PriceComparsion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ReferAFriendGreenfieldEnabledCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ReferFriendHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Regulator'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'RequestMoneyNewRequestEmail';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'RequestMoneyPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Review'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Reviews'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'SenderFormPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'SendingPartnership';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'SeoSameAs'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ShareSocialLink';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'SmsVerificationCodePage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'SocialProofingModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'SolutionsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'StickyBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'TagLinks'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'VerificationInProgress';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'WeAreRegulated';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'WhyChoose'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
      } | null;
      footer?: {
        __typename: 'LayoutFooter';
        name?: string | null;
        copyrightText?: string | null;
        sys: { __typename?: 'Sys'; id: string };
        socialIconsLinksCollection?: {
          __typename?: 'LayoutFooterSocialIconsLinksCollection';
          items: Array<{
            __typename?: 'Link';
            name?: string | null;
            url?: string | null;
            isInternal?: boolean | null;
            label?: string | null;
            ariaLabel?: string | null;
            openInNewTab?: boolean | null;
            gaTrackName?: string | null;
            isDisplayedWhenLoggedIn?: boolean | null;
            color?: string | null;
            variant?: string | null;
            underline?: string | null;
            align?: string | null;
            noFollow?: boolean | null;
            image?: {
              __typename?: 'Asset';
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
            } | null;
          } | null>;
        } | null;
        navigationCollection?: {
          __typename?: 'LayoutFooterNavigationCollection';
          items: Array<{
            __typename?: 'FooterNavigationGroup';
            title?: string | null;
            linksCollection?: {
              __typename?: 'FooterNavigationGroupLinksCollection';
              items: Array<{
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              } | null>;
            } | null;
            extras?: {
              __typename?: 'FooterNavigationGroupExtras';
              backgroundColor?: string | null;
              color?: string | null;
              title?: string | null;
            } | null;
          } | null>;
        } | null;
        footerLogo?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
      submenuItemsCollection?: {
        __typename?: 'PageSubmenuItemsCollection';
        items: Array<{
          __typename?: 'Link';
          name?: string | null;
          url?: string | null;
          isInternal?: boolean | null;
          label?: string | null;
          ariaLabel?: string | null;
          openInNewTab?: boolean | null;
          gaTrackName?: string | null;
          isDisplayedWhenLoggedIn?: boolean | null;
          color?: string | null;
          variant?: string | null;
          underline?: string | null;
          align?: string | null;
          noFollow?: boolean | null;
          image?: {
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type PageBySlugQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  region?: InputMaybe<Scalars['String']['input']>;
  isBlogPage: Scalars['Boolean']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type PageBySlugQuery = {
  __typename?: 'Query';
  pageCollection?: {
    __typename?: 'PageCollection';
    items: Array<{
      __typename: 'Page';
      name?: string | null;
      slug?: string | null;
      title?: string | null;
      description?: string | null;
      noIndex?: boolean | null;
      regions?: Array<string | null> | null;
      headerPosition?: string | null;
      headerTheme?: string | null;
      analyticsPageName?: string | null;
      analyticsPageType?: string | null;
      timeToRead?: string | null;
      publishedDate?: string | null;
      updatedDate?: string | null;
      sys: {
        __typename?: 'Sys';
        id: string;
        publishedAt?: string | null;
        firstPublishedAt?: string | null;
      };
      ogImage?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      modulesCollection?: {
        __typename?: 'PageModulesCollection';
        items: Array<
          | {
              __typename: 'AccountDuplicationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ActionResult';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'AppBanner'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'AppReview'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'AppReviews';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'BackToSchool';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'BackToSchoolCostCategory';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'BackToSchoolCountryData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'BlogContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CexChartFilterOptions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CexLineChart';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CexRateAlerts';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CexRateTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'CexSearch'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ChangePasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ContextualPromoBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'Correspondent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CorrespondentLocations';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CountryPayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CurrencyPricingConverter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'EmbeddedTableEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ExternalScript';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FaqFeedbackModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FaqFeedbackModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'FaqModule'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FaqSearchHelp';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FaqSearchHelpItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ForgottenPasswordPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'HowDoesItWork';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'HowItWorksStepRelaunch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'HowSendingWorks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'IconWithText';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'InformationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'JazzCashPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'JazzCashRequest';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Link'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'LoginPage'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Member'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'MembersList';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'MfaFactors';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'MfaVerification';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'MobileStoreData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'MoneyRequestConfirmationDialog';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'MoneyRequestPay';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'MoneyRequestPaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'MoneyTransfer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'MoneyTransferCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'NotFoundPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'OnfidoErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'OurContactInformation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PaymentReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PayoutMethodAlert';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'PhoneNumberVerificationPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PriceComparsion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ReferAFriendGreenfieldEnabledCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ReferFriendHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Regulator'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'RequestMoneyNewRequestEmail';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'RequestMoneyPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Review'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Reviews'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'SenderFormPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'SendingPartnership';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'SeoSameAs'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ShareSocialLink';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'SignIn'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'SmsVerificationCodePage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'SocialProofingModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'SolutionsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'StickyBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'TagLinks'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'VerificationInProgress';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'WeAreRegulated';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'WhyChoose'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
      } | null;
      footer?: {
        __typename: 'LayoutFooter';
        name?: string | null;
        copyrightText?: string | null;
        sys: { __typename?: 'Sys'; id: string };
        socialIconsLinksCollection?: {
          __typename?: 'LayoutFooterSocialIconsLinksCollection';
          items: Array<{
            __typename?: 'Link';
            name?: string | null;
            url?: string | null;
            isInternal?: boolean | null;
            label?: string | null;
            ariaLabel?: string | null;
            openInNewTab?: boolean | null;
            gaTrackName?: string | null;
            isDisplayedWhenLoggedIn?: boolean | null;
            color?: string | null;
            variant?: string | null;
            underline?: string | null;
            align?: string | null;
            noFollow?: boolean | null;
            image?: {
              __typename?: 'Asset';
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
            } | null;
          } | null>;
        } | null;
        navigationCollection?: {
          __typename?: 'LayoutFooterNavigationCollection';
          items: Array<{
            __typename?: 'FooterNavigationGroup';
            title?: string | null;
            linksCollection?: {
              __typename?: 'FooterNavigationGroupLinksCollection';
              items: Array<{
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              } | null>;
            } | null;
            extras?: {
              __typename?: 'FooterNavigationGroupExtras';
              backgroundColor?: string | null;
              color?: string | null;
              title?: string | null;
            } | null;
          } | null>;
        } | null;
        footerLogo?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
      submenuItemsCollection?: {
        __typename?: 'PageSubmenuItemsCollection';
        items: Array<{
          __typename?: 'Link';
          name?: string | null;
          url?: string | null;
          isInternal?: boolean | null;
          label?: string | null;
          ariaLabel?: string | null;
          openInNewTab?: boolean | null;
          gaTrackName?: string | null;
          isDisplayedWhenLoggedIn?: boolean | null;
          color?: string | null;
          variant?: string | null;
          underline?: string | null;
          align?: string | null;
          noFollow?: boolean | null;
          image?: {
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null;
        } | null>;
      } | null;
      content?: {
        __typename?: 'PageContent';
        json: Document;
        links: {
          __typename?: 'PageContentLinks';
          entries: {
            __typename?: 'PageContentEntries';
            hyperlink: Array<
              | {
                  __typename?: 'AccountDuplicationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ActionResult';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReview';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppReviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchool';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCostCategory';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BackToSchoolCountryData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BlogContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexChartFilterOptions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexLineChart';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateAlerts';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexRateTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ChangePasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContextualPromoBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CorrespondentLocations';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountryPayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CurrencyPricingConverter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'EmbeddedTableEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExternalScript';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqFeedbackModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelp';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSearchHelpItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ForgottenPasswordPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowDoesItWork';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowItWorksStepRelaunch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HowSendingWorks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InformationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'JazzCashRequest';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Link'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LoginPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Logo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Member';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MembersList';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaFactors';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MfaVerification';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileStoreData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestConfirmationDialog';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPay';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyRequestPaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransfer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MoneyTransferCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OnfidoErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'OurContactInformation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PaymentReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethodAlert';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhoneNumberVerificationPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PriceComparsion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferAFriendGreenfieldEnabledCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReferFriendHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Regulator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyNewRequestEmail';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RequestMoneyPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Review';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Reviews';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SenderFormPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendingPartnership';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ShareSocialLink';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SignIn';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SmsVerificationCodePage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SocialProofingModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SolutionsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'StickyBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TagLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename: 'TextSection';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'VerificationInProgress';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WeAreRegulated';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
      author?: {
        __typename: 'Person';
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        description?: string | null;
        sys: { __typename?: 'Sys'; id: string };
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
      banner?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type PageDataQueryVariables = Exact<{
  pageId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  sendCountryCode: Scalars['String']['input'];
  isCorrespondentPage: Scalars['Boolean']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
  paths?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  includeRelatedPages: Scalars['Boolean']['input'];
}>;

export type PageDataQuery = {
  __typename?: 'Query';
  layoutHeaderCollection?: {
    __typename?: 'LayoutHeaderCollection';
    items: Array<{
      __typename?: 'LayoutHeader';
      name?: string | null;
      menuLabel?: string | null;
      welcome?: string | null;
      lastLogin?: string | null;
      myAccount?: string | null;
      notLoggedInMenuItemsCollection?: {
        __typename?: 'LayoutHeaderNotLoggedInMenuItemsCollection';
        items: Array<{
          __typename?: 'Link';
          name?: string | null;
          url?: string | null;
          isInternal?: boolean | null;
          label?: string | null;
          ariaLabel?: string | null;
          openInNewTab?: boolean | null;
          gaTrackName?: string | null;
          isDisplayedWhenLoggedIn?: boolean | null;
          color?: string | null;
          variant?: string | null;
          underline?: string | null;
          align?: string | null;
          noFollow?: boolean | null;
          image?: {
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null;
        } | null>;
      } | null;
      loggedInMenuItemsCollection?: {
        __typename?: 'LayoutHeaderLoggedInMenuItemsCollection';
        items: Array<{
          __typename?: 'Link';
          name?: string | null;
          url?: string | null;
          isInternal?: boolean | null;
          label?: string | null;
          ariaLabel?: string | null;
          openInNewTab?: boolean | null;
          gaTrackName?: string | null;
          isDisplayedWhenLoggedIn?: boolean | null;
          color?: string | null;
          variant?: string | null;
          underline?: string | null;
          align?: string | null;
          noFollow?: boolean | null;
          image?: {
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null;
        } | null>;
      } | null;
      userDropdownMenuItemsCollection?: {
        __typename?: 'LayoutHeaderUserDropdownMenuItemsCollection';
        items: Array<{
          __typename?: 'Link';
          name?: string | null;
          url?: string | null;
          isInternal?: boolean | null;
          label?: string | null;
          ariaLabel?: string | null;
          openInNewTab?: boolean | null;
          gaTrackName?: string | null;
          isDisplayedWhenLoggedIn?: boolean | null;
          color?: string | null;
          variant?: string | null;
          underline?: string | null;
          align?: string | null;
          noFollow?: boolean | null;
          image?: {
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null;
        } | null>;
      } | null;
      referFriendLink?: {
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
      logoLight?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      logoLightMobile?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      logoDark?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      logoDarkMobile?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
  payoutMethodCollection?: {
    __typename?: 'PayoutMethodCollection';
    items: Array<{
      __typename?: 'PayoutMethod';
      name?: string | null;
      payoutMethodId?: string | null;
    } | null>;
  } | null;
  messagesCollection?: {
    __typename?: 'MessagesCollection';
    items: Array<{
      __typename?: 'Messages';
      readMore?: string | null;
      readLess?: string | null;
      captchaPxLabel?: string | null;
      previous?: string | null;
      next?: string | null;
      chooseYourRegion?: string | null;
      countries?: string | null;
      international?: string | null;
      continue?: string | null;
      cancel?: string | null;
      close?: string | null;
      geoLocationBannerContent?: string | null;
      updated?: string | null;
      all?: string | null;
      autocompleteLabel?: string | null;
      noResults?: string | null;
      relatedPosts?: string | null;
      lifeabroad?: string | null;
      community?: string | null;
      education?: string | null;
      finance?: string | null;
      product?: string | null;
      referAFriendSendFromLabel?: string | null;
      locationLangLabel?: string | null;
      rafUrlUnavailable?: string | null;
      rafUrlInvalid?: string | null;
    } | null>;
  } | null;
  legalMessagingCollection?: {
    __typename?: 'LegalMessagingCollection';
    items: Array<{
      __typename: 'LegalMessaging';
      name?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      content?: { __typename?: 'LegalMessagingContent'; json: Document } | null;
    } | null>;
  } | null;
  breadcrumbs?: {
    __typename?: 'PageCollection';
    items: Array<{
      __typename?: 'Page';
      name?: string | null;
      heading?: string | null;
      slug?: string | null;
    } | null>;
  } | null;
  correspondentId?: {
    __typename?: 'PageCollection';
    items: Array<{
      __typename?: 'Page';
      linkedFrom?: {
        __typename?: 'PageLinkingCollections';
        logoCollection?: {
          __typename?: 'LogoCollection';
          items: Array<{ __typename?: 'Logo'; id?: string | null } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
  seoOrganizationStructuredDataCollection?: {
    __typename?: 'SeoOrganizationStructuredDataCollection';
    items: Array<{
      __typename?: 'SeoOrganizationStructuredData';
      addressCountry?: string | null;
      addressLocality?: string | null;
      description?: string | null;
      email?: string | null;
      name?: string | null;
      postalCode?: string | null;
      streetAddress?: string | null;
      url?: string | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      sameAsCollection?: {
        __typename?: 'SeoOrganizationStructuredDataSameAsCollection';
        items: Array<{
          __typename?: 'SeoSameAs';
          value?: string | null;
        } | null>;
      } | null;
      contactPointCollection?: {
        __typename?: 'SeoOrganizationStructuredDataContactPointCollection';
        items: Array<{
          __typename?: 'SeoContactPoint';
          telephone?: string | null;
          areaServed?: string | null;
          contactType?: string | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
  page?: {
    __typename?: 'Page';
    relatedPagesCollection?: {
      __typename?: 'PageRelatedPagesCollection';
      items: Array<{
        __typename: 'Page';
        name?: string | null;
        slug?: string | null;
        title?: string | null;
        sys: { __typename?: 'Sys'; id: string };
        banner?: {
          __typename?: 'Asset';
          title?: string | null;
          url?: string | null;
          alt?: string | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type CountryPagesSlugsQueryVariables = Exact<{
  region: Scalars['String']['input'];
  analyticsPageType: Scalars['String']['input'];
}>;

export type CountryPagesSlugsQuery = {
  __typename?: 'Query';
  pageCollection?: {
    __typename?: 'PageCollection';
    items: Array<{ __typename?: 'Page'; slug?: string | null } | null>;
  } | null;
};

export type PageSitemapLinkFragment = {
  __typename?: 'Page';
  slug?: string | null;
  title?: string | null;
  heading?: string | null;
  name?: string | null;
  analyticsPageType?: string | null;
  sys: { __typename?: 'Sys'; publishedAt?: string | null };
};

export type PagesSitemapLinksQueryVariables = Exact<{
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  excludedSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type PagesSitemapLinksQuery = {
  __typename?: 'Query';
  pageCollection?: {
    __typename?: 'PageCollection';
    items: Array<{
      __typename?: 'Page';
      slug?: string | null;
      title?: string | null;
      heading?: string | null;
      name?: string | null;
      analyticsPageType?: string | null;
      sys: { __typename?: 'Sys'; publishedAt?: string | null };
    } | null>;
  } | null;
};

export type BlogPagesCardsQueryVariables = Exact<{
  analyticsPageType: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  tags:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  shouldIncludeSearchOptions: Scalars['Boolean']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type BlogPagesCardsQuery = {
  __typename?: 'Query';
  searchOptions?: {
    __typename?: 'PageCollection';
    items: Array<{
      __typename?: 'Page';
      name?: string | null;
      slug?: string | null;
    } | null>;
  } | null;
  cards?: {
    __typename?: 'PageCollection';
    total: number;
    items: Array<{
      __typename?: 'Page';
      name?: string | null;
      slug?: string | null;
      publishedDate?: string | null;
      contentfulMetadata: {
        __typename?: 'ContentfulMetadata';
        tags: Array<{
          __typename?: 'ContentfulTag';
          id?: string | null;
          name?: string | null;
        } | null>;
      };
      banner?: {
        __typename?: 'Asset';
        title?: string | null;
        url?: string | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type BlogPagesTagsQueryVariables = Exact<{
  analyticsPageType: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type BlogPagesTagsQuery = {
  __typename?: 'Query';
  pageCollection?: {
    __typename?: 'PageCollection';
    items: Array<{
      __typename?: 'Page';
      contentfulMetadata: {
        __typename?: 'ContentfulMetadata';
        tags: Array<{
          __typename?: 'ContentfulTag';
          id?: string | null;
          name?: string | null;
        } | null>;
      };
    } | null>;
  } | null;
};

export type PagesSharedDataQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type PagesSharedDataQuery = {
  __typename?: 'Query';
  layoutHeaderCollection?: {
    __typename?: 'LayoutHeaderCollection';
    items: Array<{
      __typename?: 'LayoutHeader';
      name?: string | null;
      menuLabel?: string | null;
      welcome?: string | null;
      lastLogin?: string | null;
      myAccount?: string | null;
      notLoggedInMenuItemsCollection?: {
        __typename?: 'LayoutHeaderNotLoggedInMenuItemsCollection';
        items: Array<{
          __typename?: 'Link';
          name?: string | null;
          url?: string | null;
          isInternal?: boolean | null;
          label?: string | null;
          ariaLabel?: string | null;
          openInNewTab?: boolean | null;
          gaTrackName?: string | null;
          isDisplayedWhenLoggedIn?: boolean | null;
          color?: string | null;
          variant?: string | null;
          underline?: string | null;
          align?: string | null;
          noFollow?: boolean | null;
          image?: {
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null;
        } | null>;
      } | null;
      loggedInMenuItemsCollection?: {
        __typename?: 'LayoutHeaderLoggedInMenuItemsCollection';
        items: Array<{
          __typename?: 'Link';
          name?: string | null;
          url?: string | null;
          isInternal?: boolean | null;
          label?: string | null;
          ariaLabel?: string | null;
          openInNewTab?: boolean | null;
          gaTrackName?: string | null;
          isDisplayedWhenLoggedIn?: boolean | null;
          color?: string | null;
          variant?: string | null;
          underline?: string | null;
          align?: string | null;
          noFollow?: boolean | null;
          image?: {
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null;
        } | null>;
      } | null;
      userDropdownMenuItemsCollection?: {
        __typename?: 'LayoutHeaderUserDropdownMenuItemsCollection';
        items: Array<{
          __typename?: 'Link';
          name?: string | null;
          url?: string | null;
          isInternal?: boolean | null;
          label?: string | null;
          ariaLabel?: string | null;
          openInNewTab?: boolean | null;
          gaTrackName?: string | null;
          isDisplayedWhenLoggedIn?: boolean | null;
          color?: string | null;
          variant?: string | null;
          underline?: string | null;
          align?: string | null;
          noFollow?: boolean | null;
          image?: {
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null;
        } | null>;
      } | null;
      referFriendLink?: {
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
      logoLight?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      logoLightMobile?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      logoDark?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      logoDarkMobile?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
  messagesCollection?: {
    __typename?: 'MessagesCollection';
    items: Array<{
      __typename?: 'Messages';
      captchaPxLabel?: string | null;
    } | null>;
  } | null;
  genericErrorPageCollection?: {
    __typename?: 'GenericErrorPageCollection';
    items: Array<{
      __typename?: 'GenericErrorPage';
      header?: string | null;
      name?: string | null;
      description?: {
        __typename?: 'GenericErrorPageDescription';
        json: Document;
      } | null;
      subDescription?: {
        __typename?: 'GenericErrorPageSubDescription';
        json: Document;
      } | null;
    } | null>;
  } | null;
  notFoundErrorPageCollection?: {
    __typename?: 'NotFoundErrorPageCollection';
    items: Array<{
      __typename?: 'NotFoundErrorPage';
      name?: string | null;
      header?: string | null;
      description?: {
        __typename?: 'NotFoundErrorPageDescription';
        json: Document;
      } | null;
      subDescription?: {
        __typename?: 'NotFoundErrorPageSubDescription';
        json: Document;
      } | null;
    } | null>;
  } | null;
};

export type PayoutMethodAlertFragment = {
  __typename: 'PayoutMethodAlert';
  name?: string | null;
  title?: string | null;
  sendCountryList?: Array<string | null> | null;
  receiveCountryList?: Array<string | null> | null;
  sys: { __typename?: 'Sys'; id: string };
  message?: { __typename?: 'PayoutMethodAlertMessage'; json: Document } | null;
  payoutMethodsCollection?: {
    __typename?: 'PayoutMethodAlertPayoutMethodsCollection';
    items: Array<{
      __typename?: 'PayoutMethod';
      payoutMethodId?: string | null;
    } | null>;
  } | null;
};

export type PayoutMethodAlertsQueryVariables = Exact<{
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  sendCountryCode: Scalars['String']['input'];
  receiveCountryCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type PayoutMethodAlertsQuery = {
  __typename?: 'Query';
  payoutMethodAlertCollection?: {
    __typename?: 'PayoutMethodAlertCollection';
    items: Array<{
      __typename: 'PayoutMethodAlert';
      name?: string | null;
      title?: string | null;
      sendCountryList?: Array<string | null> | null;
      receiveCountryList?: Array<string | null> | null;
      sys: { __typename?: 'Sys'; id: string };
      message?: {
        __typename?: 'PayoutMethodAlertMessage';
        json: Document;
      } | null;
      payoutMethodsCollection?: {
        __typename?: 'PayoutMethodAlertPayoutMethodsCollection';
        items: Array<{
          __typename?: 'PayoutMethod';
          payoutMethodId?: string | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type RegionCodeQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type RegionCodeQuery = {
  __typename?: 'Query';
  regionCodeCollection?: {
    __typename?: 'RegionCodeCollection';
    items: Array<{
      __typename?: 'RegionCode';
      name?: string | null;
      frCa?: boolean | null;
      esUs?: boolean | null;
      enGb?: boolean | null;
      enUs?: boolean | null;
      enNz?: boolean | null;
      enSe?: boolean | null;
      enMy?: boolean | null;
      enAu?: boolean | null;
      enCa?: boolean | null;
      daDk?: boolean | null;
      en?: boolean | null;
    } | null>;
  } | null;
};

export type RobotsConfigurationQueryVariables = Exact<{
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type RobotsConfigurationQuery = {
  __typename?: 'Query';
  robotsConfigurationCollection?: {
    __typename?: 'RobotsConfigurationCollection';
    items: Array<{
      __typename?: 'RobotsConfiguration';
      allow?: Array<string | null> | null;
      disallow?: Array<string | null> | null;
      userAgent?: string | null;
      blogSitemapUrl?: string | null;
    } | null>;
  } | null;
};

export type RedirectsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type RedirectsQuery = {
  __typename?: 'Query';
  redirectCollection?: {
    __typename?: 'RedirectCollection';
    items: Array<{
      __typename?: 'Redirect';
      source?: string | null;
      destination?: string | null;
      permanent?: boolean | null;
      locale?: boolean | null;
    } | null>;
  } | null;
};

export type StickyBannersQueryVariables = Exact<{
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  analyticsPageType?: InputMaybe<Scalars['String']['input']>;
  marketingChannel?: InputMaybe<Scalars['String']['input']>;
  queryParams?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  cookies?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  visitorTypes?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  sendCountryCode: Scalars['String']['input'];
  receiveCountryCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type StickyBannersQuery = {
  __typename?: 'Query';
  stickyBannerCollection?: {
    __typename?: 'StickyBannerCollection';
    items: Array<{
      __typename: 'StickyBanner';
      name?: string | null;
      textColor?: string | null;
      backgroundColor?: string | null;
      marketingChannel?: Array<string | null> | null;
      sys: { __typename?: 'Sys'; id: string };
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      description?: {
        __typename?: 'StickyBannerDescription';
        json: Document;
      } | null;
      sendCountries?: {
        __typename?: 'StickyBannerSendCountriesCollection';
        items: Array<{
          __typename?: 'Country';
          name?: string | null;
          code?: string | null;
          slug?: string | null;
          article?: string | null;
          prepositionFrom?: string | null;
          prepositionIn?: string | null;
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      } | null;
      receiveCountries?: {
        __typename?: 'StickyBannerReceiveCountriesCollection';
        items: Array<{
          __typename?: 'Country';
          name?: string | null;
          code?: string | null;
          slug?: string | null;
          article?: string | null;
          prepositionFrom?: string | null;
          prepositionIn?: string | null;
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type SearchFragment = {
  __typename: 'Search';
  name?: string | null;
  heading?: string | null;
  inputLabel?: string | null;
  resultListLabel?: string | null;
  suggestionListLabel?: string | null;
  suggestionListCollection?: {
    __typename?: 'SearchSuggestionListCollection';
    items: Array<{
      __typename?: 'Page';
      name?: string | null;
      slug?: string | null;
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
};

export type PagesWithTagsQueryVariables = Exact<{
  analyticsPageType: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type PagesWithTagsQuery = {
  __typename?: 'Query';
  pageCollection?: {
    __typename?: 'PageCollection';
    items: Array<{
      __typename?: 'Page';
      name?: string | null;
      slug?: string | null;
      contentfulMetadata: {
        __typename?: 'ContentfulMetadata';
        tags: Array<{
          __typename?: 'ContentfulTag';
          name?: string | null;
        } | null>;
      };
    } | null>;
  } | null;
};

export const Asset = gql`
  fragment Asset on Asset {
    title
    alt: description
    width
    height
  }
`;
export const CoreImage = gql`
  fragment CoreImage on Asset {
    ...Asset
    url
  }
  ${Asset}
`;
export const AppBanner = gql`
  fragment AppBanner on AppBanner {
    sys {
      id
    }
    name
    __typename
    bannerDescription
    bannerTitle
    image {
      ...CoreImage
    }
    appStoreIconLink {
      url
      image {
        ...CoreImage
      }
    }
    playStoreIconLink {
      url
      image {
        ...CoreImage
      }
    }
  }
  ${CoreImage}
`;
export const BackToSchool = gql`
  fragment BackToSchool on BackToSchool {
    sys {
      id
    }
    __typename
    name
    averageHouseholdIncomeLabel
    chooseCurrencyLabel
    totalCostPerChildLabel
    totalCostPerHouseholdLabel
    totalSpendPerChildLabel
    mapSectionHeading
    hasCurrencyChart
    mapSectionMessage {
      json
    }
    mapSectionImage {
      ...CoreImage
    }
    itemsListHeading
    itemsListMessage {
      json
    }
    incomesHeading
    incomesMessage {
      json
    }
    countriesCollection {
      items {
        name
        country
        countryFlag {
          ...CoreImage
        }
        flagXPosition
        flagYPosition
        circleRatio
        totalChildCost
        totalHouseholdCost
        childrenRatio
        householdIncome
        currency
        exchangeRate
        currencySymbol
        costs
        link {
          label
          url
          isInternal
        }
      }
    }
    categoriesCollection {
      items {
        name
        categoryTitle
        categoryImage {
          ...CoreImage
        }
        categoryHexColour
        categoryKey
      }
    }
  }
  ${CoreImage}
`;
export const Link = gql`
  fragment Link on Link {
    name
    url
    isInternal
    label
    ariaLabel
    openInNewTab
    image {
      ...CoreImage
    }
    gaTrackName
    isDisplayedWhenLoggedIn
    color
    variant
    underline
    align
    noFollow
  }
  ${CoreImage}
`;
export const Video = gql`
  fragment Video on Video {
    sys {
      id
    }
    name
    __typename
    videoId
    content {
      json
    }
    backgroundImage {
      ...CoreImage
    }
  }
  ${CoreImage}
`;
export const EmbeddedImage = gql`
  fragment EmbeddedImage on Image {
    name
    align
    position
    asset {
      ...CoreImage
    }
    width
    height
  }
  ${CoreImage}
`;
export const TextWithColor = gql`
  fragment TextWithColor on TextWithColor {
    name
    text {
      json
    }
    inline
    color
    backgroundColor
    align
  }
`;
export const ImageXs = gql`
  fragment ImageXS on Asset {
    ...Asset
    url(transform: { width: 160, format: WEBP, quality: 60 })
  }
  ${Asset}
`;
export const IconWithText = gql`
  fragment IconWithText on IconWithText {
    name
    text {
      ...TextWithColor
    }
    icon {
      ...ImageXS
    }
  }
  ${TextWithColor}
  ${ImageXs}
`;
export const BodyText = gql`
  fragment BodyText on BodyText {
    sys {
      id
    }
    name
    __typename
    bodyTextHeading: heading {
      json
    }
    bodyTextContent: content {
      json
      links {
        assets {
          block {
            sys {
              id
            }
            contentType
            ...CoreImage
          }
        }
        entries {
          block {
            sys {
              id
            }
            ...Link
            ...Video
            ...EmbeddedImage
            ...TextWithColor
            ...IconWithText
          }
          inline {
            sys {
              id
            }
            ...Link
            ...TextWithColor
            ...IconWithText
          }
          hyperlink {
            sys {
              id
            }
            ...Link
          }
        }
      }
    }
    bodyTextContentTwo: contentTwo {
      json
      links {
        assets {
          block {
            sys {
              id
            }
            contentType
            ...CoreImage
          }
        }
        entries {
          block {
            sys {
              id
            }
            ...Link
            ...Video
            ...EmbeddedImage
            ...TextWithColor
            ...IconWithText
          }
          inline {
            sys {
              id
            }
            ...Link
            ...TextWithColor
            ...IconWithText
          }
          hyperlink {
            sys {
              id
            }
            ...Link
          }
        }
      }
    }
    headingAlign
    contentAlign
    listIndent
    paragraphIndent
    isPageSection
  }
  ${CoreImage}
  ${Link}
  ${Video}
  ${EmbeddedImage}
  ${TextWithColor}
  ${IconWithText}
`;
export const Card = gql`
  fragment Card on Card {
    sys {
      id
      publishedAt
    }
    name
    __typename
    payoutMethodId
    header
    description
    backgroundColor
    backgroundImage {
      ...Asset
      url
    }
    link {
      ...Link
    }
  }
  ${Asset}
  ${Link}
`;
export const Cards = gql`
  fragment Cards on Cards {
    sys {
      id
    }
    __typename
    name
    header
    description
    carouselText
    cardsCollection {
      items {
        ... on Card {
          sys {
            id
          }
          __typename
        }
      }
    }
    paymentText
    paymentIconsCollection {
      items {
        ...CoreImage
      }
    }
  }
  ${CoreImage}
`;
export const ComparisonTableCell = gql`
  fragment ComparisonTableCell on ComparisonTableCell {
    name
    amount
    fee
    receiveCountryList
  }
`;
export const ComparisonTableRow = gql`
  fragment ComparisonTableRow on ComparisonTableRow {
    name
    image: provider {
      ...Asset
      url(transform: { width: 200 })
    }
    ATP: atpCollection {
      items {
        ... on ComparisonTableCell {
          sys {
            id
          }
          __typename
        }
      }
    }
    CSH: cshCollection {
      items {
        ... on ComparisonTableCell {
          sys {
            id
          }
          __typename
        }
      }
    }
    BNK: bnkCollection {
      items {
        ... on ComparisonTableCell {
          sys {
            id
          }
          __typename
        }
      }
    }
    MOB: mobCollection {
      items {
        ... on ComparisonTableCell {
          sys {
            id
          }
          __typename
        }
      }
    }
  }
  ${Asset}
`;
export const ComparisonTable = gql`
  fragment ComparisonTable on ComparisonTable {
    sys {
      id
      publishedAt
    }
    __typename
    name
    content {
      json
    }
    providersLabel
    caption
    rowsCollection {
      items {
        ... on ComparisonTableRow {
          sys {
            id
          }
          __typename
        }
      }
    }
    footer
  }
`;
export const CorrespondentLocations = gql`
  fragment CorrespondentLocations on CorrespondentLocations {
    sys {
      id
    }
    __typename
    title
    name
    ctaButton
    locationDetailsNames
    locationDetailsIds
  }
`;
export const Correspondents = gql`
  fragment Correspondents on Correspondents {
    sys {
      id
    }
    __typename
    name
    title
    subtitle
  }
`;
export const CexLineChart = gql`
  fragment CexLineChart on CexLineChart {
    sys {
      id
    }
    name
    titleTemplate
    chartFilterOptions: filterOptionsCollection {
      items {
        name
        label
        filterOption
        setDefault
      }
    }
  }
`;
export const CexRateAlerts = gql`
  fragment CexRateAlerts on CexRateAlerts {
    sys {
      id
    }
    name
    __typename
    titleTemplate
    description
    textFieldPlaceholder
    buttonTitle
    message
    receiveMethodLabel
    informationDialog {
      ... on InformationDialog {
        sys {
          id
        }
        name
        title
        __typename
        content {
          json
        }
      }
    }
  }
`;
export const CexRateTable = gql`
  fragment CexRateTable on CexRateTable {
    sys {
      id
    }
    name
    title {
      json
    }
    tableTitleLabel
    conversionAmounts
    tableSubtitleOne
    tableSubtitleTwo
  }
`;
export const CexSearch = gql`
  fragment CexSearch on CexSearch {
    sys {
      id
    }
    name
    searchInputLabel
    searchResultListLabel
    showCurrencyGrid
    titleTemplate {
      json
    }
  }
`;
export const EmbeddedTableEntry = gql`
  fragment EmbeddedTableEntry on EmbeddedTableEntry {
    sys {
      id
    }
    name
    __typename
    description {
      json
      links {
        assets {
          block {
            sys {
              id
            }
            contentType
            ...CoreImage
          }
        }
        entries {
          block {
            sys {
              id
            }
            ...Link
            ...Video
            ...EmbeddedImage
            ...TextWithColor
            ...IconWithText
          }
          inline {
            sys {
              id
            }
            ...Link
            ...TextWithColor
            ...IconWithText
          }
          hyperlink {
            sys {
              id
            }
            ...Link
          }
        }
      }
    }
  }
  ${CoreImage}
  ${Link}
  ${Video}
  ${EmbeddedImage}
  ${TextWithColor}
  ${IconWithText}
`;
export const ExternalScript = gql`
  fragment ExternalScript on ExternalScript {
    name
    scriptType
    id
    key
  }
`;
export const FaqFeedbackModule = gql`
  fragment FaqFeedbackModule on FaqFeedbackModule {
    name
    title
    sys {
      id
    }
    postFeedbackMessage
    analyticsTagId
    feedbackOptions: feedbackOptionsCollection {
      items {
        name
        label
        analyticsTagId
        icon {
          ...CoreImage
        }
      }
    }
  }
  ${CoreImage}
`;
export const Search = gql`
  fragment Search on Search {
    __typename
    name
    heading
    inputLabel
    resultListLabel
    suggestionListLabel
    suggestionListCollection(limit: 5) {
      items {
        ... on Page {
          sys {
            id
          }
          name
          slug
        }
      }
    }
  }
`;
export const FaqHero = gql`
  fragment FaqHero on FaqHero {
    name
    sys {
      id
    }
    heading
    heroImage {
      ...CoreImage
    }
    search {
      ...Search
    }
    menuTitle
    primaryBackgroundColor
    secondaryBackgroundColor
    links: linksCollection {
      items {
        sys {
          id
        }
        name
        image {
          ...CoreImage
        }
        label
        url
      }
    }
  }
  ${CoreImage}
  ${Search}
`;
export const FaqModuleItem = gql`
  fragment FaqModuleItem on FaqModuleItem {
    sys {
      id
    }
    name
    __typename
    title
    description {
      json
      links {
        assets {
          block {
            sys {
              id
            }
            contentType
            ...CoreImage
          }
        }
        entries {
          block {
            sys {
              id
            }
            ...Link
            ...Video
            ...EmbeddedImage
            ...TextWithColor
            ...IconWithText
          }
          inline {
            sys {
              id
            }
            ...Link
            ...TextWithColor
            ...IconWithText
          }
          hyperlink {
            sys {
              id
            }
            ...Link
          }
        }
      }
    }
  }
  ${CoreImage}
  ${Link}
  ${Video}
  ${EmbeddedImage}
  ${TextWithColor}
  ${IconWithText}
`;
export const FaqModule = gql`
  fragment FaqModule on FaqModule {
    sys {
      id
    }
    name
    __typename
    title
    subheading
    image {
      ...CoreImage
    }
    questions: questionsCollection {
      items {
        sys {
          id
        }
        name
        title
        description {
          json
        }
      }
    }
    ctaButton {
      ...Link
    }
  }
  ${CoreImage}
  ${Link}
`;
export const FaqPopularQuestions = gql`
  fragment FaqPopularQuestions on FaqPopularQuestions {
    sys {
      id
    }
    name
    title
    questions: questionsCollection {
      items {
        sys {
          id
        }
        name
        __typename
        slug
        title
      }
    }
  }
`;
export const FaqTopicsModule = gql`
  fragment FaqTopicsModule on FaqTopicsModule {
    sys {
      id
    }
    name
    title
    backgroundColor
    topics: topicsCollection {
      items {
        sys {
          id
        }
        title
        description
        icon {
          url
        }
        topicLinks: topicLinksCollection {
          items {
            sys {
              id
            }
            name
            __typename
            slug
            title
          }
        }
      }
    }
    popularQuestions {
      ...FaqPopularQuestions
    }
  }
  ${FaqPopularQuestions}
`;
export const LegalMessaging = gql`
  fragment LegalMessaging on LegalMessaging {
    sys {
      id
    }
    __typename
    name
    content {
      json
    }
  }
`;
export const FullWidthBanner = gql`
  fragment FullWidthBanner on FullWidthBanner {
    sys {
      id
    }
    __typename
    name
    backgroundImage {
      ...CoreImage
    }
    backgroundImageMobile {
      ...CoreImage
    }
    backgroundColor
    secondBackgroundColor
    image {
      ...CoreImage
    }
    text {
      json
    }
    link {
      ...Link
    }
    textColor
    textAlign
    borderColor
    secondBorderColor
    contentWidth
  }
  ${CoreImage}
  ${Link}
`;
export const Header = gql`
  fragment Header on LayoutHeader {
    name
    menuLabel
    welcome
    lastLogin
    myAccount
    notLoggedInMenuItemsCollection(limit: 30) {
      items {
        ...Link
      }
    }
    loggedInMenuItemsCollection(limit: 30) {
      items {
        ...Link
      }
    }
    userDropdownMenuItemsCollection(limit: 30) {
      items {
        ...Link
      }
    }
    referFriendLink {
      ...Link
    }
    logoLight {
      ...CoreImage
    }
    logoLightMobile {
      ...CoreImage
    }
    logoDark {
      ...CoreImage
    }
    logoDarkMobile {
      ...CoreImage
    }
  }
  ${Link}
  ${CoreImage}
`;
export const HeroSlot = gql`
  fragment HeroSlot on HeroSlot {
    sys {
      id
    }
    __typename
    name
    header
    subheader
    loginInText
    buttonTitle
    loginInLinkTitle
    image {
      ...CoreImage
    }
    loginPageLink {
      ...Link
    }
    appStoreLink {
      ...Link
    }
    googlePlayStoreLink {
      ...Link
    }
    downloadAppsPageLink {
      ...Link
    }
    qrCode {
      ...CoreImage
    }
    qrCodeLabel
    dividerLabel
  }
  ${CoreImage}
  ${Link}
`;
export const CalculatorPayoutMethod = gql`
  fragment CalculatorPayoutMethod on PayoutMethod {
    name
    description
    payoutMethodId
    transferTimeTooltip
  }
`;
export const Calculator = gql`
  fragment Calculator on Calculator {
    name
    sendLabel
    receiveLabel
    isLite
    payoutMethodsLabel
    countriesSearchPlaceholder
    unavailableCorridorErrorMessage
    airtimeTopupQuoteAvailableInApp
    exchangeRateLabel
    exchangeRatePromoLabel
    receiverPayoutMethodUnavailableMessage
    senderPayoutMethodUnavailableMessage
    generalErrorMessage
    loginLink {
      url
    }
    signUpLink {
      url
    }
    contentfulPayoutMethodsList: payoutMethodsListCollection {
      items {
        ...CalculatorPayoutMethod
      }
    }
    partnersLabel
    partnersListDefaultValue
    partnerSelectErrorMessage
    phoneValidatorLabel
    phoneValidatorPlaceholder
    phoneInputInfoMoreInfoLabel
    phoneInputInfoPopupText
    phoneNumberInputErrorMessage
    phoneNumberInvalidErrorMessage
    topUpAmountsLabel
    topUpAmountsTitle
    topUpAmountsPlaceholder
    topUpAmountsSelectErrorMessage
    topUpAmountsSelectRetrievalErrorMessage
    feeLabel
    instantDiscountLabel
    transferTimeLabel
    totalToPayLabel
    blendedRateLabel
    promotionalTermsLink {
      ...Link
    }
    phReceiveBankTransferMessage
    ctaLinks: ctaLinksCollection {
      items {
        ...Link
      }
    }
    version
  }
  ${CalculatorPayoutMethod}
  ${Link}
`;
export const SendToCountryModule = gql`
  fragment SendToCountryModule on SendToCountryModule {
    sys {
      id
    }
    __typename
    name
    description {
      json
    }
    searchPlaceholder
    continueButtonText
  }
`;
export const BetterWayTop = gql`
  fragment BetterWayTop on BetterWayTop {
    sys {
      id
    }
    __typename
    name
    headingColor
    backgroundColor
    secondBackgroundColor
    hideDescriptionOnMobile
    rightModule {
      ...Calculator
      ...Search
      ...SendToCountryModule
    }
    headingRichText: heading {
      json
    }
    subHeadingRichText: subheading {
      json
    }
    link {
      ...Link
    }
    trustpilotMessage
    trustpilotLogo {
      ...CoreImage
    }
    trustpilotLink {
      ...Link
    }
    backgroundImage: backgroundImage {
      ...CoreImage
    }
    backgroundImageMobile: backgroundImageMobile {
      ...CoreImage
    }
    calculatorPage {
      slug
    }
    contentAlignment
    contentFlexDirection
  }
  ${Calculator}
  ${Search}
  ${SendToCountryModule}
  ${Link}
  ${CoreImage}
`;
export const HowItWorksStepRelaunch = gql`
  fragment HowItWorksStepRelaunch on HowItWorksStepRelaunch {
    sys {
      id
    }
    name
    header
    description
    image {
      ...CoreImage
    }
  }
  ${CoreImage}
`;
export const HowDoesItWork = gql`
  fragment HowDoesItWork on HowDoesItWork {
    sys {
      id
    }
    name
    title
    animationSteps: animationStepsCollection {
      items {
        ... on HowItWorksStepRelaunch {
          sys {
            id
          }
          __typename
        }
      }
    }
  }
`;
export const WhyChooseReason = gql`
  fragment WhyChooseReason on WhyChooseReason {
    sys {
      id
    }
    __typename
    name
    header
    description
    imagePosition
    image {
      ...CoreImage
    }
  }
  ${CoreImage}
`;
export const WhyChoose = gql`
  fragment WhyChoose on WhyChoose {
    sys {
      id
    }
    name
    __typename
    header
    reasons: reasonsCollection {
      items {
        ...WhyChooseReason
      }
    }
    backgroundColor
    secondBackgroundColor
  }
  ${WhyChooseReason}
`;
export const InformationDialog = gql`
  fragment InformationDialog on InformationDialog {
    sys {
      id
    }
    name
    __typename
    content {
      json
    }
  }
`;
export const ListOfCountries = gql`
  fragment ListOfCountries on ListOfCountries {
    sys {
      id
    }
    __typename
    name
    header
    subheader
    countriesCollection {
      items {
        name
        code
        slug
      }
    }
    buttonLabelExpandedList
    buttonLabelHiddenList
  }
`;
export const Messages = gql`
  fragment Messages on Messages {
    readMore
    readLess
    captchaPxLabel
    previous
    next
    chooseYourRegion
    countries
    international
    continue
    cancel
    close
    geoLocationBannerContent
    updated
    all
    autocompleteLabel
    noResults
    relatedPosts
    lifeabroad
    community
    education
    finance
    product
    referAFriendSendFromLabel
    locationLangLabel
    rafUrlUnavailable
    rafUrlInvalid
  }
`;
export const NotFoundContent = gql`
  fragment NotFoundContent on NotFoundContent {
    sys {
      id
    }
    __typename
    name
    message {
      json
    }
    buttonsCollection {
      items {
        ...Link
      }
    }
  }
  ${Link}
`;
export const ContextualPromoBanner = gql`
  fragment ContextualPromoBanner on ContextualPromoBanner {
    __typename
    sys {
      id
    }
    name
    backgroundColor
    description {
      json
      links {
        assets {
          block {
            sys {
              id
            }
            contentType
            ...CoreImage
          }
        }
      }
    }
    disclaimer
    link {
      ...Link
    }
    button {
      ...Link
    }
    verticalBackgroundImage {
      ...CoreImage
    }
    promoIcon {
      ...CoreImage
    }
  }
  ${CoreImage}
  ${Link}
`;
export const PromotionalApplicability = gql`
  fragment PromotionalApplicability on PromoApplicability {
    name
    visitorTypes
    showForCorridors
  }
`;
export const PromotionalBanner = gql`
  fragment PromotionalBanner on PromotionalBanner {
    __typename
    sys {
      id
    }
    name
    bannerDescription: description {
      json
    }
    applicability: promoApplicability {
      ...PromotionalApplicability
    }
    addToInitialHtml
  }
  ${PromotionalApplicability}
`;
export const ReferFriendHeader = gql`
  fragment ReferFriendHeader on ReferFriendHeader {
    sys {
      id
    }
    __typename
    name
    backgroundImage {
      ...CoreImage
    }
    signedOutButton {
      ...Link
    }
    heading
    subheading
    hintText
    textColor
  }
  ${CoreImage}
  ${Link}
`;
export const RelatedPage = gql`
  fragment RelatedPage on Page {
    sys {
      id
    }
    name
    __typename
    slug
    title
    banner {
      title
      alt: description
      url
    }
  }
`;
export const ReviewsSection = gql`
  fragment ReviewsSection on Reviews {
    sys {
      id
    }
    name
    starIcon {
      ...CoreImage
    }
    avatarIcon {
      ...CoreImage
    }
    heading
    reviewsTotalScore
    carouselText
    reviews: reviewsCollection {
      items {
        name
        comment
        stars
        countryCode
        reviewer
        reviewsNumberText
        reviewDate
      }
    }
    reviewsNumberLabel
    hideTrustpilotHeader
    trustpilotLink {
      ...Link
    }
    trustpilotLogo {
      ...CoreImage
    }
  }
  ${CoreImage}
  ${Link}
`;
export const Sitemap = gql`
  fragment Sitemap on Sitemap {
    sys {
      id
    }
    __typename
    name
  }
`;
export const Step = gql`
  fragment Step on Step {
    sys {
      id
    }
    name
    header
    description {
      json
    }
    image {
      ...CoreImage
    }
  }
  ${CoreImage}
`;
export const StepByStep = gql`
  fragment StepByStep on StepByStep {
    sys {
      id
    }
    name
    header
    stepsCollection {
      items {
        ... on Step {
          sys {
            id
          }
          __typename
        }
      }
    }
    link {
      ...Link
    }
  }
  ${Link}
`;
export const CountryInfo = gql`
  fragment CountryInfo on Country {
    sys {
      id
    }
    name
    code
    slug
    article
    prepositionFrom
    prepositionIn
  }
`;
export const StickyBanner = gql`
  fragment StickyBanner on StickyBanner {
    sys {
      id
    }
    __typename
    name
    image {
      ...CoreImage
    }
    description {
      json
    }
    textColor
    backgroundColor
    marketingChannel
    sendCountries: sendCountriesCollection {
      items {
        ...CountryInfo
      }
    }
    receiveCountries: receiveCountriesCollection {
      items {
        ...CountryInfo
      }
    }
  }
  ${CoreImage}
  ${CountryInfo}
`;
export const TextSection = gql`
  fragment TextSection on TextSection {
    sys {
      id
    }
    name
    __typename
    content {
      json
      links {
        assets {
          block {
            sys {
              id
            }
            contentType
            ...CoreImage
          }
        }
        entries {
          block {
            sys {
              id
            }
            ...Link
            ...Video
            ...TextWithColor
            ...IconWithText
          }
          inline {
            sys {
              id
            }
            ...Link
            ...TextWithColor
            ...IconWithText
            ... on FaqModuleItem {
              name
              title
            }
          }
          hyperlink {
            sys {
              id
            }
            ...Link
            ... on TextSection {
              __typename
              name
            }
          }
        }
      }
    }
    imageCollection {
      items {
        ...CoreImage
      }
    }
    maxWidth
    backgroundColor
    isOrderReversed
  }
  ${CoreImage}
  ${Link}
  ${Video}
  ${TextWithColor}
  ${IconWithText}
`;
export const Regulator = gql`
  fragment Regulator on Regulator {
    __typename
    sys {
      id
    }
    name
    customRegulatorName
    customFlagCode
    content {
      json
    }
    countriesListCollection {
      items {
        code
        name
      }
    }
  }
`;
export const WeAreRegulated = gql`
  fragment WeAreRegulated on WeAreRegulated {
    sys {
      id
    }
    name
    title
    description
    regulatorsCollection(locale: $locale, preview: $preview) {
      items {
        ...Regulator
      }
    }
  }
  ${Regulator}
`;
export const CexCurrencyData = gql`
  fragment CexCurrencyData on CexCurrency {
    name
    countryName
    currencyCode
    countryCode
    currencyName
    pageSlug
  }
`;
export const CexCurrencyCorridorData = gql`
  fragment CexCurrencyCorridorData on CexCurrencyCorridor {
    name
    pageSlug
    sendCurrency {
      ...CexCurrencyData
    }
    receiveCurrency {
      ...CexCurrencyData
    }
  }
  ${CexCurrencyData}
`;
export const CexCurrency = gql`
  fragment CexCurrency on CexCurrency {
    ...CexCurrencyData
    sys {
      id
    }
    description {
      json
      links {
        assets {
          block {
            ...CoreImage
          }
        }
        entries {
          block {
            sys {
              id
            }
            ...Link
            ...Video
            ...EmbeddedImage
            ...TextWithColor
            ...IconWithText
          }
          inline {
            sys {
              id
            }
            ...Link
            ...TextWithColor
            ...IconWithText
          }
          hyperlink {
            sys {
              id
            }
            ...Link
          }
        }
      }
    }
    faqContent {
      json
      links {
        assets {
          block {
            ...CoreImage
          }
        }
        entries {
          block {
            sys {
              id
            }
            ...Link
            ...Video
            ...EmbeddedImage
            ...TextWithColor
            ...IconWithText
          }
          inline {
            sys {
              id
            }
            ...Link
            ...TextWithColor
            ...IconWithText
          }
          hyperlink {
            sys {
              id
            }
            ...Link
          }
        }
      }
    }
    topCurrencyPairs: topCurrencyPairsCollection {
      items {
        name
        pageSlug
        countryCode
        countryName
        currencyCode
        currencyName
        description {
          json
          links {
            assets {
              block {
                ...CoreImage
              }
            }
            entries {
              block {
                sys {
                  id
                }
                ...Link
                ...Video
                ...EmbeddedImage
                ...TextWithColor
                ...IconWithText
              }
              inline {
                sys {
                  id
                }
                ...Link
                ...TextWithColor
                ...IconWithText
              }
              hyperlink {
                sys {
                  id
                }
                ...Link
              }
            }
          }
        }
      }
    }
    corridorGridTitle
  }
  ${CexCurrencyData}
  ${CoreImage}
  ${Link}
  ${Video}
  ${EmbeddedImage}
  ${TextWithColor}
  ${IconWithText}
`;
export const CexCurrencyCorridor = gql`
  fragment CexCurrencyCorridor on CexCurrencyCorridor {
    name
    pageSlug
    sendCurrency {
      ...CexCurrency
    }
    receiveCurrency {
      ...CexCurrency
    }
  }
  ${CexCurrency}
`;
export const LogoFragment = gql`
  fragment LogoFragment on Logo {
    name
    sys {
      id
    }
    __typename
    id
    logo {
      ...Asset
      url(transform: { width: 200, format: WEBP, quality: 100 })
    }
    link {
      ... on Page {
        slug
      }
    }
  }
  ${Asset}
`;
export const SeoOrganizationStructuredData = gql`
  fragment SeoOrganizationStructuredData on SeoOrganizationStructuredData {
    addressCountry
    addressLocality
    description
    email
    image {
      ...Asset
      url(transform: { width: 500 })
    }
    name
    postalCode
    sameAsCollection {
      items {
        value
      }
    }
    contactPointCollection {
      items {
        telephone
        areaServed
        contactType
      }
    }
    streetAddress
    url
  }
  ${Asset}
`;
export const FooterNavigationGroupExtras = gql`
  fragment FooterNavigationGroupExtras on FooterNavigationGroupExtras {
    backgroundColor
    color
    title
  }
`;
export const FooterNavigationGroup = gql`
  fragment FooterNavigationGroup on FooterNavigationGroup {
    title
    linksCollection {
      items {
        ...Link
      }
    }
    extras {
      ...FooterNavigationGroupExtras
    }
  }
  ${Link}
  ${FooterNavigationGroupExtras}
`;
export const Footer = gql`
  fragment Footer on LayoutFooter {
    __typename
    name
    sys {
      id
    }
    copyrightText
    socialIconsLinksCollection(limit: 10) {
      items {
        ...Link
      }
    }
    navigationCollection(limit: 30) {
      items {
        ...FooterNavigationGroup
      }
    }
    footerLogo {
      ...Asset
      url(transform: { width: 160 })
    }
  }
  ${Link}
  ${FooterNavigationGroup}
  ${Asset}
`;
export const Page = gql`
  fragment Page on Page {
    sys {
      id
    }
    __typename
    name
    slug
    title
    description
    noIndex
    regions
    headerPosition
    headerTheme
    analyticsPageName
    analyticsPageType
    ogImage {
      ...Asset
      url(transform: { width: 500 })
    }
    modulesCollection {
      items {
        ... on Entry {
          __typename
          sys {
            id
          }
        }
      }
    }
    footer {
      ...Footer
    }
    submenuItemsCollection {
      items {
        ...Link
      }
    }
  }
  ${Asset}
  ${Footer}
  ${Link}
`;
export const BlogPage = gql`
  fragment BlogPage on Page {
    sys {
      id
      publishedAt
      firstPublishedAt
    }
    content {
      json
      links {
        entries {
          hyperlink {
            sys {
              id
            }
            ... on TextSection {
              __typename
              name
            }
          }
        }
      }
    }
    author {
      __typename
      sys {
        id
      }
      name
      firstName
      lastName
      description
      image {
        ...Asset
        url(transform: { width: 40 })
      }
    }
    timeToRead
    publishedDate
    updatedDate
    banner {
      ...CoreImage
    }
  }
  ${Asset}
  ${CoreImage}
`;
export const PageSitemapLink = gql`
  fragment PageSitemapLink on Page {
    slug
    title
    heading
    name
    analyticsPageType
    sys {
      publishedAt
    }
  }
`;
export const PayoutMethodAlert = gql`
  fragment PayoutMethodAlert on PayoutMethodAlert {
    sys {
      id
    }
    __typename
    name
    title
    message {
      json
    }
    sendCountryList
    receiveCountryList
    payoutMethodsCollection {
      items {
        payoutMethodId
      }
    }
  }
`;
export const AppBannerQuery = gql`
  query AppBannerQuery($id: String!, $locale: String!, $preview: Boolean) {
    AppBanner: appBanner(id: $id, locale: $locale, preview: $preview) {
      ...AppBanner
    }
  }
  ${AppBanner}
`;
export const BackToSchoolQuery = gql`
  query BackToSchoolQuery($id: String!, $locale: String!, $preview: Boolean) {
    BackToSchool: backToSchool(id: $id, locale: $locale, preview: $preview) {
      ...BackToSchool
    }
  }
  ${BackToSchool}
`;
export const BodyTextQuery = gql`
  query BodyTextQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    BodyText: bodyTextCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...BodyText
      }
    }
  }
  ${BodyText}
`;
export const CardsQuery = gql`
  query CardsQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    Cards: cardsCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...Cards
      }
    }
  }
  ${Cards}
`;
export const CardQuery = gql`
  query CardQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    Card: cardCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...Card
      }
    }
  }
  ${Card}
`;
export const ComparisonTableQuery = gql`
  query ComparisonTableQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    ComparisonTable: comparisonTableCollection(
      locale: $locale
      preview: $preview
      limit: 1
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...ComparisonTable
      }
    }
  }
  ${ComparisonTable}
`;
export const ComparisonTableRowQuery = gql`
  query ComparisonTableRowQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    ComparisonTableRow: comparisonTableRowCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...ComparisonTableRow
      }
    }
  }
  ${ComparisonTableRow}
`;
export const ComparisonTableCellQuery = gql`
  query ComparisonTableCellQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    ComparisonTableCell: comparisonTableCellCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...ComparisonTableCell
      }
    }
  }
  ${ComparisonTableCell}
`;
export const CorrespondentLocationsQuery = gql`
  query CorrespondentLocationsQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    CorrespondentLocations: correspondentLocationsCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...CorrespondentLocations
      }
    }
  }
  ${CorrespondentLocations}
`;
export const CorrespondentsQuery = gql`
  query CorrespondentsQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    Correspondents: correspondentsCollection(
      locale: $locale
      preview: $preview
      limit: 1
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...Correspondents
      }
    }
  }
  ${Correspondents}
`;
export const CexCurrencyQuery = gql`
  query CexCurrencyQuery($id: String!, $locale: String!, $preview: Boolean) {
    CexCurrency: cexCurrency(id: $id, locale: $locale, preview: $preview) {
      ...CexCurrency
    }
  }
  ${CexCurrency}
`;
export const CexLineChartQuery = gql`
  query CexLineChartQuery($id: String!, $locale: String!, $preview: Boolean) {
    CexLineChart: cexLineChart(id: $id, locale: $locale, preview: $preview) {
      ...CexLineChart
    }
  }
  ${CexLineChart}
`;
export const CexRateAlertsQuery = gql`
  query CexRateAlertsQuery($id: String!, $locale: String!, $preview: Boolean) {
    CexRateAlerts: cexRateAlertsCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: { sys: { id: $id } }
    ) {
      items {
        ...CexRateAlerts
      }
    }
  }
  ${CexRateAlerts}
`;
export const CexRateTableQuery = gql`
  query CexRateTableQuery($id: String!, $locale: String!, $preview: Boolean) {
    CexRateTable: cexRateTable(id: $id, locale: $locale, preview: $preview) {
      ...CexRateTable
    }
  }
  ${CexRateTable}
`;
export const CexSearchQuery = gql`
  query CexSearchQuery($id: String!, $locale: String!, $preview: Boolean) {
    CexSearch: cexSearch(id: $id, locale: $locale, preview: $preview) {
      ...CexSearch
    }
  }
  ${CexSearch}
`;
export const EmbeddedTableEntryQuery = gql`
  query EmbeddedTableEntryQuery(
    $id: String!
    $locale: String
    $preview: Boolean
  ) {
    EmbeddedTableEntry: embeddedTableEntry(
      id: $id
      locale: $locale
      preview: $preview
    ) {
      ...EmbeddedTableEntry
    }
  }
  ${EmbeddedTableEntry}
`;
export const ExternalScriptQuery = gql`
  query ExternalScriptQuery($id: String!, $locale: String!, $preview: Boolean) {
    ExternalScript: externalScript(
      id: $id
      locale: $locale
      preview: $preview
    ) {
      ...ExternalScript
    }
  }
  ${ExternalScript}
`;
export const FaqFeedbackModuleQuery = gql`
  query FaqFeedbackModuleQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
  ) {
    FaqFeedbackModule: faqFeedbackModule(
      id: $id
      locale: $locale
      preview: $preview
    ) {
      ...FaqFeedbackModule
    }
  }
  ${FaqFeedbackModule}
`;
export const FaqHeroQuery = gql`
  query FaqHeroQuery($id: String!, $locale: String!, $preview: Boolean) {
    FaqHero: faqHero(id: $id, locale: $locale, preview: $preview) {
      ...FaqHero
    }
  }
  ${FaqHero}
`;
export const FaqModuleItemQuery = gql`
  query FaqModuleItemQuery($id: String!, $locale: String!, $preview: Boolean) {
    FaqModuleItem: faqModuleItemCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: { sys: { id: $id } }
    ) {
      items {
        ...FaqModuleItem
      }
    }
  }
  ${FaqModuleItem}
`;
export const FaqModuleQuery = gql`
  query FaqModuleQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    FaqModule: faqModuleCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...FaqModule
      }
    }
  }
  ${FaqModule}
`;
export const FaqPopularQuestionsQuery = gql`
  query FaqPopularQuestionsQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
  ) {
    FaqPopularQuestions: faqPopularQuestions(
      id: $id
      locale: $locale
      preview: $preview
    ) {
      ...FaqPopularQuestions
    }
  }
  ${FaqPopularQuestions}
`;
export const FaqTopicsModuleQuery = gql`
  query FaqTopicsModuleQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
  ) {
    FaqTopicsModule: faqTopicsModule(
      id: $id
      locale: $locale
      preview: $preview
    ) {
      ...FaqTopicsModule
    }
  }
  ${FaqTopicsModule}
`;
export const ForgottenPasswordPage = gql`
  query ForgottenPasswordPage($locale: String) {
    forgottenPasswordPageCollection(locale: $locale, limit: 1) {
      items {
        sys {
          id
        }
        name
        header
        description
        emailInputLabel
        continueButtonLabel
        successEmailSentAlert
        emailHelperText
        resetPasswordTimerText
        helpText {
          json
        }
        stickyBanner {
          name
          textColor
          backgroundColor
          image {
            ...CoreImage
          }
          description {
            json
          }
        }
      }
    }
  }
  ${CoreImage}
`;
export const FullWidthBannerQuery = gql`
  query FullWidthBannerQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    FullWidthBanner: fullWidthBannerCollection(
      locale: $locale
      preview: $preview
      limit: 1
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...FullWidthBanner
      }
    }
  }
  ${FullWidthBanner}
`;
export const HeroSlotQuery = gql`
  query HeroSlotQuery($id: String!, $locale: String!, $preview: Boolean) {
    HeroSlot: heroSlotCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: { sys: { id: $id } }
    ) {
      items {
        ...HeroSlot
      }
    }
  }
  ${HeroSlot}
`;
export const BetterWayTopQuery = gql`
  query BetterWayTopQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    BetterWayTop: betterWayTopCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...BetterWayTop
      }
    }
  }
  ${BetterWayTop}
`;
export const HowDoesItWorkQuery = gql`
  query HowDoesItWorkQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    HowDoesItWork: howDoesItWorkCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...HowDoesItWork
      }
    }
  }
  ${HowDoesItWork}
`;
export const HowItWorksStepRelaunchQuery = gql`
  query HowItWorksStepRelaunchQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    HowItWorksStepRelaunch: howItWorksStepRelaunchCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...HowItWorksStepRelaunch
      }
    }
  }
  ${HowItWorksStepRelaunch}
`;
export const WhyChooseQuery = gql`
  query WhyChooseQuery($id: String!, $locale: String!, $preview: Boolean) {
    WhyChoose: whyChoose(id: $id, locale: $locale, preview: $preview) {
      ...WhyChoose
    }
  }
  ${WhyChoose}
`;
export const InformationDialogQuery = gql`
  query InformationDialogQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
  ) {
    InformationDialog: informationDialog(
      id: $id
      locale: $locale
      preview: $preview
    ) {
      ...InformationDialog
    }
  }
  ${InformationDialog}
`;
export const LinkQuery = gql`
  query LinkQuery($id: String!, $locale: String!, $preview: Boolean) {
    Link: link(id: $id, locale: $locale, preview: $preview) {
      ...Link
    }
  }
  ${Link}
`;
export const ListOfCountriesQuery = gql`
  query ListOfCountriesQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    ListOfCountries: listOfCountriesCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...ListOfCountries
      }
    }
  }
  ${ListOfCountries}
`;
export const NotFoundContentQuery = gql`
  query NotFoundContentQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
  ) {
    NotFoundContent: notFoundContentCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: { sys: { id: $id } }
    ) {
      items {
        ...NotFoundContent
      }
    }
  }
  ${NotFoundContent}
`;
export const ContextualPromoBannerQuery = gql`
  query ContextualPromoBannerQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    ContextualPromoBanner: contextualPromoBannerCollection(
      locale: $locale
      preview: $preview
      limit: 1
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...ContextualPromoBanner
      }
    }
  }
  ${ContextualPromoBanner}
`;
export const PromotionalBannerQuery = gql`
  query PromotionalBannerQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
  ) {
    PromotionalBanner: promotionalBanner(
      id: $id
      locale: $locale
      preview: $preview
    ) {
      ...PromotionalBanner
    }
  }
  ${PromotionalBanner}
`;
export const ReferFriendHeaderQuery = gql`
  query ReferFriendHeaderQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
  ) {
    ReferFriendHeader: referFriendHeader(
      id: $id
      locale: $locale
      preview: $preview
    ) {
      ...ReferFriendHeader
    }
  }
  ${ReferFriendHeader}
`;
export const ChangePasswordPage = gql`
  query ChangePasswordPage($locale: String) {
    changePasswordPageCollection(locale: $locale, limit: 1) {
      items {
        sys {
          id
        }
        name
        header
        label
        button
        successInfo {
          sys {
            id
          }
        }
        validationLowerCaseLetterLabel
        validationMinimumCharactersLabel
        validationNumericNumberLabel
        validationUpperCaseLetterLabel
        helpText {
          json
        }
      }
    }
  }
`;
export const ReviewsQuery = gql`
  query ReviewsQuery($id: String!, $locale: String!, $preview: Boolean) {
    Reviews: reviews(id: $id, locale: $locale, preview: $preview) {
      ...ReviewsSection
    }
  }
  ${ReviewsSection}
`;
export const SendToCountryModuleQuery = gql`
  query SendToCountryModuleQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
  ) {
    SendToCountryModule: sendToCountryModuleCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: { sys: { id: $id } }
    ) {
      items {
        ...SendToCountryModule
      }
    }
  }
  ${SendToCountryModule}
`;
export const SitemapQuery = gql`
  query SitemapQuery($id: String!, $locale: String!, $preview: Boolean) {
    Sitemap: sitemap(id: $id, locale: $locale, preview: $preview) {
      ...Sitemap
    }
  }
  ${Sitemap}
`;
export const StepByStepQuery = gql`
  query StepByStepQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    StepByStep: stepByStepCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...StepByStep
      }
    }
  }
  ${StepByStep}
`;
export const StepQuery = gql`
  query StepQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    Step: stepCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...Step
      }
    }
  }
  ${Step}
`;
export const TextSectionQuery = gql`
  query TextSectionQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    TextSection: textSectionCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...TextSection
      }
    }
  }
  ${TextSection}
`;
export const VideoQuery = gql`
  query VideoQuery($id: String!, $locale: String!, $preview: Boolean) {
    Video: video(id: $id, locale: $locale, preview: $preview) {
      ...Video
    }
  }
  ${Video}
`;
export const WeAreRegulatedQuery = gql`
  query WeAreRegulatedQuery($id: String!, $locale: String!, $preview: Boolean) {
    WeAreRegulated: weAreRegulated(
      id: $id
      locale: $locale
      preview: $preview
    ) {
      ...WeAreRegulated
    }
  }
  ${WeAreRegulated}
`;
export const GetActionResult = gql`
  query GetActionResult($id: String!, $locale: String!) {
    actionResult(id: $id, locale: $locale) {
      __typename
      ... on ActionResult {
        sys {
          id
        }
        description
        title
        linksCollection {
          items {
            ...Link
          }
        }
      }
    }
  }
  ${Link}
`;
export const GetCountries = gql`
  query getCountries {
    countryCollection(limit: 200) {
      items {
        code
        slug
        name
        calculatorReceiveCountry {
          code
        }
      }
    }
  }
`;
export const CountryArticlesAndPrepositions = gql`
  query CountryArticlesAndPrepositions(
    $countryCodes: [String]
    $locale: String!
    $preview: Boolean!
  ) {
    countryCollection(
      where: { code_in: $countryCodes }
      locale: $locale
      preview: $preview
      limit: 200
    ) {
      items {
        name
        slug
        code
        article
        prepositionFrom
        prepositionTo
        prepositionIn
      }
    }
  }
`;
export const GreenfieldLoyaltyCountries = gql`
  query GreenfieldLoyaltyCountries($locale: String!, $preview: Boolean!) {
    referAFriendGreenfieldEnabledCountriesCollection(
      locale: $locale
      preview: $preview
    ) {
      items {
        name
        greenfieldCountriesCollection(locale: $locale, preview: $preview) {
          items {
            name
            code
          }
        }
      }
    }
  }
`;
export const CexCurrencyCorridorsList = gql`
  query CexCurrencyCorridorsList($locale: String!, $preview: Boolean) {
    cexCurrencyCorridorCollection(locale: $locale, preview: $preview) {
      items {
        sys {
          id
        }
      }
    }
  }
`;
export const CexCorridorSlugs = gql`
  query CexCorridorSlugs($locale: String!, $preview: Boolean) {
    cexCurrencyCorridorCollection(locale: $locale, preview: $preview) {
      items {
        pageSlug
      }
    }
  }
`;
export const CexCurrencyCorridorQuery = gql`
  query CexCurrencyCorridorQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
  ) {
    CexCurrencyCorridor: cexCurrencyCorridor(
      id: $id
      preview: $preview
      locale: $locale
    ) {
      ...CexCurrencyCorridorData
    }
  }
  ${CexCurrencyCorridorData}
`;
export const CexCurrencyCorridorBySendCurrencyCode = gql`
  query CexCurrencyCorridorBySendCurrencyCode(
    $sendCurrencyCode: String
    $locale: String!
    $preview: Boolean
  ) {
    cexCurrencyCorridorCollection(
      preview: $preview
      locale: $locale
      where: { sendCurrency: { currencyCode: $sendCurrencyCode } }
      limit: 1
    ) {
      items {
        ...CexCurrencyCorridorData
      }
    }
  }
  ${CexCurrencyCorridorData}
`;
export const CexCurrencyCorridorBySlug = gql`
  query CexCurrencyCorridorBySlug(
    $slug: String
    $locale: String!
    $preview: Boolean
  ) {
    cexCurrencyCorridorCollection(
      preview: $preview
      locale: $locale
      where: { pageSlug: $slug }
      limit: 1
    ) {
      items {
        ...CexCurrencyCorridor
      }
    }
  }
  ${CexCurrencyCorridor}
`;
export const CexCurrencyList = gql`
  query CexCurrencyList($locale: String!, $preview: Boolean) {
    cexCurrencyCollection(locale: $locale, preview: $preview) {
      items {
        sys {
          id
        }
      }
    }
  }
`;
export const CexCurrencySlugs = gql`
  query CexCurrencySlugs($locale: String!, $preview: Boolean) {
    cexCurrencyCollection(locale: $locale, preview: $preview) {
      items {
        ...CexCurrencyData
      }
    }
  }
  ${CexCurrencyData}
`;
export const CexCurrencyBySlug = gql`
  query CexCurrencyBySlug($slug: String, $locale: String!, $preview: Boolean) {
    cexCurrencyCollection(
      preview: $preview
      locale: $locale
      where: { pageSlug: $slug }
      limit: 1
    ) {
      items {
        ...CexCurrency
      }
    }
  }
  ${CexCurrency}
`;
export const CexCurrencyByCode = gql`
  query CexCurrencyByCode(
    $code: [String!]
    $locale: String!
    $preview: Boolean
  ) {
    cexCurrencyCollection(
      preview: $preview
      locale: $locale
      where: { currencyCode_in: $code }
      limit: 1
    ) {
      items {
        ...CexCurrency
      }
    }
  }
  ${CexCurrency}
`;
export const CexCurrencyByCurrencyAndCountryCode = gql`
  query CexCurrencyByCurrencyAndCountryCode(
    $currencyCode: [String!]
    $countryCode: [String!]
    $locale: String!
    $preview: Boolean
  ) {
    cexCurrencyCollection(
      preview: $preview
      locale: $locale
      where: { currencyCode_in: $currencyCode, countryCode_in: $countryCode }
      limit: 1
    ) {
      items {
        ...CexCurrency
      }
    }
  }
  ${CexCurrency}
`;
export const FeatureFlags = gql`
  query FeatureFlags($preview: Boolean!) {
    featureFlagCollection(preview: $preview) {
      items {
        name
        value
      }
    }
  }
`;
export const GetFooter = gql`
  query GetFooter(
    $locale: String
    $preview: Boolean
    $sendCountryCode: String
  ) {
    layoutFooterCollection(
      locale: $locale
      preview: $preview
      limit: 1
      where: { name: "footer-layout" }
    ) {
      items {
        ...Footer
      }
    }
    legalMessagingCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: {
        OR: [
          { sendCountryList_contains_some: [$sendCountryCode] }
          { sendCountryList_exists: false }
        ]
      }
    ) {
      items {
        ...LegalMessaging
      }
    }
  }
  ${Footer}
  ${LegalMessaging}
`;
export const GenericErrorPage = gql`
  query GenericErrorPage($locale: String!, $preview: Boolean) {
    genericErrorPageCollection(locale: $locale, limit: 1, preview: $preview) {
      items {
        header
        name
        description {
          json
        }
        subDescription {
          json
        }
      }
    }
  }
`;
export const LayoutHeaderQuery = gql`
  query LayoutHeaderQuery($locale: String!) {
    layoutHeaderCollection(locale: $locale, limit: 1) {
      items {
        ...Header
      }
    }
  }
  ${Header}
`;
export const Logo = gql`
  query Logo($preview: Boolean, $ids: [String]) {
    logoCollection(preview: $preview, where: { id_in: $ids }, limit: 50) {
      items {
        ...LogoFragment
      }
    }
  }
  ${LogoFragment}
`;
export const OrganizationSchema = gql`
  query OrganizationSchema($locale: String!, $preview: Boolean) {
    seoOrganizationStructuredDataCollection(
      preview: $preview
      locale: $locale
      limit: 1
    ) {
      items {
        ...SeoOrganizationStructuredData
      }
    }
  }
  ${SeoOrganizationStructuredData}
`;
export const HomePage = gql`
  query HomePage($locale: String!, $region: String, $preview: Boolean) {
    pageCollection(
      locale: $locale
      where: {
        OR: [
          { regions_contains_some: [$locale] }
          { regions_contains_some: [$region] }
        ]
        slug_exists: false
      }
      limit: 1
      preview: $preview
    ) {
      items {
        ...Page
      }
    }
  }
  ${Page}
`;
export const PageBySlug = gql`
  query PageBySlug(
    $slug: String
    $locale: String!
    $region: String
    $isBlogPage: Boolean!
    $preview: Boolean
  ) {
    pageCollection(
      locale: $locale
      where: {
        OR: [
          { regions_contains_some: [$locale] }
          { regions_contains_some: [$region] }
        ]
        slug: $slug
      }
      limit: 1
      preview: $preview
    ) {
      items {
        ...Page
        ...BlogPage @include(if: $isBlogPage)
      }
    }
  }
  ${Page}
  ${BlogPage}
`;
export const PageData = gql`
  query PageData(
    $pageId: String!
    $locale: String!
    $sendCountryCode: String!
    $isCorrespondentPage: Boolean!
    $slug: String
    $paths: [String]
    $preview: Boolean
    $includeRelatedPages: Boolean!
  ) {
    layoutHeaderCollection(locale: $locale, preview: $preview, limit: 1) {
      items {
        ...Header
      }
    }
    payoutMethodCollection(locale: $locale, preview: $preview, limit: 10) {
      items {
        name
        payoutMethodId
      }
    }
    messagesCollection(locale: $locale, preview: $preview, limit: 1) {
      items {
        ...Messages
      }
    }
    legalMessagingCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: {
        OR: [
          { sendCountryList_exists: false }
          {
            allSendCountriesExcept_exists: false
            sendCountryList_contains_some: [$sendCountryCode]
          }
          {
            allSendCountriesExcept: false
            sendCountryList_contains_some: [$sendCountryCode]
          }
          {
            allSendCountriesExcept: true
            sendCountryList_contains_none: [$sendCountryCode]
          }
        ]
      }
    ) {
      items {
        ...LegalMessaging
      }
    }
    breadcrumbs: pageCollection(
      preview: $preview
      locale: $locale
      where: { OR: [{ slug_exists: false }, { slug_in: $paths }] }
    ) {
      items {
        name
        heading
        slug
      }
    }
    correspondentId: pageCollection(
      locale: $locale
      where: { regions_contains_some: [$locale], slug: $slug }
      limit: 1
      preview: $preview
    ) {
      items {
        linkedFrom @include(if: $isCorrespondentPage) {
          logoCollection {
            items {
              id
            }
          }
        }
      }
    }
    seoOrganizationStructuredDataCollection(
      preview: $preview
      locale: $locale
      limit: 1
    ) {
      items {
        ...SeoOrganizationStructuredData
      }
    }
    page(id: $pageId) @include(if: $includeRelatedPages) {
      relatedPagesCollection(
        locale: $locale
        where: { regions_contains_some: [$locale] }
      ) {
        items {
          ...RelatedPage
        }
      }
    }
  }
  ${Header}
  ${Messages}
  ${LegalMessaging}
  ${SeoOrganizationStructuredData}
  ${RelatedPage}
`;
export const CountryPagesSlugs = gql`
  query CountryPagesSlugs($region: String!, $analyticsPageType: String!) {
    pageCollection(
      where: {
        regions_contains_some: [$region]
        analyticsPageType: $analyticsPageType
      }
    ) {
      items {
        slug
      }
    }
  }
`;
export const PagesSitemapLinks = gql`
  query PagesSitemapLinks(
    $locale: String!
    $preview: Boolean
    $skip: Int
    $excludedSlugs: [String]
  ) {
    pageCollection(
      where: {
        noIndex_not: true
        regions_contains_some: [$locale]
        slug_not_in: $excludedSlugs
      }
      preview: $preview
      locale: $locale
      limit: 1000
      skip: $skip
    ) {
      items {
        ...PageSitemapLink
      }
    }
  }
  ${PageSitemapLink}
`;
export const BlogPagesCards = gql`
  query BlogPagesCards(
    $analyticsPageType: String!
    $locale: String!
    $preview: Boolean
    $tags: [String]!
    $title: String
    $shouldIncludeSearchOptions: Boolean!
    $skip: Int
    $limit: Int
  ) {
    searchOptions: pageCollection(
      preview: $preview
      locale: $locale
      where: {
        regions_contains_some: [$locale]
        analyticsPageType: $analyticsPageType
        contentfulMetadata: {
          tags_exists: true
          tags: { id_contains_all: $tags }
        }
        title_contains: $title
      }
    ) @include(if: $shouldIncludeSearchOptions) {
      items {
        name
        slug
      }
    }
    cards: pageCollection(
      preview: $preview
      locale: $locale
      skip: $skip
      limit: $limit
      order: publishedDate_DESC
      where: {
        regions_contains_some: [$locale]
        analyticsPageType: $analyticsPageType
        contentfulMetadata: {
          tags_exists: true
          tags: { id_contains_all: $tags }
        }
        title_contains: $title
        publishedDate_exists: true
      }
    ) {
      total
      items {
        contentfulMetadata {
          tags {
            id
            name
          }
        }
        name
        slug
        banner {
          title
          alt: description
          url
        }
        publishedDate
      }
    }
  }
`;
export const BlogPagesTags = gql`
  query BlogPagesTags(
    $analyticsPageType: String!
    $locale: String!
    $preview: Boolean
  ) {
    pageCollection(
      preview: $preview
      locale: $locale
      where: {
        regions_contains_some: [$locale]
        analyticsPageType: $analyticsPageType
        contentfulMetadata: { tags_exists: true }
      }
    ) {
      items {
        contentfulMetadata {
          tags {
            id
            name
          }
        }
      }
    }
  }
`;
export const PagesSharedData = gql`
  query PagesSharedData($locale: String, $preview: Boolean) {
    layoutHeaderCollection(locale: $locale, preview: $preview, limit: 1) {
      items {
        ...Header
      }
    }
    messagesCollection(locale: $locale, limit: 1) {
      items {
        captchaPxLabel
      }
    }
    genericErrorPageCollection(locale: $locale, limit: 1) {
      items {
        header
        name
        description {
          json
        }
        subDescription {
          json
        }
      }
    }
    notFoundErrorPageCollection(locale: $locale, limit: 1) {
      items {
        name
        header
        description {
          json
        }
        subDescription {
          json
        }
      }
    }
  }
  ${Header}
`;
export const PayoutMethodAlerts = gql`
  query PayoutMethodAlerts(
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String!
    $receiveCountryCode: String
  ) {
    payoutMethodAlertCollection(
      locale: $locale
      preview: $preview
      where: {
        enabled: true
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
      limit: 10
    ) {
      items {
        ...PayoutMethodAlert
      }
    }
  }
  ${PayoutMethodAlert}
`;
export const RegionCode = gql`
  query RegionCode($limit: Int) {
    regionCodeCollection(limit: $limit) {
      items {
        name
        frCa
        esUs
        enGb
        enUs
        enNz
        enSe
        enMy
        enAu
        enCa
        daDk
        en
      }
    }
  }
`;
export const RobotsConfiguration = gql`
  query RobotsConfiguration($locale: String!, $preview: Boolean) {
    robotsConfigurationCollection(
      preview: $preview
      locale: $locale
      limit: 1
    ) {
      items {
        allow
        disallow
        userAgent
        blogSitemapUrl
      }
    }
  }
`;
export const Redirects = gql`
  query Redirects($limit: Int) {
    redirectCollection(limit: $limit) {
      items {
        source
        destination
        permanent
        locale
      }
    }
  }
`;
export const StickyBanners = gql`
  query StickyBanners(
    $locale: String!
    $preview: Boolean
    $analyticsPageType: String
    $marketingChannel: String
    $queryParams: [String]
    $cookies: [String]
    $visitorTypes: [String]
    $sendCountryCode: String!
    $receiveCountryCode: String
  ) {
    stickyBannerCollection(
      limit: 5
      locale: $locale
      preview: $preview
      order: name_ASC
      where: {
        pageTypes_contains_some: [$analyticsPageType]
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
          {
            OR: [
              { marketingChannel_exists: false }
              { marketingChannel_contains_some: [$marketingChannel] }
            ]
          }
          {
            OR: [
              { queryParams_exists: false }
              { queryParams_contains_some: $queryParams }
            ]
          }
          {
            OR: [{ cookies_exists: false }, { cookies_contains_some: $cookies }]
          }
          {
            OR: [
              { visitorTypes_exists: false }
              { visitorTypes_contains_some: $visitorTypes }
            ]
          }
        ]
      }
    ) {
      items {
        ...StickyBanner
      }
    }
  }
  ${StickyBanner}
`;
export const PagesWithTags = gql`
  query PagesWithTags(
    $analyticsPageType: String!
    $locale: String!
    $preview: Boolean
  ) {
    pageCollection(
      preview: $preview
      locale: $locale
      where: {
        regions_contains_some: [$locale]
        analyticsPageType: $analyticsPageType
        contentfulMetadata: { tags_exists: true }
      }
      limit: 1000
    ) {
      items {
        name
        slug
        contentfulMetadata {
          tags {
            name
          }
        }
      }
    }
  }
`;
